import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import {
  selectAlertRuleStatus,
  selectAlertRuleListData,
} from '../../store/alert-rules/selectors';
import * as actions from '../../store/alert-rules/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as userGroupActions from '../../store/alert-usergroups/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import AlertRules from './alert-rules';

const mapStateToProps = (state: RootState) => {
  return {
    alertRuleStatus: selectAlertRuleStatus(state),
    alertRuleContent: selectAlertRuleListData(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadAlertRules: () => dispatch({ type: actions.LOAD_ALERT_RULES }),
    loadAlertUserGroupNameList: (id?: string) =>
      dispatch({
        type: userGroupActions.LOAD_ALERT_USER_GROUP_NAME_LIST,
        payload: id,
      }),
    deleteUserGroup: (id?: string) =>
      dispatch({ type: actions.DELETE_ALERT_RULE, payload: id }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const AlertRuleList = connect(mapStateToProps, mapDispatchToProps)(AlertRules);

export default AlertRuleList;
