import { RootState } from './../rootReducers';
import { createSelector } from 'reselect';

export const selectModalState = (state: RootState) => state.ModalState;

export const selectModalStatus = createSelector(
    selectModalState,
    state => state.modalStatus
);

export const selectModalData = createSelector(
    selectModalState,
    state => state.data
);

export const selectModalActionStatus = createSelector(
    selectModalState,
    state => state.actionStatus
);

export const selectModalError = createSelector(
    selectModalState,
    state => state.hasError
);
