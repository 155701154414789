import { useState } from 'react';
import { Box, Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import Item from '../../components/box-items/box-item';
import CustomButton from '../../components/button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import { DoubleArrowDownIcon, DoubleArrowUpIcon } from '../../components/icons';
import { ActivityLogModel } from '../../models/activityLogModel';
import { formatCamelCaseString } from '../../utilities/fieldMapping-helper';
import * as datetimehelper from '../../utilities/datetime-helper';
import './styles/activity-log-item.scss';

interface ActivityLogItemProps {
  item: ActivityLogModel;
}

const ActivityLogItem: React.FC<ActivityLogItemProps> = (props: ActivityLogItemProps) => {
  const { item } = props;

  const [showDetails, setShowDetails] = useState(false);

  const showDetailHandler = () => {
    setShowDetails((prev) => !prev);
  };

  const timezone = datetimehelper.getBrowserLocalTimezone();

  return (
    <>
      <Grid
        container
        className={classNames('activity-log-grid-container', {
          'clickable-activity-log-grid-container': item?.activityType === 'Updated',
        })}
        alignItems='center'
        onClick={showDetailHandler}
      >
        <Grid item xs={11} sm={11} rowGap={2} className='activity-log-grid-details-container'>
          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={4}>
              <Item className='acticity-log-grid-item-container'>
                <Box>
                  <Item className='acticity-log-card-field'>
                    {datetimehelper.formatDateTimebyTimeZone(timezone, item?.dateTimeUtc, 'DD/MM/YYYY hh:mm A')}
                  </Item>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item className='acticity-log-grid-item-container'>
                <Box>
                  <Item className='acticity-log-card-field'>
                    <div>{item?.details}</div>
                  </Item>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item className='acticity-log-grid-item-container'>
                <Box>
                  <Item className='acticity-log-card-field'>
                    <div>{item?.user?.email}</div>
                  </Item>
                </Box>
              </Item>
            </Grid>
          </Grid>

          {item?.activityType === 'Updated' && (
            <Collapse in={showDetails}>
              <Grid container alignItems='flex-start' className='acticity-log-card-more-details'>
                <Grid item xs={12} sm={12}>
                  <Item className='acticity-log-grid-item-container'>
                    <Table className='card-details-table'>
                      <TableHead className='table-header'>
                        <TableRow>
                          <TableCell>Property Name</TableCell>
                          <TableCell>Old Value</TableCell>
                          <TableCell>New Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item?.differences?.map((detail, i) => {
                          return (
                            <TableRow key={detail.propertyName} className={i % 2 === 0 ? 'even-row' : 'odd-row'}>
                              <TableCell component='th' scope='row'>
                                {formatCamelCaseString(detail.propertyName)}
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {detail.oldValue}
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {detail.newValue}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Item>
                </Grid>
              </Grid>
            </Collapse>
          )}
        </Grid>

        {item?.activityType === 'Updated' && (
          <Grid item xs={1} sm={1} alignItems='flex-start'>
            {
              <Item className='acticity-log-grid-item-container'>
                <div>
                  <Item className='acticity-log-grid-button-container'>
                    {
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className='button-icon-color' />
                          ) : (
                            <DoubleArrowDownIcon className='button-icon-color' />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    }
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ActivityLogItem;
