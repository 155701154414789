import { LoadingStatus } from '../../constants/loading-constants';
import { TankDeliveryModel } from '../../models/tankDeliveryModel';

interface tankState {
  tankDeliveryStatus: string;
  tankDeliveryData: TankDeliveryModel[];
  hasError: boolean;
}

export const initialState: tankState = {
  tankDeliveryStatus: LoadingStatus.LOADING,
} as tankState;
