import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface FileUploadedIconProps {
  className?: string;
}

const FileUploadedIcon = (props: FileUploadedIconProps) => {
  return <SvgIcon className={`base-icon file-uploaded-icon ${props.className}`} />;
};

export default FileUploadedIcon;
