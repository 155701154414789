export const ScreenSizes = Object.freeze({
    MOBILE_MIN_SCREEN_SIZE: 0,
    IPAD_MIN_SCREEN_SIZE: 576,
    DESKTOP_MIN_SCREEN_SIZE: 991
});
  
export const NavPaneStates = Object.freeze({
    HAMBURGER: "HAMBURGER",
    COLLAPSED: "COLLAPSED",
    EXPANDED: "EXPANDED",
    COLLAPSED_AND_FLOATING: "COLLAPSED_AND_FLOATING",
    HAMBURGER_AND_FLOATING: "HAMBURGER_AND_FLOATING",
});
