import React, { useEffect, useState } from 'react';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import MainModal from '../../../components/main-modal/modal.container';
import DateRangeComponent from '../../../components/daterange/daterange.container';
import { ModalType } from '../../../constants/modal-constants';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { DATETIME_FORMAT } from '../../../constants/dateTimePicker-constants';
import { EventFilterModel } from '../../../models/eventModel';

interface EventFilterProps {
  modalData: ModalStateModel;
  hasValidationError: boolean;
  eventFilterSetting: EventFilterModel;
  closeModal: () => void;
  removeAllValidation: () => void;
  saveEventFilter: (filter: EventFilterModel) => void;
}

const EventFilter: React.FC<EventFilterProps> = (props: EventFilterProps) => {
  const { modalData, hasValidationError, eventFilterSetting, closeModal, removeAllValidation, saveEventFilter } = props;

  const [isTouched, setIsTouched] = useState(false);
  const [eventFilter, setEventFilter] = useState(eventFilterSetting);
  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);

  let minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 6, 1);

  useEffect(() => {
    setIsTouched(false);
    if (modalData.type === ModalType.SEARCH) {
      if (!!eventFilterSetting) {
        setEventFilter(eventFilterSetting);
      } else {
        setEventFilter({} as EventFilterModel);
      }
    }
  }, [modalData.type, eventFilterSetting]);

  const onCancel = (event: any) => {
    if (isTouched) {
      setDialogStatus({
        ...dialogState,
        isOpen: true,
        context: 'Are you sure you want to discard your changes?',
      });
    } else {
      removeAllValidation();
      setDialogStatus({
        ...dialogState,
        isOpen: false,
      });
      closeModal();
    }
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!hasValidationError) {
      saveEventFilter(eventFilter);
      closeModal();
    }
  };

  const cancelDialog = () => {
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    removeAllValidation();
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
    closeModal();
  };

  const handleStartDateTimeChange = (newValue: string) => {
    setIsTouched(true);
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        startDateTime: newValue,
      };
    });
  };

  const handleEndDateTimeChange = (newValue: string) => {
    setIsTouched(true);
    setEventFilter((prevstate) => {
      return {
        ...prevstate,
        endDateTime: newValue,
      };
    });
  };

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        onClose={onCancel}
        modalTitle='Custom Date Time Picker'
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={modalData.type === ModalType.VIEW}
        cancelButtonText='Cancel'
        saveButtonText='Apply'
        closeButtonText='Close'
        hideCancelButton={true}
      >
        <div style={{ height: '400px' }}>
          <DateRangeComponent
            fromLabel='From'
            toLabel='To'
            dateFormat={DATETIME_FORMAT.DATE_ONLY}
            startDateTimeValue={eventFilter?.startDateTime}
            endDateTimeValue={eventFilter?.endDateTime}
            handleStartDateTimeChange={handleStartDateTimeChange}
            handleEndDateTimeChange={handleEndDateTimeChange}
            disableFuture={true}
            startDateFieldName='startDate'
            endDateFieldName='endDate'
            startTimeFieldName='startTime'
            endTimeFieldName='endTime'
          />
        </div>
      </MainModal>
    </>
  );
};

export default EventFilter;
