import { initialState } from './alertUserGroupInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { AlertUserGroupModel } from '../../models/alertUserGroupModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const alertUserGroupSlice = createSlice({
  name: 'alertUserGroupState',
  initialState,
  reducers: {
    setAlertUserGroupStatus: (state, action: PayloadAction<string>) => {
      state.alertUserGroupStatus = action.payload;
      state.hasError = false;
    },
    setAlertUserGroupListData: (state, action: PayloadAction<AlertUserGroupModel[]>) => {
      state.alertUserGroupList = action.payload;
      state.hasError = false;
    },
    setAlertUserGroupInfoData: (state, action: PayloadAction<AlertUserGroupModel>) => {
      state.alertUserGroupInfo = action.payload;
      state.hasError = false;
    },
    setAlertUserGroupNameListData: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.alertUserGroupNameList = action.payload;
      state.hasError = false;
    },
    setAlertUserGroupError: (state) => {
      state.hasError = true;
      state.alertUserGroupList = [];
      state.alertUserGroupStatus = LoadingStatus.ERROR;
    },
    resetAlertUserGroupState: () => {
      return initialState;
    },
  },
});

export const {
  setAlertUserGroupStatus,
  setAlertUserGroupListData,
  setAlertUserGroupInfoData,
  setAlertUserGroupNameListData,
  setAlertUserGroupError,resetAlertUserGroupState,
} = alertUserGroupSlice.actions;

export default alertUserGroupSlice.reducer;
