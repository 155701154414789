import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPLICATIONINSIGHTS_KEY
      ? process.env.REACT_APP_APPLICATIONINSIGHTS_KEY
      : '',
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    maxMessageLimit: 2,
    loggingLevelConsole: 2,
  },
});
appInsights.loadAppInsights();

export const getAppInsights = () => appInsights;
export default reactPlugin;
