import './styles/message-display.scss';

interface MessageDisplayProps {
  messageTitle?: string;
  messageContent?: string;
}
const MessageDisplay: React.FC<MessageDisplayProps> = (
  props: MessageDisplayProps
) => {
  const { messageTitle, messageContent } = props;
  return (
    <>
      <div className='container'>
        {messageTitle && <h2 className='message-title'>{messageTitle}</h2>}
        {messageContent && (
          <div className='message-content'>{messageContent}</div>
        )}
      </div>
    </>
  );
};
export default MessageDisplay;
