import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface FileProps {
  className?: string;
}

const FileIcon = (props: FileProps) => {
  return <SvgIcon className={`color-icon file-icon ${props.className}`} />;
};

export default FileIcon;
