import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import * as modalActions from '../../../store/modals/actions';
import TransactionToolBar from '.';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { RootState } from '../../../store/rootReducers';
import { selectIsFilterActive } from '../../../store/transaction/selectors';
import {
  selectTransactionData,
} from '../../../store/transaction/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    isFilterActive: selectIsFilterActive(state),
    transactionsContent: selectTransactionData(state),
  }
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  }
};

const TransactionToolbarComponent = connect(mapStateToProps, mapDispatchToProps)(TransactionToolBar);

export default TransactionToolbarComponent;
