import React, { useEffect } from "react";
import "./cards.scss";

interface CardProps {
  className?:string
  children?: React.ReactNode
  
}
 
const Card: React.FC<CardProps> = (props:CardProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = "box-card " + props.className;

  return ( 
    <div>
      <div className={classes} data-testid="test-card">{props.children}</div>
    </div>
   );
}
 
export default Card;
