import { ReceiptTemplateItemEntity } from '../../entities/receiptTemplate';
import api, { setAuthorize } from '../../utilities/api';

const RECEIPT_API_PREFIX = 'receipt-management';

export const getAllReceiptTemplate = async (organisationId: string, siteId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${RECEIPT_API_PREFIX}/receipts/templates?organisationId=${organisationId}&siteId=${siteId}`
  );
  return result.data;
};

export const getReceiptTemplateInfo = async (receiptTemplateId: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${RECEIPT_API_PREFIX}/receipts/templates/${receiptTemplateId}?organisationId=${organisationId}`
  );
  return result.data;
};

export const createReceiptTemplate = async (data: ReceiptTemplateItemEntity) => { 
  await setAuthorize();
  await api.post(`/${RECEIPT_API_PREFIX}/receipts/templates`, data);
};

export const editReceiptTemplate = async (data: ReceiptTemplateItemEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${RECEIPT_API_PREFIX}/receipts/templates/${id}`, data);
};

export const deleteReceiptTemplate = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${RECEIPT_API_PREFIX}/receipts/templates/${id}?organisationId=${organisationId}`);
};
