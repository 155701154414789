import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectOrganisationId } from './../../store/auth/selectors';
import {
  selectNavPaneStatus,
  selectSelectedMenuIndex,
  selectSelectedDropdownMenuIndex,
  selectActiveDropdownIndex,
} from '../../store/nav-pane/selectors';
import { selectCurrentOrganisation } from '../../store/auth/selectors';
import * as actions from '../../store/nav-pane/actions';
import { selectHeaderConfiguration } from '../../store/header/selectors';
import NavPane from './nav-pane';
import { NavDropdownModel } from '../../models/navItemModel';
import { selectUserInfo } from '../../store/users/selectors';
import { selectSelectedSiteId } from '../../store/sites/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    selectedOrganisationId: selectOrganisationId(state),
    navPaneState: selectNavPaneStatus(state),
    selectSelectedMenuIndex: selectSelectedMenuIndex(state),
    selectSelectedDropdownMenuIndex: selectSelectedDropdownMenuIndex(state),
    activeOrganisation: selectCurrentOrganisation(state),
    selectActiveDropdownMenu: selectActiveDropdownIndex(state),
    headerConfiguration: selectHeaderConfiguration(state),
    userInfo: selectUserInfo(state),
    selectedSiteId: selectSelectedSiteId(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedMenuIndex: (index: number) =>
      dispatch({ type: actions.SELECT_MENU_INDEX, payload: index }),   
    setNavPaneStatus: (status: string) =>
      dispatch({ type: actions.SET_NAVE_PANE_STATUS, payload: status }),
    setSubMenuStatus: (status: boolean) =>
      dispatch({ type: actions.SET_NAVE_PANE_SUB_MENU_STATUS, payload: status }),
    setDropdownMenuIndex: (data: NavDropdownModel) =>
      dispatch({ type: actions.SELECT_DROPDOWN_MENU_INDEX, payload: data }),
    setActiveDropdownMenu: (index: number) =>
      dispatch({ type: actions.SELECT_ACTIVE_DROPDOWN_INDEX, payload: index }),
  };
};

const NavPaneModule = connect(mapStateToProps, mapDispatchToProps)(NavPane);

export default NavPaneModule;