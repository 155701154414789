import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Footer } from '../../../models/receiptTemplateModel';
import { ButtonStyle } from '../../../constants/button-constants';
import CustomTextField from '../../../components/text-field/text-field.container';
import Item from '../../../components/box-items/box-item';
import TextFieldWithSuggestions from '../../../components/text-field-with-suggestions/text-field-with-suggestions.container';
import CustomButton from '../../../components/button/custom-button';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import '../styles/receipt-template-footers.scss';

interface ReceiptTemplateFootersConfigurationProps {
  validateCounter: number;
  footers: Footer[];
  readOnly?: boolean;
  removeValidation: (name: string) => void;
  onBindingValue?: (newvalue: Footer[]) => void | undefined;
}

const ReceiptTemplateFootersConfiguration: React.FC<ReceiptTemplateFootersConfigurationProps> = (
  props: ReceiptTemplateFootersConfigurationProps
) => {
  const location = useLocation();
  const { validateCounter, footers, readOnly, removeValidation, onBindingValue } = props;
  const [footersValue, setFootersValue] = useState(footers);
  const [validationFlag, setValidationFlag] = useState(0);
  const [preparedFooters, setPreparedFooters] = useState([] as Footer[]);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setFootersValue(footers);
  }, [footers, setFootersValue]);

  useEffect(() => {
    const thankYouFooter = {
      text: 'THANK YOU',
    } as Footer;
    let list = [thankYouFooter];
    setPreparedFooters(list);
  }, [location, setPreparedFooters]);

  const handleAddClick = () => {
    setValidationFlag(0);
    let FooterItem = {
      text: '',
    } as Footer;
    let list = [FooterItem];
    if (footersValue !== undefined) {
      list = [...footersValue];
      list.push(FooterItem);
    }

    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const handleRemoveClick = (index: number) => {
    resetPriceListValidation();

    let list = [...footersValue];
    list.splice(index, 1);

    setFootersValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetPriceListValidation = () => {
    footersValue.forEach((_, index) => {
      removeValidation(`footer${index}`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let footerItems = [...footersValue];
    if (!!footerItems[index]) {
      let item = { ...footerItems[index] };
      item.text = !!newvalue.value ? newvalue.value.toString() : '';
      footerItems[index] = item;
    }
    setFootersValue(footerItems);

    if (onBindingValue) {
      onBindingValue(footerItems);
    }
  };

  return (
    <React.Fragment>
      <div className='receipt-template-footer-container'>
        <div className={`${readOnly ? 'receipt-templates-footer-header-readonly' : 'receipt-templates-footer-header'}`}>
          Footers
        </div>
        {readOnly && (footers?.length <= 0 || !footers) && (
          <div className='footers-empty-msg'>No footers found for this receipt template.</div>
        )}
        {!!footersValue &&
          footersValue.map((item, i) => {
            return (
              <Item key={i}>
                {!!footers && (
                  <div className={readOnly ? 'template-form-readonly-footers' : 'template-form-footers'}>
                    {readOnly ? (
                      <CustomTextField
                        className='read-only-text-field-container'
                        key={`footer${i}`}
                        placeholder={'Enter Footer'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type='input'
                        name={`footer${i}`}
                        value={item.text}
                        validateCounter={validationFlag}
                        maxCharLength={30}
                        readOnly={readOnly}
                      ></CustomTextField>
                    ) : (
                      <TextFieldWithSuggestions
                        name='address'
                        placeholder='Type to add footer or select from dropdown'
                        value={item.text}
                        dataList={preparedFooters
                          .filter(
                            (it) =>
                              !footers
                                .map((selected) => {
                                  return selected.text;
                                })
                                .includes(it.text)
                          )
                          .map((it) => {
                            return it.text;
                          })}
                        maxHeight={192}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        onTextBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        isMandatory={false}
                        readOnly={readOnly}
                        validateCounter={validationFlag}
                        noOptionsText={''}
                        isSelectFromList={true}
                        maxCharLength={30}
                      />
                    )}
                    {!readOnly && footersValue?.length >= 1 && (
                      <div className='remove-footer-container'>
                        <CustomButton buttonStyle={ButtonStyle.DELETE} onClick={() => handleRemoveClick(i)}>
                          x
                        </CustomButton>
                      </div>
                    )}
                  </div>
                )}
              </Item>
            );
          })}
        {!readOnly && (!footersValue || footersValue?.length < 3) && (
          <div className='add-footer-container'>
            <Link className='add-footer' underline='hover' onClick={handleAddClick}>
              + Add Footer
            </Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReceiptTemplateFootersConfiguration;
