import React from 'react';
import classNames from 'classnames';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { AlertUserGroupModel } from '../../models/alertUserGroupModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Item from '../../components/box-items/box-item';
import Card from '../../components/cards/cards';
import BoxItem from '../../components/box-items/box-item';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import './styles/alert-usergroup-item.scss';

interface AlertUserGroupItemProps {
  id: string;
  item: AlertUserGroupModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string, name: string) => void;
  onClick: (id: string) => void;
}

const AlertUserGroupItem: React.FC<AlertUserGroupItemProps> = (props: AlertUserGroupItemProps) => {
  const { id, item, hasDeleteAccess, hasUpdateAccess, onEdit, onDelete, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  const selectedUserDisplay: string =
    item.userNameList.length <= 0
      ? ''
      : item.userNameList.length === 1
      ? item.userNameList[0]
      : item.userNameList.length === 2
      ? `${item.userNameList[0]} and ${item.userNameList[1]}`
      : item.userNameList.length === 3
      ? `${item.userNameList[0]}, ${item.userNameList[1]} and ${item.userNameList[2]}`
      : `${item.userNameList[0]}, ${item.userNameList[1]}, ${item.userNameList[2]} and`;

  const moreselectedUsersInfo: string =
    item.userNameList.length <= 3
      ? ''
      : String(
          item.userNameList
            .map((it) => {
              return ` ${it}`;
            })
            .slice(3)
        );

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit User Group',
      handler: () => {
        onEdit(id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete User Group',
      handler: () => {
        onDelete(id, item.name);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='alert-user-group-item-card' onClick={onItemClick}>
          <BoxItem className='user-group-name'>{item.name}</BoxItem>
          <BoxItem className='user-group-user'>
            {selectedUserDisplay}{' '}
            {item.userNameList && item.userNameList?.length > 3 && (
              <CustomTooltip title={moreselectedUsersInfo} textDisplay={`${item.userNameList?.length - 3} more`} />
            )}
          </BoxItem>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu buttonNode={<MoreVertIcon />} items={items} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </React.Fragment>
  );
};

export default AlertUserGroupItem;
