import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectAlertData, selectAlertStatus } from '../../store/alert/selectors';
import { selectSiteList } from '../../store/sites/selectors';
import { selectAlertRuleNameListData } from '../../store/alert-rules/selectors';
import { selectIsPageReachBottom } from '../../store/page-configuration/selectors';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { AlertFilterModel } from '../../models/alertModel';
import * as actions from '../../store/alert/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as siteActions from '../../store/sites/actions';
import * as alertRuleActions from '../../store/alert-rules/actions';
import * as modalActions from '../../store/modals/actions';
import * as cardActions from '../../store/cards/actions';
import * as paginationActions from '../../store/pagination/actions';
import Alerts from './alert-list';

const mapStateToProps = (state: RootState) => {
  return {
    alertStatus: selectAlertStatus(state),
    alertData: selectAlertData(state),
    siteListName: selectSiteList(state),
    alertRuleNameList: selectAlertRuleNameListData(state),
    isPageReachBottom: selectIsPageReachBottom(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initLoadAlerts: (data: AlertFilterModel) => dispatch({ type: actions.INIT_LOAD_ALERTS, payload: data }),
    loadAlerts: (data?: AlertFilterModel) => dispatch({ type: actions.LOAD_ALERTS, payload: data }),
    saveAlertFuzzySearch: (data: string) => dispatch({ type: actions.SAVE_ALERT_FUZZY_SEARCH, payload: data }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadAlertRuleNameList: () => dispatch({ type: alertRuleActions.LOAD_ALERT_RULE_NAME_LIST }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    setIsScrollRequest: (data: boolean) => dispatch({ type: cardActions.SET_IS_SCROLL_REQUEST, payload: data }),
    clearContinuationToken: () => dispatch({ type: paginationActions.CLEAR_CONTINUATION_TOKEN }),
  };
};

const AlertList = connect(mapStateToProps, mapDispatchToProps)(Alerts);

export default AlertList;
