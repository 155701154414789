import React from "react";
import classNames from 'classnames';
import Item from '../../components/box-items/box-item';
import { ItemDetailModel } from '../../models/baseModels/itemDetailModel';

interface FixedDetailsProps {
  id: string;
  fixeditemDetails: ItemDetailModel[];
  showinExpandViewOnly: boolean;
}

const CardFixedDetails: React.FC<FixedDetailsProps> = (
  props: FixedDetailsProps
) => {
  return (
    <React.Fragment>
    
      <Item className={classNames(props.showinExpandViewOnly ? undefined : 'box-row-item-child-mobile', 'box-row-item-child'
         )}>
        <Item className={classNames('row-item-label')}>
          {props.fixeditemDetails[0].name}
        </Item>
        <Item className={classNames('row-item-detail')}>
          <div
            className={classNames(
              'status-icon',
              props.fixeditemDetails[0].healthIndicator
            )}
          ></div>
       {props?.fixeditemDetails[0].value}
        </Item>
      </Item>
      <Item
        className={classNames(
          'box-row-item-child',
          'box-row-item-child-tablet',
          props.showinExpandViewOnly ? undefined : 'box-row-fixeditem-expand-tablet'
        )}
      >
        <Item className="row-item-label">{props.fixeditemDetails[1].name}</Item>
        <Item className="row-item-detail">
          <div
            className={classNames(
              'status-icon',
              props.fixeditemDetails[1].healthIndicator
            )}
          ></div>
         {props?.fixeditemDetails[1].value}
        </Item>
      </Item>
    </React.Fragment>
  );
};


export default CardFixedDetails;
