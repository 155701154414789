import { Messages } from '../constants/messages';
import { ErrorResponse } from '../entities/error';
import { GenericErrorEntity } from '../entities/generic-error';
import { GenericErrorModel, Error } from '../models/baseModels/genericErrorModel';
import * as datetimehelper from '../utilities/datetime-helper';


export const getApiErrorMessage = (error: any) => {
  let errMessage: string = Messages.SNACKBAR_DEFAULT_ERROR_MSG;

  if (error.response && error.response.data) {
    let response: ErrorResponse = error.response.data;
    if (response?.errors && response?.errors?.length > 0) errMessage = response?.errors[0]?.detail;
  }
  if (error) {
    let response: ErrorResponse = error
    if (response?.errors && response?.errors?.length > 0) errMessage = response?.errors[0]?.detail;
  }
  return errMessage;
}

export const getGenericErrorMessage = (errorResponse: any) => {
  if (!!errorResponse) {
    let genericError = errorResponse as GenericErrorEntity;
    if (!!genericError) {
      return {
        message: errorResponse.message,
        correlationId: errorResponse.correlationId,
        dateTimeUtc: datetimehelper.formatUTCDate(errorResponse?.dateTimeUtc,'DD/MM/YYYY hh:mm A'),
        statusCode: errorResponse.statusCode,
        errors: genericError?.errors?.map((items) => {
          return {
            title: items.title,
            source: items.source,
            detail: items.detail
          } as Error;
        })
      } as unknown as GenericErrorModel;
    }
    return genericError;
  }
  return {} as GenericErrorModel;
}