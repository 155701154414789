import { initialState } from './terminalFirmwareInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { FirmwareModel, TerminalBoardModel, TerminalFirmwareModel } from '../../models/terminalFirmwareModel';

const terminalFimrwareSlice = createSlice({
  name: 'terminalFirmwareState',
  initialState,
  reducers: {
    setTerminalFimrwareStatus: (state, action: PayloadAction<string>) => {
      state.terminalFirmwareStatus = action.payload;
      state.hasError = false;
    },
    setTerminalFirmwareData: (state, action: PayloadAction<TerminalFirmwareModel>) => {
      state.terminalFirmwareData = action.payload;
      state.hasError = false;
    },
    setAvailableFirmwareData: (state, action: PayloadAction<FirmwareModel[]>) => {
      state.availableFirmwareData = action.payload;
      state.hasError = false;
    },
    setTerminalBoardsData: (state, action: PayloadAction<TerminalBoardModel[]>) => {
      state.terminalBoardsData = action.payload;
      state.hasError = false;
    },
    setTerminalFirmwareError: (state) => {
      state.hasError = true;
      state.terminalFirmwareData = {} as TerminalFirmwareModel;
      state.terminalFirmwareStatus = LoadingStatus.ERROR;
    },
    resetTerminalFirmwareState: () => {
      return initialState;
    },
  },
});

export const {
  setTerminalFimrwareStatus,
  setTerminalFirmwareData,
  setAvailableFirmwareData,
  setTerminalFirmwareError,
  setTerminalBoardsData,
  resetTerminalFirmwareState,
} = terminalFimrwareSlice.actions;
export default terminalFimrwareSlice.reducer;
