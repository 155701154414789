import { Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import BoxItem from '../box-items/box-item';
import './styles/footer.scss';

interface FooterBarProps {
  className?: string;
}

const FooterBar: React.FC<FooterBarProps> = (props: FooterBarProps) => {
  const navigate = useNavigate();

  const onPrivacyPolicyClick = () => {
    navigate('/privacy');
  };

  const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  return (
    <>
      <Box className={classNames('footerbar-container', props.className)}>
        <BoxItem className={classNames('footer-bottom-nav')}>
          <span className='footer-bottom-nav-container'>
            <Link className='footer-bottom-nav-links' onClick={onPrivacyPolicyClick} underline='hover'>
              {'Privacy Policy'}
            </Link>
            <span className='footer-nav-copyright-mobile'>COPYRIGHT © {getCurrentYear()} COMPAC INDUSTRIES</span>
          </span>
          <span>
            <Link
              className='footer-bottom-nav-links'
              target={'_blank'}
              href={'https://www.compac.co.nz/service-agents'}
              underline='hover'
            >
              <div className='service-agents'>
                <div>{'Service Agents & Distributors'}</div>
                <i className='external-link'></i>
              </div>
            </Link>
          </span>
          <span>
            <Link
              className='footer-bottom-nav-links'
              target={'_blank'}
              href={'https://www.compac.co.nz/technical-support'}
              underline='hover'
            >
              <div className='contact-us'>
                {'Support'}
                <i className='external-link'></i>
              </div>
            </Link>
          </span>
        </BoxItem>
        <div className='footer-nav-copyright'>
          <span>COPYRIGHT © {getCurrentYear()} COMPAC INDUSTRIES</span>
        </div>
      </Box>
    </>
  );
};

export default FooterBar;
