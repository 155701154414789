import React, {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { CompacLogo } from '../../components/icons';
import MenuIcon from '@mui/icons-material/Menu';
import { MainNavItems, SiteNavItems } from './nav-elements';
import { ScreenSizes, NavPaneStates } from '../../constants/window-constants';
import SubNavPane from './subNavItems/index.container';
import MainNavPaneComponent from './mainNavItems/index.container';
import NavPaneFooter from './navPaneFooter';
import './styles/nav-pane.scss';
import {
  NavDropdownModel,
  NavItemModel,
  NavItemSubModel,
} from '../../models/navItemModel';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import useAuthenticationManager from '../../components/auth/authenticationManager';
import { OrganisationItemModel } from '../../models/organisationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { UserModel } from '../../models/usersModel';
import Avatar from '../../components/avatar/avatar';
import { IsSiteSubMenuDisplayed } from './nav-pane-helper';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

interface NavPaneProps {
  navPaneState: string;
  selectSelectedMenuIndex: number;
  selectSelectedDropdownMenuIndex: NavDropdownModel;
  activeOrganisation: OrganisationItemModel;
  headerConfiguration: HeaderStateModel;
  userInfo: UserModel;
  selectedSiteId: string;
  selectedOrganisationId: string;
  setSubMenuStatus: (status: boolean) => void;
  setNavPaneStatus: (status: string) => void;
  setSelectedMenuIndex: (index: number) => void;
}

const NavPane: React.FC<NavPaneProps> = (props: NavPaneProps) => {
  const {    
    navPaneState,
    selectSelectedMenuIndex,
    selectSelectedDropdownMenuIndex,
    activeOrganisation,
    headerConfiguration,
    selectedSiteId,
    selectedOrganisationId,
    setNavPaneStatus,
    setSelectedMenuIndex,
  } = props;

  
  const [levelOneNavItems, setLevelOneNavItems] = useState(MainNavItems);
  const [levelTwoNavItems, setLevelTwoNavItems] = useState(SiteNavItems);

  // state for the mobile view
  const [navDrawerState, setNavDrawerState] = useState(true);
  const navigate = useNavigate();
  const { showAccountOption, error } = headerConfiguration || '';

  const { instance } = useAuthenticationManager();

  //TEMP get user info from b2c
  const [firstName, setFirstName] = useState('User');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    let accountInfo = instance?.getActiveAccount()?.idTokenClaims;
    if (!!accountInfo) {
      let firstName = `${
        accountInfo['firstname'] ? accountInfo['firstname'] : ' '
      }`;
      setFirstName(firstName);
      let lastName = `${accountInfo['surname'] ? accountInfo['surname'] : ' '}`;
      setLastName(lastName);
      if (firstName.trim() === '' && lastName.trim() === '') {
        setFirstName('User');
      }
    }
  }, [instance]);

  // Mounting/Unmounting resize listeners
  useEffect(() => {
    // Handle resizing ofthe screen
    const windowResizeHandler = () => {
      if (window.innerWidth > ScreenSizes.DESKTOP_MIN_SCREEN_SIZE) {
        setNavPaneStatus(NavPaneStates.EXPANDED);
        setNavDrawerState(true);
      } else if (window.innerWidth > ScreenSizes.IPAD_MIN_SCREEN_SIZE) {
        setNavPaneStatus(NavPaneStates.COLLAPSED);
        setNavDrawerState(true);
      } else {
        setNavPaneStatus(NavPaneStates.HAMBURGER);
        setNavDrawerState(false);
      }
    };

    window.addEventListener('resize', windowResizeHandler);
    windowResizeHandler();
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, [setNavPaneStatus]);

  useEffect(() => { 
    setLevelOneNavItems(MainNavItems.map((item) => {
      return {
        label: item?.label,
        path: item?.path?.replace('{orgId}', selectedOrganisationId),
        icon: item?.icon,
        moduleName: item?.moduleName,
        subItems: item?.subItems?.map((subItem) => {
          return {
            label: subItem?.label,
            path: subItem?.path?.replace('{orgId}', selectedOrganisationId).replace('{siteId}', selectedSiteId),
            icon: subItem?.icon,
            moduleName: subItem?.moduleName,
          } as NavItemSubModel;
        }),
      } as NavItemModel;
    }));
 },[selectedOrganisationId, selectedSiteId]);

 useEffect(() => { 
  setLevelTwoNavItems(SiteNavItems.map((item) => {
    return {
      label: item?.label,
      path: item?.path?.replace('{orgId}', selectedOrganisationId).replace('{siteId}', selectedSiteId),
      icon: item?.icon,
      moduleName: item?.moduleName,      
    } as NavItemSubModel;
  }));
},[selectedOrganisationId, selectedSiteId]);
  
  // Handle the different states the nav bar can be in
  const collapseLeftNavBarHandler = () => {
    setNavDrawerState(true);
    if (navPaneState === NavPaneStates.COLLAPSED) {
      if (window.innerWidth >= ScreenSizes.DESKTOP_MIN_SCREEN_SIZE) {
        setNavPaneStatus(NavPaneStates.EXPANDED);
      } else if (window.innerWidth > ScreenSizes.IPAD_MIN_SCREEN_SIZE) {
        setNavPaneStatus(NavPaneStates.COLLAPSED_AND_FLOATING);
      } else {
        setNavPaneStatus(NavPaneStates.HAMBURGER_AND_FLOATING);
      }
    } else if (navPaneState === NavPaneStates.EXPANDED) {
      setNavPaneStatus(NavPaneStates.COLLAPSED);
    } else if (navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING) {
      setNavPaneStatus(NavPaneStates.COLLAPSED);
    } else if (navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING) {
      setNavPaneStatus(NavPaneStates.HAMBURGER);
      setNavDrawerState(false);
    }
  };

  //open nav bar for mobile
  const openLeftNavBarHandler = () => {
    setNavPaneStatus(NavPaneStates.HAMBURGER_AND_FLOATING);
    setNavDrawerState(true);
  };

  //close nav bar for mobile when click away
  const closeDrawerOnClickAwayHandler = () => {
    if (navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING) {
      setNavPaneStatus(NavPaneStates.HAMBURGER);
      setNavDrawerState(false);
    }
  };

  //navigate back to compac / home
  const handleLogoSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedMenuIndex(0);
    navigate('/');
  };

  let items = [
    {
      label: 'Log Out',
      handler: () => {
        navigate('/logout');
      },
    },
  ];

  if (!error && showAccountOption) {
    items = [
      {
        label: 'View Account',
        handler: () => {
          navigate('/myaccount');
        },
      },
      ...items,
    ];
  } 

  return (
    <React.Fragment>
      {/* Top Nav Bar */}
      <div>
        {(navPaneState === NavPaneStates.HAMBURGER ||
          navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING) && (
          <Toolbar className='nav-top'>
            <MenuIcon
              className='nav-top-icon'
              onClick={() => openLeftNavBarHandler()}
              fontSize='large'
            />
            <Button className='nav-top-logo' onClick={handleLogoSubmit}>
              <CompacLogo smallerSize={true} />
            </Button>
            <FloatingMenu
              key={1}
              buttonNode={
                <Avatar
                  value={firstName + lastName}
                  firstName={firstName}
                  lastName={lastName}
                  size={40}
                />
              }
              items={items}
              className='account-option'
            />
          </Toolbar>
        )}
        {/* Left Side Nav Bar */}
        <Drawer
          variant={
            navPaneState === NavPaneStates.HAMBURGER ||
            navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING
              ? 'temporary'
              : 'permanent'
          }
          className={classNames('app-left-drawer-wrapper', {
            'nav-collapsed': navPaneState === NavPaneStates.COLLAPSED,
            'nav-hamburger': navPaneState === NavPaneStates.HAMBURGER,
          })}
          classes={{ paper: 'app-left-drawer-paper' }}
          anchor='left'
          onClose={() => closeDrawerOnClickAwayHandler()}
          open={navDrawerState}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {/* Menu Items */}
          <div className='app-left-drawer'>
            <Button onClick={handleLogoSubmit}>
              <ListItem key={'compac-logo'} className='collapsed-logo'>
                <CompacLogo
                  smallerSize={navPaneState === NavPaneStates.COLLAPSED}
                />
              </ListItem>
            </Button>
            <div className='item-container'>
              {/* Nav Menu Items */}
              <MainNavPaneComponent
                mainNavItems={levelOneNavItems}
                subMenuState={IsSiteSubMenuDisplayed()}
                selectSelectedDropdownMenuIndex={selectSelectedDropdownMenuIndex}     
              />
              {/* Site Nav Menu Items */}
              <SubNavPane
                subNavItems={levelTwoNavItems}
                navPaneState={navPaneState}
                subMenuState={IsSiteSubMenuDisplayed()}
                />
            </div>
            <Divider className='divider' />
            <NavPaneFooter
              navPaneState={navPaneState}
              collapseLeftNavBarHandler={collapseLeftNavBarHandler}
              activeOrganisation={activeOrganisation}
              selectSelectedMenuIndex={selectSelectedMenuIndex}
            />
          </div>
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default NavPane;
