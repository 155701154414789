import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { FirmwareInstallModel } from '../../models/terminalFirmwareModel';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectTerminalFirmwareStatus, selectTerminalFirmware } from '../../store/terminal-firmwares/selectors';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as terminalFirmwareActions from '../../store/terminal-firmwares/actions';
import * as modalActions from '../../store/modals/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import Firmwares from './firmwares';

const MapStateToProps = (state: RootState) => {
  return {
    terminalFirmwareStatus: selectTerminalFirmwareStatus(state),
    terminalFirmwareData: selectTerminalFirmware(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const MapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadCurrentAndPendingVersion: (data: string) =>
      dispatch({ type: terminalFirmwareActions.LOAD_TERMINALS_FIRMWARES, payload: data }),
    updateCurrentAndPendingState: () => dispatch({ type: terminalFirmwareActions.CHECK_INSTALLATION_PROGRESS }),
    updateTerminalFirmware: (data: FirmwareInstallModel) =>
      dispatch({ type: terminalFirmwareActions.UPDATE_TERMINAL_FIRMWARE, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
  };
};

const TerminalFirmwares = connect(MapStateToProps, MapDispatchToProps)(Firmwares);

export default TerminalFirmwares;
