import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectTankDeliveryState = (state: RootState) => state.TankDeliveryState;

export const selectTankDeliveryData = createSelector(
  selectTankDeliveryState,
  (state) => state.tankDeliveryData
);

export const selectTankDeliveryStatus = createSelector(
  selectTankDeliveryState,
  (state) => state.tankDeliveryStatus
);

