import { RootState } from "../rootReducers";
import { createSelector } from "reselect";

export const selectTerminalState = (state:RootState) => state.TerminalState;

export const selectTerminalData = createSelector(
    selectTerminalState,
    state => state.terminalData
  );
  
export const selectTerminalStatus = createSelector(
  selectTerminalState,
  state => state.terminalStatus
);

export const selectTerminalDetailStatus = createSelector(
  selectTerminalState,
  state => state.terminalDetailStatus
);
  
export const selectTerminalInfo = createSelector(
  selectTerminalState,
  state => state.terminalInfo
);

export const selectSelectedTerminal = createSelector(
  selectTerminalState,
  state => state.selectedTerminalItem
);

export const selectTerminalNumberList = createSelector(
  selectTerminalState,
  state => state.terminalNumberList
)
