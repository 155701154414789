import { connect } from 'react-redux';
import { PumpModel, PumpInfoModel } from '../../../models/pumpModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { selectFieldsValidation, selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectTankNameList } from '../../../store/tanks/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectSelectedSiteId } from '../../../store/sites/selectors';
import { selectOrganisationId } from '../../../store/auth/selectors';
import { RootState } from '../../../store/rootReducers';
import { selectPumpInfo, selectPumpContent, selectPumpDetailStatus } from '../../../store/pump/selectors';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as pumpActions from '../../../store/pump/actions';
import * as tankActions from '../../../store/tanks/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import Pump from './pump';

const mapStateToProps = (state: RootState) => {
  return {
    pumpContent: selectPumpContent(state),
    pumpInfo: selectPumpInfo(state),
    pumpStatus: selectPumpDetailStatus(state),
    hasValidationError: selectHasValidationError(state),
    tankNameList: selectTankNameList(state),
    selectedSiteId: selectSelectedSiteId(state),
    selectedOrganisationId: selectOrganisationId(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    fieldValidations: selectFieldsValidation(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadPumps: (data: PumpModel) => dispatch({ type: pumpActions.LOAD_PUMPS, payload: data }),
    loadPumpInfo: (data: PumpModel) => dispatch({ type: pumpActions.LOAD_PUMPINFO, payload: data }),
    editPumpItem: (data: PumpInfoModel) => dispatch({ type: pumpActions.EDIT_PUMP, payload: data }),
    createPumpItem: (data: PumpInfoModel) => dispatch({ type: pumpActions.CREATE_PUMP, payload: data }),
    loadTankNameList: () => dispatch({ type: tankActions.LOAD_TANKNAME_LIST }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const PumpPage = connect(mapStateToProps, mapDispatchToProps)(Pump);

export default PumpPage;
