import React from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import CustomTooltip from '../tooltip/custom-tooltip';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTextField from '../text-field/text-field.container';
import CustomButton from '../button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import './text-field-with-button.scss';

interface TextFieldProps {
  validateCounterFlag?: number;
  className?: string;
  label?: string;
  inlineLabel?: string;
  tooltipTitle?: string;
  moreInfoTextLink?: string;
  moreInfoTitle?: string;
  moreInfoContext?: string;
  isMandatory?: boolean;
  readOnly?: boolean;
  fieldKey?: string;
  fieldPlaceHolder?: string;
  filedName: string;
  fieldValue: string | number;
  fieldType: string;
  fieldMinRange?: number;
  fieldMaxRange?: number;
  fieldMaxCharLength?: number;
  fieldErrorMsg?: boolean;
  buttonText?: string;
  buttonIcon?: any;
  hideAsteriskSymbol?: boolean;
  customMdGrid?: number[];
  customXsGrid?: number[];
  onFiledChangeHandler?: (value: KeyValuePair) => void | undefined;
  onButtonClick?: () => void;
}

const TextFieldWithButton = (props: TextFieldProps) => {
  const {
    validateCounterFlag,
    className,
    label,
    inlineLabel,
    tooltipTitle,
    moreInfoTextLink,
    moreInfoTitle,
    moreInfoContext,
    isMandatory,
    readOnly,
    fieldKey,
    fieldPlaceHolder,
    filedName,
    fieldValue,
    fieldType,
    fieldMinRange,
    fieldMaxRange,
    fieldMaxCharLength,
    fieldErrorMsg,
    buttonText,
    buttonIcon,
    hideAsteriskSymbol,
    customMdGrid,
    customXsGrid,
    onFiledChangeHandler,
    onButtonClick,
  } = props;

  return (
    <React.Fragment>
      {
        <div
          className={classNames(
            'text-field-with-button-container',
            readOnly ? 'text-field-with-button-read-only-container' : '',
            className
          )}
        >
          {!inlineLabel && (
            <div className={classNames('text-field-label', className)}>
              {label}{' '}
              {tooltipTitle && (
                <CustomTooltip
                  title={tooltipTitle}
                  moreInfoTextLink={moreInfoTextLink}
                  moreInfoTitle={moreInfoTitle}
                  moreInfoContext={moreInfoContext}
                />
              )}
              {isMandatory && !readOnly && !hideAsteriskSymbol && label && <strong className='asterisk'>*</strong>}
            </div>
          )}
          <div
            className={classNames('text-field-with-button-field', {
              'text-field-with-button-with-error-container': fieldErrorMsg,
              'inline-text-field-with-button-field': inlineLabel,
            })}
          >
            {inlineLabel && <p className='inline-label'>{inlineLabel}</p>}
            <Grid
              container
              spacing={0}
              className={classNames({ 'inline-filed-with-button': inlineLabel }, 'filed-with-button')}
            >
              <Grid item xs={customXsGrid ? customXsGrid[0] : 10} sm={9} md={customMdGrid ? customMdGrid[0] : 10}>
                <CustomTextField
                  className={classNames({ 'field-with-dropdown-error-display': !fieldErrorMsg })}
                  validateCounter={validateCounterFlag}
                  isMandatory={isMandatory}
                  key={fieldKey}
                  placeholder={fieldPlaceHolder}
                  onBindingValue={onFiledChangeHandler}
                  name={filedName}
                  value={fieldValue}
                  type={fieldType}
                  readOnly={readOnly}
                  minRange={fieldMinRange}
                  maxRange={fieldMaxRange}
                  maxCharLength={fieldMaxCharLength}
                ></CustomTextField>
              </Grid>
              <Grid item xs={customXsGrid ? customXsGrid[1] : 2} sm={3} md={customMdGrid ? customMdGrid[1] : 2}>
                <CustomButton
                  buttonStyle={ButtonStyle.TEXTFIELD_INLINE_BUTTON}
                  className='custom-button'
                  onClick={() => {
                    if (onButtonClick) onButtonClick();
                  }}
                >
                  <span className='button-text'>{buttonText}</span>
                  <span className='button-icon'>{buttonIcon}</span>
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default TextFieldWithButton;
