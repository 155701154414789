
import { LoadingStatus } from '../../constants/loading-constants';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';

interface HeaderState {
    headerState: string
    headerConfiguration: HeaderStateModel,
    hasError: boolean
}

export const initialState: HeaderState = {headerState: LoadingStatus.LOADING} as HeaderState;