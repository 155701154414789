import { connect } from 'react-redux';
import { UserModel } from '../../../models/usersModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { RootState } from '../../../store/rootReducers';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectUserInfo, selectUserStatus } from '../../../store/users/selectors';
import { selectPermissionGroupNameList } from '../../../store/permission-group/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import * as actions from '../../../store/users/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as prtmissionGroupActions from '../../../store/permission-group/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import User from './user';

const mapStateToProps = (state: RootState) => {
  return {
    userInfo: selectUserInfo(state),
    userStatus: selectUserStatus(state),
    hasValidationError: selectHasValidationError(state),
    permissionGroupNameList: selectPermissionGroupNameList(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createUser: (data: UserModel) => dispatch({ type: actions.CREATE_USER, payload: data }),
    loadUserInfo: (id?: string) => dispatch({ type: actions.LOAD_USER_INFO, payload: id }),
    editUser: (data: UserModel) => dispatch({ type: actions.EDIT_USER, payload: data }),
    inviteGuest: (data: UserModel) => dispatch({ type: actions.INVITE_GUEST, payload: data }),
    loadPermissionGroupList: () => dispatch({ type: prtmissionGroupActions.LOAD_PERMISSION_GROUP_NAME_LIST }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const UserPage = connect(mapStateToProps, mapDispatchToProps)(User);

export default UserPage;
