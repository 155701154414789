import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import BoxItem from '../../box-items/box-item';
import { IconButton } from '@mui/material';

interface ModalHeaderProps {
  isDisabled?: boolean;
  modalTitle: string;
  customHeader?: any;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = (props: ModalHeaderProps) => {
  const { isDisabled, modalTitle, customHeader, onClose } = props;
  return customHeader ? (
    customHeader
  ) : (
    <React.Fragment>
      <BoxItem className='close-icon-div'>
        <IconButton aria-label='close' onClick={onClose} disabled={isDisabled}>
          <CloseIcon className='close-icon' />
        </IconButton>
      </BoxItem>
      <div className='update-title'>{modalTitle}</div>
    </React.Fragment>
  );
};

export default ModalHeader;
