import { RootState } from "../rootReducers";
import { createSelector } from "reselect";

export const selectTerminalFirmwareState = (state:RootState) => state.TerminalFirmwareState;

export const selectTerminalFirmware = createSelector(
    selectTerminalFirmwareState,
    state => state.terminalFirmwareData
  );
  
  export const selectTerminalFirmwareStatus = createSelector(
    selectTerminalFirmwareState,
    state => state.terminalFirmwareStatus
  );

  export const selectAvailableFirmwareData = createSelector(
    selectTerminalFirmwareState,
    state => state.availableFirmwareData
  );

  
  export const selectTerminalBoards = createSelector(
    selectTerminalFirmwareState,
    state => state.terminalBoardsData
  );
  
