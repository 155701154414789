import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface DoubleArrowUpProps{
    className?:string
}

const DoubleArrowUpIcon = (props: DoubleArrowUpProps) => {
    return (
        <SvgIcon className={`base-icon double-arrow-up-icon ${props.className}`} />
    );
};

export default DoubleArrowUpIcon;