import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectEventState = (state: RootState) => state.EventState;

export const selectEventData = createSelector(
    selectEventState,
    state => state.eventData
  );

export const selectEventStatus = createSelector(
  selectEventState,
  state => state.eventStatus
);

export const selectEventTypes = createSelector(
  selectEventState,
  state => state.eventTypes
);

export const selectEventFuzzySearch = createSelector(
  selectEventState,
  state => state.eventFuzzySearch
);

