import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectActivityLogState = (state: RootState) => state.ActivityLogState;

export const selectActivityLogData = createSelector(
    selectActivityLogState,
    state => state.activityLogData
  );

export const selectActivityLogStatus = createSelector(
  selectActivityLogState,
  state => state.activityLogStatus
);

export const selectActivityLogResourceTypes = createSelector(
  selectActivityLogState,
  state => state.activityLogResourceTypes
);