import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { setUpEnv } from '../config/config';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { apiRequest, msalConfig, apiRedirectRequest } from '../config/authConfig';
import { GetDataFromSessionStorage, SetDataInSessionStorage } from './storageHelper';
import { ACCESS_TOKEN, AZURE_MAPS_RESOURCE_TOKEN } from '../constants/auth-constants';
import { GenericErrorEntity } from '../entities/generic-error';
import { setSnackBarError } from '../store/snackbar/reducers';

setUpEnv();

const apiUrl = window.API_URL;

const api = axios.create({
  baseURL: apiUrl,
  validateStatus: (status) => status >= 200 && status < 300,
  // These headers are important as IE 11 can otherwise cache API responses
  // causing very weird behaviour
  headers: {
    'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=0',
    Pragma: 'no-cache',
    Expires: 0,
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (process.env.STORYBOOK === 'true' && error.response.status === 401) {
      // return a 'successful object' on 401 for storybook
      return Promise.resolve({ data: {} });
    }
    if (!!error.response.data) {
      let errorDetail = error.response.data as GenericErrorEntity;
      errorDetail.statusCode = error.response.status.toString();
      return Promise.reject(errorDetail);
    }
    return Promise.reject(error);
  }
);

api.defaults.headers.common.Accept = 'application/json';
api.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';

export const azureApi = axios.create({
  baseURL: apiUrl,
  validateStatus: (status) => status >= 200 && status < 300,
  // These headers are important as IE 11 can otherwise cache API responses
  // causing very weird behaviour
  headers: {
    'x-ms-client-id': String(process.env.REACT_APP_AZURE_MAPS_CLIENT_ID),
    'Content-Type': 'application/json; charset=utf-8',
    'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=0',
    Pragma: 'no-cache',
  },
});

export const setAuthorize = async () => {
  let accessToken = GetDataFromSessionStorage(ACCESS_TOKEN);
  const msalInstance = new PublicClientApplication(msalConfig);
  let activeAccount: AccountInfo | null;
  let accounts: AccountInfo[];
  interface Token {
    exp: number;
  }

  if (accessToken) {
    try {
      const decodedToken = jwtDecode<Token>(accessToken);
      const exp = decodedToken?.exp ?? 0;
      const checkpointTime = Math.floor(new Date().getTime() / 1000) + 5;

      if (checkpointTime < exp) {
        setToken(accessToken);
        return;
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }

  // If accessToken is not present or expired, initialize MSAL
  await initializeMsal();

  async function initializeMsal() {
    await msalInstance.initialize();

    // we must call and await the initialize function before attempting to call any other MSAL AP
    // after MSAL initialized successfully...
    activeAccount = await msalInstance.getActiveAccount();
    accounts = await msalInstance.getAllAccounts();
    if (activeAccount && accounts.length > 0) {
      try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
          ...apiRequest,
          account: activeAccount || accounts[0],
        });
        setToken(accessTokenResponse.accessToken);
      } catch (error: any) {
        // If there's an error acquiring the token, redirect to the login page
        msalInstance.acquireTokenRedirect({
          ...apiRedirectRequest,
          account: activeAccount || accounts[0],
        });
        setSnackBarError(error);
      }
    }
  }
};

function setToken(accessToken: string) {
  SetDataInSessionStorage(ACCESS_TOKEN, accessToken);
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export const getResourceToken = () => {
  azureApi.defaults.headers.common.Authorization = `Bearer ${GetDataFromSessionStorage(AZURE_MAPS_RESOURCE_TOKEN)}`;
};

export const revokeAccess = () => {
  delete api.defaults.headers.common.Authorization;
};

export default api;
