import { LoadingStatus } from '../../constants/loading-constants';
import { CardFilterModel, CardModel } from '../../models/cardModel';

interface CardState {
  cardStatus: string;
  cardList: CardModel[];
  cardFuzzySearch: string;
  hasError: boolean;
  isScrollRequest: boolean;
  cardData: CardModel;
  cardFilter: CardFilterModel;
}

export const initialState: CardState = {
  cardStatus: LoadingStatus.LOADING,
} as CardState;
