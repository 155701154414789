import React, { useCallback, useEffect, useState } from 'react';
import { Link } from '@mui/material';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import DropDown from '../../../components/dropdown/dropdown.container';
import MainModal from '../../../components/main-modal/modal.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import DateTimeRangePickerComponent from '../../../components/datetimerange/datetimerange.containter';
import TextFieldWithSuggestions from '../../../components/text-field-with-suggestions/text-field-with-suggestions.container';
import CustomTooltip from '../../../components/tooltip/custom-tooltip';
import CustomButton from '../../../components/button/custom-button';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import {
  DEFAULT_DROP_DOWN_VALUE,
  transactionState,
  transactionType,
  transactionAcquirerName,
  cardType,
} from '../../../constants/dropdown-constants';
import { ButtonStyle } from '../../../constants/button-constants';
import { ModalType } from '../../../constants/modal-constants';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { TransactionFilterModel } from '../../../models/transactionModel';
import { BinRangeItemListModel, CardTagModel } from '../../../models/binRangeModel';
import {
  getLastMonthStartAndEndDateTime,
  getLastWeekStartAndEndDateTime,
  getThisMonthStartAndEndDateTime,
  getThisWeekStartAndEndDateTime,
  getTodaySrartAndEndDateTime,
  getYesterdaySrartAndEndDateTime,
} from '../../../utilities/datetime-helper';
import * as dateTimeHelper from '../../../utilities/datetime-helper';
import '../styles/filter-modal.scss';

interface TransactionFilterProps {
  modalData: ModalStateModel;
  siteList: KeyValuePair[];
  hasValidationError: boolean;
  transactionFilterSetting: TransactionFilterModel;
  binRangeListName: KeyValuePair[];
  binRangeList: BinRangeItemListModel[];
  closeModal: () => void;
  removeAllValidation: () => void;
  saveExportFilter: (filter: TransactionFilterModel) => void;
}

const TransactionFilter: React.FC<TransactionFilterProps> = (props: TransactionFilterProps) => {
  const {
    modalData,
    siteList,
    hasValidationError,
    transactionFilterSetting,
    binRangeListName,
    binRangeList,
    closeModal,
    removeAllValidation,
    saveExportFilter,
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const [transactionFilter, setTransactionFilter] = useState(transactionFilterSetting);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);
  const [selectedBINRange, setSelectedBINRange] = useState({} as BinRangeItemListModel);

  useEffect(() => {
    setIsTouched(false);
    if (modalData.type === ModalType.EXPORT) {
      if (!!transactionFilterSetting) {
        if (
          !transactionFilterSetting?.tags ||
          !transactionFilterSetting?.tags.length ||
          transactionFilterSetting?.tags.length <= 0
        ) {
          transactionFilterSetting.tags = [{ key: '', value: '' }];
          setTransactionFilter(transactionFilterSetting);
        } else setTransactionFilter(transactionFilterSetting);
        setSelectedBINRange(
          binRangeList?.find((x) => x?.id === transactionFilterSetting?.binRange) || ({} as BinRangeItemListModel)
        );
      } else {
        setTransactionFilter({} as TransactionFilterModel);
      }
    }
  }, [modalData.type, transactionFilterSetting]);

  const onCancel = (event: any) => {
    setValidateCounterFlag(0);
    if (isTouched) {
      setDialogStatus({
        ...dialogState,
        isOpen: true,
        context: 'Are you sure you want to discard your changes?',
      });
    } else {
      removeAllValidation();
      setDialogStatus({
        ...dialogState,
        isOpen: false,
      });
      closeModal();
    }
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError && modalData.type === ModalType.EXPORT) {
      saveExportFilter(transactionFilter);
      closeModal();
      setValidateCounterFlag(0);
    }
  };

  const onResetClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setIsTouched(false);
      setTransactionFilter({ tags: [{ key: '', value: '' }] } as TransactionFilterModel);
      removeAllValidation();
      setValidateCounterFlag(0);
      setSelectedBINRange({} as BinRangeItemListModel);
    },
    [removeAllValidation]
  );

  const cancelDialog = () => {
    setValidateCounterFlag(0);
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    removeAllValidation();
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
    closeModal();
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsTouched(true);

      if (newvalue?.key === 'binRange') {
        setTransactionFilter((prevstate) => {
          return {
            ...prevstate,
          };
        });
        let binRange = binRangeList?.find((it) => it?.id === newvalue.value);
        setSelectedBINRange(binRange ? binRange : ({} as BinRangeItemListModel));
      }

      if (newvalue?.key?.toString()?.includes('key')) {
        let updatedKeyIndex = Number(newvalue?.key?.toString()?.replace('key', ''));
        let tags = transactionFilter?.tags ? [...transactionFilter.tags] : [];
        tags[updatedKeyIndex] = { key: String(newvalue.value), value: tags[updatedKeyIndex]?.value };
        setTransactionFilter((prevstate) => {
          return {
            ...prevstate,
            tags: tags,
          };
        });
        return;
      }

      if (newvalue?.key?.toString()?.includes('value')) {
        let updatedKeyIndex = Number(newvalue?.key?.toString()?.replace('value', ''));
        let tags = transactionFilter?.tags ? [...transactionFilter.tags] : [];
        tags[updatedKeyIndex] = { key: tags[updatedKeyIndex]?.key, value: String(newvalue.value) };
        setTransactionFilter((prevstate) => {
          return {
            ...prevstate,
            tags: tags,
          };
        });
        return;
      }

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [binRangeList, transactionFilter]
  );

  const onDateTimeRangeChange = useCallback((newvalue: string) => {
    const currentDate = new Date();
    if (newvalue === 'Select') {
      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: '',
          startDateTime: '',
          endDateTime: '',
        };
      });
    }

    if (newvalue === 'today') {
      let { startDateTime, endDateTime } = getTodaySrartAndEndDateTime();

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'today',
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };
      });
    }

    if (newvalue === 'yesterday') {
      let { startDateTime, endDateTime } = getYesterdaySrartAndEndDateTime();

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'yesterday',
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };
      });
    }

    if (newvalue === 'thisWeek') {
      let { startDateTime, endDateTime } = getThisWeekStartAndEndDateTime();

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'thisWeek',
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };
      });
    }

    if (newvalue === 'lastWeek') {
      let { startDateTime, endDateTime } = getLastWeekStartAndEndDateTime();

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'lastWeek',
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };
      });
    }

    if (newvalue === 'thisMonth') {
      let { startDateTime, endDateTime } = getThisMonthStartAndEndDateTime();

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'thisMonth',
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };
      });
    }

    if (newvalue === 'lastMonth') {
      let { startDateTime, endDateTime } = getLastMonthStartAndEndDateTime();

      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'lastMonth',
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        };
      });
    }

    if (newvalue === 'custom') {
      setTransactionFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'custom',
        };
      });
    }
  }, []);

  const handleStartDateTimeChange = (newValue: string) => {
    setIsTouched(true);
    setTransactionFilter((prevstate) => {
      return {
        ...prevstate,
        startDateTime: newValue,
      };
    });
  };

  const handleEndDateTimeChange = (newValue: string) => {
    setIsTouched(true);
    setTransactionFilter((prevstate) => {
      return {
        ...prevstate,
        endDateTime: newValue,
      };
    });
  };

  const handleAddTagClick = () => {
    setTransactionFilter((prevstate) => {
      return {
        ...prevstate,
        tags: [...(prevstate?.tags || []), { key: '', value: '' }],
      };
    });
  };

  const handleRemoveClick = (value: string, i: number) => {
    let tags = [...transactionFilter?.tags];
    tags.splice(i, 1);
    setTransactionFilter((prevstate) => {
      return {
        ...prevstate,
        tags,
      };
    });
  };

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        className='export-filter-modal'
        onClose={onCancel}
        modalTitle='Filters'
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={modalData.type === ModalType.VIEW}
        cancelButtonText='Cancel'
        saveButtonText='Apply'
        closeButtonText='Close'
        displayResetButton={true}
        onResetClick={onResetClick}
        hideCancelButton={true}
      >
        <div className='filter-item-container'>
          <DropDown
            isMandatory={false}
            key='siteId'
            name='siteId'
            onBindingValue={onTextChangeHandler}
            value={!!transactionFilter?.siteId ? transactionFilter?.siteId : DEFAULT_DROP_DOWN_VALUE}
            label='Sites'
            keyValuePair={siteList}
          />

          <DropDown
            key={'type'}
            name={'type'}
            isMandatory={false}
            value={!!transactionFilter?.type ? transactionFilter?.type : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'Type'}
            keyValuePair={transactionType}
          />

          <DateTimeRangePickerComponent
            validateCounter={validateCounterFlag}
            label={`Date Range ${dateTimeHelper.getBrowserTimezone()}`}
            value={transactionFilter?.dateRange}
            startDateTimeValue={transactionFilter?.startDateTime}
            endDateTimeValue={transactionFilter?.endDateTime}
            onBindingValue={onDateTimeRangeChange}
            handleStartDateTimeChange={handleStartDateTimeChange}
            handleEndDateTimeChange={handleEndDateTimeChange}
            startDateFieldName='startDate'
            endDateFieldName='endDate'
          />

          <DropDown
            key={'state'}
            name={'state'}
            value={!!transactionFilter?.state ? transactionFilter?.state : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'State'}
            keyValuePair={transactionState}
            isMandatory={false}
          />

          <CustomTextField
            isMandatory={false}
            maxCharLength={4}
            key='reference'
            onBindingValue={onTextChangeHandler}
            label='Reference'
            placeholder='Enter Reference'
            name='reference'
            type='input'
            value={!!transactionFilter?.reference ? transactionFilter?.reference : ''}
            maxRows={4}
          />

          <DropDown
            key={'acquirerName'}
            name={'acquirerName'}
            value={!!transactionFilter?.acquirerName ? transactionFilter?.acquirerName : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'Acquirer'}
            keyValuePair={transactionAcquirerName}
            isMandatory={false}
          />

          <DropDown
            key={'binRange'}
            name={'binRange'}
            value={!!transactionFilter?.binRange ? transactionFilter?.binRange : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'BIN Range'}
            keyValuePair={binRangeListName}
            isMandatory={false}
          />

          <CustomTextField
            isMandatory={false}
            maxCharLength={19}
            key='pan'
            onBindingValue={onTextChangeHandler}
            label='PAN'
            placeholder='Enter PAN'
            name='pan'
            type='input'
            value={!!transactionFilter?.pan ? transactionFilter?.pan : ''}
            maxRows={4}
          />

          <DropDown
            key={'cardType'}
            name={'cardType'}
            value={!!transactionFilter?.cardType ? transactionFilter?.cardType : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label={'Card Type'}
            keyValuePair={cardType}
            isMandatory={false}
          />
        </div>

        <div className='card-tag-filter-container'>
          <span className='card-tag-filter-title'>
            Card Tags
            <CustomTooltip title={'Card Tags are case sensitive.'} />
          </span>
          {transactionFilter?.tags &&
            transactionFilter?.tags?.length > 0 &&
            transactionFilter?.tags?.map((it, i) => {
              return (
                <div className='card-tag-filter-container' key={i}>
                  <div className='card-tag-filter-item'>
                    <div className='card-tag-filter-item-input'>
                      <TextFieldWithSuggestions
                        name={`key${i}`}
                        placeholder='Enter Tag Key'
                        value={it?.key}
                        dataList={
                          selectedBINRange?.cardTags?.map((item) => {
                            return { key: item?.key, value: item?.key } as KeyValuePair;
                          })
                            ? selectedBINRange?.cardTags
                                ?.filter((cardTag) => !transactionFilter.tags.some((tag) => tag?.key === cardTag?.key))
                                ?.concat({ key: it?.key, value: it?.value } as CardTagModel)
                                ?.filter((item) => item?.key)
                                ?.map((item) => {
                                  return item.key;
                                })
                            : selectedBINRange?.id
                              ? []
                              : binRangeList
                                  ?.flatMap((item) => item?.cardTags?.map((tag) => tag?.key) || [])
                                  ?.filter((key, index, self) => key && self.indexOf(key) === index)
                        }
                        maxHeight={192}
                        onBindingValue={onTextChangeHandler}
                        onTextBindingValue={onTextChangeHandler}
                        validateCounter={validateCounterFlag}
                        noOptionsText={'No Suggestion Card Tags Found'}
                        isSelectFromList={true}
                      />
                      <CustomTextField
                        validateCounter={validateCounterFlag}
                        key={`value${i}`}
                        onBindingValue={onTextChangeHandler}
                        placeholder='Enter Value'
                        name={`value${i}`}
                        value={it?.value}
                        type='string'
                        maxCharLength={50}
                      />
                    </div>

                    <div className='remove-item-container'>
                      <CustomButton buttonStyle={ButtonStyle.DELETE} onClick={() => handleRemoveClick(it?.key, i)}>
                        x
                      </CustomButton>
                    </div>
                  </div>
                  {i !== transactionFilter?.tags?.length - 1 && (
                    <div className='card-tag-filter-and-indicator'> And</div>
                  )}
                </div>
              );
            })}

          {(!transactionFilter?.tags || transactionFilter?.tags?.length < 5) && (
            <div className='add-card-tag-container'>
              <Link className='add-card-tag' underline='hover' onClick={handleAddTagClick}>
                + Add Card Tag
              </Link>
            </div>
          )}
        </div>
      </MainModal>
    </>
  );
};

export default TransactionFilter;
