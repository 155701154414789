import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { TerminalOperationActionModel } from '../../../models/terminalOperationModel';
import { TerminalModel } from '../../../models/terminalModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { RootState } from '../../../store/rootReducers';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectSelectedTerminal } from '../../../store/terminal/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as headerActions from '../../../store/header/actions';
import * as terminalAction from '../../../store/terminal/actions';
import * as terminalOperationActions from '../../../store/terminal-operations/actions';
import * as modalActions from '../../../store/modals/actions';
import TerminalOperation from './terminal-operation';

const mapStateToProps = (state: RootState) => {
  return {
    hasValidationError: selectHasValidationError(state),
    terminalInfoModel: selectSelectedTerminal(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    terminalOpeationActions: (data: TerminalOperationActionModel) =>
      dispatch({
        type: terminalOperationActions.TERMINAL_OPERATION_ACTION,
        payload: data,
      }),

    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),

    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
    setSelectedTerminalItem: (data: TerminalModel) =>
      dispatch({ type: terminalAction.SET_SELECTED_TERMINAL, payload: data }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const TerminalOperationPage = connect(mapStateToProps, mapDispatchToProps)(TerminalOperation);

export default TerminalOperationPage;
