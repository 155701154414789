export const Country_List = [
  {
    name: 'Australia',
    code: 'AU',
    timezone: 'AUS Eastern Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+61',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    timezone: 'New Zealand Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+64',
  },
];

export const countryCodeWithTimeZoneList = [
  {
    countryCode: 'NZ',
    timeZones: ['Pacific/Auckland', 'Pacific/Chatham'],
  },
  {
    countryCode: 'AU',
    timeZones: [
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Currie',
      'Australia/Melbourne',
      'Australia/Sydney',
      'Australia/Broken_Hill',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Perth',
      'Australia/Eucla',
    ],
  },
];
