import { LoadingStatus } from './../../constants/loading-constants';
import { initialState } from "./headerInitialState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';

const headerSlice = createSlice({
    name: 'headerState',
    initialState,
    reducers:{
        setHeaderState: (state, action: PayloadAction<string>) => {
            state.hasError = false
            state.headerState = action.payload;
        },
        setHeaderConfiguration: (state, action: PayloadAction<HeaderStateModel>) =>{
            state.hasError = false;
            state.headerConfiguration = action.payload;
        },      
        setHeaderError: (state) =>{
            state.hasError = true
            state.headerState = LoadingStatus.ERROR;
        }
    }
});

export const {
    setHeaderState,
    setHeaderConfiguration,
    setHeaderError
} = headerSlice.actions;

export default headerSlice.reducer;