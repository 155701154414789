import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import CollapsibleCard from '../../components/collapsible-card/collapsible-card';
import { HealthStatusDisplayType } from '../../constants/status';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { ForecourtControllerDetailModel, ForecourtControllerRecordModel } from '../../models/forecourtControllerModel';
import { getHealthStatusIndicator } from '../../utilities/field-helper';
import { getStatusMessageCount } from '../../utilities/general-helpers';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/forecourt-controllers.scss';
import './styles/forecourt-controller-item.scss';

interface ForecourtControllerItemProps {
  id: string;
  forecourtControllerItem: ForecourtControllerRecordModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onDelete: (id: string, name: number) => void;
  onEdit: (id: string) => void;
  onClick: (id: string) => void;
}

const ForecourtControllerItem: React.FC<ForecourtControllerItemProps> = (props: ForecourtControllerItemProps) => {
  const { id, forecourtControllerItem, hasDeleteAccess, hasUpdateAccess, onDelete, onEdit, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let actionItems: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit Forecourt Controller',
      handler: () => {
        onEdit(id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete Forecourt Controller',
      handler: () => {
        onDelete(id, forecourtControllerItem.number);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const fixedItemDetails: ForecourtControllerDetailModel[] = [
    {
      name: 'Status',
      value: fieldMappingHelper.getDisplayValue(forecourtControllerItem.status, HealthStatusDisplayType),
      status: forecourtControllerItem.status,
      healthIndicator: getHealthStatusIndicator(forecourtControllerItem.status),
    },
    {
      name: 'Last Status Update',
      value: forecourtControllerItem.lastUpdatedDateTimeUtc,
      healthIndicator: 'display-none',
    },
  ];

  const StatusDetail = () => {
    return (
      <span>
        {forecourtControllerItem?.messages &&
          forecourtControllerItem?.messages?.length > 0 &&
          getStatusMessageCount(forecourtControllerItem?.messages)}
      </span>
    );
  };

  return (
    <>
      <Card className={classNames('box-card-parent')}>
        <Box className={classNames('primary-details')}>
          <Item
            className={classNames('box-row-columns', 'forecourt-controller-item__primary-details')}
            onClick={onItemClick}
          >
            <Item className={classNames('box-row-item-header')}>
              <div className={classNames('icon-name-header')}>
                <Item className={classNames('list-icon-size', 'img-size-mob', 'forecourt-controller-icon-img')}></Item>
                <div>
                  <Item className={classNames('list-column-header', 'item-header-name')}>
                    {forecourtControllerItem?.number || 'Unspecified'}
                  </Item>
                  <Item className={classNames('list-column-content', 'item-primary-header-name')}>
                    Forecourt Controller
                  </Item>
                </div>
              </div>
            </Item>
            <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
              <div>
                <Item className={classNames('list-column-header', 'item-primary-header-name')}>
                  {fixedItemDetails[0].name}
                </Item>
                <Item className={classNames('list-column-content')}>
                  <Item className={classNames('status-icon', fixedItemDetails[0].healthIndicator)} />
                  {fieldMappingHelper.getDisplayValue(forecourtControllerItem.status, HealthStatusDisplayType)}
                </Item>
              </div>
            </Item>
            <Item className={classNames('item-primary-header', 'item-primary-header-tablet')}>
              <div>
                <Item className={classNames('list-column-header', 'item-primary-header-name')}>Last Status Update</Item>
                <Item className={classNames('list-column-content')}>{fixedItemDetails[1].value}</Item>
              </div>
            </Item>
          </Item>
          {displayMenu ? (
            <Item className={classNames('row-item-ellipse')}>
              <FloatingMenu
                key={forecourtControllerItem.id}
                buttonNode={<MoreVertIcon className='ellipse-icon' />}
                items={actionItems}
              />
            </Item>
          ) : (
            <Box className='box-item' onClick={onItemClick}></Box>
          )}
        </Box>
        <CollapsibleCard
          id={forecourtControllerItem?.id}
          fixeditemDetails={fixedItemDetails}
          rowViewItemDetails={forecourtControllerItem?.messages}
          showMoreDetailText={
            forecourtControllerItem?.messages && forecourtControllerItem?.messages?.length > 0 && StatusDetail()
          }
          onClick={onItemClick}
        ></CollapsibleCard>
      </Card>
    </>
  );
};

export default ForecourtControllerItem;
