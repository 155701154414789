import React from 'react';
import classNames from 'classnames';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import { Box } from '@mui/material';
import Item from '../../components/box-items/box-item';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '../../components/avatar/avatar';

import './styles/organisation.scss';

interface OrganisationItemProps {
  name: string;
  id: string;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClickHandler: (id: string, name: string) => void;
  allowDelete: boolean;
}

const OrganisationItem: React.FC<OrganisationItemProps> = (
  props: OrganisationItemProps
) => {
  const { name, id, onDelete, onEdit, onClickHandler, allowDelete } = props;

  const items = [
    {
      label: 'Edit Organisation',
      handler: () => {
        onEdit(id);
      },
    },
  ];

  if (allowDelete) {
    items.push({
      label: 'Delete Organisation',
      handler: () => {
        onDelete(id, name);
      },
    });
  }

  const onItemClick = () => {
    if (onClickHandler) {
      onClickHandler(id, name);
    }
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-grid')}>
        <Box className='orgs-card' onClick={onItemClick}>
          <Item className='box-avatar'>
            <Avatar value={name} displayValue={name} />
          </Item>
          <Item className='box-main'>{name}</Item>
        </Box>
        <Item className={classNames('ellipsis')}>
          <FloatingMenu key={id} buttonNode={<MoreVertIcon />} items={items} />
        </Item>
      </Card>
    </React.Fragment>
  );
};

export default OrganisationItem;
