import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Header } from '../../../models/receiptTemplateModel';
import { SiteConfigurationModel } from '../../../models/siteModel';
import { ButtonStyle } from '../../../constants/button-constants';
import CustomTextField from '../../../components/text-field/text-field.container';
import Item from '../../../components/box-items/box-item';
import CustomButton from '../../../components/button/custom-button';
import TextFieldWithSuggestions from '../../../components/text-field-with-suggestions/text-field-with-suggestions.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import '../styles/receipt-template-headers.scss';

interface ReceiptTemplateHeadersConfigurationProps {
  validateCounter: number;
  headers: Header[];
  readOnly?: boolean;
  siteConfigurationItem: SiteConfigurationModel;
  removeValidation: (name: string) => void;
  onBindingValue?: (newvalue: Header[]) => void | undefined;
}

const ReceiptTemplateHeadersConfiguration: React.FC<ReceiptTemplateHeadersConfigurationProps> = (
  props: ReceiptTemplateHeadersConfigurationProps
) => {
  const location = useLocation();
  const { validateCounter, headers, readOnly, siteConfigurationItem, removeValidation, onBindingValue } = props;
  const [headersValue, setHeadersValue] = useState(headers);
  const [validationFlag, setValidationFlag] = useState(0);
  const [preparedHeaders, setPreparedHeaders] = useState([] as Header[]);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setHeadersValue(headers);
  }, [headers, setHeadersValue]);

  function splitAndCombineString(str: string) {
    const parts = str.split(', ');
    const combinedArray = [];

    let combinedString = '';
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (combinedString.length + part.length <= 30) {
        combinedString += (combinedString.length > 0 ? ', ' : '') + part;
      } else {
        combinedArray.push(combinedString);
        combinedString = part;
      }
    }

    if (combinedString.length > 0) {
      combinedArray.push(combinedString);
    }
    return combinedArray;
  }

  useEffect(() => {
    if (siteConfigurationItem) {
      const siteNameHeader = {
        text: siteConfigurationItem.name,
      } as Header;
      let list = [siteNameHeader];
      if (siteConfigurationItem.location.address.freeformAddress.length > 30) {
        const addressList = splitAndCombineString(siteConfigurationItem.location.address.freeformAddress);
        addressList.map((it) => list.push({ text: it } as Header));
      } else {
        list.push({ text: siteConfigurationItem.location.address.freeformAddress } as Header);
      }
      setPreparedHeaders(list);
      if (location.pathname.toLocaleLowerCase().endsWith('create') && onBindingValue) {
        onBindingValue(list);
      }
    }
  }, [location, siteConfigurationItem]);

  const handleAddClick = () => {
    setValidationFlag(0);
    let HeaderItem = {
      text: '',
    } as Header;
    let list = [HeaderItem];
    if (headersValue !== undefined) {
      list = [...headersValue];
      list.push(HeaderItem);
    }

    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const handleRemoveClick = (index: number) => {
    resetPriceListValidation();

    let list = [...headersValue];
    list.splice(index, 1);

    setHeadersValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetPriceListValidation = () => {
    headersValue.forEach((_, index) => {
      removeValidation(`header${index}`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let headerItems = [...headersValue];
    if (!!headerItems[index]) {
      let item = { ...headerItems[index] };
      item.text = !!newvalue.value ? newvalue.value.toString() : '';
      headerItems[index] = item;
    }
    setHeadersValue(headerItems);

    if (onBindingValue) {
      onBindingValue(headerItems);
    }
  };

  return (
    <React.Fragment>
      <div className='receipt-template-header-container'>
        <div className={`${readOnly ? 'receipt-templates-header-header-readonly' : 'receipt-templates-header-header'}`}>
          Headers
        </div>
        {readOnly && (headers?.length <= 0 || !headers) && (
          <div className='headers-empty-msg'>No headers found for this receipt template.</div>
        )}
        {!!headersValue &&
          headersValue.map((item, i) => {
            return (
              <Item key={i}>
                {!!headers && (
                  <div className={readOnly ? 'template-form-readonly-headers' : 'template-form-headers'}>
                    {readOnly ? (
                      <CustomTextField
                        className='read-only-text-field-container'
                        key={`header${i}`}
                        placeholder={'Enter Header'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type='input'
                        name={`header${i}`}
                        value={item.text}
                        validateCounter={validationFlag}
                        maxCharLength={30}
                        readOnly={readOnly}
                      ></CustomTextField>
                    ) : (
                      <TextFieldWithSuggestions
                        name={`header${i}`}
                        placeholder='Type to add header or select from dropdown'
                        value={item.text}
                        dataList={preparedHeaders
                          .filter(
                            (it) =>
                              !headers
                                .map((selected) => {
                                  return selected.text;
                                })
                                .includes(it.text)
                          )
                          .map((it) => {
                            return it.text;
                          })}
                        maxHeight={192}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        onTextBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        isMandatory={true}
                        readOnly={readOnly}
                        validateCounter={validationFlag}
                        noOptionsText={''}
                        isSelectFromList={true}
                        maxCharLength={30}
                      />
                    )}
                    {!readOnly && headersValue?.length >= 1 && (
                      <div className='remove-header-container'>
                        <CustomButton buttonStyle={ButtonStyle.DELETE} onClick={() => handleRemoveClick(i)}>
                          x
                        </CustomButton>
                      </div>
                    )}
                  </div>
                )}
              </Item>
            );
          })}
        {!readOnly && (!headersValue || headersValue?.length < 7) && (
          <div className='add-header-container'>
            <Link className='add-header' underline='hover' onClick={handleAddClick}>
              + Add Header
            </Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReceiptTemplateHeadersConfiguration;
