import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Strapping, StrappingPoint, TankGauge } from '../../../models/tankModel';
import { FileUploader } from '../../../components/file-uploader/file-uploader';
import { Messages } from '../../../constants/messages';
import LineChart from '../../../components/line-chart/line-chart';
import EditableTable from '../../../components/editable-table/editable-table.container';

import '../styles/strapping-table.scss';

interface StrappingTableProps {
  gauges: TankGauge[];
  readOnly?: boolean;
  specificChannel?: number;
  onBindingValue?: (newvalue: Strapping, gaugeIndex: number) => void | undefined;
}

const StrappingTable: React.FC<StrappingTableProps> = (props: StrappingTableProps) => {
  const { gauges, readOnly, specificChannel, onBindingValue } = props;
  const [currentGaugeIndex, setCurrentGaugeIndex] = useState(specificChannel ? specificChannel : 0);
  const [tableData, setTableData] = useState([
    {
      sensorReading: 0,
      correspondingValue: 0,
    },
  ]);
  const [lineChartData, setLineChartData] = useState({
    datasets: [
      {
        label: 'Product Volume',
        data: tableData?.map((it) => {
          return {
            x: it.sensorReading,
            y: it.correspondingValue,
          };
        }),
        borderColor: '#fff',
        lineTension: 0.1,
        pointRadius: 1,
        pointHoverRadius: 2,
      },
    ],
  } as any);
  const [minXaxis, setMinXaxis] = useState(0 as number | undefined);
  const [maxXaxis, setMaxXaxis] = useState(1 as number | undefined);
  const [options, setOptions] = useState({
    // Turn off animations and data parsing for performance
    animation: false,
    parsing: false,
    spanGaps: false,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        ticks: {
          color: '#fff',
          maxTicksLimit: 6,
        },
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          borderDashOffset: 0,
          borderColor: '#fff',
          color: '#fff',
        },
      },
      x: {
        type: 'linear' as const,
        min: minXaxis,
        max: maxXaxis,
        time: 4,
        display: true,
        ticks: {
          maxTicksLimit: 5,
          color: '#fff',
        },
        border: {
          color: '#fff',
        },
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          borderDashOffset: 0,
          borderColor: '#fff',
          color: '#fff',
        },
      },
    },
  } as any);

  const fieldsArr = [
    {
      label: 'Height (mm)',
      name: 'height',
      maxCharLength: 10,
      minCharLength: 1,
    },
    {
      label: 'Volume (L)',
      name: 'volume',
      maxCharLength: 10,
      minCharLength: 1,
    },
  ];

  const defaultData = [
    {
      type: 'default',
      value: '',
    },
  ];

  const findMinSensorReading = (array: StrappingPoint[]): number | undefined => {
    if (array && array?.length > 1) {
      let minReading = array[0]?.sensorReading;
      for (let i = 1; i < array.length; i++) {
        if (array[i].sensorReading < minReading) {
          minReading = array[i].sensorReading;
        }
      }
      return minReading;
    } else return 0;
  };

  const findMaxSensorReading = (array: StrappingPoint[]): number | undefined => {
    if (array && array?.length > 0) {
      let maxReading = array[0]?.sensorReading;
      for (let i = 1; i < array.length; i++) {
        if (array[i].sensorReading > maxReading) {
          maxReading = array[i].sensorReading;
        }
      }
      return maxReading;
    }
  };

  useEffect(() => {
    setMinXaxis(findMinSensorReading(tableData));
    setMaxXaxis(findMaxSensorReading(tableData));
    setLineChartData({
      datasets: [
        {
          label: 'Product Volume',
          data: tableData
            ?.map((it) => {
              return {
                x: it.sensorReading,
                y: it.correspondingValue,
              };
            })
            .sort((a, b) => a.x - b.x),
          borderColor: '#fff',
          lineTension: 0.1,
          pointRadius: 1,
          pointHoverRadius: 2,
        },
      ],
    });
  }, [tableData]);

  useEffect(() => {
    if (specificChannel !== undefined && specificChannel > -1) setCurrentGaugeIndex(specificChannel);
  }, [specificChannel]);

  useEffect(() => {
    setOptions({
      animation: false,
      parsing: false,
      spanGaps: false,
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          type: 'linear' as const,
          display: true,
          position: 'left' as const,
          title: {
            display: true,
            text: 'Product Volume (L)',
            color: '#fff',
            font: {
              family: 'Mulish',
              size: 14,
            },
          },
          ticks: {
            color: '#fff',
            maxTicksLimit: 6,
          },
          border: {
            color: '#fff',
            dash: [2, 4],
          },
          grid: {
            display: true,
            drawBorder: false,
            drawOnChartArea: true,
            borderDashOffset: 0,
            borderColor: '#fff',
            color: '#fff',
          },
        },
        x: {
          type: 'linear' as const,
          min: minXaxis,
          max: maxXaxis,
          time: 4,
          display: true,
          title: {
            display: true,
            text: 'Product Height (mm)',
            color: '#fff',
            font: {
              family: 'Mulish',
              size: 14,
            },
          },
          ticks: {
            maxTicksLimit: 5,
            color: '#fff',
          },
          border: {
            color: '#fff',
            dash: [2, 4],
          },
          grid: {
            display: true,
            drawBorder: false,
            drawOnChartArea: true,
            borderDashOffset: 0,
            borderColor: '#fff',
            color: '#fff',
          },
        },
      },
    });
  }, [minXaxis, maxXaxis]);

  useEffect(() => {
    if (gauges && gauges?.length > 0) {
      let newTableData = gauges[currentGaugeIndex]?.strapping?.table?.map((it: any) => {
        return {
          sensorReading: it?.sensorReading,
          correspondingValue: it?.correspondingValue,
        };
      });
      setTableData(newTableData);
    }
  }, [gauges, currentGaugeIndex]);

  const tableDataUpdateHandler = (row: any, tableInfo?: string | number) => {
    if (row) {
      let newTableData = row?.map((it: any) => {
        return {
          sensorReading: it?.height ? Number(it?.height) : '',
          correspondingValue: it?.volume ? Number(it?.volume) : '',
        };
      });
      setTableData(newTableData);
      if (onBindingValue) {
        onBindingValue(
          {
            sensorReadingUnitOfMeasure: 'millimetre',
            correspondingValueUnitOfMeasure: 'litre',
            table: newTableData,
          },
          currentGaugeIndex
        );
      }
    }
  };

  const localDataHandler = (data: any) => {
    const results = [];
    const lines = data.split('\n');
    // Assuming the first line contains headers, so skip it
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim(); // Remove leading/trailing whitespace
      if (line) {
        const values = line.split(','); // Split the line into values by comma
        const height = parseInt(values[0]?.trim()); // Parse height as integer
        const volume = parseInt(values[1]?.trim()); // Parse volume as integer
        // Push the parsed values into the results array
        results.push({
          sensorReading: height,
          correspondingValue: volume,
        });
      }
    }
    setTableData(results);
    if (onBindingValue) {
      onBindingValue(
        {
          sensorReadingUnitOfMeasure: 'millimetre',
          correspondingValueUnitOfMeasure: 'litre',
          table: results,
        },
        currentGaugeIndex
      );
    }
  };
  return (
    <>
      <Grid container spacing={2} className='strapping-table-form'>
        <Grid item md={12} lg={5}>
          <FileUploader
            isMandatory={false}
            readOnly={readOnly}
            isDataUploadFromLocal={true}
            dataFromLocalFile={localDataHandler}
            templateData={'height,volume \r\n10,100'}
            templateFileName='Strapping Table Template'
            infoHelper={
              'Please download the template below and fill it with your data, or you could enter the data by using the table below.'
            }
            componentLabel='Click or drag to upload'
          ></FileUploader>
          <EditableTable
            key={'strapping-table'}
            name='editable-table'
            isMandatory={true}
            initWithoutHead
            defaultData={defaultData}
            tableData={tableData}
            tableDataUpdate={tableDataUpdateHandler}
            fieldsArr={fieldsArr}
            readOnly={readOnly}
            noDuplicateKey='height'
            noDuplicateValue='volume'
            duplicateErrorMsg={Messages.DUPLICATE_HEIGHT_VALUES_IN_STRAPPING_TABLE_MSG}
          />
        </Grid>
        <Grid item md={0} lg={1}></Grid>
        <Grid item md={12} lg={6}>
          <LineChart data={lineChartData} options={options} />
        </Grid>
      </Grid>
    </>
  );
};

export default StrappingTable;
