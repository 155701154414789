import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface SystemUpdateIconProps {
  className?: string;
}

const SystemUpdateIcon = (props: SystemUpdateIconProps) => {
  return <SvgIcon className={`base-icon system-update-icon  ${props.className}`} />;
};

export default SystemUpdateIcon;
