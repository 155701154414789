import { LoadingStatus } from '../../constants/loading-constants';
import { ForecourtControllerModel, ForecourtControllerRecordModel } from '../../models/forecourtControllerModel';

interface ForecourtControllerState {
  forecourtControllerStatus: string;
  forecourtControllerDetailStatus: string;
  forecourtControllerContent: ForecourtControllerRecordModel[];
  forecourtControllerItem: ForecourtControllerModel;
  hasError: boolean;
}

export const initialState: ForecourtControllerState = {
  forecourtControllerStatus: LoadingStatus.LOADING,
  forecourtControllerDetailStatus: LoadingStatus.LOADING,
} as ForecourtControllerState;
