import { ActivityLogRequest } from '../../entities/activity-log';
import api, { setAuthorize } from '../../utilities/api';
import { convertObjectToQueryString } from '../../utilities/querystring-helper';

const ACTIVITYLOG_API_PREFIX = 'activity-log-management';

export const getActivityLogsData = async (request: ActivityLogRequest) => {
  const qsRequest = convertObjectToQueryString(request);
  await setAuthorize();
  let result = await api.get(`/${ACTIVITYLOG_API_PREFIX}/activity-logs?${qsRequest}`);
  return result.data;
};

export const getActivityLogResourceTypes = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${ACTIVITYLOG_API_PREFIX}/activity-logs/resource-types?organisationId=${organisationId}`);
  return result.data;
};
