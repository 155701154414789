import { PriceSchedListErrorModel, PriceScheduleInfoModel } from '../../models/priceScheduleModel';
import { LoadingStatus } from '../../constants/loading-constants';

interface PriceScheduleState {
  priceSchedStatus: string;
  priceSchedDetailStatus: string;
  priceSchedContent: PriceScheduleInfoModel[];
  priceSchedInfo: PriceScheduleInfoModel;
  hasError: boolean;
  priceSchedListError: PriceSchedListErrorModel[];
}

export const initialState: PriceScheduleState = {
  priceSchedStatus: LoadingStatus.LOADING,
  priceSchedDetailStatus: LoadingStatus.LOADING,
} as PriceScheduleState;
