import { LoadingStatus } from '../../constants/loading-constants';
import { initialState } from './page-configInitstate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const pageSlice = createSlice({
  name: 'PageConfigurationState',
  initialState,
  reducers: {
    setPageConfigurationState: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.pageConfigurationState = action.payload;
    },
    setPageConfiguration: (state, action: PayloadAction<PageSettingStateModel>) => {
      state.hasError = false;
      state.pageConfiguration = action.payload;
    },
    setPageError: (state) => {
      state.hasError = true;
      state.pageConfigurationState = LoadingStatus.ERROR;
    },
    setIsPageDirty: (state, action: PayloadAction<boolean>) => {
      state.isPageDirty = action.payload;
    },
    setIsPageReachBottom: (state, action: PayloadAction<boolean>) => {
      state.isPageReachBottom = action.payload;
    },
    setIsCancelFromChange: (state, action: PayloadAction<boolean>) => {
      state.isCancelFromChange = action.payload;
    },
  },
});

export const {
  setPageConfigurationState,
  setPageConfiguration,
  setPageError,
  setIsPageDirty,
  setIsPageReachBottom,
  setIsCancelFromChange,
} = pageSlice.actions;

export default pageSlice.reducer;
