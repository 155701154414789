import React, { forwardRef, useEffect, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Step, Stepper } from '@mui/material';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import FilterOption from './filter-option';
import './search-bar.scss';

export interface SearchBarProps {
  placeholder: string;
  filterDetails?: FilterDetail[];
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFilterValueChange?: (data: KeyValuePair[]) => void;
  onClearFilter?: () => void;
}

const SearchBar = forwardRef((props: SearchBarProps, ref) => {
  const { placeholder, filterDetails, onKeyUp, onFilterValueChange, onClearFilter } = props;
  const [filterDetailValue, setFilterDetailValue] = useState(filterDetails);
  const [selectedFilterValue, setSelectedFilterValue] = useState([] as KeyValuePair[]);
  const [inputSearch, setInputSearch] = useState('');
  const [groupFilterDetailValue, setGroupFilterDetailValue] = useState([] as any[]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isToggleFilterOption, setIsToggleFilterOption] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsToggleFilterOption(true);
    return;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsToggleFilterOption(true);
    return;
  };

  const handleNewFilter = (data: KeyValuePair) => {
    if (isToggleFilterOption) return;
    const filteredArray = selectedFilterValue?.filter((item) => item.value !== 'default');
    const selectedFilterIndex = filteredArray?.findIndex((it) => it.key === data.key);
    if (selectedFilterIndex && selectedFilterIndex <= -1) {
      const newFilters = selectedFilterValue?.concat(data);
      setSelectedFilterValue(newFilters?.filter((item) => item.value !== 'default'));
    } else if (selectedFilterIndex >= 0) {
      const removeFilter: KeyValuePair[] | undefined = selectedFilterValue?.filter((item) => item.key !== data.key);
      const newFilters = removeFilter?.concat(data);
      setSelectedFilterValue(newFilters?.filter((item) => item.value !== 'default'));
    }
  };

  const handleClearFilter = () => {
    if (selectedFilterValue && selectedFilterValue?.length <= 0 && inputSearch.length <= 0) return;
    else {
      setInputSearch('');
      setSelectedFilterValue([]);
      if (onClearFilter) onClearFilter();
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const onFilterClickHanlder = (data: boolean) => {
    if (data) {
      setIsToggleFilterOption(false);
    } else setIsToggleFilterOption(true);
  };

  useEffect(() => {
    if (selectedFilterValue?.filter((it) => it?.value !== 'default')?.length > 0 && onFilterValueChange) {
      onFilterValueChange(selectedFilterValue);
    }
  }, [selectedFilterValue]);

  useEffect(() => {
    let groupFilterDetails = [] as any[];

    if (filterDetails && setFilterDetailValue) setFilterDetailValue(filterDetails);
    if (filterDetails) {
      groupFilterDetails.push([filterDetails[0]]);
      for (let i = 1; i < filterDetails.length; i += 2) {
        const group = filterDetails.slice(i, i + 2);
        groupFilterDetails.push(group);
      }
      if (filterDetails.length % 2 === 1) {
        groupFilterDetails.push([filterDetails[filterDetails.length - 1]]);
      }
      setGroupFilterDetailValue(groupFilterDetails);
    }
  }, [filterDetails, filterDetailValue]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 768) {
        setActiveStep(0);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <div className='search-bar'>
      {activeStep <= 0 && (
        <>
          <SearchIcon />
          <InputBase
            className='input-base'
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            inputRef={ref}
            onChange={onChange}
            value={inputSearch}
          />
        </>
      )}
      <div className='filter-options'>
        {filterDetailValue && filterDetailValue?.length > 0 && (
          <div className='filter-option-desktop'>
            {filterDetailValue?.map((it) => {
              return (
                <FilterOption
                  filterDetail={it}
                  handleNewFilter={handleNewFilter}
                  onFilterClick={onFilterClickHanlder}
                />
              );
            })}
          </div>
        )}
        {
          <Stepper className='filter-option-mobile' activeStep={activeStep}>
            {groupFilterDetailValue && (
              <Step key={activeStep}>
                {groupFilterDetailValue[activeStep]?.map((it: any) => {
                  return (
                    <FilterOption
                      filterDetail={it}
                      handleNewFilter={handleNewFilter}
                      onFilterClick={onFilterClickHanlder}
                    />
                  );
                })}
              </Step>
            )}
          </Stepper>
        }
        {filterDetails && filterDetails?.length > 1 && (
          <React.Fragment>
            <div className='filter-toggle-options'>
              <Button className='filter-back-button' color='inherit' disabled={activeStep === 0} onClick={handleBack}>
                {'<'}
              </Button>
              <Button className='filter-next-button' color='inherit' disabled={activeStep === 1} onClick={handleNext}>
                {'>'}
              </Button>
            </div>
          </React.Fragment>
        )}
        {filterDetailValue && filterDetailValue?.length > 0 && (
          <div
            className={`${
              (selectedFilterValue && selectedFilterValue?.length > 0) || inputSearch.length > 0
                ? 'selected'
                : 'unselected'
            }-clear-filter-button`}
            onClick={handleClearFilter}
          >
            X
          </div>
        )}
      </div>
    </div>
  );
});

export default SearchBar;
