import { ReceiptTemplateItemModel } from '../../models/receiptTemplateModel';
import { LoadingStatus } from '../../constants/loading-constants';

interface ReceiptTemplateState {
  receiptTemplateStatus: string;
  receiptTemplateContent: ReceiptTemplateItemModel[];
  receiptTemplateItem: ReceiptTemplateItemModel;
  hasError: boolean;
}

export const initialState: ReceiptTemplateState = {
  receiptTemplateStatus: LoadingStatus.LOADING,
} as ReceiptTemplateState;
