import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { ReceiptTemplateItemModel } from '../../../models/receiptTemplateModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import {
  selectReceiptTemplateContent,
  selectReceiptTemplateItem,
  selectReceiptTemplateStatus,
} from '../../../store/receipt-template/selectors';
import { selectBinRangeData, selectBinRangeList, selectBinRangeNameList } from '../../../store/bin-ranges/selectors';
import { selectSiteConfiguration } from '../../../store/sites/selectors';
import * as actions from '../../../store/receipt-template/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as binRangeActions from '../../../store/bin-ranges/actions';
import * as siteActions from '../../../store/sites/actions';
import * as modalActions from '../../../store/modals/actions';
import ReceiptTemplate from './receipt-template';

const mapStateToProps = (state: RootState) => {
  return {
    receiptTemplates: selectReceiptTemplateContent(state),
    receiptTemplateItem: selectReceiptTemplateItem(state),
    receiptTemplateStatus: selectReceiptTemplateStatus(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    binRangeList: selectBinRangeList(state),
    selectBinRangeData: selectBinRangeData(state),
    binRangeListName: selectBinRangeNameList(state),
    siteConfigurationItem: selectSiteConfiguration(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadReceiptTemplates: () => dispatch({ type: actions.LOAD_RECEIPTTEMPLATES }),
    loadReceiptTemplateInfo: (id: string) => dispatch({ type: actions.LOAD_RECEIPTTEMPLATE_INFO, payload: id }),
    loadBinRangeList: () => dispatch({ type: binRangeActions.LOAD_BIN_RANGE_LIST }),
    loadBinRangeNameList: () => dispatch({ type: binRangeActions.LOAD_BIN_RANGE_NAME_LIST }),
    loadSiteConfiguration: (id?: string) => dispatch({ type: siteActions.SITE_CONFIGURATION, payload: id }),
    createReceiptTemplate: (data: ReceiptTemplateItemModel) =>
      dispatch({ type: actions.CREATE_RECEIPTTEMPLATE, payload: data }),
    editReceiptTemplate: (data: ReceiptTemplateItemModel) =>
      dispatch({ type: actions.EDIT_RECEIPTTEMPLATE, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
  };
};

const ReceiptTemplatePage = connect(mapStateToProps, mapDispatchToProps)(ReceiptTemplate);

export default ReceiptTemplatePage;
