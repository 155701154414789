import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import './box-items.scss';

interface BoxItemProps {
  className?: string;
  children?: React.ReactNode;  
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const BoxItem: React.FC<BoxItemProps> = (props: BoxItemProps) => {
  const { className, onClick } = props;
  return (
    <Box onClick={onClick} className={classNames(className)} data-testid="test-box-item">
      {props.children}
    </Box>
  );
};

export default BoxItem;
