import {
  TRANSACTION_EXPORT_COLUMN_STORAG_KEY,
  TRANSACTION_EXPORT_FILTER_STORAG_KEY,
} from '../../constants/transaction-constants';
import { TransactionReportColumn } from '../../models/reportModel';
import { TransactionFilterModel } from '../../models/transactionModel';
import { GetDataFromLocalStorage } from '../../utilities/localStorage-helper';
import { selectAuthState } from '../auth/selectors';
import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectReportState = (state: RootState) => state.ReportState;

export const selectReportData = createSelector(selectReportState, (state) => state.reportData);

export const selectReportStatus = createSelector(selectReportState, (state) => state.reportStatus);

export const selectExportColumnsData = createSelector(selectReportState, (state) => state.exportColumnsData);

export const selectExportFilter = createSelector([selectReportState, selectAuthState], (state, authState) => {
  let transactionFilter: TransactionFilterModel = GetDataFromLocalStorage(
    `${TRANSACTION_EXPORT_FILTER_STORAG_KEY}-${authState.organisationId}-${authState.userId}`
  );

  if (transactionFilter === null || transactionFilter === undefined || !transactionFilter) {
    const currentDate = new Date();
    const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    firstDateOfMonth.setHours(0, 0, 0, 0);

    const nextMonth = currentDate.getMonth() + 1;
    const lastDateOfMonth = new Date(currentDate.getFullYear(), nextMonth, 0);
    lastDateOfMonth.setHours(23, 59, 59, 999);

    transactionFilter = {
      dateRange: 'thisMonth',
      startDateTime: String(firstDateOfMonth),
      endDateTime: String(lastDateOfMonth),
      type: 'Completion',
    } as TransactionFilterModel;
  }

  return transactionFilter;
});

export const selecteSelectedColumns = createSelector([selectReportState, selectAuthState], (state, authState) => {
  let transactionColumn: TransactionReportColumn[] = GetDataFromLocalStorage(
    `${TRANSACTION_EXPORT_COLUMN_STORAG_KEY}-${authState.organisationId}-${authState.userId}`
  );

  if (transactionColumn === null || transactionColumn === undefined || !transactionColumn) {
    transactionColumn = [
      {
        name: 'Date Time Site Local',
      },
      {
        name: 'Site Name',
      },
      {
        name: 'Reference',
      },
      {
        name: 'Pan',
      },
      {
        name: 'Product Name',
      },
      {
        name: 'Pump Number',
      },
      {
        name: 'Hose Number',
      },
      {
        name: 'Pump Amount',
      },
      {
        name: 'Pump Quantity',
      },
      {
        name: 'Pump Unit Price',
      },
      {
        name: 'State',
      },
      {
        name: 'Type',
      },
      {
        name: 'Response Text',
      },
    ];
  }

  return transactionColumn;
});
