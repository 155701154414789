import { LoadingStatus } from '../../constants/loading-constants';
import { AlertModel } from '../../models/alertModel';

interface AlertState {
  alertStatus: string;
  alertData: AlertModel[];
  alertTypes: string[];
  alertFuzzySearch: string;
  hasError: boolean;
}

export const initialState: AlertState = {
  alertStatus: LoadingStatus.LOADING,
} as AlertState;
