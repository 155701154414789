import { PriceScheduleInfoEntity } from '../../entities/priceSchedule';
import api, { setAuthorize } from '../../utilities/api';

const PRICE_SCHED_API_PREFIX = 'product-management';

export const getAllPriceScheduleData = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${PRICE_SCHED_API_PREFIX}/products/price-schedules?organisationId=${organisationId}`
  );
  return result.data;
};

export const getPriceScheduleById = async (
  id: string,
  organisationId: string
) => {
  await setAuthorize();
  let result = await api.get(
    `/${PRICE_SCHED_API_PREFIX}/products/price-schedules/${id}?organisationId=${organisationId}`
  );
  return result.data;
};

export const createPriceSchedule = async (data: PriceScheduleInfoEntity) => {
  await setAuthorize();
  await api.post(`/${PRICE_SCHED_API_PREFIX}/products/price-schedules`, data);
};

export const editPriceSchedule = async (
  data: PriceScheduleInfoEntity,
  id: string
) => {
  await setAuthorize();
  await api.put(
    `/${PRICE_SCHED_API_PREFIX}/products/price-schedules/${id}`,
    data
  );
};

export const deletePriceSchedule = async (
  id: string,
  organisationId: string
) => {
  await setAuthorize();
  await api.delete(
    `/${PRICE_SCHED_API_PREFIX}/products/price-schedules/${id}?organisationId=${organisationId}`
  );
};
