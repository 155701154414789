import {initialState} from './navPaneInitState'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NavDropdownModel } from '../../models/navItemModel';

const navPaneSlice = createSlice({
    name: 'navaPaneState',
    initialState,
    reducers: {
        setNavePaneState: (state, action:PayloadAction<string>) => {
            state.navPaneState = action.payload;
            state.hasError = false;
        },
        setNavPaneError: (state) => {
            state.hasError = false;
        },
        setSubMenuOpened: (state, action:PayloadAction<boolean>) => {
            state.subMenuOpened = action.payload;
            state.hasError = false;
        },
        setSelectedMenuIndex: (state, action: PayloadAction<number>) => {
            state.selectedMenuIndex = action.payload;
            state.hasError = false;
        },
        setSelectedSubMenuIndex: (state, action: PayloadAction<number>) => {
            state.selectedSubMenuIndex = action.payload;
            state.hasError = false;
        },
        setSelectedDropdownIndex: (state, action: PayloadAction<NavDropdownModel>) => {
            state.selectedDropdownMenuIndex = action.payload;
            state.hasError = false;
        },
        setSelectedActiveDropdown: (state, action: PayloadAction<number>) => {
            state.selectActiveDropdown = action.payload;
            state.hasError = false;
        },
        setSelectedMenu : (state, action: PayloadAction<string>) => {
            state.selectedMenu = action.payload;
        },
        
    }
});

export const {
    setSelectedSubMenuIndex,
    setSelectedMenuIndex,
    setNavePaneState,
    setNavPaneError,
    setSubMenuOpened,
    setSelectedDropdownIndex,
    setSelectedActiveDropdown,
    setSelectedMenu,
} = navPaneSlice.actions;

export default navPaneSlice.reducer;
