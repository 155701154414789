import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, IconButton, InputBase } from '@mui/material';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import MainModal from '../../../components/main-modal/modal.container';
import LoadingDisplay from '../../../components/loading-spinner/loading-display';
import BoxItem from '../../../components/box-items/box-item';
import { ModalType } from '../../../constants/modal-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { TerminalFileType } from '../../../constants/terminal-operation-constants';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import {
  TerminalDirectoryItemModel,
  TerminalDirectoryModel,
  TerminalOperationActionModel,
  TerminalOperationActionPostModel,
} from '../../../models/terminalOperationModel';
import { EnterIcon, FileIcon, FolderIcon } from '../../../components/icons';
import { formatSize } from '../../../utilities/general-helper';
import * as fieldMappingHelper from '../../../utilities/fieldMapping-helper';
import '../styles/file-explorer.scss';

interface FileExplorerProps {
  modalData: ModalStateModel;
  terminalDirectoryStatus: string;
  terminalDirectoryList: TerminalDirectoryModel[];
  hasValidationError: boolean;
  getTerminalDirectory: (data: TerminalOperationActionModel) => void;
  clearFileDirectory: () => void;
  closeModal: () => void;
  removeAllValidation: () => void;
  newPathHandler?: (path: string) => void;
}

const FileExplorer: React.FC<FileExplorerProps> = (props: FileExplorerProps) => {
  const {
    modalData,
    terminalDirectoryStatus,
    terminalDirectoryList,
    hasValidationError,
    getTerminalDirectory,
    clearFileDirectory,
    closeModal,
    removeAllValidation,
    newPathHandler,
  } = props;

  const { orgId, terminalId } = useParams();

  const [isTouched, setIsTouched] = useState(false);
  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);
  const [operationInfoValue, setOperationInfoValue] = useState({
    organisationId: orgId,
    type: 'directoryUpload',
    path: '/',
  } as TerminalOperationActionPostModel);
  const [currentPath, setCurrentPath] = useState('/');
  const [currentPathInput, setCurrentPathInput] = useState('/');
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedName, setSelectedName] = useState('');

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useMemo(() => {
    setLoading(terminalDirectoryStatus === LoadingStatus.LOADING);
    setSuccess(terminalDirectoryStatus === LoadingStatus.SUCCESS);
  }, [terminalDirectoryStatus]);

  useEffect(() => {
    if (
      terminalDirectoryList?.find((it) => it?.path?.toLocaleUpperCase() === currentPath || it?.path === currentPath)
    ) {
      setSuccess(true);
    }
  }, [currentPath]);

  useEffect(() => {
    setIsTouched(false);

    if (modalData.type === ModalType.FILE_EXPLORER && modalData?.name === 'directory') {
      clearFileDirectory();
      setCurrentPath('/');
      setCurrentPathInput('/');
      getTerminalDirectory({
        terminalId: terminalId,
        postData: operationInfoValue,
      } as TerminalOperationActionModel);
    }
  }, [modalData.type, orgId, terminalId]);

  useEffect(() => {
    if (
      terminalDirectoryList &&
      terminalDirectoryList?.length > 0 &&
      terminalDirectoryList?.find((it) => it?.path?.toLocaleUpperCase() === currentPath)
    ) {
      return;
    } else if (modalData.type === ModalType.FILE_EXPLORER && modalData?.name === 'directory') {
      getTerminalDirectory({
        terminalId: terminalId,
        postData: operationInfoValue,
      } as TerminalOperationActionModel);
    }
  }, [operationInfoValue]);

  const onPathInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPathInput(event.target.value);
  };

  const onPathKeyUpChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event?.key === 'Enter' && currentPathInput) {
      let currentOperation = operationInfoValue;
      currentOperation.path = currentPathInput;
      setCurrentPath(currentPathInput);
      getTerminalDirectory({
        terminalId: terminalId,
        postData: currentOperation,
      } as TerminalOperationActionModel);
    }
  };

  const onPathEnter = () => {
    if (currentPathInput) {
      let currentOperation = operationInfoValue;
      currentOperation.path = currentPathInput;
      setCurrentPath(currentPathInput);
      getTerminalDirectory({
        terminalId: terminalId,
        postData: currentOperation,
      } as TerminalOperationActionModel);
    }
  };

  const onBlur = () => {
    if (currentPathInput) {
      let currentOperation = operationInfoValue;
      currentOperation.path = currentPathInput;
      setCurrentPath(currentPathInput);
      getTerminalDirectory({
        terminalId: terminalId,
        postData: currentOperation,
      } as TerminalOperationActionModel);
    }
  };

  const onCancel = (event: any) => {
    if (isTouched) {
      setDialogStatus({
        ...dialogState,
        isOpen: true,
        context: 'Are you sure you want to discard your changes?',
      });
    } else {
      removeAllValidation();
      setDialogStatus({
        ...dialogState,
        isOpen: false,
      });
      setIsFileSelected(false);
      setOperationInfoValue({
        organisationId: orgId,
        type: 'directoryUpload',
        path: '/',
      } as TerminalOperationActionPostModel);
      setSelectedName('');
      closeModal();
    }
  };

  const onBack = () => {
    if (currentPath === '/') return;
    else if (currentPath) {
      document.getElementsByClassName('form-container')[0]?.scroll(0, 0);
      const pathParts = currentPath.split('/');
      const newPathParts = pathParts.slice(0, pathParts.length - 1);
      let newPath = '';
      if (!newPathParts || newPathParts?.length <= 1) newPath = '/';
      else newPath = newPathParts.join('/').toLocaleUpperCase();
      setCurrentPath(newPath);
      setCurrentPathInput(newPath);
      setOperationInfoValue((prev) => ({ ...prev, path: newPath }));
      setIsFileSelected(false);
      setSelectedName('');
    }
  };

  const onRefresh = () => {
    getTerminalDirectory({
      terminalId: terminalId,
      postData: operationInfoValue,
    } as TerminalOperationActionModel);
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let selectedFilePath = '';
    if (currentPathInput === '/') {
      selectedFilePath = currentPathInput + selectedName;
    } else selectedFilePath = currentPathInput + '/' + selectedName;
    if (!hasValidationError) {
      setOperationInfoValue({
        organisationId: orgId,
        type: 'directoryUpload',
        path: '/',
      } as TerminalOperationActionPostModel);
      closeModal();
      if (newPathHandler) {
        newPathHandler(selectedFilePath);
      }
    }
  };

  const onItemClick = (item: TerminalDirectoryItemModel) => {
    if (item.type === 'directory') {
      document.getElementsByClassName('form-container')[0]?.scroll(0, 0);
      let newPath = '';
      if (currentPathInput === '/') {
        newPath = currentPathInput + item.name;
      } else newPath = currentPathInput + '/' + item.name;
      setCurrentPath(newPath);
      setCurrentPathInput(newPath);
      setOperationInfoValue((prev) => ({ ...prev, path: newPath }));
      setIsFileSelected(false);
      setSelectedName('');
    } else if (item.type === 'file') {
      setSelectedName(item?.name);
      setIsFileSelected(true);
    }
  };

  const cancelDialog = () => {
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    removeAllValidation();
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
    closeModal();
  };

  const pathInputRef = useRef<HTMLInputElement>(null!);

  const customModalHeader = () => {
    return (
      <Box className='file-explorer-modal-header'>
        <BoxItem className='tool-icons-div'>
          <IconButton aria-label='back' onClick={onBack} disabled={currentPath === '/' || loading}>
            <KeyboardBackspaceIcon className='back-icon' />
          </IconButton>
          <IconButton aria-label='refresh' onClick={onRefresh} disabled={loading}>
            <RefreshIcon className='refresh-icon' />
          </IconButton>
        </BoxItem>
        <BoxItem className='close-icon-div'>
          <IconButton aria-label='close' onClick={onCancel}>
            <CloseIcon className='close-icon' />
          </IconButton>
        </BoxItem>
      </Box>
    );
  };

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        id='file-explorer-modal'
        className='file-explorer-modal'
        onClose={onCancel}
        modalTitle=''
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={modalData.type === ModalType.VIEW}
        cancelButtonText='Cancel'
        saveButtonText='Confirm'
        saveButtonEnabled={isFileSelected}
        closeButtonText='Close'
        hideCancelButton={true}
        customHeader={customModalHeader()}
      >
        <Box className='file-path-container'>
          <InputBase
            className='input-base'
            placeholder={'Enter Path'}
            onKeyUp={onPathKeyUpChange}
            inputRef={pathInputRef}
            onChange={onPathInputChange}
            onBlur={onBlur}
            value={currentPathInput}
          />
          <div className='enter-button-container' onClick={onPathEnter}>
            <EnterIcon />
          </div>
        </Box>

        {loading && (
          <div className='loading-error-handler-container'>
            <LoadingDisplay />
          </div>
        )}

        {success && (
          <div className='file-explorer-details-container'>
            <Grid container spacing={4} className='file-explorer-header'>
              <Grid item xs={6}>
                Name
              </Grid>
              <Grid item xs={3}>
                Type
              </Grid>
              <Grid item xs={3}>
                Size
              </Grid>
            </Grid>

            {terminalDirectoryList &&
              terminalDirectoryList?.length > 0 &&
              terminalDirectoryList
                ?.find((it) => it?.path?.toLocaleUpperCase() === currentPath || it?.path === currentPath)
                ?.contents?.map((it, i) => {
                  return (
                    <Box
                      key={i}
                      onClick={() => onItemClick(it)}
                      className={classNames('file-explorer-details', {
                        'file-explorer-details-selected': it?.name === selectedName,
                      })}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={6} className='file-name-container'>
                          {it?.type === 'file' ? <FileIcon /> : <FolderIcon />}
                          {it?.name}
                        </Grid>
                        <Grid item xs={3}>
                          {fieldMappingHelper.getDisplayValue(it?.type, TerminalFileType)}
                        </Grid>
                        <Grid item xs={3}>
                          {it?.type === 'file' && formatSize(it?.size)}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}

            {terminalDirectoryList &&
              terminalDirectoryList?.length > 0 &&
              terminalDirectoryList?.find(
                (it) => it?.path?.toLocaleUpperCase() === currentPath || it?.path === currentPath
              )?.contents?.length === 0 && <div className='no-file-found'>No File Found</div>}
          </div>
        )}
      </MainModal>
    </>
  );
};

export default FileExplorer;
