import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Badge from '@mui/material/Badge';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import { SiteItemModel } from '../../models/siteModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { HealthStatus, HealthStatusDisplayType } from '../../constants/status';
import { ButtonStyle } from '../../constants/button-constants';
import { SiteStatusIcon } from '../../components/icons';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import Avatar from '../../components/avatar/avatar';
import CustomButton from '../../components/button/custom-button';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/sites.scss';

interface SiteItemProps {
  siteItem: SiteItemModel;
  path: string;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  drawerOpen: boolean;
  selectedSiteId?: string;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClickHandler: (id: string) => void;
  onStatusClickHander?: (id: string) => void;
}

const SiteItem: React.FC<SiteItemProps> = (props: SiteItemProps) => {
  const {
    siteItem,
    path,
    hasDeleteAccess,
    hasUpdateAccess,
    drawerOpen,
    selectedSiteId,
    onDelete,
    onEdit,
    onClickHandler,
    onStatusClickHander,
  } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit Site',
      handler: () => {
        onEdit(siteItem.id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete Site',
      handler: () => {
        onDelete(siteItem.id, siteItem.name);
      },
    });
  }

  const onItemClick = () => {
    if (onClickHandler) {
      onClickHandler(siteItem.id);
    }
  };

  const onDrawOpenClick = (event: any) => {
    if (selectedSiteId === siteItem?.id) return;
    if (onStatusClickHander) {
      onStatusClickHander(siteItem.id);
    }
  };

  return (
    <React.Fragment>
      <Card
        className={classNames('box-card', 'box-grid', 'site-item-container', {
          'site-item-container-with-drawer-open': drawerOpen,
        })}
      >
        <Link to={path} style={{ textDecoration: 'none' }}>
          <Box
            className={classNames('site-card', {
              'site-card-without-status-details': siteItem.status === HealthStatus.UNAVAILABLE,
            })}
            onClick={onItemClick}
          >
            <Badge
              className={classNames('site-health-badge', `badge-with-status-${siteItem.health}`)}
              variant='dot'
              color='success'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Item className='user-avatar'>
                <Avatar value={siteItem.name} displayValue={siteItem.name} />
              </Item>
            </Badge>

            <Item className='box-main'>{siteItem.name}</Item>
            <Link to='' style={{ textDecoration: 'none' }}>
              <Item className='box-main site-status-container'>
                <Item className={classNames('box-health-icon', siteItem.health)}></Item>
                <span className='box-child'>
                  &nbsp;{fieldMappingHelper.getDisplayValue(siteItem.status, HealthStatusDisplayType)}
                </span>
              </Item>
            </Link>
            {!drawerOpen && (
              <Item className='box-main hidden-mobile'>
                <span className='box-child'>{siteItem.lastUpdatedDateTimeUtc}</span>
              </Item>
            )}
          </Box>
        </Link>

        <Item className={classNames('buttons-container', { 'buttons-container-with-drawer-open': drawerOpen })}>
          <CustomButton
            buttonStyle={ButtonStyle.ITEM_INLINE_BUTTON}
            onClick={onDrawOpenClick}
            className={classNames('status-details-button', {
              'selected-site-status-details-button': selectedSiteId === siteItem?.id,
            })}
            icon={<SiteStatusIcon />}
            iconTooltip={'Status Details'}
          ></CustomButton>
          {displayMenu ? (
            <FloatingMenu key={siteItem.id} buttonNode={<MoreVertIcon />} items={items} />
          ) : drawerOpen ? (
            <Box className='box-item' onClick={onItemClick}></Box>
          ) : null}
        </Item>
      </Card>
    </React.Fragment>
  );
};

export default SiteItem;
