import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { selectOrganisationId } from './../../store/auth/selectors';
import { RootState } from '../../store/rootReducers';
import { selectSelectedSiteId } from './../../store/sites/selectors';
import { selectTerminalData, selectTerminalStatus } from '../../store/terminal/selectors';
import { selectTerminalOperationStatus } from '../../store/terminal-operations/selectors';
import { selectModalStatus } from '../../store/modals/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { TerminalOperationActionModel } from '../../models/terminalOperationModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { TerminalModel } from '../../models/terminalModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import * as terminalOperationActions from '../../store/terminal-operations/actions';
import * as actions from '../../store/terminal/actions';
import * as modalActions from '../../store/modals/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import Terminals from './terminal-list';

const mapStateToProps = (state: RootState) => {
  return {
    terminalData: selectTerminalData(state),
    terminalStatus: selectTerminalStatus(state),
    selectedSiteId: selectSelectedSiteId(state),
    selectedOrganisationId: selectOrganisationId(state),
    authStatus: selectAuthStatus(state),
    terminalOperationStatus: selectTerminalOperationStatus(state),
    selectModalStatus: selectModalStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTerminals: () => dispatch({ type: actions.LOAD_TERMINALS }),
    deleteTerminalItem: (data: TerminalModel) => dispatch({ type: actions.DELETE_TERMINAL, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    terminalOpeationActions: (data: TerminalOperationActionModel) =>
      dispatch({
        type: terminalOperationActions.TERMINAL_OPERATION_ACTION,
        payload: data,
      }),
    setSelectedTerminalItem: (data: TerminalModel) => dispatch({ type: actions.SET_SELECTED_TERMINAL, payload: data }),
    resetOperationState: () => dispatch({ type: terminalOperationActions.RESET_OPERATION_STATE }),
  };
};

const TerminalList = connect(mapStateToProps, mapDispatchToProps)(Terminals);

export default TerminalList;
