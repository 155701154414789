import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import CustomNavLink from './navlink';
import * as actions from '../../../store/nav-pane/actions';



const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
   setSelectedMenu : (moduleName: string) =>
      dispatch({ type: actions.SET_SELECTED_MENU, payload: moduleName }),
  };
};

const NavLink = connect(
  null,
  mapDispatchToProps
)(CustomNavLink);

export default NavLink;
