import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectTankData, selectTankDetailStatus, selectTankInfo } from '../../../store/tanks/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectProductListName } from '../../../store/product/selectors';
import { selectSelectedSiteId } from '../../../store/sites/selectors';
import {
  selectCustomValidationMessage,
  selectFieldsValidation,
  selectHasValidationError,
} from './../../../store/fieldValidation/selectors';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { TankModel } from '../../../models/tankModel';
import * as actions from '../../../store/tanks/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as productActions from '../../../store/product/actions';
import Tank from './tank';

const mapStateToProps = (state: RootState) => {
  return {
    tankList: selectTankData(state),
    tankInfo: selectTankInfo(state),
    tankDetailStatus: selectTankDetailStatus(state),
    hasValidationError: selectHasValidationError(state),
    productNameList: selectProductListName(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    selectedSiteId: selectSelectedSiteId(state),
    fieldValidations: selectFieldsValidation(state),
    customValidationMessage: selectCustomValidationMessage(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadTanks: (data: string) => dispatch({ type: actions.LOAD_TANKS, payload: data }),
    editTank: (data: TankModel) => dispatch({ type: actions.EDIT_TANK, payload: data }),
    createTank: (data: TankModel) => dispatch({ type: actions.CREATE_TANK, payload: data }),
    loadTankInfo: (id: string) => dispatch({ type: actions.LOAD_TANK_INFO, payload: id }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    loadProductNameList: () => dispatch({ type: productActions.LOAD_PRODUCT_NAME_LIST }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const TankPage = connect(mapStateToProps, mapDispatchToProps)(Tank);

export default TankPage;
