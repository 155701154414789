import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import './chip.scss';

interface ChipControlProps {
  id: string;
  label: string;
  hideDeleteIcon?: boolean;
  onDeleteChip?: () => void;
}

const ChipControl = (props: ChipControlProps) => {
  const { id, label, hideDeleteIcon, onDeleteChip } = props;

  return (
    <div className='chip-container'>
      <Chip
        id={id}
        label={label}
        onDelete={() => {
          if (onDeleteChip) onDeleteChip();
        }}
        className='chip-bg-color'
        deleteIcon={hideDeleteIcon ? <></> : <CloseIcon />}
      ></Chip>
    </div>
  );
};

export default ChipControl;
