import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import * as services from './services';
import * as actions from './actions';
import { LoadingStatus } from '../../constants/loading-constants';
import { PermissionGroupItemsModel } from '../../models/permissionGroupModel';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import * as fieldHelper from '../../utilities/field-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { PermissionGroupResponse, PermissionGroupItems } from '../../entities/permission-group';
import {
  setPermissionGroupStatus,
  setPermissionGroupContent,
  setPermissionGroupError,
  setPermissionGroupListName,
  setPermissionGroupItem,
} from './reducers';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { selectCurrentUserPermissions, selectOrganisationId } from '../auth/selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { UserPermissionDetail } from '../../entities/userPermission';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_PERMISSIONSGROUP, loadPermissionGroup);
  yield takeLatest(actions.LOAD_PERMISSIONSGROUP_INFO, loadPermissionGroupInfo);
  yield takeLatest(actions.LOAD_PERMISSION_GROUP_NAME_LIST, loadPermissionGroupNameList);
  yield takeLatest(actions.DELETE_PERMISSIONSGROUP, deletePermissionGroupItem);
  yield takeLatest(actions.EDIT_PERMISSIONSGROUP, editPermissionGroup);
  yield takeLatest(actions.CREATE_PERMISSIONSGROUP, createPermissionGroup);
}

export function* loadPermissionGroup() {
  try {
    yield put(setPermissionGroupStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: PermissionGroupResponse = yield call(services.getAllPermissionGroup, organisationId);
    let records: PermissionGroupItems[] = yield call(MapPermissionGroupEntityToModel, response);

    yield put(setPermissionGroupContent(records));
    yield put(setPermissionGroupStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPermissionGroupError());
  }
}

export function* loadPermissionGroupInfo(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setPermissionGroupStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      const userPermissions: UserPermissionDetail[] = yield select(selectCurrentUserPermissions);
      let response: PermissionGroupItems = yield call(services.getPermissionGroupById, action.payload, organisationId);

      let item: PermissionGroupItemsModel = MapPermissionGroupInfoEntityToModel(response, userPermissions);
      yield put(setPermissionGroupItem(item));
      yield put(setPermissionGroupStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPermissionGroupError());
    yield put(clearAllFieldValidation());
  }
}

export function* loadPermissionGroupNameList() {
  try {
    yield put(setPermissionGroupStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: PermissionGroupResponse = yield call(services.getAllPermissionGroup, organisationId);
    let records: KeyValuePair[] = yield call(MapPermissionGroupEntityToKeyValuePair, response);
    yield put(setPermissionGroupListName(records));
    yield put(setPermissionGroupStatus(LoadingStatus.SUCCESS));
  } catch {
    yield put(setPermissionGroupStatus(LoadingStatus.ERROR));
  }
}

export function* editPermissionGroup(action: PayloadAction<PermissionGroupItemsModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let permissionGroupEntity: PermissionGroupItems = MapPermissionGroupInfoModelToEntity(
      action.payload,
      organisationId
    );
    yield call(services.editPermissionGroup, permissionGroupEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PERMISSION_GROUP_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    yield put(setSnackBarError(errorMsg));
    return;
  }
}

export function* createPermissionGroup(action: PayloadAction<PermissionGroupItemsModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let permissionGroupEntity: PermissionGroupItems = MapPermissionGroupInfoModelToEntity(
      action.payload,
      organisationId
    );
    yield call(services.createPermissionGroup, permissionGroupEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PERMISSION_GROUP_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* deletePermissionGroupItem(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deletePermissionGroup, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.PERMISSION_GROUP_DELETE_SUCCESS));
    yield call(loadPermissionGroup);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const MapPermissionGroupInfoEntityToModel = (
  response: PermissionGroupItems,
  usersPermissions_response: UserPermissionDetail[]
) => {
  if (!!response) {
    return {
      id: response.id,
      organisationId: response?.organisationId,
      name: response?.name,
      description: response?.description,
      permissionNames: response?.permissionNames,
      userPermissions: usersPermissions_response?.map((permission_item) => {
        const result = response?.permissionNames?.filter(
          (users_response) => permission_item.name === users_response
        )[0];
        return {
          name: permission_item?.name,
          description: permission_item?.description,
          enabled: !!result ? true : false,
        };
      }),
    } as PermissionGroupItemsModel;
  }

  return {} as PermissionGroupItemsModel;
};

export const MapPermissionGroupEntityToModel = (response: PermissionGroupResponse) => {
  if (!!response && response?.items?.length > 0) {
    const result: PermissionGroupItemsModel[] = response?.items?.map((item) => {
      return {
        id: item?.id,
        organisationId: item?.organisationId,
        name: fieldHelper.getDefaultStringvalue(item?.name),
        permissionNames: item?.permissionNames,
        description: fieldHelper.getDefaultStringvalue(item?.description),
        userPermissions: [],
      };
    });
    return result;
  }
  return [] as PermissionGroupItemsModel[];
};

const MapPermissionGroupEntityToKeyValuePair = (response: PermissionGroupResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};

const MapPermissionGroupInfoModelToEntity = (model: PermissionGroupItemsModel, organisationId: string) => {
  if (!!model) {
    let permissionGroupEntity = {
      organisationId: organisationId,
      name: model?.name,
      description: fieldMappingHelper.sanitizeStringValue(model?.description),
      permissionNames: model?.userPermissions
        ?.filter((items) => items.enabled === true)
        .map((i) => {
          return i.name;
        }),
    } as unknown as PermissionGroupItems;
    return permissionGroupEntity;
  }
  return {} as PermissionGroupItems;
};
