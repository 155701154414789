import { RootState } from './../rootReducers';
import { createSelector } from 'reselect';

export const selectBackDropState = (state: RootState) => state.BackDropState;

export const selectBackDropStatus = createSelector(
    selectBackDropState,
    state => state.backDropStatus
);

export const selectBackdropActionStatus = createSelector(
    selectBackDropState,
    state => state.actionStatus
);

export const selectBackDropError = createSelector(
    selectBackDropState,
    state => state.hasError
);
