import { Collapse, List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import './collapsible-list.scss';

interface CollapsibleListProps {
  label: string;
  expanded: boolean;
  children?: React.ReactNode;
  titleButtonOption?: React.ReactNode;
  icon?: string;
  clickTooltip?: string;
  readOnly?: boolean;
  disabledExpand?: boolean;
  hideCollapseArrow?: boolean;
}

const CollapsibleList: React.FC<CollapsibleListProps> = (props: CollapsibleListProps) => {
  const { label, expanded, children, titleButtonOption, icon, clickTooltip, disabledExpand, hideCollapseArrow } = props;
  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(expanded);

  const collapsedHandler = () => {
    handleTooltipOpen();
    if (disabledExpand) {
      return;
    }
    setIsExpanded((prev) => !prev);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(handleTooltipClose, 3000);
  };

  useEffect(() => {
    if (disabledExpand) setIsExpanded(false);
    else setIsExpanded(true);
  }, [disabledExpand]);

  return (
    <Fragment>
      <div
        className={classNames('list-control', {
          'list-not-expanded': !isExpanded,
          'list-expanded': isExpanded,
        })}
      >
        {
          <Tooltip title={clickTooltip ? clickTooltip : ''} onClose={handleTooltipClose} open={open} followCursor arrow>
            <ListItemButton onClick={collapsedHandler} className='list-header' disableRipple>
              <div className={icon} />
              {titleButtonOption && (
                <>
                  <div
                    className={classNames('collapsed-icon', {
                      'expand-more': !isExpanded,
                      'expand-less': isExpanded,
                      'hide-collasped-icon': hideCollapseArrow,
                    })}
                  ></div>
                  <div className='list-title'>{label}</div>
                  <div className='title-button-option'>{titleButtonOption}</div>
                </>
              )}
              {!titleButtonOption && (
                <>
                  <div className='list-title'>{label}</div>
                  <div
                    className={classNames('collapsed-icon', {
                      'expand-more': !isExpanded,
                      'expand-less': isExpanded,
                    })}
                  ></div>
                </>
              )}
            </ListItemButton>
          </Tooltip>
        }
      </div>
      <Collapse in={isExpanded}>
        <List className='listview'>{children}</List>
      </Collapse>
    </Fragment>
  );
};

export default CollapsibleList;
