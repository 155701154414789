import { useNavigate, useParams } from 'react-router-dom';
import { FilterIcon, ExportIcon, TimerIcon } from '../../../components/icons';
import CustomButton from '../../../components/button/custom-button';
import { ButtonStyle } from '../../../constants/button-constants';
import { ModalType } from '../../../constants/modal-constants';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import classNames from 'classnames';
import '../styles/transaction-toolbar.scss';

interface TransactionToolBarProps {
  hasReportReadPermission?: boolean;
  hasTransactionReportPermission?: boolean;
  isFilterActive: boolean;
  openModal: (data: ModalStateModel) => void;
}

const TransactionToolBar: React.FC<TransactionToolBarProps> = (props: TransactionToolBarProps) => {
  const { hasReportReadPermission, hasTransactionReportPermission, isFilterActive, openModal } = props;
  const navigate = useNavigate();
  const { orgId } = useParams();

  const onFilterClickHanlder = () => {
    openModal({
      type: ModalType.SEARCH,
      dataId: '',
    } as ModalStateModel);
  };

  const onExportClickHandler = () => {
    navigate(`/organisations/${orgId}/transactions/export`);
  };

  return (
    <div className='toolbar-container'>
      <div className='toolbar-buttons'>
        {/*TODO: TOOLBAR WILL UPDATE IN SEPARATE STORY */}
        <div className='toolbar-custom-button'>
          <CustomButton
            buttonStyle={ButtonStyle.TOOLBAR}
            onClick={onFilterClickHanlder}
            icon={
              <FilterIcon
                className={classNames({
                  'grid-toolbar-icon': !isFilterActive,
                  'active-grid-toolbar-icon': isFilterActive,
                })}
              />
            }
            className={classNames({
              'active-grid-toolbar-button-text': isFilterActive,
            })}
          >
            Filters
          </CustomButton>
          {(hasReportReadPermission || hasTransactionReportPermission) && (
            <CustomButton
              buttonStyle={ButtonStyle.TOOLBAR}
              onClick={onExportClickHandler}
              icon={<ExportIcon className='grid-toolbar-icon' />}
            >
              Export
            </CustomButton>
          )}
          <div className='toolbar-unused-buttons'>
            <CustomButton buttonStyle={ButtonStyle.TOOLBAR} icon={<ExportIcon className='grid-toolbar-icon' />}>
              Export Report
            </CustomButton>
            <CustomButton buttonStyle={ButtonStyle.TOOLBAR} icon={<TimerIcon className='grid-toolbar-icon' />}>
              Automate Report
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionToolBar;
