import { SiteConfigurationModel } from './../../models/siteModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { SiteItemModel } from '../../models/siteModel'
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { SitesInfoModel } from '../../models/siteModel';

interface SiteState {
    siteStatus: string
    selectedSiteId: string
    siteContent: SiteItemModel[]
    siteConfiguration: SiteConfigurationModel
    siteItem: SiteItemModel
    siteNameList: KeyValuePair[]
    hasError: boolean
    sitesIdContent: SitesInfoModel[]

};

export const initialState: SiteState = {siteStatus: LoadingStatus.LOADING } as SiteState;