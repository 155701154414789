import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData, selectModalActionStatus } from '../../../store/modals/selectors';
import * as modalActions from '../../../store/modals/actions';
import WindcaveStatusModal from './windcave-status-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    modalActionStatus: selectModalActionStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
  };
};

const WindcaveStatusModalContainer = connect(mapStateToProps, mapDispatchToProps)(WindcaveStatusModal);

export default WindcaveStatusModalContainer;
