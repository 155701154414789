import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put, call, select, delay } from 'redux-saga/effects';
import {
  setPriceSchedStatus,
  setpriceSchedContent,
  setPriceScheduleInfoContent,
  setPriceSchedServerError,
  setPriceSchedListError,
  setPriceSchedDetailStatus,
  setPriceSchedDetailError,
} from './reducers';
import { PriceScheduleInfoEntity, PriceScheduleResponse, ProductPriceEntity } from '../../entities/priceSchedule';
import { SiteResponseEntity } from '../../entities/site';
import { ProductsResponse } from '../../entities/product';
import {
  PriceSchedListErrorModel,
  PriceScheduleInfoModel,
  PriceSchedProductModel,
  ProductPriceModel,
  SitesInfoModel,
} from '../../models/priceScheduleModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { SiteItemModel } from '../../models/siteModel';
import { selectOrganisationId } from '../auth/selectors';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from './../../constants/loading-constants';
import { timezone } from '../../constants/dropdown-constants';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { setGenericErrorData } from '../generic-error/reducers';
import { setProductListName } from '../product/reducers';
import { setSiteNameList } from '../sites/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { MapSiteEntityToModel } from '../sites/sagas';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as fieldHelper from '../../utilities/field-helper';
import * as generalHelper from '../../utilities/general-helper';
import * as actions from './actions';
import * as services from './services';
import * as siteServices from '../sites/services';
import * as productServices from '../product/services';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_PRICESCHEDULES, loadPriceSchedules);
  yield takeLatest(actions.LOAD_PRICESCHEDULEINFO, loadPriceScheduleInfoById);
  yield takeLatest(actions.CREATE_PRICESCHEDULE, createPriceSchedule);
  yield takeLatest(actions.EDIT_PRICESCHEDULE, editPriceSchedule);
  yield takeLatest(actions.DELETE_PRICESCHEDULE, deletePriceSchedule);
}

export function* loadPriceSchedules() {
  const organisationId: string = yield select(selectOrganisationId);

  try {
    yield put(setPriceSchedStatus(LoadingStatus.LOADING));

    let price_sched_response: PriceScheduleResponse = yield call(services.getAllPriceScheduleData, organisationId);
    let siteList: SiteItemModel[] = [] as SiteItemModel[];
    let products_response: ProductsResponse = yield call(productServices.getProductsData, organisationId);
    let priceScheduleData: PriceScheduleInfoModel[] = yield call(
      MapPriceScheduleEntityToModel,
      price_sched_response,
      siteList,
      products_response
    );

    let productListName: KeyValuePair[] = yield call(MapProductItemEntityToKeyValuePair, products_response);
    yield put(setProductListName(productListName));
    let priceSchedlistError: PriceSchedListErrorModel[] = yield call(validateInceptionDate, price_sched_response);
    yield put(setPriceSchedListError(priceSchedlistError));
    yield put(setpriceSchedContent(priceScheduleData));
    yield put(setPriceSchedStatus(LoadingStatus.SUCCESS));

    if (!siteList || siteList?.length <= 0) {
      let site_response: SiteResponseEntity = yield call(siteServices.getSiteList, organisationId);
      let siteList: SiteItemModel[] = yield call(MapSiteEntityToModel, site_response);
      let priceScheduleData: PriceScheduleInfoModel[] = yield call(
        MapPriceScheduleEntityToModel,
        price_sched_response,
        siteList,
        products_response
      );
      yield put(setpriceSchedContent(priceScheduleData));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPriceSchedServerError());
    yield put(setPriceSchedStatus(LoadingStatus.ERROR));
  }
}

export function* loadPriceScheduleInfoById(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      const organisationId: string = yield select(selectOrganisationId);
      yield put(setPriceSchedDetailStatus(LoadingStatus.LOADING));
      let price_sched_response: PriceScheduleInfoEntity = yield call(
        services.getPriceScheduleById,
        action.payload,
        organisationId
      );

      let site_response: SiteResponseEntity = yield call(siteServices.getSiteList, organisationId);
      let siteNameRecords: KeyValuePair[] = yield call(MapSiteItemEntityToKeyValuePair, site_response);
      yield put(setSiteNameList(siteNameRecords));

      let products_response: ProductsResponse = yield call(productServices.getProductsData, organisationId);
      let productRecords: KeyValuePair[] = yield call(MapProductItemEntityToKeyValuePair, products_response);

      yield put(setProductListName(productRecords));

      let priceScheduleInfo: PriceScheduleInfoModel = yield call(
        MapPriceScheduleInfoEntityToModel,
        price_sched_response,
        site_response,
        products_response
      );
      yield put(setPriceScheduleInfoContent(priceScheduleInfo));

      yield put(setPriceSchedDetailStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPriceSchedDetailError());
    yield put(clearAllFieldValidation());
  }
}

export function* createPriceSchedule(action: PayloadAction<PriceScheduleInfoModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let priceScheduleEntity = MapPriceScheduleModelToEntity(action.payload);
    priceScheduleEntity.organisationId = yield select(selectOrganisationId);
    yield call(services.createPriceSchedule, priceScheduleEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PRICESCHEDULE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    return;
  }
}

export function* editPriceSchedule(action: PayloadAction<PriceScheduleInfoModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let priceScheduleEntity = MapPriceScheduleModelToEntity(action.payload);
    const organisationId: string = yield select(selectOrganisationId);
    priceScheduleEntity.organisationId = organisationId;
    yield call(services.editPriceSchedule, priceScheduleEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PRICESCHEDULE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    yield put(setSnackBarError(errorMsg));
    return;
  }
}

export function* deletePriceSchedule(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deletePriceSchedule, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.PRICESCHEDULE_DELETE_SUCESS));
    yield call(loadPriceSchedules);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const MapPriceScheduleEntityToModel = (
  price_sched_response: PriceScheduleResponse,
  siteList: SiteItemModel[],
  products_response: ProductsResponse
) => {
  if (price_sched_response && price_sched_response.items.length > 0) {
    const result: PriceScheduleInfoModel[] = price_sched_response.items.map((record, i) => {
      let matchSites = record?.sites
        ?.map((s, i) => {
          let tempGroup = siteList?.find((e) => e.id === s.id);
          return tempGroup;
        })
        ?.filter((it) => it !== undefined);

      return {
        id: record?.id,
        organisationId: record?.organisationId,
        name: record?.name,
        activationDateTimeUtc: record?.sites[0]?.activationDateTimeUtc,
        sites: record?.sites?.map((s) => {
          return s.id;
        }),
        sitesInfo: matchSites?.map((data: any) => {
          const timezonename = timezone
            .filter((item) => item?.key === data?.timeZoneId)
            .map((data) => {
              return data?.value;
            });
          return {
            id: data?.id,
            name: fieldHelper.getDefaultStringvalue(data?.name),
            timezone: fieldHelper.getDefaultStringvalue(data?.timeZoneId),
            timezoneName: fieldHelper.getDefaultStringvalue(timezonename?.toString()),
          } as SitesInfoModel;
        }),
        products: record?.products?.map((item, i) => {
          return {
            id: item?.id,
            price: item?.price.toString(),
            productInfo: products_response?.items
              .filter((data) => data.id === item?.id)
              .map((item) => {
                return {
                  id: item.id,
                  name: fieldHelper.getDefaultStringvalue(item.name),
                  shortName: fieldHelper.getDefaultStringvalue(item.shortName),
                } as PriceSchedProductModel;
              }),
          } as ProductPriceModel;
        }),
      } as PriceScheduleInfoModel;
    });
    return result;
  }

  return [] as PriceScheduleInfoModel[];
};

const MapPriceScheduleInfoEntityToModel = (
  price_sched_response: PriceScheduleInfoEntity,
  site_response: SiteResponseEntity,
  products_response: ProductsResponse
) => {
  if (price_sched_response) {
    let siteList: KeyValuePair[] = MapSiteItemEntityToKeyValuePair(site_response);
    let productList: KeyValuePair[] = MapProductItemEntityToKeyValuePair(products_response);
    const priceScheduleEntityInfoToModel = {
      name: price_sched_response.name,
      organisationId: price_sched_response.organisationId,
      id: price_sched_response.id,
      activationDateTimeUtc: price_sched_response?.sites[0]?.activationDateTimeUtc,
      sites: fieldMappingHelper.validateEnumValueReturnBoolean(
        price_sched_response?.sites?.map((s) => {
          return s.id;
        }),
        siteList
      )
        ? price_sched_response?.sites?.map((s) => {
            return s.id;
          })
        : [],
      sitesInfo: !price_sched_response?.sites
        ? ([] as SitesInfoModel[])
        : site_response?.items
            .filter((data) => data.id === price_sched_response?.sites[0]?.id) //always gets the 1st siteId
            .map((data) => {
              const timezonename = timezone
                .filter((item) => item?.key === data.timeZoneId)
                .map((data) => {
                  return data?.value;
                });
              return {
                id: data.id,
                name: fieldHelper.getDefaultStringvalue(data.name),
                timezone: fieldHelper.getDefaultStringvalue(data.timeZoneId),
                timezoneName: fieldHelper.getDefaultStringvalue(timezonename.toString()),
              } as SitesInfoModel;
            }),
      products: price_sched_response?.products?.map((item, i) => {
        return {
          id: fieldMappingHelper.validateEnumValueReturnBoolean(item?.id, productList) ? item?.id : '',
          price: generalHelper.numberHandlerWithDP(item?.price.toString(), item?.price > 10 ? 2 : 3),
          productInfo: products_response?.items
            ?.filter((data) => data?.id === item?.id)
            ?.map((item) => {
              return {
                id: item?.id,
                name: fieldHelper.getDefaultStringvalue(item?.name),
                shortName: fieldHelper.getDefaultStringvalue(item?.shortName),
              } as PriceSchedProductModel;
            }),
        } as ProductPriceModel;
      }),
    } as PriceScheduleInfoModel;
    return priceScheduleEntityInfoToModel;
  }

  return {} as PriceScheduleInfoModel;
};

const MapPriceScheduleModelToEntity = (model: PriceScheduleInfoModel) => {
  if (model) {
    let priceScheduleEntity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(model.organisationId),
      name: fieldMappingHelper.sanitizeStringValue(model.name),
      sites: model?.sites.map((site) => {
        return {
          id: site,
          activationDateTimeUtc: dateTimeHelper.convertDateStringtoUTCString(model.activationDateTimeUtc),
        };
      }),
      products:
        model?.products.length > 0
          ? model?.products?.map((item, i) => {
              return {
                id: item.id,
                price: fieldMappingHelper.convertStringToNumber(item.price),
              } as ProductPriceEntity;
            })
          : undefined,
    } as PriceScheduleInfoEntity;
    return priceScheduleEntity;
  }
  return {} as PriceScheduleInfoEntity;
};

const validateInceptionDate = (response: PriceScheduleResponse) => {
  const date_now = new Date();
  let ListError = {};
  if (response) {
    if (response.items.length > 0) {
      const upcomingList =
        response.items.filter((item) => new Date(item.activationDateTimeUtc) >= new Date(date_now.toISOString()))
          .length < 1;

      const activeList =
        response.items.filter((item) => new Date(item.activationDateTimeUtc) < new Date(date_now.toISOString()))
          .length < 1;

      ListError = [
        {
          upcomingHasError: upcomingList,
          activeHasError: activeList,
        },
      ] as unknown as PriceSchedListErrorModel;
    } else {
      ListError = [
        {
          upcomingHasError: true,
          activeHasError: true,
        },
      ] as unknown as PriceSchedListErrorModel;
    }
    return ListError;
  }

  return {} as PriceSchedListErrorModel;
};

const MapProductItemEntityToKeyValuePair = (response: ProductsResponse): KeyValuePair[] => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }

  return [] as KeyValuePair[];
};

const MapSiteItemEntityToKeyValuePair = (response: SiteResponseEntity): KeyValuePair[] => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }

  return [] as KeyValuePair[];
};
