import { AccountInfo  } from "@azure/msal-browser";
import { OrganisationItemModel } from "../../models/organisationModel";
import { UserPermissionModel } from "../../models/userPermissionModel";

interface AuthState {
    authStatus: string;
    account: AccountInfo;
    organisationId: string;
    hasError: boolean;
    currentOrganisation: OrganisationItemModel;
    userPermissions: UserPermissionModel[];
    userId:string;
}

export const initialState: AuthState = {
    account: {} as AccountInfo,
    organisationId: '',
    hasError: false,
    currentOrganisation: {} as OrganisationItemModel,
    userPermissions: [] as UserPermissionModel[],
    userId:''
} as AuthState;