import { initialState } from './terminalInitState';
import { TerminalCertificateModel, TerminalInfo, TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const terminalSlice = createSlice({
  name: 'terminalState',
  initialState,
  reducers: {
    setTerminalState: (state, action: PayloadAction<string>) => {
      state.terminalStatus = action.payload;
      state.hasError = false;
    },
    setTerminalDetailState: (state, action: PayloadAction<string>) => {
      state.terminalDetailStatus = action.payload;
      state.hasError = false;
    },
    setTerminalData: (state, action: PayloadAction<TerminalRecordModel[]>) => {
      state.terminalData = action.payload;
      state.hasError = false;
    },
    setTerminalInfo: (state, action: PayloadAction<TerminalInfo>) => {
      state.hasError = false;
      state.terminalInfo = action.payload;
    },
    setSelectedTerminal: (state, action: PayloadAction<TerminalModel>) => {
      state.hasError = false;
      state.selectedTerminalItem = action.payload;
    },
    setTerminalError: (state) => {
      state.hasError = true;
      state.terminalData = [];
      state.terminalStatus = LoadingStatus.ERROR;
    },
    setTerminalDetailError: (state) => {
      state.hasError = true;
      state.terminalInfo = {} as TerminalInfo;
      state.terminalDetailStatus = LoadingStatus.ERROR;
    },
    setTerminalCertificate: (state, action: PayloadAction<TerminalCertificateModel>) => {
      state.hasError = false;
      state.terminalCertificate = action.payload;
    },
    setTerminalNumberList: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.hasError = false;
      state.terminalNumberList = action.payload;
    },
    resetTerminalState: () => {
      return initialState;
    },
  },
});

export const {
  setTerminalState,
  setTerminalDetailState,
  setTerminalData,
  setTerminalInfo,
  setSelectedTerminal,
  setTerminalError,
  setTerminalDetailError,
  setTerminalCertificate,
  setTerminalNumberList,
  resetTerminalState,
} = terminalSlice.actions;
export default terminalSlice.reducer;
