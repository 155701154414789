import React, { useState } from 'react';
import { Collapse, Grid, TableCell, TableRow, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import Item from '../../components/box-items/box-item';
import CardFixedDetails from './collapsible-card-fixedDetails';
import { ItemDetailModel, StatusMessageModel } from '../../models/baseModels/itemDetailModel';
import '../../components/cards/cards.scss';
import './collapsible-card.scss';
import '../../styles/general/theme-variables.scss';

interface CollapsibleCardProps {
  id: string;
  fixeditemDetails: ItemDetailModel[];
  itemDetails?: ItemDetailModel[];
  rowViewItemDetails?: StatusMessageModel[];
  componentDetails?: any;
  showMoreDetailText?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = (props: CollapsibleCardProps) => {
  const { id, fixeditemDetails, itemDetails, rowViewItemDetails, componentDetails, showMoreDetailText, onClick } =
    props;
  const [showDetails, setShowDetails] = useState(false);

  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const showDetailHandler = () => {
    setShowDetails((prev) => !prev);
  };

  const withDetails = (itemDetails && itemDetails?.length > 0) || componentDetails || rowViewItemDetails;

  return (
    <React.Fragment>
      <Box onClick={onClick} className={classNames('box-card-child', 'box-row-fixeditem-collapsed-mobile')}>
        <Item className='box-row-columns'>
          <CardFixedDetails id={id} fixeditemDetails={fixeditemDetails} showinExpandViewOnly={true} />
        </Item>
      </Box>
      <Item className={classNames({ 'collapsible-card__details__no-content': !withDetails })}>
        <Collapse in={showDetails}>
          <Box onClick={onClick} className='box-card-child'>
            {!componentDetails && (
              <Item className='box-row-columns'>
                <CardFixedDetails id={id} fixeditemDetails={fixeditemDetails} showinExpandViewOnly={false} />
                {withDetails &&
                  itemDetails?.map((item, i) => (
                    <Item className='box-row-item-child' key={i}>
                      <Tooltip
                        title={item.tooltipTitle ? item.tooltipTitle : ''}
                        disableFocusListener
                        disableTouchListener
                        arrow
                      >
                        <span className='row-item-label'>
                          <Item>
                            {item?.name}
                            {item.tooltipTitle ? <span className='info-icon'></span> : ''}
                          </Item>
                        </span>
                      </Tooltip>
                      <Item className='row-item-detail'>
                        {item.healthIndicator && (
                          <div className={classNames('status-icon', item.healthIndicator)}></div>
                        )}
                        {item?.value}
                      </Item>
                    </Item>
                  ))}
              </Item>
            )}
            {/* Status Messages */}
            {!componentDetails && (
              <Item className='box-row-columns'>
                <Grid container className={classNames('row-view-container')} alignItems='flex-start'>
                  {withDetails &&
                    rowViewItemDetails
                      ?.slice()
                      ?.sort((a, b) => {
                        const levelOrder = ['error', 'warning'];
                        return levelOrder.indexOf(a.level) - levelOrder.indexOf(b.level);
                      })
                      ?.map((item, i) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          xl={6}
                          className={
                            isSmallerThanMd
                              ? i % 2 === 0
                                ? 'even-row'
                                : 'odd-row'
                              : Math.floor(i / 2) % 2 === 0 && i % 2 === 0
                                ? 'even-row-with-right-border'
                                : Math.floor(i / 2) % 2 === 0 && i % 2 !== 0
                                  ? 'even-row'
                                  : Math.floor(i / 2) % 2 !== 0 && i % 2 === 0
                                    ? 'odd-row-with-right-border'
                                    : Math.floor(i / 2) % 2 !== 0 && i % 2 !== 0
                                      ? 'odd-row'
                                      : ''
                          }
                        >
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell align='left' className='row-view-item-container'>
                              {item?.level === 'warning' ? (
                                <ErrorOutlineIcon className='row-view-warning-icon' />
                              ) : item?.level === 'error' ? (
                                <ErrorOutlineIcon className='row-view-error-icon' />
                              ) : (
                                ''
                              )}
                              <span>{item?.text}</span>
                            </TableCell>
                          </TableRow>
                        </Grid>
                      ))}
                </Grid>
              </Item>
            )}
            {componentDetails && componentDetails}
          </Box>
        </Collapse>
        {!(showDetails && componentDetails) && <Box className='box-line' />}
        <Box className='showhide-container' onClick={showDetailHandler}>
          {!showDetails && showMoreDetailText
            ? showMoreDetailText
            : !showDetails && !showMoreDetailText
              ? 'Show'
              : 'Hide'}
          <span
            className={classNames('collapsed-icon', {
              'expand-more': !showDetails,
              'expand-less': showDetails,
            })}
          ></span>
        </Box>
      </Item>
    </React.Fragment>
  );
};

export default CollapsibleCard;
