import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import Dashboard from './dashboard';
import * as headerActions from '../../store/header/actions';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { RootState } from '../../store/rootReducers';
import { selectCurrentOrganisation, selectOrganisationId } from '../../store/auth/selectors';
import { selectAuthStatus } from '../../store/auth/selectors';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const mapStateToProps = (state:RootState) => {
    return {
      activeOrganisation: selectCurrentOrganisation(state),      
      selectedOrganisationId: selectOrganisationId(state),
      authStatus: selectAuthStatus(state),
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),        
      setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
      setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const DashboardPage = connect(mapStateToProps,mapDispatchToProps)(Dashboard);

export default DashboardPage;
