import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavDropdownModel, NavItemModel, NavItemSubModel } from '../../../models/navItemModel';
import '../styles/nav-pane.scss';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import NavPaneTooltip from '../navPaneTooltip/index.container';
import NavPaneFloatingMenu from '../navpaneFloatingMenu/index.container';
import { ModuleName } from '../../../constants/module-constants';
import NavLink from '../navLink/navLink.container';
import { useLocation } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

interface MainNavPaneProps {
  mainNavItems: NavItemModel[];
  subMenuState: boolean;
  selectSelectedDropdownMenuIndex: NavDropdownModel;
  userAccess: (moduleName: string) => AuthorisationModel;
}

const MainNavPaneControl: React.FC<MainNavPaneProps> = (props: MainNavPaneProps) => {
  const { subMenuState, mainNavItems, selectSelectedDropdownMenuIndex, userAccess } = props;

  const [showUserMgtMenu, setShowUserMgtMenu] = useState(false);
  const [showCardMgtMenu, setShowCardMgtMenu] = useState(false);
  const [showMonitorMenu, setShowMonitorMenu] = useState(false);
  const location = useLocation();
  const hasReadAccess = (moduleName: string | undefined, path: string) => {
    if (moduleName === ModuleName.DASHBOARD) {
      return true;
    }
    return !!moduleName ? userAccess(moduleName).hasReadAccess : false;
  };

  const displayMainNavItem = (subNavItems: NavItemSubModel[], path: string) => {
    let hasReadPermission = false;
    for (let item of subNavItems) {
      if (hasReadAccess(item.moduleName, path)) {
        hasReadPermission = true;
        break;
      }
    }
    return hasReadPermission;
  };
  useEffect(() => {
    if (
      location.pathname.toLocaleLowerCase().includes('/users') ||
      location.pathname.toLocaleLowerCase().includes('/permission-groups')
    ) {
      setShowCardMgtMenu(false);
      setShowUserMgtMenu(true);
      setShowMonitorMenu(false);
    } else if (
      location.pathname.toLocaleLowerCase().includes('/bin-ranges') ||
      location.pathname.toLocaleLowerCase().includes('/card')
    ) {
      setShowCardMgtMenu(true);
      setShowUserMgtMenu(false);
      setShowMonitorMenu(false);
    } else if (
      location.pathname.toLocaleLowerCase().includes('/events') ||
      location.pathname.toLocaleLowerCase().includes('/activity-logs') ||
      location.pathname.toLocaleLowerCase().includes('/alerts')
    ) {
      setShowCardMgtMenu(false);
      setShowUserMgtMenu(false);
      setShowMonitorMenu(true);
    } else {
      setShowCardMgtMenu(false);
      setShowUserMgtMenu(false);
      setShowMonitorMenu(false);
    }
  }, [location.pathname]);

  const menuDropDownToggle = (moduleName: string) => {
    if (moduleName === ModuleName.USER_MANAGEMENT) {
      setShowCardMgtMenu(false);
      setShowUserMgtMenu((prev) => !prev);
      setShowMonitorMenu(false);
    }
    if (moduleName === ModuleName.CARD_MANAGEMENT) {
      setShowCardMgtMenu((prev) => !prev);
      setShowUserMgtMenu(false);
      setShowMonitorMenu(false);
    }
    if (moduleName === ModuleName.MONITOR) {
      setShowCardMgtMenu(false);
      setShowUserMgtMenu(false);
      setShowMonitorMenu((prev) => !prev);
    }
  };

  const getStateOfDropDownMenu = (moduleName: string) => {
    if (moduleName === ModuleName.USER_MANAGEMENT) {
      return showUserMgtMenu;
    }
    if (moduleName === ModuleName.CARD_MANAGEMENT) {
      return showCardMgtMenu;
    }
    if (moduleName === ModuleName.MONITOR) {
      return showMonitorMenu;
    }
  };

  return (
    <Slide direction='right' in={!subMenuState} mountOnEnter unmountOnExit>
      <div className='nav-menu'>
        <List className={classNames('app-left-pane', { 'pop-behind': subMenuState })}>
          {mainNavItems?.map((item, i) => {
            return (
              <React.Fragment key={'main' + i}>
                {!!item.subItems && item.subItems?.length > 0 && displayMainNavItem(item.subItems, item.path) && (
                  <div>
                    <NavPaneFloatingMenu
                      buttonNode={
                        <ListItem
                          button
                          key={item.label}
                          selected={getStateOfDropDownMenu(item.moduleName)}
                          className={classNames('item')}
                          onClick={() => menuDropDownToggle(item.moduleName)}
                        >
                          <ListItemIcon key={item.label}>{item.icon}</ListItemIcon>
                          <ListItemText className='item-text' primary={item.label} />
                          <div className='expand-button-container'>
                            {getStateOfDropDownMenu(item.moduleName) ? <ExpandLess /> : <ExpandMore />}
                          </div>
                        </ListItem>
                      }
                      menuIndex={i}
                      menuInfo={item}
                      selectSelectedDropdownMenuIndex={selectSelectedDropdownMenuIndex}
                      isDropdownMenuToggled={getStateOfDropDownMenu(item.moduleName)}
                    />
                    <Collapse in={getStateOfDropDownMenu(item.moduleName)} timeout='auto' unmountOnExit>
                      {item.subItems?.map((subItem, x) => {
                        return (
                          hasReadAccess(subItem.moduleName, subItem.path) && (
                            <NavLink key={'sub' + x} path={subItem.path} moduleName={subItem.moduleName}>
                              <NavPaneTooltip
                                title={subItem.label}
                                buttonNode={
                                  <ListItem
                                    button
                                    key={subItem.label}
                                    className={classNames('item', 'item-sub', 'child-item-selected')}
                                  >
                                    <div className={classNames('icon-selected')}>
                                      <ListItemIcon key={subItem.label}>{subItem.icon}</ListItemIcon>
                                    </div>
                                    <ListItemText className='item-text' primary={subItem.label} />
                                  </ListItem>
                                }
                              ></NavPaneTooltip>
                            </NavLink>
                          )
                        );
                      })}
                    </Collapse>
                  </div>
                )}

                {!item.subItems && hasReadAccess(item.moduleName, item.path) && (
                  <NavLink key={'main' + i} path={item.path} moduleName={item.moduleName}>
                    <NavPaneTooltip
                      title={item.label}
                      buttonNode={
                        <ListItem button key={item.label} className={classNames('item', 'item-selected')}>
                          <div className={classNames('icon-selected')}>
                            <ListItemIcon key={'icon' + i}>{item.icon}</ListItemIcon>
                          </div>
                          <ListItemText key={'label' + i} className='item-text' primary={item.label} />
                        </ListItem>
                      }
                    ></NavPaneTooltip>
                  </NavLink>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </div>
    </Slide>
  );
};

export default MainNavPaneControl;
