import React from 'react';
import MainModal from '../../../components/main-modal/modal.container';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';

interface TransactionReceiptProps {
  modalData: ModalStateModel;
  closeModal: () => void;
}

const TransactionReceipt: React.FC<TransactionReceiptProps> = (props: TransactionReceiptProps) => {
  const { modalData, closeModal } = props;

  const generateReceipt = (string: string) => {
    return string.split('\n').map((content, i) => {
      return (
        <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
          {content}
        </div>
      );
    });
  };

  const onCancel = (event: any) => {
    closeModal();
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const receiptContent = document.querySelector('.receipt-containter')?.innerHTML;
    if (receiptContent) {
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        // Copy styles from the parent document
        const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'));

        printWindow.document.write(`  
          <html>  
            <head>  
              <title>Receipt</title>  
              ${styles.map((style) => style.outerHTML).join('')}
              <style>  
                /* Center content vertically and horizontally */  
                body, html {
                  height: 100%;  
                  margin: 0;  
                  display: flex;  
                  justify-content: center;  
                  align-items: center;  
                  text-align: center;  
                }
              </style> 
            </head>  
            <body>
              <div class="receipt-containter">  
                ${receiptContent}  
              </div>  
            </body>  
          </html>  
        `);
        printWindow.document.close();

        printWindow.onload = () => {
          setTimeout(() => {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
          }, 50);
        };
      }
    }
  };

  return (
    <>
      <MainModal
        className='receipt-modal'
        onClose={onCancel}
        modalTitle='Receipt'
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={false}
        cancelButtonText='Cancel'
        saveButtonText='Print'
        closeButtonText='Close'
        hideFooter={false}
      >
        <div className='receipt-containter'>
          <div className='receipt'>{modalData.dataId ? generateReceipt(modalData.dataId) : ''}</div>
        </div>
      </MainModal>
    </>
  );
};

export default TransactionReceipt;
