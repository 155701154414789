import React from 'react';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { msalConfig } from './config/authConfig';
import store from './store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.scss';

const theme = createTheme({
  typography: {
    fontFamily: 'Mulish',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 577,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          color: #fff;
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
      `,
    },
  },
});

const msalInstance = new PublicClientApplication(msalConfig);

async function initializeMsal() {
  await msalInstance.initialize();
}

initializeMsal()
  .then(() => {
    const container = document.getElementById('root');
    if (!container) throw new Error('Failed to find the root element');
    const root = createRoot(container);
    root.render(
      //<React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <StyledEngineProvider injectFirst>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </Provider>
          </StyledEngineProvider>
        </MsalProvider>
      //</React.StrictMode>
    );
  })
  .catch((error) => {
    prompt('Please contact technical support, MSAL initialization error:', error);
  });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
