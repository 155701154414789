import { put, select, takeLatest, call, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectOrganisationId } from '../auth/selectors';
import {
  setUsersContent,
  setUserStatus,
  setUsersError,
  setUserInfo,
  setUserPermissions,
  setUserListPermissionGroups,
  setUserContent,
  setUserPermissionsError,
} from './reducers';
import * as actions from './actions';
import * as services from './services';
import * as fieldHelper from '../../utilities/field-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { selectUsersContent, selectUserInfo, selectUserListPermissionGroups } from './selectors';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { PermissionAssignmentModel, UserModel, UserErrorModel } from '../../models/usersModel';
import { closeModal, setModalError, setModalActionStatus } from '../modals/reducers';
import { UserPermissionModel } from '../../models/userPermissionModel';
import { PermissionGroupItemsModel } from '../../models/permissionGroupModel';
import { GuestUserEntity, PermissionAssignmentEntity, UserEntity, UsersResponse } from '../../entities/users';
import { PermissionGroupResponse } from '../../entities/permission-group';
import { UserPermissionAssignmentEntity, UserPermissionsEntity } from '../../entities/userPermission';
import { Organisation } from '../../entities/organisation';
import { setSnackBarSuccess, setSnackBarError, setSnackBarWarning } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { setGenericErrorData } from '../generic-error/reducers';
import { closeDialogBox, setDialogBoxActionStatus } from '../dialog-box/reducers';
import { setPermissionGroupListName } from '../permission-group/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { MapPermissionGroupEntityToModel } from '../permission-group/sagas';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_USERS, loadUsers);
  yield takeLatest(actions.LOAD_USER_PERMISSION, loadUserPermissions);
  yield takeLatest(actions.LOAD_USER_INFO, loadUserInfo);
  yield takeLatest(actions.DELETE_USER, deleteUser);
  yield takeLatest(actions.CREATE_USER, createUser);
  yield takeLatest(actions.EDIT_USER, editUser);
  yield takeLatest(actions.LOAD_MY_ACCOUNT, loadMyAccount);
  yield takeLatest(actions.EDIT_MY_ACCOUNT, editMyAccount);
  yield takeLatest(actions.SEND_USER_INVITE, sendInvite);
  yield takeLatest(actions.INVITE_GUEST, inviteGuest);
}

export function* loadUsers() {
  try {
    yield put(setUserStatus(LoadingStatus.LOADING));
    const organizationId: string = yield select(selectOrganisationId);

    let permissionGroupResponse: PermissionGroupResponse = {} as PermissionGroupResponse;
    const usersResponse: UsersResponse = yield call(services.getAllUsers, organizationId);
    let permissionGroupRecords: PermissionGroupItemsModel[] = yield call(
      MapPermissionGroupEntityToModel,
      permissionGroupResponse
    );
    let records: UserModel[] = yield call(mapUserEntityToModelList, usersResponse);
    yield put(setUsersContent(records));
    yield put(setUserStatus(LoadingStatus.SUCCESS));

    permissionGroupResponse = yield call(services.getAllPermissionGroup, organizationId);
    let groupNameList: KeyValuePair[] = yield call(MapPermissionGroupEntityToKeyValuePair, permissionGroupResponse);
    yield put(setPermissionGroupListName(groupNameList));
    permissionGroupRecords = yield call(MapPermissionGroupEntityToModel, permissionGroupResponse);
    records = yield call(mapUserEntityToModelList, usersResponse);
    yield put(setUsersContent(records));

    yield put(setUserListPermissionGroups(permissionGroupRecords));
  } catch (error: any) {
    yield put(setUsersError());
    if (!!error || error?.message?.length < 1) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* loadUserPermissions() {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const users: UserModel[] = yield select(selectUsersContent);

    yield delay(500);

    if (users) {
      for (let i = 0; i < users.length; i++) {
        if (!users[i].permissionsLoaded) {
          try {
            let userPermissionResponse: UserPermissionAssignmentEntity = yield call(
              services.getUserPermissionAssignments,
              users[i].id,
              organisationId
            );

            const permissionGroupRecords: PermissionGroupItemsModel[] = yield select(selectUserListPermissionGroups);
            const result: UserModel = {
              id: users[i].id,
              organisationId: users[i].organisationId,
              firstName: fieldHelper.getDefaultStringvalue(users[i].firstName),
              lastName: fieldHelper.getDefaultStringvalue(users[i].lastName),
              email: fieldHelper.getDefaultStringvalue(users[i].email),
              countryCode: fieldHelper.getDefaultStringvalue(users[i].mobileNumber)?.slice(0, 3),
              mobileNumber: fieldHelper.getDefaultStringvalue(users[i].mobileNumber)?.slice(3),
              permissionGroupName:
                !!userPermissionResponse.items.length && userPermissionResponse.items.length > 0
                  ? permissionGroupRecords.filter(
                      (data) => data.id === userPermissionResponse.items[0]?.permissionsGroupId
                    )[0]?.name
                  : 'Unspecified',
              permissionCount:
                !!userPermissionResponse.items.length && userPermissionResponse.items.length > 0
                  ? permissionGroupRecords
                      .filter((data) => data.id === userPermissionResponse.items[0]?.permissionsGroupId)[0]
                      ?.permissionNames.length.toString()
                  : 'No Permissions',
              permissionAssignmentId:
                !!userPermissionResponse.items.length && userPermissionResponse.items.length > 0
                  ? userPermissionResponse.items[0].id
                  : undefined,
              permissionsLoaded: true,
              membershipType: users[i]?.membershipType,
              name: users[i]?.name,
            };
            yield put(setUserContent(result));
          } catch {
            yield put(setUserPermissionsError(users[i].id));
          }
        }
      }
    }
  } catch (error: any) {
    yield put(setUsersError());
    if (!!error || error?.message?.length < 1) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
  }
}

export function* loadUserInfo(action: PayloadAction<string>) {
  try {
    yield put(setUserStatus(LoadingStatus.LOADING));
    const organizationId: string = yield select(selectOrganisationId);
    let response: UserEntity = yield call(services.getUserById, action.payload, organizationId);

    let userPermissionResponse: UserPermissionAssignmentEntity = yield call(
      services.getUserPermissionAssignments,
      action.payload,
      organizationId
    );

    let record: UserModel = yield call(MapUserEntityToUserModel, response, userPermissionResponse);

    yield put(setUserInfo(record));
    yield put(setUserStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setUsersError());
    yield put(clearAllFieldValidation());
  }
}

export function* loadMyAccount(action: PayloadAction<string>) {
  try {
    yield put(setUserStatus(LoadingStatus.LOADING));
    const organizationId: string = yield select(selectOrganisationId);
    let response: UserEntity = yield call(services.getUserById, action.payload, organizationId);
    let record: UserModel = yield call(MapUserEntityToUserModel, response);
    yield put(setUserInfo(record));

    let permissionResponse: UserPermissionsEntity = yield call(
      services.getUserPermissions,
      action.payload,
      organizationId
    );
    let userPermissions: UserPermissionModel[] = yield call(MapUserPermissionEntityToModel, permissionResponse);
    yield put(setUserPermissions(userPermissions));
    yield put(setUserStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setUsersError());
  }
}

export function* deleteUser(action: PayloadAction<UserModel>) {
  yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
  const organizationId: string = yield select(selectOrganisationId);
  try {
    if (action.payload.permissionAssignmentId) {
      yield call(
        services.deleteUserPermissionAssignment,
        action.payload.id,
        action.payload.permissionAssignmentId,
        organizationId
      );
    }
  } catch (error) {
    if (!checkPermAssignmentNotFound(error)) {
      yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
      let errorMsg = getApiErrorMessage(error);
      yield put(setSnackBarError(errorMsg));
      return;
    }
  }
  try {
    yield call(services.deleteUserData, action.payload.id, organizationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.USER_DELETE_SUCCESS));
    yield call(loadUsers);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* createUser(action: PayloadAction<UserModel>) {
  const errorStatus = {
    userHasError: true,
    invitationHasError: true,
    permissionAssignemtHasError: true,
  } as UserErrorModel;
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    //Create User
    const organisationId: string = yield select(selectOrganisationId);
    let userEntity: UserEntity = MapUserInfoModalToEntity(action.payload, organisationId);
    let userCreateResponse: UserEntity = yield call(services.createUserInfo, userEntity);
    yield put(setIsPageDirty(false));
    errorStatus.userHasError = false;

    if (userCreateResponse) {
      //Permission Assignment
      let permissionAssignment: PermissionAssignmentEntity = MapUserPermissionAssignmentToEntity(
        userEntity,
        action.payload.permissionGroupId
      );
      yield call(services.assignPermission, permissionAssignment, userCreateResponse.id);

      errorStatus.permissionAssignemtHasError = false;
      //Send Invite
      let organisationEntity: Organisation = {
        organisationId: organisationId,
      };
      yield call(services.sendInvite, userCreateResponse.id, organisationEntity);
      errorStatus.invitationHasError = false;
    }
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    if (errorStatus.userHasError) {
      yield put(setSnackBarError(errorMsg));
      yield put(setBackDropActionStatus(LoadingStatus.ERROR));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      return;
    }
    if (errorStatus.permissionAssignemtHasError) {
      yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
      yield delay(1500);
      yield put(setSnackBarWarning(Messages.USER_PERMISSION_ERROR));
      yield put(setBackDropActionStatus(LoadingStatus.WARNING));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      yield call(loadUsers);
      return;
    }
    if (errorStatus.invitationHasError) {
      yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
      yield delay(1500);
      yield put(setSnackBarSuccess(Messages.USER_PERMISSION_SUCCESS));
      yield delay(1500);
      yield put(setSnackBarWarning(Messages.USER_INVITE_ERROR));
      yield put(setBackDropActionStatus(LoadingStatus.WARNING));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      yield call(loadUsers);
      return;
    }
  }
}

export function* editUser(action: PayloadAction<UserModel>) {
  const errorStatus = {
    userHasError: true,
    permissionAssignemtHasError: true,
  } as UserErrorModel;
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let userEntity: UserEntity = MapUserInfoModalToEntity(action.payload, organisationId);
    if (!action.payload.isUpdatePermissionOnly) yield call(services.editUserInfo, userEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    errorStatus.userHasError = false;
    if (!action.payload.permissionAssignmentId) {
      let permissionAssignment: PermissionAssignmentEntity = MapUserPermissionAssignmentToEntity(
        userEntity,
        action.payload.permissionGroupId
      );
      yield call(services.assignPermission, permissionAssignment, action.payload.id);
    } else {
      let permissionAssignment: PermissionAssignmentEntity = MapUserPermissionAssignmentToEntity(
        userEntity,
        action.payload.permissionGroupId
      );
      yield call(
        services.editPermission,
        permissionAssignment,
        action.payload.id,
        action.payload.permissionAssignmentId
      );
    }
    errorStatus.permissionAssignemtHasError = false;
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    if (errorStatus.userHasError) {
      try {
        const organisationId: string = yield select(selectOrganisationId);
        let userEntity: UserEntity = MapUserInfoModalToEntity(action.payload, organisationId);
        let permissionAssignment: PermissionAssignmentEntity = MapUserPermissionAssignmentToEntity(
          userEntity,
          action.payload.permissionGroupId
        );
        yield call(
          services.editPermission,
          permissionAssignment,
          action.payload.id,
          action.payload.permissionAssignmentId
        );
        yield delay(1500);
        yield put(setSnackBarSuccess(Messages.USER_PERMISSION_SUCCESS));
      } catch {
        yield put(setBackDropError(true));
        yield put(hideBackdrop());
        yield put(setSnackBarWarning(Messages.USER_PERMISSION_ERROR));
        return;
      }
      yield put(setBackDropActionStatus(LoadingStatus.ERROR));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      yield delay(1500);
      yield put(setSnackBarError(errorMsg));
      return;
    }
    if (errorStatus.permissionAssignemtHasError) {
      yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
      yield delay(1500);
      yield put(setBackDropActionStatus(LoadingStatus.WARNING));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      yield put(setSnackBarWarning(Messages.USER_PERMISSION_ERROR));
      yield call(loadUsers);
      return;
    }
  }
}

export function* editMyAccount(action: PayloadAction<UserModel>) {
  try {
    yield put(setModalActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let userEntity: UserEntity = MapMyAccountInfoModalToEntity(action.payload, organisationId);
    yield call(services.editUserInfo, userEntity, action.payload.id);
    yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
    yield put(closeModal());
    yield call(loadMyAccount, {
      type: actions.LOAD_MY_ACCOUNT,
      payload: action.payload.id,
    });
  } catch (error) {
    yield put(setModalError(true));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* sendInvite(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let organisationEntity: Organisation = {
      organisationId: organisationId,
    };
    yield call(services.sendInvite, action.payload, organisationEntity);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.USER_INVITE_SUCCESS));
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* inviteGuest(action: PayloadAction<UserModel>) {
  const errorStatus = {
    userHasError: true,
    invitationHasError: true,
    permissionAssignemtHasError: true,
  } as UserErrorModel;
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));

    //Invite Guest User
    const organisationId: string = yield select(selectOrganisationId);
    let userEntity: GuestUserEntity = MapGuestUserInfoModalToEntity(action.payload, organisationId);
    let inviteGuestResponse: UserEntity = yield call(services.inviteGuest, userEntity);
    yield put(setIsPageDirty(false));
    errorStatus.userHasError = false;

    if (inviteGuestResponse) {
      //Permission Assignment
      let permissionAssignment: PermissionAssignmentEntity = MapGuestUserPermissionAssignmentToEntity(
        userEntity,
        action.payload.permissionGroupId
      );
      yield call(services.assignPermission, permissionAssignment, inviteGuestResponse.id);
      errorStatus.permissionAssignemtHasError = false;
      //Send Invite
      let organisationEntity: Organisation = {
        organisationId: organisationId,
      };
      yield call(services.sendInvite, inviteGuestResponse.id, organisationEntity);
      errorStatus.invitationHasError = false;
    }
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
    yield put(hideBackdrop());
    yield call(loadUsers);
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    if (errorStatus.userHasError) {
      yield put(setSnackBarError(errorMsg));
      yield put(setBackDropActionStatus(LoadingStatus.ERROR));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      return;
    }
    if (errorStatus.permissionAssignemtHasError) {
      yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
      yield delay(1500);
      yield put(setSnackBarWarning(Messages.USER_PERMISSION_ERROR));
      yield put(setBackDropActionStatus(LoadingStatus.WARNING));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      yield call(loadUsers);
      return;
    }
    if (errorStatus.invitationHasError) {
      yield put(setSnackBarSuccess(Messages.USER_SAVE_SUCCESS));
      yield delay(1500);
      yield put(setSnackBarSuccess(Messages.USER_PERMISSION_SUCCESS));
      yield delay(1500);
      yield put(setSnackBarWarning(Messages.USER_INVITE_ERROR));
      yield put(setBackDropActionStatus(LoadingStatus.WARNING));
      yield put(setBackDropError(true));
      yield put(hideBackdrop());
      yield call(loadUsers);
      return;
    }
  }
}

export function* assignPermission(action: PayloadAction<PermissionAssignmentModel>) {
  try {
    const userInfo: UserModel = yield select(selectUserInfo);
    yield call(services.assignPermission, action.payload, userInfo.id);
    yield put(setSnackBarSuccess(Messages.USER_INVITE_SUCCESS));
    yield call(loadUsers);
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export const MapUserEntityToUserModel = (user: UserEntity, userPermissionResponse?: UserPermissionAssignmentEntity) => {
  if (!!user) {
    let model = {
      id: user.id,
      firstName: fieldHelper.getDefaultStringvalue(user.firstName),
      lastName: fieldHelper.getDefaultStringvalue(user.lastName),
      email: fieldHelper.getDefaultStringvalue(user.email),
      countryCode: user.mobileNumber ? fieldHelper.getDefaultStringvalue(user.mobileNumber)?.slice(0, 3) : undefined,
      mobileNumber: user.mobileNumber ? fieldHelper.getDefaultStringvalue(user.mobileNumber)?.slice(3) : undefined,
      organisationId: user.organisationId,
      permissionGroupId: !!userPermissionResponse
        ? userPermissionResponse.items.length > 0
          ? userPermissionResponse.items[0].permissionsGroupId
          : ''
        : '',
      permissionAssignmentId: !!userPermissionResponse
        ? userPermissionResponse.items.length > 0
          ? userPermissionResponse.items[0].id
          : undefined
        : undefined,
      hasAccessToAllOrganisations: userPermissionResponse?.items[0]?.hasAccessToAllOrganisations
        ? userPermissionResponse?.items[0]?.hasAccessToAllOrganisations
        : undefined,
      membershipType: user.membershipType,
    } as UserModel;
    return model;
  }

  return {} as UserModel;
};

const MapUserInfoModalToEntity = (model: UserModel, organisationId: string) => {
  if (model) {
    let userEntity = {
      id: fieldMappingHelper.sanitizeStringValue(model.id),
      organisationId: fieldMappingHelper.sanitizeStringValue(organisationId),
      email: fieldMappingHelper.sanitizeStringValue(model.email),
      mobileNumber: model.mobileNumber
        ? `${fieldMappingHelper.sanitizeStringValue(model.countryCode)}${fieldMappingHelper.sanitizeStringValue(
            model.mobileNumber
          )}`
        : undefined,
      firstName: fieldMappingHelper.sanitizeStringValue(model.firstName),
      lastName: fieldMappingHelper.sanitizeStringValue(model.lastName),
      hasAccessToAllOrganisations: model.hasAccessToAllOrganisations ? model.hasAccessToAllOrganisations : undefined,
    } as UserEntity;
    return userEntity;
  }
  return {} as UserEntity;
};

const MapGuestUserInfoModalToEntity = (model: UserModel, organisationId: string) => {
  if (model) {
    let guestUserEntity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(organisationId),
      email: fieldMappingHelper.sanitizeStringValue(model.email),
    } as GuestUserEntity;
    return guestUserEntity;
  }
  return {} as GuestUserEntity;
};

const MapMyAccountInfoModalToEntity = (model: UserModel, organisationId: string) => {
  if (model) {
    let userEntity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(organisationId),
      email: fieldMappingHelper.sanitizeStringValue(model.email),
      firstName: fieldMappingHelper.sanitizeStringValue(model.firstName),
      lastName: fieldMappingHelper.sanitizeStringValue(model.lastName),
    } as UserEntity;
    return userEntity;
  }
  return {} as UserEntity;
};

const MapUserPermissionAssignmentToEntity = (entity: UserEntity, permissionGroupId?: string) => {
  if (entity) {
    let permissionAssignmentEntity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(entity.organisationId),
      permissionsGroupId: fieldMappingHelper.sanitizeStringValue(permissionGroupId),
      hasAccessToAllOrganisations: entity.hasAccessToAllOrganisations ? entity.hasAccessToAllOrganisations : undefined,
    } as PermissionAssignmentEntity;
    return permissionAssignmentEntity;
  }

  return {} as PermissionAssignmentEntity;
};

const MapGuestUserPermissionAssignmentToEntity = (entity: GuestUserEntity, permissionGroupId?: string) => {
  if (entity) {
    let permissionAssignmentEntity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(entity.organisationId),
      permissionsGroupId: fieldMappingHelper.sanitizeStringValue(permissionGroupId),
      hasAccessToAllOrganisations: false,
    } as PermissionAssignmentEntity;
    return permissionAssignmentEntity;
  }

  return {} as PermissionAssignmentEntity;
};

const MapUserPermissionEntityToModel = (response: UserPermissionsEntity) => {
  if (response && response.items.length > 0) {
    const result: UserPermissionModel[] = response.items.map((item) => {
      return {
        name: fieldHelper.getDefaultStringvalue(item.name),
        description: fieldHelper.getDefaultStringvalue(item.description),
        enabled: true,
        isVisible: true,
      };
    });
    return result;
  }

  return [] as UserPermissionModel[];
};

export const checkPermAssignmentNotFound = (error: any) => {
  if (error.response && error.response.status === 404) {
    return true;
  }
  return false;
};

const MapPermissionGroupEntityToKeyValuePair = (response: PermissionGroupResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};

export const mapUserEntityToModelList = (response: UsersResponse) => {
  if (response && response.items.length > 0) {
    const records: UserModel[] = [];
    if (response) {
      for (let user in response.items) {
        const result: UserModel = {
          id: response.items[user].id,
          organisationId: response.items[user].organisationId,
          firstName: fieldHelper.getDefaultStringvalue(response.items[user].firstName),
          lastName: fieldHelper.getDefaultStringvalue(response.items[user].lastName),
          email: fieldHelper.getDefaultStringvalue(response.items[user].email),
          countryCode: fieldHelper.getDefaultStringvalue(response.items[user].mobileNumber)?.slice(0, 3),
          mobileNumber: fieldHelper.getDefaultStringvalue(response.items[user].mobileNumber)?.slice(3),
          permissionsLoaded: false,
          membershipType: response.items[user].membershipType,
          name: response.items[user]?.name,
        };
        records.push(result);
      }
    }
    return records;
  }
  return [] as UserModel[];
};
