import { LoadingStatus } from '../../constants/loading-constants';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

interface PageConfigurationState {
  pageConfigurationState: string;
  pageConfiguration: PageSettingStateModel;
  isPageDirty: boolean;
  hasError: boolean;
  isPageReachBottom: boolean;
  isCancelFromChange: boolean;
}

export const initialState: PageConfigurationState = {
  pageConfigurationState: LoadingStatus.LOADING,
  isPageDirty: false,
  isPageReachBottom: false,
  isCancelFromChange: true,
} as PageConfigurationState;
