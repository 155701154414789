import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { Doughnut } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

interface PieChartProps {
  chartData: KeyValuePair[];
  totalItemCount: number;
  width?: number;
  height?: number;
}

export const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const { chartData, totalItemCount, width, height } = props;
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const tooltipFooter = () => {
    return 'Total Items: ' + totalItemCount;
  };

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (ctx) {
        new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: chartData.map((item) => item.key),
            datasets: [
              {
                data: chartData.map((item) => item.value),
                backgroundColor: chartData.map((item) => String(item.additionalValue)),
                borderColor: chartData.map((item) => String(item.additionalValue)),
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  padding: 20,
                  font: {
                    size: 14,
                    family: 'Mulish',
                  },
                },
              },
              tooltip: {
                backgroundColor: '#3A4856',
                bodyFont: {
                  size: 14,
                  family: 'Mulish',
                },
                footerFont: {
                  size: 14,
                  family: 'Mulish',
                  weight: 0,
                },
                callbacks: {
                  title: () => '',
                  label: (context) => {
                    return ` ${context.label}: ${context.dataset.data[context.dataIndex]}`;
                  },

                  footer: tooltipFooter,
                },
              },
            },
          },
        });
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div>
        <canvas ref={chartRef} width={width ? width : 400} height={height ? height : 400} />
      </div>
    </React.Fragment>
  );
};

const DoughnutChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const { chartData, totalItemCount, width, height } = props;
  const chartRef = useRef<ChartJSOrUndefined<'doughnut', (string | number | boolean)[], string | number>>(null);

  const displayData = chartData.filter((it) => Number(it.value) > 0);
  const data = {
    labels: displayData.map((item) => item.key),
    datasets: [
      {
        labels: displayData.map((item) => item.key),
        data: displayData.map((item) => item.value),
        backgroundColor: displayData.map((item) => String(item.additionalValue)),
        borderColor: displayData.map((item) => String(item.additionalValue)),
        borderWidth: 1,
        polyline: {
          color: 'gray',
          labelColor: 'gray',
          formatter: (value: any) => `${value}`,
        },
      },
    ],
  };

  const getSuitableY = (y: any, yArray: any[] = [], direction: string) => {
    let result = y;
    yArray.forEach((existedY) => {
      if (existedY - 14 < result && existedY + 14 > result) {
        if (direction === 'right') {
          result = existedY + 14;
        } else {
          result = existedY - 14;
        }
      }
    });

    return result;
  };

  const tooltipFooter = () => {
    return 'Total Items: ' + totalItemCount;
  };

  let plugins = [
    {
      id: 'outer tooltip list',
      afterDraw: (chart: any) => {
        // Display only when the data list has more than one data and have enough space
        if (displayData.length <= 1 || (chartRef.current?.width && chartRef.current?.width < 530)) return;
        const ctx = chart.ctx;
        ctx.save();
        ctx.font = "12px 'Mulish'";
        const leftLabelCoordinates: any[] = [];
        const rightLabelCoordinates: any[] = [];
        const chartCenterPoint = {
          x: (chart.chartArea.right - chart.chartArea.left) / 2 + chart.chartArea.left,
          y: (chart.chartArea.bottom - chart.chartArea.top) / 2 + chart.chartArea.top - 5,
        };
        chart.config.data.labels.forEach((label: any, i: any) => {
          const meta = chart.getDatasetMeta(0);
          const arc = meta.data[i];
          const dataset = chart.config.data.datasets[0];

          // Prepare data to draw
          const centerPoint = arc.getCenterPoint();

          let color = chart.config._config.data.datasets[0].backgroundColor[i];
          let labelColor = chart.config._config.data.datasets[0].backgroundColor[i];

          const angle = Math.atan2(centerPoint.y - chartCenterPoint.y, centerPoint.x - chartCenterPoint.x);

          // important point 2, this point overlapsed with existed points
          const point2X =
            chartCenterPoint.x +
            Math.cos(angle) * (centerPoint.x < chartCenterPoint.x ? arc.outerRadius + 10 : arc.outerRadius + 10);
          let point2Y =
            chartCenterPoint.y +
            Math.sin(angle) * (centerPoint.y < chartCenterPoint.y ? arc.outerRadius + 15 : arc.outerRadius + 15);

          let suitableY;
          if (point2X < chartCenterPoint.x) {
            // on the left
            suitableY = getSuitableY(point2Y, leftLabelCoordinates, 'left');
          } else {
            // on the right
            suitableY = getSuitableY(point2Y, rightLabelCoordinates, 'right');
          }

          point2Y = suitableY;

          let value = dataset.data[i];

          let edgePointX =
            point2X < chartCenterPoint.x
              ? chartCenterPoint.x - arc.outerRadius - 10
              : chartCenterPoint.x + arc.outerRadius + 10;

          if (point2X < chartCenterPoint.x) {
            leftLabelCoordinates.push(point2Y);
          } else {
            rightLabelCoordinates.push(point2Y);
          }

          //DRAW CODE
          // first line: connect between arc's center point and outside point
          ctx.lineWidth = 2;
          ctx.strokeStyle = color;
          ctx.beginPath();
          ctx.moveTo(centerPoint.x, centerPoint.y);
          ctx.lineTo(point2X, point2Y);
          ctx.stroke();
          // second line: connect between outside point and chart's edge
          ctx.beginPath();
          ctx.moveTo(point2X, point2Y);
          ctx.lineTo(edgePointX, point2Y);
          ctx.stroke();
          // fill custom label
          const labelAlignStyle = edgePointX < chartCenterPoint.x ? 'right' : 'left';
          const labelX = edgePointX < chartCenterPoint.x ? edgePointX : edgePointX + 0;
          const labelY = point2Y + 7;
          ctx.textAlign = labelAlignStyle;
          ctx.textBaseline = 'bottom';
          ctx.font = '14px Mulish';
          ctx.fillStyle = labelColor;
          ctx.fillText(`${label}: ${value}`, labelX, labelY);
        });
        ctx.restore();
      },
    },
    {
      id: 'space between chart and legend',
      beforeInit: function (chart: any) {
        chart.legend.afterFit = function () {
          this.height = this.height + 50;
        };
      },
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
        left: 0,
        right: 0,
        bottom: 30,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          align: 'start',
          padding: 20,
          color: 'white',
          font: {
            size: 14,
            family: 'Mulish',
          },
        },
      },

      tooltip: {
        mode: 'dataset',
        backgroundColor: '#3A4856',
        bodyFont: {
          size: 14,
          family: 'Mulish',
        },
        footerFont: {
          size: 14,
          family: 'Mulish',
          weight: '0',
        },
        callbacks: {
          title: () => '',
          label: (context: any) => {
            return ` ${context.label}: ${context.dataset.data[context.dataIndex]}`;
          },
          footer: tooltipFooter,
        },
      },
    },
  };

  return (
    <Doughnut
      ref={chartRef}
      width={width ? width : 750}
      height={height ? height : 500}
      //@ts-ignore
      options={options}
      data={data}
      plugins={plugins}
    />
  );
};

export default DoughnutChart;
