import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectTankHistoryState = (state: RootState) => state.TankHistoryState;

export const selectTankHistoryData = createSelector(
  selectTankHistoryState,
  (state) => state.tankHisotoryData
);

export const selectTankHistoryStatus = createSelector(
  selectTankHistoryState,
  (state) => state.tankHistoryStatus
);

