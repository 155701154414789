import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Divider } from '@mui/material';
import classNames from 'classnames';
import './confirm-dialog.scss';
import CustomButton from '../button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';

interface ConfirmDialogBoxProps {
  open: any;
  header?: string;
  context: string;
  onClose: any;
  confirmDialog: any;
  closeTextButton: string;
  confirmTextButton: string;
  className?: string;
}

const ConfirmDialogBox: React.FC<ConfirmDialogBoxProps> = (
  props: ConfirmDialogBoxProps
) => {
  const {
    open,
    header,
    context,
    onClose,
    confirmDialog,
    closeTextButton,
    confirmTextButton,
    className,
  } = props;

  const onCloseHandler = (event: any, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };

  return (
    <Dialog
      className='dialog-container'
      open={open}
      onClose={onCloseHandler}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {header && (
        <React.Fragment>
          <DialogTitle id='alert-dialog-title' className='dialogbg'>
            {header}
          </DialogTitle>
          <Divider />
        </React.Fragment>
      )}

      <DialogContent className='dialog-body'>
        <DialogContentText
          id='alert-dialog-description'
          className={classNames('dialog-context', className)}
        >
          {context}
        </DialogContentText>
      </DialogContent>
      <DialogActions className='dialogbg'>
        <CustomButton
          className={classNames(
            'dialog-buttons',
            'dialog-buttons-no',
            'secondary-button'
          )}
          onClick={onClose}
          buttonStyle={ButtonStyle.SECONDARY}
        >
          {closeTextButton}
        </CustomButton>
        <CustomButton
          className={classNames(
            'dialog-buttons',
            'dialog-buttons-yes',
            'primary-button'
          )}
          onClick={confirmDialog}
          buttonStyle={ButtonStyle.PRIMARY}
        >
          {confirmTextButton}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogBox;
