import { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated, useAccount } from '@azure/msal-react';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../config/authConfig';

const useAuthenticationManager = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(useAccount());

  useEffect(() => {
    // Setting the active account for every future redirection from B2C
    instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse) {
          instance.setActiveAccount(tokenResponse.account);
          setActiveAccount(tokenResponse.account);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [instance]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  }, [isAuthenticated, inProgress, activeAccount]);

  // Optional - This will update account state if a user signs in from another tab or window
  instance.enableAccountStorageEvents();

  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account ? payload.account : ({} as AccountInfo);

      instance.setActiveAccount(account);
    }
  });

  return {
    instance,
    inProgress,
    isAuthenticated,
    account,
  };
};

export default useAuthenticationManager;
