import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import './loading-skeleton.scss';

interface LoadingSkeletonProps {
  customWidth?: number;
  customHeight?: number;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = (props: LoadingSkeletonProps) => {
  const { customWidth, customHeight } = props;

  return (
    <React.Fragment>
      <Skeleton className='loading-skeleton' width={customWidth ?? 170} height={customHeight ?? 20} />
    </React.Fragment>
  );
};

export default LoadingSkeleton;
