import { ValidationError } from '../../models/baseModels/validationModel';

interface FieldsValidationState {
  hasError: boolean;
  Validations: ValidationError[];
  hasValidationError: boolean;
  showValidationError: boolean;
  customValidationMessage: string;
}

export const initialState: FieldsValidationState = {
  Validations: [] as ValidationError[],
  hasValidationError: false,
  showValidationError: false,
  hasError: false,
  customValidationMessage: '',
};
