import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import SubNavPaneControl from './index';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectOrganisationId } from '../../../store/auth/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),    
    selectedOrganisationId: selectOrganisationId(state),
  };
};

const SubNavPane = connect(mapStateToProps)(SubNavPaneControl);

export default SubNavPane;
