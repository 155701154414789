import { ClickAwayListener, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useState } from 'react';
import './drawer.scss';

interface DrawerProps {
  open: boolean;
  children?: React.ReactNode;
  drawerOpenHandler?: (open: boolean) => void;
}

const DrawerControl = (props: DrawerProps) => {
  const { open, children, drawerOpenHandler } = props;

  const [drawerOpen, setDrawerOpen] = useState(open);

  useEffect(() => {
    setDrawerOpen(open);
  }, [open]);

  const clickAwayHandler = useCallback((event: any) => {
    const target = event.target;
    // Add some expections here, drawer should stay open
    if (target.tagName.toLowerCase() === 'span' && target.textContent === 'Details') return;
    if (target.matches('.common-button-icon-container')) return;
    if (target.tagName === 'svg' && target.classList.contains('site-status-icon')) return;
    if (
      target.tagName.toLowerCase() === 'button' &&
      target.classList.contains('item-inline-button') &&
      target.classList.contains('status-details-button')
    )
      return;
    if (
      target.tagName.toLowerCase() === 'svg' &&
      target.classList.contains('MuiSvgIcon-root') &&
      target.classList.contains('MuiSvgIcon-fontSizeMedium') &&
      target.classList.contains('css-i4bv87-MuiSvgIcon-root')
    )
      return;
    if (
      target.tagName.toLowerCase() === 'li' &&
      target.classList.contains('MuiButtonBase-root') &&
      target.classList.contains('MuiMenuItem-root')
    )
      return;
    if (target.tagName.toLowerCase() === 'svg' && target.classList.contains('MuiSvgIcon-fontSizeMedium')) return;
    if (target.tagName.toLowerCase() === 'button' && target.classList.contains('ellipsisBtn')) return;
    if (target.tagName.toLowerCase() === 'path') return;

    if (drawerOpenHandler) {
      setDrawerOpen(false);
      drawerOpenHandler(false);
    }
  }, []);

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
    if (drawerOpenHandler) {
      drawerOpenHandler(newOpen);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    if (drawerOpenHandler) {
      drawerOpenHandler(false);
    }
  };

  return (
    <ClickAwayListener
      mouseEvent='onMouseDown'
      touchEvent='onTouchStart'
      onClickAway={(e: any) => {
        if (drawerOpen) {
          clickAwayHandler(e);
        }
      }}
    >
      <Drawer
        className='drawer-container'
        anchor='right'
        variant='persistent'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <CloseIcon className='drawer-close-icon' onClick={handleDrawerClose} />

        {children && children}
      </Drawer>
    </ClickAwayListener>
  );
};

export default DrawerControl;
