import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceSchedListErrorModel, PriceScheduleInfoModel } from '../../models/priceScheduleModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { initialState } from './priceSchedInitialState';

const PriceScheduleState = createSlice({
  name: 'priceScheduleState',
  initialState,
  reducers: {
    setPriceSchedStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.priceSchedStatus = action.payload;
    },
    setPriceSchedDetailStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.priceSchedDetailStatus = action.payload;
    },
    setpriceSchedContent: (state, action: PayloadAction<PriceScheduleInfoModel[]>) => {
      state.hasError = false;
      state.priceSchedContent = action.payload;
    },
    setPriceScheduleInfoContent: (state, action: PayloadAction<PriceScheduleInfoModel>) => {
      state.priceSchedInfo = action.payload;
      state.hasError = false;
    },
    setPriceSchedServerError: (state) => {
      state.hasError = true;
      state.priceSchedContent = [];
      state.priceSchedStatus = LoadingStatus.ERROR;
    },
    setPriceSchedListError: (state, action: PayloadAction<PriceSchedListErrorModel[]>) => {
      state.priceSchedListError = action.payload;
      state.priceSchedContent = [];
    },
    setPriceSchedDetailError: (state) => {
      state.hasError = true;
      state.priceSchedInfo = {} as PriceScheduleInfoModel;
      state.priceSchedDetailStatus = LoadingStatus.ERROR;
    },
    resetPriceSchedState: () => {
      return initialState;
    },
  },
});

export const {
  setPriceSchedStatus,
  setPriceSchedDetailStatus,
  setpriceSchedContent,
  setPriceScheduleInfoContent,
  setPriceSchedServerError,
  setPriceSchedListError,
  setPriceSchedDetailError,
  resetPriceSchedState,
} = PriceScheduleState.actions;

export default PriceScheduleState.reducer;
