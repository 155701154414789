import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData } from '../../../store/modals/selectors';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { TerminalOperationActionModel } from '../../../models/terminalOperationModel';
import {
  selectTerminalDirectoryList,
  selectTerminalDirectoryStatus,
} from '../../../store/terminal-operations/selectors';
import * as modalActions from '../../../store/modals/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as terminalOperationActions from '../../../store/terminal-operations/actions';
import FileExplorer from './file-explorer';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    terminalDirectoryStatus: selectTerminalDirectoryStatus(state),
    terminalDirectoryList: selectTerminalDirectoryList(state),
    hasValidationError: selectHasValidationError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getTerminalDirectory: (data: TerminalOperationActionModel) =>
      dispatch({
        type: terminalOperationActions.GET_DIRECTORY_OPERATION,
        payload: data,
      }),
    clearFileDirectory: () => dispatch({ type: terminalOperationActions.CLEAR_FILE_DIRECTORY }),
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const FileExplorerForm = connect(mapStateToProps, mapDispatchToProps)(FileExplorer);

export default FileExplorerForm;
