import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import {
  selectSitesContent,
  selectSitesStatus,
  selectSelectedSiteId,
} from '../../store/sites/selectors';
import { selectNavPaneStatus } from '../../store/nav-pane/selectors';
import * as siteActions from '../../store/sites/actions';
import * as headerActions from '../../store/header/actions';
import Sites from './sites';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import * as dialogActions from '../../store/dialog-box/actions';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const mapStateToProps = (state: RootState) => {
  return {
    sitesContent: selectSitesContent(state),
    sitesStatus: selectSitesStatus(state),
    selectedSiteId: selectSelectedSiteId(state),
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadSites: () => dispatch({ type: siteActions.LOAD_SITES_STATUS }),
    deleteSiteItem: (id: string) =>
      dispatch({ type: siteActions.DELETE_SITE, payload: id }), 
    setSelectedSiteId: (id: string) =>
      dispatch({ type: siteActions.SET_SELECTED_SITE_ID, payload: id }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const SiteList = connect(mapStateToProps, mapDispatchToProps)(Sites);

export default SiteList;
