import { initialState } from '././productInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { ActivityLogModel } from '../../models/activityLogModel';

const activityLogSlice = createSlice({
  name: 'activityLogState',
  initialState,
  reducers: {
    setActivityLogStatus: (state, action: PayloadAction<string>) => {
      state.activityLogStatus = action.payload;
      state.hasError = false;
    },
    setActivityLogData: (state, action: PayloadAction<ActivityLogModel[]>) => {
      state.activityLogData = action.payload;
      state.hasError = false;
    },
    setActivityLogResourceTypes: (state, action: PayloadAction<string[]>) => {
      state.activityLogResourceTypes = action.payload;
      state.hasError = false;
    },
    setActivityLogError: (state) => {
      state.hasError = true;
      state.activityLogData = [];
      state.activityLogStatus = LoadingStatus.ERROR;
    },
    resetActivityLogtate: () => {
      return initialState;
    },
  },
});

export const {
  setActivityLogStatus,
  setActivityLogData,
  setActivityLogResourceTypes,
  setActivityLogError,
  resetActivityLogtate,
} = activityLogSlice.actions;

export default activityLogSlice.reducer;
