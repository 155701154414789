import { DEFAULT_DROP_DOWN_VALUE } from '../constants/dropdown-constants';
import KeyValuePair from '../models/baseModels/keyValuePairModel';

export const sanitizeNumericValue = (value: number | undefined) => {
  if (value === 0) return Number(value);
  return !!value && !isNaN(Number(value)) ? Number(value) : undefined;
};

export const sanitizeStringValue = (value: string | null | undefined) => {
  return !!value ? value : undefined;
};

export const sanitizeStringValueFromDropdown = (value: string | null | undefined) => {
  return !!value && value !== 'Select' ? value : undefined;
};

export const sanitizeStringWithoutEmptySpaceBeforeOrAfter = (value: string | null | undefined) => {
  return !!value ? value.trim() : undefined;
};

export const convertBooleanToNumber = (value: boolean | undefined) => {
  if (value && !(typeof value == 'boolean')) {
    return undefined;
  }
  return value ? 1 : 0;
};

export const convertStringToNumber = (value: string) => {
  return isNaN(Number(value)) ? undefined : Number(value);
};

export const validateNumericValue = (value: string | number) => {
  return !isNaN(Number(value)) ? Number(value) : undefined;
};

export const validateBooleanValue = (value: boolean | number | string) => {
  if (value && !(typeof value == 'boolean') && !(typeof value == 'number') && !(typeof value == 'string')) {
    throw new Error(`${value}: Not a valid boolean value`);
  }
  if (typeof value == 'number' || typeof value == 'string') {
    if (value === 0 || value === '0') return false;
    else if (value === 1 || value === '1') return true;
    else throw new Error(`${value}: Not a valid boolean value`);
  }

  return value;
};

export const validateEnumValue = (value: string | number, list: KeyValuePair[]) => {
  if (!!value) {
    const found = list.some((x) => x.key.toString() === value.toString());
    if (!found) {
      throw new Error(`${value}: Not valid enum value`);
    }
  }
  return value;
};

export const validateEnumValueReturnBoolean = (value: string | number | string[], list: KeyValuePair[]) => {
  if (!!value) {
    if (Array.isArray(value)) {
      return value.every((val) => list.some((x) => x.key.toString() === val.toString()));
    }

    const found = list.some((x) => x.key.toString() === value.toString());
    if (!found) {
      return false;
    }
  }
  return true;
};

export const sanitizeStringDropDownValue = (value: string | undefined) => {
  return !!value && value !== DEFAULT_DROP_DOWN_VALUE ? value : undefined;
};

export const getDisplayValue = (inputValue: string | number | undefined, list: KeyValuePair[] | undefined) => {
  if (!!inputValue && !!list) {
    const item = list.find((x) => x.key.toString() === inputValue.toString());
    if (!!item) {
      return item?.value.toString();
    }
  }
  return '';
};

export const getKeyFromKeyValue = (inputValue: string | number | undefined, list: KeyValuePair[] | undefined) => {
  if (!!inputValue && !!list) {
    const item = list.find((x) => x.value.toString() === inputValue.toString());
    if (!!item) {
      return item?.key.toString();
    }
  }
  return '';
};

export const formatCamelCaseString = (value: string) => {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase());
};

export const formatStringToCamelCase = (value: string) => {
  return value
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
};

export const getLastCamelCaseFromString = (input: string): string => {
  const parts = input.split(/(?=[A-Z])/);
  return parts[parts.length - 1];
};

export const removeStringFromAnotherString = (removeString: string, input: string): string => {
  const regex = new RegExp(removeString, 'g');
  return input.replace(regex, '');
};
