import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './azureMapInitstate';
import { AddressDetailsModel } from '../../models/siteModel';

const azureMapSlice = createSlice({
  name: 'paginationState',
  initialState,
  reducers: {
    setAddressList: (state, action: PayloadAction<AddressDetailsModel[]>) => {
      state.addressList.length = 0;
      state.addressList = action.payload;
    },
    setSelectedAddressTimezone: (state, action: PayloadAction<string>) => {
      state.selectedAddressTimezone = action.payload;
    },
    setSelectedAddress: (state, action: PayloadAction<AddressDetailsModel>) => {
      state.selectedAddress = action.payload;
    },
  },
});

export const { setAddressList, setSelectedAddressTimezone, setSelectedAddress } = azureMapSlice.actions;

export default azureMapSlice.reducer;
