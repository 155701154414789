import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import Logout from './logout';
import { RootState } from '../../store/rootReducers';
import * as pageConfigActions from '../../store/page-configuration/actions';

const mapStateToProps = (state:RootState) => {
    return {}
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {   
      setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const LogOutPage = connect(mapStateToProps,mapDispatchToProps)(Logout);

export default LogOutPage;
