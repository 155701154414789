import { SvgIcon } from '@mui/material';
import './icons.scss';

interface HelpdeskIconProps{
    className?:string
}

const HelpdeskIcon = (props: HelpdeskIconProps) => {
    return (
        <SvgIcon className={`base-icon helpdesk-icon ${props.className}`} />
    );
};

export default HelpdeskIcon