import { LoadingStatus } from '../../constants/loading-constants';
import { PriceSignModel, PriceSignRecordModel } from '../../models/priceSignModel';

interface PriceSignState {
  priceSignStatus: string;
  priceSignDetailStatus: string;
  priceSignContent: PriceSignRecordModel[];
  priceSignItem: PriceSignModel;
  hasError: boolean;
}

export const initialState: PriceSignState = {
  priceSignStatus: LoadingStatus.LOADING,
  priceSignDetailStatus: LoadingStatus.LOADING,
} as PriceSignState;
