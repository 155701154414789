import { takeLatest, put } from 'redux-saga/effects';
import {
  clearAllFieldValidation,
  clearFieldValidation,
  setCustomValidationMessage,
  setFieldError,
  setFieldValidation,
  setShowValidationError,
} from './reducers';
import * as actions from './actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ValidationError } from '../../models/baseModels/validationModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.SET_FIELD_VALIDATION, fieldValidation);
  yield takeLatest(actions.REMOVE_FIELD_VALIDATION, removeFieldValidation);
  yield takeLatest(actions.REMOVE_ALL_FIELD_VALIDATION, removeAllFieldValidation);
  yield takeLatest(actions.SET_SHOW_FIELD_VALIDATION, showValidationError);
  yield takeLatest(actions.SET_CUSTOM_VALIDATION_MSG, customValidationMsg);
}

export function* fieldValidation(action: PayloadAction<ValidationError>) {
  try {
    yield put(setFieldValidation(action.payload));
  } catch {
    yield put(setFieldError(true));
  }
}

export function* showValidationError(action: PayloadAction<KeyValuePair>) {
  try {
    yield put(setShowValidationError(action.payload));
  } catch {
    yield put(setFieldError(true));
  }
}

export function* removeFieldValidation(action: PayloadAction<string>) {
  try {
    yield put(clearFieldValidation(action.payload));
  } catch {
    yield put(setFieldError(true));
  }
}

export function* removeAllFieldValidation() {
  try {
    yield put(clearAllFieldValidation());
  } catch {
    yield put(setFieldError(true));
  }
}

export function* customValidationMsg(action: PayloadAction<string>) {
  try {
    yield put(setCustomValidationMessage(action.payload));
  } catch {
    yield put(setFieldError(true));
  }
}
