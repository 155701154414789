import React, { useEffect, useMemo, useState } from 'react';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import './styles/dashboard.scss';
import { OrganisationItemModel } from '../../models/organisationModel';
import { LoadingStatus } from '../../constants/loading-constants';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import MessageDisplay from '../../components/message-display/message-display';
import FooterBar from '../../components/footer/footer';
import classNames from 'classnames';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { useNavigate, useParams } from 'react-router-dom';

interface DashboardProps {
  activeOrganisation: OrganisationItemModel;
  selectedOrganisationId: string;
  authStatus: string;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const {
    activeOrganisation,
    selectedOrganisationId,
    authStatus,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty
  } = props;

  
  const navigate = useNavigate();
  const { orgId } = useParams();

  useEffect(() => {
    if(orgId === undefined || orgId === '')
    {
      navigate(`/organisations/${selectedOrganisationId}`);
    }
  },[navigate, orgId, selectedOrganisationId]);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
  }, [authStatus]);

  useEffect(() => {
    setHeaderConfiguration({
      showOrganisation: true,
      title: 'Dashboard',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: authError,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authError]);

  useEffect(() => {
    setPageConfiguration({
     showFooter: true
    } as PageSettingStateModel);
  }, [setPageConfiguration]);
  
  useEffect(() =>{
    setIsPageDirty(false);
  },[setIsPageDirty]);
  
  return (
    <React.Fragment>
      {authError && <ErrorDisplayControl />}
      {hasNoSystemAccess && (
        <MessageDisplay
          messageTitle='Insufficient permissions'
          messageContent='Please contact system admin'
        ></MessageDisplay>
      )}
      {authSuccess && (
        <div className='container'>
          <h2 className='h2'>
            Welcome to the organisation portal for {activeOrganisation.name}
          </h2>
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar className={classNames('footer-no-content')} />
        </div>
      }
    </React.Fragment>
  );
};

export default Dashboard;
