import { takeLatest, all, call, put, select, delay } from 'redux-saga/effects';
import {
  setTerminalState,
  setTerminalData,
  setTerminalError,
  setTerminalInfo,
  setSelectedTerminal,
  setTerminalNumberList,
  setTerminalDetailState,
  setTerminalDetailError,
} from './reducers';
import { LoadingStatus } from '../../constants/loading-constants';
import {
  TerminalRecordModel,
  TerminalModel,
  TerminalInfo,
  TerminalDetailModel,
  TerminalCertificateModel,
} from '../../models/terminalModel';
import {
  TerminalCertificateEntity,
  TerminalDoorSwitchEntity,
  TerminalEmergencyStopSwitchEntity,
  TerminalInfoEntity,
  TerminalResponse,
} from '../../entities/terminal';
import { PayloadAction } from '@reduxjs/toolkit';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { authMode, hardwareVariant, printerType } from '../../constants/dropdown-constants';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { selectOrganisationId } from '../auth/selectors';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { closeModal, setModalActionStatus, setModalError } from '../modals/reducers';
import { selectModalStatus } from '../../store/modals/selectors';
import { TerminalOperationActionPostModel } from '../../models/terminalOperationModel';
import { base64EncodingGuid } from '../../utilities/general-helper';
import { selectSelectedSiteId } from '../sites/selectors';
import { selectTerminalData } from './selectors';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as datetimeHelper from '../../utilities/datetime-helper';
import * as fieldHelper from '../../utilities/field-helper';
import * as fileDownload from '../../utilities/fileDownload-helper';
import * as actions from './actions';
import * as services from './services';
import * as terminalOperationServices from '../terminal-operations/services';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TERMINALS, loadTerminalData);
  yield takeLatest(actions.RELOAD_TERMINALS, reLoadTerminalData);
  yield takeLatest(actions.DELETE_TERMINAL, deleteTerminalItem);
  yield takeLatest(actions.LOAD_TERMINALINFO, loadTerminalInfo);
  yield takeLatest(actions.EDIT_TERMINAL, editTerminalItem);
  yield takeLatest(actions.CREATE_TERMINAL, createTerminalItem);
  yield takeLatest(actions.SET_SELECTED_TERMINAL, setSelectedTerminalItem);
  yield takeLatest(actions.REENROL_TERMINAL, reenrolTerminal);
  yield takeLatest(actions.LOAD_TERMINALNUMBER_LIST, loadTerminalNumberList);
  yield takeLatest(actions.CLEAR_TERMINAL_LIST_DATA, clearTerminalListData);
}
let isCancelTerminalReloadRequested = false;

export function* loadTerminalData() {
  const organisationId: string = yield select(selectOrganisationId);
  const siteId: string = yield select(selectSelectedSiteId);
  try {
    yield put(setTerminalState(LoadingStatus.LOADING));
    isCancelTerminalReloadRequested = false;

    let response: TerminalResponse = yield call(services.getAllTerminalData, organisationId, siteId);
    let records: TerminalRecordModel[] = MapTerminalEntityToModel(response);
    yield put(setTerminalData(records));
    yield put(setTerminalState(LoadingStatus.SUCCESS));
    if (records && records?.length > 0) yield loadTerminalStatus(records, organisationId, siteId);
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTerminalError());
  }
}

function* loadTerminalStatus(records: TerminalRecordModel[], organisationId: string, siteId: string) {
  try {
    const responses: any[] = yield all(
      records.map((record) => {
        return call(function* () {
          try {
            yield call(terminalOperationServices.postOperationAction, record.id, {
              organisationId: organisationId,
              type: 'terminalStatusUpload',
            } as TerminalOperationActionPostModel);
            return { data: 200 };
          } catch (error) {
            return { data: 'error' };
          }
        });
      })
    );

    // if success receive data from operation api, retrieve the updated terminal data twice with 5 sec wait
    if (responses?.some((result) => result.data === 200 && !isCancelTerminalReloadRequested)) {
      let reloadCount = 0;
      while (reloadCount < 2) {
        yield delay(5000);
        if (isCancelTerminalReloadRequested) return;
        yield call(reLoadTerminalsAction, organisationId, siteId);
        reloadCount += 1;
      }
    }
  } catch (error) {
    return;
  }
}

export function* reLoadTerminalsAction(organisationId: string, siteId: string) {
  yield call(reLoadTerminalData, {
    type: actions.RELOAD_TERMINALS,
    payload: { organisationId, siteId },
  });
}

export function* reLoadTerminalData(action: any): Generator<any, void, TerminalResponse> {
  const { organisationId, siteId } = action.payload;
  try {
    let response: TerminalResponse = yield call(services.getAllTerminalData, organisationId, siteId);

    let currentTerminalData: any = yield select(selectTerminalData);
    if (response?.items?.length && currentTerminalData?.length) {
      const firstResponseItem = response.items[0];
      const firstCurrentTerminal = currentTerminalData[0];
      if (
        firstCurrentTerminal?.id === firstResponseItem?.id &&
        firstResponseItem?.dateTimeUtc > firstCurrentTerminal?.dateTimeUtc
      ) {
        isCancelTerminalReloadRequested = true;
      }
    }

    let records: TerminalRecordModel[] = MapTerminalEntityToModel(response);
    yield put(setTerminalData(records));
    yield put(setTerminalState(LoadingStatus.SUCCESS));
    return;
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTerminalError());
  }
  return;
}

export function* loadTerminalInfo(action: PayloadAction<TerminalModel>) {
  try {
    if (!!action.payload) {
      yield put(setTerminalDetailState(LoadingStatus.LOADING));
      const modalState: boolean = yield select(selectModalStatus);
      if (modalState) {
        yield put(setModalActionStatus(LoadingStatus.LOADING));
      }
      let response: TerminalInfoEntity = yield call(services.getTerminalInfo, action.payload);
      let terminalInfo: TerminalInfo = MapTerminalInfoEntityToModel(response);
      if (modalState) {
        yield put(setModalActionStatus(LoadingStatus.SUCCESS));
      }
      yield put(setTerminalInfo(terminalInfo));
      yield put(setTerminalDetailState(LoadingStatus.SUCCESS));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(clearAllFieldValidation());
    yield put(setTerminalDetailError());
  }
}

export function* setSelectedTerminalItem(action: PayloadAction<TerminalModel>) {
  try {
    yield put(setSelectedTerminal(action.payload));
  } catch (error) {
    yield put(setTerminalError());
  }
}

export function* deleteTerminalItem(action: PayloadAction<TerminalModel>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    yield call(services.deleteTerminalData, action.payload);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.TERMINAL_DELETE_SUCESS));
    yield call(loadTerminalData);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    yield put(setSnackBarError(getApiErrorMessage(error)));
  }
}

export function* createTerminalItem(action: PayloadAction<TerminalInfo>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let terminalEntity = MapTerminalInfoModelToEntity(action.payload);
    let response: TerminalCertificateEntity = yield call(services.createTerminal, terminalEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.TERMINAL_SAVE_SUCCESS));
    yield put(hideBackdrop());
    let terminalCetificate: TerminalCertificateModel = MapTerminalCertificateEntityToModal(response);
    yield call(fileDownload.downloadObjectAsJSON, terminalCetificate, base64EncodingGuid(terminalCetificate.id));
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editTerminalItem(action: PayloadAction<TerminalInfo>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const terminalModal = action.payload;
    let terminalEntity = MapTerminalInfoModelToEntity(terminalModal);
    terminalEntity.organisationId = yield select(selectOrganisationId);
    yield call(services.editTerminalData, terminalEntity, terminalModal.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.TERMINAL_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* reenrolTerminal(action: PayloadAction<TerminalInfo>) {
  try {
    yield put(setModalActionStatus(LoadingStatus.LOADING));
    const terminalInfo = action.payload;
    const terminalReenrolInfo = {
      registrationId: terminalInfo.registrationId,
      organisationId: terminalInfo.organisationId,
    };
    let response: TerminalCertificateEntity = yield call(
      services.reenrolTerminal,
      terminalInfo.id,
      terminalReenrolInfo
    );
    let terminalCetificate: TerminalCertificateModel = MapTerminalCertificateEntityToModal(response);
    yield call(fileDownload.downloadObjectAsJSON, terminalCetificate, base64EncodingGuid(terminalCetificate.id));
    yield put(setModalActionStatus(LoadingStatus.SUCCESS));
    yield put(closeModal());
    yield put(setSnackBarSuccess(Messages.TERMINAL_REENROL_SUCCESS));
  } catch (error) {
    yield put(setModalError(true));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* loadTerminalNumberList(action: PayloadAction<string>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    let terminals: TerminalResponse = yield getTerminalList(organisationId, action.payload);
    let terminalNumberList: KeyValuePair[] = yield call(mapTerminalEntityToKeyValuePair, terminals);
    yield put(setTerminalNumberList(terminalNumberList));
  } catch {
    yield put(setTerminalError());
  }
}

export function* getTerminalList(organisationId: string, siteId?: string) {
  try {
    let updatedSiteId = siteId as string;
    if (!siteId) updatedSiteId = yield select(selectSelectedSiteId);
    let terminalList: TerminalResponse = yield call(services.getTerminalList, updatedSiteId, organisationId);
    return terminalList;
  } catch (error) {
    yield put(setTerminalError());
  }
}

// This function used for clearing terminal list, terminal list should be cleared when user visit site detail page
export function* clearTerminalListData() {
  yield put(setTerminalData([]));
}

const checkFieldWithHealthIndicator = (fieldName: string, fieldStatus: string): string => {
  const fieldWithIndicator: string[] = [
    'Power Status',
    'Printer Status',
    'Door Status',
    'SD Card',
    'Completion Buffer',
    'CPU Temperature',
  ];
  return fieldHelper.findFieldinList(fieldName, fieldWithIndicator).length > 0
    ? fieldHelper.getHealthStatusIndicator(fieldStatus)
    : 'display-none';
};

const checkDateField = (fieldName: string, fieldValue: string): string => {
  const dateFields: string[] = ['Last Transaction', 'Last Card Swipe', 'Last Message'];

  return fieldHelper.findFieldinList(fieldName, dateFields).length > 0
    ? datetimeHelper.formatDate(fieldValue)
    : fieldValue;
};

const checkFieldWithDaysounter = (fieldName: string, fieldValue: string): string => {
  const fieldwithDayCounter: string[] = ['Last Status Update'];

  return fieldHelper.findFieldinList(fieldName, fieldwithDayCounter).length
    ? datetimeHelper.getDayCounter(fieldValue)
    : fieldHelper.getDefaultStringvalue(fieldValue);
};

const checkFields = (fieldName: string, fieldValue: string): string => {
  fieldValue = fieldHelper.getDefaultStringvalue(fieldValue);
  fieldValue = checkDateField(fieldName, fieldValue);
  return checkFieldWithDaysounter(fieldName, fieldValue);
};

/** MAPPING */

const MapTerminalEntityToModel = (response: TerminalResponse) => {
  if (response && response?.items?.length > 0) {
    const result: TerminalRecordModel[] = response.items.map((item, i) => {
      const windcaveStatusOverview: string =
        item?.windcave?.cardReader?.status === 'offline' ||
        item?.windcave?.keypad?.status === 'offline' ||
        item?.windcave?.contactlessReader?.status === 'offline' ||
        item?.windcave?.uplink?.status === 'offline'
          ? 'Offline'
          : item?.windcave?.cardReader?.status === 'online' ||
              item?.windcave?.keypad?.status === 'online' ||
              item?.windcave?.contactlessReader?.status === 'online' ||
              item?.windcave?.uplink?.status === 'online'
            ? 'Online'
            : item?.windcave?.cardReader?.status === 'disabled' ||
                item?.windcave?.keypad?.status === 'disabled' ||
                item?.windcave?.contactlessReader?.status === 'disabled' ||
                item?.windcave?.uplink?.status === 'disabled'
              ? 'Disabled'
              : '';
      return {
        id: item.id,
        siteId: item.siteId,
        number: item.number,
        status: fieldHelper.getDefaultStringvalue(item.status),
        healthIndicator: fieldHelper.getHealthStatusIndicator(item.status),
        lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(item.dateTimeUtc),
        dateTimeUtc: item.dateTimeUtc,
        temperatureCelsius: item.temperatureCelsius,
        transactionStorageUsage: item.transactionStorageUsage,
        sdCard: item.sdCard,
        upsBoard: item.upsBoard ? item.upsBoard : undefined,
        fmsReplacementBoard: item.fmsReplacementBoard ? item.fmsReplacementBoard : undefined,
        systemStartupDateTimeUtc: item.systemStartupDateTimeUtc
          ? datetimeHelper.getDayCounter(item.systemStartupDateTimeUtc).slice(0, -4)
          : undefined,
        doorStatus: item.doorStatus ? item.doorStatus : undefined,
        emergencyStopStatus: item.emergencyStopStatus ? item.emergencyStopStatus : undefined,
        passcode: item.passcode ? item.passcode : undefined,
        card: item.card ? item.card : undefined,
        printer: item.printer ? item.printer : undefined,
        windcave: item.windcave ? item.windcave : undefined,
        windcaveStatus: windcaveStatusOverview,
        messages: item?.messages ? item.messages : [],
        /** items could be update in the future */
        items: item?.items?.map((itemDet, i) => {
          return {
            name: itemDet?.name,
            value: checkFields(itemDet?.name, itemDet?.value),
            status: fieldHelper.getDefaultStringvalue(itemDet?.status),
            healthIndicator: checkFieldWithHealthIndicator(itemDet?.name, itemDet?.status),
          } as TerminalDetailModel;
        }),
      } as TerminalRecordModel;
    });
    return result;
  }

  return [] as TerminalRecordModel[];
};

const MapTerminalInfoEntityToModel = (response: TerminalInfoEntity) => {
  if (response) {
    const hardwareVariantData = hardwareVariant.find((it) => {
      return it.key === response.hardwareVariant;
    })?.key;
    const authorisationModeList = response.authorisationMode.split(', ').map((it) => {
      return authMode.find((its) => its.key === it)?.key;
    });
    const printerTypeData = printerType.find((it) => {
      return it.key === response.printerType;
    })?.key;
    return {
      id: response.id,
      organisationId: response.organisationId,
      siteId: response.siteId,
      number: response.number,
      description: response.description,
      registrationId: response.registrationId,
      hardwareVariant: hardwareVariantData,
      authorisationMode: authorisationModeList,
      authorisationTimeoutSeconds: fieldMappingHelper.validateNumericValue(response.authorisationTimeoutSeconds),
      paymentGatewayAddress: response.paymentGatewayAddress,
      paymentGatewayPort: fieldMappingHelper.validateNumericValue(response.paymentGatewayPort),
      printerType: printerTypeData,
      passcode: response.passcode,
      doorSwitchConfig: response?.doorSwitch?.configuration,
      emergencyStopSwitchConfig: response?.emergencyStopSwitch?.configuration,
    } as unknown as TerminalInfo;
  }

  return {} as TerminalInfo;
};

const MapTerminalInfoModelToEntity = (model: TerminalInfo) => {
  if (model) {
    const authorisationModeStringList = model?.authorisationMode.map((it) => {
      return authMode.find((its) => its.key === it)?.key;
    });
    let terminalDoorSwitch = {
      configuration: model.doorSwitchConfig,
    } as TerminalDoorSwitchEntity;
    let terminalEmergencyStopSwitch = {
      configuration: model.emergencyStopSwitchConfig,
    } as TerminalEmergencyStopSwitchEntity;

    return {
      siteId: fieldMappingHelper.sanitizeStringValue(model.siteId),
      organisationId: fieldMappingHelper.sanitizeStringValue(model.organisationId),
      number: fieldMappingHelper.sanitizeNumericValue(model.number),
      description: fieldMappingHelper.sanitizeStringValue(model.description),
      registrationId: fieldMappingHelper.sanitizeStringValue(model.registrationId),
      hardwareVariant: fieldMappingHelper.sanitizeStringValueFromDropdown(model.hardwareVariant),
      authorisationMode: authorisationModeStringList.length > 0 ? authorisationModeStringList.toString() : '',
      authorisationTimeoutSeconds: fieldMappingHelper.sanitizeNumericValue(model.authorisationTimeoutSeconds),
      authorisationFlags: fieldMappingHelper.sanitizeNumericValue(model.authorisationFlags),
      paymentGatewayAddress: fieldMappingHelper.sanitizeStringValue(model.paymentGatewayAddress),
      paymentGatewayPort: fieldMappingHelper.sanitizeNumericValue(model.paymentGatewayPort),
      printerType: fieldMappingHelper.sanitizeStringValueFromDropdown(model.printerType),
      passcode: fieldMappingHelper.sanitizeStringValue(model.passcode),
      currency: fieldMappingHelper.sanitizeStringDropDownValue(model.currency),
      doorSwitch: terminalDoorSwitch,
      emergencyStopSwitch: terminalEmergencyStopSwitch,
    } as TerminalInfoEntity;
  }
  return {} as TerminalInfoEntity;
};

const MapTerminalCertificateEntityToModal = (response: TerminalCertificateEntity) => {
  if (response) {
    return {
      id: response.id,
      provisioningEndpoint: response.provisioningEndpoint,
      provisioningScope: response.provisioningScope,
      certificates: response.certificates,
      registrationId: response.registrationId,
    } as TerminalCertificateModel;
  }
  return {} as TerminalCertificateModel;
};

const mapTerminalEntityToKeyValuePair = (response: TerminalResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((record, i) => {
      return {
        key: record.id,
        value: record.number,
      };
    });
    return result;
  }
};
