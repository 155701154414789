import { Alert,  Snackbar } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './custom-snackbar.scss';

export interface SnackbarProps {
  message?: string;
  openAlert: boolean;
  className?: string | null;
  severity: string;
  onClose: (data: boolean) => void;
}

const CustomSnackbar = (props: SnackbarProps) => {
  const { message, openAlert, className, severity, onClose } = props;
  const [snackPackage, setSnackPackage] = useState({ open: openAlert });

  useEffect(() => {
    setSnackPackage({ open: openAlert });
  }, [openAlert]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackPackage({ open: false });
    onClose(false);
  };

  return (
    <Snackbar
      key={new Date().getTime()} //this is to get a new unique key everytime it opens a snackbar, to reset the autohide duration
      className={classNames('snackbar', className)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={snackPackage.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        className={classNames('snackbar-alert', severity, className)}
        onClose={handleClose}
        variant="filled"
        severity={
          severity === 'success'
            ? 'success'
            : severity === 'error'
            ? 'error'
            : severity === 'warning'
            ? 'warning'
            : 'info'
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
