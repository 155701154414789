import { TankRecordEntity } from '../../entities/tank';
import api, { setAuthorize } from '../../utilities/api';

const TANK_API_PREFIX = 'tank-management';

export const getTankStatuses = async (
  siteId: string,
  organisationId: string
) => {
  await setAuthorize();
  let response = await api.get(
    `/${TANK_API_PREFIX}/tanks/status?organisationId=${organisationId}&siteId=${siteId}`
  );
  return response.data;
};

export const getTankList = async (siteId: string, organisationId: string) => {
  await setAuthorize();
  let response = await api.get(
    `/${TANK_API_PREFIX}/tanks?organisationId=${organisationId}&siteId=${siteId}`
  );
  return response.data;
};

export const getTankInfo = async (tankId: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${TANK_API_PREFIX}/tanks/${tankId}?organisationId=${organisationId}`
  );
  return result.data;
};

export const createTank = async (data: TankRecordEntity) => {
  await setAuthorize();
  await api.post(`/${TANK_API_PREFIX}/tanks`, data);
};

export const editTank = async (data: TankRecordEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${TANK_API_PREFIX}/tanks/${id}`, data);
};

export const deleteTank = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(
    `/${TANK_API_PREFIX}/tanks/${id}?organisationId=${organisationId}`
  );
};
