import { TransactionReportRequestEntity } from '../../entities/report';
import api, { setAuthorize } from '../../utilities/api';

const REPORT_API_PREFIX = 'report-management';

export const getAllReports = async (orgId: string, userId: string) => {
  await setAuthorize();
  const res = await api.get(`/${REPORT_API_PREFIX}/reports?organisationId=${orgId}&userId=${userId}`);
  return res.data;
};

export const getAllColumns = async (orgId: string) => {
  await setAuthorize();
  const res = await api.get(`/${REPORT_API_PREFIX}/reports/transactions/columns?organisationId=${orgId}`);
  return res.data;
};

export const generateTransactionReport = async (data: TransactionReportRequestEntity) => {
  await setAuthorize();
  await api.post(`/${REPORT_API_PREFIX}/reports/transactions`, data);
};
