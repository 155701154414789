import { LoadingStatus } from "../../constants/loading-constants";
import { FirmwareModel, TerminalBoardModel, TerminalFirmwareModel } from "../../models/terminalFirmwareModel";

interface terminalFirmwareState {
    terminalFirmwareStatus: string;
    terminalFirmwareData: TerminalFirmwareModel;
    availableFirmwareData: FirmwareModel[];
    terminalBoardsData: TerminalBoardModel[];
    hasError: boolean;
}

export const initialState: terminalFirmwareState = {
    terminalFirmwareStatus: LoadingStatus.LOADING,
    hasError: false
} as terminalFirmwareState;