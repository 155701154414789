import { NewTankDeliveryEntity } from '../../entities/tankDelivery';
import api, { setAuthorize } from '../../utilities/api';

const TANK_API_PREFIX = 'tank-management';

export const getTankDeliveries = async (tankId: string, orgId: string) => {
  await setAuthorize();
  let result = await api.get(`/${TANK_API_PREFIX}/tanks/${tankId}/deliveries?organisationId=${orgId}`);
  return result.data;
};

export const createNewTankDelivery = async (tankId: string, data: NewTankDeliveryEntity) => {
  await setAuthorize();
  let result = await api.post(`/${TANK_API_PREFIX}/tanks/${tankId}/deliveries`, data);
  return result.data;
};
