import React, { forwardRef, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Popper, Paper, ClickAwayListener, Button, Divider } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { ButtonStyle } from '../../constants/button-constants';
import CustomButton from '../button/custom-button';
import './search-bar.scss';

export interface FilterOptionProps {
  filterDetail: FilterDetail;
  handleNewFilter: (data: KeyValuePair) => void;
  onFilterClick: (data: boolean) => void;
}

const FilterOption = forwardRef((props: FilterOptionProps, ref) => {
  const { filterDetail, handleNewFilter, onFilterClick } = props;
  const [selectedValue, setSelectedValue] = useState(filterDetail.selectedOption);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedValue(filterDetail.selectedOption);
  }, [filterDetail]);

  useEffect(() => {
    if (handleNewFilter) {
      handleNewFilter({ key: filterDetail.filterName, value: selectedValue } as KeyValuePair);
    }
  }, [selectedValue]);

  const clearSelected = () => {
    setSelectedValue('default');
  };

  const handleClick = (event: any) => {
    onFilterClick(true);
    if (event.target.value === selectedValue) {
      return;
    } else {
      setSelectedValue(event.target.value);
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {
        <Button
          className={`${selectedValue === 'default' ? 'default-selector' : 'selcted-selector'}`}
          id='basic-button'
          onClick={handleButtonClick}
        >
          {selectedValue !== 'default' ? selectedValue : filterDetail.filterName}{' '}
          <div className='expand-button-container'>{open ? <ExpandLess /> : <ExpandMore />}</div>
        </Button>
      }
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement='bottom-end'
        disablePortal
        className={'floatingMenuContainer'}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Paper>
              <RadioGroup
                className='filter-option-list'
                name={filterDetail.filterName}
                value={selectedValue ? selectedValue : 'default'}
              >
                {filterDetail?.filterOptions?.map((it) => {
                  return (
                    <FormControlLabel value={it} label={it} control={<Radio color='success' onClick={handleClick} />} />
                  );
                })}
              </RadioGroup>
              <Divider />
              <div className='clear-button-row'>
                {selectedValue === 'default' ? (
                  <span className='disabled-clear-text'> &#10006; Clear</span>
                ) : (
                  <CustomButton
                    buttonStyle={ButtonStyle.NO_BORDER}
                    className={`${selectedValue !== 'default' ? 'clear-button' : 'disabled-clear-button'}`}
                    onClick={clearSelected}
                  >
                    &#10006; Clear
                  </CustomButton>
                )}
              </div>
            </Paper>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
});

export default FilterOption;
