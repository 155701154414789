import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectPermissionGroupNameList } from '../../../store/permission-group/selectors';
import { selectOrganisationId } from './../../../store/auth/selectors';
import { selectPermissionGroupItem, selectPermissionGroupStatus } from '../../../store/permission-group/selectors';
import { selectCurrentUserPermissions } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PermissionGroupItemsModel } from '../../../models/permissionGroupModel';
import * as actions from '../../../store/permission-group/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as headerActions from '../../../store/header/actions';
import PermissionGroup from './permission-group';
import '../styles/formStyle.scss';

const mapStateToProps = (state: RootState) => {
  return {
    hasValidationError: selectHasValidationError(state),
    permissionGroupNameList: selectPermissionGroupNameList(state),
    permissionGroupStatus: selectPermissionGroupStatus(state),
    permissionGroupInfo: selectPermissionGroupItem(state),
    selectedOrganisationId: selectOrganisationId(state),
    userPermissions: selectCurrentUserPermissions(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadPermissionGroupInfo: (data: string | undefined) =>
      dispatch({ type: actions.LOAD_PERMISSIONSGROUP_INFO, payload: data }),
    editPermissionGroupInfo: (data: PermissionGroupItemsModel) =>
      dispatch({ type: actions.EDIT_PERMISSIONSGROUP, payload: data }),
    createPermissionGroupInfo: (data: PermissionGroupItemsModel) =>
      dispatch({ type: actions.CREATE_PERMISSIONSGROUP, payload: data }),

    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const PermissionGroupPage = connect(mapStateToProps, mapDispatchToProps)(PermissionGroup);

export default PermissionGroupPage;
