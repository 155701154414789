import React from 'react';
import { ButtonStyle } from '../../../constants/button-constants';
import CustomButton from '../../button/custom-button';
import classNames from 'classnames';
import { MODAL_VALIDATION_MESSAGE } from '../../../constants/modal-constants';
import { Grid } from '@mui/material';
import { CancelIcon } from '../../icons';

interface ModalFooterProps {
  saveButtonEnabled?: boolean;
  cancelButtonDisabled?: boolean;
  showValidationError: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
  closeButtonText: string;
  className?: string;
  hideCancelButton?: boolean;
  hideSaveButton?: boolean;
  viewOnlyModal: boolean;
  displayResetButton?: boolean;
  onSaveButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onReset?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ModalFooter: React.FC<ModalFooterProps> = (props: ModalFooterProps) => {
  const {
    saveButtonEnabled = true,
    cancelButtonDisabled,
    saveButtonText,
    cancelButtonText,
    closeButtonText,
    hideCancelButton,
    hideSaveButton,
    viewOnlyModal,
    displayResetButton,
    className,
    showValidationError,
    onSaveButton,
    onCancelButton,
    onClose,
    onReset,
  } = props;

  const onSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onSaveButton) {
      onSaveButton(event);
    }
  };

  const onCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onCancelButton) {
      onCancelButton(event);
    }
  };

  const onResetClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onReset) {
      onReset(event);
    }
  };

  const smValidationGridSize: number = displayResetButton ? 12 : 6;
  const xsResetGridSize: number = hideCancelButton ? 6 : 12;
  const xsButtonGridSize: number = hideCancelButton && displayResetButton ? 6 : 12;

  return (
    <div className={classNames('footer-container', className)}>
      <Grid container className={classNames('grid-footer-modal-container')}>
        <Grid
          item
          xs={12}
          sm={smValidationGridSize}
          className={classNames({ 'grid-reset-container': showValidationError })}
        >
          {showValidationError && <div className={'validation-ErrorMessage'}>{MODAL_VALIDATION_MESSAGE}</div>}
        </Grid>
        <Grid
          item
          xs={xsResetGridSize}
          sm={6}
          className={classNames({ 'grid-reset-container': displayResetButton })}
          sx={{ display: displayResetButton ? 'flex' : 'none' }}
        >
          {displayResetButton && (
            <div className='reset-container'>
              <CustomButton
                isDisabled={cancelButtonDisabled}
                buttonStyle={ButtonStyle.SECONDARY}
                onClick={onResetClick}
                className={'footer-reset-button'}

              >
                Reset
              </CustomButton>
            </div>
          )}
        </Grid>
        <Grid item xs={xsButtonGridSize} sm={6} className={'grid-save-cancel-container'}>
          {viewOnlyModal && (
            <CustomButton buttonStyle={ButtonStyle.PRIMARY} onClick={onClose} className={'footer-primary-button'}>
              {closeButtonText}
            </CustomButton>
          )}
          {!viewOnlyModal && !hideCancelButton && (
            <CustomButton
              isDisabled={cancelButtonDisabled}
              buttonStyle={ButtonStyle.SECONDARY}
              onClick={onCancelClick}
              className={'footer-secondary-button'}
            >
              {!!cancelButtonText ? cancelButtonText : 'Cancel'}
            </CustomButton>
          )}
          {!viewOnlyModal && !hideSaveButton && (
            <CustomButton
              isDisabled={!saveButtonEnabled}
              buttonStyle={ButtonStyle.PRIMARY}
              onClick={onSaveClick}
              className={'footer-primary-button'}
            >
              {!!saveButtonText ? saveButtonText : 'Save'}
            </CustomButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ModalFooter;
