import { useLocation } from 'react-router-dom';

export const IsSiteSubMenuDisplayed = () => {
  let location = useLocation();
  const locationPath = location.pathname.toLocaleLowerCase();
  var urlPattern = locationPath.split('/');
  return (
    urlPattern.length > 3 &&
    !locationPath.includes('/sites/create') &&
    (locationPath.includes('/sites/') ||
      locationPath.includes('/terminals/') ||
      locationPath.includes('/pumps/') ||
      locationPath.includes('/tanks/'))
  );
};
