import { RootState } from '../rootReducers';

import { createSelector } from 'reselect';

export const selectNavePaneState = (state: RootState) => state.NavePane;

export const selectNavPaneStatus = createSelector(
  selectNavePaneState,
  (state) => state.navPaneState
);
export const selectSubMenuStatus = createSelector(
  selectNavePaneState,
  (state) => state.subMenuOpened
);
export const selectSelectedMenuIndex = createSelector(
  selectNavePaneState,
  (state) => state.selectedSubMenuIndex
);

export const selectSelectedSubMenuIndex = createSelector(
  selectNavePaneState,
  (state) => state.selectedSubMenuIndex
);

export const selectSelectedDropdownMenuIndex = createSelector(
  selectNavePaneState,
  (state) => state.selectedDropdownMenuIndex
);

export const selectActiveDropdownIndex = createSelector(
  selectNavePaneState,
  (state) => state.selectActiveDropdown
);

export const selectActiveMenu = createSelector(
  selectNavePaneState,
  (state) => state.selectedMenu
);
