import { LoadingStatus } from './../../constants/loading-constants';
import { PumpInfoModel, PumpRecordModel } from '../../models/pumpModel';
import { initialState } from './pumpInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const pumpSlice = createSlice({
  name: 'pumpState',
  initialState,
  reducers: {
    setPumpStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.pumpStatus = action.payload;
    },
    setPumpDetailStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.pumpDetailStatus = action.payload;
    },
    setPumpContent: (state, action: PayloadAction<PumpRecordModel[]>) => {
      state.hasError = false;
      state.pumpContent = action.payload;
    },
    setPumpInfo: (state, action: PayloadAction<PumpInfoModel>) => {
      state.hasError = false;
      state.pumpInfo = action.payload;
    },
    setPumpError: (state) => {
      state.hasError = true;
      state.pumpContent = [];
      state.pumpStatus = LoadingStatus.ERROR;
    },
    setPumpDetailError: (state) => {
      state.hasError = true;
      state.pumpInfo = {} as PumpInfoModel;
      state.pumpDetailStatus = LoadingStatus.ERROR;
    },
    setPumpNumberList: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.hasError = false;
      state.pumpNumberList = action.payload;
    },
    resetPumpState: () => {
      return initialState;
    },
  },
});

export const {
  setPumpStatus,
  setPumpDetailStatus,
  setPumpContent,
  setPumpInfo,
  setPumpError,
  setPumpDetailError,
  setPumpNumberList,
  resetPumpState,
} = pumpSlice.actions;

export default pumpSlice.reducer;
