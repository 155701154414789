import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { BulkOperationDetailsItem } from '../../../models/cardOperationModel';
import { bulkActionType } from '../../../constants/dropdown-constants';
import Card from '../../../components/cards/cards';
import Item from '../../../components/box-items/box-item';
import * as fieldMappingHelper from '../../../utilities/fieldMapping-helper';
import '../styles/card-operations.scss';

interface OperationItemProps {
  operationDetails: BulkOperationDetailsItem;
}

const OperationItem: React.FC<OperationItemProps> = (props: OperationItemProps) => {
  const { operationDetails } = props;

  const displayPanGroupByFour = (pan: string): string => {
    return pan.replace(/\d{1,4}(?=(\d{4})+(?!\d))/g, (match) => match + ' ');
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='detail-card'>
          <Item className='box-main'>{displayPanGroupByFour(operationDetails.pan)}</Item>
          <Item className='box-main'>
            <span className='box-child'>{operationDetails.binRangeName}</span>
          </Item>
          <Item className='box-main hidden-mobile'>
            <span className='box-child'>{operationDetails.success ? 'Success' : 'Failure'}</span>
          </Item>
          <Item className='box-main hidden-table hidden-mobile'>
            <span className='box-child'>
              {fieldMappingHelper.getDisplayValue(operationDetails?.actionType, bulkActionType)}
            </span>
          </Item>
          <Item className='box-main hidden-table hidden-mobile'>
            <span className='box-child'>{operationDetails.errorMessage}</span>
          </Item>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default OperationItem;
