import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';
import { TRANSACTION_FILTER_STORAG_KEY } from '../../constants/transaction-constants';
import { TransactionFilterModel } from '../../models/transactionModel';
import { selectAuthState } from '../auth/selectors';
import { GetDataFromLocalStorage } from '../../utilities/localStorage-helper';

export const selectTransactionState = (state: RootState) =>
  state.TransactionState;

export const selectTransactionData = createSelector(
  selectTransactionState,
  (state) => state.transactionData
);

export const selectTransactionStatus = createSelector(
  selectTransactionState,
  (state) => state.transactionStatus
);

export const selectIsFilterActive = createSelector(
  selectTransactionState,
  (state) => state.isFilterActive
);
export const selectTransactionFilter = createSelector(
  [selectTransactionState, selectAuthState],
  (state, authState) => {
    let transactionFilter: TransactionFilterModel = GetDataFromLocalStorage(
      `${TRANSACTION_FILTER_STORAG_KEY}-${authState.organisationId}-${authState.userId}`
    );
    
    if (transactionFilter === null || transactionFilter === undefined) {
      transactionFilter = {} as TransactionFilterModel;
    }
    return transactionFilter;
  }
);
