import api, { setAuthorize, getResourceToken, azureApi } from '../../utilities/api';

const RESOURCE_ACCESS_API_PREFIX = 'resource-access-token-management';

export const getAzureMapsResourceTokens = async () => {
  await setAuthorize();
  let result = await api.get(`${RESOURCE_ACCESS_API_PREFIX}/resource-tokens?resource=azuremaps`);
  return result.data;
};

export const getAddressList = async (query: string) => {
  await setAuthorize();
  await getResourceToken();
  let result = await azureApi.get(
    `https://atlas.microsoft.com/search/address/json?typeahead=true&api-version=1&query=${query}&language=en-US&countrySet=NZ,AU&view=Auto`
  );
  return result.data;
};

export const getTimezone = async (query: string) => {
  await setAuthorize();
  await getResourceToken();
  let result = await azureApi.get(
    `https://atlas.microsoft.com/timezone/byCoordinates/json?api-version=1.0&options=all&query=${query}&view=Auto`
  );
  return result.data;
};
