import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface FileUploadIconProps {
  className?: string;
}

const FileUploadIcon = (props: FileUploadIconProps) => {
  return <SvgIcon className={`base-icon file-upload-icon ${props.className}`} />;
};

export default FileUploadIcon;
