import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { Bar } from 'react-chartjs-2';
import './stacked-bar-chart.scss';

interface PieChartProps {
  chartData?: KeyValuePair[];
  totalItemCount?: number;
}

const StackedBarChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const { chartData, totalItemCount } = props;

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const labels = ['Bulk Details'];

  const data = {
    labels,
    datasets: chartData
      ?.filter((it) => Number(it.value) > 0)
      .map((it) => {
        return {
          label: it.key,
          data: labels.map(() => it.value),
          backgroundColor: it.additionalValue,
        };
      }),
  };

  const tooltipFooter = () => {
    return 'Total Items: ' + totalItemCount;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    barThickness: 8,
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          font: {
            size: 12,
            family: 'Mulish',
          },
          color: '#fff',
        },
      },
      tooltip: {
        callbacks: {
          title: () => '',
          footer: tooltipFooter,
        },
      },
    },
  };

  return (
    <Bar
      className='stacked-bar-chart-container'
      //@ts-ignore
      options={options}
      //@ts-ignore
      data={data}
    />
  );
};

export default StackedBarChart;
