import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import DownloadIcon from '../../components/icons/download-icon';
import { ReportModel } from '../../models/reportModel';
import { HealthStatusDisplayType } from '../../constants/status';
import * as fileDownload from '../../utilities/fileDownload-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/report-item.scss';

interface ReportItemProps {
  reportData: ReportModel;
}

const ReportItem: React.FC<ReportItemProps> = (props: ReportItemProps) => {
  const { reportData } = props;

  const onDownloadClick = (downloadLink: string, name: string) => {
    fileDownload.downloadFile(downloadLink, name, 'csv');
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='report-card'>
          <Item className='box-main report-name'>{reportData.name}</Item>
          <Item className='box-main'>
            {fieldMappingHelper.getDisplayValue(reportData.state, HealthStatusDisplayType)}
          </Item>
          <Item
            className={classNames('box-main', 'box-button')}
            onClick={() => onDownloadClick(reportData.downloadLink, reportData.name)}
          >
            {reportData?.downloadLink && <DownloadIcon className='download-icon' />}
          </Item>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default ReportItem;
