import { put, takeLatest } from 'redux-saga/effects';
import { setNavePaneState, 
    setNavPaneError, 
    setSubMenuOpened,
    setSelectedMenuIndex,
    setSelectedSubMenuIndex,
    setSelectedDropdownIndex,
    setSelectedActiveDropdown,
    setSelectedMenu
 } from './reducers';
import * as actions from './actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { NavDropdownModel } from '../../models/navItemModel';

export function* rootSaga() {
    yield takeLatest(actions.SET_NAVE_PANE_STATUS, setNavPaneStatus);
    yield takeLatest(actions.SET_NAVE_PANE_SUB_MENU_STATUS, setSubMenueStatus);
    yield takeLatest(actions.SELECT_MENU_INDEX, setMenuIndex);
    yield takeLatest(actions.SELECT_SUB_MENU_INDEX, setSubMenuIndex);
    yield takeLatest(actions.SELECT_DROPDOWN_MENU_INDEX, setDropdownMenuIndex);
    yield takeLatest(actions.SELECT_ACTIVE_DROPDOWN_INDEX, setActiveDropdown);
    yield takeLatest(actions.SET_SELECTED_MENU, setSelectedMenuAction)
}

export function* setNavPaneStatus(action: PayloadAction<string>) {
    try{
        yield put(setNavePaneState(action.payload));
    }
    catch{
        yield put(setNavPaneError());
    }
}

export function* setSubMenueStatus(action: PayloadAction<boolean>){
    try{
        yield put(setSubMenuOpened(action.payload));
    }
    catch{
        yield put(setNavPaneError());
    }
}

export function* setMenuIndex(action:PayloadAction<number>) {
    try{
        yield put(setSelectedMenuIndex(action.payload));
    }
    catch{
        yield put(setNavPaneError());
    }
}

export function* setSubMenuIndex(action:PayloadAction<number>) {
    try{
        yield put(setSelectedSubMenuIndex(action.payload));
    }
    catch{
        yield put(setNavPaneError());
    }
}

export function* setDropdownMenuIndex(action: PayloadAction<NavDropdownModel>) {
    try{
        yield put(setSelectedDropdownIndex(action.payload));
        yield put(setSelectedMenuIndex(action.payload.menuIndex));
    }
    catch {
        yield put(setNavPaneError());
    }
}

export function* setActiveDropdown(action: PayloadAction<number>) {
    try {
        yield put(setSelectedActiveDropdown(action.payload));
    } catch {
        yield put(setNavPaneError());
    }
}

export function* setSelectedMenuAction(action: PayloadAction<string>) {
    try {
        yield put(setSelectedMenu(action.payload));
    } catch {
        yield put(setNavPaneError());
    }
}
