import React, { MouseEventHandler } from 'react';
import { Button as BaseButton } from '@mui/base/Button';
import { Tooltip } from '@mui/material';
import { ButtonStyle } from '../../constants/button-constants';
import classNames from 'classnames';
import './custom-button.scss';

interface CustomButtonProps {
  ref?: any;
  isDisabled?: boolean;
  buttonStyle: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode | undefined;
  iconTooltip?: string;
}

const CustomButton: React.FC<CustomButtonProps> = (props: CustomButtonProps) => {
  const { ref, isDisabled, buttonStyle, children, icon, iconTooltip } = props;
  return (
    <>
      <BaseButton
        //@ts-ignore
        ref={ref}
        onClick={props.onClick}
        className={classNames(
          'common-button-style ',
          {
            'create-button': buttonStyle === ButtonStyle.CREATE,
            'primary-button': buttonStyle === ButtonStyle.PRIMARY,
            'secondary-button': buttonStyle === ButtonStyle.SECONDARY,
            'toolbar-button': buttonStyle === ButtonStyle.TOOLBAR || buttonStyle === ButtonStyle.TOOLBAR_FILTER,
            'review-button': buttonStyle === ButtonStyle.REVIEW,
            'edit-button': buttonStyle === ButtonStyle.EDIT,
            'icon-button': buttonStyle === ButtonStyle.ICON,
            'no-border-button': buttonStyle === ButtonStyle.NO_BORDER,
            'delete-button': buttonStyle === ButtonStyle.DELETE,
            'filter-button': buttonStyle === ButtonStyle.FILTER,
            'float-menu-toolbar-button': buttonStyle === ButtonStyle.FLOAT_MENU_TOOLBAR,
            'search-bar-button': buttonStyle === ButtonStyle.SEARCH_BAR,
            'textfield-inline-button': buttonStyle === ButtonStyle.TEXTFIELD_INLINE_BUTTON,
            'item-inline-button': buttonStyle === ButtonStyle.ITEM_INLINE_BUTTON,
          },
          props.className
        )}
        disabled={isDisabled}
      >
        {buttonStyle === ButtonStyle.CREATE ? (
          <React.Fragment>
            <span className='create-span-mobile'>{icon ? icon : '+'}</span>
            <span className='create-span-desktop'>{children}</span>
          </React.Fragment>
        ) : buttonStyle === ButtonStyle.EDIT ? (
          <React.Fragment>
            <span className='create-span-mobile'>{icon}</span>
            <span className='create-span-desktop'>{children}</span>
          </React.Fragment>
        ) : buttonStyle === ButtonStyle.TOOLBAR ? (
          <React.Fragment>
            <div className='toolbar-button-icon-container'>
              <span className='create-span-mobile'>{icon}</span>
              <div className='toolbar-button-icon-desktop-container'>
                <span>{children}</span>
                <div className={classNames('button-icon-container')}>{icon}</div>
              </div>
            </div>
          </React.Fragment>
        ) : buttonStyle === ButtonStyle.TEXTFIELD_INLINE_BUTTON ? (
          <React.Fragment>
            <div className='text-field-inline-icon-container'>
              <span className='button-children'>{children}</span>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='common-button-icon-container'>
              <span>{children}</span>
              <Tooltip title={iconTooltip} disableFocusListener describeChild followCursor>
                <div className={classNames('button-icon-container')}>{icon}</div>
              </Tooltip>
            </div>
          </React.Fragment>
        )}
      </BaseButton>
    </>
  );
};

export default CustomButton;
