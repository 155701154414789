import { LoadingStatus } from './../../constants/loading-constants';
import { initialState } from './backdropInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'backDropState',
  initialState,
  reducers: {
    showBackdrop: (state) => {
      state.backDropStatus = true;
      state.hasError = false;
      state.actionStatus = '';
    },
    hideBackdrop: (state) => {
      state.backDropStatus = false;
      state.hasError = false;
      state.actionStatus = '';
    },
    setBackDropActionStatus: (state, action: PayloadAction<string>) => {
      state.actionStatus = action.payload;
    },
    setBackDropError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
      state.actionStatus = LoadingStatus.ERROR;
    },
  },
});

export const { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } = modalSlice.actions;

export default modalSlice.reducer;
