import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as services from './services';
import * as actions from './actions';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { clearContinuationTokenList, setContinuationTokenList, setIsReachEnd } from '../pagination/reducers';
import { selectContinuationToken, selectIsReachEnd } from '../pagination/selectors';
import { EventListResponse, EventRequest, EventTypeResponse } from '../../entities/event';
import { EventFilterModel, EventModel } from '../../models/eventModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectEventData, selectEventFuzzySearch } from './selectors';
import { setEventStatus, setEventData, setEventError, setEventTypes, setEventFuzzySearch } from './reducers';
import { setSnackBarError } from '../snackbar/reducers';

export function* rootSaga() {
  yield takeLatest(actions.INIT_LOAD_EVENTS, initLoadEvents);
  yield takeLatest(actions.LOAD_EVENTS, loadEvents);
  yield takeLatest(actions.LOAD_EVENT_TYPES, loadEventTypes);
  yield takeLatest(actions.SAVE_EVENT_FUZZY_SEARCH, saveEventFuzzySearch);
  yield takeLatest(actions.CLEAR_EVENT_LIST, clearEventList);
}

export function* initLoadEvents(filters?: PayloadAction<EventFilterModel>) {
  try {
    yield put(setEventStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    const filterOptions: EventFilterModel = filters?.payload ? filters.payload : ({} as EventFilterModel);
    const request: EventRequest = MapDetailsToEventRequest(organisationId, [], false, filterOptions);
    const fuzzyRequest: string = yield select(selectEventFuzzySearch);
    let response: EventListResponse = yield call(services.getEventsData, request, fuzzyRequest);
    yield put(setIsReachEnd(!response.continuationToken));
    let eventsData: EventModel[] = mapEventEntityToModel(response);
    if (response.continuationToken) yield put(setContinuationTokenList(response.continuationToken));
    else yield put(clearContinuationTokenList());
    yield put(setEventData(eventsData));
    yield put(setEventStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setEventError());
  }
}

export function* loadEvents(filters?: PayloadAction<EventFilterModel>) {
  try {
    const isReachEnd: boolean = yield select(selectIsReachEnd);
    const organisationId: string = yield select(selectOrganisationId);
    const continuationTokenList: string[] = yield select(selectContinuationToken);
    const eventsInState: EventModel[] = yield select(selectEventData);
    const filterOptions: EventFilterModel = filters?.payload ? filters.payload : ({} as EventFilterModel);
    const request: EventRequest = MapDetailsToEventRequest(
      organisationId,
      continuationTokenList,
      isReachEnd,
      filterOptions
    );
    if (isReachEnd) return;
    const fuzzyRequest: string = yield select(selectEventFuzzySearch);
    yield put(setEventStatus(LoadingStatus.LOADING));
    let response: EventListResponse = yield call(services.getEventsData, request, fuzzyRequest);
    yield put(setIsReachEnd(!response.continuationToken));
    if (response.continuationToken) yield put(setContinuationTokenList(response.continuationToken));
    let eventsData: EventModel[] = mapEventEntityToModel(response);
    const newRecords = continuationTokenList.length > 0 ? eventsInState.concat(eventsData) : eventsData;
    yield put(setEventData(newRecords));
    yield put(setEventStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setEventError());
  }
}

export function* loadEventTypes() {
  try {
    const organizationId: string = yield select(selectOrganisationId);
    let response: EventTypeResponse = yield call(services.getEventTypes, organizationId);
    let eventTypesData: string[] = mapTypeEntityToModel(response);
    yield put(setEventTypes(eventTypesData));
  } catch (error) {
    yield put(setEventError());
  }
}

export function* saveEventFuzzySearch(action: PayloadAction<string>) {
  try {
    yield put(setEventFuzzySearch(action.payload));
  } catch (error) {
    yield put(setSnackBarError(String(error)));
  }
}

export function* clearEventList() {
  yield put(setEventData([]));
}

const mapEventEntityToModel = (response: EventListResponse) => {
  if (response && response.items.length > 0) {
    const result: EventModel[] = response.items.map((item, i) => {
      if (item?.type === 'pumpStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Pump ${item?.pump?.number} Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'pumpPrice') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Pump ${item?.pump?.number} Price`,
          details: item?.message,
          hoses: item?.hoses,
          expendDetails: true,
        } as EventModel;
      } else if (item?.type === 'pumpSnapshot') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Pump ${item?.pump?.number} Snapshot`,
          details: item?.message,
          hoses: item?.hoses,
          expendDetails: true,
        } as EventModel;
      } else if (item?.type === 'terminalConfiguration') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          displayType: `Terminal ${item?.terminal?.number} Configuration`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalStarted') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          displayType: `Terminal ${item?.terminal?.number} Started`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalPowerStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Power State`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalPasscodeEntered') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Passcode Entered`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalCardPresented') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Card Presented`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalDoorStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Door Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalPrinterStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Printer Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalTransactionStorageUsage') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Transaction Storage Usage`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalSdCardStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} SD Card Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'terminalWindcaveStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Windcave Status`,
          details: item?.message,
          windcave: item?.windcave,
          expendDetails: true,
        } as EventModel;
      } else if (item?.type === 'terminalBaseBoardStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Base Board Status`,
          details: item?.message,
          windcave: item?.windcave,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'tankStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Tank ${item?.tank?.number} Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'tankSnapshot') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Tank ${item?.tank?.number} Snapshot`,
          details: item?.message,
          product: item?.product,
          water: item?.water,
          ullage: item?.ullage,
          current: item?.current,
          temperature: item?.temperature,
          expendDetails:
            item?.product?.volume?.value ||
            item?.product?.volume?.temperatureCompensatedValue ||
            item?.product?.volume?.value ||
            item?.ullage?.value ||
            item?.product?.height?.value ||
            item?.water?.volume?.value ||
            item?.water?.height?.value ||
            item?.current?.value ||
            item?.temperature?.value,
        } as EventModel;
      } else if (item?.type === 'terminalEmergencyStopStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Terminal ${item?.terminal?.number} Emergency Stop Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'forecourtControllerStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Forecourt Controller ${item?.forecourtController?.number} Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'forecourtControllerBackOfficeRecordStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Forecourt Controller ${item?.forecourtController?.number} Back Office Record Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'priceSignStatus') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Price Sign ${item?.priceSign?.number} Status`,
          details: item?.message,
          expendDetails: false,
        } as EventModel;
      } else if (item?.type === 'priceSignPrice') {
        return {
          dateTimeUtc: item?.dateTimeUtc,
          id: item?.id,
          organisationId: item?.organisationId,
          siteName: item?.site?.name,
          type: item?.type,
          displayType: `Price Sign ${item?.priceSign?.number} Price`,
          details: item?.message,
          products: item?.products,
          expendDetails: item?.products && item?.products?.length > 0,
        } as EventModel;
      } else return {} as EventModel;
    });
    return result;
  }

  return [] as EventModel[];
};

const MapDetailsToEventRequest = (
  organisationId: string,
  continuationTokenList: string[],
  isReachEnd: boolean,
  filterOptions?: EventFilterModel
) => {
  const requestEntity: EventRequest = {
    limit: filterOptions?.limit ? filterOptions?.limit : 50,
    organisationId: organisationId,
    continuationToken: fieldMappingHelper.sanitizeStringValue(
      continuationTokenList.length > 0 && !isReachEnd
        ? continuationTokenList[continuationTokenList.length - 1]
        : isReachEnd
          ? ''
          : ''
    ),
    siteId: filterOptions?.siteId && filterOptions?.siteId !== 'undefined' ? filterOptions?.siteId : undefined,
    terminalId:
      filterOptions?.terminalId && filterOptions?.terminalId !== 'undefined' ? filterOptions?.terminalId : undefined,
    pumpId: filterOptions?.pumpId && filterOptions?.pumpId !== 'undefined' ? filterOptions?.pumpId : undefined,
    tankId: filterOptions?.tankId && filterOptions?.tankId !== 'undefined' ? filterOptions?.tankId : undefined,
    type: filterOptions?.type && filterOptions?.type !== 'Default' ? filterOptions?.type : undefined,
    startDateTimeUtc:
      filterOptions?.startDateTime && filterOptions?.dateRange !== 'Default'
        ? dateTimeHelper.convertDateStringtoUTCString(filterOptions?.startDateTime)
        : undefined,
    endDateTimeUtc:
      filterOptions?.endDateTime && filterOptions?.dateRange !== 'Default'
        ? dateTimeHelper.convertDateStringtoUTCString(filterOptions?.endDateTime)
        : undefined,
  };
  return requestEntity;
};

const mapTypeEntityToModel = (response: EventTypeResponse) => {
  if (response && response.items.length > 0) {
    return response?.items?.map((it) => {
      return it.type;
    });
  } else return [];
};
