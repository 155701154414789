export const ModuleName = Object.freeze({
  PRICE_SCHEDULE: 'priceschedule',
  SITE: 'site',
  PRODUCT: 'product',
  SITESTATUS: 'sitestatus',
  TANK: 'tank',
  TANK_DELIVERY: 'tank.delivery',
  TERMINAL: 'terminal',
  ORGANISATION: 'organisation',
  PUMP: 'pump',
  TRANSACTION: 'transaction',
  USER_MANAGEMENT: 'user-management',
  USER: 'user',
  PERMISSION_GROUP: 'permissiongroup',
  ACTIVITY_LOG: 'activitylog',
  DASHBOARD: 'dashboard',
  CARD_MANAGEMENT: 'card-management',
  BINRANGE: 'binrange',
  CARD: 'card',
  CARD_OPERATION: 'card.bulkoperation',
  TERMINAL_FIRMWARE: 'terminal.firmware',
  TERMINAL_OPERATION: 'terminal.operation',
  RECEIPT_TEMPLATE: 'receipt.template',
  REPORT: 'report',
  PRICE_SIGN: 'pricesign',
  MONITOR: 'monitor',
  EVENT: 'event',
  ALERT: 'alert',
  ALERT_RULE: 'alert.rule',
  ALERT_USER_GROUP: 'alert.usergroup',
  FORECOURT_CONTROLLER: 'forecourtcontroller',
});
