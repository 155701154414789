import { BulkOperationDetailsModel, BulkOperationsModel } from '../../models/cardOperationModel';

interface CardState {
  bulkOperationState: string;
  bulkOperations: BulkOperationsModel;
  runningOperationsDetails: BulkOperationDetailsModel[];
  operationsDetailsList: BulkOperationDetailsModel[];
  operationsDetailsListState: string;
  operationDetails: BulkOperationDetailsModel;
  operationType: string;
  storageLocationUrl: string;
  fileUploadedToStorage: boolean;
  fileUploadState: string;
}

export const initialState: CardState = {} as CardState;
