import { RootState } from '../../store/rootReducers';
import { selectUserInfo, selectUserPermission, selectUserStatus } from "../../store/users/selectors";
import { selectAccount } from "../../store/auth/selectors";
import { Dispatch } from '@reduxjs/toolkit';
import * as actions from '../../store/users/actions';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import * as headerActions from '../../store/header/actions';
import MyAccount from './my-account';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import * as modalActions from '../../store/modals/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';


const mapStateToProps = (state: RootState) => {
    return {
        userInfo: selectUserInfo(state),
        userStatus: selectUserStatus(state),
        userAccount: selectAccount(state),
        userPermissions: selectUserPermission(state)
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadMyAccount: (data?: string) => dispatch({ type: actions.LOAD_MY_ACCOUNT,payload: data }),
        setHeaderConfiguration:(data: HeaderStateModel) =>  dispatch({ type: headerActions.CHANGE_HEADER_CONFIGURATION, payload: data}),
        openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),    
        setPageConfiguration: (data: PageSettingStateModel) =>
        dispatch({
          type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
          payload: data,
        }),
        setIsPageDirty: (data: boolean) =>
        dispatch({
          type: pageConfigActions.SET_IS_PAGE_DIRTY,
          payload: data,
        }),
    }
};

const MyAccountPage = connect(mapStateToProps, mapDispatchToProps)(MyAccount);

export default MyAccountPage;