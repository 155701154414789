export const INIT_LOAD_CARD_LIST = 'compac/INIT_LOAD_CARD_LIST';
export const LOAD_CARD_LIST = 'compac/LOAD_CARD_LIST';
export const CLEAR_CARD_LIST = 'compac/CLEAR_CARD_LIST';
export const LOAD_CARD_BY_ID = 'compac/LOAD_CARD_BY_ID';
export const CLEAR_SELECTED_CARD = 'compac/CLEAR_SELECTED_CARD';
export const DELETE_CARD = 'compac/DELETE_CARD';
export const SAVE_CARD_FUZZY_SEARCH = 'compac/SAVE_CARD_FUZZY_SEARCH';
export const SET_IS_SCROLL_REQUEST = 'compac/SET_IS_SCROLL_REQUEST';
export const CREATE_CARD = 'compac/CREATE_CARD';
export const UPDATE_CARD = 'compac/UPDATE_CARD';
export const CREATE_CARD_PIN = 'compac/CREATE_CARD_PIN';
export const UPDATE_CARD_PIN = 'compac/UPDATE_CARD_PIN';
export const CHECK_CARD_HAS_PIN = 'compac/CHECK_CARD_HAS_PIN';
export const REMOVE_PIN = 'compac/REMOVE_PIN';
