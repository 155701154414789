import React, { useCallback, useEffect, useState } from 'react';
import { Moment } from 'moment';
import classNames from 'classnames';
import { ValidationError } from '../../models/baseModels/validationModel';
import {
  DATETIME_CONTROL_FUTURE_VALIDATION_MESSAGE,
  DATETIME_CONTROL_VALIDATION_MESSAGE,
} from '../../constants/dateTimePicker-constants';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import DatePickerComponent from '../datepicker/datepicker.container';
import TimePickerComponent from '../timepicker/timepicker.container';
import CustomTooltip from '../tooltip/custom-tooltip';
import './datetimepicker.scss';

interface DateTimePickerProps {
  label?: string;
  subLabel?: string;
  dateFormat: string;
  dateTimeValue: string;
  disableFuture: boolean;
  dateField: string;
  timeField: string;
  validateCounter?: number;
  isMandatory?: boolean;
  tooltipTitle?: string;
  readOnly?: boolean;
  minDate?: Date;
  invalidDateMessage?: string;
  disabledPassedDates?: Date;
  disabledFutureDates?: Date;
  fieldValidationStatus: (name: string) => ValidationError;
  setFieldValidation: (validation: ValidationError) => void;
  handleDateTimeChange: (newValue: string) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
  setToCurrentBrowserTime?: () => void;
}

const DateTimePickerControl = (props: DateTimePickerProps) => {
  const {
    label,
    dateFormat,
    dateTimeValue,
    disableFuture,
    dateField,
    timeField,
    validateCounter,
    isMandatory,
    tooltipTitle,
    readOnly,
    minDate,
    invalidDateMessage,
    disabledPassedDates,
    disabledFutureDates,
    handleDateTimeChange,
    setFieldValidation,
    removeValidation,
    fieldValidationStatus,
    setShowValidationMessage,
    setToCurrentBrowserTime,
  } = props;

  const dateTimeFieldName = `datetime${dateField}-${timeField}`;
  const [datePickerValue, setDatePickerValue] = useState('');
  const [timePickerValue, setTimePickerValue] = useState('');

  useEffect(() => {
    if (!!dateTimeValue) {
      setDatePickerValue(dateTimeValue);
      setTimePickerValue(dateTimeValue);
    } else {
      setDatePickerValue('');
      setTimePickerValue('');
    }
  }, [dateTimeValue]);

  const futureDateTimeValidation = useCallback(
    (value: string) => {
      if (new Date(value).valueOf() >= new Date().valueOf()) {
        setFieldValidation({
          name: dateTimeFieldName,
          hasError: true,
          showErrorMessage: false,
          validationMessage: DATETIME_CONTROL_FUTURE_VALIDATION_MESSAGE,
        });
        setShowValidationMessage({ key: dateTimeFieldName, value: true });
      } else {
        return true;
      }

      return false;
    },
    [dateTimeFieldName, setFieldValidation, setShowValidationMessage]
  );

  const validateDateTimeCombination = useCallback(
    (date?: string, time?: string) => {
      if (!isMandatory) {
        if ((date === '' || date === undefined) && !!time) {
          setFieldValidation({
            name: dateTimeFieldName,
            hasError: true,
            showErrorMessage: false,
            validationMessage: DATETIME_CONTROL_VALIDATION_MESSAGE,
          });
          setShowValidationMessage({ key: dateTimeFieldName, value: true });
        } else if (!!date || ((date === '' || date === undefined) && (time === '' || time === undefined))) {
          return true;
        }

        return false;
      }
    },
    [isMandatory, dateTimeFieldName, setFieldValidation, setShowValidationMessage]
  );

  const validate = useCallback(
    (value: string, date?: string, time?: string) => {
      let combinationValidationResult = validateDateTimeCombination(date, time);
      let futureValidationResult = disableFuture ? futureDateTimeValidation(value) : true;

      if (combinationValidationResult && futureValidationResult) {
        removeValidation(dateTimeFieldName);
      }
    },
    [disableFuture, dateTimeFieldName, removeValidation, futureDateTimeValidation, validateDateTimeCombination]
  );

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      validate(dateTimeValue, datePickerValue, timePickerValue);
    }
  }, [dateTimeValue, validateCounter, datePickerValue, timePickerValue, validate]);

  const onDateChange = (newValue: Moment | null) => {
    let momentToString = newValue?.toLocaleString();
    const combinedValue = dateTimeHelper.combineDateAndTime(momentToString, timePickerValue);
    setDatePickerValue(momentToString ? momentToString : '');
    handleDateTimeChange(combinedValue);
    validate(combinedValue, momentToString, timePickerValue);
  };

  const onTimeChange = (newValue: Moment | null) => {
    let momentToString = newValue?.toLocaleString();
    const combinedValue = dateTimeHelper.combineDateAndTime(datePickerValue, momentToString);
    setTimePickerValue(momentToString ? momentToString : '');
    handleDateTimeChange(combinedValue);
    validate(combinedValue, datePickerValue, momentToString);
  };

  return (
    <React.Fragment>
      <div
        className={
          fieldValidationStatus(dateTimeFieldName)?.hasError
            ? classNames('datetimepicker', 'datetimepicker-error')
            : classNames('datetimepicker')
        }
      >
        {label && (
          <div className='datetimepicker-main-label'>
            {label} {tooltipTitle && <CustomTooltip title={tooltipTitle} />}
            {isMandatory && !readOnly && <strong className='asterisk'>*</strong>}
          </div>
        )}

        <div className='datetimepicker-grid-container'>
          <DatePickerComponent
            validateCounter={validateCounter}
            invalidDateMessage={invalidDateMessage ? invalidDateMessage : ''}
            isMandatory={isMandatory ? isMandatory : false}
            dateFormat={dateFormat}
            disableFuture={disableFuture}
            handleDateChange={onDateChange}
            name={dateField}
            dateValue={datePickerValue}
            readOnly={readOnly}
            minDate={minDate}
            disabledPassedDates={disabledPassedDates}
            disabledFutureDates={disabledFutureDates}
            setToCurrentBrowserTime={setToCurrentBrowserTime}
          />
          <TimePickerComponent
            validateCounter={validateCounter}
            invalidDateMessage={invalidDateMessage ? invalidDateMessage : ''}
            isMandatory={isMandatory ? isMandatory : false}
            handleTimeChange={onTimeChange}
            name={timeField}
            timeValue={timePickerValue}
            readOnly={readOnly}
          />
        </div>
      </div>
      {fieldValidationStatus(dateTimeFieldName)?.hasError && (
        <div className='datetime-validation-text-message-error'>
          {fieldValidationStatus(dateTimeFieldName)?.validationMessage}
        </div>
      )}
    </React.Fragment>
  );
};

export default DateTimePickerControl;
