import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData } from '../../../store/modals/selectors';
import { selectBinRangeNameList } from '../../../store/bin-ranges/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import { selectTransactionFilter } from './../../../store/transaction/selectors';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as modalActions from '../../../store/modals/actions';
import EventFilter from './filter-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    siteList: selectSiteList(state),
    hasValidationError: selectHasValidationError(state),
    transactionFilterSetting: selectTransactionFilter(state),
    binRangeListName: selectBinRangeNameList(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const EventFilterForm = connect(mapStateToProps, mapDispatchToProps)(EventFilter);

export default EventFilterForm;
