import { LoadingStatus } from '../../constants/loading-constants';
import { ReportModel, TransactionReportColumn } from '../../models/reportModel';
import { TransactionFilterModel } from '../../models/transactionModel';

interface reportState {
  reportStatus: string;
  exportColumnsStatus: string;
  reportData: ReportModel[];
  exportColumnsData: string[];
  exportFilter: TransactionFilterModel;
  exportSelectedColumns: TransactionReportColumn[];
  hasError: boolean;
}

export const initialState: reportState = {
  reportStatus: LoadingStatus.LOADING,
} as reportState;
