import { createSelector } from 'reselect';
import { RootState } from '../rootReducers';

export const selectLocalState = (state: RootState) => state.CardOperationState;

export const selectStorageLocationUrl = createSelector(
    selectLocalState, 
    (state) => state.storageLocationUrl
);

export const selectFileUploadedToStorage = createSelector(
    selectLocalState, 
    (state) => state.fileUploadedToStorage
);

export const selectFileUploadState = createSelector(
    selectLocalState, 
    (state) => state.fileUploadState
);

export const selectBulkOperationState = createSelector(
    selectLocalState, 
    (state) => state.bulkOperationState
);

export const selectBulkOperations= createSelector(
    selectLocalState, 
    (state) => state.bulkOperations
);

export const selectOperationDetails= createSelector(
    selectLocalState, 
    (state) => state.operationDetails
);

export const selectRunningOperationsDetails= createSelector(
    selectLocalState, 
    (state) => state.runningOperationsDetails
);

export const selectOperationsDetailsList= createSelector(
    selectLocalState, 
    (state) => state.operationsDetailsList
);

export const selectOperationsDetailsListState= createSelector(
    selectLocalState, 
    (state) => state.operationsDetailsListState
);