import { Organisation } from '../../entities/organisation';
import { GuestUserEntity, PermissionAssignmentEntity, UserEntity } from '../../entities/users';
import api, { setAuthorize } from '../../utilities/api';

const USER_API_PREFIX = 'user-management';

export const getAllUsers = async (organisationId: string) => {
  await setAuthorize();
  const res = await api.get(`/${USER_API_PREFIX}/users?organisationId=${organisationId}`);
  return res.data;
};

export const deleteUserData = async (userId: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${USER_API_PREFIX}/users/${userId}?organisationId=${organisationId}`);
};

export const editUserInfo = async (data: UserEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${USER_API_PREFIX}/users/${id}`, data);
};

export const getAllPermissionGroup = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${USER_API_PREFIX}/users/permission-groups?organisationId=${organisationId}`);
  return result.data;
};

export const createUserInfo = async (data: UserEntity) => {
  await setAuthorize();
  const res = await api.post(`/${USER_API_PREFIX}/users`, data);
  return res.data;
};

export const sendInvite = async (userId: string, organisation: Organisation) => {
  await setAuthorize();
  const res = await api.post(`/${USER_API_PREFIX}/users/${userId}/invite`, organisation);
  return res.data;
};

export const inviteGuest = async (data: GuestUserEntity) => {
  await setAuthorize();
  const res = await api.post(`/${USER_API_PREFIX}/users/guests`, data);
  return res.data;
};

export const assignPermission = async (data: PermissionAssignmentEntity, userId: string) => {
  await setAuthorize();
  await api.post(`/${USER_API_PREFIX}/users/${userId}/permission-assignments`, data);
};

export const editPermission = async (data: PermissionAssignmentEntity, userId: string, id?: string) => {
  await setAuthorize();
  await api.put(`/${USER_API_PREFIX}/users/${userId}/permission-assignments/${id}`, data);
};

export const deleteUserPermissionAssignment = async (userId: string, id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${USER_API_PREFIX}/users/${userId}/permission-assignments/${id}?organisationId=${organisationId}`);
};

export const getUserOrganisations = async (userId: string) => {
  await setAuthorize();
  let result = await api.get(`/${USER_API_PREFIX}/users/${userId}/organisations`);
  return result.data;
};

export const getUserPermissionAssignments = async (userId: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${USER_API_PREFIX}/users/${userId}/permission-assignments?organisationId=${organisationId}`
  );
  return result.data;
};

export const getUserById = async (userId: string, organisationId: string) => {
  await setAuthorize();
  const res = await api.get(`/${USER_API_PREFIX}/users/${userId}?organisationId=${organisationId}`);
  return res.data;
};

export const getUserPermissions = async (userId: string, organisationId: string) => {
  await setAuthorize();
  const res = await api.get(`/${USER_API_PREFIX}/users/${userId}/permissions?organisationId=${organisationId}`);
  return res.data;
};
