import { AddressDetailsModel } from '../../models/siteModel';

interface AddressState {
  addressList: AddressDetailsModel[];
  selectedAddress: AddressDetailsModel;
  selectedAddressTimezone: string;
}

export const initialState: AddressState = {
  addressList: [] as AddressDetailsModel[],
} as AddressState;
