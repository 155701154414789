export const Messages = Object.freeze({
  GENERAL_ERROR: 'There was an error communicating with the server',
  NO_ACCESS_MESSAGE: 'Insufficient permissions',
  CONTACT_ADMIN: 'Please contact system admin',

  //SITE section
  SITE_DELETE_SUCESS: 'Site successfully deleted',
  SITE_SAVE_SUCCESS: 'Site successfully saved',
  SITE_INFORMATION_ERROR: 'Error loading site information',

  //Pump section
  PUMP_SAVE_SUCCESS: 'Pump successfully saved',
  PUMP_DELETE_SUCESS: 'Pump successfully deleted',
  PUMP_LOAD_ERROR: 'Error loading pump information',
  PUMP_PRODUCT_CODE_REQUIRED_MSG: 'Product code is required',

  //Terminal section
  TERMINAL_DELETE_SUCESS: 'Terminal successfully deleted',
  TERMINAL_SAVE_SUCCESS: 'Terminal successfully saved',
  TERMINAL_LOAD_ERROR: 'Error loading terminal information',
  TERMINAL_REENROL_SUCCESS: 'Terminal successfully re-enrolled',
  TERMINAL_REENROL_ERROR: 'Error re-enrolling terminal',

  //Terminal firmware section
  TERMINAL_FIRMWARE_UPDATE_SUCESS: 'Firmware installation started successfully',
  TERMINAL_FIRMWARE_DOWNLOAD_SUCESS: 'Firmware downloaded successfully',
  TERMINAL_FIRMWARE_DOWNLOAD_ERROR: 'Firmware is not available for download',

  //Terminal Operation section
  TERMINAL_RESTART_SUCESS: 'Terminal restart queued',
  TERMINAL_BOOTLOADER_RESTART_SUCESS: 'Terminal bootloader restart queued',
  TERMINAL_MEMORY_DUMP_SUCCESS: 'Terminal memory dump queued',
  TERMINAL_CANCEL_SUCCESS: 'Terminal operation successfully cancelled',
  TERMINAL_FILE_UPLOAD_SUCCESS: 'Terminal file retrieval queued',

  //Price Schedule section
  PRICESCHEDULE_INFO_ERROR: 'Error loading price schedule information',
  PRICESCHEDULE_SAVE_SUCCESS: 'Price Schedule successfully saved',
  PRICESCHEDULE_DELETE_SUCESS: 'Price Schedule successfully deleted',

  //Product section
  PRODUCTS_INFO_ERROR: 'Error loading product information',
  PRODUCTS_SAVE_SUCCESS: 'Product successfully saved',
  PRODUCTS_DELETE_SUCCESS: 'Product successfully deleted',

  //Organisation section
  ORGS_DELETE_SUCESS: 'Organisation successfully deleted',
  ORGS_INFORMATION_ERROR: 'Error loading organisation information',
  ORGS_SAVE_SUCCESS: 'Organisation successfully saved',
  ORGS_DELETE_NOT_ALLOWED: 'Cannot delete the active organisation',

  //Date Time
  DATE_RANGE_START_END_VALIDATION: 'Start date and time must be earlier than End date and time',
  DATE_TIME_FUTURE_VALIDATION: 'Date and time cannot be in the future',

  //general
  REQUIRED_FIELD: 'Required field',

  //User Section
  USER_DELETE_SUCCESS: 'User successfully deleted',
  USER_LOAD_ERROR: 'Error loading user information',
  USER_SAVE_SUCCESS: 'User successfully saved',
  USER_PERMISSION_ERROR: 'Warning: Permission Group assignment failed',
  USER_INVITE_ERROR: 'Warning: No Invitation sent',
  USER_INVITE_SUCCESS: 'Invitation successfully sent',
  USER_PERMISSION_SUCCESS: 'Permission assignment successful',
  GUEST_USER_CANNOT_BE_EDITED: 'Guest users cannot be edited',

  //Tank section
  TANK_DELETE_SUCCESS: 'Tank successfully deleted',
  TANK_SAVE_SUCCESS: 'Tank successfully saved',
  TANK_LOAD_ERROR: 'Error loading tank information',
  TANK_DELIVERY_SUCCESS: 'Tank delivery successfully saved',

  //Generic Error
  GENERIC_ERROR_HEADER: 'Oops, something went wrong.',
  SNACKBAR_DEFAULT_ERROR_MSG: 'An error occurred',

  //PermissionGroup section
  PERMISSION_GROUP_DELETE_SUCCESS: 'Permission Group successfully deleted',
  PERMISSION_GROUP_SAVE_SUCCESS: 'Permission Group successfully saved',
  PERMISSION_GROUP_LOAD_ERROR: 'Error loading permission group information',

  //BIN Range section
  BIN_RANGE_SAVE_SUCCESS: 'BIN Range successfully saved',
  BIN_RANGE_DELETE_SUCCESS: 'BIN Range successfully deleted',

  //Card section
  CARD_DELETE_SUCCESS: 'Card successfully deleted',
  CARD_SAVE_SUCCESS: 'Card successfully saved',
  PIN_CONFIG_REQUIRED: 'PIN configuration is required',

  //Receipt template
  RECEIPT_TEMPLATE_SAVE_SUCCESS: 'Receipt template successfully saved',
  RECEIPT_TEMPLATE_DELETE_SUCCESS: 'Receipt template successfully deleted',

  //File Upload
  FILE_UPLOAD_SUCCESS: 'File successfully uploaded',
  FILE_UPLOAD_ERROR: 'Error uploading the file',

  //Price Sign section
  PRICESIGN_SAVE_SUCCESS: 'Price Sign successfully saved',
  PRICESIGN_DELETE_SUCESS: 'Price Sign successfully deleted',

  //Alert Rules
  ALERT_RULE_DELETE_SUCCESS: 'Alert rule successfully deleted',
  ALERT_RULE_SAVE_SUCCESS: 'Alert rule successfully saved',
  ALERT_RULE_LOAD_ERROR: 'Error loading alert rule information',

  //Alert User Group
  ALERT_USER_GROUP_DELETE_SUCCESS: 'Alert user group successfully deleted',
  ALERT_USER_GROUP_SAVE_SUCCESS: 'Alert user group successfully saved',
  ALERT_USER_GROUP_LOAD_ERROR: 'Error loading alert user group information',

  //Forecourt Controller
  FORECOURTCONTROLLER_SAVE_SUCCESS: 'Forecourt controller successfully saved',
  FORECOURTCONTROLLER_DELETE_SUCESS: 'Forecourt controller successfully deleted',

  //Editable Table
  DUPLICATE_VALUES_ERROR_MESSAGE: 'Duplicate values are not allowed',
  DUPLICATE_HEIGHT_VALUES_IN_STRAPPING_TABLE_MSG:
    'Duplicate height or volume values in the strapping table are not allowed',

  // Report
  REPORT_GENERATE_QUEUED: 'Report successfully queued',
});
