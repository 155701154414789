import { useEffect, useState } from 'react';
import { FormControl, RadioGroup, Radio } from '@mui/material';
import { RadioGroupModel } from '../../models/baseModels/radioGroupModel';
import FormControlLabel from '@mui/material/FormControlLabel';
import './custom-radio-group.scss';

interface RadioGroupProps {
  groupLabel?: string;
  isMandatory?: boolean;
  readOnly?: boolean;
  defaultValue: string;
  items: RadioGroupModel[];
  horizontalList?: boolean;
  onRadioChange: (newvalue: string) => void | undefined;
}

const CustomRadioGroup = (props: RadioGroupProps) => {
  const { groupLabel, isMandatory, readOnly, defaultValue, items, horizontalList, onRadioChange } = props;
  const [value, setValue] = useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    onRadioChange(value);
  }, [value, onRadioChange]);

  return (
    <div>
      <FormControl>
        <div className='radio-buttons-group-label'>
          {groupLabel} {isMandatory && !readOnly && <strong className='asterisk'> *</strong>}
        </div>

        <RadioGroup
          row={horizontalList}
          aria-labelledby='radio-buttons-group-label'
          name='radio-buttons-group'
          className='radio-buttons-group'
          defaultValue={defaultValue}
          onChange={handleRadioChange}
        >
          {items?.map((item, i) => {
            return (
              <FormControlLabel
                className='radio-item'
                key={i}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default CustomRadioGroup;
