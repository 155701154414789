import * as actions from './actions';
import * as services from './services';
import * as terminalServices from '../terminal/services';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setAvailableFirmwareData,
  setTerminalBoardsData,
  setTerminalFimrwareStatus,
  setTerminalFirmwareData,
  setTerminalFirmwareError,
} from './reducers';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectTerminalBoards, selectTerminalFirmware } from './selectors';
import { selectOrganisationId } from '../auth/selectors';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { selectSelectedSiteId } from '../sites/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { TerminalModel } from '../../models/terminalModel';
import { TerminalInfoEntity } from '../../entities/terminal';
import {
  FirmwareModel,
  FirmwareStateModel,
  TerminalFirmwareModel,
  FirmwareInstallModel,
  TerminalBoardModel,
} from '../../models/terminalFirmwareModel';
import {
  AvailableFirmwaresEntity,
  FirmwareEntity,
  FirmwareInstallEntity,
  TerminalBoardEntity,
  TerminalBoardsEntity,
  TerminalCurrentFirmwareEntity,
  TerminalFirmwareStateEntity,
  TerminalPendingFirmwareEntity,
} from '../../entities/terminalFirmware';
import { closeModal, setModalActionStatus, setModalError } from '../modals/reducers';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { selectSelectedTerminal } from '../terminal/selectors';
import { Messages } from '../../constants/messages';
import * as fileDownload from '../../utilities/fileDownload-helper';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TERMINALS_FIRMWARES, loadCurrentAndPendingVersion);
  yield takeLatest(actions.CHECK_INSTALLATION_PROGRESS, updateStatusOfPendingVersion);
  yield takeLatest(actions.LOAD_AVAILABLE_FIRMWARES, loadAvailableFirmwares);
  yield takeLatest(actions.UPDATE_TERMINAL_FIRMWARE, updataTerminalFirmware);
  yield takeLatest(actions.GET_FIRMWARE_DOWNLOAD_LINK, getFirmwareDownloadLink);
}

export function* loadCurrentAndPendingVersion(action: PayloadAction<string>) {
  try {
    yield put(setTerminalFimrwareStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    const terminalInfoModel: TerminalModel = yield select(selectSelectedTerminal);

    let terminalModel: TerminalModel = {
      id: action.payload,
      organisationId: organisationId,
      number: 0,
      siteId: siteId,
    };

    let terminalId = terminalInfoModel?.id;
    let terminalNumber = terminalInfoModel?.number;

    if (terminalInfoModel?.id !== action.payload) {
      let terminalInfoResponse: TerminalInfoEntity = yield call(terminalServices.getTerminalInfo, terminalModel);

      terminalId = terminalInfoResponse?.id;
      terminalNumber = terminalInfoResponse?.number;
    }

    let boardResponse: TerminalBoardsEntity = yield call(services.getBoards, terminalModel);

    let boardsModel: TerminalBoardModel[] = MapBoardsEntityToModel(boardResponse?.items);
    yield put(setTerminalBoardsData(boardsModel));

    let firmwareResponse: TerminalFirmwareStateEntity = yield call(
      services.getCurrentAndPendingFirmwares,
      terminalModel
    );

    let modelData: TerminalFirmwareModel = MapTerminalFirmwareEntitiesToModel(
      terminalId,
      terminalNumber,
      boardsModel,
      firmwareResponse
    );

    yield put(setTerminalFirmwareData(modelData));
    yield put(setTerminalFimrwareStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTerminalFirmwareError());
  }
}

export function* updateStatusOfPendingVersion() {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const siteId: string = yield select(selectSelectedSiteId);
    let currentAndPendingVersion: TerminalFirmwareModel = yield select(selectTerminalFirmware);

    const terminalBoards: TerminalBoardModel[] = yield select(selectTerminalBoards);

    if (!!currentAndPendingVersion.pendingVersion && currentAndPendingVersion.pendingVersion.length > 0) {
      let terminalModel: TerminalModel = {
        id: currentAndPendingVersion.id,
        organisationId: organisationId,
        number: 0,
        siteId: siteId,
      };

      let firmwareResponse: TerminalFirmwareStateEntity = yield call(
        services.getCurrentAndPendingFirmwares,
        terminalModel
      );

      let updatedCurrentFirmWare = MapToCurrentVersionModel(firmwareResponse?.current, terminalBoards);
      let updatedPendingFirmware = MapToPendingVersionModel(firmwareResponse?.pending, terminalBoards);

      let updatecurrentAndPendingVersion: TerminalFirmwareModel = {
        id: currentAndPendingVersion.id,
        number: currentAndPendingVersion.number,
        currentVersion: updatedCurrentFirmWare,
        pendingVersion: updatedPendingFirmware,
      };
      yield put(setTerminalFirmwareData(updatecurrentAndPendingVersion));
    }
  } catch (error) {
    //Do nothing here, since it will retry and get the status on interval
  }
}

export function* loadAvailableFirmwares(action: PayloadAction<string>) {
  try {
    yield put(setModalActionStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);

    let firmwareResponse: AvailableFirmwaresEntity = yield call(
      services.getAvailableFirmwares,
      organisationId,
      action.payload
    );

    let recordData = MapAvailableFimwareEntityToModel(firmwareResponse);

    yield put(setAvailableFirmwareData(recordData));

    yield put(setModalActionStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTerminalFirmwareError());
  }
}

export function* updataTerminalFirmware(action: PayloadAction<FirmwareInstallModel>) {
  try {
    yield put(setModalActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let firmwareInstall: FirmwareInstallEntity = MapFirmwareModelToFirmwareInstallEntity(
      action.payload,
      organisationId
    );
    yield call(services.updateFirmware, firmwareInstall, action.payload.terminalId);
    yield put(setSnackBarSuccess(Messages.TERMINAL_FIRMWARE_UPDATE_SUCESS));
    yield put(closeModal());
    yield call(loadCurrentAndPendingVersion, {
      type: actions.LOAD_TERMINALS_FIRMWARES,
      payload: action.payload.terminalId,
    });
  } catch (error) {
    yield put(setModalError(true));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* getFirmwareDownloadLink(action: PayloadAction<string>) {
  try {
    yield put(setModalActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let response: FirmwareEntity = yield call(services.getFirmwareDownloadLink, organisationId, action.payload);
    if (response?.downloadLink) {
      fileDownload.downloadFileWithOutFileType(response?.downloadLink, `${action.payload}`);
      yield put(setSnackBarSuccess(Messages.TERMINAL_FIRMWARE_DOWNLOAD_SUCESS));
      yield put(closeModal());
    } else {
      yield put(setSnackBarError(Messages.TERMINAL_FIRMWARE_DOWNLOAD_ERROR));
      yield put(setModalActionStatus(LoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setModalError(true));
    let errorMsg = getApiErrorMessage(error);
    yield put(setModalActionStatus(LoadingStatus.ERROR));
    yield put(setSnackBarError(errorMsg));
  }
}

function MapAvailableFimwareEntityToModel(firmwareResponse: AvailableFirmwaresEntity) {
  if (firmwareResponse && firmwareResponse?.items?.length > 0) {
    const result: FirmwareModel[] = firmwareResponse.items.map((item, i) => {
      return {
        boardModel: item?.boardModel,
        version: item?.version,
        description: item?.description,
      } as FirmwareModel;
    });
    return result;
  }

  return [] as FirmwareModel[];
}

function MapTerminalFirmwareEntitiesToModel(
  terminalId: string,
  terminalNumber: number,

  boardResponse: TerminalBoardModel[],
  firmwareResponse: TerminalFirmwareStateEntity
): TerminalFirmwareModel {
  let modelData: TerminalFirmwareModel = {
    id: terminalId,
    number: terminalNumber,
    currentVersion: MapToCurrentVersionModel(firmwareResponse?.current, boardResponse),
    pendingVersion: MapToPendingVersionModel(firmwareResponse?.pending, boardResponse),
  };
  return modelData;
}

function MapToCurrentVersionModel(
  currentFirmwareEntity: TerminalCurrentFirmwareEntity,
  boardResponse: TerminalBoardModel[]
): FirmwareStateModel[] {
  let currentFirmware: FirmwareStateModel[] = [];
  for (let board of boardResponse) {
    let version: string | undefined = '';
    switch (board.model.toLowerCase()) {
      case 'ci500': {
        version = currentFirmwareEntity?.ci500?.version;
        break;
      }
      case 'ci502': {
        version = currentFirmwareEntity?.ci502?.version;
        break;
      }
      case 'ci506': {
        version = currentFirmwareEntity?.ci506?.version;
        break;
      }
      case 'ci525': {
        version = currentFirmwareEntity?.ci525?.version;
        break;
      }
      case 'ci526': {
        version = currentFirmwareEntity?.ci526?.version;
        break;
      }
    }
    currentFirmware.push({
      boardModel: board?.model,
      boardName: board?.name,
      version: version,
    } as FirmwareStateModel);
  }

  return currentFirmware;
}

function MapToPendingVersionModel(
  pending: TerminalPendingFirmwareEntity,
  boardResponse: TerminalBoardModel[]
): FirmwareStateModel[] {
  let pendingFirmware: FirmwareModel[] = [];
  if (!!pending?.ci500) {
    let board = boardResponse.find((x) => x.model.toLowerCase() === 'ci500');
    pendingFirmware.push({
      boardModel: board?.model,
      boardName: board?.name,
      version: pending?.ci500?.version,
      state: pending?.ci500?.state,
      progress: pending?.ci500?.progress,
    } as FirmwareStateModel);
  }
  if (!!pending?.ci502) {
    let board = boardResponse.find((x) => x.model.toLowerCase() === 'ci502');
    pendingFirmware.push({
      boardModel: board?.model,
      boardName: board?.name,
      version: pending?.ci502?.version,
      state: pending?.ci502?.state,
      progress: pending?.ci502?.progress,
    } as FirmwareStateModel);
  }
  if (!!pending?.ci506) {
    let board = boardResponse.find((x) => x.model.toLowerCase() === 'ci506');
    pendingFirmware.push({
      boardModel: board?.model,
      boardName: board?.name,
      version: pending?.ci506?.version,
      state: pending?.ci506?.state,
      progress: pending?.ci506?.progress,
    } as FirmwareStateModel);
  }
  if (!!pending?.ci525) {
    let board = boardResponse.find((x) => x.model.toLowerCase() === 'ci525');
    pendingFirmware.push({
      boardModel: board?.model,
      boardName: board?.name,
      version: pending?.ci525?.version,
      state: pending?.ci525?.state,
      progress: pending?.ci525?.progress,
    } as FirmwareStateModel);
  }
  if (!!pending?.ci526) {
    let board = boardResponse.find((x) => x.model.toLowerCase() === 'ci526');
    pendingFirmware.push({
      boardModel: board?.model,
      boardName: board?.name,
      version: pending?.ci526?.version,
      state: pending?.ci526?.state,
      progress: pending?.ci526?.progress,
    } as FirmwareStateModel);
  }

  return pendingFirmware;
}

function MapFirmwareModelToFirmwareInstallEntity(
  model: FirmwareInstallModel,
  organisationId: string
): FirmwareInstallEntity {
  if (!!model) {
    let installData: FirmwareInstallEntity = {
      organisationId: organisationId,
      version: model?.version,
      boardModel: model?.boardModel,
    };
    return installData;
  }
  return {} as FirmwareInstallEntity;
}

function MapBoardsEntityToModel(items: TerminalBoardEntity[]): TerminalBoardModel[] {
  if (items && items?.length > 0) {
    const result: TerminalBoardModel[] = items.map((item, i) => {
      return {
        name: item.name,
        model: item.model,
      } as TerminalBoardModel;
    });
    return result;
  }
  return [] as TerminalBoardModel[];
}
