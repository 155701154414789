import React, { useState, useEffect, useCallback } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import MainModal from '../../../components/main-modal/modal.container';
import { BinRangeItemListModel } from '../../../models/binRangeModel';
import { SiteConfigurationModel } from '../../../models/siteModel';
import { ReceiptTemplateItemModel } from '../../../models/receiptTemplateModel';
import { UnitPriceFormat } from '../../../constants/receipt-constants';

interface ReceiptTemplatePreviewModalProps {
  templateData: ReceiptTemplateItemModel;
  binRangeList: BinRangeItemListModel[];
  siteConfigurationItem: SiteConfigurationModel;
  closeModal: () => void;
}

const ReceiptTemplatePreviewModal: React.FC<ReceiptTemplatePreviewModalProps> = (
  props: ReceiptTemplatePreviewModalProps
) => {
  const { templateData, binRangeList, siteConfigurationItem, closeModal } = props;

  const onCancel = (event: any) => {
    closeModal();
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const now = new Date();
  const formattedDate = now
    .toLocaleDateString('en-AU', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    .replace(/\b(\w{3})\w*\b/, '$1')
    .replace(' ', '-')
    .toUpperCase();

  const formattedTime = now.toLocaleTimeString('en-AU', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  const generateReceipt = (string: string) => {
    const receiptList = [];
    for (let i = 0; i < string?.length; i += 30) {
      receiptList.push(string?.substring(i, i + 30));
    }
    return receiptList.map((content, i) => {
      const contentWithNonBreakingSpace = content.replace(/ /g, '\u00a0');
      return (
        <div className='receipt-list' key={i}>
          {contentWithNonBreakingSpace}
        </div>
      );
    });
  };

  const mockAccountTemplateData = `*-------DELIVERY RECORD------*${formattedDateTime}    TRAN 0001                              PUMP 01                       H2O                      $2.001.00L @${
    templateData?.unitPriceFormat === UnitPriceFormat.CENTSPERUNIT ? '200.0¢/L' : '$2.000/L'
  }                                             TOTAL                    $2.00                              *----------FUEL CARD---------*TERMINAL             Terminal1CARD          7800123412341234TOTAL                    $2.00           ACCEPTED           *----------------------------*`;
  const accountTempalte = (
    <React.Fragment>
      <div className='receipt'>
        {templateData?.headers?.length > 0 &&
          templateData.headers.map((h, i) => {
            return (
              <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
                {h.text}
              </div>
            );
          })}
        <div className='receipt-list-mock-data'>{generateReceipt(mockAccountTemplateData)}</div>
        {templateData?.footers?.length > 0 &&
          templateData.footers.map((h, i) => {
            return (
              <div className='receipt-list' key={i}>
                {h.text}
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
  const mockBankTemplateData = `*---------TAX INVOICE--------*${formattedDateTime}    TRAN 0001                              PUMP 01                       Diesel                   $2.001.00L @${
    templateData?.unitPriceFormat === UnitPriceFormat.CENTSPERUNIT ? '200.0¢/L' : '$2.000/L'
  }                                             TOTAL                    $2.00Including GST            $0.${
    2 * siteConfigurationItem?.taxRate
  }                              *-----------EFTPOS-----------*${formattedDateTime}       CREDITICC CONTACT VISA              CARD          ************0000AUTHORISATION           000000REFERENCE               000000PURCHASE               NZD2.00TOTAL                  NZD2.00           APPROVED                                                  PIN VERIFIED                                       *----------------------------*PLEASE RETAIN FOR YOUR RECORDS`;
  const bankTemplateWithOutDisclaimer = (
    <React.Fragment>
      <div className='receipt'>
        {templateData?.headers?.length > 0 &&
          templateData.headers.map((h, i) => {
            return (
              <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
                {h.text}
              </div>
            );
          })}
        <div className='receipt-list-mock-data'>{generateReceipt(mockBankTemplateData)}</div>
        {templateData?.footers?.length > 0 &&
          templateData.footers.map((h, i) => {
            return (
              <div className='receipt-list' key={i}>
                {h.text}
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
  const bankTemplateWithEftposDisclaimer = (
    <React.Fragment>
      <div className='receipt'>
        {templateData?.headers?.length > 0 &&
          templateData.headers.map((h, i) => {
            return (
              <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
                {h.text}
              </div>
            );
          })}
        <div className='receipt-list-mock-data'>{generateReceipt(mockBankTemplateData)}</div>
        {templateData?.footers?.length > 0 &&
          templateData.footers.map((h, i) => {
            return (
              <div className='receipt-list' key={i}>
                {h.text}
              </div>
            );
          })}
        {templateData?.disclaimer?.eftpos && (
          <div className='receipt-disclamier-list' key={'eftpos-disclaimer'}>
            {templateData.disclaimer.eftpos}
          </div>
        )}
      </div>
    </React.Fragment>
  );
  const bankTemplateWithCreditDisclaimer = (
    <React.Fragment>
      <div className='receipt'>
        {templateData?.headers?.length > 0 &&
          templateData.headers.map((h, i) => {
            return (
              <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
                {h.text}
              </div>
            );
          })}
        <div className='receipt-list-mock-data'> {generateReceipt(mockBankTemplateData)}</div>
        {templateData?.footers?.length > 0 &&
          templateData.footers.map((h, i) => {
            return (
              <div className='receipt-list' key={i}>
                {h.text}
              </div>
            );
          })}
        {templateData?.disclaimer?.credit && (
          <div className='receipt-disclamier-list' key={'credit-disclaimer'}>
            {templateData.disclaimer.credit}
          </div>
        )}
      </div>
    </React.Fragment>
  );
  const mockAmpolWindcaveTemplateData = `*---------TAX INVOICE--------*${formattedDateTime}    TRAN 0001                              PUMP 01                       Diesel                   $2.001.00L @${
    templateData?.unitPriceFormat === UnitPriceFormat.CENTSPERUNIT ? '200.0¢/L' : '$2.000/L'
  }                                             TOTAL                    $2.00Including GST            $0.${
    2 * siteConfigurationItem?.taxRate
  }                              *-----------EFTPOS-----------*TERMINAL              00000000TRAN 000000             SAVINGEFTPOS                   SWIPECARD          ************0000STAN                    000000AUTHORIZATION           000000TOTAL                  NZD2.00           APPROVED                                                  PIN VERIFIED                                       *----------------------------*PLEASE RETAIN FOR YOUR RECORDS`;
  const ampolWindcaveTemplate = (
    <React.Fragment>
      <div className='receipt'>
        {templateData?.headers?.length > 0 &&
          templateData.headers.map((h, i) => {
            return (
              <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
                {h.text}
              </div>
            );
          })}
        <div className='receipt-list-mock-data'>{generateReceipt(mockAmpolWindcaveTemplateData)}</div>
        {templateData?.footers?.length > 0 &&
          templateData.footers.map((h, i) => {
            return (
              <div className='receipt-list' key={i}>
                {h.text}
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );

  const theme = useTheme();
  const [activeTemplate, setActiveTemplate] = useState(0);
  const [templateList, setTemplateList] = useState([] as any[]);
  const [maxSteps, setMaxSteps] = useState(0);
  let newList = [] as any[];

  useEffect(() => {
    const selectedCardTypeList = templateData?.binRanges?.map((selected) => {
      return binRangeList?.find((it) => it.id === selected)?.cardType;
    });

    if (selectedCardTypeList && selectedCardTypeList.length > 0) {
      const hasBank = selectedCardTypeList.includes('bank');
      const hasAccountOrBp = selectedCardTypeList.includes('account') || selectedCardTypeList.includes('bpSterling');
      const hasWex = selectedCardTypeList.includes('wex');
      const hasAmpol = selectedCardTypeList.includes('ampol');

      const hasSelectedCompacProcessingRule =
        templateData?.binRanges
          ?.map((selected) => {
            return binRangeList?.find((it) => it?.id === selected && it?.cardType === 'ampol')
              ?.selectedCompacProcessingRule;
          })
          .findIndex((it) => it === true) >= 0;
      const hasSelectedWindcaveProcessingRule =
        templateData?.binRanges
          ?.map((selected) => {
            return binRangeList?.find((it) => it?.id === selected && it?.cardType === 'ampol')
              ?.selectedWindcaveProcessingRule;
          })
          .findIndex((it) => it === true) >= 0;

      const isFirstBank = selectedCardTypeList.indexOf('bank') < selectedCardTypeList.indexOf('account');
      const isFirstAccount = selectedCardTypeList.indexOf('account') < selectedCardTypeList.indexOf('bank');

      // logic could be updated
      if ((hasAccountOrBp || hasWex) && !hasBank) {
        newList.push(accountTempalte);
      } else if (hasBank && !(hasAccountOrBp || hasWex)) {
        if (templateData?.disclaimer?.credit && templateData?.disclaimer?.eftpos) {
          newList.push(bankTemplateWithEftposDisclaimer);
          newList.push(bankTemplateWithCreditDisclaimer);
        } else if (templateData?.disclaimer?.eftpos) {
          newList.push(bankTemplateWithEftposDisclaimer);
        } else if (templateData?.disclaimer?.credit) {
          newList.push(bankTemplateWithCreditDisclaimer);
        } else {
          newList.push(bankTemplateWithOutDisclaimer);
        }
      } else if (hasBank && (hasAccountOrBp || hasWex)) {
        if (isFirstAccount) {
          newList.push(accountTempalte);
          if (templateData?.disclaimer?.credit && templateData?.disclaimer?.eftpos) {
            newList.push(bankTemplateWithEftposDisclaimer);
            newList.push(bankTemplateWithCreditDisclaimer);
          } else if (templateData?.disclaimer?.eftpos) {
            newList.push(bankTemplateWithEftposDisclaimer);
          } else if (templateData?.disclaimer?.credit) {
            newList.push(bankTemplateWithCreditDisclaimer);
          } else {
            newList.push(bankTemplateWithOutDisclaimer);
          }
        } else if (isFirstBank) {
          if (templateData?.disclaimer?.credit && templateData?.disclaimer?.eftpos) {
            newList.push(bankTemplateWithEftposDisclaimer);
            newList.push(bankTemplateWithCreditDisclaimer);
          } else if (templateData?.disclaimer?.eftpos) {
            newList.push(bankTemplateWithEftposDisclaimer);
          } else if (templateData?.disclaimer?.credit) {
            newList.push(bankTemplateWithCreditDisclaimer);
          } else {
            newList.push(bankTemplateWithOutDisclaimer);
          }
          newList.push(accountTempalte);
        }
      }

      if (hasAmpol) {
        if (hasSelectedCompacProcessingRule && !hasSelectedWindcaveProcessingRule) {
          if ((hasAccountOrBp || hasWex) && !hasBank) return;
          else newList.push(accountTempalte);
        } else if (!hasSelectedCompacProcessingRule && hasSelectedWindcaveProcessingRule) {
          newList.push(ampolWindcaveTemplate);
        } else if (hasSelectedCompacProcessingRule && hasSelectedWindcaveProcessingRule) {
          if (!((hasAccountOrBp || hasWex) && !hasBank)) newList.push(accountTempalte);
          newList.push(ampolWindcaveTemplate);
        }
      }
    }

    setMaxSteps(newList.length);
    setTemplateList(newList);
  }, [binRangeList, templateData]);

  const handleNext = () => {
    setActiveTemplate((prevActiveTemplate) => prevActiveTemplate + 1);
  };

  const handleBack = () => {
    setActiveTemplate((prevActiveTemplate) => prevActiveTemplate - 1);
  };

  const DisplayReceiptTemplate = useCallback(() => {
    if (templateList[activeTemplate]) {
      return templateList[activeTemplate];
    } else
      return (
        <React.Fragment>
          <div className='receipt-template'>
            {templateData?.headers &&
              templateData?.headers?.length > 0 &&
              templateData.headers.map((h, i) => {
                return (
                  <div className={i === 0 ? 'receipt-list-first-header' : 'receipt-list'} key={i}>
                    {h.text}
                  </div>
                );
              })}
            <div className='receipt-list-mock-data'>{generateReceipt(mockAccountTemplateData)}</div>
            {templateData?.footers &&
              templateData?.footers?.length > 0 &&
              templateData.footers.map((h, i) => {
                return (
                  <div className='receipt-list' key={i}>
                    {h.text}
                  </div>
                );
              })}
          </div>
        </React.Fragment>
      );
  }, [activeTemplate, templateData, templateList]);

  return (
    <>
      <MainModal
        className='receipt-preview-modal'
        onClose={onCancel}
        modalTitle='Receipt'
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={false}
        cancelButtonText='Cancel'
        saveButtonText='Download'
        closeButtonText='Close'
        hideFooter={true}
      >
        <div className='receipt-containter'>
          <DisplayReceiptTemplate />
        </div>
        {maxSteps > 0 && (
          <MobileStepper
            className='receipt-navigation'
            variant='text'
            steps={maxSteps}
            position='static'
            activeStep={activeTemplate}
            nextButton={
              <Button size='small' onClick={handleNext} disabled={activeTemplate === maxSteps - 1}>
                Next
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size='small' onClick={handleBack} disabled={activeTemplate === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        )}
      </MainModal>
    </>
  );
};

export default ReceiptTemplatePreviewModal;
