import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface DownloadIconProps{
    className?:string
}

const DownloadIcon = (props: DownloadIconProps) => {
    return (
        <SvgIcon className={`base-icon download-icon ${props.className}`} />
    );
};

export default DownloadIcon;