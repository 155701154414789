import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingStatus } from '../../constants/loading-constants';
import { NavPaneStates } from '../../constants/window-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { PermissionGroupItemsModel } from '../../models/permissionGroupModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import MessageDisplay from '../../components/message-display/message-display';
import EmptyList from '../../components/empty-list/empty-list';
import FooterBar from '../../components/footer/footer';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import PermissionGroupItem from './permission-group-item';
import './styles/permission-group.scss';

interface PermissionGroupProps {
  permissiongroupContent: PermissionGroupItemsModel[];
  permissiongroupStatus: string;
  navPaneState: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadPermissionGroup: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  deletePermissionItem: (id: string) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const PermissionGroup: React.FC<PermissionGroupProps> = (props: PermissionGroupProps) => {
  const {
    permissiongroupContent,
    permissiongroupStatus,
    navPaneState,
    authStatus,
    userAccess,
    loadPermissionGroup,
    setHeaderConfiguration,
    deletePermissionItem,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  const isLoadPermissionGroupRequested = useRef(false);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PERMISSION_GROUP).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PERMISSION_GROUP).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PERMISSION_GROUP).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.PERMISSION_GROUP).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(permissiongroupStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(permissiongroupStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(permissiongroupStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, permissiongroupStatus]);

  const [permissionGroupDataToDisplay, setPermissionGroupDataToDisplay] = useState(permissiongroupContent);
  const emptyListMessage = 'No permission groups found';
  const [deleteContext, setDeleteContent] = useState('');
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    if (!isLoadPermissionGroupRequested.current && authSuccess && hasReadAccess) {
      loadPermissionGroup();
    }
    isLoadPermissionGroupRequested.current = true;
  }, [authSuccess, hasReadAccess, loadPermissionGroup]);

  useEffect(() => {
    setPermissionGroupDataToDisplay(permissiongroupContent);
  }, [permissiongroupContent]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Permission Groups',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      pageURL: 'permission-groups',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deletePermissionItem(selectedId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const onClickHandler = (id: string) => {
    navigate(`/organisations/${orgId}/permission-groups/${id}/details`);
  };

  /** ACTION MENU (Create, Edit, Clone, Delete) */
  const onEditHandler = (id: string) => {
    navigate(`/organisations/${orgId}/permission-groups/${id}/details/edit`);
  };

  const onCloneHandler = (id: string) => {
    navigate(`/organisations/${orgId}/permission-groups/${id}/create`);
  };

  const onDeleteHandler = useCallback(
    (id: string, name: string) => {
      setSelectedId(id);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Permission Group'
        />
      }

      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {success && (
        <div className='group'>
          {!!permissionGroupDataToDisplay && permissionGroupDataToDisplay.length > 0 && (
            <>
              <div
                className={classNames('header-container', {
                  'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
                  'list-collapsed-width':
                    navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
                  'list-hamburger-width':
                    navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
                })}
              >
                <div className='group-list-header'>
                  <div className='group-name'>Group Name</div>
                  <div className='group-desc'>Description</div>
                  <div className='group-permissions'>Permissions Count</div>
                </div>
              </div>
              <div className='group-list-container'>
                <>
                  {permissionGroupDataToDisplay.map((item, i) => (
                    <PermissionGroupItem
                      key={i}
                      name={item.name}
                      desc={item.description}
                      id={item.id}
                      permissions={!!item.permissionNames ? item.permissionNames?.length.toString() : 'Unspecified'}
                      hasDeleteAccess={hasDeleteAccess}
                      hasUpdateAccess={hasUpdateAccess}
                      hasCreateAccess={hasCreateAccess}
                      onDelete={onDeleteHandler}
                      onEdit={onEditHandler}
                      onClone={onCloneHandler}
                      onClick={onClickHandler}
                    ></PermissionGroupItem>
                  ))}
                </>
              </div>
            </>
          )}
          {<>{permissionGroupDataToDisplay?.length === 0 && <EmptyList message={emptyListMessage}></EmptyList>}</>}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!permissiongroupContent && !!permissionGroupDataToDisplay && permissionGroupDataToDisplay.length < 1
                ? 'footer-no-content'
                : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default PermissionGroup;
