import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertTitle, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DropDown from '../../../components/dropdown/dropdown.container';
import Form from '../../../components/form/form.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomButton from '../../../components/button/custom-button';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import { ModuleName } from '../../../constants/module-constants';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { BinRangeModel, CardProcessingRuleModel, CardTagModel } from '../../../models/binRangeModel';
import { TabListModel } from '../../../models/baseModels/tabListModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import { binRangeType, cardType, DEFAULT_DROP_DOWN_VALUE, PromptTypes } from '../../../constants/dropdown-constants';
import { FormActionType } from '../../../constants/form-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { ButtonStyle } from '../../../constants/button-constants';
import { DefaultBinRangeBasedOnCardType } from '../../../constants/binRange-constants';
import CardProcessingRule from './card-processing-rule';

interface BinRangeProps {
  binRangeItem: BinRangeModel;
  binRangeStatus: string;
  hasValidationError: boolean;
  siteListName: KeyValuePair[];
  pageTitle?: string;
  action?: string;
  backDropActionStatus: string;
  fieldValidations: ValidationError[];
  userAccess: (moduleName: string) => AuthorisationModel;
  loadBinRangeData: (id: string) => void;
  createBinRange: (item: BinRangeModel) => void;
  editBinRange: (item: BinRangeModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
  loadSiteNameList: () => void;
  setFieldValidation: (validation: ValidationError) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
}

const BinRange: React.FC<BinRangeProps> = (props: BinRangeProps) => {
  const {
    binRangeItem,
    binRangeStatus,
    hasValidationError,
    siteListName,
    pageTitle,
    action,
    backDropActionStatus,
    fieldValidations,
    userAccess,
    loadBinRangeData,
    createBinRange,
    editBinRange,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
    loadSiteNameList,
    setFieldValidation,
    setShowValidationMessage,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  let [binRange, setBinRange] = useState({} as BinRangeModel);
  const navigate = useNavigate();
  const { orgId, binRangeId } = useParams();
  const isLoadBinRangeDataRequested = useRef(false);
  const isLoadSiteNameListRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasSiteReadAccess, setHasSiteReadAccess] = useState(false);
  const [hasSiteCreateAccess, setHasSiteCreateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.BINRANGE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.BINRANGE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.BINRANGE).hasUpdateAccess);
    setHasSiteReadAccess(userAccess(ModuleName.SITE).hasReadAccess);
    setHasSiteCreateAccess(userAccess(ModuleName.SITE).hasCreateAccess);
  }, [userAccess]);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [binRangeCompareValidation, setBinRangeCompareValidation] = useState(false);
  const [isSelectedSites, setIsSelectedSites] = useState(false);
  const [binRangeCompareMsg, setBinRangeCompareMsg] = useState('');
  const [secondaryButtonMoreOptions, setSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [primaryButtonMoreOptions, setPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);

  useMemo(() => {
    setLoading(binRangeStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(binRangeStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(binRangeStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, binRangeStatus]);

  useEffect(() => {
    if (!!!siteListName && !isLoadSiteNameListRequested.current) {
      loadSiteNameList();
      isLoadSiteNameListRequested.current = true;
    }
  }, [loadSiteNameList, siteListName]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [action]);

  useEffect(() => {
    setIsPageDirty(false);
    if (
      !isLoadBinRangeDataRequested.current &&
      !!binRangeId &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadBinRangeData(binRangeId);
      isLoadBinRangeDataRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      removeAllValidation();
    }
  }, [
    action,
    binRangeId,
    hasCreateAccess,
    hasReadAccess,
    isClickFromViewPage,
    loadBinRangeData,
    removeAllValidation,
    setIsPageDirty,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      showWizard: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setIsPageDirty(false);
    if (action === FormActionType.EDIT && hasReadAccess) {
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setBinRange({
        type: 'authorisation',
        cardProcessingRules: [
          {
            name: 'Rule 1',
            sites: [],
            applyToAllSites: true,
            cachedCardsMethod: 'compac',
            cachedCardsOnlineEnabled: true,
            cachedCardsOnlineAuthorisationAmount: undefined,
            cachedCardsOnlineAlternateAuthorisationAmount: undefined,
            cachedCardsOfflineEnabled: true,
            cachedCardsOfflineMaxTransactionsPerCardPerDay: undefined,
            cachedCardsOfflineAuthorisationAmount: undefined,
            cachedCardsOfflineAlternateAuthorisationAmount: undefined,
            cachedCardsRequiredPrompts: [],
            avaliableCachedCardsRequiredPrompts: PromptTypes.filter(
              (item) => item.key !== 'productConfirmation' && item.key !== 'secondPump'
            ),
            cachedCardsOptionalPrompts: [],
            avaliableCachedCardsOptionalPrompts: PromptTypes.filter((item) => item.key !== 'secondPump'),
            uncachedCardsMethod: binRange?.cardType === 'bank' ? 'windcave' : 'compac',
            uncachedCardsOnlineEnabled: true,
            uncachedCardsOnlineAuthorisationAmount: undefined,
            uncachedCardsOnlineAlternateAuthorisationAmount: undefined,
            uncachedCardsOfflineEnabled: binRange?.cardType === 'wex' ? false : true,
            uncachedCardsOfflineAuthorisationAmount: undefined,
            uncachedCardsOfflineAlternateAuthorisationAmount: undefined,
            uncachedCardsRequiredPrompts: [],
            avaliableUncachedCardsRequiredPrompts: PromptTypes.filter(
              (item) =>
                item.key !== 'productConfirmation' && (binRange?.cardType === 'bank' ? '' : item.key !== 'secondPump')
            ),
            uncachedCardsOptionalPrompts: [],
            avaliableUncachedCardsOptionalPrompts: PromptTypes.filter((item) =>
              binRange?.cardType === 'bank' ? '' : item.key !== 'secondPump'
            ),
            displaySelectAllSiteOption: true,
            displaySiteOption: siteListName,
            merchantId: '',
            terminalId: '',
          } as CardProcessingRuleModel,
        ],
      } as BinRangeModel);
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess, siteListName, setIsPageDirty]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/bin-ranges`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  useEffect(() => {
    if (binRange?.cardType === 'bank' && (action === FormActionType.CREATE || action === FormActionType.EDIT)) {
      setBinRange((prevstate) => {
        return {
          ...prevstate,
          binLow: DefaultBinRangeBasedOnCardType.bank[0],
          binHigh: DefaultBinRangeBasedOnCardType.bank[1],
        };
      });
      binRange?.cardProcessingRules?.forEach((_, i) => {
        removeValidation(`merchantId${i}`);
        removeValidation(`terminalId${i}`);
      });
    } else if (binRange?.cardType === 'wex' && action === FormActionType.EDIT) {
      setBinRange((prevstate) => {
        return {
          ...prevstate,
          binLow: DefaultBinRangeBasedOnCardType.wex[0],
          binHigh: DefaultBinRangeBasedOnCardType.wex[1],
        };
      });
    } else if (binRange?.cardType === 'wex' && action === FormActionType.CREATE) {
      setBinRange((prevstate) => {
        return {
          ...prevstate,
          binLow: DefaultBinRangeBasedOnCardType.wex[0],
          binHigh: DefaultBinRangeBasedOnCardType.wex[1],
          cardProcessingRules: [
            {
              name: 'Rule 1',
              sites: [] as string[],
              applyToAllSites: true,
              displaySelectAllSiteOption: true,
              displaySiteOption: siteListName,
              uncachedCardsMethod: 'compac',
              uncachedCardsOnlineEnabled: true,
              uncachedCardsOnlineAuthorisationAmount: undefined,
              uncachedCardsOfflineEnabled: false,
              uncachedCardsOfflineAuthorisationAmount: undefined,
              uncachedCardsRequiredPrompts: [] as string[],
              avaliableUncachedCardsRequiredPrompts: PromptTypes.filter(
                (item) => item.key !== 'productConfirmation' && item.key !== 'secondPump'
              ),
              uncachedCardsOptionalPrompts: [] as string[],
              avaliableUncachedCardsOptionalPrompts: PromptTypes.filter((item) => item.key !== 'secondPump'),
            } as CardProcessingRuleModel,
          ],
        };
      });
    } else if (binRange?.cardType === 'bpSterling' && action === FormActionType.CREATE) {
      setBinRange((prevstate) => {
        return {
          ...prevstate,
          binLow: DefaultBinRangeBasedOnCardType.bpSterling[0],
          binHigh: DefaultBinRangeBasedOnCardType.bpSterling[1],
          cardProcessingRules: [
            {
              name: 'Rule 1',
              sites: [] as string[],
              applyToAllSites: true,
              displaySelectAllSiteOption: true,
              displaySiteOption: siteListName,
              uncachedCardsMethod: 'compac',
              uncachedCardsOnlineEnabled: true,
              uncachedCardsOnlineAuthorisationAmount: undefined,
              uncachedCardsOfflineEnabled: false,
              uncachedCardsOfflineAuthorisationAmount: undefined,
              uncachedCardsRequiredPrompts: [] as string[],
              avaliableUncachedCardsRequiredPrompts: PromptTypes.filter(
                (item) => item.key !== 'productConfirmation' && item.key !== 'secondPump'
              ),
              uncachedCardsOptionalPrompts: [] as string[],
              avaliableUncachedCardsOptionalPrompts: PromptTypes.filter((item) => item.key !== 'secondPump'),
              cachedCardsMethod: 'compac',
              cachedCardsOnlineEnabled: true,
              cachedCardsOnlineAuthorisationAmount: undefined,
              cachedCardsOfflineEnabled: false,
              cachedCardsOfflineAuthorisationAmount: undefined,
              cachedCardsRequiredPrompts: [] as string[],
              avaliableCachedCardsRequiredPrompts: PromptTypes.filter(
                (item) => item.key !== 'productConfirmation' && item.key !== 'secondPump'
              ),
              cachedCardsOptionalPrompts: [] as string[],
              avaliableCachedCardsOptionalPrompts: PromptTypes.filter((item) => item.key !== 'secondPump'),
            } as CardProcessingRuleModel,
          ],
        };
      });
    } else if (action === FormActionType.CREATE) {
      setBinRange((prevstate) => {
        return {
          ...prevstate,
          binLow: '',
          binHigh: '',
        };
      });
      binRange?.cardProcessingRules?.forEach((_, i) => {
        removeValidation(`merchantId${i}`);
        removeValidation(`terminalId${i}`);
      });
    }
  }, [binRange?.cardType]);

  useMemo(() => {
    if (!!binRangeItem) {
      setBinRange(binRangeItem);
    }
  }, [binRangeItem, action]);

  useEffect(() => {
    if (binRange.binHigh && binRange.binLow) {
      const regExp = /[a-zA-Z]/g;

      if (binRange.binHigh?.length !== binRange.binLow?.length) {
        setBinRangeCompareValidation(true);
        setBinRangeCompareMsg('BIN High and BIN Low should have same length');
        return;
      }

      if (regExp.test(binRange.binHigh) || regExp.test(binRange.binLow)) {
        if (binRange.binHigh.localeCompare(binRange.binLow) < 0) {
          setBinRangeCompareValidation(true);
          setBinRangeCompareMsg('BIN High should be higher or equal to BIN Low');
        } else setBinRangeCompareValidation(false);
      } else if (Number(binRange.binLow) > Number(binRange.binHigh)) {
        setBinRangeCompareValidation(true);
        setBinRangeCompareMsg('BIN High should be higher or equal to BIN Low');
      } else setBinRangeCompareValidation(false);
    }

    if (
      binRange?.cardProcessingRules
        ?.map((it) => {
          return it.sites;
        })
        .flat().length <= 0
    ) {
      setIsSelectedSites(false);
    } else {
      setIsSelectedSites(true);
      setBinRangeCompareValidation(false);
    }
  }, [binRange]);

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    let wexSectionValidationError = false;
    if (binRange?.cardType === 'wex') {
      const updatedCardProcessingRules = binRange?.cardProcessingRules?.map((it, i) => {
        if (it?.terminalId?.length <= 0 || it?.terminalId === '') {
          setFieldValidation({
            name: `terminalId${i}`,
            hasError: true,
            showErrorMessage: false,
            validationMessage: 'Required',
          });
          setShowValidationMessage({ key: `terminalId${i}`, value: true });
          wexSectionValidationError = true;
          return it;
        } else if (it?.merchantId?.length <= 0 || it?.merchantId === '') {
          setFieldValidation({
            name: `merchantId${i}`,
            hasError: true,
            showErrorMessage: false,
            validationMessage: 'Required',
          });
          setShowValidationMessage({ key: `merchantId${i}`, value: true });
          wexSectionValidationError = true;
          return it;
        } else if (it?.merchantId) {
          return { ...it, merchantId: it.merchantId.padStart(15, '0') };
        } else {
          return it;
        }
      });
      binRange = { ...binRange, cardProcessingRules: updatedCardProcessingRules };
    }

    if (wexSectionValidationError) return;
    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editBinRange(binRange);
          break;
        case FormActionType.CREATE:
          createBinRange(binRange);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
      setBinRange((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, action]
  );

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/bin-ranges/${binRangeId}/details/edit`);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleNewCardprocessingRuleValue = useCallback((newvalue: CardProcessingRuleModel[]) => {
    setBinRange((prevstate) => {
      return {
        ...prevstate,
        cardProcessingRules: [...newvalue],
      };
    });
  }, []);

  const handleAddTagClick = () => {
    let tagItem = {
      key: '',
    } as CardTagModel;
    let list = [tagItem];
    if (binRange?.cardTags !== undefined) {
      list = [...binRange?.cardTags];
      list.push(tagItem);
    }

    setBinRange((prevstate) => {
      return {
        ...prevstate,
        cardTags: list,
      };
    });
  };

  const handleRemoveTagClick = (index: number) => {
    //resetPriceListValidation();

    let list = [...binRange?.cardTags];
    list.splice(index, 1);

    setBinRange((prevstate) => {
      return {
        ...prevstate,
        cardTags: list,
      };
    });
  };

  const handleNewTagValue = (newvalue: KeyValuePair, index: number) => {
    let tags = [...binRange?.cardTags];
    if (!!tags[index]) {
      let item = { ...tags[index] };
      item.key = !!newvalue.value ? newvalue.value.toString() : '';
      tags[index] = item;
    }

    setBinRange((prevstate) => {
      return {
        ...prevstate,
        cardTags: tags,
      };
    });
  };

  const tabWizardList = [
    {
      tabType: 'link',
      tabLink: `/organisations/${orgId}/bin-ranges`,
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>BIN Ranges</span> <NavigateNextIcon className='next-icon' />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Basic Info</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.find(
        (it) =>
          (it.name === 'name' && it.hasError === true) ||
          (it.name === 'type' && it.hasError === true) ||
          (it.name === 'cardType' && it.hasError === true) ||
          (it.name === 'binHigh' && it.hasError === true) ||
          (it.name === 'binLow' && it.hasError === true)
      ),
      tabFormCompleted:
        !fieldValidations?.find(
          (it) =>
            (it.name === 'name' && it.hasError === true) ||
            (it.name === 'type' && it.hasError === true) ||
            (it.name === 'cardType' && it.hasError === true) ||
            (it.name === 'binHigh' && it.hasError === true) ||
            (it.name === 'binLow' && it.hasError === true)
        ) &&
        binRange?.name &&
        binRange?.type &&
        binRange?.cardType &&
        binRange?.binHigh &&
        binRange?.binLow,
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='name'
            onBindingValue={onTextChangeHandler}
            label='Name'
            placeholder='Enter Name'
            name='name'
            value={binRange?.name}
            type='input'
            minCharLength={3}
            maxCharLength={30}
            readOnly={!isUpdatingForm}
          />

          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={false}
            key='description'
            onBindingValue={onTextChangeHandler}
            label='Description'
            placeholder='Enter Description'
            name='description'
            value={binRange?.description}
            type='input'
            maxCharLength={250}
            readOnly={!isUpdatingForm}
          />

          <DropDown
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='type'
            name='type'
            value={!!binRange?.type ? binRange?.type : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label='BIN Range Type'
            keyValuePair={binRangeType}
            readOnly={!isUpdatingForm}
          />

          <DropDown
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='cardType'
            name='cardType'
            value={!!binRange?.cardType ? binRange?.cardType : DEFAULT_DROP_DOWN_VALUE}
            onBindingValue={onTextChangeHandler}
            label='Card Type'
            keyValuePair={cardType}
            readOnly={!isUpdatingForm}
          />

          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='binHigh'
            onBindingValue={onTextChangeHandler}
            label='BIN High'
            placeholder='Enter BIN High'
            name='binHigh'
            value={binRange?.binHigh}
            type={TextFieldType.ALPHANUMERIC}
            maxCharLength={8}
            readOnly={!isUpdatingForm}
            tooltipTitle='Bank Identification Number'
            compareValidation={binRangeCompareValidation}
            compareValidationMsg={binRangeCompareMsg}
          />

          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            key='binLow'
            onBindingValue={onTextChangeHandler}
            label='BIN Low'
            placeholder='Enter BIN Low'
            name='binLow'
            value={binRange?.binLow}
            type={TextFieldType.ALPHANUMERIC}
            maxCharLength={8}
            readOnly={!isUpdatingForm}
            compareValidation={binRangeCompareValidation}
            compareValidationMsg={binRangeCompareMsg}
          />

          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={false}
            key='access'
            onBindingValue={onTextChangeHandler}
            label='Access'
            placeholder='Enter Access'
            name='access'
            value={binRange?.access}
            type='input'
            minCharLength={5}
            maxCharLength={5}
            readOnly={!isUpdatingForm || binRange?.cardType === 'bank'}
            inputTooltip={binRange?.cardType === 'bank' ? 'Access cannot be configured for Bank type BIN Range.' : ''}
          />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Card Processing Rules</span>
        </div>
      ),
      tabError:
        binRange?.cardType === 'wex'
          ? fieldValidations?.find(
              (it) =>
                (it.name === 'name0' && it.hasError === true) ||
                (it.name === 'sites0' && it.hasError === true) ||
                (it.name === 'merchantId0' && it.hasError === true) ||
                (it.name === 'terminalId0' && it.hasError === true) ||
                (it.name === 'name1' && it.hasError === true) ||
                (it.name === 'sites1' && it.hasError === true) ||
                (it.name === 'merchantId1' && it.hasError === true) ||
                (it.name === 'terminalId1' && it.hasError === true) ||
                (it.name === 'name2' && it.hasError === true) ||
                (it.name === 'sites2' && it.hasError === true) ||
                (it.name === 'merchantId2' && it.hasError === true) ||
                (it.name === 'terminalId2' && it.hasError === true)
            )
          : fieldValidations?.find(
              (it) =>
                (it.name === 'name0' && it.hasError === true) ||
                (it.name === 'sites0' && it.hasError === true) ||
                (it.name === 'name1' && it.hasError === true) ||
                (it.name === 'sites1' && it.hasError === true) ||
                (it.name === 'name2' && it.hasError === true) ||
                (it.name === 'sites2' && it.hasError === true)
            ),
      tabFormCompleted:
        binRange?.cardType === 'wex'
          ? !fieldValidations?.find(
              (it) =>
                (it.name === 'name0' && it.hasError === true) ||
                (it.name === 'sites0' && it.hasError === true) ||
                (it.name === 'merchantId0' && it.hasError === true) ||
                (it.name === 'terminalId0' && it.hasError === true) ||
                (it.name === 'name1' && it.hasError === true) ||
                (it.name === 'sites1' && it.hasError === true) ||
                (it.name === 'merchantId1' && it.hasError === true) ||
                (it.name === 'terminalId1' && it.hasError === true) ||
                (it.name === 'name2' && it.hasError === true) ||
                (it.name === 'sites2' && it.hasError === true) ||
                (it.name === 'merchantId2' && it.hasError === true) ||
                (it.name === 'terminalId2' && it.hasError === true)
            ) &&
            binRange?.cardProcessingRules
              ?.map((it, i) => {
                if (it) {
                  if (
                    binRange?.cardProcessingRules[i] &&
                    binRange?.cardProcessingRules[i].merchantId &&
                    binRange?.cardProcessingRules[i].terminalId &&
                    (binRange?.cardProcessingRules[0].applyToAllSites || binRange?.cardProcessingRules[0].sites)
                  ) {
                    return true;
                  } else return false;
                } else return false;
              })
              .every((value) => value === true)
          : !fieldValidations?.find(
              (it) =>
                (it.name === 'name0' && it.hasError === true) ||
                (it.name === 'sites0' && it.hasError === true) ||
                (it.name === 'name1' && it.hasError === true) ||
                (it.name === 'sites1' && it.hasError === true) ||
                (it.name === 'name2' && it.hasError === true) ||
                (it.name === 'sites2' && it.hasError === true)
            ),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <CardProcessingRule
            binRange={binRange}
            validateCounter={validateCounterFlag}
            cardProcessingRules={binRange?.cardProcessingRules}
            removeValidation={removeValidation}
            siteListName={siteListName}
            readOnly={!isUpdatingForm}
            onBindingValue={handleNewCardprocessingRuleValue}
            isSelectedSites={isSelectedSites}
            cardType={binRange?.cardType}
            action={action}
            hasSiteReadAccess={hasSiteReadAccess}
            hasSiteCreateAccess={hasSiteCreateAccess}
            isSaveButtonEnabled={isSaveButtonEnabled}
            setIsPageDirty={setIsPageDirty}
          ></CardProcessingRule>
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Card Tags</span>
        </div>
      ),
      tabError: binRange?.cardTags?.length > 5,
      tabFormCompleted:
        binRange?.cardTags?.length > 0 && binRange?.cardTags?.every((item) => item.key.trim().length > 0),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          {isUpdatingForm && (
            <Alert className='card-tag-info-container' variant='outlined' severity='info'>
              <AlertTitle>Info</AlertTitle>
              Card Tags are used for adding custom details to the cards and they are case sensitive.
            </Alert>
          )}
          {!isUpdatingForm && !binRange?.cardTags && <div className='no-card-tags-found-text'>No card tags found</div>}
          {binRange?.cardTags?.map((it, i) => {
            return (
              <div className={!isUpdatingForm ? 'bin-range-form-readonly-tag' : 'bin-range-form-tag'} key={i}>
                <CustomTextField
                  key={`tag${i}`}
                  placeholder={'Enter Tag Name'}
                  onBindingValue={(newvalue: KeyValuePair) => {
                    handleNewTagValue(newvalue, i);
                    onTextChangeHandler;
                  }}
                  type='input'
                  name={`tag${i}`}
                  value={it.key}
                  validateCounter={validateCounterFlag}
                  maxCharLength={50}
                  readOnly={!isUpdatingForm}
                ></CustomTextField>

                {isUpdatingForm && (
                  <div className='remove-header-container'>
                    <CustomButton buttonStyle={ButtonStyle.DELETE} onClick={() => handleRemoveTagClick(i)}>
                      x
                    </CustomButton>
                  </div>
                )}
              </div>
            );
          })}

          {isUpdatingForm && (!binRange?.cardTags || binRange?.cardTags?.length < 5) && (
            <div className='add-header-container'>
              <Link className='add-header' underline='hover' onClick={handleAddTagClick}>
                + Add Card Tag
              </Link>
            </div>
          )}
        </div>
      ),
    },
  ] as TabListModel[];

  const currentTabWizardChangeHandler = (data: number) => {
    if (data <= 1) {
      setSecondaryButtonMoreOptions([]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data === 2) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data >= 3) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([]);
    }
  };

  const wizardListChangeHandler = () => {
    if (fieldValidations && fieldValidations?.length > 0) setValidateCounterFlag((prev) => ++prev);
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        formDataloading={loading}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/bin-ranges`}
        isClickFromViewPage={isClickFromViewPage}
        tabWizardList={tabWizardList}
        onCurrentTabWizardChange={currentTabWizardChangeHandler}
        wizardListChange={wizardListChangeHandler}
        primaryButtonMoreOptions={primaryButtonMoreOptions}
        secondaryButtonMoreOptions={secondaryButtonMoreOptions}
      >
        <></>
      </Form>
    </>
  );
};

export default BinRange;
