import { TankStatusHistoryQuery } from '../../entities/tankHistory';
import api, { setAuthorize } from '../../utilities/api';
import { convertObjectToQueryString } from '../../utilities/querystring-helper';

const TANK_API_PREFIX = 'tank-management';

export const getTankStatusHistories = async (request: TankStatusHistoryQuery, continuationToken?: string) => {
  const qsRequest = convertObjectToQueryString(request);

  await setAuthorize();
  let result = await api.get(
    `/${TANK_API_PREFIX}/tanks/${request.tankId}/status?${qsRequest}` +
      (continuationToken ? `&continuationToken=${continuationToken}` : '')
  );
  return result.data;
};
