import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import BoxItem from '../../components/box-items/box-item';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { ProductInfoModel } from '../../models/productModel';
import './styles/product-item.scss';

interface ProductItemProps {
  id: string;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  productItem: ProductInfoModel;
  onEdit: (id: string) => void;
  onDelete: (id: string, name: string) => void;
  onClick: (id: string) => void;
}

const ProductItem: React.FC<ProductItemProps> = (props: ProductItemProps) => {
  const { id, hasDeleteAccess, hasUpdateAccess, productItem, onEdit, onDelete, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit Product',
      handler: () => {
        onEdit(id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete Product',
      handler: () => {
        onDelete(id, productItem.name);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <React.Fragment>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='product-item-card' onClick={onItemClick}>
          <BoxItem className='product-name'>{productItem.name}</BoxItem>
          <BoxItem className='product-codes'>{productItem.internalCode}</BoxItem>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu buttonNode={<MoreVertIcon />} items={items} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ProductItem;
