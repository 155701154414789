import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import './loading-display.scss';

interface LoadingDisplayProps {
  className?: string;
}

const LoadingDisplay: React.FC<LoadingDisplayProps> = (
  props: LoadingDisplayProps
) => {
  const { className } = props;

  return (
    <div className={classNames('loading-box-card')}>
      <CircularProgress className={className} color='inherit' /> 
    </div>
  );
};

export default LoadingDisplay;
