import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectDialogBoxState = (state: RootState) => state.DialogBoxState;

export const selectDialogBoxActionState= createSelector(
    selectDialogBoxState,
    state => state.actionState
);

export const selectDialogBoxVisibleState = createSelector(
    selectDialogBoxState,
    state => state.isVisible
);

