import { OrganisationItem } from '../../entities/organisation';
import api, { setAuthorize } from '../../utilities/api';

const ORG_API_PREFIX = 'organisation-management';

export const getAllOrganisationsData = async () => {
  await setAuthorize();
  let result = await api.get(`/${ORG_API_PREFIX}/organisations`);
  return result.data;
};

export const getOrganisationData = async (id: string) => {
  await setAuthorize();
  let result = await api.get(`/${ORG_API_PREFIX}/organisations/${id}`);
  return result.data;
};

export const createOrganisation = async (item: OrganisationItem) => {
  await setAuthorize();
  await api.post(`/${ORG_API_PREFIX}/organisations`, item);
};

export const editOrganisationData = async (
  item: OrganisationItem,
  id: string
) => {
  await setAuthorize();
  await api.put(`/${ORG_API_PREFIX}/organisations/${id}`, item);
};

export const deleteOrgsData = async (id: string) => {
    await setAuthorize();
    await api.delete(`/${ORG_API_PREFIX}/organisations/${id}`); 
};
