import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid } from '@mui/material';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { ActivityLogFilterModel, ActivityLogModel } from '../../models/activityLogModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { ModalType } from '../../constants/modal-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { formatCamelCaseString } from '../../utilities/fieldMapping-helper';
import { getBrowserZoomLevel } from '../../utilities/general-helper';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import CustomButton from '../../components/button/custom-button';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Item from '../../components/box-items/box-item';
import ActivityLogItem from './activity-log-item';
import ActivityLogFilter from './child-modal/filter-container';
import './styles/activity-log-list.scss';

interface activityLogsProps {
  activityLogStatus: string;
  activityLogContent: ActivityLogModel[];
  activityLogResourceTypes: string[];
  authStatus: string;
  isPageReachBottom: boolean;
  userAccess: (moduleName: string) => AuthorisationModel;
  initLoadActivityLogs: (filer?: ActivityLogFilterModel) => void;
  loadActivityLogs: (filer: ActivityLogFilterModel) => void;
  loadActivityLogResourceTypes: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  setIsScrollRequest: (data: boolean) => void;
  openModal: (data: ModalStateModel) => void;
}

const ActivityLogs: React.FC<activityLogsProps> = (props: activityLogsProps) => {
  const {
    activityLogStatus,
    activityLogContent,
    activityLogResourceTypes,
    authStatus,
    isPageReachBottom,
    userAccess,
    initLoadActivityLogs,
    loadActivityLogs,
    loadActivityLogResourceTypes,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    setIsScrollRequest,
    openModal,
  } = props;

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  const [activityLogFilter, setActivityLogFilter] = useState({} as ActivityLogFilterModel);
  const [displayResourceFilter, setDisplayResourceFilter] = useState('Default');
  const [displayTimeFilter, setDisplayTimeFilter] = useState('Default');
  const [resourceFilterItems, setResourceFilterItems] = useState([] as FloatingMenuItem[]);
  const [isInitLoad, setIsInitLoad] = useState(false);
  const [isFilterUpdated, isetIsFilterUpdated] = useState(false);
  const [activityLogList, setActivityLogList] = useState([] as any);
  const isInitLoadActivityLogsRequested = useRef(false);

  useEffect(() => {
    let newResourceFilter = [] as FloatingMenuItem[];
    activityLogResourceTypes?.map((it) =>
      newResourceFilter.push({
        label: formatCamelCaseString(it),
        isSelected: it === displayResourceFilter,
        handler: () => {
          isetIsFilterUpdated(true);
          setDisplayResourceFilter(it);
        },
      })
    );
    setResourceFilterItems(newResourceFilter);
  }, [activityLogResourceTypes, displayResourceFilter, setResourceFilterItems]);

  const displayTimeList = [
    'Today',
    'Yesterday',
    'Last 7 Days',
    'Last 30 Days',
    'Last 3 Months',
    'Last 6 Months',
    'Custom',
  ];

  let timeFilterItems: FloatingMenuItem[] = [];
  displayTimeList?.map((it) =>
    timeFilterItems.push({
      label: it,
      isSelected: it === displayTimeFilter,
      handler: () => {
        if (it === 'Custom') {
          openModal({
            type: ModalType.SEARCH,
            dataId: '',
          } as ModalStateModel);
        }
        isetIsFilterUpdated(true);
        setDisplayTimeFilter(it);
      },
    })
  );

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ACTIVITY_LOG).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(activityLogStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(activityLogStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(activityLogStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, activityLogStatus]);

  useEffect(() => {
    if (!isInitLoadActivityLogsRequested.current && authSuccess && hasReadAccess) {
      const browserZoomLevel = getBrowserZoomLevel();
      initLoadActivityLogs({
        limit: browserZoomLevel < 0.8 ? Math.trunc(50 / browserZoomLevel) : undefined,
      } as ActivityLogFilterModel);
      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          limit: Math.trunc(50 / browserZoomLevel),
        };
      });
      setIsInitLoad(true);
      loadActivityLogResourceTypes();
      isInitLoadActivityLogsRequested.current = true;
    }
  }, [initLoadActivityLogs, loadActivityLogResourceTypes, setIsInitLoad, authSuccess, hasReadAccess]);

  // Adds a resize event listener to the window. When the window size changes, the event listener reloads the activity logs
  useEffect(() => {
    window.addEventListener('resize', browserResizeHandler);
    return () => window.removeEventListener('resize', browserResizeHandler);
  }, []);

  const browserResizeHandler = () => {
    const browserZoomLevel = getBrowserZoomLevel();
    if (browserZoomLevel < 0.8) {
      isetIsFilterUpdated(true);
      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          limit: Math.trunc(50 / browserZoomLevel),
        };
      });
    }
  };

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Activity Logs',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      pageURL: 'activity-logs',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    setIsScrollRequest(true);
    if (isPageReachBottom) {
      // when activity log list is still loading, do not trigger another load request
      if (activityLogStatus === LoadingStatus.LOADING) return;
      setIsInitLoad(false);
      loadActivityLogs(activityLogFilter);
    }
  }, [isPageReachBottom, activityLogFilter, loadActivityLogs, setIsScrollRequest]);

  useEffect(() => {
    setActivityLogFilter((prevstate) => {
      return {
        ...prevstate,
        resourceType: displayResourceFilter,
      };
    });
  }, [displayResourceFilter]);

  useEffect(() => {
    const currentDate = new Date();

    if (displayTimeFilter === 'Default') {
      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'Default',
        };
      });
    }

    if (displayTimeFilter === 'Today') {
      const todayStart = new Date(currentDate);
      todayStart.setHours(0, 0, 0, 0);

      const todayEnd = new Date(currentDate);
      todayEnd.setHours(23, 59, 59, 999);

      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'today',
          startDateTime: String(todayStart),
          endDateTime: String(todayEnd),
        };
      });
    }

    if (displayTimeFilter === 'Yesterday') {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);

      const yesterdayStart = new Date(yesterday);
      yesterdayStart.setHours(0, 0, 0, 0);

      const yesterdayEnd = new Date(yesterday);
      yesterdayEnd.setHours(23, 59, 59, 999);

      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'yesterday',
          startDateTime: String(yesterdayStart),
          endDateTime: String(yesterdayEnd),
        };
      });
    }

    if (displayTimeFilter === 'Last 7 Days') {
      const last7DaysStart = new Date(currentDate);
      last7DaysStart.setDate(currentDate.getDate() - 6);

      const last7DaysEnd = new Date(currentDate);
      last7DaysEnd.setHours(23, 59, 59, 999);

      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last7Days',
          startDateTime: String(last7DaysStart),
          endDateTime: String(last7DaysEnd),
        };
      });
    }

    if (displayTimeFilter === 'Last 30 Days') {
      const last30DaysStart = new Date(currentDate);
      last30DaysStart.setDate(currentDate.getDate() - 29);

      const last30DaysEnd = new Date(currentDate);
      last30DaysEnd.setHours(23, 59, 59, 999);

      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last30Days',
          startDateTime: String(last30DaysStart),
          endDateTime: String(last30DaysEnd),
        };
      });
    }

    if (displayTimeFilter === 'Last 3 Months') {
      const last3MonthsStart = new Date(currentDate);
      last3MonthsStart.setMonth(currentDate.getMonth() - 3);

      const last3MonthsEnd = new Date(currentDate);
      last3MonthsEnd.setMonth(currentDate.getMonth() + 1, 0);

      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last3Months',
          startDateTime: String(last3MonthsStart),
          endDateTime: String(last3MonthsEnd),
        };
      });
    }

    if (displayTimeFilter === 'Last 6 Months') {
      const last6MonthsStart = new Date(currentDate);
      last6MonthsStart.setMonth(currentDate.getMonth() - 6);

      const last6MonthsEnd = new Date(currentDate);
      last6MonthsEnd.setMonth(currentDate.getMonth() + 1, 0);

      setActivityLogFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'last6Months',
          startDateTime: String(last6MonthsStart),
          endDateTime: String(last6MonthsEnd),
        };
      });
    }
  }, [displayTimeFilter]);

  useEffect(() => {
    if (isFilterUpdated) {
      initLoadActivityLogs(activityLogFilter);
      setIsInitLoad(true);
    }
  }, [activityLogFilter, isFilterUpdated, initLoadActivityLogs]);

  useEffect(() => {
    if (activityLogContent && activityLogContent.length > 0 && (isInitLoad ? isInitLoad && !loading : true)) {
      const activityLogList = activityLogContent?.map((item) => (
        <ActivityLogItem key={item.id} item={item}></ActivityLogItem>
      ));
      setActivityLogList(activityLogList);
    } else {
      setActivityLogList([]);
    }
  }, [activityLogContent, isInitLoad]);

  const saveActivityLogFilterHandler = (newFilter: ActivityLogFilterModel) => {
    setActivityLogFilter((prevstate) => {
      return {
        ...prevstate,
        dateRange: 'Custom',
        startDateTime: newFilter.startDateTime,
        endDateTime: newFilter.endDateTime,
      };
    });
  };

  const Modal = useMemo(() => {
    return (
      <ActivityLogFilter
        activityLogFilterSetting={activityLogFilter}
        saveActivityLogFilter={saveActivityLogFilterHandler}
      />
    );
  }, [activityLogFilter]);

  const clearSearchHandler = (filterName: string) => {
    if (filterName === 'Resource') {
      setDisplayResourceFilter('Default');
    }
    if (filterName === 'Date Range') {
      setDisplayTimeFilter('Default');
    }
  };

  return (
    <>
      {<>{Modal}</>}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {
        <div className={classNames('activity-log-list')}>
          {!error && (
            <div className='filter-list'>
              {resourceFilterItems && resourceFilterItems?.length > 0 && (
                <FloatingMenu
                  buttonNode={
                    <CustomButton
                      className='toolbar-button'
                      buttonStyle={ButtonStyle.TOOLBAR_FILTER}
                      icon={<ArrowDropDownIcon />}
                    >
                      {displayResourceFilter === 'Default' ? 'Resource' : formatCamelCaseString(displayResourceFilter)}
                    </CustomButton>
                  }
                  items={resourceFilterItems}
                  isSelectDropdown={true}
                  searchFromList={true}
                  filterName='Resource'
                  clearSearch={clearSearchHandler}
                />
              )}

              <FloatingMenu
                buttonNode={
                  <CustomButton
                    className='toolbar-button'
                    buttonStyle={ButtonStyle.TOOLBAR_FILTER}
                    icon={<ArrowDropDownIcon />}
                  >
                    {displayTimeFilter === 'Default' ? 'Date Range' : displayTimeFilter}
                  </CustomButton>
                }
                items={timeFilterItems}
                isSelectDropdown={true}
                filterName='Date Range'
                clearSearch={clearSearchHandler}
              />
            </div>
          )}

          {!error && (
            <Grid container className={classNames('activity-log-header-container')} alignItems='center'>
              <Grid item xs={11} sm={11} rowGap={2}>
                <Grid container alignItems='flex-start'>
                  <Grid item xs={12} sm={4}>
                    <Item className={classNames('activity-log-header-field')}>Activity Time</Item>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Item className={classNames('activity-log-header-field')}>Details</Item>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Item className={classNames('activity-log-header-field')}>User</Item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {activityLogContent && activityLogContent?.length > 0 && (
            <div className='product-item-list-container'>
              <div className='product-list'>{activityLogList}</div>
            </div>
          )}

          {!(activityLogContent && activityLogContent?.length > 0) && !loading && success && (
            <div className='empty-div-container'>
              <EmptyList message={'No activity logs found'}></EmptyList>
            </div>
          )}
        </div>
      }
      {(!authCheckCompleted || loading) && <LoadingDisplay />}

      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!activityLogContent && activityLogContent.length < 1 ? 'footer-no-content' : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default ActivityLogs;
