import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData } from '../../../store/modals/selectors';
import * as modalActions from '../../../store/modals/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import ExportColumnsModal from './export-columns-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const ExportColumnsModalContainer = connect(mapStateToProps, mapDispatchToProps)(ExportColumnsModal);

export default ExportColumnsModalContainer;
