import Chip from '@mui/material/Chip';
import './tag.scss';

interface TagProps {
  id: string;
  label: string;
}

const Tag = (props: TagProps) => {
  const { id, label } = props;

  return (
    <div className='tag-container'>
      <Chip className='tag-item' id={id} label={label} size='small'></Chip>
    </div>
  );
};

export default Tag;
