import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericErrorModel } from "../../models/baseModels/genericErrorModel";
import { initialState } from "./genericErrorInitState";

const genericErrorSlice = createSlice({
    name: 'genericErrorState',
    initialState,
    reducers: {
        setGenericErrorData: (state, action: PayloadAction<GenericErrorModel>) => {
            state.data = action.payload;
        }
    }
});

export const {
    setGenericErrorData,
} = genericErrorSlice.actions;

export default genericErrorSlice.reducer;