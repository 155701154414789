import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface CancelOperationIconProps{
    className?:string
}

const CancelOperationIcon = (props: CancelOperationIconProps) => {
    return (
        <SvgIcon className={`base-icon cancel-operation-icon ${props.className}`} />
    );
};

export default CancelOperationIcon;