import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { DATETIME_FORMAT } from '../../../constants/dateTimePicker-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { productUnitOfMeasure } from '../../../constants/dropdown-constants';
import { convertDateToString } from '../../../utilities/datetime-helper';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { NewTankDeliveryModel } from '../../../models/tankDeliveryModel';
import { TankModel } from '../../../models/tankModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import DateTimePickerComponent from '../../../components/datetimepicker/datetimepicker.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomTextFieldWithDropdown from '../../../components/text-field-with-dropdown/text-field-with-dropdowncontainer';
import DialogBoxComponent from '../../../components/dialog-box/dialog-box.container';

interface TankPageProps {
  pageTitle?: string;
  action?: string;
  hasValidationError: boolean;
  backDropActionStatus: string;
  tankList: TankModel[];
  createTankDelivery: (data: NewTankDeliveryModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
}

const TankDeliveryForm: React.FC<TankPageProps> = (props: TankPageProps) => {
  const {
    pageTitle,
    action,
    hasValidationError,
    backDropActionStatus,
    tankList,
    createTankDelivery,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    openDialogBox,
    closeDialogBox,
  } = props;

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isAdjuestmentForm, setIsAdjuestmentForm] = useState(false);
  const [tankDeliveryInfoValue, setTankDeliveryInfoValue] = useState({} as NewTankDeliveryModel);
  const [currentTankVolume, setCurrentTankVolume] = useState(0);
  const [currentTankCapacity, setCurrentTankCapacity] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, siteId, tankId } = useParams();

  useEffect(() => {
    const isAdjuestmentForm = location?.pathname?.includes('/add-adjustment');
    setIsAdjuestmentForm(isAdjuestmentForm);
    setTankDeliveryInfoValue({
      dateTime: convertDateToString(new Date()),
      deliveredUnitOfMeasure: productUnitOfMeasure[0]?.key,
      desiredUnitOfMeasure: productUnitOfMeasure[0]?.key,
    } as NewTankDeliveryModel);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    setIsSaveButtonEnabled(true);
    if (tankList?.find((it) => it?.id === tankId)) {
      setCurrentTankVolume(tankList?.find((it) => it?.id === tankId)?.product?.volume?.value ?? 0);
      setCurrentTankCapacity(tankList?.find((it) => it?.id === tankId)?.capacity?.value ?? 0);
    }
  }, [action, tankId, setIsPageDirty, removeAllValidation]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      showSiteHeader: true,
      //error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/tanks/${tankId}/deliveries`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useEffect(() => {
    setCurrentTankVolume(tankList?.find((it) => it?.id === tankId)?.product?.volume?.value ?? 0);
  }, []);

  useEffect(() => {
    setCurrentTankVolume(tankList?.find((it) => it?.id === tankId)?.product?.volume?.value ?? 0);
  }, [tankList]);

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError && tankId) {
      setTankDeliveryInfoValue((prevstate) => {
        return {
          ...prevstate,
          tankId: tankId,
          type: isAdjuestmentForm ? 'Adjustment' : 'Delivery',
          currentLevel: currentTankVolume,
        };
      });
      openDialogBox();
    }
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleDateTimeChange = useCallback(
    (newValue: string) => {
      setTankDeliveryInfoValue((prevstate) => {
        return {
          ...prevstate,
          dateTime: newValue,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onTextChangeHandler = useCallback(
    (newValue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setTankDeliveryInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newValue.key]: newValue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    createTankDelivery(tankDeliveryInfoValue);
    setValidateCounterFlag(0);
    closeDialogBox();
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  return (
    <>
      {
        <DialogBoxComponent
          context={`
            <div classname='dialog-overview' style="display: flex; flex-direction: column">  
<span classname='dialog-overview-text' style="display: flex"> Current Tank Level:&nbsp; <b>${currentTankVolume}</b> L </span> <br /> 
<span classname='dialog-overview-text' style="display: flex"> ${isAdjuestmentForm ? 'Adjustment Level' : 'Delivery Volume'}:&nbsp;  <b>${isAdjuestmentForm ? tankDeliveryInfoValue?.desiredLevel - currentTankVolume : tankDeliveryInfoValue?.deliveredVolume}</b> L  </span> <br />
<span classname='dialog-overview-text' style="display: flex"> Tank Level After ${isAdjuestmentForm ? 'Adjustment' : 'Delivery'}:&nbsp; <b>${
            isAdjuestmentForm
              ? tankDeliveryInfoValue?.desiredLevel
                ? tankDeliveryInfoValue?.desiredLevel
                : ''
              : tankDeliveryInfoValue?.deliveredVolume
                ? `${+tankDeliveryInfoValue?.deliveredVolume + +currentTankVolume}`
                : ''
          }</b> L </span><br /><br />
 <span classname='dialog-overview-text' style="display: flex; font-size: 16px; text-align: left;">  Are you sure you want to add this ${isAdjuestmentForm ? 'adjustment' : 'delivery'}? Once this ${isAdjuestmentForm ? 'adjustment' : 'delivery'} is added, it cannot be edited and no further deliveries or adjustments can be made before this ${isAdjuestmentForm ? 'adjustment' : 'delivery'}. </span>
  <div/>
`}
          isHtmlContext={true}
          closeTextButton='Cancel'
          confirmTextButton='Confirm'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header={`Add Tank ${isAdjuestmentForm ? 'Adjustment' : 'Delivery'}`}
        />
      }
      <Form
        displayLoadingIndicator={false}
        displayErrorDetails={false}
        displayNoAccessMessage={false}
        displayForm={true}
        isSaveButtonEnabled={true}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        listURL={`/organisations/${orgId}/sites/${siteId}/tanks/${tankId}/deliveries`}
      >
        <DateTimePickerComponent
          label='Date Time'
          dateFormat={DATETIME_FORMAT.DATE_ONLY}
          dateField='Date'
          timeField='Time'
          dateTimeValue={tankDeliveryInfoValue?.dateTime}
          handleDateTimeChange={handleDateTimeChange}
          disableFuture={false}
          isMandatory={true}
          validateCounter={validateCounterFlag}
          disabledFutureDates={new Date(new Date().setHours(23, 59, 59, 999))}
        />
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={false}
          key='currentLevel'
          label='Current Level'
          placeholder=''
          name='currentLevel'
          value={currentTankVolume + ' L'}
          type='input'
          readOnly={true}
        ></CustomTextField>
        {!isAdjuestmentForm && (
          <CustomTextFieldWithDropdown
            validateCounterFlag={validateCounterFlag}
            isMandatory={true}
            label='Delivered Volume'
            fieldKey='deliveredVolume'
            fieldPlaceHolder='Enter Delivered Volume'
            filedName='deliveredVolume'
            fieldValue={tankDeliveryInfoValue?.deliveredVolume}
            fieldType={TextFieldType.DECIMAL}
            fieldMinRange={0}
            fieldMaxRange={currentTankCapacity}
            onFiledChangeHandler={onTextChangeHandler}
            dropdownKey='unitOfMeasure'
            dropdownName='unitOfMeasure'
            dropdownValue={
              tankDeliveryInfoValue?.deliveredUnitOfMeasure
                ? tankDeliveryInfoValue?.deliveredUnitOfMeasure
                : productUnitOfMeasure[0]?.key
            }
            dropdownKeyValuePair={productUnitOfMeasure}
            onDropDownChangeHandler={onTextChangeHandler}
          />
        )}

        {!isAdjuestmentForm && (
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={false}
            key='levelAfterDelivery'
            label='Level After Delivery'
            placeholder=''
            name='levelAfterDelivery'
            value={
              tankDeliveryInfoValue?.deliveredVolume
                ? `${+tankDeliveryInfoValue?.deliveredVolume + +currentTankVolume} L`
                : ''
            }
            type='input'
            readOnly={true}
          ></CustomTextField>
        )}

        {isAdjuestmentForm && (
          <CustomTextFieldWithDropdown
            validateCounterFlag={validateCounterFlag}
            isMandatory={true}
            label='Desired Level'
            fieldKey='desiredLevel'
            fieldPlaceHolder='Enter Desired Level'
            filedName='desiredLevel'
            fieldValue={tankDeliveryInfoValue?.desiredLevel}
            fieldType={TextFieldType.DECIMAL}
            fieldMinRange={0}
            fieldMaxRange={currentTankCapacity}
            onFiledChangeHandler={onTextChangeHandler}
            dropdownKey='unitOfMeasure'
            dropdownName='unitOfMeasure'
            dropdownValue={
              tankDeliveryInfoValue?.desiredUnitOfMeasure
                ? tankDeliveryInfoValue?.desiredUnitOfMeasure
                : productUnitOfMeasure[0]?.key
            }
            dropdownKeyValuePair={productUnitOfMeasure}
            onDropDownChangeHandler={onTextChangeHandler}
          />
        )}

        {isAdjuestmentForm && (
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={false}
            key='adjustment'
            label='Adjustment'
            placeholder=''
            name='adjustment'
            value={
              tankDeliveryInfoValue?.desiredLevel ? tankDeliveryInfoValue?.desiredLevel - currentTankVolume + ' L' : ''
            }
            type='input'
            readOnly={true}
          ></CustomTextField>
        )}
      </Form>
    </>
  );
};

export default TankDeliveryForm;
