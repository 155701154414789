import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { HoseDetail } from '../../../models/pumpModel';
import { TextFieldType } from '../../../constants/textfield-constants';
import { DEFAULT_DROP_DOWN_VALUE } from '../../../constants/dropdown-constants';
import { PUMPS_MAX_HOSES } from '../../../constants/pumps-constants';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import Item from '../../../components/box-items/box-item';
import '../styles/hoses.scss';

interface PumpHosesProps {
  validateCounter: number;
  hoses: HoseDetail[];
  tankNameList: KeyValuePair[];
  readOnly?: boolean;
  onBindingValue?: (newvalue: HoseDetail[]) => void | undefined;
  removeValidation: (name: string) => void;
}

const PumpHoses: React.FC<PumpHosesProps> = (props: PumpHosesProps) => {
  const { hoses, validateCounter, tankNameList, readOnly, onBindingValue, removeValidation } = props;

  const [hosesValue, setHosesValue] = useState(hoses);
  const [validationFlag, setValidationFlag] = useState(0);
  const [focusHoseNumber, setFocusHoseNumber] = useState(false);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setHosesValue(hoses);
  }, [hoses, setHosesValue]);

  // handle click event of the Add button
  const handleAddClick = () => {
    setValidationFlag(0);
    let hoseItem = {} as HoseDetail;
    let list = [hoseItem];
    if (hosesValue !== undefined) {
      list = [...hosesValue];
      list.push(hoseItem);
    }
    setFocusHoseNumber(true);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetHoseListValidation();

    let list = [...hosesValue];
    list.splice(index, 1);

    setHosesValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetHoseListValidation = () => {
    hosesValue.forEach((_, index) => {
      removeValidation(`number${index}`);
      removeValidation(`tankId${index}`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let hoseItems = [...hosesValue];
    if (!!hoseItems[index]) {
      let item = { ...hoseItems[index] };

      if (newvalue.key.toString().includes('number')) {
        item.number = !!newvalue.value ? Number(newvalue.value) : undefined;
      } else if (newvalue.key.toString().includes('tankId')) {
        item.tankId = newvalue.value.toString();
      }

      hoseItems[index] = item;
    }
    setHosesValue(hoseItems);

    if (onBindingValue) {
      onBindingValue(hoseItems);
    }
  };

  return (
    <>
      <div className='hoses-header'> Hoses </div>
      {hosesValue?.length < 1 && readOnly && <div>No hoses found</div>}
      {!!hosesValue &&
        hosesValue?.map((item, i) => {
          return (
            <Item key={i} className='hoses-box'>
              {!readOnly && hosesValue.length > 1 && (
                <Link className='remove-hoses' underline='hover' onClick={() => handleRemoveClick(i)}>
                  - Remove Hose
                </Link>
              )}
              {
                <div className='hose-form'>
                  <CustomTextField
                    key={`number${i}`}
                    label={'Hose Number'}
                    placeholder={'Enter Hose Number'}
                    onBindingValue={(newvalue: KeyValuePair) => {
                      handleNewValue(newvalue, i);
                    }}
                    type={TextFieldType.INTEGER}
                    name={`number${i}`}
                    value={item?.number ? item?.number : ''}
                    autoFocus={focusHoseNumber}
                    validateCounter={validationFlag}
                    isMandatory={true}
                    maxRange={5}
                    minRange={1}
                    readOnly={readOnly}
                  ></CustomTextField>

                  <DropDown
                    key={`tankId${i}`}
                    name={`tankId${i}`}
                    value={!!item?.tankId ? item?.tankId : DEFAULT_DROP_DOWN_VALUE}
                    onBindingValue={(newvalue: KeyValuePair) => {
                      handleNewValue(newvalue, i);
                    }}
                    label={'Tank'}
                    keyValuePair={tankNameList}
                    noOptionText='No Tanks Available'
                    isMandatory={true}
                    validateCounter={validationFlag}
                    readOnly={readOnly}
                  />
                </div>
              }
            </Item>
          );
        })}
      {!readOnly && (!hosesValue || hosesValue?.length < PUMPS_MAX_HOSES) && (
        <Link className='add-hoses' underline='hover' onClick={handleAddClick}>
          + Add Hose
        </Link>
      )}
    </>
  );
};

export default PumpHoses;
