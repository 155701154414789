import { HealthStatus, PumpStatus, BulkOperationState, TankStatus, PriceSignStatus } from '../constants/status';
import { useEffect, useState } from 'react';

/* indicator color palette

unavailable-icon - blue
online-icon - green
disconnected-icon - orange
offline-icon - red
*/

export const getHealthStatusIndicator = (status?: string) => {
  switch (status?.toLowerCase()) {
    case HealthStatus.UNAVAILABLE:
      return 'unavailable-icon';
    case HealthStatus.CONNECTED:
    case HealthStatus.ONLINE:
    case HealthStatus.HEALTHY:
      return 'online-icon';
    case HealthStatus.DISCONNECTED:
    case HealthStatus.DEGRADED:
    case HealthStatus.TERMINAL_DISABLED:
    case HealthStatus.DISABLED:
      return 'disconnected-icon';
    case HealthStatus.OFFLINE:
    case HealthStatus.UNHEALTHY:
      return 'offline-icon';
    default:
      return 'display-none';
  }
};

export const getPumpStatusIndicator = (status?: string) => {
  switch (status?.toLowerCase()) {
    case PumpStatus.UNAVAILABLE:
      return 'unavailable-icon';
    case PumpStatus.CLOSED:
    case PumpStatus.IDLE:
    case PumpStatus.CALLING:
    case PumpStatus.AUTHORISED:
    case PumpStatus.STARTED:
    case PumpStatus.SUSPENDEDSTARTED:
    case PumpStatus.FUELLING:
    case PumpStatus.SUSPENDEDFUELLING:
      return 'online-icon';
    case PumpStatus.INOPERATIVE:
    case PumpStatus.INVALID:
      return 'disconnected-icon';
    case PumpStatus.OFFLINE:
    case PumpStatus.DISABLED:
      return 'offline-icon';
    default:
      return 'display-none';
  }
};

export const getTankStatusIndicator = (status?: string) => {
  switch (status?.toLowerCase()) {
    case TankStatus.UNAVAILABLE:
      return 'unavailable-icon';
    case TankStatus.ONLINE:
    case TankStatus.DELIVERYINPROGRESS:
      return 'online-icon';
    case TankStatus.ERROR:
      return 'disconnected-icon';
    case TankStatus.OFFLINE:
      return 'offline-icon';
    default:
      return 'display-none';
  }
};

export const getPriceSignStatusIndicator = (status?: string) => {
  switch (status?.toLowerCase()) {
    case PriceSignStatus.UNAVAILABLE:
      return 'unavailable-icon';
    case PriceSignStatus.ONLINE:
      return 'online-icon';
    case PriceSignStatus.DEGRADEDCOMMUNICATION:
      return 'disconnected-icon';
    case PriceSignStatus.OFFLINE:
      return 'offline-icon';
    default:
      return 'display-none';
  }
};

export const getBulkStateIndicator = (status?: string) => {
  switch (status?.toLowerCase()) {
    case BulkOperationState.RUNNING:
    case BulkOperationState.COMPLETED:
      return 'completed-running-icon';
    case BulkOperationState.PENDING:
      return 'pending-icon';
    case BulkOperationState.FAILED:
      return 'failure-icon';
  }
};

export const getDefaultStringvalue = (strValue: string) => {
  return !!strValue ? strValue : 'Unspecified';
};

export const findFieldinList = (strValue: string, strList: string[]) => {
  const result = strList.filter((str) => str.toLowerCase() === strValue.toLowerCase());
  return result;
};

export const registrationIdInputHandler = (strValue: string) => {
  return strValue.replace(/[^0-9a-f-]/gi, '');
};

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
