import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { AlertUserGroupModel } from '../../../models/alertUserGroupModel';
import { RootState } from '../../../store/rootReducers';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectAlertUserGroupInfoData, selectAlertUserGroupStatus } from '../../../store/alert-usergroups/selectors';
import { selectUsersContent } from '../../../store/users/selectors';
import * as actions from '../../../store/alert-usergroups/actions';
import * as headerActions from '../../../store/header/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as userActions from '../../../store/users/actions';
import AlertUserGroup from './alert-usergroup';

const mapStateToProps = (state: RootState) => {
  return {
    alertUserGroupItem: selectAlertUserGroupInfoData(state),
    alertUserGroupStatus: selectAlertUserGroupStatus(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    usersContent: selectUsersContent(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadAlertUserGroupInfo: (id?: string) => dispatch({ type: actions.LOAD_ALERT_USER_GROUP_INFO, payload: id }),
    createAlertUserGroup: (item: AlertUserGroupModel) =>
      dispatch({ type: actions.CREATE_ALERT_USER_GROUP, payload: item }),
    editAlertUserGroup: (item: AlertUserGroupModel) =>
      dispatch({ type: actions.EDIT_ALERT_USER_GROUP, payload: item }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    loadUserList: () => dispatch({ type: userActions.LOAD_USERS }),
  };
};

const AlertUserGroupPage = connect(mapStateToProps, mapDispatchToProps)(AlertUserGroup);

export default AlertUserGroupPage;
