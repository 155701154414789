import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface TimerIconProps {
    className?:string
};

const TimerIcon = (props: TimerIconProps) => {
    return (
        <SvgIcon className={`base-icon timer-icon ${props.className}`}/>
    );
};

export default TimerIcon