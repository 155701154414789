import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import classNames from 'classnames';
import './wizard.scss';

interface WizardProps {
  wizardList?: KeyValuePair[];
}

const Wizard: React.FC<WizardProps> = (props: WizardProps) => {
  const { wizardList } = props;

  const navigate = useNavigate();

  function handleClick(url: string) {
    navigate(url);
  }

  const breadcrumbs = wizardList?.map((it, i) => {
    return (
      <div
        className={classNames('wizard-item', {
          'wizard-item-active': i === wizardList.length - 1,
        })}
        key={it.key}
        onClick={() => {
          if (it.additionalValue === 'same') {
          } else handleClick(String(it.value));
        }}
      >
        {it.key}
      </div>
    );
  });

  return (
    <div className='wizard-container'>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
};

export default Wizard;
