import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface EditIconProps {
  className?: string;
}

const EditIcon = (props: EditIconProps) => {
  return <SvgIcon className={`base-icon edit-icon ${props.className}`} />;
};

export default EditIcon;
