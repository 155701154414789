import { PermissionGroupItemsModel } from '../../models/permissionGroupModel';
import { LoadingStatus } from '../../constants/loading-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';


interface PermissionGroupState {
    permissionGroupStatus: string
    permissionGroupContent: PermissionGroupItemsModel[]
    permissionGroupItem: PermissionGroupItemsModel
    hasError: boolean
    permissionGroupNameList: KeyValuePair[]
};

export const initialState: PermissionGroupState = {permissionGroupStatus: LoadingStatus.LOADING } as PermissionGroupState;
