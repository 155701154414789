import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectTankState = (state: RootState) => state.TankState;

export const selectTankData = createSelector(
  selectTankState,
  (state) => state.tankData
);

export const selectTankStatus = createSelector(
  selectTankState,
  (state) => state.tankStatus
);

export const selectTankDetailStatus = createSelector(
  selectTankState,
  (state) => state.tankDetailStatus
);

export const selectTankInfo = createSelector(
  selectTankState,
  (state) => state.tankInfo
);

export const selectTankNameList = createSelector(
  selectTankState,
  (state) => state.tankNameList
);

export const selectTankNumberList = createSelector(
  selectTankState,
  state => state.tankNumberList
)

