import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface FolderProps {
  className?: string;
}

const FolderIcon = (props: FolderProps) => {
  return <SvgIcon className={`color-icon folder-icon ${props.className}`} />;
};

export default FolderIcon;
