import { createSelector } from 'reselect';
import { RootState } from '../rootReducers';

export const selectLocalState = (state: RootState) => state.CardState;

export const selectCardStatus = createSelector(
    selectLocalState,
    state => state.cardStatus
);

export const selectCardList = createSelector(
    selectLocalState,
    state => state.cardList
);

export const selectCardError = createSelector(
    selectLocalState,
    state => state.hasError
);

export const selectCardFuzzySearch = createSelector(
    selectLocalState,
    state => state.cardFuzzySearch
);

export const selectIsScrollRequest = createSelector(
    selectLocalState,
    state => state.isScrollRequest
);

export const selectCardData = createSelector(
    selectLocalState,
    state => state.cardData
);

export const selectCardFilters = createSelector(
    selectLocalState,
    state => state.cardFilter
)
