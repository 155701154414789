import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import NavPaneFloatingMenuControl from './index';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectSelectedSubMenuIndex, selectNavPaneStatus } from '../../../store/nav-pane/selectors';
import * as actions from '../../../store/nav-pane/actions';

const mapStateToProps = (state: RootState) => {
  return {
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
    selectSelectedSubMenuIndex: selectSelectedSubMenuIndex(state),
    navPaneState: selectNavPaneStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSelectedSubMenuIndex: (index: number) =>
      dispatch({ type: actions.SELECT_SUB_MENU_INDEX, payload: index }),
  };
};

const NavPaneFloatingMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavPaneFloatingMenuControl);

export default NavPaneFloatingMenu;
