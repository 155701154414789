import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectAlertState = (state: RootState) => state.AlertState;

export const selectAlertData = createSelector(
    selectAlertState, 
    (state) => state.alertData);

export const selectAlertStatus = createSelector(
    selectAlertState, 
    (state) => state.alertStatus);

export const selectAlertTypes = createSelector(
    selectAlertState, 
    (state) => state.alertTypes);

export const selectAlertFuzzySearch = createSelector(
    selectAlertState, 
    (state) => state.alertFuzzySearch);
