import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  DEFAULT_DROP_DOWN_VALUE,
  operationType,
  fileUploadType,
  offsetUnitOfMeasure,
} from '../../../constants/dropdown-constants';
import { FormActionType } from '../../../constants/form-constants';
import { TerminalOperationType, offsetUnitOfMeasureOptions } from '../../../constants/terminal-operation-constants';
import { ModuleName } from '../../../constants/module-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { ModalType } from '../../../constants/modal-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { TerminalOperationActionModel, TerminalOperationActionPostModel } from '../../../models/terminalOperationModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { TerminalModel } from '../../../models/terminalModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { BrowserIcon } from '../../../components/icons';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import Form from '../../../components/form/form.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomTextFieldWithButton from '../../../components/text-field-with-button/text-field-with-button.container';
import FileExplorerForm from '../child-modal/file-explorer.container';
import CustomTextFieldWithDropdown from '../../../components/text-field-with-dropdown/text-field-with-dropdowncontainer';

interface TerminalOperationPageProps {
  hasValidationError: boolean;
  pageTitle?: string;
  action?: string;
  terminalInfoModel: TerminalModel;
  backDropActionStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  terminalOpeationActions: (data: TerminalOperationActionModel) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
  setSelectedTerminalItem: (data: TerminalModel) => void;
  openModal: (data: ModalStateModel) => void;
}

const TerminalOperation: React.FC<TerminalOperationPageProps> = (props: TerminalOperationPageProps) => {
  const {
    hasValidationError,
    pageTitle,
    action,
    terminalInfoModel,
    backDropActionStatus,
    userAccess,
    terminalOpeationActions,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
    setSelectedTerminalItem,
    openModal,
  } = props;

  const { orgId, siteId, terminalId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [allowSaving, setAllowSaving] = useState(false);
  const [operationInfoValue, setOperationInfoValue] = useState({} as TerminalOperationActionPostModel);
  const [success, setSuccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasOperationFileUpdateAccess, setHasOperationFileUpdateAccess] = useState(false);
  const [hasOperationRestartAccess, setHasOperationRestartAccess] = useState(false);
  const [hasOperationBootloaderRestartAccess, sethasOperationBootloaderRestartAccess] = useState(false);
  const [hasOperationMemoryDumpAccess, setHasOperationMemoryDumpAccess] = useState(false);
  const [hasDirectoryuploadAccess, setHasDirectoryuploadAccess] = useState(false);
  const [availableOperation, setAvailableOperation] = useState([] as KeyValuePair[]);

  /** CHECK ACCESS STATUS */
  useMemo(() => {
    setHasOperationFileUpdateAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasFileUploadAccess);
    setHasOperationRestartAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasRestartAccess);
    sethasOperationBootloaderRestartAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasBootloaderRestartAccess);
    setHasOperationMemoryDumpAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasMemoryDumpAccess);
    setHasDirectoryuploadAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasDirectoryuploadAccess);
  }, [userAccess]);

  useMemo(() => {
    setHasCreateAccess(
      hasOperationFileUpdateAccess ||
        hasOperationRestartAccess ||
        hasOperationBootloaderRestartAccess ||
        hasOperationMemoryDumpAccess
    );
  }, [
    hasOperationBootloaderRestartAccess,
    hasOperationFileUpdateAccess,
    hasOperationMemoryDumpAccess,
    hasOperationRestartAccess,
  ]);

  useEffect(() => {
    setOperationInfoValue({
      organisationId: orgId,
      type:
        type === TerminalOperationType.FILE_UPLOAD && hasOperationFileUpdateAccess
          ? TerminalOperationType.FILE_UPLOAD
          : '',
      uploadType: type === TerminalOperationType.FILE_UPLOAD && hasOperationFileUpdateAccess ? 'create' : '',
    } as TerminalOperationActionPostModel);
    setAllowSaving(hasCreateAccess);
    removeAllValidation();
  }, [action, hasCreateAccess, orgId, siteId, terminalId, removeAllValidation, type, hasOperationFileUpdateAccess]);

  useEffect(() => {
    setSuccess(hasCreateAccess);
  }, [action, hasCreateAccess]);

  useEffect(() => {
    let operationList = operationType.filter(
      (x) =>
        (x.key === 'fileUpload' && hasOperationFileUpdateAccess) ||
        (x.key === 'terminalRestart' && hasOperationRestartAccess) ||
        (x.key === 'bootloaderRestart' && hasOperationBootloaderRestartAccess) ||
        (x.key === 'memoryDump' && hasOperationMemoryDumpAccess)
    );

    setAvailableOperation(operationList);
  }, [
    hasOperationBootloaderRestartAccess,
    hasOperationFileUpdateAccess,
    hasOperationMemoryDumpAccess,
    hasOperationRestartAccess,
  ]);

  useEffect(() => {
    setSelectedTerminalItem({
      id: terminalId,
      organisationId: orgId,
      siteId: siteId,
      number: terminalInfoModel?.number,
    } as TerminalModel);
  }, [orgId, setSelectedTerminalItem, siteId, terminalId]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      pageURL: 'terminal-operation',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: false,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
    setAllowSaving(hasCreateAccess);
  }, [hasCreateAccess, action, setIsPageDirty]);

  useEffect(() => {
    if (action === FormActionType.CREATE && allowSaving) {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [setIsPageDirty, action, allowSaving, siteId, orgId]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/terminals/${terminalId}/operations`);
    }
  }, [backDropActionStatus, orgId, siteId, terminalId, navigate]);

  const setSaveButtonTitle = () => {
    return 'Save';
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      terminalOpeationActions({
        terminalId: terminalId,
        postData: operationInfoValue,
      } as TerminalOperationActionModel);

      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(allowSaving);
      setOperationInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
      if (newvalue.key === 'type' && newvalue.value !== TerminalOperationType.FILE_UPLOAD) {
        removeValidation('path');
        removeValidation('uploadType');
        setOperationInfoValue({
          organisationId: orgId,
          type: newvalue.value,
        } as TerminalOperationActionPostModel);
      } else if (newvalue.key === 'type' && newvalue.value === TerminalOperationType.FILE_UPLOAD) {
        setOperationInfoValue((prevstate) => {
          return {
            ...prevstate,
            uploadType: 'create',
          };
        });
      } else if (newvalue.key === 'uploadType' && newvalue.value !== 'create') {
        setOperationInfoValue((prevstate) => {
          return {
            ...prevstate,
            offset: undefined,
          };
        });
      }
    },
    [setIsPageDirty, allowSaving, removeValidation]
  );

  const onButtonClickHandler = () => {
    openModal({
      type: ModalType.FILE_EXPLORER,
      name: 'directory',
    } as ModalStateModel);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const parsEnums = (value: string | number) => {
    if (value !== undefined) {
      return value.toString();
    } else {
      return DEFAULT_DROP_DOWN_VALUE;
    }
  };

  const newPathHandler = (path: string) => {
    setOperationInfoValue((prevstate) => {
      return {
        ...prevstate,
        path: path,
      };
    });
  };

  const Modal = useMemo(() => {
    return <FileExplorerForm newPathHandler={newPathHandler} />;
  }, []);

  return (
    <React.Fragment>
      <Form
        displayLoadingIndicator={false}
        displayErrorDetails={false}
        displayNoAccessMessage={!hasCreateAccess}
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        saveText={setSaveButtonTitle()}
        enableDisplayPrompt={allowSaving}
        isClickFromViewPage={false}
        listURL={`/organisations/${orgId}/sites/${siteId}/terminals/${terminalId}/operations`}
      >
        {<>{Modal}</>}
        <DropDown
          key='type'
          name='type'
          value={parsEnums(operationInfoValue?.type)}
          onBindingValue={onTextChangeHandler}
          label='Operation Type'
          keyValuePair={availableOperation}
          validateCounter={validateCounterFlag}
          isMandatory={true}
        />
        {operationInfoValue?.type === TerminalOperationType.FILE_UPLOAD && (
          <>
            {hasDirectoryuploadAccess ? (
              <CustomTextFieldWithButton
                key='path'
                filedName='path'
                label='File Path'
                fieldPlaceHolder='Enter File Path'
                fieldType='input'
                onFiledChangeHandler={onTextChangeHandler}
                fieldValue={operationInfoValue?.path ? operationInfoValue?.path : ''}
                validateCounterFlag={validateCounterFlag}
                isMandatory={true}
                fieldMaxCharLength={60}
                buttonText='Browse'
                buttonIcon={<BrowserIcon />}
                onButtonClick={onButtonClickHandler}
              ></CustomTextFieldWithButton>
            ) : (
              <CustomTextField
                key='path'
                name='path'
                label='File Path'
                placeholder='Enter File Path'
                type='input'
                onBindingValue={onTextChangeHandler}
                value={operationInfoValue?.path ? operationInfoValue?.path : ''}
                validateCounter={validateCounterFlag}
                isMandatory={true}
                maxCharLength={60}
              ></CustomTextField>
            )}
            <DropDown
              key='uploadType'
              name='uploadType'
              value={parsEnums(operationInfoValue?.uploadType ? operationInfoValue?.uploadType : 'create')}
              onBindingValue={onTextChangeHandler}
              label='Upload Type'
              keyValuePair={fileUploadType}
              validateCounter={validateCounterFlag}
              isMandatory={true}
              hideDefaultSelect={true}
            />
            {operationInfoValue?.uploadType !== 'create' && (
              <CustomTextField
                key='offset'
                name='offset'
                label='Offset'
                placeholder='Enter Offset'
                type='input'
                onBindingValue={onTextChangeHandler}
                value={operationInfoValue?.offset ? operationInfoValue?.offset : ''}
                validateCounter={validateCounterFlag}
                isMandatory={false}
                maxCharLength={100}
                readOnly={operationInfoValue?.uploadType !== 'create' ? true : false}
                inputTooltip={
                  operationInfoValue?.uploadType !== 'create'
                    ? 'Offset can be only configured for Create upload type.'
                    : ''
                }
              ></CustomTextField>
            )}
            {operationInfoValue?.uploadType === 'create' && (
              <CustomTextFieldWithDropdown
                validateCounterFlag={validateCounterFlag}
                isMandatory={false}
                label='Offset'
                fieldKey='offset'
                fieldPlaceHolder='Enter Offset'
                filedName='offset'
                fieldValue={operationInfoValue?.offset ? operationInfoValue?.offset : ''}
                fieldType={TextFieldType.INTEGER}
                fieldMinRange={1}
                fieldMaxRange={100000000000}
                onFiledChangeHandler={onTextChangeHandler}
                dropdownKey='offsetUnit'
                dropdownName='offsetUnit'
                dropdownValue={
                  operationInfoValue?.offsetUnit ? operationInfoValue?.offsetUnit : offsetUnitOfMeasureOptions?.BYTES
                }
                dropdownKeyValuePair={offsetUnitOfMeasure}
                onDropDownChangeHandler={onTextChangeHandler}
                readOnly={operationInfoValue?.uploadType !== 'create' ? true : false}
              />
            )}
          </>
        )}
      </Form>
    </React.Fragment>
  );
};

export default TerminalOperation;
