import { initialState } from "./snackBarInitState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackBarStateModel } from "../../models/baseModels/snackBarStateModel";
import { SnackBarType } from "../../constants/snackbar-constants";

const snackBarSlice = createSlice({
    name: 'snackBarState',
    initialState,
    reducers:{     
        setSnackBarState: (state, action: PayloadAction<SnackBarStateModel>) => {
            state.data = action.payload;
        },
        setSnackBarSuccess:  (state, action: PayloadAction<string>) => {
            state.data = {
                isOpen: true,
                message: action.payload,
                type: SnackBarType.SUCCESS
            } as SnackBarStateModel
        },
        setSnackBarError:  (state, action: PayloadAction<string>) => {
            state.data = {
                isOpen: true,
                message: action.payload,
                type: SnackBarType.ERROR
            } as SnackBarStateModel
        },
        setSnackBarWarning:  (state, action: PayloadAction<string>) => {
            state.data = {
                isOpen: true,
                message: action.payload,
                type: SnackBarType.WARNING
            } as SnackBarStateModel
        }
    }
});

export const {
    setSnackBarState,
    setSnackBarSuccess,
    setSnackBarError,
    setSnackBarWarning
} = snackBarSlice.actions;

export default snackBarSlice.reducer;