import { ForecourtControllerEntity } from '../../entities/forecourtController';
import api, { setAuthorize } from '../../utilities/api';

const FORECOURT_CONTROLLER_API_PREFIX = 'forecourt-controller-management';

export const getAllForecourtControllerData = async (organisationId: string, siteId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${FORECOURT_CONTROLLER_API_PREFIX}/forecourt-controllers?organisationId=${organisationId}&siteId=${siteId}`
    );
  return result.data;
};

export const getAllForecourtControllerStatuses = async (organisationId: string, siteId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${FORECOURT_CONTROLLER_API_PREFIX}/forecourt-controllers/status?organisationId=${organisationId}&siteId=${siteId}`
    );
  return result.data;
}

export const getForecourtControllerById = async (
    id: string,
    organisationId: string
  ) => {
    await setAuthorize();
    let result = await api.get(
      `/${FORECOURT_CONTROLLER_API_PREFIX}/forecourt-controllers/${id}?organisationId=${organisationId}`
    );
    return result.data;
  };
  
  export const createForecourtController = async (data: ForecourtControllerEntity) => {
    await setAuthorize();
    await api.post(`/${FORECOURT_CONTROLLER_API_PREFIX}/forecourt-controllers`, data);
  };
  
  export const editForecourtController = async (
    data: ForecourtControllerEntity,
    id: string
  ) => {
    await setAuthorize();
    await api.put(
      `/${FORECOURT_CONTROLLER_API_PREFIX}/forecourt-controllers/${id}`,
      data
    );
  };
  
  export const deleteForecourtController = async (
    id: string,
    organisationId: string
  ) => {
    await setAuthorize();
    await api.delete(
      `/${FORECOURT_CONTROLLER_API_PREFIX}/forecourt-controllers/${id}?organisationId=${organisationId}`
    );
  };
  