import { selectNavPaneStatus } from '../../store/nav-pane/selectors';
import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectReceiptTemplateContent, selectReceiptTemplateStatus } from '../../store/receipt-template/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import * as headerActions from '../../store/header/actions';
import * as actions from '../../store/receipt-template/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import ReceiptTemplate from './receipt-templates-list';

const mapStateToProps = (state: RootState) => {
  return {
    receiptTemplateContent: selectReceiptTemplateContent(state),
    receiptTemplateStauts: selectReceiptTemplateStatus(state),
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadReceiptTemplates: () => dispatch({ type: actions.LOAD_RECEIPTTEMPLATES }),
    deleteReceiptTemplate: (id: string) => dispatch({ type: actions.DELETE_RECEIPTTEMPLATE, payload: id }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const ReceiptTemplatePage = connect(mapStateToProps, mapDispatchToProps)(ReceiptTemplate);

export default ReceiptTemplatePage;
