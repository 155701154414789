import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from './rootReducers';
import rootSaga from './rootSagas';
const sagaMiddleware = createSagaMiddleware();
const isDevMode = process.env.NODE_ENV === 'development';

const store = configureStore({
  reducer,
  devTools: isDevMode,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
