import { useEffect, useRef, useState } from 'react';
import { Box, Collapse, Grid, Tooltip } from '@mui/material';
import classNames from 'classnames';
import Item from '../../components/box-items/box-item';
import CustomButton from '../../components/button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import { DoubleArrowDownIcon, DoubleArrowUpIcon } from '../../components/icons';
import { AlertModel } from '../../models/alertModel';
import { checkIsTextEllipsis } from '../../utilities/general-helper';
import * as datetimehelper from '../../utilities/datetime-helper';
import './styles/alert-item.scss';

interface AlertItemProps {
  item: AlertModel;
}

const AlertItem: React.FC<AlertItemProps> = (props: AlertItemProps) => {
  const { item } = props;
  const [isEventitemSiteEllipsis, setIsEventitemSiteEllipsis] = useState<boolean>(false);
  const [isEventItemTypeEllipsis, setIsEventItemTypeEllipsis] = useState<boolean>(false);
  const EventItemSiteText = useRef<HTMLDivElement | null>(null);
  const EventItemTypeText = useRef<HTMLDivElement | null>(null);
  const [showDetails, setShowDetails] = useState(false);

  const updateTooltipDisplay = () => {
    if (checkIsTextEllipsis(EventItemTypeText.current)) {
      setIsEventItemTypeEllipsis(true);
    } else {
      setIsEventItemTypeEllipsis(false);
    }

    if (checkIsTextEllipsis(EventItemSiteText.current)) {
      setIsEventitemSiteEllipsis(true);
    } else {
      setIsEventitemSiteEllipsis(false);
    }
  };

  useEffect(() => {
    updateTooltipDisplay();
    window.addEventListener('resize', updateTooltipDisplay);
    return () => window.removeEventListener('resize', updateTooltipDisplay);
  }, []);

  const showDetailHandler = () => {
    setShowDetails((prev) => !prev);
  };

  const timezone = datetimehelper.getBrowserLocalTimezone();

  const NotifyDetailSection = () => {
    return (
      <Box className='notify-detail-container'>
        {item?.notifiedEmailList && item?.notifiedEmailList?.length > 0 && (
          <Box>
            <Item className={classNames('notify-detail-header')}> Email Sent To:</Item>
            <Item className={classNames('notify-detail-list')}>
              {item?.notifiedEmailList?.map((email) => email).join(', ')}
            </Item>
          </Box>
        )}

        {item?.notifiedMobileList && item?.notifiedMobileList?.length > 0 && (
          <Box>
            <Item className={classNames('notify-detail-header')}> SMS Sent To:</Item>
            <Item className={classNames('notify-detail-list')}>
              {item?.notifiedMobileList?.map((email) => email).join(', ')}
            </Item>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Grid
        container
        className={classNames(
          'alert-grid-container',
          item?.usersNotified && item?.usersNotified?.length > 0 ? 'alert-grid-with-more-info-container' : ''
        )}
        alignItems='center'
        onClick={showDetailHandler}
      >
        <Grid item xs={11} sm={11} rowGap={2}>
          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={3} md={2}>
              <Item className={classNames('alert-grid-item-container')}>
                <Box>
                  <Item className={classNames('alert-card-field')}>
                    {datetimehelper.formatDateTimebyTimeZone(timezone, item?.dateTimeUtc, 'DD/MM/YYYY hh:mm:ss A')}
                  </Item>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <Item className={classNames('alert-grid-item-container, alert-card-field')}>
                <Tooltip
                  title={isEventitemSiteEllipsis ? item?.siteName : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <div ref={EventItemSiteText} className='alert-item-site'>
                    {item?.siteName}
                  </div>
                </Tooltip>
              </Item>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Item className={classNames('alert-grid-item-container, alert-card-field')}>
                <Tooltip
                  title={isEventItemTypeEllipsis ? item?.alertRuleName : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <div ref={EventItemTypeText} className='alert-item-type'>
                    {item?.alertRuleName}
                  </div>
                </Tooltip>
              </Item>
            </Grid>

            <Grid item xs={12} sm={3} md={4}>
              <Item className={classNames('alert-grid-item-container, alert-card-field')}>
                <Tooltip
                  title={isEventItemTypeEllipsis ? item?.message : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <div ref={EventItemTypeText} className='alert-item-type'>
                    {item?.message}
                  </div>
                </Tooltip>
              </Item>
            </Grid>
          </Grid>

          {item?.usersNotified && item?.usersNotified?.length > 0 && (
            <Collapse in={showDetails}>
              <Grid container alignItems='flex-start' className={classNames('alert-card-more-details')}>
                <Grid item xs={12} sm={12}>
                  <Item className={classNames('alert-grid-item-container')}>
                    <NotifyDetailSection />
                  </Item>
                </Grid>
              </Grid>
            </Collapse>
          )}
        </Grid>

        {item?.usersNotified && item?.usersNotified?.length > 0 && (
          <Grid item xs={1} sm={1} alignItems='flex-start'>
            {
              <Item className={classNames('alert-grid-item-container')}>
                <div>
                  <Item className={classNames('alert-grid-button-container')}>
                    {
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className={classNames('button-icon-color')} />
                          ) : (
                            <DoubleArrowDownIcon className={classNames('button-icon-color')} />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    }
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        )}
      </Grid>

      <Grid
        container
        className={classNames(
          'alert-grid-mobile-container',
          item?.usersNotified && item?.usersNotified?.length > 0 ? 'alert-grid-with-more-info-container' : ''
        )}
        alignItems='center'
      >
        <Grid item xs={11} sm={11} rowGap={2}>
          <Grid container alignItems='flex-start'>
            {item?.dateTimeUtc && (
              <Grid item xs={12}>
                <Item className={classNames('alert-grid-item-container')}>
                  <Box>
                    <Item className={classNames('alert-card-field-header')}>Datetime</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('alert-mobile-card-field-value')}>
                      {datetimehelper.formatDateTimebyTimeZone(timezone, item?.dateTimeUtc, 'DD/MM/YYYY hh:mm:ss A')}
                    </Item>
                  </Box>
                </Item>
              </Grid>
            )}
            {item?.siteName && (
              <Grid item xs={12}>
                <Item className={classNames('alert-grid-item-container')}>
                  <Box>
                    <Item className={classNames('alert-card-field-header')}>Site</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('alert-mobile-card-field-value')}>{item?.siteName}</Item>
                  </Box>
                </Item>
              </Grid>
            )}
            {item?.alertRuleName && (
              <Grid item xs={12}>
                <Item className={classNames('alert-grid-item-container')}>
                  <Box>
                    <Item className={classNames('alert-card-field-header')}>Alert Rule Name</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('alert-mobile-card-field-value')}>{item?.alertRuleName}</Item>
                  </Box>
                </Item>
              </Grid>
            )}
            {item?.message && (
              <Grid item xs={12}>
                <Item className={classNames('alert-grid-item-container')}>
                  <Box>
                    <Item className={classNames('alert-card-field-header')}>Message</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('alert-mobile-card-field-value')}>{item?.message}</Item>
                  </Box>
                </Item>
              </Grid>
            )}
          </Grid>

          {item?.usersNotified && item?.usersNotified?.length > 0 && (
            <Collapse in={showDetails}>
              <Grid container alignItems='flex-start' className={classNames('alert-card-more-details')}>
                <Grid item xs={12} sm={12}>
                  <Item className={classNames('alert-grid-item-container')}>
                    <NotifyDetailSection />
                  </Item>
                </Grid>
              </Grid>
            </Collapse>
          )}
        </Grid>

        {item?.usersNotified && item?.usersNotified?.length > 0 && (
          <Grid item xs={1} sm={1} alignItems='flex-start'>
            {
              <Item className={classNames('alert-grid-item-container')}>
                <div>
                  <Item className={classNames('alert-grid-button-container')}>
                    {
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className={classNames('button-icon-color')} />
                          ) : (
                            <DoubleArrowDownIcon className={classNames('button-icon-color')} />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    }
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AlertItem;
