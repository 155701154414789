import './icons.scss';

interface ArrowDownIconProps {
    className:string
    onClick: () => void
};

const ArrowDownIcon = (props: ArrowDownIconProps) => {
    return (
        <div className={`base-icon arrow-down-icon ${props.className}`} onClick={props.onClick} />
    );
};

export default ArrowDownIcon