import {
  CardsIcon,
  DashboardIcon,
  PriceSchedulesIcon,
  ProductsIcon,
  PumpsIcon,
  SitesIcon,
  TerminalsIcon,
  UserManagementIcon,
  UserSingleIcon,
  UserCheckIcon,
  TransactionsIcon,
  TanksIcon,
  SiteDetailsIcon,
  ReceiptTemplatesIcon,
  PriceSignIcon,
  EventsIcon,
  AlertLogIcon,
  ForecourtControllersIcon,
} from '../../components/icons';
import ActivityLogIcon from '../../components/icons/activity-log-icon';
import BinRangeIcon from '../../components/icons/bin-range-icon';
import MonitorIcon from '../../components/icons/monitor-icon';
import { ModuleName } from '../../constants/module-constants';

import { NavItemModel } from '../../models/navItemModel';

export const MainNavItems: NavItemModel[] = [
  {
    label: 'Home',
    path: 'organisations/{orgId}',
    icon: <DashboardIcon />,
    moduleName: ModuleName.DASHBOARD,
  },
  {
    label: 'Sites',
    path: 'organisations/{orgId}/sites',
    icon: <SitesIcon />,
    moduleName: ModuleName.SITE,
  },
  {
    label: 'Transactions',
    path: 'organisations/{orgId}/transactions',
    icon: <TransactionsIcon />,
    moduleName: ModuleName.TRANSACTION,
  },
  {
    label: 'Products',
    path: 'organisations/{orgId}/products',
    icon: <ProductsIcon />,
    moduleName: ModuleName.PRODUCT,
  },
  {
    label: 'Price Schedules',
    path: 'organisations/{orgId}/price-schedules',
    icon: <PriceSchedulesIcon />,
    moduleName: ModuleName.PRICE_SCHEDULE,
  },
  {
    label: 'Card Management',
    path: 'card-management',
    icon: <CardsIcon />,
    moduleName: ModuleName.CARD_MANAGEMENT,
    subItems: [
      {
        label: 'BIN Ranges',
        path: 'organisations/{orgId}/bin-ranges',
        icon: <BinRangeIcon />,
        moduleName: ModuleName.BINRANGE,
      },
      {
        label: 'Cards',
        path: 'organisations/{orgId}/cards',
        icon: <CardsIcon />,
        moduleName: ModuleName.CARD,
      },
    ],
  },
  {
    label: 'User Management',
    path: 'user-management',
    icon: <UserCheckIcon />,
    moduleName: ModuleName.USER_MANAGEMENT,
    subItems: [
      {
        label: 'Users',
        path: 'organisations/{orgId}/users',
        icon: <UserSingleIcon />,
        moduleName: ModuleName.USER,
      },
      {
        label: 'Permission Groups',
        path: 'organisations/{orgId}/permission-groups',
        icon: <UserManagementIcon />,
        moduleName: ModuleName.PERMISSION_GROUP,
      },
    ],
  },
  {
    label: 'Monitor',
    path: 'monitor',
    icon: <MonitorIcon />,
    moduleName: ModuleName.MONITOR,
    subItems: [
      {
        label: 'Events',
        path: 'organisations/{orgId}/events',
        icon: <EventsIcon />,
        moduleName: ModuleName.EVENT,
      },
      {
        label: 'Activity Logs',
        path: 'organisations/{orgId}/activity-logs',
        icon: <ActivityLogIcon />,
        moduleName: ModuleName.ACTIVITY_LOG,
      },
      {
        label: 'Alerts',
        path: 'organisations/{orgId}/alerts',
        icon: <AlertLogIcon />,
        moduleName: ModuleName.ALERT,
      },
    ],
  },
];

export const SiteNavItems: NavItemModel[] = [
  {
    label: 'Site Details',
    path: 'organisations/{orgId}/sites/{siteId}/details',
    icon: <SiteDetailsIcon />,
    moduleName: ModuleName.SITE,
  },
  {
    label: 'Terminals',
    path: 'organisations/{orgId}/sites/{siteId}/terminals',
    icon: <TerminalsIcon />,
    moduleName: ModuleName.TERMINAL,
  },
  {
    label: 'Tanks',
    path: 'organisations/{orgId}/sites/{siteId}/tanks',
    icon: <TanksIcon />,
    moduleName: ModuleName.TANK,
  },
  {
    label: 'Pumps & Hoses',
    path: 'organisations/{orgId}/sites/{siteId}/pumps',
    icon: <PumpsIcon />,
    moduleName: ModuleName.PUMP,
  },
  {
    label: 'Price Signs',
    path: 'organisations/{orgId}/sites/{siteId}/price-signs',
    icon: <PriceSignIcon />,
    moduleName: ModuleName.PRICE_SIGN,
  },
  {
    label: 'Forecourt Controllers',
    path: 'organisations/{orgId}/sites/{siteId}/forecourt-controllers',
    icon: <ForecourtControllersIcon />,
    moduleName: ModuleName.FORECOURT_CONTROLLER,
  },
  {
    label: 'Receipt Templates',
    path: 'organisations/{orgId}/sites/{siteId}/receipt-templates',
    icon: <ReceiptTemplatesIcon />,
    moduleName: ModuleName.RECEIPT_TEMPLATE,
  },
];
