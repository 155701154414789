import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from './actions';
import * as azureMapService from './services';
import { setAddressList, setSelectedAddressTimezone, setSelectedAddress } from './reducers';
import { AddressDetailsModel, AddressModel } from '../../models/siteModel';
import {
  AzureMapAddressResponse,
  AzureMapTimezoneResponse,
  AzureMapsResourceTokenResponse,
} from '../../entities/azure-map';
import { GetDataFromSessionStorage, SetDataInSessionStorage } from '../../utilities/storageHelper';
import { AZURE_MAPS_RESOURCE_TOKEN } from '../../constants/auth-constants';
import { getApiErrorMessage } from '../../utilities/errorhandler';
import { setSnackBarError } from '../snackbar/reducers';

export function* rootSaga() {
  yield takeLatest(actions.GET_MAPS_RESOURCE_TOKEN, getMapResourceToken);
  yield takeLatest(actions.CHANGE_ADDRESS_LIST, changeAddressList);
  yield takeLatest(actions.CHANGE_SELECTED_ADDRESS, changeSelectedAddress);
  yield takeLatest(actions.CLEAR_ADDRESS_LIST, clearAddressList);
}

export function* getMapResourceToken() {
  try {
    let tokenResponse: AzureMapsResourceTokenResponse = yield call(azureMapService.getAzureMapsResourceTokens);
    SetDataInSessionStorage(AZURE_MAPS_RESOURCE_TOKEN, tokenResponse.token);
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* changeAddressList(action: PayloadAction<string>) {
  try {
    if (!GetDataFromSessionStorage(AZURE_MAPS_RESOURCE_TOKEN)) getMapResourceToken();
    let response: AzureMapAddressResponse = yield call(azureMapService.getAddressList, action.payload);
    let records: AddressDetailsModel[] = yield call(MapAddressListEntityToModel, response);
    yield put(setAddressList(records));
  } catch {
    yield put(setAddressList([]));
  }
}

export function* changeSelectedAddress(action: PayloadAction<AddressDetailsModel>) {
  try {
    const position = `${action.payload.position.lat},${action.payload.position.lon}`;
    let response: AzureMapTimezoneResponse = yield call(azureMapService.getTimezone, position);
    yield put(setSelectedAddressTimezone(response.TimeZones[0].Id));
    yield put(setSelectedAddress(MapAddressEntityToModel(action.payload)));
  } catch {
    yield put(setSelectedAddressTimezone(''));
    yield put(setSelectedAddress({} as AddressDetailsModel));
  }
}

export function* clearAddressList() {
  yield put(setAddressList([]));
}

const MapAddressListEntityToModel = (response: AzureMapAddressResponse) => {
  if (response && response.results.length > 0) {
    const result: AddressDetailsModel[] = response.results.map((it) => {
      return { address: it.address, position: it.position };
    });
    return result;
  }
  return [] as AddressDetailsModel[];
};

const MapAddressEntityToModel = (data: AddressDetailsModel) => {
  if (data) {
    return {
      address: data.address as AddressModel,
      position: data.position,
    } as AddressDetailsModel;
  }
  return {} as AddressDetailsModel;
};
