import KeyValuePair from '../models/baseModels/keyValuePairModel';
export const TRANSACTION_FILTER_STORAG_KEY = 'transaction-filter-Key';
export const TRANSACTION_EXPORT_FILTER_STORAG_KEY = 'transaction-export-filter-Key';
export const TRANSACTION_EXPORT_COLUMN_STORAG_KEY = 'transaction-export-column-Key';

export const TransactionType = Object.freeze({
  VOID: 'void',
  AUTHORISATION: 'authorisation',
  COMPLETION: 'completion',
});

export const transactionDisplayType: KeyValuePair[] = [
  { key: 'void', value: 'Void' },
  { key: 'authorisation', value: 'Authorisation' },
  { key: 'completion', value: 'Completion' },
];
