import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface HideNavIconProps {
    onClick: () => void,
    className: string
};

const HideNavIcon = (props: HideNavIconProps) => {
    return (
        <div onClick={props.onClick} >
            <SvgIcon className={"base-icon hide-nav-icon " + props.className} />
        </div>
    );
};

export default HideNavIcon