import { AlertUserGroupEntity } from '../../entities/alert-usergroup';
import api, { setAuthorize } from '../../utilities/api';

const ALERT_API_PREFIX = 'alert-management';

export const getAlertUserGroupList = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${ALERT_API_PREFIX}/alerts/user-groups?organisationId=${organisationId}`);
  return result.data;
};

export const getAlertUserGroupInfo = async (userGroupId: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${ALERT_API_PREFIX}/alerts/user-groups/${userGroupId}?organisationId=${organisationId}`);
  return result.data;
};

export const createAlertUserGroup = async (data: AlertUserGroupEntity) => {
  await setAuthorize();
  await api.post(`/${ALERT_API_PREFIX}/alerts/user-groups`, data);
};

export const editAlertUserGroup = async (data: AlertUserGroupEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${ALERT_API_PREFIX}/alerts/user-groups/${id}`, data);
};

export const deleteAlertUserGroup = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${ALERT_API_PREFIX}/alerts/user-groups/${id}?organisationId=${organisationId}`);
};
