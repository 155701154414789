import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectUsersContent } from '../../store/users/selectors';
import { selectNavPaneStatus } from '../../store/nav-pane/selectors';
import { selectAlertUserGroupListData, selectAlertUserGroupStatus } from '../../store/alert-usergroups/selectors';
import * as actions from '../../store/alert-usergroups/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as userActions from '../../store/users/actions';
import AlertUserGroups from './alert-usergroups';

const mapStateToProps = (state: RootState) => {
  return {
    alertUserGroupStatus: selectAlertUserGroupStatus(state),
    alertUserGroupList: selectAlertUserGroupListData(state),
    usersContent: selectUsersContent(state),
    authStatus: selectAuthStatus(state),
    navPaneState: selectNavPaneStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadAlertUserGroups: () => dispatch({ type: actions.LOAD_ALERT_USER_GROUPS }),
    deleteAlertUserGroup: (id: string) => dispatch({ type: actions.DELETE_ALERT_USER_GROUP, payload: id }),
    loadUserList: () => dispatch({ type: userActions.LOAD_USERS }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const AlertUserGroupList = connect(mapStateToProps, mapDispatchToProps)(AlertUserGroups);

export default AlertUserGroupList;
