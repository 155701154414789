import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ActivityLogFilterModel } from '../../models/activityLogModel';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectOrganisationId } from '../../store/auth/selectors';
import {
  selectActivityLogData,
  selectActivityLogResourceTypes,
  selectActivityLogStatus,
} from '../../store/activity-log/selectors';
import { selectIsPageReachBottom } from '../../store/page-configuration/selectors';
import * as actions from '../../store/activity-log/actions';
import * as headerActions from '../../store/header/actions';
import * as modalActions from '../../store/modals/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as cardActions from '../../store/cards/actions';
import ActivityLogs from './activity-log-list';

const mapStateToProps = (state: RootState) => {
  return {
    selectedOrganisationId: selectOrganisationId(state),
    activityLogStatus: selectActivityLogStatus(state),
    activityLogContent: selectActivityLogData(state),
    activityLogResourceTypes: selectActivityLogResourceTypes(state),
    authStatus: selectAuthStatus(state),
    isPageReachBottom: selectIsPageReachBottom(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initLoadActivityLogs: (data?: ActivityLogFilterModel) =>
      dispatch({ type: actions.INIT_LOAD_ACTIVITY_LOGS, payload: data }),
    loadActivityLogs: (data: ActivityLogFilterModel) => dispatch({ type: actions.LOAD_ACTIVITY_LOGS, payload: data }),
    loadActivityLogResourceTypes: () => dispatch({ type: actions.LOAD_ACTIVITY_LOG_RESOURCE_TYPES }),
    setIsScrollRequest: (data: boolean) => dispatch({ type: cardActions.SET_IS_SCROLL_REQUEST, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const ActivityLogList = connect(mapStateToProps, mapDispatchToProps)(ActivityLogs);

export default ActivityLogList;
