import { useCallback, useEffect } from 'react';
import { Messages } from '../../constants/messages';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { ValidationError } from '../../models/baseModels/validationModel';
import DateTimePickerComponent from '../datetimepicker/datetimepicker.container';
import classNames from 'classnames';
import './daterange.scss';
import CustomTooltip from '../tooltip/custom-tooltip';

interface DateRangeProps {
  fromLabel?: string;
  toLabel?: string;
  dateFormat: string;
  startDateTimeValue: string;
  endDateTimeValue: string;
  disableFuture: boolean;
  startDateFieldName: string;
  endDateFieldName: string;
  startTimeFieldName: string;
  endTimeFieldName: string;
  validateCounter?: number;
  isStartDateTimeMandatory?: boolean;
  isEndDateTimeMandatory?: boolean;
  minDate?: Date;
  fromLabelTooltipTitle?: string;
  handleStartDateTimeChange: (newValue: string) => void;
  handleEndDateTimeChange: (newValue: string) => void;
  fieldValidationStatus: (name: string) => ValidationError;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
}

const DateRangeControl = (props: DateRangeProps) => {
  const {
    fromLabel,
    toLabel,
    dateFormat,
    startDateTimeValue,
    endDateTimeValue,
    disableFuture,
    startDateFieldName,
    endDateFieldName,
    startTimeFieldName,
    endTimeFieldName,
    validateCounter,
    isStartDateTimeMandatory,
    isEndDateTimeMandatory,
    minDate,
    fromLabelTooltipTitle,
    handleStartDateTimeChange,
    handleEndDateTimeChange,
    fieldValidationStatus,
    setFieldValidation,
    removeValidation,
    setShowValidationMessage,
  } = props;

  const dateRangeControlName = `daterange-${startDateFieldName}-${endDateFieldName}`;

  const dateRangeValidation = useCallback(() => {
    if (!!startDateTimeValue && !!endDateTimeValue) {
      let startDateTime = new Date(startDateTimeValue);
      let endDateTime = new Date(endDateTimeValue);

      if (startDateTime.valueOf() >= endDateTime?.valueOf()) {
        setFieldValidation({
          name: dateRangeControlName,
          hasError: true,
          showErrorMessage: false,
          validationMessage: Messages.DATE_RANGE_START_END_VALIDATION,
        });
        setShowValidationMessage({ key: dateRangeControlName, value: true });
      } else {
        removeValidation(dateRangeControlName);
        setShowValidationMessage({ key: dateRangeControlName, value: false });
      }
    } else {
      removeValidation(dateRangeControlName);
      setShowValidationMessage({ key: dateRangeControlName, value: false });
    }
  }, [
    dateRangeControlName,
    endDateTimeValue,
    startDateTimeValue,
    removeValidation,
    setFieldValidation,
    setShowValidationMessage,
  ]);

  useEffect(() => {
    dateRangeValidation();
  }, [dateRangeValidation]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      dateRangeValidation();
    }
  }, [validateCounter, dateRangeValidation]);

  const onHandleStartDateTimeChange = (newValue: string) => {
    handleStartDateTimeChange(newValue);
    dateRangeValidation();
  };

  const onHandleEndDateTimeChange = (newValue: string) => {
    handleEndDateTimeChange(newValue);
    dateRangeValidation();
  };

  return (
    <div className='dateRange'>
      <div className='dateRange-sub-label'>
        {fromLabel} {fromLabelTooltipTitle && <CustomTooltip title={fromLabelTooltipTitle} />}{' '}
        {isStartDateTimeMandatory && <strong className='asterisk'>*</strong>}
      </div>
      <div
        className={
          fieldValidationStatus(dateRangeControlName)?.hasError
            ? classNames('dateRange-container', 'daterangepicker-error')
            : classNames('dateRange-container')
        }
      >
        <DateTimePickerComponent
          validateCounter={validateCounter}
          dateFormat={dateFormat}
          dateTimeValue={startDateTimeValue}
          handleDateTimeChange={onHandleStartDateTimeChange}
          isMandatory={isStartDateTimeMandatory ? isStartDateTimeMandatory : false}
          disableFuture={disableFuture}
          dateField={startDateFieldName}
          timeField={startTimeFieldName}
          minDate={minDate}
        />
        <div className='dateRange-sub-label'>
          {toLabel} {isEndDateTimeMandatory && <strong className='asterisk'>*</strong>}
        </div>
        <DateTimePickerComponent
          dateFormat={dateFormat}
          dateTimeValue={endDateTimeValue}
          handleDateTimeChange={onHandleEndDateTimeChange}
          isMandatory={isEndDateTimeMandatory ? isEndDateTimeMandatory : false}
          disableFuture={disableFuture}
          dateField={endDateFieldName}
          timeField={endTimeFieldName}
        />
      </div>
      {fieldValidationStatus(dateRangeControlName)?.hasError && (
        <div className='daterange-validation-text-message-error'>
          {fieldValidationStatus(dateRangeControlName)?.validationMessage}
        </div>
      )}
    </div>
  );
};

export default DateRangeControl;
