import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import * as actions from '../../store/tank-histories/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as modalActions from '../../store/modals/actions';
import * as tankActions from '../../store/tanks/actions';
import { RootState } from '../../store/rootReducers';
import { selectTankHistoryData, selectTankHistoryStatus } from '../../store/tank-histories/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectTankInfo } from '../../store/tanks/selectors';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { TankStatusHistoryFilterModel } from '../../models/tankHistory';
import TankHistoryComponent from './tank-histories';

const mapStateToProps = (state: RootState) => {
  return {
    tankHistoryData: selectTankHistoryData(state),
    tankHistoryStatus: selectTankHistoryStatus(state),
    tankInfo: selectTankInfo(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTankStatusHistories: (data: TankStatusHistoryFilterModel) =>
      dispatch({ type: actions.LOAD_TANK_STATUS_HISTORIES, payload: data }),
    loadTankInfo: (id: string) => dispatch({ type: tankActions.LOAD_TANK_INFO, payload: id }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const TankHistoriesPage = connect(mapStateToProps, mapDispatchToProps)(TankHistoryComponent);

export default TankHistoriesPage;
