import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface EnterIconProps {
  className?: string;
}

const EnterIcon = (props: EnterIconProps) => {
  return <SvgIcon className={`base-icon enter-icon ${props.className}`} />;
};

export default EnterIcon;
