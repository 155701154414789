import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectAlertUserGroupState = (state: RootState) => state.AlertUserGroupState;

export const selectAlertUserGroupListData = createSelector(
    selectAlertUserGroupState,
    state => state.alertUserGroupList
  );

export const selectAlertUserGroupInfoData = createSelector(
  selectAlertUserGroupState,
  state => state.alertUserGroupInfo
);

export const selectAlertUserGroupStatus = createSelector(
  selectAlertUserGroupState,
  state => state.alertUserGroupStatus
);

export const selectAlertUserGroupNameListData = createSelector(
  selectAlertUserGroupState,
  state => state.alertUserGroupNameList
);