import { LoadingStatus } from './../../constants/loading-constants';
import { initialState } from "./modalsInitState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalStateModel } from "../../models/baseModels/modalStateModel";

const modalSlice = createSlice({
    name: 'modalState',
    initialState,
    reducers: {
        openModal:(state, action: PayloadAction<ModalStateModel>) => {
            state.modalStatus = true;
            state.hasError = false;
            state.actionStatus= '';
            state.data = action.payload;
        },
        closeModal:(state) => {
            state.modalStatus = false;
            state.hasError = false;
            state.actionStatus= '';
            state.data = {} as ModalStateModel;
        },
        setModalActionStatus: (state, action: PayloadAction<string>) => {
            state.actionStatus = action.payload;
        },
        setModalError: (state, action: PayloadAction<boolean>) => {
            state.hasError = action.payload;
            state.actionStatus = LoadingStatus.ERROR;
        }
    }
});

export const {
    openModal,
    closeModal,
    setModalError,
    setModalActionStatus,
} = modalSlice.actions;

export default modalSlice.reducer;