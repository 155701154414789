import KeyValuePair from '../models/baseModels/keyValuePairModel';

export const BatteryDisplayType: KeyValuePair[] = [
  { key: 'unknown', value: 'Unknown' },
  { key: 'discharging', value: 'Discharging' },
  { key: 'slowCharging', value: 'SlowCharging' },
  { key: 'fastCharging', value: 'FastCharging' },
  { key: 'connected', value: 'Connected' },
  { key: 'disconnected', value: 'Disconnected' },
];

export const CardReaderType: KeyValuePair[] = [
  { key: 'unknown', value: 'Unknown' },
  { key: 'magtekCardReader', value: 'Magtek Card Reader' },
  { key: 'windcaveSecureCardReaderMagstripe', value: 'Windcave Secure Card Reader Magstripe' },
  { key: 'windcaveSecureCardReaderIcc', value: 'Windcave Secure Card Reader Icc' },
  { key: 'windcaveSecureCardReaderContactless', value: 'Windcave Secure Card Reader Contactless' },
  { key: 'windcaveSecureCardReaderMifare', value: 'Windcave Secure Card Reader Mifare' },
  { key: 'windcaveSecureCardReaderUnknown', value: 'Windcave Secure Card Reader Unknown' },
  { key: 'hidCardReader', value: 'HID Card Reader' },
  { key: 'hidSignoCardReader', value: 'HID Signo Card Reader' },
  { key: 'keypadEntry', value: 'Keypad Entry' },
  { key: 'qrCodeScanner', value: 'QR Code Scanner' },
  { key: 'remotePump', value: 'Remote Pump' },
];

export const DoorStatusType: KeyValuePair[] = [
  { key: 'closed', value: 'Closed' },
  { key: 'open', value: 'Open' },
  { key: 'notConnected', value: 'Not Connected' },
  { key: 'disabled', value: 'Disabled' },
];

export const EmergencyStopStatusType: KeyValuePair[] = [
  { key: 'inactive', value: 'Inactive' },
  { key: 'active', value: 'Active' },
  { key: 'notConnected', value: 'Not Connected' },
  { key: 'disabled', value: 'Disabled' },
];

export const PrinterStatusType: KeyValuePair[] = [
  { key: 'online', value: 'Online' },
  { key: 'offline', value: 'Offline' },
  { key: 'paperLow', value: 'Paper Low' },
  { key: 'noPaper', value: 'No Paper' },
  { key: 'inError', value: 'Error' },
  { key: 'notConnected', value: 'Not Connected' },
  { key: 'disabled', value: 'Disabled' },
];

export const PowerSourceType: KeyValuePair[] = [
  { key: 'mains', value: 'Mains' },
  { key: 'battery', value: 'Battery' },
];

export const TerminalPasscodeType: KeyValuePair[] = [
  { key: 'backdoor', value: 'Backdoor' },
  { key: 'main', value: 'Main' },
];
