import { LoadingStatus } from '../../constants/loading-constants';
import { ActivityLogModel } from '../../models/activityLogModel';

interface ActivityLogState {
  activityLogStatus: string;
  activityLogData: ActivityLogModel[];
  activityLogResourceTypes: string[];
  hasError: boolean;
}

export const initialState: ActivityLogState = {
  activityLogStatus: LoadingStatus.LOADING,
} as ActivityLogState;
