import { initialState } from "./authInitState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountInfo } from "@azure/msal-browser";
import { OrganisationItemModel } from "../../models/organisationModel";
import { UserPermissionModel } from "../../models/userPermissionModel";
import { LoadingStatus } from "../../constants/loading-constants";

const authSlice = createSlice({
    name: 'authState',
    initialState,
    reducers:{
        setAccount: (state, action: PayloadAction<AccountInfo>) => {
            state.account = action.payload
            state.hasError = false;
        },
        setOrganisationId:(state, action: PayloadAction<string>) => {
            state.organisationId = action.payload
            state.hasError = false;
        },
        setAuthError: (state) => {
            state.hasError = true;
            state.authStatus = LoadingStatus.ERROR;
        },
        setCurrentOrganisation: (state, action: PayloadAction<OrganisationItemModel>) => {
            state.currentOrganisation = action.payload
        },
        setUserPermissions: (state, action: PayloadAction<UserPermissionModel[]>) => {
            state.userPermissions = action.payload
        },
        setAuthStatus :(state, action: PayloadAction<string>) => {
            state.hasError = false;
            state.authStatus = action.payload;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId= action.payload;
         }
    }
});

export const {
    setAccount,
    setOrganisationId,
    setAuthError,
    setCurrentOrganisation,
    setUserPermissions,
    setAuthStatus,
    setUserId
} = authSlice.actions;

export default authSlice.reducer;