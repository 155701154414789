import { combineReducers } from '@reduxjs/toolkit';

//import modeules reducers here
import SiteState from './sites/reducers';
import NavePane from './nav-pane/reducers';
import PumpState from './pump/reducers';
import TerminalState from './terminal/reducers';
import ProductState from './product/reducers';
import ModalState from './modals/reducers';
import SnackBarState from './snackbar/reducers';
import AuthState from './auth/reducers';
import FieldValidationState from './fieldValidation/reducers';
import PriceScheduleState from './price-schedules/reducers';
import TransactionState from './transaction/reducers';
import HeaderState from './header/reducers';
import OrganisationState from './organisations/reducers';
import UserState from './users/reducers';
import TankState from './tanks/reducers';
import TankHistoryState from './tank-histories/reducers';
import TankDeliveryState from './tank-deliveries/reducers';
import GenericErrorState from './generic-error/reducers';
import PermissionGroupState from './permission-group/reducers';
import DialogBoxState from './dialog-box/reducers';
import PageConfigurationState from './page-configuration/reducers';
import BackDropState from './backdrop/reducers';
import BinRangeState from './bin-ranges/reducers';
import PaginationState from './pagination/reducers';
import TerminalFirmwareState from './terminal-firmwares/reducers';
import TerminalOperationState from './terminal-operations/reducers';
import AzureMapState from './azure-map/reducers';
import CardState from './cards/reducers';
import ReceiptTemplateState from './receipt-template/reducers';
import ReportState from './reports/reducers';
import CardOperationState from './card-operations/reducers';
import PriceSignState from './price-signs/reducers';
import ActivityLogState from './activity-log/reducers';
import EventState from './event/reducers';
import AlertState from './alert/reducers';
import AlertRuleState from './alert-rules/reducers';
import AlertUserGroupState from './alert-usergroups/reducers';
import ForecourtControllerState from './forecourt-controllers/reducers';

const rootReducer = combineReducers({
  SiteState,
  NavePane,
  PumpState,
  TerminalState,
  ProductState,
  ModalState,
  SnackBarState,
  AuthState,
  FieldValidationState,
  PriceScheduleState,
  TransactionState,
  HeaderState,
  OrganisationState,
  TankState,
  TankHistoryState,
  TankDeliveryState,
  GenericErrorState,
  UserState,
  PermissionGroupState,
  DialogBoxState,
  PageConfigurationState,
  BackDropState,
  BinRangeState,
  PaginationState,
  TerminalFirmwareState,
  TerminalOperationState,
  AzureMapState,
  CardState,
  ReceiptTemplateState,
  ReportState,
  CardOperationState,
  PriceSignState,
  ActivityLogState,
  EventState,
  AlertState,
  AlertRuleState,
  AlertUserGroupState,
  ForecourtControllerState,
});

//declare type for state of the App
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
