import { LoadingStatus } from '../../constants/loading-constants';

interface DialogBoxState {
    actionState: string
    isVisible: boolean
}

export const initialState: DialogBoxState = {
    actionState: LoadingStatus.INIT,
    isVisible: false
} as DialogBoxState;