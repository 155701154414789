import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';
import { ValidationError } from '../../models/baseModels/validationModel';

export const selectModalState = (state: RootState) => state.FieldValidationState;

export const selectFieldsValidation = createSelector(
    selectModalState,
    state => state.Validations
);

export const selectHasValidationError = createSelector(
    selectModalState,
    state => state.hasValidationError
);

export const showValidationError = (state:RootState) => state.FieldValidationState.showValidationError;

export const selectFieldValidation = (name :string) => createSelector(
    selectFieldsValidation,
    (validationList) => {
        const item = validationList.find(item => item.name === name);
        return (item) ? item : {} as ValidationError;
    }
);

export const selectCustomValidationMessage = createSelector(
    selectModalState,
    state => state.customValidationMessage
);
