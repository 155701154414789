import { takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { setSnackBarError, setSnackBarState, setSnackBarSuccess, setSnackBarWarning } from './reducers';
import { SnackBarStateModel } from '../../models/baseModels/snackBarStateModel';
import * as actions from './actions';

export function* rootSaga() {
  yield takeLatest(actions.CHANGE_SNACKBAR_STATUS, changeSnackBarState);
  yield takeLatest(actions.DISPLAY_SUCCESS_SNACKBAR, displaySuccessSnackbar);
  yield takeLatest(actions.DISPLAY_WARNING_SNACKBAR, displayWarningSnackbar);
  yield takeLatest(actions.DISPLAY_ERROR_SNACKBAR, displayErrorSnackbar);
}

export function* changeSnackBarState(action: PayloadAction<SnackBarStateModel>) {
  yield put(setSnackBarState(action.payload));
}

export function* displaySuccessSnackbar(action: PayloadAction<string>) {
  yield put(setSnackBarSuccess(action.payload));
}

export function* displayWarningSnackbar(action: PayloadAction<string>) {
  yield put(setSnackBarWarning(action.payload));
}

export function* displayErrorSnackbar(action: PayloadAction<string>) {
  yield put(setSnackBarError(action.payload));
}
