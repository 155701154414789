import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface FileUploadedToCloudIconProps {
  className?: string;
}

const FileUploadedToCloudIcon = (props: FileUploadedToCloudIconProps) => {
  return <SvgIcon className={`color-icon file-uploaded-to-cloud-icon ${props.className}`} />;
};

export default FileUploadedToCloudIcon;
