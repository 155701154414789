import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Link, IconButton } from '@mui/material';
import classNames from 'classnames';
import './custom-tooltip.scss';

interface TooltipPropss {
  title: string;
  textDisplay?: string;
  moreInfoTextLink?: string;
  moreInfoTitle?: string;
  moreInfoContext?: string;
  disableHoverListener?: boolean;
}

const CustomTooltip = (props: TooltipPropss) => {
  const { title, textDisplay, moreInfoTextLink, moreInfoTitle, moreInfoContext } = props;
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setiIsDialogOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const openDialogBox = () => {
    setiIsDialogOpen(true);
  };

  const closeDialogBox = () => {
    setiIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        className='dialog-container'
        open={isDialogOpen}
        onClose={closeDialogBox}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <React.Fragment>
          <DialogTitle id='alert-dialog-title' className='dialogbg'>
            {moreInfoTitle}
            {
              <IconButton aria-label='close' onClick={closeDialogBox} className='dialog-title-close'>
                <CloseIcon />
              </IconButton>
            }
          </DialogTitle>
          <Divider />
        </React.Fragment>

        <DialogContent className={classNames('tooltip-dialog-content')}>
          <DialogContentText id='alert-dialog-description' className={classNames('dialog-context', 'dialog-text')}>
            <span className='tooltip-dialog-content'>{moreInfoContext}</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <span className='custom-tooltip'>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement='top'
            arrow
            title={
              <React.Fragment>
                <span style={{ whiteSpace: 'pre-wrap' }}>{title}</span>
                <Link className='tooltip-moreInfo-link' onClick={openDialogBox}>
                  {moreInfoTextLink}
                </Link>
              </React.Fragment>
            }
          >
            {textDisplay ? (
              <span className='display-text' onMouseOver={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
                {textDisplay ? textDisplay : ''}
              </span>
            ) : (
              <div className='tooltip-info-icon' onClick={handleTooltipOpen}></div>
            )}
          </Tooltip>
        </ClickAwayListener>
      </span>
    </React.Fragment>
  );
};

export default CustomTooltip;
