import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { selectModalData } from '../../../store/modals/selectors';
import { RootState } from '../../../store/rootReducers';
import { selectAvailableFirmwareData } from '../../../store/terminal-firmwares/selectors';
import { FirmwareInstallModel } from '../../../models/terminalFirmwareModel';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as terminalFirmwareActions from '../../../store/terminal-firmwares/actions';
import * as modalActions from '../../../store/modals/actions';
import * as dialogActions from '../../../store/dialog-box/actions';
import AvailableFirmwareModal from './available-firmwares';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    availableFirmware: selectAvailableFirmwareData(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadAvailableFirmware: (boardModel: string) =>
      dispatch({ type: terminalFirmwareActions.LOAD_AVAILABLE_FIRMWARES, payload: boardModel }),
    downloadFirmware: (firmwareName: string) =>
      dispatch({ type: terminalFirmwareActions.GET_FIRMWARE_DOWNLOAD_LINK, payload: firmwareName }),
    updateTerminalFirmware: (data: FirmwareInstallModel) =>
      dispatch({ type: terminalFirmwareActions.UPDATE_TERMINAL_FIRMWARE, payload: data }),
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const AvailableFirmwares = connect(mapStateToProps, mapDispatchToProps)(AvailableFirmwareModal);

export default AvailableFirmwares;
