import { NavPaneStates } from '../../constants/window-constants';
import { NavDropdownModel } from '../../models/navItemModel';

export interface NavePaneState {
  navPaneState: string;
  subMenuOpened: boolean;
  selectedMenuIndex: number;
  selectedSubMenuIndex: number;
  selectedDropdownMenuIndex: NavDropdownModel;
  hasError: boolean;
  selectActiveDropdown: number;
  selectedMenu: string;
}

export const initialState: NavePaneState = {
  navPaneState: NavPaneStates.EXPANDED,
  subMenuOpened: false,
} as NavePaneState;
