import { useState } from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import './styles/transactions.scss';

interface TransactionHeaderProps {
  isExpandAll: boolean;
  onToggleAll: () => void;
}

export const TransactionHeader = (props: TransactionHeaderProps) => {
  const { isExpandAll, onToggleAll } = props;

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      handleTooltipClose();
    }, 5000);
  };

  const handleToggleAllClick = () => {
    onToggleAll();
    handleTooltipOpen();
  };

  return (
    <TableRow>
      <TableCell className='icon-header'>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            className='navpane-tooltip'
            title={isExpandAll ? 'Collapse All' : 'Expand All'}
            placement='right'
            arrow
            followCursor
            open={openTooltip}
            onClose={handleTooltipClose}
          >
            <span className='toggle-all-button' onClick={handleToggleAllClick} onMouseMove={() => setOpenTooltip(true)}>
              {isExpandAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </span>
          </Tooltip>
        </ClickAwayListener>
      </TableCell>
      <TableCell className='site-header'>Site</TableCell>
      <TableCell align='left'>{`Time ${dateTimeHelper.getBrowserTimezone()}`}</TableCell>
      <TableCell align='left'>Card No. (PAN)</TableCell>
      <TableCell align='left'>Type</TableCell>
      <TableCell align='right'>Amount</TableCell>
      <TableCell align='left' className='response-header'>
        Response
      </TableCell>
      <TableCell align='left'>Reference</TableCell>
      <TableCell align='left'>State</TableCell>
    </TableRow>
  );
};
