import { BinRangeEntity } from '../../entities/bin-range';
import api, { setAuthorize } from '../../utilities/api';

const BIN_RANGE_API_PREFIX = 'card-management';

export const getBinRangeList = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${BIN_RANGE_API_PREFIX}/cards/bin-ranges?organisationId=${organisationId}`
  );
  return result.data;
};

export const deleteBinRange = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(
    `/${BIN_RANGE_API_PREFIX}/cards/bin-ranges/${id}?organisationId=${organisationId}`
  );
};

export const getBinRangeById = async (id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${BIN_RANGE_API_PREFIX}/cards/bin-ranges/${id}?organisationId=${organisationId}`
  );
  return result.data;
};

export const createBinRange = async (data: BinRangeEntity) => {
  await setAuthorize();
  await api.post(`/${BIN_RANGE_API_PREFIX}/cards/bin-ranges`, data);
};


export const editBinRange = async (data: BinRangeEntity) => {
  await setAuthorize();
  await api.put(`/${BIN_RANGE_API_PREFIX}/cards/bin-ranges/${data.id}`, data);
};