import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import * as fieldActions from '../../store/fieldValidation/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { RootState } from '../../store/rootReducers';
import { selectIsPageDirty } from '../../store/page-configuration/selectors';
import { selectBackdropActionStatus } from '../../store/backdrop/selectors';
import FormComponent from './form';

const mapStateToProps = (state: RootState) => {
  return {
    isPageDirty: selectIsPageDirty(state),
    backDropActionStatus: selectBackdropActionStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),

    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const Form = connect(mapStateToProps, mapDispatchToProps)(FormComponent);

export default Form;
