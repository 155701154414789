export const SITES_CONTENT = 'compac/SITES_CONTENT';
export const SITES_STATUS = 'compac/SITES_STATUS';
export const LOAD_SITES = 'compac/LOAD_SITES';
export const LOAD_SITES_STATUS = 'compac/LOAD_SITES_STATUS';
export const SITE_CONFIGURATION = 'compac/SITE_CONFIGURATION';
export const SITE_ITEM = 'compac/SITE_ITEM';
export const DELETE_SITE = 'compac/DELETE_SITE';
export const EDIT_SITE = 'compac/EDIT_SITE';
export const CREATE_SITE = 'compac/CREATE_SITE';
export const SET_SELECTED_SITE_ID = 'compac/SET_SELECTED_SITE_ID';
export const LOAD_SITENAME_LIST = 'compac/LOAD_SITENAME_LIST';
export const LOAD_SELECTED_SITE_INFO = 'compac/LOAD_SELECTED_SITE_INFO';
