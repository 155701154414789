import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { DEFAULT_DROP_DOWN_VALUE } from '../../../constants/dropdown-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { ProductPriceModel } from '../../../models/priceScheduleModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomTextField from '../../../components/text-field/text-field.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import Item from '../../../components/box-items/box-item';
import * as generalHelper from '../../../utilities/general-helper';
import '../styles/product-prices.scss';

interface ProductPriceConfigurationProps {
  validateCounter: number;
  productPrices: ProductPriceModel[];
  products: KeyValuePair[];
  readOnly?: boolean;
  removeValidation: (name: string) => void;
  onBindingValue?: (newvalue: ProductPriceModel[]) => void | undefined;
}

const ProductPriceConfiguration: React.FC<ProductPriceConfigurationProps> = (props: ProductPriceConfigurationProps) => {
  const { productPrices, validateCounter, products, readOnly, removeValidation, onBindingValue } = props;

  const [productPricesValue, setProductPricesValue] = useState(productPrices);
  const [validationFlag, setValidationFlag] = useState(0);
  const [focusPriceNumber, setFocusPriceNumber] = useState(false);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setProductPricesValue(productPrices);
  }, [productPrices, setProductPricesValue]);

  // handle click event of the Add button
  const handleAddClick = () => {
    setValidationFlag(0);
    let productPriceItem = {
      price: '',
    } as ProductPriceModel;
    let list = [productPriceItem];
    if (productPricesValue !== undefined) {
      list = [...productPricesValue];
      list.push(productPriceItem);
    }
    setFocusPriceNumber(true);

    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetPriceListValidation();

    let list = [...productPricesValue];
    list.splice(index, 1);

    setProductPricesValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetPriceListValidation = () => {
    productPricesValue.forEach((_, index) => {
      removeValidation(`product${index}`);
      removeValidation(`price${index}`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let productPriceItems = [...productPricesValue];
    if (!!productPriceItems[index]) {
      let item = { ...productPriceItems[index] };
      if (newvalue.key.toString().includes('product')) {
        item.id = !!newvalue.value ? newvalue.value.toString() : '';
      } else if (newvalue.key.toString().includes('price')) {
        item.price = newvalue.value.toString();
      }

      productPriceItems[index] = item;
    }
    setProductPricesValue(productPriceItems);

    if (onBindingValue) {
      onBindingValue(productPriceItems);
    }
  };

  const onFieldLostFocusHandler = (value: any, index: number) => {
    let productPriceItems = [...productPricesValue];
    if (!!productPriceItems[index]) {
      let item = { ...productPriceItems[index] };
      item.price = generalHelper.numberHandlerWithDP(value, value >= 10 ? 2 : 3);
      productPriceItems[index] = item;
    }
    setProductPricesValue(productPriceItems);

    if (onBindingValue) {
      onBindingValue(productPriceItems);
    }
  };

  return (
    <React.Fragment>
      <div className='product-prices-header'> Product Prices </div>
      {(products?.length <= 0 || !products) && (
        <div className='product-prices-empty'>No products found for this organisation</div>
      )}
      {productPricesValue?.length < 1 && readOnly && <div className='no-data-found-text'>No product prices found</div>}
      {!!productPricesValue &&
        productPricesValue.map((item, i) => {
          return (
            <Item key={i} className='product-prices-box'>
              {!readOnly && productPricesValue?.length >= 1 && (
                <div className='remove-product-container'>
                  <Link className='remove-product-prices' underline='hover' onClick={() => handleRemoveClick(i)}>
                    - Remove Product
                  </Link>
                </div>
              )}
              {!!products && (
                <div className='product-prices-form'>
                  <DropDown
                    key={`product${i}`}
                    name={`product${i}`}
                    value={!!item.id ? item.id : DEFAULT_DROP_DOWN_VALUE}
                    onBindingValue={(newvalue: KeyValuePair) => {
                      handleNewValue(newvalue, i);
                    }}
                    label={'Product'}
                    keyValuePair={products}
                    isMandatory={true}
                    validateCounter={validationFlag}
                    autoFocus={focusPriceNumber}
                    readOnly={readOnly}
                    disabledKeys={productPrices.map((x) => {
                      return x.id;
                    })}
                  />

                  <CustomTextField
                    key={`price${i}`}
                    label={'Price'}
                    placeholder={'Enter Price'}
                    onBindingValue={(newvalue: KeyValuePair) => {
                      handleNewValue(newvalue, i);
                    }}
                    type={TextFieldType.DECIMAL}
                    name={`price${i}`}
                    value={item.price}
                    validateCounter={validationFlag}
                    isMandatory={true}
                    maxCharLength={5}
                    maxRange={item?.price && Number(item?.price) >= 10 ? 99.99 : 9.999}
                    minRange={item?.price && Number(item?.price) >= 10 ? 0.01 : 0.001}
                    readOnly={readOnly}
                    onFieldLostFocus={() => onFieldLostFocusHandler(item.price, i)}
                  ></CustomTextField>
                </div>
              )}
            </Item>
          );
        })}
      {!readOnly && (!productPricesValue || productPricesValue?.length < products?.length) && (
        <Link className='add-product-prices' underline='hover' onClick={handleAddClick}>
          + Add Product
        </Link>
      )}
    </React.Fragment>
  );
};

export default ProductPriceConfiguration;
