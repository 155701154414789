import { all, fork } from 'redux-saga/effects';

//import module sagas here
import { rootSaga as SiteRootSaga } from './sites/sagas';
import { rootSaga as NavPaneSaga } from './nav-pane/sagas';
import { rootSaga as PumpSagas } from './pump/sagas';
import { rootSaga as TerminalsSaga } from './terminal/sagas';
import { rootSaga as ProductsSaga } from './product/sagas';
import { rootSaga as ModalsSaga } from './modals/sagas';
import { rootSaga as SnackBarSaga } from './snackbar/sagas';
import { rootSaga as AuthSagas } from './auth/sagas';
import { rootSaga as FieldValidationSagas } from './fieldValidation/sagas';
import { rootSaga as PriceScheduleSagas } from './price-schedules/sagas';
import { rootSaga as TransactionSaga } from './transaction/sagas';
import { rootSaga as HeaderSaga } from './header/sagas';
import { rootSaga as TanksSaga } from './tanks/sagas';
import { rootSaga as TankHistorySaga } from './tank-histories/sagas';
import { rootSaga as TankDeliverySaga } from './tank-deliveries/sagas';
import { rootSaga as Organisationsaga } from './organisations/sagas';
import { rootSaga as UsersSaga } from './users/sagas';
import { rootSaga as PermssionGroupSaga } from './permission-group/sagas';
import { rootSaga as DialogBoxSaga } from './dialog-box/sagas';
import { rootSaga as PageConfigurationSaga } from './page-configuration/sagas';
import { rootSaga as BackDropSaga } from './backdrop/sagas';
import { rootSaga as BinRangeSaga } from './bin-ranges/sagas';
import { rootSaga as PaginationSaga } from './pagination/sagas';
import { rootSaga as TerminalFirmwareSaga } from './terminal-firmwares/sagas';
import { rootSaga as TerminalOperationSaga } from './terminal-operations/sagas';
import { rootSaga as AzureMapSaga } from './azure-map/sagas';
import { rootSaga as CardSage } from './cards/sagas';
import { rootSaga as ReceiptTemplateSaga } from './receipt-template/sagas';
import { rootSaga as ReportSaga } from './reports/sagas';
import { rootSaga as CardOperationSaga } from './card-operations/sagas';
import { rootSaga as PriceSignSaga } from './price-signs/sagas';
import { rootSaga as ActivityLogSaga } from './activity-log/sagas';
import { rootSaga as EventSaga } from './event/sagas';
import { rootSaga as AlertSaga } from './alert/sagas';
import { rootSaga as AlertRuleSaga } from './alert-rules/sagas';
import { rootSaga as AlertUserGroupSaga } from './alert-usergroups/sagas';
import { rootSaga as ForecourtControllerSaga } from './forecourt-controllers/sagas';

export default function* rootSaga() {
  //add you saga module here
  yield all([
    fork(SiteRootSaga),
    fork(NavPaneSaga),
    fork(PumpSagas),
    fork(TerminalsSaga),
    fork(ProductsSaga),
    fork(ModalsSaga),
    fork(SnackBarSaga),
    fork(AuthSagas),
    fork(FieldValidationSagas),
    fork(PriceScheduleSagas),
    fork(TransactionSaga),
    fork(HeaderSaga),
    fork(Organisationsaga),
    fork(TanksSaga),
    fork(TankHistorySaga),
    fork(TankDeliverySaga),
    fork(UsersSaga),
    fork(PermssionGroupSaga),
    fork(DialogBoxSaga),
    fork(PageConfigurationSaga),
    fork(BackDropSaga),
    fork(BinRangeSaga),
    fork(PaginationSaga),
    fork(TerminalFirmwareSaga),
    fork(TerminalOperationSaga),
    fork(AzureMapSaga),
    fork(CardSage),
    fork(ReceiptTemplateSaga),
    fork(ReportSaga),
    fork(CardOperationSaga),
    fork(PriceSignSaga),
    fork(ActivityLogSaga),
    fork(EventSaga),
    fork(AlertSaga),
    fork(AlertRuleSaga),
    fork(AlertUserGroupSaga),
    fork(ForecourtControllerSaga),
  ]);
}
