import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducers';
import { selectFieldValidation } from './../../store/fieldValidation/selectors';
import * as actions from '../../store/snackbar/actions';
import CopyTextButton from './copy-text';

const mapStateToProps = (state: RootState) => {
  return {
    fieldValidationStatus: (name: string) => selectFieldValidation(name)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    displaySuccessSnackbar: (validation: string) =>
      dispatch({ type: actions.DISPLAY_SUCCESS_SNACKBAR, payload: validation }),
  };
};

const CopyText = connect(mapStateToProps, mapDispatchToProps)(CopyTextButton);

export default CopyText;
