import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { PermissionGroupItemsModel } from '../../../models/permissionGroupModel';
import { UserPermissionModel } from '../../../models/userPermissionModel';
import { FormActionType } from '../../../constants/form-constants';
import { ModuleName } from '../../../constants/module-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import Form from '../../../components/form/form.container';
import Card from '../../../components/cards/cards';
import SwitchList from '../../../components/switch-list/switch-list';
import SearchBar from '../../../components/search-bar/search-bar';
import EmptyList from '../../../components/empty-list/empty-list';
import CustomTextField from '../../../components/text-field/text-field.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import '../styles/permission-group.scss';

interface PermissionGroupPageProps {
  pageTitle?: string;
  action?: string;
  hasValidationError: boolean;
  permissionGroupStatus: string;
  permissionGroupInfo: PermissionGroupItemsModel;
  selectedOrganisationId: string;
  userPermissions: UserPermissionModel[];
  backDropActionStatus: string;
  loadPermissionGroupInfo: (id?: string) => void;
  editPermissionGroupInfo: (data: PermissionGroupItemsModel) => void;
  createPermissionGroupInfo: (data: PermissionGroupItemsModel) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
}

const PermissionGroup: React.FC<PermissionGroupPageProps> = (props: PermissionGroupPageProps) => {
  const {
    pageTitle,
    action,
    hasValidationError,
    permissionGroupStatus,
    permissionGroupInfo,
    userPermissions,
    backDropActionStatus,
    loadPermissionGroupInfo,
    editPermissionGroupInfo,
    createPermissionGroupInfo,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
  } = props;

  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const emptyListMessage = 'No permissions found';
  const searchInputRef = useRef<HTMLInputElement>(null!);
  const isLoadPermissionGroupInfoRequested = useRef(false);

  const [enabledPermissions, setEnabledPermissions] = useState(['']);
  const [displayUserPermissions, setDisplayUserPermissions] = useState([] as UserPermissionModel[]);
  const [responseUserPermissions, setResponseUserPermissions] = useState([] as UserPermissionModel[]);
  const [permissionGroupInfoValue, setpermissionGroupInfoValue] = useState({} as PermissionGroupItemsModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [onSave, setOnSave] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, groupId } = useParams();

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);

  const viewPageDisplayePermissionGroups = permissionGroupInfoValue?.permissionNames?.map((permission) => {
    return permissionGroupInfoValue?.userPermissions?.find((it) => it.name === permission);
  }) as UserPermissionModel[];

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PERMISSION_GROUP).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PERMISSION_GROUP).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PERMISSION_GROUP).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(permissionGroupStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(permissionGroupStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(permissionGroupStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, permissionGroupStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (
      !isLoadPermissionGroupInfoRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadPermissionGroupInfo(groupId);
      setIsSaveButtonEnabled(hasUpdateAccess);
      isLoadPermissionGroupInfoRequested.current = true;
    } else if (
      !isLoadPermissionGroupInfoRequested.current &&
      action === FormActionType.CREATE &&
      hasCreateAccess &&
      groupId
    ) {
      loadPermissionGroupInfo(groupId);
      isLoadPermissionGroupInfoRequested.current = true;
    }
    setIsSaveButtonEnabled(hasCreateAccess);
  }, [
    loadPermissionGroupInfo,
    setIsPageDirty,
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    groupId,
    isClickFromViewPage,
  ]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useMemo(() => {
    if (!!permissionGroupInfo && action === FormActionType.CREATE) {
      setpermissionGroupInfoValue((prev) => ({
        ...prev,
        permissionNames: permissionGroupInfo.permissionNames,
        userPermissions: permissionGroupInfo.userPermissions,
      }));
    } else if (!!permissionGroupInfo) {
      setpermissionGroupInfoValue(permissionGroupInfo);
    }
  }, [permissionGroupInfo, action]);

  useEffect(() => {
    setEnabledPermissions([]);
    //Initial load
    switch (action) {
      case FormActionType.CREATE:
        if (groupId && !!permissionGroupInfo?.userPermissions) {
          setDisplayUserPermissions(permissionGroupInfo?.userPermissions as UserPermissionModel[]);
          setResponseUserPermissions(permissionGroupInfo?.userPermissions);
          permissionGroupInfo?.permissionNames?.map((items) => setEnabledPermissions((current) => [...current, items]));
        } else {
          setDisplayUserPermissions(userPermissions);
          setResponseUserPermissions(userPermissions);
          setpermissionGroupInfoValue({} as PermissionGroupItemsModel);
        }
        removeAllValidation();
        break;
      case FormActionType.EDIT:
        if (!!permissionGroupInfo?.userPermissions) {
          setDisplayUserPermissions(permissionGroupInfo?.userPermissions as UserPermissionModel[]);
          setResponseUserPermissions(permissionGroupInfo?.userPermissions);
          //GET enabled permissions on load
          // eslint-disable-next-line array-callback-return
          permissionGroupInfo?.permissionNames?.map((items) => {
            setEnabledPermissions((current) => [...current, items]);
          });
        }
        break;
      case FormActionType.VIEW:
        if (!!permissionGroupInfo?.userPermissions) {
          setDisplayUserPermissions(viewPageDisplayePermissionGroups);
          setResponseUserPermissions(permissionGroupInfo?.userPermissions);
          //GET enabled permissions on load
          // eslint-disable-next-line array-callback-return
          permissionGroupInfo?.permissionNames?.map((items) => {
            setEnabledPermissions((current) => [...current, items]);
          });
        }
        break;
      default:
        setpermissionGroupInfoValue({} as PermissionGroupItemsModel);
        setEnabledPermissions([]);
        break;
    }
  }, [setDisplayUserPermissions, removeAllValidation, userPermissions, permissionGroupInfo, action]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setIsPageDirty(false);
    if (hasReadAccess && (action === FormActionType.EDIT || action === FormActionType.VIEW)) {
      setIsSaveButtonEnabled(hasUpdateAccess);
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setIsSaveButtonEnabled(hasCreateAccess);
    }
  }, [hasCreateAccess, hasUpdateAccess, hasReadAccess, action, setIsPageDirty]);

  useEffect(() => {
    if (onSave) {
      setValidateCounterFlag((prev) => ++prev);
      if (!hasValidationError) {
        switch (action) {
          case FormActionType.EDIT:
            editPermissionGroupInfo(permissionGroupInfoValue);
            break;
          case FormActionType.CREATE:
            createPermissionGroupInfo(permissionGroupInfoValue);
            break;
        }
        setValidateCounterFlag(0);
      }
      setOnSave(false); //Reset state
    }
  }, [onSave, enabledPermissions]); /* NOTE: onSave is ONLY being updated every click of onSaveClickHandler. 
  DO NOT add other dependencies to avoid re-rendering of this section. 
  enabledPermissions is added to notify that this has been updated on search and toggle. */

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/permission-groups`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  const updatePayloadPermissions = () => {
    let authUsersPermissions = [...(responseUserPermissions as UserPermissionModel[])];

    if (!!authUsersPermissions && authUsersPermissions?.length > 0) {
      for (let i = 0; i < authUsersPermissions.length; i++) {
        let item = { ...authUsersPermissions[i] };
        let item_enabled: boolean = false;

        if (!!enabledPermissions && enabledPermissions?.length > 0) {
          enabledPermissions
            ?.filter((permissions) => permissions === authUsersPermissions[i]?.name)
            // eslint-disable-next-line array-callback-return
            ?.map((data) => {
              item_enabled = true;
            });
        }
        item.enabled = item_enabled;
        authUsersPermissions[i] = item;
      }
    }
    setpermissionGroupInfoValue((prev) => ({
      ...prev,
      userPermissions: authUsersPermissions,
    }));

    setOnSave(true);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);

    if (!hasValidationError) {
      updatePayloadPermissions();
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setpermissionGroupInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/permission-groups/${groupId}/details/edit`);
  };

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const onSearchKeyUpHandler = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      let enteredSearchValue = searchInputRef.current?.value.trim();
      let authUsersPermissions = [...(responseUserPermissions as UserPermissionModel[])];
      if (action === FormActionType.VIEW) authUsersPermissions = viewPageDisplayePermissionGroups;

      if (!!authUsersPermissions && authUsersPermissions?.length > 0) {
        for (let i = 0; i < authUsersPermissions.length; i++) {
          let item = { ...authUsersPermissions[i] };
          let item_enabled: boolean = false;

          if (!!enabledPermissions && enabledPermissions?.length > 0) {
            enabledPermissions
              ?.filter((permissions) => permissions === authUsersPermissions[i]?.name)
              // eslint-disable-next-line array-callback-return
              ?.map((data) => {
                item_enabled = true;
              });
          }

          item.enabled = item_enabled;
          authUsersPermissions[i] = item;
        }
      }

      const listFiltered: UserPermissionModel[] = !!authUsersPermissions
        ? authUsersPermissions?.filter((x) => x.name.toLowerCase().includes(enteredSearchValue.toLowerCase()))
        : [];

      setDisplayUserPermissions(listFiltered);

      window.scrollTo(0, 0);
    },
    [action, enabledPermissions, responseUserPermissions, viewPageDisplayePermissionGroups]
  );

  const onToggleChange = useCallback(
    (newvalue: KeyValuePair, index: number) => {
      setIsPageDirty(isSaveButtonEnabled);
      let permissions = [...(displayUserPermissions as UserPermissionModel[])];

      //UPDATE TOGGLE SWITCH
      if (!!permissions[index]) {
        //UPDATE permissions list by index
        let item = { ...permissions[index] };
        let toggleStatus = newvalue.value as boolean;
        item.enabled = toggleStatus;

        permissions[index] = item;

        //Binds value in toggle since this is being used for display
        setDisplayUserPermissions(permissions);

        if (toggleStatus) {
          //PUSH selected items in a list
          setEnabledPermissions((current) => [...current, item?.name]);
        } else {
          //REMOVE from list
          const unselectedPermissionsList = enabledPermissions.filter((items) => items !== item?.name);
          setEnabledPermissions(unselectedPermissionsList);
        }
      }
    },
    [displayUserPermissions, enabledPermissions, isSaveButtonEnabled, setIsPageDirty]
  );

  const MemoPermissions = useMemo(() => {
    return (
      <SwitchList
        onChangeValue={onToggleChange}
        items={displayUserPermissions}
        readOnly={!isUpdatingForm}
        showSwitch={isUpdatingForm}
      />
    );
  }, [displayUserPermissions, isUpdatingForm]); //NOTE: do not add onToggleChange as dependencies, it will reRender this everytime the value change

  return (
    <>
      <Form
        displayLoadingIndicator={
          (action === FormActionType.VIEW ||
            action === FormActionType.EDIT ||
            (action === FormActionType.CREATE && !!groupId)) &&
          loading
        }
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/permission-groups`}
        isClickFromViewPage={isClickFromViewPage}
      >
        <CustomTextField
          className='name'
          validateCounter={validateCounterFlag}
          isMandatory={true}
          maxCharLength={50}
          minCharLength={3}
          key='name'
          onBindingValue={onTextChangeHandler}
          label='Group Name'
          placeholder='Enter Group Name'
          name='name'
          value={permissionGroupInfoValue?.name}
          type='input'
          readOnly={!isUpdatingForm}
        />

        <CustomTextField
          className='desc'
          validateCounter={validateCounterFlag}
          isMandatory={false}
          maxCharLength={250}
          key='description'
          onBindingValue={onTextChangeHandler}
          label='Description'
          placeholder='Enter Description'
          name='description'
          value={permissionGroupInfoValue?.description}
          type='input'
          readOnly={!isUpdatingForm}
        />
        <div className='permission-header'>{'Permissions'}</div>
        <>
          <div className='permission-groups__search-bar'>
            <SearchBar placeholder={'Search Permission'} onKeyUp={onSearchKeyUpHandler} ref={searchInputRef} />
          </div>
          {displayUserPermissions?.length === 0 && <EmptyList message={emptyListMessage}></EmptyList>}
          {!!displayUserPermissions && displayUserPermissions?.length > 0 && (
            <Card className={'permission-card'}>{MemoPermissions}</Card>
          )}
        </>
      </Form>
    </>
  );
};

export default PermissionGroup;
