import { initialState } from './terminalOperationInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { TerminalDirectoryModel, TerminalOperationsModel } from '../../models/terminalOperationModel';

const terminalOperationSlice = createSlice({
  name: 'terminalOperationState',
  initialState,
  reducers: {
    setTerminalOperationStatus: (state, action: PayloadAction<string>) => {
      state.terminalOperationStatus = action.payload;
      state.hasError = false;
    },
    setTerminalDirectoryStatus: (state, action: PayloadAction<string>) => {
      state.terminalDirectoryStatus = action.payload;
      state.hasError = false;
    },
    setTerminalOperationData: (state, action: PayloadAction<TerminalOperationsModel>) => {
      state.terminalOperatonsData = action.payload;
      state.hasError = false;
    },
    setTerminalDirectoryData: (state, action: PayloadAction<TerminalDirectoryModel>) => {
      state.terminalDirectoryData = action.payload;
      state.hasError = false;
    },
    setTerminalDirectoryList: (state, action: PayloadAction<TerminalDirectoryModel[]>) => {
      state.terminalDirectoryList = action.payload;
      state.hasError = false;
    },
    setTerminalOperationError: (state) => {
      state.hasError = true;
      state.terminalOperatonsData = {} as TerminalOperationsModel;
      state.terminalOperationStatus = LoadingStatus.ERROR;
    },
    resetTerminalOperationState: () => {
      return initialState;
    },
  },
});

export const {
  setTerminalOperationStatus,
  setTerminalDirectoryStatus,
  setTerminalOperationData,
  setTerminalDirectoryData,
  setTerminalDirectoryList,
  setTerminalOperationError,
  resetTerminalOperationState,
} = terminalOperationSlice.actions;
export default terminalOperationSlice.reducer;
