import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { selectNavPaneStatus } from './../../store/nav-pane/selectors';
import { RootState } from '../../store/rootReducers';
import { selectPermissionGroupContent, selectPermissionGroupStatus } from '../../store/permission-group/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as actions from '../../store/permission-group/actions';
import * as headerActions from '../../store/header/actions';
import PermissionGroup from './permission-group-list';

const mapStateToProps = (state: RootState) => {
  return {
    permissiongroupContent: selectPermissionGroupContent(state),
    permissiongroupStatus: selectPermissionGroupStatus(state),
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadPermissionGroup: () => dispatch({ type: actions.LOAD_PERMISSIONSGROUP }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    deletePermissionItem: (id: string) => dispatch({ type: actions.DELETE_PERMISSIONSGROUP, payload: id }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const PermissionGroupPage = connect(mapStateToProps, mapDispatchToProps)(PermissionGroup);

export default PermissionGroupPage;
