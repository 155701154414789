import React, { useEffect, useState } from 'react';
import { Calibration, CalibrationPoint, TankGauge } from '../../../models/tankModel';
import { TextFieldType } from '../../../constants/textfield-constants';
import {
  currentUnitOfMeasure,
  distanceUnitOfMeasure,
  tankGuagingSensorPositionOption,
} from '../../../constants/dropdown-constants';
import { getMeasureSign } from '../../../utilities/general-helper';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomTextFieldWithDropdown from '../../../components/text-field-with-dropdown/text-field-with-dropdowncontainer';
import CollapsibleList from '../../../components/collapsible-list/collapsible-list';
import DropDown from '../../../components/dropdown/dropdown.container';
import FullTankImg from './full-tank-img';
import FullTankWithBottomSensorImg from './full-tank-with-bottom-sensor-img';
import EmptyTankImg from './empty-tank-img';
import EmptyTankWithBottomSensorImg from './empty-tank-with-bottom-sensor-img';
import '../styles/current-loop-sensor.scss';

interface CurrentLoopSensorProps {
  gauges: TankGauge[];
  validateCounter: number;
  readOnly?: boolean;
  specificChannel?: number;
  onBindingValue?: (newvalue: Calibration, gaugeIndex: number) => void | undefined;
}

const CurrentLoopSensor: React.FC<CurrentLoopSensorProps> = (props: CurrentLoopSensorProps) => {
  const { gauges, validateCounter, readOnly, specificChannel, onBindingValue } = props;
  const [validationFlag, setValidationFlag] = useState(0);
  const [currentLoopCalibrationValue, setCurrentLoopCalibrationValue] = useState({} as Calibration);
  const [currentGaugeIndex, setCurrentGaugeIndex] = useState(specificChannel ? specificChannel : 0);
  const [currentSensorPosition, setCurrentSensorPostion] = useState('top');
  const [fullTankPointValue, setFullTankPointValue] = useState({
    current: gauges[currentGaugeIndex]?.calibration?.points[0]?.current
      ? gauges[currentGaugeIndex]?.calibration?.points[0]?.current
      : '20',
    correspondingValue: gauges[currentGaugeIndex]?.calibration?.points[0]?.correspondingValue
      ? gauges[currentGaugeIndex]?.calibration?.points[0]?.correspondingValue
      : undefined,
  } as CalibrationPoint);
  const [emptyTankPointValue, setEmptyTankPointValue] = useState({
    current: gauges[currentGaugeIndex]?.calibration?.points[1]?.current
      ? gauges[currentGaugeIndex]?.calibration?.points[1]?.current
      : '20',
    correspondingValue: gauges[currentGaugeIndex]?.calibration?.points[1]?.correspondingValue
      ? gauges[currentGaugeIndex]?.calibration?.points[1]?.correspondingValue
      : undefined,
  } as CalibrationPoint);

  useEffect(() => {
    if (specificChannel !== undefined && specificChannel > -1) setCurrentGaugeIndex(specificChannel);
  }, [specificChannel]);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    if (gauges && gauges?.length > 0) {
      setCurrentLoopCalibrationValue(gauges[currentGaugeIndex]?.calibration);
      setCurrentSensorPostion(gauges[currentGaugeIndex]?.sensorPosition);
      if (
        fullTankPointValue?.correspondingValue !== gauges[currentGaugeIndex]?.calibration?.points[0]?.correspondingValue
      ) {
        let textElement = document.getElementById('full-tank-distance');
        if (textElement) {
          textElement.textContent =
            String(gauges[currentGaugeIndex]?.calibration?.points[0]?.correspondingValue) +
            ' ' +
            getMeasureSign(gauges[currentGaugeIndex]?.calibration?.correspondingValueUnitOfMeasure);
        }
      }
      if (
        emptyTankPointValue?.correspondingValue !==
        gauges[currentGaugeIndex]?.calibration?.points[1]?.correspondingValue
      ) {
        let textElement = document.getElementById('empty-tank-distance');
        if (textElement) {
          textElement.textContent =
            String(gauges[currentGaugeIndex]?.calibration?.points[1]?.correspondingValue) +
            ' ' +
            getMeasureSign(gauges[currentGaugeIndex]?.calibration?.correspondingValueUnitOfMeasure);
        }
      }
    } else {
      setCurrentLoopCalibrationValue({
        currentUnitOfMeasure: 'milliAmpere',
        correspondingValueUnitOfMeasure: 'millimetre',
        points: [
          {
            current: '20',
          },
          {
            current: '4',
          },
        ],
      } as Calibration);
    }
  }, [gauges, currentGaugeIndex]);

  useEffect(() => {
    let newCalibrationValue = currentLoopCalibrationValue;
    if (newCalibrationValue && newCalibrationValue?.points && newCalibrationValue?.points?.length > 0) {
      setCurrentLoopCalibrationValue((prevValue) => {
        let newCalibrationValue = { ...prevValue };
        let updatedPoints = [...newCalibrationValue.points];
        let updatedPoint = { ...updatedPoints[0] };
        updatedPoint = fullTankPointValue;
        updatedPoints[0] = updatedPoint;
        newCalibrationValue.points = updatedPoints;
        setCurrentLoopCalibrationValue(newCalibrationValue);
        if (onBindingValue) onBindingValue(newCalibrationValue, currentGaugeIndex);
        return newCalibrationValue;
      });
    }
  }, [fullTankPointValue]);

  useEffect(() => {
    let newCalibrationValue = currentLoopCalibrationValue;
    if (newCalibrationValue && newCalibrationValue?.points && newCalibrationValue?.points?.length > 0) {
      setCurrentLoopCalibrationValue((prevValue) => {
        let newCalibrationValue = { ...prevValue };
        let updatedPoints = [...newCalibrationValue.points];
        let updatedPoint = { ...updatedPoints[1] };
        updatedPoint = emptyTankPointValue;
        updatedPoints[1] = updatedPoint;
        newCalibrationValue.points = updatedPoints;
        setCurrentLoopCalibrationValue(newCalibrationValue);
        if (onBindingValue) onBindingValue(newCalibrationValue, currentGaugeIndex);
        return newCalibrationValue;
      });
    }
  }, [emptyTankPointValue]);

  const onTextChangeHandler = (newvalue: KeyValuePair) => {
    if (newvalue.key === 'distanceFromFullTank') {
      let textElement = document.getElementById('full-tank-distance');
      if (textElement) {
        textElement.textContent =
          Number(newvalue.value) + ' ' + getMeasureSign(currentLoopCalibrationValue?.correspondingValueUnitOfMeasure);
        setFullTankPointValue((prevstate) => {
          return {
            current: prevstate.current,
            correspondingValue: newvalue?.value !== undefined ? Number(newvalue?.value) : undefined,
          };
        });
      }
    } else if (newvalue.key === 'distanceFromEmptyTank') {
      let textElement = document.getElementById('empty-tank-distance');
      if (textElement) {
        textElement.textContent =
          Number(newvalue.value) + ' ' + getMeasureSign(currentLoopCalibrationValue?.correspondingValueUnitOfMeasure);
        setEmptyTankPointValue((prevstate) => {
          return {
            current: prevstate.current,
            correspondingValue: newvalue?.value !== undefined ? Number(newvalue?.value) : undefined,
          };
        });
      }
    } else if (newvalue.key === 'currentOfFullTank') {
      setFullTankPointValue((prevstate) => {
        return {
          ...prevstate,
          current: String(newvalue?.value),
        };
      });
    } else if (newvalue.key === 'currentOfEmptyTank') {
      setEmptyTankPointValue((prevstate) => {
        return {
          ...prevstate,
          current: String(newvalue?.value),
        };
      });
    } else if (newvalue.key === 'sensorPosition') {
      setCurrentSensorPostion(String(newvalue.value));
    }
  };

  useEffect(() => {
    let fullTankTextElement = document.getElementById('full-tank-distance');
    if (fullTankTextElement && fullTankPointValue.correspondingValue) {
      fullTankTextElement.textContent =
        fullTankPointValue?.correspondingValue +
        ' ' +
        getMeasureSign(currentLoopCalibrationValue?.correspondingValueUnitOfMeasure);
    }

    let emptyTanktextElement = document.getElementById('empty-tank-distance');
    if (emptyTanktextElement && emptyTankPointValue?.correspondingValue) {
      emptyTanktextElement.textContent =
        emptyTankPointValue?.correspondingValue +
        ' ' +
        getMeasureSign(currentLoopCalibrationValue?.correspondingValueUnitOfMeasure);
    }
  }, [currentSensorPosition]);

  return (
    <div className='current-loop-sensor-form'>
      <DropDown
        validateCounter={validationFlag}
        key='sensorPosition'
        name='sensorPosition'
        onBindingValue={onTextChangeHandler}
        value={currentSensorPosition ? currentSensorPosition : 'top'}
        label='Sensor Position'
        keyValuePair={tankGuagingSensorPositionOption}
        readOnly={readOnly}
        hideDefaultSelect={true}
      />

      <CollapsibleList
        key={'fullTank'}
        label={'Full Tank'}
        expanded={true}
        children={
          <>
            <div className='full-tank-loop-sensor'>
              <CustomTextFieldWithDropdown
                validateCounterFlag={validationFlag}
                isMandatory={
                  gauges?.filter((it) => {
                    return it.type === 'currentLoop';
                  })?.length > 0
                }
                key='distanceFromFullTank'
                label='Distance From Sensor'
                inlineLabel='Distance From Sensor'
                fieldPlaceHolder='Enter Distance From Sensor to Full Tank'
                onFiledChangeHandler={onTextChangeHandler}
                filedName='distanceFromFullTank'
                fieldValue={
                  fullTankPointValue?.correspondingValue !== undefined ? fullTankPointValue?.correspondingValue : ''
                }
                fieldType={TextFieldType.INTEGER}
                fieldMinRange={0}
                fieldMaxRange={9999999}
                fieldMaxCharLength={7}
                readOnly={readOnly}
                dropdownKey='unitOfMeasure'
                dropdownName='unitOfMeasure'
                dropdownValue={currentLoopCalibrationValue?.correspondingValueUnitOfMeasure}
                dropdownKeyValuePair={distanceUnitOfMeasure}
                dropdownErrorMsg={false}
                onDropDownChangeHandler={onTextChangeHandler}
              ></CustomTextFieldWithDropdown>
              <CustomTextFieldWithDropdown
                validateCounterFlag={validationFlag}
                isMandatory={false}
                key='currentOfFullTank'
                label='Current'
                inlineLabel='Current'
                fieldPlaceHolder='Enter Full Tank Current'
                onFiledChangeHandler={onTextChangeHandler}
                filedName='currentOfFullTank'
                fieldValue={fullTankPointValue?.current}
                fieldType={TextFieldType.DECIMAL}
                fieldMinRange={4}
                fieldMaxRange={20}
                fieldMaxCharLength={5}
                readOnly={readOnly}
                dropdownKey='unitOfMeasure'
                dropdownName='unitOfMeasure'
                dropdownValue={currentLoopCalibrationValue?.currentUnitOfMeasure}
                dropdownKeyValuePair={currentUnitOfMeasure}
                dropdownErrorMsg={false}
                onDropDownChangeHandler={onTextChangeHandler}
              ></CustomTextFieldWithDropdown>
              {currentSensorPosition === 'bottom' ? <FullTankWithBottomSensorImg /> : <FullTankImg />}
            </div>
          </>
        }
      ></CollapsibleList>

      <CollapsibleList
        key={'emptyTank'}
        label={'Empty Tank'}
        expanded={true}
        children={
          <>
            <div className='empty-tank-loop-sensor'>
              <CustomTextFieldWithDropdown
                validateCounterFlag={validationFlag}
                isMandatory={
                  gauges?.filter((it) => {
                    return it.type === 'currentLoop';
                  })?.length > 0
                }
                key='distanceFromEmptyTank'
                label='Distance From Sensor'
                inlineLabel='Distance From Sensor'
                fieldPlaceHolder='Enter Distance From Sensor to Empty Tank'
                onFiledChangeHandler={onTextChangeHandler}
                filedName='distanceFromEmptyTank'
                fieldValue={
                  emptyTankPointValue?.correspondingValue !== undefined ? emptyTankPointValue?.correspondingValue : ''
                }
                fieldType={TextFieldType.INTEGER}
                fieldMinRange={0}
                fieldMaxRange={9999999}
                fieldMaxCharLength={7}
                readOnly={readOnly}
                dropdownKey='unitOfMeasure'
                dropdownName='unitOfMeasure'
                dropdownValue={currentLoopCalibrationValue?.correspondingValueUnitOfMeasure}
                dropdownKeyValuePair={distanceUnitOfMeasure}
                dropdownErrorMsg={false}
                onDropDownChangeHandler={onTextChangeHandler}
              ></CustomTextFieldWithDropdown>
              <CustomTextFieldWithDropdown
                validateCounterFlag={validationFlag}
                isMandatory={false}
                key='currentOfEmptyTank'
                label='Current'
                inlineLabel='Current'
                fieldPlaceHolder='Enter Empty Tank Current'
                onFiledChangeHandler={onTextChangeHandler}
                filedName='currentOfEmptyTank'
                fieldValue={emptyTankPointValue?.current}
                fieldType={TextFieldType.DECIMAL}
                fieldMinRange={4}
                fieldMaxRange={20}
                fieldMaxCharLength={5}
                readOnly={readOnly}
                dropdownKey='unitOfMeasure'
                dropdownName='unitOfMeasure'
                dropdownValue={currentLoopCalibrationValue?.currentUnitOfMeasure}
                dropdownKeyValuePair={currentUnitOfMeasure}
                dropdownErrorMsg={false}
                onDropDownChangeHandler={onTextChangeHandler}
              ></CustomTextFieldWithDropdown>
              {currentSensorPosition === 'bottom' ? <EmptyTankWithBottomSensorImg /> : <EmptyTankImg />}
            </div>
          </>
        }
      ></CollapsibleList>
    </div>
  );
};

export default CurrentLoopSensor;
