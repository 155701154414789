import React, { useCallback, useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { productCodeTypes } from '../../../constants/dropdown-constants';
import { PUMPS_MAX_HOSES } from '../../../constants/pumps-constants';
import { ProductCodeValue, ExternalProductCode } from '../../../models/productModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CollapsibleList from '../../../components/collapsible-list/collapsible-list';
import EditableTable from '../../../components/editable-table/editable-table.container';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import '../styles/product-codes.scss';

interface ProductCodesProps {
  validateCounter: number;
  productCodes: ExternalProductCode[];
  readOnly?: boolean;
  siteListName: KeyValuePair[];
  hasSiteReadAccess: boolean;
  hasSiteCreateAccess: boolean;
  siteStatus: string;
  onBindingValue?: (newvalue: ExternalProductCode[]) => void | undefined;
  removeValidation: (name: string) => void;
  loadSiteNameList: () => void;
}

const ProductCodesComponent: React.FC<ProductCodesProps> = (props: ProductCodesProps) => {
  const {
    productCodes,
    validateCounter,
    readOnly,
    siteListName,
    hasSiteReadAccess,
    hasSiteCreateAccess,
    siteStatus,
    onBindingValue,
    removeValidation,
    loadSiteNameList,
  } = props;
  const [productCodeValue, setProductCodeValue] = useState(productCodes);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);

  const { orgId } = useParams();

  useEffect(() => {
    setValidateCounterFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setProductCodeValue(productCodes);
  }, [productCodes, setProductCodeValue]);

  // handle click event of the Add button
  const handleAddClick = () => {
    setValidateCounterFlag(0);
    let newItem = { values: [{ type: 'wex' } as ProductCodeValue] } as ExternalProductCode;
    let list = [newItem];
    if (productCodeValue !== undefined) {
      list = [...productCodeValue];
      list.push(newItem);
    }
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetHoseListValidation();
    let list = [...productCodeValue];
    list.splice(index, 1);
    setProductCodeValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetHoseListValidation = () => {
    productCodeValue.forEach((_, index) => {
      removeValidation(`sites${index}`);
    });
  };

  const handleNewSitesValue = useCallback(
    (newvalue: KeyValuePair[], index: number) => {
      let productCodeItems = [...productCodeValue];

      if (!!productCodeItems[index]) {
        let item = { ...productCodeItems[index] };

        let sites = newvalue?.map((x) => {
          return x.key.toString();
        });
        let uniqueSites = sites.filter((site, i) => {
          return sites.indexOf(site) === i;
        });
        item.sites = [...uniqueSites];

        productCodeItems[index] = item;
      }

      setProductCodeValue(productCodeItems);
      if (onBindingValue) {
        onBindingValue(productCodeItems);
      }
    },
    [productCodeValue]
  );

  const getSiteName = (siteId: string): string => {
    var siteName = siteListName?.find((c) => {
      return c.key === siteId;
    })?.value;
    return !!siteName ? siteName.toString() : '';
  };

  const defaultData = [
    {
      type: 'default',
      value: '',
    },
  ];

  const tableDataUpdateHandler = (row: any, tableInfo?: number | string) => {
    let productCodeItems = [...productCodeValue];
    if (!!productCodeItems[Number(tableInfo)]) {
      let item = { ...productCodeItems[Number(tableInfo)] };
      item.values = [...row];
      productCodeItems[Number(tableInfo)] = item;
    }

    setProductCodeValue(productCodeItems);
    if (onBindingValue) {
      onBindingValue(productCodeItems);
    }
  };

  const fieldsArr = [
    {
      label: 'Type',
      selectMessage: 'Select',
      name: 'type',
      type: 'select',
      options: productCodeTypes?.map((it) => {
        return {
          label: it.value,
          value: it.key,
        };
      }),
    },
    {
      label: 'Value',
      name: 'value',
      maxCharLength: 3,
      minCharLength: 1,
      maxRange: 999,
      minRange: 1,
    },
  ];

  const avaliableSites = (value: string[]) => {
    const selectedCapabilitis = productCodeValue
      ?.map((it) => {
        return it.sites;
      })
      .flat();

    const avaliableList = siteListName?.filter(
      (item) => !selectedCapabilitis?.includes(String(item.key)) || value?.includes(String(item.key))
    );

    return avaliableList && avaliableList?.length > 0 ? avaliableList : [];
  };

  const handleSitekeyValuePairLoding = () => {
    loadSiteNameList();
  };

  return (
    <div className='product-codes-container'>
      <div className='product-codes-header'>External Product Codes </div>
      {(!productCodeValue || productCodeValue?.length < 1) && readOnly && (
        <div className='no-data-found-text'>No external product codes set</div>
      )}
      {!!productCodeValue &&
        productCodeValue?.map((item, i) => {
          return (
            <CollapsibleList
              key={`${i} ${item?.sites}`}
              label={`Code Value ${i + 1}`}
              expanded={true}
              titleButtonOption={
                !readOnly && (
                  <Link className='remove-product-code' underline='hover' onClick={() => handleRemoveClick(i)}>
                    - Remove
                  </Link>
                )
              }
              children={
                item && (
                  <>
                    {
                      <MultiSelectDropdown
                        validateCounter={validateCounterFlag}
                        dataList={avaliableSites(item?.sites)}
                        label='Sites'
                        name={`sites${i}`}
                        placeholder='Add Sites'
                        noOptionsText={'No Sites Available'}
                        createOptionsLink={
                          hasSiteReadAccess && hasSiteCreateAccess ? `/organisations/${orgId}/sites/create` : ''
                        }
                        value={item?.sites?.map((item) => ({ key: item, value: getSiteName(item) }) as KeyValuePair)}
                        onBindingValue={(value: KeyValuePair[]) => handleNewSitesValue(value, i)}
                        maxHeight={192}
                        isMandatory={true}
                        readOnly={readOnly}
                        keyValuePairLoding={handleSitekeyValuePairLoding}
                        keyValuePairLoadingStatus={siteStatus}
                      ></MultiSelectDropdown>
                    }
                    <EditableTable
                      key={i}
                      tableInfo={i}
                      name='editable-table'
                      initWithoutHead
                      defaultData={
                        item?.values
                          ? item?.values?.map((it) => {
                              return { type: it.type, value: it.value };
                            })
                          : defaultData
                      }
                      tableDataUpdate={tableDataUpdateHandler}
                      fieldsArr={fieldsArr}
                      readOnly={readOnly}
                      displayValueList={productCodeTypes}
                      minimumRows={1}
                    />
                  </>
                )
              }
            ></CollapsibleList>
          );
        })}

      {!readOnly && (!productCodeValue || productCodeValue?.length < PUMPS_MAX_HOSES) && (
        <Link className='add-product-codes' underline='hover' onClick={handleAddClick}>
          + Add Product Code
        </Link>
      )}
    </div>
  );
};

export default ProductCodesComponent;
