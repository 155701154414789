import { DashboardIcon, RetryIcon, HelpdeskIcon } from '../../components/icons';
import './styles/error-display.scss';
import { Link } from '@mui/material';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import BoxItem from '../box-items/box-item';
import { Messages } from '../../constants/messages';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';

interface ErrorDisplayProps {
  className?: string;
  message?: string;
  genericError?: GenericErrorModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
}
const ErrorDisplay: React.FC<ErrorDisplayProps> = (props: ErrorDisplayProps) => {
  const { genericError, setHeaderConfiguration } = props;

  useEffect(() => {
    setHeaderConfiguration({
      title: '',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: false,
      showOrganisation: false,
      error: true,
    } as HeaderStateModel);
  }, [setHeaderConfiguration]);

  return (
    <>
      <div className='error-container'>
        <h1 className='header'>{Messages.GENERIC_ERROR_HEADER}</h1>
        <div className='error-body'>
          <label className='option-label'>Please try one of these options</label>
          <div className='link-container'>
            <Link className='items' href='/' underline='hover'>
              <DashboardIcon />
              <span className='text'>Home</span>
            </Link>
          </div>
          <div className='link-container'>
            <Link className='items' onClick={() => window.location.reload()} underline='hover'>
              <RetryIcon />
              <span className='text'>Retry</span>
            </Link>
          </div>
          <div className='link-container'>
            <Link
              className='items'
              href={'https://www.compac.co.nz/technical-support'}
              target={'_blank'}
              underline='hover'
            >
              <HelpdeskIcon />
              <span className='text'>Help desk</span>
            </Link>
          </div>
          {!!genericError && !!genericError.errors && (
            <div>
              <BoxItem className='details-container'>
                {
                  <div>
                    <div className='error-items'>
                      <div className={classNames('correlation-key', 'item-key')}>Correlation ID:</div>
                      <div className={classNames('correlation-value', 'item-value')}>{genericError.correlationId}</div>
                    </div>
                    <div className='error-items'>
                      <div className={classNames('top-item', 'item-key')}>{'Time (UTC)'}:</div>
                      <div className={classNames('top-item', 'item-value')}>
                        {/* @ts-ignore */}
                        {genericError?.dateTimeUtc || null}
                      </div>
                    </div>
                  </div>
                }
                {genericError.errors.map((items, i) => (
                  <div key={i}>
                    <div className='error-items'>
                      <div className='item-key'>Title:</div>
                      <div className='item-value'>{items?.title}</div>
                    </div>
                    <div className='error-items'>
                      <div className='item-key'>Source:</div>
                      <div className='item-value'>{items?.source}</div>
                    </div>
                    <div className={classNames('error-items', 'bottom-item')}>
                      <div className='item-key'>Description:</div>
                      <div className='item-value'>{items?.detail}</div>
                    </div>
                  </div>
                ))}
              </BoxItem>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ErrorDisplay;
