import { useEffect, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import './styles/line-chart.scss';

interface LineChartProps {
  data?: any;
  options?: any;
  plugins?: any;
  historyChartDisplayData?: any;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

const LineChart = (props: LineChartProps) => {
  const { data, options, plugins } = props;

  const memoizedLineChart = useMemo(() => {
    return <Line options={options} data={data} plugins={plugins} />;
  }, [data, options]);

  return <div className='line-chart-container'>{memoizedLineChart}</div>;
};

export default LineChart;
