import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Modal, Box } from '@mui/material';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { LoadingStatus } from '../../constants/loading-constants';
import LoadingDisplay from '../loading-spinner/loading-display';
import ModalHeader from './modalHeader';
import ModalFooter from './modalFooter';
import classes from './main-modal.module.scss';
import './main-modal.scss';

interface MainModalProps {
  id?: string;
  hasValidationError: boolean;
  open: boolean;
  modalActionStatus: string;
  modalTitle: string;
  modalData: ModalStateModel;
  className?: string;
  footerClassName?: string;
  children: React.ReactNode;
  saveButtonText?: string;
  cancelButtonText?: string;
  closeButtonText: string;
  modalContentClassName?: string;
  modalLoadingClassName?: string;
  showValidationError: boolean;
  saveButtonEnabled?: boolean;
  cancelButtonDisabled?: boolean;
  viewOnlyModal: boolean;
  hideFooter?: boolean;
  hideCancelButton?: boolean;
  hideSaveButton?: boolean;
  displayResetButton?: boolean;
  customHeader?: any;
  enableClickOutsideClose?: boolean;
  onConfirm?: () => void;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSaveButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onResetClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MainModalComponent: React.FC<MainModalProps> = (props: MainModalProps) => {
  const {
    id,
    hasValidationError,
    open,
    children,
    modalActionStatus,
    modalTitle,
    className,
    footerClassName,
    saveButtonText,
    cancelButtonText,
    closeButtonText,
    viewOnlyModal,
    modalContentClassName,
    modalLoadingClassName,
    showValidationError,
    saveButtonEnabled,
    cancelButtonDisabled,
    hideFooter,
    hideCancelButton,
    hideSaveButton,
    displayResetButton,
    customHeader,
    enableClickOutsideClose,
    onClose,
    onSaveButton,
    onConfirm,
    onCancelButton,
    onResetClick,
  } = props;

  const backdropElement = document.getElementById('backdrop-root')!;
  const overlayElement = document.getElementById('overlay-root')!;

  return (
    <>
      {ReactDOM.createPortal(
        //Backdrop
        <div className={classes.backdrop} onClick={onConfirm} />,
        backdropElement
      )}
      {ReactDOM.createPortal(
        <Modal
          id={id}
          className={classNames('modal', className)}
          open={open}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          onClose={enableClickOutsideClose ? onClose : undefined}
        >
          {/** DO NOT DELETE THIS NOTE: Modal children should always be inside of a BOX.*/}
          <Box>
            <div className='update-form'>
              <ModalHeader
                isDisabled={modalActionStatus === LoadingStatus.SUBMITTED}
                modalTitle={modalTitle}
                customHeader={customHeader}
                onClose={onClose}
              />
              <>
                {modalActionStatus === LoadingStatus.LOADING ? (
                  <div className={classNames('loading-container', modalLoadingClassName)}>
                    <LoadingDisplay />
                  </div>
                ) : (
                  <div className={classNames('form-container', modalContentClassName)}>{children}</div>
                )}
              </>
              {!hideFooter && (
                <ModalFooter
                  viewOnlyModal={viewOnlyModal}
                  saveButtonEnabled={modalActionStatus === LoadingStatus.SUBMITTED ? false : saveButtonEnabled}
                  cancelButtonDisabled={modalActionStatus === LoadingStatus.SUBMITTED || cancelButtonDisabled}
                  showValidationError={hasValidationError && showValidationError}
                  cancelButtonText={cancelButtonText}
                  saveButtonText={saveButtonText}
                  closeButtonText={closeButtonText}
                  className={footerClassName}
                  onCancelButton={onCancelButton}
                  onSaveButton={onSaveButton}
                  onClose={onClose}
                  hideCancelButton={hideCancelButton}
                  hideSaveButton={hideSaveButton}
                  displayResetButton={displayResetButton}
                  onReset={onResetClick}
                />
              )}
            </div>
          </Box>
        </Modal>,
        overlayElement
      )}
    </>
  );
};
export default MainModalComponent;
