import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import FooterBar from '../../components/footer/footer';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import './styles/privacy-page.scss';

interface PrivacyPageProps {
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const PrivacyPage: React.FC<PrivacyPageProps> = (props: PrivacyPageProps) => {
  const { setHeaderConfiguration, setPageConfiguration, setIsPageDirty } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setHeaderConfiguration({
      showOrganisation: true,
      title: 'Privacy Policy',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: false,
    } as HeaderStateModel);
  }, [setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    setTimeout(function () {
      setLoading(true);
    }, 200);
  }, []);

  return (
    <>
      {loading && (
        <div className='container'>
          <p className='body'>
            Compac Industries Limited together with its affiliates (including Compac Sales Pty Limited) (collectively
            <b> we</b>, <b> our</b> or <b> us</b> in this Privacy Policy) is committed to maintaining the privacy of its
            customers’ information.
            <br></br>
            <br></br>
            In the course of our business dealings with you we may collect, use, disclose, and hold information about an
            identifiable individual (<b>Personal Information</b>), as well as other business or technical data.
            <br></br>
            <br></br>
            This Privacy Policy describes how we collect, use, store and distribute Personal Information and other data,
            and describes the purposes for which we may use, disclose or hold such Personal Information and other data.
          </p>

          <h2 className='h3'>Information that we collect from you</h2>
          <p className='body'>
            During the course of your relationship with us, your ordering goods or services from us, or your use of our
            CompacOnline fuel management service (<b>CompacOnline</b>), we may collect the following information:
            <br></br>
            <ul>
              <li>
                We will collect personal details during the course of your ordering any hardware, equipment or services
                from us, and during the course of our fulfilling any such orders.
              </li>
              <li>
                Our hardware and equipment may collect user or usage details during the course or as a result of use of
                that hardware and equipment, such as credit and debit card details, user names and contact details, fuel
                transaction information, fuel pricing information, and equipment usage data.
              </li>
              <li>
                We will collect personal details from you during the course of your establishing and maintaining an
                account or user profile with us via CompacOnline, such as your name, user name, passwords, address,
                email address, phone numbers, and payment details.
              </li>
              <li>We will collect data that is inputted or uploaded by users into CompacOnline;</li>
              <li>
                We will collect data related to the use of CompacOnline, including details of the pages visited, the
                reports generated and the data accessed.
              </li>
              <li>
                We may collect other Personal Information and data during the course or as a result of your relationship
                with us, including (without limitation) where necessary to enable us to provide goods and services to
                you or to respond to requests for further information.
              </li>
            </ul>
          </p>

          <h3 className='h3'>How we use Personal Information and other data</h3>
          <p className='body'>
            We will use, disclose and hold Personal Information and data collected by us for the following purposes:
            <br></br>
            <ul>
              <li>
                to enable us and our agents and affiliates to supply any hardware, equipment or services requested by
                you, and to maintain, support and service any such hardware and equipment;
              </li>
              <li>
                to enable us to operate CompacOnline (where applicable) for the benefit of you or your organisation;
              </li>
              <li>to establish and maintain any account you hold with us;</li>
              <li>
                to complete sales transactions, including billing, payment, receipt, credit check and verification
                services;
              </li>
              <li>to respond to your queries or requests for additional information;</li>
              <li>to provide any after-sales service required by you;</li>
              <li>to maintain our records;</li>
              <li>
                to provide technical support and administration services in relation to CompacOnline and any hardware,
                equipment or services ordered by you;
              </li>
              <li>
                to keep you informed about products, services, events, promotions or any other marketing activities, but
                only to the extent permissible under applicable laws, and subject to any other restrictions contained in
                this Privacy Policy;
              </li>
              <li>for product development or research purposes; and</li>
              <li>to evaluate customer satisfaction and the performance of marketing activities.</li>
            </ul>
          </p>

          <h3 className='h3'>Cookies</h3>
          <p className='body'>
            Our websites on which we make available CompacOnline or any other online services or information (
            <b>Websites</b>) may use cookies. “Cookies” are small text files that are placed on computers, devices or
            browsers used to access websites, apps or other internet content. We may use cookies to remember information
            about your personal preferences and user settings for the Websites, to analyse Website traffic and trends,
            and to generally understand the behaviours and interests of people who use the Websites.
            <br></br>
            <br></br>
            Our cookies will only use information about your personal preferences and user settings so that our Websites
            will remember your details next time you visit. We may use, disclose or sell other data collected by us from
            cookies for other purposes, but only on an aggregated basis and in a way that ensures that no individual is
            able to be identified from such information.
          </p>

          <h3 className='h3'>Statistical data that we collect</h3>
          <p className='body'>
            During your use of CompacOnline and our other Websites we may collect statistical data about such use, such
            as the date, time and length of your use, the pages of the Websites that you visit, and information about
            the device you are using to access the Websites. This information may be collected by software operating on
            our Websites, or by third party service providers on our behalf.
            <br></br>
            We may use and disclose such statistical data for the following purposes:
            <ul className='ol'>
              <li>to measure the effectiveness of any services or features provided via the Websites;</li>
              <li>to better direct users to goods or services that might interest them;</li>
              <li>to identify user behaviour and user trends on the Websites;</li>
              <li>
                to maintain and optimise the technical performance, operation and security of any hardware, equipment or
                other services (including CompacOnline and our other Websites) provided by us; and
              </li>
              <li>to assist in resource planning.</li>
            </ul>
            We may disclose or sell such statistical data to others for any purpose, but only on an aggregated basis and
            in a way that ensures that no individual is able to be identified from such data.
          </p>

          <h3 className='h3'>Marketing</h3>
          <p className='body'>
            We may use Personal Information and other data collected by us or via our Websites to send or email to you
            marketing or promotional information about our services or products, or the services or products of other
            companies (<b>Direct Marketing Information</b>), but only if you have expressly given us permission to do
            so.
            <br></br>
            <br></br>
            If you have given us permission to send to you Direct Marketing Information, and you later decide that you
            do not want us to send you any further Direct Marketing Information, you can contact us at any time to
            request that we stop sending you such information. You can either contact our Privacy Officer (see the
            details at the end of this Privacy Policy) or use the “Unsubscribe” facility at the bottom of any Direct
            Marketing Information email or communication that we send you.
            <br></br>
            <br></br>
            We will not sell your Personal Information or other data to direct marketers unless you have expressly given
            us permission to do so.
          </p>

          <h3 className='h3'>Business acquisition</h3>
          <p className='body'>
            We may transfer your Personal Information and other data to another entity in connection with a sale of our
            business or assets, or a merger or consolidation or restructuring of our business or company.
          </p>

          <h3 className='h3'>Disclosure of information to third-parties</h3>
          <p className='body'>
            We will not use your Personal Information and other data, or disclose your Personal Information and other
            data to third parties, except:
            <br></br>
            <ul className='ul'>
              <li>
                to the extent reasonably necessary to achieve any of the purposes described in this Privacy Policy; or
              </li>
              <li>
                where we reasonably believe that such use or disclosure is required or expressly permitted under any
                applicable law.
              </li>
            </ul>
          </p>

          <h3 className='h3'>Holding Personal Information</h3>
          <p className='body'>
            We will not hold your Personal Information and other data for longer than is reasonably required for the
            purposes for which we may lawfully use that Personal Information or data.
          </p>

          <h3 className='h3'>Security</h3>
          <p className='body'>
            We will use all reasonable endeavours to effect and maintain adequate security measures to safeguard your
            Personal Information and other data we hold from loss or unauthorised access, use, modification or
            disclosure. However, as the internet is an open network, we do not warrant or guarantee that your Personal
            Information or other data is safe from loss or unauthorised access, use, modification or disclosure, during
            transmission to or from our Websites or when held by us.
          </p>

          <h3 className='h3'>Transfer of Information</h3>
          <p className='body'>
            We may transfer the information described in this Privacy Policy to or from other countries to the extent
            necessary to enable us to operate CompacOnline and the Websites, and to supply any hardware, equipment or
            services ordered by you. Some of these countries may have less protective privacy or data protection laws
            than the region in which you reside. Where this is the case, we will take appropriate measures to protect
            your Personal Information and other data in accordance with this Privacy Policy.
          </p>

          <h3 className='h3'>Use of third-party websites</h3>
          <p className='body'>
            If you access any third-party websites via a link from any of our Websites, you will leave that Website. By
            accessing these links you are not covered by the policies relating to that Website. We are not responsible
            for the content of any third-party websites, or their use of your Personal Information or other data.
          </p>

          <h3 className='h3'>Your rights to access and correct Personal Information</h3>
          <p className='body'>
            Where we hold Personal Information or other data about you, you have a right to access and correct that
            Personal Information or data in accordance with applicable laws. If you want to access or correct your
            Personal Information or data, please contact our Privacy Officer (see below) and the officer will tell you
            how to make a request and if any charges will apply.
          </p>

          <h3 className='h3'>Amendments to the Privacy Policy</h3>
          <p className='body'>
            We may amend this Privacy Policy from time to time. Notice of any such amendments will be announced on our
            Websites where we have published this Privacy Policy or to CompacOnline users via email or other means. Any
            such amendments will be effective immediately, unless we state otherwise. Your continued use of our Websites
            or CompacOnline after any such notice will constitute your acceptance of any amendments or revisions to this
            Privacy Policy.
            <br></br>
            <br></br>
            You should periodically review this Privacy Policy for the latest information about our privacy practices.
          </p>

          <h3 className='h3'>Contact</h3>
          <p className='body'>
            For any queries or further information about our Privacy Policy, please contact our Privacy Officer via
            email at the address below.
            <br></br>
            <a className='link' target={'_blank'} href={'mailto: privacyofficer@compac.co.nz'} rel='noreferrer'>
              {'privacyofficer@compac.co.nz'}
            </a>
          </p>
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar className={classNames('footer-with-content')} />
        </div>
      }
    </>
  );
};

export default PrivacyPage;
