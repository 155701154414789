import { PermissionGroupItems } from '../../entities/permission-group';
import api, { setAuthorize } from '../../utilities/api';

const USER_API_PREFIX = 'user-management';

export const getAllPermissionGroup = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${USER_API_PREFIX}/users/permission-groups?organisationId=${organisationId}`
  );
  return result.data;
};

export const deletePermissionGroup = async (
  id: string,
  organisationId: string
) => {
  await setAuthorize();
  await api.delete(
    `/${USER_API_PREFIX}/users/permission-groups/${id}?organisationId=${organisationId}`
  );
};

export const editPermissionGroup = async (
  data: PermissionGroupItems,
  id: string
) => {
  await setAuthorize();
  await api.put(`/${USER_API_PREFIX}/users/permission-groups/${id}`, data);
};

export const createPermissionGroup = async (data: PermissionGroupItems) => {
  await setAuthorize();
  await api.post(`/${USER_API_PREFIX}/users/permission-groups`, data);
};

export const getPermissionGroupById = async (
  id: string,
  organisationId: string
) => {
  await setAuthorize();
  let result = await api.get(
    `/${USER_API_PREFIX}/users/permission-groups/${id}?organisationId=${organisationId}`
  );
  return result.data;
};
