import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducers';
import { selectFieldValidation } from '../../store/fieldValidation/selectors';
import { ValidationError } from '../../models/baseModels/validationModel';
import * as fieldActions from '../../store/fieldValidation/actions';
import TextFieldWithSuggestionsControl from './text-field-with-suggestions';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const mapStateToProps = (state: RootState) => {
  return {
    fieldValidationStatus: (name: string) => selectFieldValidation(name)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setFieldValidation: (validation: ValidationError) =>
      dispatch({ type: fieldActions.SET_FIELD_VALIDATION, payload: validation }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
    setShowValidationMessage: (value: KeyValuePair) =>
      dispatch({ type: fieldActions.SET_SHOW_FIELD_VALIDATION, payload: value }),
  };
};

const TextFieldWithSuggestions = connect(mapStateToProps, mapDispatchToProps)(TextFieldWithSuggestionsControl);

export default TextFieldWithSuggestions;
