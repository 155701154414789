import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectPageConfigurationState = (state: RootState) => state.PageConfigurationState;

export const selectPageConfiguration = createSelector(selectPageConfigurationState, (state) => state.pageConfiguration);

export const selectIsPageDirty = createSelector(selectPageConfigurationState, (state) => state.isPageDirty);

export const selectIsPageReachBottom = createSelector(selectPageConfigurationState, (state) => state.isPageReachBottom);

export const selectedIsCancelFromChange = createSelector(
  selectPageConfigurationState,
  (state) => state.isCancelFromChange
);
