import { PumpRecordModel } from '../../models/pumpModel';
import { PumpInfoModel } from '../../models/pumpModel';
import { LoadingStatus } from '../../constants/loading-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

interface PumpState {
  pumpStatus: string;
  pumpDetailStatus: string;
  pumpContent: PumpRecordModel[];
  pumpInfo: PumpInfoModel;
  pumpNumberList: KeyValuePair[];
  hasError: boolean;
}

export const initialState: PumpState = {
  pumpStatus: LoadingStatus.LOADING,
  pumpDetailStatus: LoadingStatus.LOADING,
  hasError: false,
} as PumpState;
