import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData, selectModalActionStatus } from '../../../store/modals/selectors';
import * as modalActions from '../../../store/modals/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as terminalActions from '../../../store/terminal/actions';
import { selectTerminalInfo } from '../../../store/terminal/selectors';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { TerminalInfo, TerminalModel } from '../../../models/terminalModel';
import ReenrolModal from './reenrol-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
    modalActionStatus: selectModalActionStatus(state),
    terminalInfo: selectTerminalInfo(state),
    hasValidationError: selectHasValidationError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    loadTerminalInfo: (data: TerminalModel) => dispatch({ type: terminalActions.LOAD_TERMINALINFO, payload: data }),
    renenrolTerminal: (data: TerminalInfo) => dispatch({ type: terminalActions.REENROL_TERMINAL, payload: data }),
  };
};

const ReenrolModalContainer = connect(mapStateToProps, mapDispatchToProps)(ReenrolModal);

export default ReenrolModalContainer;
