import { ValidationError } from '../../models/baseModels/validationModel';
import { initialState } from './fieldsValidationInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const fieldSlice = createSlice({
  name: 'FieldState',
  initialState,
  reducers: {
    setFieldError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    setFieldValidation: (state, action: PayloadAction<ValidationError>) => {
      let validationItem = action.payload;
      let index = state.Validations?.map((item) => item.name).indexOf(validationItem.name);
      if (index !== -1) state.Validations[index] = action.payload;
      else state.Validations.push(action.payload);

      state.hasValidationError = true;
      state.hasError = false;
    },
    setShowValidationError: (state, action: PayloadAction<KeyValuePair>) => {
      const item = action.payload;
      let index = state.Validations?.map((item) => item.name).indexOf(item.key.toString());
      if (index !== -1) {
        let selectedItem = state.Validations[index];

        state.Validations[index] = { ...selectedItem, showErrorMessage: item.value as boolean };
      }

      var showItems = state.Validations.filter((item) => item.showErrorMessage === true);
      if (showItems.length > 0) state.showValidationError = true;
      else state.showValidationError = false;
    },
    clearFieldValidation: (state, action: PayloadAction<string>) => {
      if (!!state && state.Validations?.length > 0) {
        var result = state.Validations.filter((item) => item.name !== action.payload);
        state.Validations = result;
      }

      if (state.Validations?.length === 0) {
        state.hasValidationError = false;
        state.showValidationError = false;
      } else {
        var showItems = state.Validations.filter((item) => item.showErrorMessage === true);
        if (showItems.length > 0) state.showValidationError = true;
        else state.showValidationError = false;
      }

      state.hasError = false;
    },
    clearAllFieldValidation: (state) => {
      state.Validations = [] as ValidationError[];
      state.hasError = false;
      state.hasValidationError = false;
      state.showValidationError = false;
    },
    setCustomValidationMessage: (state, action: PayloadAction<string>) => {
      state.customValidationMessage = action.payload;
    },
  },
});

export const {
  setFieldError,
  setFieldValidation,
  clearFieldValidation,
  clearAllFieldValidation,
  setShowValidationError,
  setCustomValidationMessage,
} = fieldSlice.actions;

export default fieldSlice.reducer;
