import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { ForecourtControllerModel } from '../../../models/forecourtControllerModel';
import { selectFieldsValidation, selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import {
  selectForecourtControllerContent,
  selectForecourtControllerDetailStatus,
  selectForecourtControllerItem,
} from '../../../store/forecourt-controllers/selectors';
import * as actions from '../../../store/forecourt-controllers/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import ForecourtController from './forecourt-controller';

const mapStateToProps = (state: RootState) => {
  return {
    forecourtControllerStatus: selectForecourtControllerDetailStatus(state),
    forecourtControllerItem: selectForecourtControllerItem(state),
    forecourtControllerContent: selectForecourtControllerContent(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    fieldValidations: selectFieldsValidation(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadForecourtControllerInfo: (data: string) => dispatch({ type: actions.LOAD_FORECOURTCTRL_INFO, payload: data }),
    loadForecourtControllers: () => dispatch({ type: actions.LOAD_FORECOURTCTRLS }),
    createForecourtController: (data: ForecourtControllerModel) =>
      dispatch({ type: actions.CREATE_FORECOURTCTRL, payload: data }),
    editForecourtController: (data: ForecourtControllerModel) =>
      dispatch({ type: actions.EDIT_FORECOURTCTRL, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const ForecourtControllerPage = connect(mapStateToProps, mapDispatchToProps)(ForecourtController);

export default ForecourtControllerPage;
