import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { EventFilterModel } from '../../models/eventModel';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectOrganisationId } from '../../store/auth/selectors';
import { selectIsPageReachBottom } from '../../store/page-configuration/selectors';
import { selectSiteList } from './../../store/sites/selectors';
import { selectTankNumberList } from '../../store/tanks/selectors';
import { selectTerminalNumberList } from '../../store/terminal/selectors';
import { selectPumpNumberList } from '../../store/pump/selectors';
import { selectEventData, selectEventStatus, selectEventTypes } from '../../store/event/selectors';
import * as actions from '../../store/event/actions';
import * as headerActions from '../../store/header/actions';
import * as modalActions from '../../store/modals/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as cardActions from '../../store/cards/actions';
import * as siteActions from '../../store/sites/actions';
import * as terminalActions from '../../store/terminal/actions';
import * as pumpActions from '../../store/pump/actions';
import * as tankActions from '../../store/tanks/actions';
import * as paginationActions from '../../store/pagination/actions';
import Events from './event-list';

const mapStateToProps = (state: RootState) => {
  return {
    selectedOrganisationId: selectOrganisationId(state),
    eventStatus: selectEventStatus(state),
    eventContent: selectEventData(state),
    eventTypes: selectEventTypes(state),
    siteListName: selectSiteList(state),
    terminalNumberList: selectTerminalNumberList(state),
    pumpNumberList: selectPumpNumberList(state),
    tankNameList: selectTankNumberList(state),
    authStatus: selectAuthStatus(state),
    isPageReachBottom: selectIsPageReachBottom(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initLoadEvents: (data?: EventFilterModel) => dispatch({ type: actions.INIT_LOAD_EVENTS, payload: data }),
    loadEvents: (data: EventFilterModel) => dispatch({ type: actions.LOAD_EVENTS, payload: data }),
    loadEventTypes: () => dispatch({ type: actions.LOAD_EVENT_TYPES }),
    saveEventFuzzySearch: (data: string) => dispatch({ type: actions.SAVE_EVENT_FUZZY_SEARCH, payload: data }),
    clearEventList: () => dispatch({ type: actions.CLEAR_EVENT_LIST }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    loadTerminalNumberList: (data: string) =>
      dispatch({ type: terminalActions.LOAD_TERMINALNUMBER_LIST, payload: data }),
    loadPumpNumberList: (data: string) => dispatch({ type: pumpActions.LOAD_PUMPNUMBER_LIST, payload: data }),
    loadTankNumberList: (data: string) => dispatch({ type: tankActions.LOAD_TANKNUMBER_LIST, payload: data }),
    setIsScrollRequest: (data: boolean) => dispatch({ type: cardActions.SET_IS_SCROLL_REQUEST, payload: data }),
    clearContinuationToken: () => dispatch({ type: paginationActions.CLEAR_CONTINUATION_TOKEN }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const EventList = connect(mapStateToProps, mapDispatchToProps)(Events);

export default EventList;
