import { LoadingStatus } from "../../constants/loading-constants";
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { BinRangeItemListModel, BinRangeModel } from "../../models/binRangeModel";

interface BinRangeState{
    binRangeStatus: string;
    binRangeList: BinRangeItemListModel[];
    binRangeData:BinRangeModel;
    hasError: boolean;
    binRnageNameList: KeyValuePair[]
}

export const initialState: BinRangeState = {
    binRangeStatus: LoadingStatus.LOADING
} as BinRangeState;