import { useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  useDndContext,
  DropAnimation,
} from '@dnd-kit/core';
import type { DragStartEvent, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, useSortable, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { CSS, isKeyboardEvent } from '@dnd-kit/utilities';
import { DndItem, Position } from './dnd-item';
import type { Props as PageProps } from './dnd-item';
import './dnd-grid.scss';

interface DndGridProps {
  itemList: string[];
  onBindingValue?: (newvalue: string[]) => void | undefined;
}

const dropAnimation: DropAnimation = {
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
};

const PageOverlay = ({ id, items, ...props }: Omit<PageProps, 'index'> & { items: string[] }) => {
  const { activatorEvent, over } = useDndContext();
  const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  const activeIndex = items.indexOf(String(id));
  const overIndex = over?.id ? items.indexOf(String(over?.id)) : -1;

  return (
    <DndItem
      id={id}
      {...props}
      clone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
    />
  );
};

const SortablePage = ({ id, activeIndex, ...props }: PageProps & { activeIndex: number }) => {
  const { attributes, listeners, index, isDragging, isSorting, over, transform, transition, setNodeRef } = useSortable({
    id,
  });

  return (
    <DndItem
      ref={setNodeRef}
      id={id}
      active={isDragging}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
      insertPosition={over?.id === id ? (index > activeIndex ? Position.After : Position.Before) : undefined}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

const DndGrid = (props: DndGridProps) => {
  const { itemList, onBindingValue } = props;
  const [activeId, setActiveId] = useState('');
  const [items, setItems] = useState(itemList);
  const activeIndex = activeId ? items.indexOf(activeId) : -1;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(String(active.id));
  };

  const handleDragCancel = () => {
    setActiveId('');
  };

  const handleDragEnd = ({ over }: DragEndEvent) => {
    if (over) {
      const overIndex = items.indexOf(String(over.id));

      if (activeIndex !== overIndex) {
        const newIndex = overIndex;

        setItems((items) => arrayMove(items, activeIndex, newIndex));

        if (onBindingValue) {
          onBindingValue(arrayMove(items, activeIndex, newIndex));
        }
      }
    }
    setActiveId('');
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={closestCenter}
    >
      <SortableContext items={items}>
        <ul className='grid-list'>
          {items.map((id, index) => (
            <SortablePage
              id={id}
              index={index + 1}
              key={id}
              layout='grid'
              activeIndex={activeIndex}
              onRemove={() => {
                setItems((items) => items.filter((itemId) => itemId !== id));
                if (onBindingValue) {
                  onBindingValue(items.filter((itemId) => itemId !== id));
                }
              }}
            />
          ))}
        </ul>
      </SortableContext>
      <DragOverlay dropAnimation={dropAnimation}>
        {activeId ? <PageOverlay id={activeId} layout='grid' items={items} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default DndGrid;
