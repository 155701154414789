import { BulkOperationRequest } from '../../entities/cardOperations';
import api, { setAuthorize } from '../../utilities/api';

const CARD_API_PREFIX = 'card-management';

export const getBulkOperations = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${CARD_API_PREFIX}/cards/bulk-operations?organisationId=${organisationId}`);
  return result.data;
};

export const getBulkOperationById = async (id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${CARD_API_PREFIX}/cards/bulk-operations/${id}?organisationId=${organisationId}`);
  return result.data;
};

export const createBulkOperation = async (data: BulkOperationRequest) => {
  await setAuthorize();
  let result = await api.post(`/${CARD_API_PREFIX}/cards/bulk-operations`, data);
  return result.data;
};
