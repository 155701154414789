import { LoadingStatus } from "../../constants/loading-constants";
import { TransactionFilterModel, TransactionModel } from "../../models/transactionModel";

interface transactionState{
    transactionStatus: string;
    transactionData: TransactionModel[];
    transactionFilter: TransactionFilterModel;
    hasError: boolean;
    isFilterActive: boolean;
}

export const initialState: transactionState = {   
    transactionStatus: LoadingStatus.LOADING,
    isFilterActive:false
} as transactionState;