import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface FilterIconProps {
    className?:string
};

const FilterIcon = (props: FilterIconProps) => {
    return (
        <SvgIcon className={`base-icon filter-icon ${props.className}`}/>
    );
};

export default FilterIcon