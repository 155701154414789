import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectAlertRuleState = (state: RootState) => state.AlertRuleState;

export const selectAlertRuleListData = createSelector(
  selectAlertRuleState,
    state => state.alertRuleList
  );

export const selectAlertRuleNameListData = createSelector(
  selectAlertRuleState,
    state => state.alertRuleNameList
);

export const selectAlertRuleInfoData = createSelector(
  selectAlertRuleState,
  state => state.alertRuleInfo
);

export const selectAlertRuleStatus = createSelector(
  selectAlertRuleState,
  state => state.alertRuleStatus
);

export const selectAlertRuleTriggerListData = createSelector(
  selectAlertRuleState,
    state => state.alertRuleTriggerList
  );
