import { selectGenericErrorData } from './../../store/generic-error/selectors';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import ErrorDisplay from './error-display';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { Dispatch } from '@reduxjs/toolkit';
import * as headerActions from '../../store/header/actions';

const mapStateToProps = (state: RootState) => {
  return {
    genericError: selectGenericErrorData(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
  };
};

const ErrorDisplayControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorDisplay);

export default ErrorDisplayControl;
