import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { 
    selectDialogBoxActionState, 
    selectDialogBoxVisibleState, 
} from './../../store/dialog-box/selectors';
import DialogBox from './dialog-box';

const mapStateToProps = (state: RootState) => {
    return {   
        dialogBoxActionState: selectDialogBoxActionState(state),
        isVisible: selectDialogBoxVisibleState(state),
    }
}

const DialogBoxComponent = connect(mapStateToProps)(DialogBox);

export default DialogBoxComponent;