import { FirmwareInstallEntity } from '../../entities/terminalFirmware';
import { TerminalModel } from '../../models/terminalModel';
import api, { setAuthorize } from '../../utilities/api';

const TERMINAL_FIRMWARE_API_PREFIX = 'terminal-firmware-management';

export const getCurrentAndPendingFirmwares = async (data: TerminalModel) => {
  await setAuthorize();
  const res = await api.get(
    `/${TERMINAL_FIRMWARE_API_PREFIX}/terminals/${data?.id}/firmware?organisationId=${data?.organisationId}`
  );
  return res.data;
};

export const getBoards = async (data: TerminalModel) => {
  await setAuthorize();
  const res = await api.get(
    `/${TERMINAL_FIRMWARE_API_PREFIX}/terminals/${data?.id}/boards?organisationId=${data?.organisationId}`
  );
  return res.data;
};

export const getAvailableFirmwares = async (organisationId: string, boardModel?: string) => {
  await setAuthorize();
  let apiRequest: string = `/${TERMINAL_FIRMWARE_API_PREFIX}/terminals/firmware?organisationId=${organisationId}`;
  if (!!boardModel) {
    apiRequest = `${apiRequest}&boardModel=${boardModel.toLocaleLowerCase()}`;
  }
  const res = await api.get(apiRequest);
  return res.data;
};

export const updateFirmware = async (data: FirmwareInstallEntity, id: string) => {
  await setAuthorize();
  await api.put(
    `/${TERMINAL_FIRMWARE_API_PREFIX}/terminals/${id}/firmware?organisationId=${data?.organisationId}`,
    data
  );
};

export const getFirmwareDownloadLink = async (organisationId: string, firmwareName: string) => {
  await setAuthorize();
  const res = await api.get(
    `/${TERMINAL_FIRMWARE_API_PREFIX}/terminals/firmware/${firmwareName}?organisationId=${organisationId}`
  );
  return res.data;
};
