import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface DoubleArrowDownProps{
    className?:string
}

const DoubleArrowDownIcon = (props: DoubleArrowDownProps) => {
    return (
        <SvgIcon className={`base-icon double-arrow-down-icon ${props.className}`} />
    );
};

export default DoubleArrowDownIcon;