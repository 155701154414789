import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as services from './services';
import * as actions from './actions';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { clearContinuationTokenList, setContinuationTokenList, setIsReachEnd } from '../pagination/reducers';
import { selectContinuationToken, selectIsReachEnd } from '../pagination/selectors';
import { AlertListResponse, AlertRequest } from '../../entities/alert';
import { AlertFilterModel, AlertModel } from '../../models/alertModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { selectAlertData, selectAlertFuzzySearch } from './selectors';
import { setAlertStatus, setAlertData, setAlertError, setAlertFuzzySearch } from './reducers';
import { setSnackBarError } from '../snackbar/reducers';
import { selectIsScrollRequest } from '../cards/selectors';

export function* rootSaga() {
  yield takeLatest(actions.INIT_LOAD_ALERTS, initLoadAlerts);
  yield takeLatest(actions.LOAD_ALERTS, loadAlerts);
  yield takeLatest(actions.SAVE_ALERT_FUZZY_SEARCH, saveAlertFuzzySearch);
  yield takeLatest(actions.CLEAR_ALERT_LIST, clearAlertList);
}

export function* initLoadAlerts(filters?: PayloadAction<AlertFilterModel>) {
  try {
    yield put(setAlertStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    const filterOptions: AlertFilterModel = filters?.payload ? filters.payload : ({} as AlertFilterModel);
    const request: AlertRequest = MapDetailsToAlertRequest(organisationId, [], false, filterOptions);
    const fuzzyRequest: string = yield select(selectAlertFuzzySearch);
    let response: AlertListResponse = yield call(services.getAlertsData, request, fuzzyRequest);
    yield put(setIsReachEnd(!response.continuationToken));
    let alertData: AlertModel[] = mapAlertEntityToModel(response);
    if (response.continuationToken) yield put(setContinuationTokenList(response.continuationToken));
    else yield put(clearContinuationTokenList());
    yield put(setAlertData(alertData));
    yield put(setAlertStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertError());
  }
}

export function* loadAlerts(filters?: PayloadAction<AlertFilterModel>) {
  try {
    const isReachEnd: boolean = yield select(selectIsReachEnd);
    const isScrollRequest: boolean = yield select(selectIsScrollRequest);
    const organisationId: string = yield select(selectOrganisationId);
    const filterOptions: AlertFilterModel = filters?.payload ? filters.payload : ({} as AlertFilterModel);
    const continuationTokenList: string[] = yield select(selectContinuationToken);
    const request: AlertRequest = MapDetailsToAlertRequest(organisationId, continuationTokenList, false, filterOptions);
    const fuzzyRequest: string = yield select(selectAlertFuzzySearch);
    const alertInState: AlertModel[] = yield select(selectAlertData);
    if (isReachEnd && isScrollRequest) return;
    yield put(setAlertStatus(LoadingStatus.LOADING));
    let response: AlertListResponse = yield call(services.getAlertsData, request, fuzzyRequest);
    yield put(setIsReachEnd(!response.continuationToken));
    let alertData: AlertModel[] = mapAlertEntityToModel(response);
    if (response.continuationToken) yield put(setContinuationTokenList(response.continuationToken));
    const newRecords = continuationTokenList.length > 0 ? alertInState.concat(alertData) : alertData;
    yield put(setAlertData(newRecords));
    yield put(setAlertStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertError());
  }
}

export function* saveAlertFuzzySearch(action: PayloadAction<string>) {
  try {
    yield put(setAlertFuzzySearch(action.payload));
  } catch (error) {
    yield put(setSnackBarError(String(error)));
  }
}

export function* clearAlertList() {
  yield put(setAlertData([]));
}

const mapAlertEntityToModel = (response: AlertListResponse) => {
  if (response && response.items.length > 0) {
    const result: AlertModel[] = response.items.map((record) => {
      return {
        dateTimeUtc: record.dateTimeUtc,
        message: record.message,
        siteId: record.site.id,
        siteName: record.site.name,
        alertRuleName: record.alertRule.name,
        usersNotified: record.alertRule.usersNotified,
        notifiedEmailList: record?.alertRule?.usersNotified
          ?.filter((user) => user.notificationMethod === 'email')
          ?.map((it) => it.email),
        notifiedMobileList: record?.alertRule?.usersNotified
          ?.filter((user) => user.notificationMethod === 'sms')
          ?.map((it) => it.mobileNumber),
      } as AlertModel;
    });
    return result;
  }
  return [] as AlertModel[];
};

const MapDetailsToAlertRequest = (
  organisationId: string,
  continuationTokenList: string[],
  isReachEnd: boolean,
  filterOptions?: AlertFilterModel
) => {
  const requestEntity: AlertRequest = {
    limit: filterOptions?.limit ? filterOptions?.limit : 50,
    continuationToken: fieldMappingHelper.sanitizeStringValue(
      continuationTokenList.length > 0 && !isReachEnd
        ? continuationTokenList[continuationTokenList.length - 1]
        : isReachEnd
          ? ''
          : ''
    ),
    organisationId: organisationId,
    siteId: filterOptions?.siteId && filterOptions?.siteId !== 'undefined' ? filterOptions?.siteId : undefined,
    alertRuleId:
      filterOptions?.alertRuleId && filterOptions?.alertRuleId !== 'undefined' ? filterOptions?.alertRuleId : undefined,
    startDateTimeUtc:
      filterOptions?.startDateTime && filterOptions?.dateRange !== 'Default'
        ? dateTimeHelper.convertDateStringtoUTCString(filterOptions?.startDateTime)
        : undefined,
    endDateTimeUtc:
      filterOptions?.endDateTime && filterOptions?.dateRange !== 'Default'
        ? dateTimeHelper.convertDateStringtoUTCString(filterOptions?.endDateTime)
        : undefined,
  };
  return requestEntity;
};
