import Products from './recent-reports';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { selectAuthStatus, selectUserAccess } from '../../../store/auth/selectors';
import { selectReportData } from '../../../store/reports/selectors';
import { RootState } from '../../../store/rootReducers';
import { selectReportStatus } from '../../../store/reports/selectors';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as reportActions from '../../../store/reports/actions';
import * as headerActions from '../../../store/header/actions';

const mapStateToProps = (state: RootState) => {
  return {
    reportList: selectReportData(state),
    reportState: selectReportStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadReports: () => dispatch({ type: reportActions.LOAD_REPORTS }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
  };
};

const ProductList = connect(mapStateToProps, mapDispatchToProps)(Products);

export default ProductList;
