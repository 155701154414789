import classNames from 'classnames';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import LoadingSkeleton from '../../components/loading-skeleton/loading-skeleton';
import { BinRangeItemListModel } from '../../models/binRangeModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { cardType } from '../../constants/dropdown-constants';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/bin-range-item.scss';

interface BinRangeItemProps {
  item: BinRangeItemListModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string, name: string) => void;
  onClick: (id: string) => void;
}

const BinRangeItem: React.FC<BinRangeItemProps> = (props: BinRangeItemProps) => {
  const { item, hasDeleteAccess, hasUpdateAccess, onEdit, onDelete, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let actionItems: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit BIN Range',
      handler: () => {
        onEdit(item?.id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete BIN Range',
      handler: () => {
        onDelete(item?.id, item?.name);
      },
    });
  }

  const siteListStr: string =
    item?.sites?.length > 2
      ? `${String(
          item?.sites
            ?.map((s) => {
              return ` ${s}`;
            })
            .slice(2)
        )}`
      : '';

  const onItemClick = () => {
    if (onClick) {
      onClick(item?.id);
    }
  };
  return (
    <>
      <Card key={item?.id} className={classNames('box-card-parent', 'bin-range-card')}>
        <Box className={classNames('primary-details', 'bin-range-box')}>
          <Grid
            container
            spacing={{ xs: 4, sm: 4, md: 4 }}
            rowSpacing={6}
            className='bin-range-grid-container'
            onClick={onItemClick}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Item className={classNames('bin-range-field-title', 'bin-range-name')}>{item?.name}</Item>
                  <Item className={classNames('bin-range-field-sub-title', 'bin-ranges')}>{item?.binRange}</Item>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={8} md={5}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Item className={classNames('bin-range-field-title')}>Sites</Item>
                  {item?.applyToAllSites ? (
                    <Item className={classNames('bin-range-field-sub-title', 'site-names-tooltip')}>All</Item>
                  ) : (
                    <Item className={classNames('bin-range-field-sub-title', 'site-names-tooltip')}>
                      {!item?.siteSummary && <LoadingSkeleton />}
                      {item?.siteSummary}{' '}
                      {item?.sites?.length > 2 && (
                        <CustomTooltip title={siteListStr} textDisplay={`${item?.sites?.length - 2} more`} />
                      )}
                    </Item>
                  )}
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Item className={classNames('bin-range-field-title')}>Card Type</Item>
                  <Item className={classNames('bin-range-field-sub-title', 'card-type')}>
                    {fieldMappingHelper.getDisplayValue(item?.cardType, cardType)}
                  </Item>
                </div>
              </Item>
            </Grid>
          </Grid>
          <Item className={classNames('row-item-ellipse', 'bin-range-card-ellipse')}>
            {displayMenu ? (
              <FloatingMenu key={item?.id} buttonNode={<MoreVertIcon className='ellipse-icon' />} items={actionItems} />
            ) : (
              <Box className='box-item' onClick={onItemClick}></Box>
            )}
          </Item>
        </Box>
      </Card>
    </>
  );
};

export default BinRangeItem;
