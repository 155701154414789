import './empty-list.scss';

interface EmptyListProps {
  message?: string;
}

const EmptyList: React.FC<EmptyListProps> = (
  props: EmptyListProps
) => {
  const {  message } = props;
  return (
    <div className='empty-box-card'>
      <div className="empty-display-container">
        <div className="empty-display-message">{message}</div>
      </div>
    </div>
  );
};

export default EmptyList;
