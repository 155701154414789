import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MouseEventHandler } from 'react';
import './styles/back-button.scss';

interface BackButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
  const { onClick } = props;
  return (
    <div className='back-button'>
      <Button variant='text' startIcon={<ArrowBackIcon className='base-mui-icon' />} disableRipple onClick={onClick}>
        Back
      </Button>
    </div>
  );
};

export default BackButton;
