import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface WarningIconProps {
  className?: string;
}

const WarningIcon = (props: WarningIconProps) => {
  return <SvgIcon className={`base-icon warning-icon ${props.className}`} />;
};

export default WarningIcon;
