export const HealthStatus = Object.freeze({
    HEALTHY: 'ok',
    NORMAL: 'Pump OK',
    POOR: 'Poor'
});

export const Paths = Object.freeze({
    SITE_PATH_BASE: 'site-status/',
});

export const SITE_ID_STORAG_KEY = 'siteId-Key';
export const SITE_NAME_STORAG_KEY = 'siteName-Key';
export const SITE_ADDRESS_STORAG_KEY = 'siteAddress-Key';
