import { RootState } from './../rootReducers';
import { createSelector } from 'reselect';

export const selectPriceSignState = (state:RootState) => state.PriceSignState;

export const selectPriceSignContent = createSelector(
    selectPriceSignState,
    state => state.priceSignContent
);

export const selectPriceSignItem = createSelector(
    selectPriceSignState,
    state => state.priceSignItem
)

export const selectPriceSignStatus = createSelector(
    selectPriceSignState,
    state => state.priceSignStatus
);

export const selectPriceSignDetailStatus = createSelector(
    selectPriceSignState,
    state => state.priceSignDetailStatus
);
