import { LogLevel } from "@azure/msal-browser";



export const msalConfig = {
    auth: {
        clientId: (process.env.REACT_APP_AZURE_CLIENT_ID) ? process.env.REACT_APP_AZURE_CLIENT_ID : '',
        authority: (process.env.REACT_APP_AZURE_AUTHORITY) ? process.env.REACT_APP_AZURE_AUTHORITY : '',
        redirectUri: (process.env.REACT_APP_AZURE_REDIRECT_URI) ? process.env.REACT_APP_AZURE_REDIRECT_URI : '',
        knownAuthorities: [(process.env.REACT_APP_KNOWN_AUTHORITY) ? process.env.REACT_APP_KNOWN_AUTHORITY: ''],
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: string, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["openid", (process.env.REACT_APP_API_SCOPE)? process.env.REACT_APP_API_SCOPE: ''],
};

export const apiRequest = {
    scopes: [(process.env.REACT_APP_API_SCOPE)? process.env.REACT_APP_API_SCOPE: ''],
};

export const apiRedirectRequest = {
    scopes: [(process.env.REACT_APP_API_SCOPE)? process.env.REACT_APP_API_SCOPE: ''],
    navigateToLoginRequestUrl: true,
}