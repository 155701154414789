import { createSelector } from 'reselect';
import { SiteItemModel } from './../../models/siteModel';
import { SITE_ID_STORAG_KEY, SITE_NAME_STORAG_KEY, SITE_ADDRESS_STORAG_KEY } from './../../constants/sites-constants';
import { RootState } from '../rootReducers'; // retrieve all state
import { GetDataFromLocalStorage } from '../../utilities/localStorage-helper';
import { selectAuthState } from '../auth/selectors';

export const selectLocalState = (state: RootState) =>state.SiteState

export const selectSitesContent = createSelector(
  selectLocalState,
  state => state.siteContent
);

export const selectSitesStatus = createSelector(
  selectLocalState,
  state => state.siteStatus
);

export const selectSiteConfiguration = createSelector(
  selectLocalState,
  state => state.siteConfiguration
);

export const selectSiteItem = createSelector(
  [selectLocalState,selectAuthState],
  (state, authState) => {

    if(state.siteContent){
      return (state.siteContent.find(it => it.id === state.selectedSiteId) as SiteItemModel)
    }
    else {
      return {
        name:GetDataFromLocalStorage(`${SITE_NAME_STORAG_KEY}-${authState.userId}`),
        address: GetDataFromLocalStorage(`${SITE_ADDRESS_STORAG_KEY}-${authState.userId}`)
      } as SiteItemModel
    }
  }
);

export const selectSiteList = createSelector(
  selectLocalState,
  state => state.siteNameList
);

export const selectSelectedSiteId = createSelector(
  [selectLocalState,selectAuthState],
  (state, authState) => {
    
    if(!!state.selectedSiteId){
      return state.selectedSiteId;
    }else {
      return GetDataFromLocalStorage(`${SITE_ID_STORAG_KEY}-${authState.userId}`)
    }
  }
);



export const selectSitesIdData = createSelector(
  selectLocalState,
  state => state.sitesIdContent
);