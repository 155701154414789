import React, { useCallback, useRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import classNames from 'classnames';
import CustomTextField from '../text-field/text-field.container';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { ValidationError } from '../../models/baseModels/validationModel';
import './text-field-with-suggestions.scss';

interface TextFieldWithSuggestionsnProps {
  name: string;
  label?: string;
  className?: string;
  placeholder: string;
  dataList: string[];
  value: string;
  maxHeight: number;
  isMandatory?: boolean;
  validateCounter?: number;
  readOnly?: boolean;
  noOptionsText: string;
  isSelectFromList: boolean;
  maxCharLength?: number;
  onBindingValue?: (newvalue: KeyValuePair) => void | undefined;
  onTextBindingValue?: (newvalue: KeyValuePair) => void | undefined;
  fieldValidationStatus: (name: string) => ValidationError | undefined;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
}
const TextFieldWithSuggestions = (props: TextFieldWithSuggestionsnProps) => {
  const {
    name,
    label,
    className,
    placeholder,
    dataList = [],
    value,
    maxHeight,
    isMandatory,
    validateCounter,
    readOnly,
    noOptionsText,
    isSelectFromList,
    maxCharLength,
    onBindingValue,
    onTextBindingValue,
    setFieldValidation,
    removeValidation,
    fieldValidationStatus,
    setShowValidationMessage,
  } = props;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const popperAnchor = useRef({} as HTMLDivElement);

  const VALIDATION_MESSAGE_MANDATORY_FIELD = 'Required field';
  const VALIDATION_MESSAGE_NOT_SELECT_FROM_LIST = 'Please select an address from the list';

  const onChangeValue = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (onBindingValue) {
      onBindingValue({ key: name, value: value } as KeyValuePair);
    }
    CheckMandatoryValue(value);
  };

  const onChangeTextValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onTextBindingValue) {
      onTextBindingValue({
        key: event.target.name,
        value: maxCharLength ? event.target.value.slice(0, maxCharLength) : event.target.value,
      });
      validateData(event.target.value ? event.target.value : '');
    }
  };

  /**FOR VALIDATION */
  const CheckMandatoryValue = useCallback(
    (values: string) => {
      if (values.length <= 0) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MANDATORY_FIELD,
        });
      } else {
        removeValidation(name);
      }
    },
    [VALIDATION_MESSAGE_MANDATORY_FIELD, name, setFieldValidation, removeValidation]
  );

  const CheckIsSelectFromList = useCallback(
    (value: boolean) => {
      if (!value) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_NOT_SELECT_FROM_LIST,
        });
        return;
      } else {
        removeValidation(name);
      }
    },
    [VALIDATION_MESSAGE_NOT_SELECT_FROM_LIST, name, setFieldValidation, removeValidation]
  );

  const validateData = useCallback(
    (value: string) => {
      if (isMandatory) {
        CheckMandatoryValue(value);
      }
      CheckIsSelectFromList(isSelectFromList);
    },
    [isMandatory, isSelectFromList, CheckMandatoryValue, CheckIsSelectFromList]
  );

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      validateData(value ? value : '');
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, value?.length, name, isSelectFromList, validateData, setShowValidationMessage]);

  const selectFocusHandler = (event: React.SyntheticEvent) => {
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  const selectBlurHandler = (event: React.SyntheticEvent) => {
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  return (
    <div className={classNames('multiselect-field-container')}>
      {readOnly ? (
        <CustomTextField name={name} value={value} type='string' label={label} isMultiline={true} readOnly />
      ) : (
        <>
          {label && (
            <div className={classNames('multiselect-field-label', className)}>
              {label} {isMandatory && <strong className='asterisk'> *</strong>}
            </div>
          )}
          <div ref={popperAnchor}>
            <Autocomplete
              id='size-small-filled-multi'
              sx={{ maxHeight: maxHeight, overflow: 'auto' }}
              getOptionLabel={(data) => data?.toString()}
              options={dataList}
              autoComplete
              includeInputInList
              value={value}
              noOptionsText={noOptionsText}
              className={
                isShowMessage && fieldValidationStatus(name)?.hasError
                  ? classNames('autocomplete', 'autocomplete-error')
                  : classNames('autocomplete')
              }
              disableClearable
              size='small'
              onChange={onChangeValue}
              onBlur={selectBlurHandler}
              onOpen={selectFocusHandler}
              PopperComponent={({ style, ...props }) => (
                <Popper
                  {...props}
                  className={classNames(props.className, {
                    'hide-popper':
                      (dataList.length <= 0 && !noOptionsText) ||
                      !dataList.some((str) => str?.toLowerCase().includes(value?.toLowerCase())),
                  })}
                  anchorEl={popperAnchor.current}
                  style={{ width: popperAnchor.current.clientWidth }}
                  placement='bottom'
                />
              )}
              renderInput={(params) => (
                <TextField
                  name={name}
                  className={classNames('multiselect', className)}
                  autoComplete='on'
                  placeholder={placeholder}
                  onChange={onChangeTextValue}
                  InputProps={{
                    ...params.InputProps,
                    inputProps: {
                      ...params.inputProps,
                      maxLength: 30,
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
            />
            {isShowMessage && fieldValidationStatus(name)?.hasError && (
              <div className='validation-text-message-error'>{fieldValidationStatus(name)?.validationMessage}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TextFieldWithSuggestions;
