import { ProductsInfoEntity } from '../../entities/product';
import api, { setAuthorize } from '../../utilities/api';

const PRODUCT_API_PREFIX = 'product-management';

export const getProductsData = async (organizationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${PRODUCT_API_PREFIX}/products?organisationId=${organizationId}`
  );
  return result.data;
};

export const getProductById = async (id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${PRODUCT_API_PREFIX}/products/${id}?organisationId=${organisationId}`
  );
  return result.data;
};

export const createProduct = async (item: ProductsInfoEntity) => {
  await setAuthorize();
  await api.post(`/${PRODUCT_API_PREFIX}/products`, item);
};

export const editProductData = async (item: ProductsInfoEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${PRODUCT_API_PREFIX}/products/${id}`, item);
};

export const deleteProductData = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(
    `/${PRODUCT_API_PREFIX}/products/${id}?organisationId=${organisationId}`
  );
};
