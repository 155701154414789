import { createSelector } from "reselect";
import { UserModel } from "../../models/usersModel";
import { RootState } from "../rootReducers";

export const selectLocalState = (state: RootState) => state.UserState;

export const selectUsersContent = createSelector(
    selectLocalState,
    state => state.usersContent
);

export const selectUserStatus = createSelector(
    selectLocalState,
    state => state.userStatus
);

export const selectUserInfo = createSelector(
    selectLocalState,
    state => state.userInfo
);

export const selectUserPermission = createSelector(
    selectLocalState,
    state => state.userPermission
);

export const selectUserListPermissionGroups = createSelector(
    selectLocalState,
    state => state.userListPermissionGroups
);
export const selectUserListPermission = createSelector(
    selectLocalState,
    state => state.userListPermission
);

export const selectUser = (id: string) =>
  createSelector(selectUsersContent, (users) => {
    const user = users.filter((u) => u.id === id);

    return user?.length > 0 ? user[0] : ({} as UserModel);
  });