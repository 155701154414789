import { LoadingStatus } from '../../constants/loading-constants';
import { TankStatusHistoryModel } from '../../models/tankHistory';

interface tankState {
  tankHistoryStatus: string;
  tankHisotoryData: TankStatusHistoryModel[];
  hasError: boolean;
}

export const initialState: tankState = {
  tankHistoryStatus: LoadingStatus.LOADING,
} as tankState;
