import { LoadingStatus } from '../../constants/loading-constants';
import { TankModel } from '../../models/tankModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

interface tankState {
  tankStatus: string;
  tankDetailStatus: string;
  tankData: TankModel[];
  hasError: boolean;
  tankInfo: TankModel;
  tankNameList: KeyValuePair[];
  tankNumberList: KeyValuePair[];
}

export const initialState: tankState = {
  tankStatus: LoadingStatus.LOADING,
  tankDetailStatus: LoadingStatus.LOADING,
} as tankState;
