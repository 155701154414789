import { PaginationModel } from '../../models/paginationModel';

interface PaginationState {
  paginationInfo: PaginationModel;
  continuationToken: string[];
  isReachEnd: boolean;
  hasError: boolean;
}

export const initialState: PaginationState = {
  paginationInfo: { page: 0, limit: 10, isClickNext: true } as PaginationModel,
  continuationToken: [],
  isReachEnd: false,
  hasError: false,
} as PaginationState;
