import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectFieldsValidation, selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectBinRangeData, selectBinRangeStatus } from '../../../store/bin-ranges/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectSiteList } from './../../../store/sites/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { BinRangeModel } from '../../../models/binRangeModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import * as headerActions from '../../../store/header/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as binRangeActions from '../../../store/bin-ranges/actions';
import * as siteActions from '../../../store/sites/actions';
import BinRange from './bin-range';

const mapStateToProps = (state: RootState) => {
  return {
    binRangeItem: selectBinRangeData(state),
    binRangeStatus: selectBinRangeStatus(state),
    hasValidationError: selectHasValidationError(state),
    siteListName: selectSiteList(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    fieldValidations: selectFieldsValidation(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadBinRangeData: (id: string) =>
      dispatch({
        type: binRangeActions.LOAD_BIN_RANGE_BY_ID,
        payload: id,
      }),
    createBinRange: (item: BinRangeModel) => dispatch({ type: binRangeActions.CREATE_BIN_RANGE, payload: item }),
    editBinRange: (item: BinRangeModel) => dispatch({ type: binRangeActions.UPDATE_BIN_RANGE, payload: item }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    setFieldValidation: (validation: ValidationError) =>
      dispatch({ type: fieldActions.SET_FIELD_VALIDATION, payload: validation }),
    setShowValidationMessage: (value: KeyValuePair) =>
      dispatch({ type: fieldActions.SET_SHOW_FIELD_VALIDATION, payload: value }),
  };
};

const BinRangePage = connect(mapStateToProps, mapDispatchToProps)(BinRange);

export default BinRangePage;
