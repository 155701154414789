import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './styles/copy-text.scss';

interface BackButtonProps {
  copyValue: string;
  label: string;
  displaySuccessSnackbar: (message: string) => void;
}

const CopyTextButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
  const { copyValue, label, displaySuccessSnackbar } = props;

  const onClick = () => {
    navigator.clipboard.writeText(copyValue);
    displaySuccessSnackbar(`${label} copied to clipboard`);
  };

  return (
    <Button
      className='copy-icon-button'
      variant='text'
      startIcon={<ContentCopyIcon className='base-mui-icon copy-icon' />}
      disableRipple
      onClick={onClick}
    ></Button>
  );
};

export default CopyTextButton;
