export const LOAD_USERS = 'compac/LOAD_USERS';
export const LOAD_USER_PERMISSION = 'compac/LOAD_USER_PERMISSION';
export const DELETE_USER = 'compac/DELETE_USER';
export const EDIT_USER = 'compac/EDIT_USER';
export const CREATE_USER = 'compac/CREATE_USER';
export const LOAD_USER_INFO = 'compac/LOAD_USER_INFO';
export const LOAD_MY_ACCOUNT='compac/LOAD_MY_ACCOUNT';
export const EDIT_MY_ACCOUNT = 'compac/EDIT_MY_ACCOUNT';
export const SEND_USER_INVITE = 'compac/SEND_USER_INVITE';
export const INVITE_GUEST = 'compac/INVITE_GUEST';
