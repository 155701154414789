import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import * as services from './services';
import * as userServices from '../users/services';
import * as actions from './actions';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { LoadingStatus } from '../../constants/loading-constants';
import {
  setAlertUserGroupStatus,
  setAlertUserGroupListData,
  setAlertUserGroupInfoData,
  setAlertUserGroupError,
  setAlertUserGroupNameListData,
} from './reducers';
import { AlertUserGroupModel } from '../../models/alertUserGroupModel';
import {
  AlertUserGroupEntity,
  AlertUserGroupListResponse,
  AlertUserGroupUserEntity,
} from '../../entities/alert-usergroup';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { Messages } from '../../constants/messages';
import { setIsPageDirty } from '../page-configuration/reducers';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { UsersResponse } from '../../entities/users';
import { UserModel } from '../../models/usersModel';
import { setUsersContent } from '../users/reducers';
import { mapUserEntityToModelList } from '../users/sagas';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_ALERT_USER_GROUPS, loadAlertUserGroups);
  yield takeLatest(actions.CREATE_ALERT_USER_GROUP, createAlertUserGroup);
  yield takeLatest(actions.EDIT_ALERT_USER_GROUP, editAlertUserGroup);
  yield takeLatest(actions.DELETE_ALERT_USER_GROUP, deleteAlertUserGroup);
  yield takeLatest(actions.LOAD_ALERT_USER_GROUP_INFO, loadAlertUserGroupInfo);
  yield takeLatest(actions.LOAD_ALERT_USER_GROUP_NAME_LIST, loadAlertUserGroupNameList);
}

export function* loadAlertUserGroups() {
  try {
    yield put(setAlertUserGroupStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: AlertUserGroupListResponse = yield call(services.getAlertUserGroupList, organisationId);
    let user_response: UsersResponse = yield call(userServices.getAllUsers, organisationId);
    let userList: UserModel[] = mapUserEntityToModelList(user_response);
    yield put(setUsersContent(userList));
    let userGroupsData: AlertUserGroupModel[] = mapAlertUserGroupEntityToModelList(response, userList);
    yield put(setAlertUserGroupListData(userGroupsData));
    yield put(setAlertUserGroupStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertUserGroupError());
  }
}

export function* loadAlertUserGroupNameList() {
  try {
    yield put(setAlertUserGroupStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: AlertUserGroupListResponse = yield call(services.getAlertUserGroupList, organisationId);
    let records: KeyValuePair[] = yield call(mapAlertUserGroupEntityToKeyValuePair, response);
    yield put(setAlertUserGroupNameListData(records));
    yield put(setAlertUserGroupStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertUserGroupError());
  }
}

export function* loadAlertUserGroupInfo(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setAlertUserGroupStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: AlertUserGroupEntity = yield call(services.getAlertUserGroupInfo, action.payload, organisationId);
      let userGroupInfo: AlertUserGroupModel = yield call(mapAlertUserGroupEntityToModel, response);
      yield put(setAlertUserGroupInfoData(userGroupInfo));
      yield put(setAlertUserGroupStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setAlertUserGroupError());
    yield put(clearAllFieldValidation());
  }
}

export function* createAlertUserGroup(action: PayloadAction<AlertUserGroupModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let alertUserGroupEntity: AlertUserGroupEntity = MapAlertUserGroupModelToEntity(action.payload, organisationId);
    yield call(services.createAlertUserGroup, alertUserGroupEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.ALERT_USER_GROUP_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* editAlertUserGroup(action: PayloadAction<AlertUserGroupModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let alertUserGroupEntity: AlertUserGroupEntity = MapAlertUserGroupModelToEntity(action.payload, organisationId);
    yield call(services.editAlertUserGroup, alertUserGroupEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.ALERT_USER_GROUP_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* deleteAlertUserGroup(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteAlertUserGroup, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.ALERT_USER_GROUP_DELETE_SUCCESS));
    yield call(loadAlertUserGroups);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const mapAlertUserGroupEntityToModelList = (response: AlertUserGroupListResponse, userList: UserModel[]) => {
  if (response && response.items.length > 0) {
    let userNamesList = userList?.map((it) => {
      return {
        key: it?.id,
        value: it?.firstName + ' ' + it?.lastName,
      } as KeyValuePair;
    });

    const result: AlertUserGroupModel[] = response.items.map((item, i) => {
      let selectedUserList = item?.users?.map((it) => {
        return it?.id;
      });

      return {
        id: item?.id,
        name: item?.name,
        organisationId: item?.organisationId,
        users: item?.users?.map((it) => {
          return it?.id;
        }),
        userNameList: userNamesList
          .filter((obj) => selectedUserList.includes(String(obj.key)))
          .map((obj) => String(obj.value)),
      };
    });
    return result;
  }
  return [] as AlertUserGroupModel[];
};

const mapAlertUserGroupEntityToKeyValuePair = (response: AlertUserGroupListResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((item, i) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
  return [] as KeyValuePair[];
};

const mapAlertUserGroupEntityToModel = (response: AlertUserGroupEntity) => {
  if (response) {
    let userGroupModel = {
      id: response?.id,
      organisationId: response?.organisationId,
      name: response?.name,
      users: response?.users?.map((it) => {
        return it?.id;
      }),
    } as AlertUserGroupModel;
    return userGroupModel;
  }
  return {} as AlertUserGroupModel;
};

const MapAlertUserGroupModelToEntity = (model: AlertUserGroupModel, organisationId: string): AlertUserGroupEntity => {
  if (model) {
    let userList = model?.users?.map((it) => {
      return {
        id: it,
      } as AlertUserGroupUserEntity;
    });

    let userGroupEntity = {
      organisationId: organisationId,
      name: model?.name,
      users: userList,
    } as AlertUserGroupEntity;
    return userGroupEntity;
  }
  return {} as AlertUserGroupEntity;
};
