import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePageVisibility } from 'react-page-visibility';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { BulkOperationDetailsModel, BulkOperationsModel } from '../../models/cardOperationModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { Messages } from '../../constants/messages';
import { ModuleName } from '../../constants/module-constants';
import { AppPollInterval } from '../../config/config';
import { usePageLeaveListener } from '../../utilities/general-helpers';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import MessageDisplay from '../../components/message-display/message-display';
import Wizard from '../../components/wizard/wizard';
import EmptyList from '../../components/empty-list/empty-list';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import OperationItem from './operationItem';
import './styles/card-operations.scss';

interface OperationsProps {
  authStatus: string;
  bulkOperationList: BulkOperationsModel;
  bulkOperationState: string;
  runningOperationsDetails: BulkOperationDetailsModel[];
  opeationDetailsList: BulkOperationDetailsModel[];
  operationsDetailsListState: string;
  loadAllOperations: () => void;
  loadOperationByIdStoreInList: (data: string) => void;
  cancelOperationReload: () => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const Operations: React.FC<OperationsProps> = (props: OperationsProps) => {
  const {
    authStatus,
    bulkOperationList,
    bulkOperationState,
    runningOperationsDetails,
    opeationDetailsList,
    operationsDetailsListState,
    loadAllOperations,
    loadOperationByIdStoreInList,
    cancelOperationReload,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const { orgId } = useParams();
  const isLoadAllOperationsRequested = useRef(false);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  const [hasOperationReadAccess, setHasOperationReadAccess] = useState(false);
  const [hasOperationCardImportAccess, setHasOperationCardImportAccess] = useState(false);
  const [hasOperationCardDeleteAccess, setHasOperationCardDeleteAccess] = useState(false);

  /** CHECK ACCESS STATUS */
  useMemo(() => {
    setHasOperationReadAccess(userAccess(ModuleName.CARD_OPERATION).hasReadAccess);
    setHasOperationCardImportAccess(userAccess(ModuleName.CARD_OPERATION).hasCardImportAccess);
    setHasOperationCardDeleteAccess(userAccess(ModuleName.CARD_OPERATION).hasCardDeleteAccess);
  }, [userAccess]);

  const hasCreateAccess = hasOperationCardImportAccess || hasOperationCardDeleteAccess;

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(bulkOperationState === LoadingStatus.LOADING && hasOperationReadAccess);
    setSuccess(bulkOperationState === LoadingStatus.SUCCESS && hasOperationReadAccess && !hasNoSystemAccess);
    setError(bulkOperationState === LoadingStatus.ERROR || authError);
  }, [authError, hasNoSystemAccess, hasOperationReadAccess, bulkOperationState]);

  usePageLeaveListener(cancelOperationReload);

  useEffect(() => {
    if (!isLoadAllOperationsRequested.current && authSuccess && hasOperationReadAccess) {
      loadAllOperations();
      isLoadAllOperationsRequested.current = true;
    }
  }, [authSuccess, hasOperationReadAccess, loadAllOperations]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Bulk Operations',
      showCreateButton: hasCreateAccess,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      showWizard: true,
      error: error,
      pageURL: 'cards/card-operations',
      createButtonText: 'Start New',
    } as HeaderStateModel);
  }, [error, hasCreateAccess, setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  const isPageVisible = usePageVisibility();

  useEffect(() => {
    if (isPageVisible) {
      let intervalId = setInterval(() => {}, AppPollInterval);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPageVisible]);

  const wizardList = [
    { key: 'Cards', value: `/organisations/${orgId}/cards` },
    { key: 'Bulk Operations', value: `/organisations/${orgId}/cards/card-operations` },
  ] as KeyValuePair[];

  return (
    <>
      <Wizard wizardList={wizardList} />
      {(!authCheckCompleted || loading) && (
        <div className='loading-error-handler-container'>
          <LoadingDisplay />
        </div>
      )}
      {(hasNoSystemAccess || (authSuccess && !hasOperationReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {success && (
        <>
          {!bulkOperationList?.count && <EmptyList message='No operations found'></EmptyList>}

          {bulkOperationList?.count && (
            <>
              <div className='operation-section-title'>On-going Operations</div>
              <div className='ongoing-operation-list-container'>
                {bulkOperationList?.runningOperations &&
                  bulkOperationList?.runningOperations?.map((item, i) => (
                    <OperationItem
                      key={item.id}
                      item={item}
                      details={runningOperationsDetails?.find((it) => it.id === item.id)}
                      opeationDetailsList={opeationDetailsList}
                      loadOperationById={loadOperationByIdStoreInList}
                    />
                  ))}
                {!!bulkOperationList?.runningOperations && bulkOperationList?.runningOperations?.length < 1 && (
                  <EmptyList message='No on-going operations found'></EmptyList>
                )}
              </div>
              <div className='operation-section-title'>Pending Operations</div>
              <div className='pending-operation-list-container'>
                {bulkOperationList?.pendingOperations &&
                  bulkOperationList?.pendingOperations?.map((item, i) => (
                    <OperationItem
                      key={item.id}
                      item={item}
                      opeationDetailsList={opeationDetailsList}
                      loadOperationById={loadOperationByIdStoreInList}
                    />
                  ))}
                {!!bulkOperationList?.pendingOperations && bulkOperationList?.pendingOperations?.length < 1 && (
                  <EmptyList message='No pending operations found'></EmptyList>
                )}
              </div>
              <div className='operation-section-title'>Completed Operations</div>
              <div className='completed-operation-list-container'>
                {}
                {bulkOperationList?.completedOperations &&
                  bulkOperationList?.completedOperations.map((item, i) => (
                    <OperationItem
                      key={item.id}
                      item={item}
                      opeationDetailsList={opeationDetailsList}
                      operationsDetailsListState={operationsDetailsListState}
                      loadOperationById={loadOperationByIdStoreInList}
                    />
                  ))}
                {!!bulkOperationList?.completedOperations && bulkOperationList?.completedOperations?.length < 1 && (
                  <EmptyList message='No completed operations found'></EmptyList>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Operations;
