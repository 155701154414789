import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import CollapsibleCard from '../../components/collapsible-card/collapsible-card';
import { PriceSignStatusType } from '../../constants/status';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { PriceSignDetailModel, PriceSignProductIndexModel, PriceSignRecordModel } from '../../models/priceSignModel';
import { ItemDetailModel } from '../../models/baseModels/itemDetailModel';
import { getPriceSignStatusIndicator } from '../../utilities/field-helper';
import { getStatusMessageCount } from '../../utilities/general-helpers';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as generalHelper from '../../utilities/general-helper';
import './styles/price-signs.scss';
import './styles/price-sign-item.scss';

interface PriceSignItemProps {
  id: string;
  priceSignItem: PriceSignRecordModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onDelete: (id: string, name: number) => void;
  onEdit: (id: string) => void;
  onClick: (id: string) => void;
}

const PriceSignItem: React.FC<PriceSignItemProps> = (props: PriceSignItemProps) => {
  const { id, priceSignItem, hasDeleteAccess, hasUpdateAccess, onDelete, onEdit, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let actionItems: FloatingMenuItem[] = [];
  let moreItemDetails: ItemDetailModel[] = [];

  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit Price Sign',
      handler: () => {
        onEdit(id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete Price Sign',
      handler: () => {
        onDelete(id, priceSignItem.number);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const fixedItemDetails: PriceSignDetailModel[] = [
    {
      name: 'Status',
      value: fieldMappingHelper.getDisplayValue(priceSignItem.status, PriceSignStatusType),
      status: priceSignItem.status,
      healthIndicator: getPriceSignStatusIndicator(priceSignItem.status),
    },
    {
      name: 'Last Status Update',
      value: priceSignItem.lastUpdatedDateTimeUtc,
      healthIndicator: 'display-none',
    },
  ];

  if (priceSignItem?.products && priceSignItem?.products?.length > 0) {
    // create a copy of the array before sorting by index
    const sortedArray = [...priceSignItem?.products].sort(
      (a: PriceSignProductIndexModel, b: PriceSignProductIndexModel) => a.index - b.index
    );

    sortedArray?.map((it) =>
      moreItemDetails.push({
        name: it.name,
        value: `${
          it.unitPrice !== undefined || it.unitPrice
            ? '$' + generalHelper.numberHandlerWithDP(it.unitPrice, 3)
            : 'Unknown'
        }`,
      })
    );
  }

  const StatusDetail = () => {
    return (
      <span>
        {priceSignItem?.messages &&
          priceSignItem?.messages?.length > 0 &&
          getStatusMessageCount(priceSignItem?.messages)}
      </span>
    );
  };

  return (
    <>
      <Card className={classNames('box-card-parent')}>
        <Box className={classNames('primary-details')}>
          <Item className={classNames('box-row-columns', 'price-sign-item__primary-details')} onClick={onItemClick}>
            <Item className={classNames('box-row-item-header')}>
              <div className={classNames('icon-name-header')}>
                <Item className={classNames('list-icon-size', 'img-size-mob', 'price-sign-icon-img')}></Item>
                <div>
                  <Item className={classNames('list-column-header', 'item-header-name')}>
                    {priceSignItem?.number || 'Unspecified'}
                  </Item>
                  <Item className={classNames('list-column-content', 'item-primary-header-name')}>Price Sign</Item>
                </div>
              </div>
            </Item>
            <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
              <div>
                <Item className={classNames('list-column-header', 'item-primary-header-name')}>
                  {fixedItemDetails[0].name}
                </Item>
                <Item className={classNames('list-column-content')}>
                  <Item className={classNames('status-icon', fixedItemDetails[0].healthIndicator)} />
                  {fieldMappingHelper.getDisplayValue(priceSignItem.status, PriceSignStatusType)}
                </Item>
              </div>
            </Item>
            <Item className={classNames('item-primary-header', 'item-primary-header-tablet')}>
              <div>
                <Item className={classNames('list-column-header', 'item-primary-header-name')}>Last Status Update</Item>
                <Item className={classNames('list-column-content')}>{fixedItemDetails[1].value}</Item>
              </div>
            </Item>
          </Item>
          {displayMenu ? (
            <Item className={classNames('row-item-ellipse')}>
              <FloatingMenu
                key={priceSignItem.id}
                buttonNode={<MoreVertIcon className='ellipse-icon' />}
                items={actionItems}
              />
            </Item>
          ) : (
            <Box className='box-item' onClick={onItemClick}></Box>
          )}
        </Box>
        <CollapsibleCard
          id={priceSignItem.id}
          itemDetails={moreItemDetails}
          fixeditemDetails={fixedItemDetails}
          rowViewItemDetails={priceSignItem?.messages}
          showMoreDetailText={priceSignItem?.messages && priceSignItem?.messages?.length > 0 && StatusDetail()}
          onClick={onItemClick}
        ></CollapsibleCard>
      </Card>
    </>
  );
};

export default PriceSignItem;
