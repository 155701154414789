import { LoadingStatus } from "../../constants/loading-constants";
import { ProductInfoModel } from "../../models/productModel"; 
import KeyValuePair from "../../models/baseModels/keyValuePairModel";

interface ProductState{
    productStatus: string;
    productData: ProductInfoModel[];
    productItem: ProductInfoModel;
    hasError: boolean;
    productListName: KeyValuePair[];
}

export const initialState: ProductState = {
    productStatus: LoadingStatus.LOADING
} as ProductState;