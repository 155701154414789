import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectNavPaneStatus } from './../../store/nav-pane/selectors';
import { selectOrganisationId } from './../../store/auth/selectors';
import { selectProductData, selectProductStatus } from '../../store/product/selectors';
import * as actions from '../../store/product/actions';
import * as headerActions from '../../store/header/actions';
import * as modalActions from '../../store/modals/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import Products from './products-list';

const mapStateToProps = (state: RootState) => {
  return {
    selectedOrganisationId: selectOrganisationId(state),
    productContent: selectProductData(state),
    productStatus: selectProductStatus(state),
    authStatus: selectAuthStatus(state),
    navPaneState: selectNavPaneStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadProduct: () => dispatch({ type: actions.LOAD_PRODUCTS }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    deleteProductItem: (id: string) => dispatch({ type: actions.DELETE_PRODUCT, payload: id }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const ProductList = connect(mapStateToProps, mapDispatchToProps)(Products);

export default ProductList;
