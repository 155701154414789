import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './styles/progress-bar.scss';
import LinearProgress from '@mui/material/LinearProgress';

interface ProgressBarProps {
  progress?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = (
  props: ProgressBarProps
) => {
  const { progress } = props;
  const [progressValue, setProgressValue] = useState(0);

  useEffect(()=>{
    setProgressValue(!!progress? progress: 0);
  },[progress])

  return (
    <div className={classNames('progress-bar-container')}>
      <LinearProgress variant='determinate' value={progressValue} className='progress-bar' color="success" />
      <div className='progress-bar-value'>{`${progressValue}%`}</div>
    </div>
  );
};

export default ProgressBar;
