import KeyValuePair from '../models/baseModels/keyValuePairModel';

export const DEFAULT_DROP_DOWN_VALUE = 'Select';
export const DEFAUL_HARDWARE_VARIANT_VALUE = 'controller';

export const currency: KeyValuePair[] = [
  { key: 'NZD', value: 'New Zealand Dollar' },
  { key: 'AUD', value: 'Australian Dollar' },
];

export const timezone: KeyValuePair[] = [
  { key: 'Australia/Adelaide', value: 'Adelaide' },
  { key: 'Pacific/Auckland', value: 'Auckland' },
  { key: 'Australia/Brisbane', value: 'Brisbane' },
  { key: 'Australia/Broken_Hill', value: 'Broken Hill' },
  { key: 'Pacific/Chatham', value: 'Chatham Islands' },
  { key: 'Australia/Darwin', value: 'Darwin' },
  { key: 'Australia/Eucla', value: 'Eucla' },
  { key: 'Australia/Hobart', value: 'Hobart' },
  { key: 'Australia/Lindeman', value: 'Lindeman' },
  { key: 'Australia/Lord_Howe', value: 'Lord Howe' },
  { key: 'Australia/Melbourne', value: 'Melbourne' },
  { key: 'Australia/Perth', value: 'Perth' },
  { key: 'Australia/Sydney', value: 'Sydney' },
];

export const baudRate: KeyValuePair[] = [
  { key: 'default', value: 'Default' },
  { key: '1200', value: '1200' },
  { key: '2400', value: '2400' },
];

export const communicationProtocol: KeyValuePair[] = [
  { key: 'internal', value: 'Internal' },
  { key: 'compac', value: 'Compac' },
  { key: 'pec', value: 'PEC' },
  { key: 'gilbarco', value: 'Gilbarco Australia' },
  { key: 'doms', value: 'Doms' },
  { key: 'enabler', value: 'Enabler' },
];

export const displayType: KeyValuePair[] = [
  { key: 'fiveDigit', value: '5 Digit' },
  { key: 'sixDigit', value: '6 Digit' },
  { key: 'sevenDigit', value: '7 Digit' },
];

export const pumpSettingsFlags: KeyValuePair[] = [{ key: '1', value: '1' }];

export const pumpProtocolVersion: KeyValuePair[] = [
  { key: '1', value: '1' },
  { key: '2', value: '2' },
];

export const presetMessageType: KeyValuePair[] = [
  { key: 'preset2Digits', value: 'Preset 2 Digits' },
  { key: 'preset4Digits', value: 'Preset 4 Digits' },
  { key: 'allocation', value: 'Allocation' },
  // TODO: support more messae type
  // { key: 'compac', value: 'Compac' },
  // { key: 'pec', value: 'Pec' },
];

export const printerType: KeyValuePair[] = [
  { key: 'none', value: 'None' },
  { key: 'tg2460h', value: 'TG2460H' },
  { key: 'tg2460hNoCutter', value: 'TG2460H (No Cutter)' },
  { key: 'vkp80iii', value: 'VKP80III' },
];

export const hardwareVariant: KeyValuePair[] = [
  { key: 'hybrid', value: 'Hybrid' },
  { key: 'controller', value: 'Controller' },
];

export const authMode: KeyValuePair[] = [
  { key: 'magtek', value: 'Magtek Card Reader' },
  { key: 'windcave', value: 'Windcave' },
  { key: 'windcaveNoCvm', value: 'Windcave (No cardholder verification)' },
  { key: 'hid', value: 'HID' },
  { key: 'keypadEntry', value: 'Keypad Entry' },
  { key: 'opticonScanner', value: 'Opticon Scanner' },
];

export const peripheralConfiguration: KeyValuePair[] = [
  { key: 'notConnected', value: 'Not Connected' },
  { key: 'activeHigh', value: 'Active High' },
  { key: 'activeLow', value: 'Active Low' },
  { key: 'disabled', value: 'Disabled' },
];

export const transactionType: KeyValuePair[] = [
  { key: 'Authorisation', value: 'Authorisation' },
  { key: 'Void', value: 'Void' },
  { key: 'Completion', value: 'Completion' },
];

export const transactionState: KeyValuePair[] = [
  { key: 'approved', value: 'Approved' },
  { key: 'completed', value: 'Completed' },
  { key: 'declined', value: 'Declined' },
  { key: 'voided', value: 'Voided' },
  { key: 'completionFailed', value: 'Completion Failed' },
  { key: 'voidFailed', value: 'Void Failed' },
];

export const transactionAcquirerName: KeyValuePair[] = [
  { key: 'compac', value: 'Compac' },
  { key: 'windcave', value: 'Windcave' },
  { key: 'wex', value: 'Wex' },
];

export const transactionAcquirerState: KeyValuePair[] = [
  { key: 'online', value: 'Online' },
  { key: 'offline', value: 'Offline' },
];

export const binRangeType: KeyValuePair[] = [{ key: 'authorisation', value: 'Authorisation' }];

export const cardType: KeyValuePair[] = [
  { key: 'account', value: 'Account' },
  { key: 'bank', value: 'Bank' },
  { key: 'ampol', value: 'Ampol' },
  { key: 'wex', value: 'Wex' },
  { key: 'bpSterling', value: 'BP Sterling' },
  // TODO: support more card type
  // { key: 'prepay', value: 'Prepay' },
  // { key: 'shell', value: 'Shell' },
];

export const processingMethod: KeyValuePair[] = [
  { key: 'windcave', value: 'Windcave' },
  { key: 'compac', value: 'Compac' },
];

export const operationType: KeyValuePair[] = [
  { key: 'fileUpload', value: 'Retrieve File' },
  { key: 'terminalRestart', value: 'Terminal Restart' },
  { key: 'bootloaderRestart', value: 'Bootloader Restart' },
  { key: 'memoryDump', value: 'Memory Dump' },
];

export const bulkOperationType: KeyValuePair[] = [
  { key: 'cardImport', value: 'Card Import' },
  { key: 'cardDelete', value: 'Card Delete' },
];

export const bulkActionType: KeyValuePair[] = [
  { key: 'created', value: 'Created' },
  { key: 'updated', value: 'Updated' },
  { key: 'deleted', value: 'Deleted' },
  { key: 'skipped', value: 'Skipped' },
];

export const fileUploadType: KeyValuePair[] = [
  { key: 'create', value: 'Create' },
  { key: 'append', value: 'Append' },
  { key: 'streamCreate', value: 'Stream Create' },
];

export const operationState: KeyValuePair[] = [
  { key: 'pending', value: 'Pending' },
  { key: 'running', value: 'Running' },
  { key: 'paused', value: 'Paused' },
  { key: 'completed', value: 'Completed' },
  { key: 'cancelled', value: 'Cancelled' },
  { key: 'error', value: 'Error' },
];

export const PromptTypes: KeyValuePair[] = [
  { key: 'odometer', value: 'Odometer' },
  { key: 'engineHours', value: 'Engine Hours' },
  { key: 'authorisationAmount', value: 'Authorisation Amount' },
  { key: 'orderNumber', value: 'Order Number' },
  { key: 'vehicleNumber', value: 'Vehicle Number' },
  { key: 'pin', value: 'PIN' },
  { key: 'productConfirmation', value: 'Product Confirmation' },
  { key: 'secondPump', value: 'Second Pump' },
];

export const protocolTypes: KeyValuePair[] = [
  { key: 'dresserWayneScandinavia', value: 'Dresser Wayne Scandinavia' },
  //  TODO: support more protocol type
  // { key: 'novyc', value: 'Novyc' },
];

export const priceSignProtocolBaudRate: KeyValuePair[] = [{ key: '1200', value: '1200' }];

export const productCodeTypes: KeyValuePair[] = [
  { key: 'wex', value: 'Wex' },
  { key: 'doms', value: 'Doms' },
];
export const productUnitOfMeasure: KeyValuePair[] = [{ key: 'litre', value: 'Litres' }];

export const tankGaugeType: KeyValuePair[] = [
  { key: 'veederRoot', value: 'Veeder Root' },
  { key: 'currentLoop', value: 'Current Loop Sensor' },
  { key: 'manual', value: 'Manual' },
  // TODO: support more gauge type
  // { key: 'fafnir', value: 'Fafnir' },
];

export const MeasuringCapability: KeyValuePair[] = [
  { key: 'productVolume', value: 'Product Volume' },
  // TODO: support more capability
  // { key: 'temperature', value: 'Temperature' },
];

export const distanceUnitOfMeasure: KeyValuePair[] = [{ key: 'millimetre', value: 'Millimetres' }];

export const currentUnitOfMeasure: KeyValuePair[] = [{ key: 'milliAmpere', value: 'Milliamps' }];

export const notificationMethod: KeyValuePair[] = [
  { key: 'email', value: 'Email' },
  { key: 'sms', value: 'SMS' },
];

export const forecourtControllerType: KeyValuePair[] = [
  { key: 'none', value: 'None' },
  { key: 'postec', value: 'Postec' },
  { key: 'doms', value: 'Doms' },
  { key: 'enabler', value: 'Enabler' },
];

export const forecourtControllerBackOfficeRecordFormat: KeyValuePair[] = [
  { key: 'none', value: 'None' },
  { key: 'standard', value: 'Standard' },
  { key: 'ampol', value: 'Ampol' },
];

export const forecourtControllerCommunicationType: KeyValuePair[] = [
  { key: 'network', value: 'Network' },
  { key: 'serial', value: 'Serial' },
];

export const alternateAuthorisationAmountOption: KeyValuePair[] = [{ key: 'pump', value: 'Pump' }];

export const tankGuagingSensorPositionOption: KeyValuePair[] = [
  { key: 'top', value: 'Top' },
  { key: 'bottom', value: 'Bottom' },
];

export const countryCodeOption: KeyValuePair[] = [
  { key: '+61', value: 'AU +61', additionalValue: 'au-flag' },
  { key: '+64', value: 'NZ +64', additionalValue: 'nz-flag' },
];

export const offsetUnitOfMeasure: KeyValuePair[] = [
  { key: 'bytes', value: 'Bytes' },
  { key: 'kilobytes', value: 'Kilobytes' },
  { key: 'megabytes', value: 'Megabytes' },
];

export const tankDeliveryType: KeyValuePair[] = [
  { key: 'delivery', value: 'Delivery' },
  { key: 'adjustment', value: 'Adjustment' },
];
