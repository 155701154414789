import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { OrganisationItemModel } from '../../../models/organisationModel';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import * as organisationActions from '../../../store/organisations/actions';
import {
  selectOrganisationItem,
  selectOrganisationStatus,
} from '../../../store/organisations/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import Organisation from './organisation';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import * as headerActions from '../../../store/header/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import * as fieldActions from '../../../store/fieldValidation/actions';

const mapStateToProps = (state: RootState) => {
  return {
    organisationItem: selectOrganisationItem(state),
    organisationStatus: selectOrganisationStatus(state),
    hasValidationError: selectHasValidationError(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
    backDropActionStatus: selectBackdropActionStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    editOrganisation: (item: OrganisationItemModel) =>
      dispatch({ type: organisationActions.EDIT_ORGANISATION, payload: item }),
    createOrganisation: (item: OrganisationItemModel) =>
      dispatch({
        type: organisationActions.CREATE_ORGANISATION,
        payload: item,
      }),
    loadSelectedOrganisation: (id?: string) =>
      dispatch({
        type: organisationActions.LOAD_SELECTED_ORGANISATION,
        payload: id,
      }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
      setOrganisationEditState:(data: boolean) =>
      dispatch({
        type: organisationActions.SET_ORGANISATION_EDIT_STATE,
        payload: data,
      }),
      removeAllValidation: () =>
      dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const OrganisationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Organisation);

export default OrganisationPage;
