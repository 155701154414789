import { RootState } from './../rootReducers';
import { createSelector } from 'reselect';

export const selectPriceSchedState = (state:RootState) => state.PriceScheduleState;

export const selectPriceSchedContent = createSelector(
    selectPriceSchedState,
    state => state.priceSchedContent
);

export const selectPriceSchedStatus = createSelector(
    selectPriceSchedState,
    state => state.priceSchedStatus
);

export const selectPriceSchedDetailStatus = createSelector(
    selectPriceSchedState,
    state => state.priceSchedDetailStatus
);

export const selectPriceSchedInfo = createSelector(
    selectPriceSchedState,
    state => state.priceSchedInfo
);

export const selectPricSchedListStatus = createSelector(
    selectPriceSchedState, 
    state => state.priceSchedListError
);

