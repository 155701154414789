export const setUpEnv = () => {
  if (process.env.REACT_APP_USE_LOCAL === 'true') {
    window.API_URL = process.env.REACT_APP_API_LOCAL_URL;
  } else   {
    window.API_URL = process.env.REACT_APP_API_URL;
  }

  return;
};


export const AppPollInterval: number = process.env.REACT_APP_POLL_INTERVAL
  ? !isNaN(Number(process.env.REACT_APP_POLL_INTERVAL))
    ? 15000
    : Number(process.env.REACT_APP_POLL_INTERVAL)
  : 15000;
