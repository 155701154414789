import { LoadingStatus } from '../../constants/loading-constants';
import { OrganisationItemModel } from '../../models/organisationModel';

interface OrganisationState {
  organisationStatus: string;
  organisationContent: OrganisationItemModel[];
  organisationItem: OrganisationItemModel;
  organisationEditState: boolean;
  hasError: boolean;
}

export const initialState: OrganisationState = {
  organisationStatus: LoadingStatus.LOADING,
  organisationEditState: false,
  hasError: false
} as OrganisationState;
