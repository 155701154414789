import { PriceSignEntity } from '../../entities/priceSign';
import api, { setAuthorize } from '../../utilities/api';

const PRICE_SIGN_API_PREFIX = 'price-sign-management';

export const getAllPriceSignData = async (organisationId: string, siteId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${PRICE_SIGN_API_PREFIX}/price-signs?organisationId=${organisationId}&siteId=${siteId}`
    );
  return result.data;
};

export const getAllPriceSignStatuses = async (organisationId: string, siteId: string) => {
  await setAuthorize();
  let result = await api.get(
    `/${PRICE_SIGN_API_PREFIX}/price-signs/status?organisationId=${organisationId}&siteId=${siteId}`
    );
  return result.data;
}

export const getPriceSignById = async (
    id: string,
    organisationId: string
  ) => {
    await setAuthorize();
    let result = await api.get(
      `/${PRICE_SIGN_API_PREFIX}/price-signs/${id}?organisationId=${organisationId}`
    );
    return result.data;
  };
  
  export const createPriceSign = async (data: PriceSignEntity) => {
    await setAuthorize();
    await api.post(`/${PRICE_SIGN_API_PREFIX}/price-signs`, data);
  };
  
  export const editPriceSign = async (
    data: PriceSignEntity,
    id: string
  ) => {
    await setAuthorize();
    await api.put(
      `/${PRICE_SIGN_API_PREFIX}/price-signs/${id}`,
      data
    );
  };
  
  export const deletePriceSign = async (
    id: string,
    organisationId: string
  ) => {
    await setAuthorize();
    await api.delete(
      `/${PRICE_SIGN_API_PREFIX}/price-signs/${id}?organisationId=${organisationId}`
    );
  };
  