import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { LoadingStatus } from '../../../constants/loading-constants';
import { NavPaneStates } from '../../../constants/window-constants';
import { ModuleName } from '../../../constants/module-constants';
import { Messages } from '../../../constants/messages';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { BulkOperationDetailsModel } from '../../../models/cardOperationModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import LoadingDisplay from '../../../components/loading-spinner/loading-display';
import EmptyList from '../../../components/empty-list/empty-list';
import FooterBar from '../../../components/footer/footer';
import ErrorDisplayControl from '../../../components/error-display/error-display.container';
import MessageDisplay from '../../../components/message-display/message-display';
import Wizard from '../../../components/wizard/wizard';
import OperationItem from './operation-item';

interface OperationDetailsProps {
  bulkOperationState: string;
  operationDetails: BulkOperationDetailsModel;
  navPaneState: string;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  initLoadOperationById: (data: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const OperationDetails: React.FC<OperationDetailsProps> = (props: OperationDetailsProps) => {
  const {
    bulkOperationState,
    operationDetails,
    navPaneState,
    authStatus,
    userAccess,
    initLoadOperationById,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const { orgId, operationId } = useParams();

  const [operationDetailsToDisplay, setOperationDetailsToDisplay] = useState(operationDetails);
  const [emptyListMessage, setEmptyListMessage] = useState('No Details');

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.CARD_OPERATION).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(bulkOperationState === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(bulkOperationState === LoadingStatus.SUCCESS && hasReadAccess);
    setError(bulkOperationState === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, bulkOperationState]);

  useEffect(() => {
    setHeaderConfiguration({
      showOrganisation: true,
      title: 'Operation Details',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showWizard: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (authSuccess && hasReadAccess && operationId) {
      initLoadOperationById(operationId);
    }
  }, [authSuccess, hasReadAccess, operationId, initLoadOperationById]);

  useEffect(() => {
    setOperationDetailsToDisplay(operationDetails);
  }, [operationDetails]);

  const operationDetailList =
    operationDetails?.result?.items &&
    operationDetails?.result?.items.map((it) => <OperationItem key={it.pan} operationDetails={it}></OperationItem>);

  const wizardList = [
    { key: 'Cards', value: `/organisations/${orgId}/cards` },
    { key: 'Bulk Operations', value: `/organisations/${orgId}/cards/card-operations` },
    { key: 'Operation Details', value: `/organisations/${orgId}/cards/card-operations/${operationId}/details` },
  ] as KeyValuePair[];

  return (
    <>
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {error && <ErrorDisplayControl />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {success && (
        <div className='operation-details'>
          <div
            className={classNames('search-container', {
              'list-expanded-width': navPaneState === NavPaneStates.EXPANDED,
              'list-collapsed-width':
                navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
              'list-hamburger-width':
                navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
            })}
          >
            <Wizard wizardList={wizardList} />

            {/* TODO: ADD SEARCH BAR */}
            {/* <SearchBar
              placeholder={'Search PAN'}
              filterDetails={filterDetails}
              onKeyUp={onKeyUpHandler}
              onFilterValueChange={onFilterValueChangeHandler}
              onClearFilter={onClearFilterHandler}
              ref={searchInputRef}
            /> */}

            {operationDetails && operationDetailsToDisplay?.result?.items.length > 0 && (
              <div className='detail-list-header'>
                <div className='detail-pan'>PAN</div>
                <div className='detail-bin-range'>BIN Range</div>
                <div className='detail-status'>Status</div>
                <div className='detail-action-type'>Action Type</div>
                <div className='detail-more-detail'>Details</div>
              </div>
            )}
          </div>

          {operationDetails && operationDetailsToDisplay?.result.items.length > 0 && (
            <div className='detail-list-container'>
              <div className='detail-list'>{operationDetailList}</div>
            </div>
          )}

          {!!operationDetails && !!operationDetailsToDisplay && operationDetailsToDisplay?.result?.items.length < 1 && (
            <div className='empty-div-container-extra-spacing'>
              <EmptyList message={emptyListMessage}></EmptyList>
            </div>
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar
            className={classNames(
              !!operationDetails && !!operationDetailsToDisplay && operationDetailsToDisplay?.result?.items.length < 1
                ? 'footer-no-content'
                : 'footer-with-content'
            )}
          />
        </div>
      }
    </>
  );
};

export default OperationDetails;
