import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { LoadingStatus } from '../../constants/loading-constants';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { TankStatusHistoryFilterModel, TankStatusHistoryModel } from '../../models/tankHistory';
import { selectOrganisationId } from '../auth/selectors';
import { setTankHistoryStatus, setTankHistoryData, setTankHistoryError } from './reducers';
import { setGenericErrorData } from '../generic-error/reducers';
import { TankHistoryResponse, TankStatusHistoryQuery } from '../../entities/tankHistory';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { setContinuationTokenList } from '../pagination/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as actions from './actions';
import * as services from './services';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TANK_STATUS_HISTORIES, loadTankStatusHistories);
}

export function* loadTankStatusHistories(action: PayloadAction<TankStatusHistoryFilterModel>) {
  try {
    if (!!action.payload) {
      yield put(setTankHistoryStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let request: TankStatusHistoryQuery = MapTankStatusHistoryFilterModelToEntity(action.payload, organisationId);

      let allData: TankStatusHistoryModel[] = [];
      let continuationToken: string | undefined;

      do {
        const response: TankHistoryResponse = yield call(services.getTankStatusHistories, request, continuationToken);
        const data: TankStatusHistoryModel[] = yield call(mapTankHistoryEntityToTankHistoryModel, response);
        allData = allData.concat(data);

        continuationToken = response?.continuationToken;
        if (continuationToken) {
          yield put(setContinuationTokenList(continuationToken));
        }

        // Update the data in the store
        yield put(setTankHistoryData(allData));
      } while (continuationToken);

      yield put(setTankHistoryStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTankHistoryError());
    yield put(clearAllFieldValidation());
  }
}

const mapTankHistoryEntityToTankHistoryModel = (response: TankHistoryResponse) => {
  const timezone = dateTimeHelper.getBrowserLocalTimezone();
  if (response && response.items.length > 0) {
    let result: TankStatusHistoryModel[] = response?.items?.map((tank, i) => {
      return {
        id: tank.id,
        number: fieldMappingHelper.validateNumericValue(tank.number),
        productId: tank.productId,
        siteId: tank.siteId,
        organisationId: tank.organisationId,
        status: tank.status,
        capacity: tank.capacity,
        product: tank.product,
        water: tank.water,
        ullage: tank.ullage,
        current: tank.current,
        temperature: tank.temperature,
        lastUpdatedDateTime: dateTimeHelper.formatDateTimebyTimeZone(timezone, tank.dateTimeUtc, 'YYYY-MM-DD HH:mm:ss'),
      } as TankStatusHistoryModel;
    });
    const uniqueEntries = new Set();
    result = result.filter((item) => {
      const key = `${item.lastUpdatedDateTime}`;
      if (!uniqueEntries.has(key)) {
        uniqueEntries.add(key);
        return true;
      }
      return false;
    });
    return result;
  }
  return [] as TankStatusHistoryModel[];
};

const MapTankStatusHistoryFilterModelToEntity = (request: TankStatusHistoryFilterModel, organisationId: string) => {
  const requestEntity: TankStatusHistoryQuery = {
    organisationId: organisationId,
    tankId: request.tankId,
    startDateTimeUtc: dateTimeHelper.convertDateStringtoUTCString(request?.startDateTime),
    endDateTimeUtc: dateTimeHelper.convertEndDateStringtoUTCString(request?.endDateTime),
  };

  return requestEntity;
};
