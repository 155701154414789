import React, { useEffect, useState } from 'react';
import { Box, Typography, Alert, AlertTitle } from '@mui/material';
import { FileUploadIcon, FileUploadedIcon, FileUploadedToCloudIcon } from '../icons';
import { LoadingStatus } from '../../constants/loading-constants';
import * as fileDownload from '../../utilities/fileDownload-helper';
import './file-uploader.scss';

export type FileUploadProps = {
  fieldLabel?: string;
  componentLabel: string;
  isMandatory?: boolean;
  readOnly?: boolean;
  infoHelper?: string;
  uploadProgress?: string;
  templateData?: string;
  templateFileName?: string;
  isDataUploadFromLocal?: boolean;
  clickOrDropHandler?: () => void;
  setFileUploadedToComponent?: (data: File) => void;
  dataFromLocalFile?: (data: any) => void;
};

export const FileUploader: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  const {
    fieldLabel,
    componentLabel,
    isMandatory,
    readOnly,
    infoHelper,
    uploadProgress,
    templateData,
    templateFileName,
    isDataUploadFromLocal,
    clickOrDropHandler,
    setFileUploadedToComponent,
    dataFromLocalFile,
  } = props;
  const [componentText, setComponentText] = useState(componentLabel);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isFileReadyForDrop, setIsFileReadyForDrop] = useState(false);

  useEffect(() => {
    setComponentText(componentLabel);
  }, [uploadProgress]);

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dropLabel = 'Drop file here';

  useEffect(() => {
    if (uploadedFile !== undefined && uploadedFile !== null && setFileUploadedToComponent) {
      setFileUploadedToComponent(uploadedFile);
    }
  }, [uploadedFile, setFileUploadedToComponent]);

  const onChange = async (event: any) => {
    if (event.target.files !== null && event.target?.files?.length > 0) {
      setFileName(event.target.files[0].name);
      if (isDataUploadFromLocal && dataFromLocalFile) {
        dataFromLocalFile(await getFileData(event.target.files[0]));
      }
    }
  };

  const onDrop = async (event: any) => {
    if (event.dataTransfer.files[0]) {
      if (!event.dataTransfer.files[0].name.endsWith('.csv')) {
        setFileName('');
        return;
      }
      if (event.dataTransfer.files[0].size > 50 * 1024 * 1024) {
        alert('File size exceeds the limit of 50MB');
        setFileName('');
        return;
      } else {
        setFileName(event.dataTransfer.files[0].name);
        setUploadedFile(event.dataTransfer.files[0]);
        if (isDataUploadFromLocal && dataFromLocalFile) {
          dataFromLocalFile(await getFileData(event.dataTransfer.files[0]));
        }
      }
    }
  };

  const getFileData = async (file: any) => {
    const response = await file;
    const data = await response.text();
    return data;
  };

  const dragEvents = {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setComponentText(dropLabel);
      setIsFileReadyForDrop(true);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setComponentText(componentLabel);
      setIsFileReadyForDrop(false);
    },
    onDragOver: (e: React.DragEvent) => {
      setComponentText(dropLabel);
      stopDefaults(e);
      setIsFileReadyForDrop(true);
    },
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setIsFileReadyForDrop(false);
      setComponentText(componentLabel);
      if (e.dataTransfer.files[0]) {
        onDrop(e);
      }
      if (clickOrDropHandler) clickOrDropHandler();
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files[0].size > 50 * 1024 * 1024) {
        alert('File size exceeds the limit of 50MB');
        setFileName('');
        return;
      } else setUploadedFile(event.target.files[0]);
    }
    onChange(event);
  };

  const handleClick = () => {
    if (clickOrDropHandler) clickOrDropHandler();
  };

  const onTemplateDownloadHandler = () => {
    if (templateData && templateFileName) fileDownload.downloadCSVTemplate(templateData, templateFileName);
  };

  return (
    <React.Fragment>
      {fieldLabel && (
        <div className='file-uploader-label'>
          {fieldLabel} {isMandatory && !readOnly && <strong className='asterisk'> *</strong>}
        </div>
      )}
      <div className='file-uploader-helper'>
        {infoHelper && (
          <Alert className='helper-info' variant='outlined' severity='info'>
            <AlertTitle>Info</AlertTitle>
            {infoHelper}
            {templateData && templateFileName && (
              <div className='template-download-container'>
                <span className='tempalte-download-link' onClick={onTemplateDownloadHandler}>
                  {`${templateFileName}.CSV`}
                </span>
              </div>
            )}
          </Alert>
        )}
      </div>

      <div className={`file-uploader ${isFileReadyForDrop ? 'file-uploader-ready-for-drop' : ''}`}>
        <input
          onChange={handleChange}
          onClick={handleClick}
          accept={'.csv'}
          className='uploader-input'
          id='file-upload'
          type='file'
        />

        <label htmlFor='file-upload' {...dragEvents} className='uploader-container'>
          <Box className='uploader-box'>
            {fileName && uploadProgress === LoadingStatus.ERROR && (
              <Box className='uploaded-failed-box'>
                <Typography className='failed-uploaded-text'>UPLOAD FAILED, PLEASE TRY AGAIN.</Typography>
                <Typography>{componentText}</Typography>
              </Box>
            )}

            {fileName && uploadProgress === LoadingStatus.SUCCESS && (
              <Box className='uploaded-display-box'>
                <FileUploadedToCloudIcon />
                <Typography>{fileName}</Typography>
              </Box>
            )}

            {fileName && uploadProgress === LoadingStatus.LOADING && (
              <Box className='uploaded-display-box'>
                <FileUploadedIcon className='file-upload-icon' />
                <Typography className='loading'>FILE UPLOADING</Typography>
              </Box>
            )}

            {fileName &&
              uploadProgress !== LoadingStatus.SUCCESS &&
              uploadProgress !== LoadingStatus.LOADING &&
              uploadProgress !== LoadingStatus.ERROR && (
                <Box className='uploader-display-box'>
                  <FileUploadIcon className='file-upload-icon' />
                  <Typography>{fileName}</Typography>
                </Box>
              )}

            {!fileName && (
              <Box className='uploader-display-box'>
                <FileUploadIcon className='file-upload-icon' />
                <Typography>{componentText}</Typography>
              </Box>
            )}
          </Box>
        </label>
      </div>
    </React.Fragment>
  );
};
