import classNames from 'classnames';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import Item from '../../components/box-items/box-item';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import CollapsibleCard from '../../components/collapsible-card/collapsible-card';
import LoadingSkeleton from '../../components/loading-skeleton/loading-skeleton';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { TankDetailModel, TankModel } from '../../models/tankModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { TankStatusType } from '../../constants/status';
import { ItemDetailModel } from '../../models/baseModels/itemDetailModel';
import { getMeasureSign } from '../../utilities/general-helper';
import { getStatusMessageCount } from '../../utilities/general-helpers';
import './styles/tank-item.scss';

interface TankItemProps {
  tankItem: TankModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  hasDeliveryReadAccess: boolean;
  onDelete: (id: string, name: number) => void;
  onEdit: (id: string) => void;
  onClick: (id: string) => void;
  onStatusHistory: (id: string) => void;
  onDeliveries: (id: string) => void;
}

const TankItem: React.FC<TankItemProps> = (props: TankItemProps) => {
  const {
    tankItem,
    hasDeleteAccess,
    hasUpdateAccess,
    hasDeliveryReadAccess,
    onDelete,
    onEdit,
    onClick,
    onStatusHistory,
    onDeliveries,
  } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let moreItemDetails: ItemDetailModel[] = [];

  let actionItems: FloatingMenuItem[] = [];
  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit',
      handler: () => {
        onEdit(tankItem.id);
      },
    });
  }

  if (tankItem) {
    actionItems.push({
      label: 'History',
      handler: () => {
        onStatusHistory(tankItem.id);
      },
    });
  }

  if (hasDeliveryReadAccess) {
    actionItems.push({
      label: 'Deliveries',
      handler: () => {
        onDeliveries(tankItem.id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete',
      handler: () => {
        onDelete(tankItem.id, tankItem.number);
      },
    });
  }

  const fixedItemDetails: TankDetailModel[] = [
    {
      name: 'Status',
      value: fieldMappingHelper.getDisplayValue(tankItem.status, TankStatusType),
      status: tankItem.status,
      healthIndicator: tankItem.healthIndicator,
    },
    {
      name: 'Last Status Update',
      value: tankItem.lastUpdatedDateTimeUtc ? tankItem.lastUpdatedDateTimeUtc : 'Unspecified',
      status: tankItem.status ? tankItem.status : 'Unspecified',
      healthIndicator: 'display-none',
    },
  ];

  if (tankItem?.capacity?.value !== undefined) {
    moreItemDetails.push({
      name: 'Capacity',
      value: `${tankItem?.capacity?.value} ${getMeasureSign(tankItem?.capacity?.unitOfMeasure)}`,
    });
  }

  if (tankItem?.product?.volume?.value !== undefined || tankItem?.product?.height?.value !== undefined) {
    moreItemDetails.push({
      name: 'Product',
      value: `${tankItem?.product?.volume?.value >= 0 ? tankItem?.product?.volume?.value + ' ' + getMeasureSign(tankItem?.product?.volume?.unitOfMeasure) : ''} ${
        tankItem?.product?.height?.value >= 0
          ? '/ ' + tankItem?.product?.height?.value + ' ' + getMeasureSign(tankItem?.product?.height?.unitOfMeasure)
          : ''
      }`,
      tooltipTitle: `${tankItem?.product?.volume?.value >= 0 ? 'Product Volume' : ''} ${tankItem?.product?.height?.value >= 0 ? ' / Product Height' : ''}`,
    });
  }

  if (tankItem?.product?.volume?.temperatureCompensatedValue !== undefined) {
    moreItemDetails.push({
      name: 'TC Volume',
      value: `${tankItem?.product?.volume?.temperatureCompensatedValue} ${getMeasureSign(
        tankItem?.product?.volume?.unitOfMeasure
      )}`,
      tooltipTitle: 'This value is temperature compensated',
    });
  }

  if (tankItem?.water?.height?.value) {
    moreItemDetails.push({
      name: 'Water',
      value: `${tankItem?.water?.volume?.value} ${getMeasureSign(tankItem?.water?.volume?.unitOfMeasure)} / ${
        tankItem?.water?.height?.value
      } ${getMeasureSign(tankItem?.water?.height?.unitOfMeasure)}`,
      tooltipTitle: 'Water Volume / Water Height',
    });
  }

  if (tankItem?.current?.value) {
    moreItemDetails.push({
      name: 'Current Loop Sensor',
      value: `${tankItem?.current?.value} ${getMeasureSign(tankItem?.current?.unitOfMeasure)}`,
    });
  }

  if (tankItem?.temperature?.value) {
    moreItemDetails.push({
      name: 'Temperature',
      value: `${tankItem?.temperature?.value} ${getMeasureSign(tankItem?.temperature?.unitOfMeasure)}`,
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(tankItem.id);
    }
  };

  const StatusDetail = () => {
    return (
      <span>{tankItem?.messages && tankItem?.messages?.length > 0 && getStatusMessageCount(tankItem?.messages)}</span>
    );
  };

  return (
    <Card className={classNames('box-card-parent')}>
      <Box className={classNames('primary-details')}>
        <Item className={classNames('box-row-columns', 'tank-item__primary-details')} onClick={onItemClick}>
          <Item className={classNames('box-row-item-header')}>
            <div className={classNames('icon-name-header')}>
              <Item className={classNames('list-icon-size', 'img-size-mob', 'tank-icon-img')}></Item>
              <div>
                <Item className={classNames('list-column-header', 'item-header-name')}>
                  <div className='tank-number-section'>
                    {tankItem?.number}{' '}
                    {tankItem?.productName ? tankItem?.productName : <LoadingSkeleton customWidth={120} />}
                  </div>
                </Item>
                <Item className={classNames('list-column-content', 'item-primary-header-name')}>Tank</Item>
              </div>
            </div>
          </Item>
          <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
            <div>
              <Item className={classNames('list-column-header', 'item-primary-header-name')}>
                {fixedItemDetails[0].name}
              </Item>
              <Item className={classNames('list-column-content')}>
                <Item className={classNames('status-icon', fixedItemDetails[0].healthIndicator)} />
                {fieldMappingHelper.getDisplayValue(tankItem.status, TankStatusType)}
              </Item>
            </div>
          </Item>
          <Item className={classNames('item-primary-header', 'item-primary-header-tablet')}>
            <div>
              <Item className={classNames('list-column-header', 'item-primary-header-name')}>Last Status Update</Item>
              <Item className={classNames('list-column-content')}>{fixedItemDetails[1].value}</Item>
            </div>
          </Item>
        </Item>
        {displayMenu ? (
          <Item className={classNames('row-item-ellipse')}>
            <FloatingMenu
              key={tankItem.id}
              buttonNode={<MoreVertIcon className='ellipse-icon' />}
              items={actionItems}
            />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Box>

      <CollapsibleCard
        id={tankItem.id}
        itemDetails={moreItemDetails}
        fixeditemDetails={fixedItemDetails}
        rowViewItemDetails={tankItem?.messages}
        showMoreDetailText={tankItem?.messages && tankItem?.messages?.length > 0 && StatusDetail()}
        onClick={onItemClick}
      ></CollapsibleCard>
    </Card>
  );
};

export default TankItem;
