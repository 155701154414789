import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';
import { setPageConfiguration, setIsPageDirty, setIsPageReachBottom, setIsCancelFromChange } from './reducers';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import * as actions from './actions';

export function* rootSaga() {
  yield takeLatest(actions.CHANGE_PAGE_CONFIGURATION, changePageConfiguration);
  yield takeLatest(actions.SET_IS_PAGE_DIRTY, changeIsPageDirty);
  yield takeLatest(actions.SET_IS_PAGE_REACH_BOTTOM, changeIsPageReachBottom);
  yield takeLatest(actions.SET_IS_CANCEL_FORM_CHANGE, cancelFromChange);
}

export function* changePageConfiguration(action: PayloadAction<PageSettingStateModel>) {
  yield put(setPageConfiguration(action.payload));
}

export function* changeIsPageDirty(action: PayloadAction<boolean>) {
  yield put(setIsPageDirty(action.payload));
}

export function* changeIsPageReachBottom(action: PayloadAction<boolean>) {
  yield put(setIsPageReachBottom(action.payload));
}

export function* cancelFromChange(action: PayloadAction<boolean>) {
  yield put(setIsCancelFromChange(action.payload));
}
