import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import * as actions from './actions';
import {
  setTransactionState,
  setTransactionData,
  setTransactionError,
  setTransactionFilter,
  setIsFilterActive,
} from './reducers';
import * as services from './services';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import { TransactionFilterRequest, TransactionResponse } from '../../entities/transaction';
import { TransactionFilterModel, TransactionModel } from '../../models/transactionModel';
import { selectOrganisationId, selectUserId } from '../auth/selectors';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { TRANSACTION_FILTER_STORAG_KEY } from '../../constants/transaction-constants';
import { selectTransactionFilter } from './selectors';
import { SetDataInLocalStorage } from '../../utilities/localStorage-helper';
import { PaginationModel } from '../../models/paginationModel';
import { selectContinuationToken, selectPaginationInfo, selectIsReachEnd } from '../pagination/selectors';
import { setContinuationTokenList, setIsReachEnd } from '../pagination/reducers';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_TRANSACTIONS, loadTransactions);
  yield takeLatest(actions.SAVE_TRANSACTIONS_FILTER, saveTransactionFilter);
}

export function* loadTransactions() {
  try {
    yield put(setTransactionState(LoadingStatus.LOADING));

    const organisationId: string = yield select(selectOrganisationId);
    const transactionFilter: TransactionFilterModel = yield select(selectTransactionFilter);
    const paginationInfo: PaginationModel = yield select(selectPaginationInfo);
    const continuationTokenList: string[] = yield select(selectContinuationToken);
    const isReachEnd: boolean = yield select(selectIsReachEnd);
    const fuzzyRequest: string = transactionFilter.pan ? transactionFilter.pan : '';
    let isFilterActive: boolean = CheckIfNonDefaultFilterApplied(transactionFilter);
    let request: TransactionFilterRequest = MapTransactionFilterModelToEntity(
      transactionFilter,
      organisationId,
      paginationInfo,
      continuationTokenList,
      isReachEnd
    );
    let response: TransactionResponse = yield call(services.getAllTransactions, request, fuzzyRequest);
    let records: TransactionModel[] = MapTransactionEntityToModel(response);
    yield put(setIsReachEnd(!response.continuationToken));
    yield put(setContinuationTokenList(response.continuationToken));
    yield put(setIsFilterActive(isFilterActive));
    yield put(setTransactionData(records));
    yield put(setTransactionState(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTransactionError());
  }
}

export function* saveTransactionFilter(action: PayloadAction<TransactionFilterModel>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const userId: string = yield select(selectUserId);
    yield call(SetDataInLocalStorage, `${TRANSACTION_FILTER_STORAG_KEY}-${organisationId}-${userId}`, action.payload);
    yield put(setTransactionFilter(action.payload));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTransactionError());
  }
}

const MapTransactionEntityToModel = (response: TransactionResponse) => {
  if (response && response.items.length > 0) {
    const result: TransactionModel[] = response.items.map((item, i) => {
      return {
        id: item.id,
        organisationId: item.organisationId,
        siteName: item?.site?.name,
        siteId: item?.site?.id,
        terminalId: item.terminal.id,
        terminalNumber: item.terminal.number,
        correlationId: item.correlationId,
        type: item.type,
        state: item.state,
        cardPan: item.card.pan,
        binRange: item.card?.binRange?.name,
        amountValue: item.amount.value,
        amountCurrencyCode: item.amount.currencyCode,
        dateTimeUtc: item.dateTimeUtc,
        reference: item.reference,
        responseCode: item.responseCode,
        responseText: item.responseText,
        receipt: item.receipt,
        acquirer: item.acquirer,
        cardType: item.card.type,
        pumps: item.pumps?.map((pump) => {
          return {
            id: pump.id,
            number: pump.number,
            amount: pump?.amount,
            products: pump.products,
          };
        }),
        odometer: item.odometer,
        engineHours: item.engineHours,
        orderNumber: item.orderNumber,
        vehicleNumber: item.vehicleNumber,
        tags: item?.card?.tags,
      } as TransactionModel;
    });
    return result;
  }
  return [] as TransactionModel[];
};

const MapTransactionFilterModelToEntity = (
  request: TransactionFilterModel,
  organisationId: string,
  paginationInfo: PaginationModel,
  continuationTokenList: string[],
  isReachEnd: boolean
) => {
  let startDateTime: string | undefined = undefined;
  let endDateTime: string | undefined = undefined;
  let currentDate = new Date();

  if (request && request?.dateRange === 'today') {
    const todayStart = new Date(currentDate);
    todayStart.setHours(0, 0, 0, 0);

    const todayEnd = new Date(currentDate);
    todayEnd.setHours(23, 59, 59, 999);

    startDateTime = String(todayStart);
    endDateTime = String(todayEnd);
  }

  if (request && request?.dateRange === 'yesterday') {
    const yesterdayStart = new Date(currentDate);
    yesterdayStart.setDate(currentDate.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);

    const yesterdayEnd = new Date(currentDate);
    yesterdayEnd.setDate(currentDate.getDate() - 1);
    yesterdayEnd.setHours(23, 59, 59, 999);

    startDateTime = String(yesterdayStart);
    endDateTime = String(yesterdayEnd);
  }

  if (request && request?.dateRange === 'thisWeek') {
    const today = currentDate.getDay();
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - today + 1);
    mondayDate.setHours(0, 0, 0, 0);

    const sundayDate = new Date(currentDate);
    sundayDate.setDate(currentDate.getDate() + (7 - today));
    sundayDate.setHours(23, 59, 59, 999);

    startDateTime = String(mondayDate);
    endDateTime = String(sundayDate);
  }

  if (request && request?.dateRange === 'lastWeek') {
    const currentDayOfWeek = currentDate.getDay();
    const daysToLastMonday = (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) + 7;
    const lastMondayDate = new Date(currentDate);
    lastMondayDate.setDate(currentDate.getDate() - daysToLastMonday);
    lastMondayDate.setHours(0, 0, 0, 0);

    const daysToLastSunday = currentDayOfWeek === 0 ? 0 : currentDayOfWeek;
    const lastSundayDate = new Date(currentDate);
    lastSundayDate.setDate(currentDate.getDate() - daysToLastSunday);
    lastSundayDate.setHours(23, 59, 59, 999);

    startDateTime = String(lastMondayDate);
    endDateTime = String(lastSundayDate);
  }

  if (request && request?.dateRange === 'thisMonth') {
    const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    firstDateOfMonth.setHours(0, 0, 0, 0);

    const nextMonth = currentDate.getMonth() + 1;
    const lastDateOfMonth = new Date(currentDate.getFullYear(), nextMonth, 0);
    lastDateOfMonth.setHours(23, 59, 59, 999);

    startDateTime = String(firstDateOfMonth);
    endDateTime = String(lastDateOfMonth);
  }

  if (request && request?.dateRange === 'lastMonth') {
    const lastMonth = currentDate.getMonth() - 1;
    const firstDateOfLastMonth = new Date(currentDate.getFullYear(), lastMonth, 1);
    firstDateOfLastMonth.setHours(0, 0, 0, 0);

    const lastDateOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    lastDateOfLastMonth.setHours(23, 59, 59, 999);

    startDateTime = String(firstDateOfLastMonth);
    endDateTime = String(lastDateOfLastMonth);
  }

  if (request && request?.dateRange === 'custom') {
    startDateTime = request?.startDateTime;
    endDateTime = request?.endDateTime;
  }

  const requestEntity: TransactionFilterRequest = {
    organisationId: organisationId,
    siteId: fieldMappingHelper.sanitizeStringDropDownValue(request?.siteId ? request?.siteId : undefined),
    type: fieldMappingHelper.sanitizeStringDropDownValue(request?.type ? request?.type : undefined),
    reference: fieldMappingHelper.sanitizeStringValue(request?.reference),
    state: fieldMappingHelper.sanitizeStringDropDownValue(request?.state ? request?.state : undefined),
    startDateTimeUtc: dateTimeHelper.convertDateStringtoUTCString(startDateTime),
    endDateTimeUtc: dateTimeHelper.convertEndDateStringtoUTCString(endDateTime),
    binRangeId: fieldMappingHelper.sanitizeStringDropDownValue(request?.binRange ? request?.binRange : undefined),
    acquirerName: fieldMappingHelper.sanitizeStringDropDownValue(
      request?.acquirerName ? request?.acquirerName : undefined
    ),
    cardType: fieldMappingHelper.sanitizeStringDropDownValue(request?.cardType ? request?.cardType : undefined),
    limit: fieldMappingHelper.sanitizeNumericValue(paginationInfo.limit),
    continuationToken: fieldMappingHelper.sanitizeStringValue(
      paginationInfo.page > 0 && !isReachEnd
        ? paginationInfo.isClickNext
          ? continuationTokenList[continuationTokenList.length - 1]
          : continuationTokenList[continuationTokenList.length - 3]
        : isReachEnd
          ? continuationTokenList[continuationTokenList.length - 3]
          : ''
    ),
    cardTags: request?.tags?.filter(
      (tag) =>
        fieldMappingHelper.sanitizeStringWithoutEmptySpaceBeforeOrAfter(tag.key) ||
        fieldMappingHelper.sanitizeStringWithoutEmptySpaceBeforeOrAfter(tag.value)
    ),
  };
  return requestEntity;
};

const CheckIfNonDefaultFilterApplied = (filter: TransactionFilterModel) => {
  if (!!filter?.siteId) {
    if (filter?.siteId !== 'Select') {
      return true;
    }
  }
  if (!!filter?.reference) return true;
  if (!!filter?.pan) return true;
  if (!!filter?.state) {
    if (filter?.state !== 'Select') {
      return true;
    }
  }
  if (!!filter?.startDateTime) return true;
  if (!!filter?.endDateTime) return true;
  if (!!filter?.type) {
    if (filter?.type !== 'Select') return true;
  }
  if (!!filter?.binRange) {
    if (filter?.binRange !== 'Select') {
      return true;
    }
  }
  if (!!filter?.acquirerName) {
    if (filter?.acquirerName !== 'Select') {
      return true;
    }
  }
  if (!!filter?.cardType) {
    if (filter?.cardType !== 'Select') {
      return true;
    }
  }
  if (!!filter?.tags?.some((tag) => tag.key.trim() !== '' || tag.value.trim() !== '')) return true;
  return false;
};
