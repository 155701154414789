import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useMatches, useResolvedPath, useParams} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import { HideNavIcon } from '../../../components/icons';
import { NavPaneStates } from '../../../constants/window-constants';
import '../styles/nav-pane.scss';
import { OrganisationItemModel } from '../../../models/organisationModel';
import Avatar from '../../../components/avatar/avatar';
import NavPaneTooltip from '../navPaneTooltip/index.container';
import classNames from 'classnames';
import { checkIsTextEllipsis } from '../../../utilities/general-helper';

interface NavPaneFooterProps {
  navPaneState: string;
  collapseLeftNavBarHandler: () => void;
  activeOrganisation: OrganisationItemModel;
  selectSelectedMenuIndex: number;
}

const NavPaneFooter: React.FC<NavPaneFooterProps> = (props: NavPaneFooterProps) => {
  const { navPaneState, collapseLeftNavBarHandler, activeOrganisation } = props;

  const navigate = useNavigate();
  const [isTextEllipsis, setIsTextEllipsis] = useState<boolean>(false);
  const NavPaneOrgText = useRef<HTMLDivElement | null>(null);

  const onAccountLogoClick = () => {
    navigate('/organisations');
  };

  const updateTooltipDisplay = () => {
    if (checkIsTextEllipsis(NavPaneOrgText.current)) {
      setIsTextEllipsis(true);
      return;
    }
    setIsTextEllipsis(false);
  };

  useEffect(() => {
    updateTooltipDisplay();
  }, [activeOrganisation]);

  useEffect(() => {
    window.addEventListener('resize', updateTooltipDisplay);
    return () => window.removeEventListener('resize', updateTooltipDisplay);
  }, []);

  const {orgId} = useParams();

  let resolved = useResolvedPath('/organisations');
  let match = useMatches();
  let isMatch = false;
  if (!!match) {
    isMatch =
      match.filter((m) => m.pathname.toLowerCase() === resolved.pathname).length > 0 ||
      match.filter((m) => m.pathname.toLowerCase() === resolved.pathname + '/create').length > 0 ||
      match.filter((m) => m.pathname.toLowerCase() === `${resolved.pathname}/${orgId}/edit`).length > 0;
  }

  return (
    <div className='bottom-nav'>
      <div
        className={classNames('account-container', { 'account-container-active': isMatch })}
        onClick={onAccountLogoClick}
      >
        {navPaneState === NavPaneStates.COLLAPSED && (
          <ListItem
            key={'item-text'}
            className={classNames('item', 'item-sub', {
              'item-selected': isMatch,
            })}
          >
            <NavPaneTooltip
              title={activeOrganisation.name ? activeOrganisation.name : 'Unspecified'}
              buttonNode={
                <div className='account-avatar'>
                  <Tooltip
                    title={navPaneState === NavPaneStates.COLLAPSED ? '' : activeOrganisation.name}
                    disableFocusListener
                    describeChild
                    followCursor
                  >
                    <div className='box-avatar'>
                      <Avatar
                        value={activeOrganisation.name ? activeOrganisation.name : 'Unspecified'}
                        displayValue={activeOrganisation.name}
                      />
                    </div>
                  </Tooltip>
                </div>
              }
            ></NavPaneTooltip>
          </ListItem>
        )}
        {navPaneState !== NavPaneStates.COLLAPSED && (
          <div className={'account-details-container'}>
            <ListItem
              key={'item-text'}
              className={classNames('item', 'item-sub', {
                'item-selected': isMatch,
              })}
            >
              <NavPaneTooltip
                title={activeOrganisation.name ? activeOrganisation.name : 'Unspecified'}
                buttonNode={
                  <div className='account-avatar'>
                    <div className='box-avatar'>
                      <Avatar
                        value={activeOrganisation.name ? activeOrganisation.name : 'Unspecified'}
                        displayValue={activeOrganisation.name}
                      />
                    </div>
                  </div>
                }
              ></NavPaneTooltip>
              <Tooltip
                title={navPaneState !== NavPaneStates.COLLAPSED && isTextEllipsis ? activeOrganisation.name : ''}
                disableFocusListener
                describeChild
                followCursor
              >
                <div className='footer-info'>
                  <div ref={NavPaneOrgText} className='footer-org'>
                    {!!activeOrganisation && activeOrganisation.name}
                  </div>
                  <div className='footer-label'>
                    {activeOrganisation.name ? 'Active Organisation' : 'Unspecified Organisation'}
                  </div>
                </div>
              </Tooltip>
            </ListItem>
          </div>
        )}
      </div>
      <NavPaneTooltip
        isFooter={true}
        title={navPaneState === NavPaneStates.COLLAPSED ? 'Show more' : 'Show less'}
        buttonNode={
          <div className='expandIconContainer'>
            <HideNavIcon
              className='hide-nav-dynamic-icon'
              onClick={() => {
                collapseLeftNavBarHandler();
                setTimeout(() => updateTooltipDisplay(), 100);
              }}
            />
          </div>
        }
      ></NavPaneTooltip>
    </div>
  );
};

export default NavPaneFooter;
