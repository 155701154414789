import React from 'react';
import Avvvatars from 'avvvatars-react';
import { removeSpecialCharacters } from '../../utilities/general-helper';

interface AvatarProps {
  value: string;
  displayValue?: string;
  firstName?: string;
  lastName?: string;
  size?: number;
}

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { value, displayValue, firstName, lastName, size } = props;

  const UpdateDisplayValue = (displayValue: string) => {
    const words = removeSpecialCharacters(displayValue)
      .split(' ')
      .filter((it: string) => it);
    return words.length > 1 ? words[0]?.charAt(0) + words[1]?.charAt(0) : words[0]?.charAt(0);
  };

  const UpdateNameInitial = () => {
    return firstName && lastName
      ? `${UpdateInitial(firstName)}${UpdateInitial(lastName)}`
      : firstName
      ? `${UpdateInitial(firstName)}`
      : lastName
      ? `${UpdateInitial(lastName)}`
      : '';
  };

  const UpdateInitial = (name: string) => {
    if (name === 'Unspecified') {
      return '';
    } else return name[0].charAt(0);
  };

  return (
    <div>
      {value ? (
        <Avvvatars
          value={value}
          size={size}
          displayValue={
            displayValue
              ? UpdateDisplayValue(displayValue)
              : UpdateNameInitial()
              ? UpdateNameInitial()
              : 'U'
          }
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Avatar;
