import React from 'react';
import classNames from 'classnames';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import FloatingMenu from '../floating-menu/floating-menu';
import SearchBar from '../search-bar/search-bar';
import CustomButton from '../button/custom-button';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import { ButtonStyle } from '../../constants/button-constants';
import './styles/search-create.scss';

interface SearchCreateProps {
  className?: string;
  placeholder: string;
  searchRef?: React.MutableRefObject<HTMLInputElement>;
  showCreateButton: boolean;
  firstActionButton?: string;
  firstActionButtonIcon?: any;
  secondActionButton?: string;
  secondActionButtonIcon?: any;
  filterDetails?: FilterDetail[];
  dropdownOptionMenu?: string;
  onKeyUpHandler: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onCreateHandler?: () => void;
  onFirstActionButtonHandler?: () => void;
  onSecondActionButtonHandler?: () => void;
  onFilterValueChangeHandler?: (data: KeyValuePair[]) => void;
  onClearFilterHandler?: () => void;
  dropdownOptionMenuHanlder?: () => void;
}

const SearchCreate: React.FC<SearchCreateProps> = (props: SearchCreateProps) => {
  const {
    className,
    searchRef,
    placeholder,
    filterDetails,
    showCreateButton = true,
    firstActionButton,
    firstActionButtonIcon,
    secondActionButton,
    secondActionButtonIcon,
    dropdownOptionMenu,
    onKeyUpHandler,
    onCreateHandler,
    onFirstActionButtonHandler,
    onSecondActionButtonHandler,
    onFilterValueChangeHandler,
    onClearFilterHandler,
    dropdownOptionMenuHanlder,
  } = props;

  return (
    <div className={classNames('search-create-container')}>
      <div className={classNames('default-top-bar', className)}>
        <SearchBar
          placeholder={placeholder}
          onKeyUp={onKeyUpHandler}
          ref={searchRef}
          filterDetails={filterDetails}
          onFilterValueChange={onFilterValueChangeHandler}
          onClearFilter={onClearFilterHandler}
        />
        {firstActionButton && (
          <CustomButton
            className={classNames('create-button')}
            buttonStyle={ButtonStyle.CREATE}
            onClick={onFirstActionButtonHandler}
            icon={firstActionButtonIcon}
          >
            {firstActionButton}
          </CustomButton>
        )}
        {secondActionButton && (
          <CustomButton
            className={classNames('create-button')}
            buttonStyle={ButtonStyle.CREATE}
            onClick={onSecondActionButtonHandler}
            icon={secondActionButtonIcon}
          >
            {secondActionButton}
          </CustomButton>
        )}
        {showCreateButton && !dropdownOptionMenu && (
          <CustomButton
            className={classNames('create-button')}
            buttonStyle={ButtonStyle.CREATE}
            onClick={onCreateHandler}
          >
            Create New
          </CustomButton>
        )}

        {showCreateButton && dropdownOptionMenu && (
          <div className='create-button-with-dropdown-options-container'>
            <CustomButton buttonStyle={ButtonStyle.CREATE} className={'create-button'} onClick={onCreateHandler}>
              Create New
            </CustomButton>
            <FloatingMenu
              className='footer-primary-floating-menu'
              buttonNode={<ArrowDropDownIcon />}
              items={[
                {
                  label: dropdownOptionMenu,
                  handler: () => {
                    if (dropdownOptionMenuHanlder) dropdownOptionMenuHanlder();
                  },
                },
              ]}
              isPopStartEnd={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchCreate;
