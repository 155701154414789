import { selectNavPaneStatus } from './../../store/nav-pane/selectors';
import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import {
  selectOrganisationContent,
  selectOrganisationStatus,
} from '../../store/organisations/selectors';
import * as actions from '../../store/organisations/actions';
import Organisations from './organisations-list';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import * as headerActions from '../../store/header/actions';
import * as modalActions from '../../store/modals/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { OrganisationItemModel } from '../../models/organisationModel';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const mapStateToProps = (state: RootState) => {
  return {
    organisationContent: selectOrganisationContent(state),
    organisationStatus: selectOrganisationStatus(state),
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadOrganisations: () => dispatch({ type: actions.LOAD_ORGANISATIONS }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    deleteOrgsItem: (id: string) =>
      dispatch({ type: actions.DELETE_ORGS, payload: id }),
    openModal: (data: ModalStateModel) =>
      dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setSelectedOrganisation: (item: OrganisationItemModel) =>
      dispatch({ type: actions.SET_SELECTED_ORGANISATION, payload: item }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
      setOrganisationEditState:(data: boolean) =>
      dispatch({
        type: actions.SET_ORGANISATION_EDIT_STATE,
        payload: data,
      }),
  };
};

const OrganisationList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Organisations);

export default OrganisationList;
