import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModalType } from '../../../constants/modal-constants';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { TerminalInfo, TerminalModel } from '../../../models/terminalModel';
import { RadioGroupModel } from '../../../models/baseModels/radioGroupModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomCheckBox from '../../../components/checkbox/checkbox';
import CustomRadioGroup from '../../../components/radio-group/custom-radio-group';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import MainModal from '../../../components/main-modal/modal.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import * as fieldHelper from '../../../utilities/field-helper';

interface ReenrolModalProps {
  modalData: ModalStateModel;
  modalActionStatus: string;
  terminalInfo: TerminalInfo;
  hasValidationError: boolean;
  closeModal: () => void;
  removeAllValidation: () => void;
  loadTerminalInfo: (data: TerminalModel) => void;
  renenrolTerminal: (data: TerminalInfo) => void;
}

const ReenrolModal: React.FC<ReenrolModalProps> = (props: ReenrolModalProps) => {
  const {
    modalData,
    modalActionStatus,
    terminalInfo,
    hasValidationError,
    closeModal,
    removeAllValidation,
    loadTerminalInfo,
    renenrolTerminal,
  } = props;
  const { orgId, siteId } = useParams();
  const [isTouched, setIsTouched] = useState(false);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);
  const [isUseNewId, setIsUseNewId] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [hasUserAcceptedCertDownload, setHasUserAcceptedCertDownload] = useState(false);
  const [terminalInfoValue, setTerminalInfoValue] = useState({} as TerminalInfo);
  const [existingRegistrationid, setExistingRegistrationid] = useState('');

  const radioList = [
    { value: 'existing', label: 'Use Existing Registration ID' },
    { value: 'new', label: 'Use New Registration ID' },
  ] as RadioGroupModel[];

  useEffect(() => {
    setIsTouched(false);
    if (modalData.type === ModalType.EDIT) {
      loadTerminalInfo({
        siteId: siteId,
        organisationId: orgId,
        id: modalData.dataId,
      } as TerminalModel);
    }
  }, [modalData.type]);

  useEffect(() => {
    setIsSaveButtonEnabled(hasUserAcceptedCertDownload);
  }, [modalData, terminalInfo, hasUserAcceptedCertDownload]);

  useEffect(() => {
    if (!!terminalInfo) {
      setTerminalInfoValue(terminalInfo);
    }
  }, [modalActionStatus]);

  useMemo(() => {
    if (!!terminalInfo) {
      setTerminalInfoValue(terminalInfo);
      setExistingRegistrationid(terminalInfo.registrationId);
    }
  }, [terminalInfo]);

  const onCancel = (event: any) => {
    setValidateCounterFlag(0);
    if (isTouched) {
      setDialogStatus({
        ...dialogState,
        isOpen: true,
        context: 'Are you sure you want to discard your changes?',
      });
    } else {
      removeAllValidation();
      setDialogStatus({
        ...dialogState,
        isOpen: false,
      });
      closeModal();
    }
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      setValidateCounterFlag(0);
      renenrolTerminal(terminalInfoValue);
      setHasUserAcceptedCertDownload(false);
    }
  };

  const cancelDialog = () => {
    setValidateCounterFlag(0);
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    removeAllValidation();
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
    setHasUserAcceptedCertDownload(false);
    closeModal();
  };

  const onTextChangeHandler = useCallback((newvalue: KeyValuePair) => {
    setIsTouched(true);
    newvalue.value = fieldHelper.registrationIdInputHandler(String(newvalue.value));
    setTerminalInfoValue((prevstate) => {
      return {
        ...prevstate,
        [newvalue.key]: newvalue.value,
      };
    });
  }, []);

  const onRadioChangeHandler = useCallback(
    (newValue: any) => {
      setIsUseNewId(newValue === 'new');
      if (newValue === 'existing' && existingRegistrationid) {
        setTerminalInfoValue((prevstate) => {
          return {
            ...prevstate,
            registrationId: existingRegistrationid,
          };
        });
      } else if (newValue === 'new') {
        setTerminalInfoValue((prevstate) => {
          return {
            ...prevstate,
            registrationId: '',
          };
        });
      }
    },
    [existingRegistrationid]
  );

  const certificateCheckedHandler = useCallback((value: boolean) => {
    setIsTouched(true);
    setHasUserAcceptedCertDownload(!!value ? value : false);
  }, []);

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        onClose={onCancel}
        modalTitle='Re-enrol Terminal'
        saveButtonEnabled={isSaveButtonEnabled}
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={modalData.type === ModalType.VIEW}
        cancelButtonText='Cancel'
        saveButtonText='Re-enrol'
        closeButtonText='Close'
      >
        <div style={{ height: '400px' }}>
          <CustomRadioGroup defaultValue={'existing'} items={radioList} onRadioChange={onRadioChangeHandler} />
          <CustomTextField
            key='registrationId'
            label='Registration ID'
            placeholder='Enter Registration ID'
            type='guid'
            onBindingValue={onTextChangeHandler}
            name='registrationId'
            isMandatory={true}
            value={terminalInfoValue?.registrationId}
            validateCounter={validateCounterFlag}
            maxCharLength={36}
            readOnly={!isUseNewId}
          />
          <CustomCheckBox
            onChecked={certificateCheckedHandler}
            name='downloadCert'
            label='Terminal File'
            defaultState={false}
            description='Re-enroling will download a new provisioning file and it will disconnect the terminal from CompacOnline until the new provisioning file is loaded onto it.'
          />
        </div>
      </MainModal>
    </>
  );
};

export default ReenrolModal;
