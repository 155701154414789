export const TextFieldType = Object.freeze({
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  EMAIL: 'email',
  GUID: 'guid',
  ALPHANUMERIC: 'alphanumeric',
  TEXTAREA: 'textarea',
  PHONE: 'phone',
  INTEGER_PIN: 'integer_pin',
});
