import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import {
  selectTransactionData,
  selectTransactionFilter,
  selectTransactionStatus,
} from '../../store/transaction/selectors';
import Transactions from './transactions';
import * as actions from '../../store/transaction/actions';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as paginationActions from '../../store/pagination/actions';
import * as modalActions from '../../store/modals/actions';
import * as binRangeActions from '../../store/bin-ranges/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { PaginationModel } from '../../models/paginationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { selectModalData } from '../../store/modals/selectors';
import { selectIsReachEnd } from '../../store/pagination/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    transactionsContent: selectTransactionData(state),
    transactionsStatus: selectTransactionStatus(state),
    transactionFilterSetting: selectTransactionFilter(state),
    authStatus: selectAuthStatus(state),
    selectModalData: selectModalData(state),
    isReachEnd: selectIsReachEnd(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTransactions: () => dispatch({ type: actions.LOAD_TRANSACTIONS }),
    loadBinRangeNameList: () => dispatch({ type: binRangeActions.LOAD_BIN_RANGE_NAME_LIST }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setPaginationDetails: (data: PaginationModel) =>
      dispatch({
        type: paginationActions.CHANGE_PAGINATION_DETAILS,
        payload: data,
      }),
    setIsReachEnd: (data: boolean) =>
      dispatch({
        type: paginationActions.CHANGE_IS_REACH_END,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const TransactionsPage = connect(mapStateToProps, mapDispatchToProps)(Transactions);

export default TransactionsPage;
