import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { BinRangeItemListModel, BinRangeModel } from '../../models/binRangeModel';
import { initialState } from './bin-rangeInitState';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const BinRangeState = createSlice({
  name: 'binRangeState',
  initialState,
  reducers: {
    setBinRangeStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.binRangeStatus = action.payload;
    },
    setBinRangeList: (state, action: PayloadAction<BinRangeItemListModel[]>) => {
      state.hasError = false;
      state.binRangeList = action.payload;
    },
    setBinRangeData: (state, action: PayloadAction<BinRangeModel>) => {
      state.hasError = false;
      state.binRangeData = action.payload;
    },
    setBinRangeError: (state) => {
      state.hasError = true;
      state.binRangeStatus = LoadingStatus.ERROR;
    },
    setBinRangeNameList: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.hasError = false;
      state.binRnageNameList = action.payload;
    },
    resetBinRangeState: () => {
      return initialState;
    },
  },
});

export const {
  setBinRangeStatus,
  setBinRangeList,
  setBinRangeData,
  setBinRangeError,
  setBinRangeNameList,
  resetBinRangeState,
} = BinRangeState.actions;

export default BinRangeState.reducer;
