import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { CardModel } from '../../../models/cardModel';
import { TabListModel } from '../../../models/baseModels/tabListModel';
import { BinRangeItemListModel } from '../../../models/binRangeModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { ModuleName } from '../../../constants/module-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { DATETIME_FORMAT } from '../../../constants/dateTimePicker-constants';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomTextField from '../../../components/text-field/text-field.container';
import CustomSwitch from '../../../components/switch/custom-switch.containter';
import Form from '../../../components/form/form.container';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import DatePickerComponent from '../../../components/datepicker/datepicker.container';
import Item from '../../../components/box-items/box-item';
import '../styles/card.scss';

interface CardConfigurationProps {
  pageTitle?: string;
  action?: string;
  hasValidationError: boolean;
  cardStatus: string;
  siteListName: KeyValuePair[];
  cardItem: CardModel;
  backDropActionStatus: string;
  binRangeList: BinRangeItemListModel[];
  productNameList: KeyValuePair[];
  fieldValidations: ValidationError[];
  productStatus: string;
  siteStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
  loadSiteNameList: () => void;
  createCard: (data: CardModel) => void;
  editCard: (data: CardModel) => void;
  loadCardData: (data: string | undefined) => void;
  loadBinRangeList: () => void;
  loadProductNameList: () => void;
  removePin: (data: string) => void;
  clearSelectedCard: () => void;
}

const CardConfiguration: React.FC<CardConfigurationProps> = (props: CardConfigurationProps) => {
  const {
    pageTitle,
    action,
    hasValidationError,
    cardStatus,
    siteListName,
    cardItem,
    backDropActionStatus,
    binRangeList,
    productNameList,
    fieldValidations,
    productStatus,
    siteStatus,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
    loadSiteNameList,
    createCard,
    editCard,
    loadCardData,
    loadBinRangeList,
    loadProductNameList,
    removePin,
    clearSelectedCard,
  } = props;

  const [cardConfigurationValue, setCardConfigurationValue] = useState({} as CardModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, cardId } = useParams();
  const isLoadCardDataRequested = useRef(false);
  const isLoadProductNameListRequested = useRef(false);
  const isLoadSiteNameListRequested = useRef(false);
  const isLoadBinRangeListRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasSiteReadAccess, setHasSiteReadAccess] = useState(false);
  const [hasSiteCreateAccess, setHasSiteCreateAccess] = useState(false);
  const [hasBINRangeUpdateAccess, setHasBINRangeUpdateAccess] = useState(false);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [binRangeData, setBinRangeData] = useState([] as KeyValuePair[]);
  const [panPrefix, setPanPrefix] = useState('');
  const [minRange, setMinRange] = useState('');
  const [maxRange, setMaxRange] = useState('');

  const [selectedOnAllSites, setSelectedOnAllSites] = useState(true);
  const [selectedOnAllProducts, setSelectedOnAllProducts] = useState(true);

  const [showPinSection, setShowPinSection] = useState(false);
  const [hasPinConfigChanged, setHasPinConfigChanged] = useState(false);
  const [hasRemovePinRequest, setHasRemovePinRequest] = useState(false);
  const [isTriggeredByAddPin, setIsTriggeredByAddPin] = useState(false);
  const [isDisabledPasswordField, setIsDisabledPasswordField] = useState(false);
  const [secondaryButtonMoreOptions, setSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [primaryButtonMoreOptions, setPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [selectedBINRange, setSelectedBINRange] = useState({} as BinRangeItemListModel);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.CARD).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.CARD).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.CARD).hasUpdateAccess);
    setHasSiteReadAccess(userAccess(ModuleName.SITE).hasReadAccess);
    setHasSiteCreateAccess(userAccess(ModuleName.SITE).hasCreateAccess);
    setHasBINRangeUpdateAccess(userAccess(ModuleName.BINRANGE).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(cardStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(cardStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(cardStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, cardStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
    if (action === FormActionType.VIEW) setIsClickFromViewPage(false);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (
      !isLoadCardDataRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadCardData(cardId);
      setIsSaveButtonEnabled(hasUpdateAccess);
      isLoadCardDataRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setCardConfigurationValue({
        pan: '',
        binRangeId: '',
        allowedOnAllSites: true,
        sites: [] as string[],
        allowedAllProducts: true,
        products: [] as string[],
        enabled: true,
        resetRequiredOnNextUse: false,
      } as CardModel);
      setSelectedOnAllSites(true);
      setSelectedOnAllProducts(true);
      setIsSaveButtonEnabled(hasCreateAccess);
      if ((!binRangeList || binRangeList?.length <= 0) && !isLoadBinRangeListRequested.current) {
        loadBinRangeList();
        isLoadBinRangeListRequested.current = true;
      }
    }
    removeAllValidation();
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    orgId,
    cardId,
    isClickFromViewPage,
    loadCardData,
    setIsPageDirty,
    removeAllValidation,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/cards`);
      clearSelectedCard();
    }
  }, [backDropActionStatus, orgId, navigate]);

  useEffect(() => {
    let binRangeData = binRangeList?.map((b) => {
      return { key: b.id, value: b.name, additionalValue: b.binRange } as KeyValuePair;
    });

    if (action === FormActionType.EDIT && cardConfigurationValue?.pan && binRangeData?.length > 0) {
      const panArray = binRangeList?.map((it) => {
        return it.binRange;
      });
      const matchingRanges = panArray.filter((range) => {
        const [start, end] = range.split(' - ').map((part) => part.trim());
        const current = cardConfigurationValue?.pan.slice(0, start.length);

        // Check if the current PAN is within the range
        return current >= start && current <= end;
      });

      binRangeData = binRangeData.filter((item) =>
        matchingRanges.some((range) => item.additionalValue && String(item.additionalValue).includes(range))
      );
    }

    setBinRangeData(binRangeData);
  }, [binRangeList, cardConfigurationValue]);

  useEffect(() => {
    if (action === FormActionType.CREATE) {
      getPrefixAndRange(cardConfigurationValue.binRangeId);
    }
    if (action === FormActionType.VIEW || action === FormActionType.EDIT) setPanPrefix('');
  }, [action, cardConfigurationValue.binRangeId]);

  useEffect(() => {
    setIsDisabledPasswordField(
      !hasPinConfigChanged &&
        Boolean(cardConfigurationValue?.pin) &&
        (action === FormActionType.EDIT || action === FormActionType.VIEW)
    );
  }, [hasPinConfigChanged, cardConfigurationValue?.pin, action]);

  useEffect(() => {
    if (cardItem?.pin && action === FormActionType.EDIT) {
      setIsTriggeredByAddPin(false);
    }
  }, [action, cardItem?.pin]);

  useEffect(() => {
    if (!!cardItem) {
      const selectedBINRangeDetail = binRangeList?.find((x) => x.id === cardItem?.binRangeId);
      if (selectedBINRangeDetail !== undefined) {
        setSelectedBINRange(selectedBINRangeDetail);
      }
      // this will only happened when creating
      if (panPrefix) {
        setCardConfigurationValue((prevstate) => {
          return {
            ...prevstate,
            id: cardItem?.id,
            binRangeId: cardItem?.binRangeId,
            binRangeInfo: cardItem?.binRangeInfo,
            panPrefix: panPrefix,
            pan: cardItem?.pan?.substring(panPrefix.length),
            enabled: cardItem?.enabled,
            allowedOnAllSites: cardItem?.allowedOnAllSites,
            sites: cardItem?.sites,
            sitesInfo: cardItem?.sitesInfo,
          };
        });
      } else {
        if ((action === FormActionType.VIEW || action === FormActionType.EDIT) && cardItem?.pin) {
          setCardConfigurationValue(cardItem);
          setSelectedOnAllSites(cardItem?.allowedOnAllSites);
          setSelectedOnAllProducts(cardItem?.allowedAllProducts);
          setShowPinSection(true);
        } else {
          setCardConfigurationValue(cardItem);
          setSelectedOnAllSites(cardItem?.allowedOnAllSites);
          setSelectedOnAllProducts(cardItem?.allowedAllProducts);
          setShowPinSection(false);
        }
      }

      if (cardItem?.pan && !cardItem?.allowedOnAllSites && !isLoadSiteNameListRequested.current && success) {
        loadSiteNameList();
        isLoadSiteNameListRequested.current = true;
      }
      if (cardItem?.pan && !cardItem?.allowedAllProducts && !isLoadProductNameListRequested.current && success) {
        loadProductNameList();
        isLoadProductNameListRequested.current = true;
      }
    }
  }, [cardItem, success, action]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/cards/${cardId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      let updatedCardConfigurationValue = { ...cardConfigurationValue };

      updatedCardConfigurationValue = {
        ...updatedCardConfigurationValue,
        hasPinConfigChanged: hasPinConfigChanged,
        hasRemovePinRequest: hasRemovePinRequest,
      };

      switch (action) {
        case FormActionType.EDIT:
          if (hasRemovePinRequest && !cardConfigurationValue?.pin) {
            removePin(updatedCardConfigurationValue?.id);
          }
          editCard(updatedCardConfigurationValue);
          break;
        case FormActionType.CREATE:
          if (hasRemovePinRequest && !cardConfigurationValue?.pin) {
            removePin(updatedCardConfigurationValue?.id);
          }
          createCard(updatedCardConfigurationValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      if (newvalue.key === 'pin' || newvalue.key === 'resetRequiredOnNextUse') {
        setHasPinConfigChanged(true);

        if (!isTriggeredByAddPin && newvalue.key === 'resetRequiredOnNextUse' && action === FormActionType.EDIT) {
          setCardConfigurationValue((prevstate) => {
            return {
              ...prevstate,
              [newvalue.key]: newvalue.value,
            };
          });
          return;
        }
      }
      if (newvalue.key === 'binRangeId') {
        const selectedBINRangeDetail = binRangeList?.find((x) => x.id === newvalue.value);
        if (selectedBINRangeDetail !== undefined) {
          setSelectedBINRange(selectedBINRangeDetail);
        }
      }
      if (newvalue.key?.toString()?.includes('tag-')) {
        const updateTag = newvalue.key?.toString()?.replace('tag-', '');
        if (cardConfigurationValue?.tags?.find((x) => x.key === updateTag)) {
          let updateIndex = cardConfigurationValue?.tags?.findIndex((x) => x.key === updateTag);
          let updatedTags = [...cardConfigurationValue?.tags];
          updatedTags[updateIndex] = {
            key: updateTag,
            value: String(newvalue?.value),
          };
          setCardConfigurationValue((prevstate) => {
            return {
              ...prevstate,
              tags: updatedTags,
            };
          });
          return;
        } else {
          let updatedTags = [] as any[];
          if (cardConfigurationValue?.tags) updatedTags = [...cardConfigurationValue?.tags];
          updatedTags?.push({
            key: updateTag,
            value: String(newvalue?.value),
          });
          setCardConfigurationValue((prevstate) => {
            return {
              ...prevstate,
              tags: updatedTags,
            };
          });
          return;
        }
      }
      setCardConfigurationValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, isSaveButtonEnabled, isTriggeredByAddPin, action, cardConfigurationValue]
  );

  const onCancel = () => {
    setHasPinConfigChanged(false);
    setValidateCounterFlag(0);
  };

  const handleNewSitesValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
      setCardConfigurationValue((prevstate: any) => {
        let sites = newvalue?.map((x) => {
          return x.key.toString();
        });
        let uniqueSites = sites.filter((site, i) => {
          return sites.indexOf(site) === i;
        });
        return {
          ...prevstate,
          sites: [...uniqueSites],
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, action]
  );

  const handleNewProductsValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
      setCardConfigurationValue((prevstate: any) => {
        let products = newvalue?.map((x) => {
          return x.key.toString();
        });
        let uniqueProducts = products.filter((product, i) => {
          return products.indexOf(product) === i;
        });
        return {
          ...prevstate,
          products: [...uniqueProducts],
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, action]
  );

  const handleSelectedOnAllSites = useCallback(
    (newvalue: boolean) => {
      setCardConfigurationValue((prevstate) => {
        return {
          ...prevstate,
          allowedOnAllSites: newvalue,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handleSelectedOnAllProducts = useCallback(
    (newvalue: boolean) => {
      setCardConfigurationValue((prevstate) => {
        return {
          ...prevstate,
          allowedAllProducts: newvalue,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handleSitekeyValuePairLoding = () => {
    if (!isLoadSiteNameListRequested.current) {
      loadSiteNameList();
      isLoadSiteNameListRequested.current = true;
    }
  };

  const handleProductkeyValuePairLoding = () => {
    if (!isLoadProductNameListRequested.current) {
      loadProductNameList();
      isLoadProductNameListRequested.current = true;
    }
  };

  const getSiteName = (siteId: string): string => {
    var siteName = siteListName?.find((c) => {
      return c.key === siteId;
    })?.value;
    return !!siteName ? siteName.toString() : '';
  };

  const getProductName = (productId: string): string => {
    var productName = productNameList?.find((c) => {
      return c.key === productId;
    })?.value;
    return !!productName ? productName.toString() : '';
  };

  const getPrefixAndRange = (selectedBinRangeList: string) => {
    if (!selectedBinRangeList || !binRangeData) return;
    const commonPrefix = findCommonPrefix(selectedBinRangeList);
    switch (action) {
      case FormActionType.CREATE:
        setCardConfigurationValue((prevstate) => {
          return {
            ...prevstate,
            panPrefix: commonPrefix,
          };
        });
        break;
    }
  };

  const findCommonPrefix = (selectedBinRangeList: string): string => {
    let selectedBinRange: string | number | boolean | undefined = '';

    binRangeList
      ?.map((b) => {
        return { key: b.id, value: b.name, additionalValue: b.binRange } as KeyValuePair;
      })
      .map((b) => {
        if (selectedBinRangeList === b.key) {
          selectedBinRange = b.additionalValue;
        }
      });

    const [binLow, connectsymbol, binHigh] = selectedBinRange.split(' ');
    const arr = [binLow, binHigh];

    let prefix = '';
    if (arr.length === 0) return prefix;

    for (let i = 0; i < arr[0].length; i++) {
      const char = arr[0][i];
      if (arr.every((str) => str[i] === char)) {
        prefix += char;
      } else {
        break;
      }
    }
    setPanPrefix(prefix);
    setMinRange(binLow);
    setMaxRange(binHigh);
    return prefix;
  };

  const handleExpiryDateChange = useCallback(
    (newValue: any) => {
      setIsPageDirty(isSaveButtonEnabled);
      setCardConfigurationValue((prevstate) => {
        return {
          ...prevstate,
          expiryDate: newValue,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handlePinAdd = () => {
    setShowPinSection(true);
    setHasRemovePinRequest(false);
    setIsTriggeredByAddPin(true);
    setCardConfigurationValue((prevstate) => {
      return {
        ...prevstate,
        pin: '',
        resetRequiredOnNextUse: true,
      };
    });
  };

  const handlePinRemove = () => {
    setShowPinSection(false);
    setHasRemovePinRequest(true);
    setIsTriggeredByAddPin(false);
    removeValidation('pin');
    setCardConfigurationValue((prevstate) => {
      return {
        ...prevstate,
        pin: '',
        resetRequiredOnNextUse: false,
      };
    });
  };

  const tabWizardList = [
    {
      tabType: 'link',
      tabLink: `/organisations/${orgId}/cards`,
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Cards</span> <NavigateNextIcon className='next-icon' />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Basic Info</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.find(
        (it) =>
          (it.name === 'binRangeId' && it.hasError === true) ||
          (it.name === 'pan' && it.hasError === true) ||
          (it.name === 'allowedSites' && it.hasError === true) ||
          (it.name === 'allowedProducts' && it.hasError === true)
      ),
      tabFormCompleted:
        !fieldValidations?.find(
          (it) =>
            (it.name === 'binRangeId' && it.hasError === true) ||
            (it.name === 'pan' && it.hasError === true) ||
            (it.name === 'allowedSites' && it.hasError === true) ||
            (it.name === 'allowedProducts' && it.hasError === true)
        ) &&
        cardConfigurationValue?.binRangeId &&
        cardConfigurationValue?.pan &&
        (cardConfigurationValue?.allowedOnAllSites || cardConfigurationValue?.sites) &&
        (cardConfigurationValue?.allowedAllProducts || cardConfigurationValue?.products),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          {
            <DropDown
              validateCounter={validateCounterFlag}
              key='binRangeId'
              name='binRangeId'
              label='BIN Range'
              onBindingValue={onTextChangeHandler}
              value={cardConfigurationValue?.binRangeId}
              keyValuePair={binRangeData}
              isMandatory={true}
              readOnly={!isUpdatingForm || !binRangeData}
            />
          }
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            maxCharLength={19}
            minCharLength={1}
            key='pan'
            onBindingValue={onTextChangeHandler}
            label='PAN'
            placeholder='Enter PAN'
            name='pan'
            value={cardConfigurationValue?.pan}
            type={TextFieldType.ALPHANUMERIC}
            readOnly={!isUpdatingForm || action === FormActionType.EDIT}
            prefix={panPrefix}
            minRange={minRange}
            maxRange={maxRange}
          />
          {
            <MultiSelectDropdown
              id='allowedSites'
              dataList={siteListName}
              label='Sites'
              name='allowed sites'
              placeholder='Add Allowed Sites'
              value={cardConfigurationValue?.sites?.map(
                (item) => ({ key: item, value: getSiteName(item) }) as KeyValuePair
              )}
              onBindingValue={handleNewSitesValue}
              maxHeight={192}
              readOnly={!isUpdatingForm}
              isMandatory={true}
              selectAllOption={true}
              isSelectedAll={(newvalue: boolean) => {
                handleSelectedOnAllSites(newvalue);
              }}
              validateCounter={validateCounterFlag}
              selectedAll={selectedOnAllSites}
              noOptionsText={'No Sites Available'}
              createOptionsLink={hasSiteReadAccess && hasSiteCreateAccess ? `/organisations/${orgId}/sites/create` : ''}
              keyValuePairLoding={handleSitekeyValuePairLoding}
              keyValuePairLoadingStatus={siteStatus}
            ></MultiSelectDropdown>
          }

          {
            <MultiSelectDropdown
              id='allowedProducts'
              dataList={productNameList}
              label='Allowed Products'
              name='allowed products'
              placeholder='Add Allowed Products'
              value={cardConfigurationValue?.products?.map(
                (item) => ({ key: item, value: getProductName(item) }) as KeyValuePair
              )}
              onBindingValue={handleNewProductsValue}
              maxHeight={192}
              readOnly={!isUpdatingForm}
              isMandatory={true}
              selectAllOption={
                productStatus === LoadingStatus.SUCCESS ? productNameList && productNameList?.length > 0 : true
              }
              isSelectedAll={(newvalue: boolean) => {
                handleSelectedOnAllProducts(newvalue);
              }}
              validateCounter={validateCounterFlag}
              selectedAll={selectedOnAllProducts}
              noOptionsText={'No Products Available'}
              keyValuePairLoding={handleProductkeyValuePairLoding}
              keyValuePairLoadingStatus={productStatus}
            ></MultiSelectDropdown>
          }

          <DatePickerComponent
            validateCounter={validateCounterFlag}
            isMandatory={false}
            dateFormat={DATETIME_FORMAT.MONTH_AND_YEAR_ONLY}
            disableFuture={false}
            handleDateChange={handleExpiryDateChange}
            name={'expiryDateTimeUtc'}
            label='Expiry Date'
            dateValue={cardConfigurationValue?.expiryDate}
            readOnly={!isUpdatingForm}
            monthAndYearPicker={true}
            tooltipTitle={'The expiry date will be the last day of the selected month.'}
          />

          <CustomSwitch
            className='enabled-switch'
            id='enabled'
            key='enabled'
            name='enabled'
            value={cardConfigurationValue?.enabled}
            onBindingValue={onTextChangeHandler}
            label='Enabled'
            readOnly={!isUpdatingForm}
          />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>PIN</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabFormCompleted: cardConfigurationValue?.pin,
      tabError: fieldValidations?.find((it) => it.name === 'pin' && it.hasError === true),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <div className='pin-header'>PIN Configuration</div>
          {!isUpdatingForm && action === FormActionType.VIEW && !cardConfigurationValue?.pin && (
            <div className='no-pin-found-text'>No PIN configured</div>
          )}
          {isUpdatingForm && !showPinSection && (
            <Link className='add-pin' underline='hover' onClick={() => handlePinAdd()}>
              + Add PIN
            </Link>
          )}
          {showPinSection && (
            <Item className='pin-section-box'>
              {
                <div className='pin-section-form'>
                  {isUpdatingForm && (
                    <Link className='remove-pin' underline='hover' onClick={() => handlePinRemove()}>
                      - Remove
                    </Link>
                  )}
                  <CustomTextField
                    validateCounter={validateCounterFlag}
                    maxCharLength={9}
                    minCharLength={1}
                    key='pin'
                    onBindingValue={onTextChangeHandler}
                    label='PIN'
                    placeholder='Enter PIN'
                    name='pin'
                    value={cardConfigurationValue?.pin ? cardConfigurationValue?.pin : ''}
                    type={TextFieldType.INTEGER_PIN}
                    isPasswordField={true}
                    isDisabledPasswordField={isDisabledPasswordField}
                    readOnly={!isUpdatingForm}
                    isMandatory={!cardConfigurationValue?.resetRequiredOnNextUse}
                  />
                  <CustomSwitch
                    id='resetRequiredOnNextUse'
                    key='resetRequiredOnNextUse'
                    name='resetRequiredOnNextUse'
                    value={cardConfigurationValue?.resetRequiredOnNextUse}
                    onBindingValue={onTextChangeHandler}
                    label='Reset On Next Use'
                    readOnly={!isUpdatingForm}
                  />
                </div>
              }
            </Item>
          )}
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Tags</span>
        </div>
      ),
      tabFormCompleted:
        cardConfigurationValue?.tags &&
        cardConfigurationValue?.tags?.length > 0 &&
        cardConfigurationValue?.tags?.filter((it) => it?.key && it?.value).length > 0,
      tabDisabled: !cardConfigurationValue?.binRangeId || cardConfigurationValue?.binRangeId === 'Select',
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          {selectedBINRange && selectedBINRange?.cardTags && selectedBINRange?.cardTags?.length > 0 && (
            <Item className='tags-section-box'>
              {selectedBINRange?.cardTags?.map((it, i) => {
                return (
                  <CustomTextField
                    key={`tag-${it.key}`}
                    name={`tag-${it.key}`}
                    inlineLabel={it.key}
                    placeholder={`Enter ${it.key}`}
                    type='string'
                    maxCharLength={50}
                    value={cardConfigurationValue?.tags?.find((tag) => tag?.key === it?.key)?.value ?? ''}
                    onBindingValue={onTextChangeHandler}
                    validateCounter={validateCounterFlag}
                    readOnly={!isUpdatingForm}
                  ></CustomTextField>
                );
              })}
            </Item>
          )}

          {selectedBINRange &&
          (!selectedBINRange?.cardTags || selectedBINRange?.cardTags?.length <= 0) &&
          isUpdatingForm &&
          hasBINRangeUpdateAccess ? (
            <Item className='tags-section-box-without-tag-list'>
              <Link
                className='create-new-entity-link'
                onMouseDown={() => {
                  navigate(`/organisations/${orgId}/bin-ranges/${selectedBINRange?.id}/details/edit`);
                }}
              >
                No Card Tags Available, Click Here to Add
              </Link>
            </Item>
          ) : !selectedBINRange?.cardTags && (!isUpdatingForm || !hasBINRangeUpdateAccess) ? (
            <Item className='tags-section-box-without-tag-list'>
              <span className='no-card-tags-text'>No Card Tags Available</span>
            </Item>
          ) : null}
        </div>
      ),
    },
  ] as TabListModel[];

  const currentTabWizardChangeHandler = (data: number) => {
    if (data <= 1) {
      setSecondaryButtonMoreOptions([]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data === 2) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data >= 3) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([]);
    }
  };

  const wizardListChangeHandler = () => {
    if (fieldValidations && fieldValidations?.length > 0) setValidateCounterFlag((prev) => ++prev);
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/cards`}
        isClickFromViewPage={isClickFromViewPage}
        tabWizardList={tabWizardList}
        onCurrentTabWizardChange={currentTabWizardChangeHandler}
        wizardListChange={wizardListChangeHandler}
        primaryButtonMoreOptions={primaryButtonMoreOptions}
        secondaryButtonMoreOptions={secondaryButtonMoreOptions}
      >
        <></>
      </Form>
    </>
  );
};

export default CardConfiguration;
