export const SetDataInLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const RemoveDataFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}

export const GetDataFromLocalStorage = (key: string): any => {
    let value = localStorage.getItem(key);
    
    if(!!value || value !== null)
        return JSON.parse(value || '')
    else 
        return '';
}