import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { AlertFilterModel, AlertModel } from '../../models/alertModel';
import { FilterDetail } from '../../models/baseModels/searchBarFilterModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { LoadingStatus } from '../../constants/loading-constants';
import { ModuleName } from '../../constants/module-constants';
import { ButtonStyle } from '../../constants/button-constants';
import { Messages } from '../../constants/messages';
import { ModalType } from '../../constants/modal-constants';
import { useDebounce } from '../../utilities/field-helper';
import {
  getLast30DaysStartAndEndDateTime,
  getLast3MonthsStartAndEndDateTime,
  getLast6MonthsStartAndEndDateTime,
  getLast7DaysStartAndEndDateTime,
  getTodaySrartAndEndDateTime,
  getYesterdaySrartAndEndDateTime,
} from '../../utilities/datetime-helper';
import CustomButton from '../../components/button/custom-button';
import FilterGroup from '../../components/filter-group/filter-group';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import FooterBar from '../../components/footer/footer';
import MessageDisplay from '../../components/message-display/message-display';
import EmptyList from '../../components/empty-list/empty-list';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import Item from '../../components/box-items/box-item';
import AlertItem from './alert-item';
import AlertFilter from './child-modal/filter-container';
import './styles/alert-list.scss';

interface alertsProps {
  alertStatus: string;
  alertData: AlertModel[];
  siteListName: KeyValuePair[];
  alertRuleNameList: KeyValuePair[];
  isPageReachBottom: boolean;
  authStatus: string;
  initLoadAlerts: (data: AlertFilterModel) => void;
  loadAlerts: (data?: AlertFilterModel) => void;
  saveAlertFuzzySearch: (data: string) => void;
  loadSiteNameList: () => void;
  loadAlertRuleNameList: () => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  openModal: (data: ModalStateModel) => void;
  setIsScrollRequest: (data: boolean) => void;
  clearContinuationToken: () => void;
}

const Alerts: React.FC<alertsProps> = (props: alertsProps) => {
  const {
    alertStatus,
    alertData,
    siteListName,
    alertRuleNameList,
    isPageReachBottom,
    authStatus,
    initLoadAlerts,
    loadAlerts,
    saveAlertFuzzySearch,
    loadSiteNameList,
    loadAlertRuleNameList,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    openModal,
    setIsScrollRequest,
    clearContinuationToken,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  const isLoadAlertUserGroupsRequested = useRef(false);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authStatus, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.ALERT).hasReadAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(alertStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(alertStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(alertStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, alertStatus]);

  const [isInitLoad, setIsInitLoad] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [alertFilter, setAlertFilter] = useState({} as AlertFilterModel);
  const [displaySiteFilter, setDisplaySiteFilter] = useState('Default');
  const [displayAlertRuleFilter, setDisplayAlertRuleFilter] = useState('Default');
  const [displayDateRangeFilter, setDisplayDateRangeFilter] = useState('Default');
  const [siteFilterItems, setSiteFilterItems] = useState([] as FloatingMenuItem[]);
  const [alertRuleFilterItems, setAlertRuleFilterItems] = useState([] as FloatingMenuItem[]);
  const dateRangeFilterItems = [
    {
      label: 'Today',
      isSelected: 'Today' === displayDateRangeFilter,
      handler: () => {
        let { startDateTime, endDateTime } = getTodaySrartAndEndDateTime();
        setDisplayDateRangeFilter('Today');
        setIsFilterUpdated(true);
        setAlertFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'today',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          };
        });
      },
    },
    {
      label: 'Yesterday',
      isSelected: 'Yesterday' === displayDateRangeFilter,
      handler: () => {
        let { startDateTime, endDateTime } = getYesterdaySrartAndEndDateTime();
        setDisplayDateRangeFilter('Yesterday');
        setIsFilterUpdated(true);
        setAlertFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'yesterday',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          };
        });
      },
    },
    {
      label: 'Last 7 Days',
      isSelected: 'Last 7 Days' === displayDateRangeFilter,
      handler: () => {
        let { startDateTime, endDateTime } = getLast7DaysStartAndEndDateTime();
        setDisplayDateRangeFilter('Last 7 Days');
        setIsFilterUpdated(true);
        setAlertFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'Last 7 Days',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          };
        });
      },
    },
    {
      label: 'Last 30 Days',
      isSelected: 'Last 30 Days' === displayDateRangeFilter,
      handler: () => {
        let { startDateTime, endDateTime } = getLast30DaysStartAndEndDateTime();
        setDisplayDateRangeFilter('Last 30 Days');
        setIsFilterUpdated(true);
        setAlertFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'Last 30 Days',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          };
        });
      },
    },
    {
      label: 'Last 3 Months',
      isSelected: 'Last 3 Months' === displayDateRangeFilter,
      handler: () => {
        let { startDateTime, endDateTime } = getLast3MonthsStartAndEndDateTime();
        setDisplayDateRangeFilter('Last 3 Months');
        setIsFilterUpdated(true);
        setAlertFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'Last 3 Months',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          };
        });
      },
    },
    {
      label: 'Last 6 Months',
      isSelected: 'Last 6 Months' === displayDateRangeFilter,
      handler: () => {
        let { startDateTime, endDateTime } = getLast6MonthsStartAndEndDateTime();
        setDisplayDateRangeFilter('Last 6 Months');
        setIsFilterUpdated(true);
        setAlertFilter((prevstate) => {
          return {
            ...prevstate,
            dateRange: 'Last 6 Months',
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          };
        });
      },
    },
    {
      label: 'Custom',
      isSelected: 'Custom' === displayDateRangeFilter,
      handler: () => {
        setDisplayDateRangeFilter('Custom');
        setIsFilterUpdated(true);
        openModal({
          type: ModalType.SEARCH,
          dataId: '',
        } as ModalStateModel);
      },
    },
  ];
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [isFuzzySearchTriggered, setIsFuzzySearchTriggered] = useState(false);

  const debouncedValue = useDebounce<string>(searchKeyword, 500);

  const [filterDetails, setFilterDetails] = useState([
    {
      filterName: 'Site',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displaySiteFilter,
    },
    {
      filterName: 'Alert Rule',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayAlertRuleFilter,
    },
    {
      filterName: 'Date Range',
      filterOptions: [],
      selectedOption: 'default',
      displayValue: displayDateRangeFilter,
    },
  ] as FilterDetail[]);

  useEffect(() => {
    let newSiteFilter = [] as FloatingMenuItem[];
    siteListName?.map((it) =>
      newSiteFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displaySiteFilter,
        handler: () => {
          setDisplaySiteFilter(String(it.value));
        },
      })
    );
    setSiteFilterItems(newSiteFilter);
  }, [siteListName, displaySiteFilter, setDisplaySiteFilter, setSiteFilterItems]);

  useEffect(() => {
    let newAlertRuleFilter = [] as FloatingMenuItem[];
    alertRuleNameList?.map((it) =>
      newAlertRuleFilter.push({
        label: String(it.value),
        isSelected: String(it.value) === displayAlertRuleFilter,
        handler: () => {
          setDisplayAlertRuleFilter(String(it.value));
        },
      })
    );
    setAlertRuleFilterItems(newAlertRuleFilter);
  }, [alertRuleNameList, displayAlertRuleFilter, setDisplayAlertRuleFilter, setAlertRuleFilterItems]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Alert History',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: false,
      pageURL: 'alerts',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess]);

  useEffect(() => {
    if (!isLoadAlertUserGroupsRequested.current && authSuccess && hasReadAccess) {
      setSearchKeyword('');
      saveAlertFuzzySearch('');
      setDisplaySiteFilter('Default');
      initLoadAlerts({
        limit: 50,
      } as AlertFilterModel);
      loadSiteNameList();
      loadAlertRuleNameList();
      setIsInitLoad(true);
      isLoadAlertUserGroupsRequested.current = true;
    }
  }, [authSuccess, hasReadAccess]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    setFilterDetails([
      {
        filterName: 'Site',
        filterOptions: siteFilterItems,
        selectedOption: 'default',
        displayValue: displaySiteFilter,
      },
      {
        filterName: 'Alert Rule',
        filterOptions: alertRuleFilterItems,
        selectedOption: 'default',
        displayValue: displayAlertRuleFilter,
      },
      {
        filterName: 'Date Range',
        filterOptions: dateRangeFilterItems,
        selectedOption: 'default',
        displayValue: displayDateRangeFilter,
      },
    ]);
  }, [siteFilterItems, alertRuleFilterItems, displaySiteFilter, displayAlertRuleFilter, displayDateRangeFilter]);

  useEffect(() => {
    if (!isFilterUpdated && displaySiteFilter === 'Default') return;
    const selectedSiteId = String(siteListName?.find((it) => it?.value === displaySiteFilter)?.key);
    setAlertFilter((prevstate) => {
      return {
        ...prevstate,
        siteId: selectedSiteId,
      };
    });
    setIsFilterUpdated(true);
  }, [displaySiteFilter]);

  useEffect(() => {
    if (!isFilterUpdated && displayAlertRuleFilter === 'Default') return;
    const selectedAlertRuleId = String(alertRuleNameList?.find((it) => it?.value === displayAlertRuleFilter)?.key);
    setAlertFilter((prevstate) => {
      return {
        ...prevstate,
        alertRuleId: selectedAlertRuleId,
      };
    });
    setIsFilterUpdated(true);
  }, [displayAlertRuleFilter]);

  useEffect(() => {
    if (isFilterUpdated && displayDateRangeFilter === 'Default') {
      setAlertFilter((prevstate) => {
        return {
          ...prevstate,
          dateRange: 'Default',
        };
      });
    }
  }, [displayDateRangeFilter]);

  useEffect(() => {
    if (searchKeyword && isFuzzySearchTriggered) {
      initLoadAlerts(alertFilter);
      setIsInitLoad(true);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (isFilterUpdated) {
      setIsInitLoad(true);
      initLoadAlerts(alertFilter);
    }
  }, [alertFilter, isFilterUpdated]);

  useEffect(() => {
    setIsScrollRequest(true);
    if (isPageReachBottom) {
      // when card list is still loading, do not trigger another load request
      if (alertStatus === LoadingStatus.LOADING) return;
      setIsInitLoad(false);
      setTimeout(() => {
        if (alertFilter) loadAlerts(alertFilter);
        else loadAlerts();
      }, 200);
    }
  }, [isPageReachBottom]);

  const alertList = useMemo(() => {
    return alertData?.map((item, i) => <AlertItem key={i} item={item} />);
  }, [alertData]);

  const searchInputRef = useRef<HTMLInputElement>(null!);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let enteredSearchValue = searchInputRef.current?.value.trim();
    clearContinuationToken();
    setIsScrollRequest(false);
    if (enteredSearchValue === '') {
      setSearchKeyword('');
      saveAlertFuzzySearch('');
      initLoadAlerts(alertFilter);
    }
    setSearchKeyword(enteredSearchValue);
    saveAlertFuzzySearch(enteredSearchValue);
    setIsFuzzySearchTriggered(true);
    window.scrollTo(0, 0);
  };

  const onAlertRuleButtonHandler = () => {
    navigate(`/organisations/${orgId}/alerts/alert-rules`);
  };

  const onUserGroupButtonHandler = () => {
    navigate(`/organisations/${orgId}/alerts/user-groups`);
  };

  const clearSearchHandler = (filterName: string) => {
    setIsFilterUpdated(true);
    if (filterName === 'Site') {
      setDisplaySiteFilter('Default');
    }
    if (filterName === 'Alert Rule') {
      setDisplayAlertRuleFilter('Default');
    }
    if (filterName === 'Date Range') {
      setDisplayDateRangeFilter('Default');
    }
  };

  const clearFiltersHandler = () => {
    setSearchKeyword(' ');
    saveAlertFuzzySearch(' ');
    setDisplaySiteFilter('Default');
    setDisplayAlertRuleFilter('Default');
    setDisplayDateRangeFilter('Default');
    setAlertFilter({} as AlertFilterModel);
    setIsFilterUpdated(true);
  };

  const saveActivityLogFilterHandler = (newFilter: AlertFilterModel) => {
    setAlertFilter((prevstate) => {
      return {
        ...prevstate,
        dateRange: 'Custom',
        startDateTime: newFilter.startDateTime,
        endDateTime: newFilter.endDateTime,
      };
    });
  };

  const Modal = useMemo(() => {
    return <AlertFilter alertFilterSetting={alertFilter} saveAlertFilter={saveActivityLogFilterHandler} />;
  }, [alertFilter]);

  return (
    <>
      {<>{Modal}</>}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {hasReadAccess && (
        <div className={classNames('alert-list')}>
          <div className='filter-list'>
            {
              <FilterGroup
                searchBarPlaceholder='Search Message'
                searchRef={searchInputRef}
                filterDetails={filterDetails}
                clearSearch={clearSearchHandler}
                clearFilters={clearFiltersHandler}
                onKeyUp={onKeyUpHandler}
              />
            }
            {
              <CustomButton
                className={classNames('create-button')}
                buttonStyle={ButtonStyle.CREATE}
                onClick={onAlertRuleButtonHandler}
              >
                Alert Rules
              </CustomButton>
            }
            {
              <CustomButton
                className={classNames('create-button')}
                buttonStyle={ButtonStyle.CREATE}
                onClick={onUserGroupButtonHandler}
              >
                User Groups
              </CustomButton>
            }
          </div>

          {alertList && alertList?.length > 0 && (
            <Grid container className={classNames('alert-header-container')} alignItems='center'>
              <Grid item xs={11} sm={11} rowGap={2}>
                <Grid container alignItems='flex-start'>
                  <Grid item xs={12} sm={3} md={2}>
                    <Item className={classNames('event-header-filed')}>Datetime</Item>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                    <Item className={classNames('event-header-filed')}>Site</Item>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Item className={classNames('event-header-filed')}>Alert Rule</Item>
                  </Grid>
                  <Grid item xs={12} sm={3} md={4}>
                    <Item className={classNames('event-header-filed')}>Details</Item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <div className='product-item-list-container'>
            <div className='product-list'>
              {searchKeyword.length > 0 && isInitLoad
                ? searchKeyword && !loading && alertList
                : (displaySiteFilter !== 'Default' ||
                      displayAlertRuleFilter !== 'Default' ||
                      displayDateRangeFilter !== 'Default') &&
                    isInitLoad
                  ? !loading && alertList
                  : alertList}
            </div>
          </div>

          {!loading && success && !!alertData && alertData.length <= 0 && (
            <div className='empty-div-container'>
              <EmptyList message={'No alert history found'}></EmptyList>
            </div>
          )}
        </div>
      )}

      {(!authCheckCompleted || loading) && <LoadingDisplay />}

      {
        <div className='for-mobile'>
          <FooterBar className='footer-no-content' />
        </div>
      }
    </>
  );
};

export default Alerts;
