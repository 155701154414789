import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface DownloadFileIconProps{
    className?:string
}

const DownloadFileIcon = (props: DownloadFileIconProps) => {
    return (
        <SvgIcon className={`base-icon download-file-icon ${props.className}`} />
    );
};

export default DownloadFileIcon;