import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { List, Divider, ListItem, ListItemIcon, ListItemText, Slide } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classNames from 'classnames';
import { NavPaneStates } from '../../../constants/window-constants';
import { NavItemModel } from '../../../models/navItemModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import NavPaneTooltip from '../navPaneTooltip/index.container';
import NavLink from '../navLink/navLink.container';
import '../styles/nav-pane.scss';

interface SubNavPaneProps {
  subNavItems: NavItemModel[];
  navPaneState: string;
  subMenuState: boolean;
  userAccess: (moduleName: string) => AuthorisationModel;
}

const SubNavPaneControl: React.FC<SubNavPaneProps> = (props: SubNavPaneProps) => {
  const { subNavItems, subMenuState, navPaneState, userAccess } = props;

  const { orgId } = useParams();
  const hasReadAccess = (moduleName: string | undefined) => {
    if (moduleName === 'sitestatus') return !!moduleName ? userAccess('site').hasReadAccess : false;
    else return !!moduleName ? userAccess(moduleName).hasReadAccess : false;
  };

  const navigate = useNavigate();

  const navToSitePage = () => {
    navigate(`/organisations/${orgId}/sites`);
  };

  return (
    <Slide direction='left' in={subMenuState} mountOnEnter unmountOnExit timeout={500}>
      <div
        className={classNames('nav-menu', {
          'pop-behind': !subMenuState,
          'nav-sub-menu': subMenuState,
          'nav-sub-menu-collapsed': navPaneState === NavPaneStates.COLLAPSED,
        })}
      >
        <NavLink path={`/organisations/${orgId}/sites`} key={'site-list'} moduleName='sites'>
          <NavPaneTooltip
            title='Sites'
            buttonNode={
              <ListItem button className={classNames('item', 'level2-home')} onClick={() => navToSitePage()}>
                <ListItemIcon>
                  <ArrowBackIcon className='base-mui-icon' />
                </ListItemIcon>
                <ListItemText className='item-text' primary={'Sites'} />
              </ListItem>
            }
          ></NavPaneTooltip>
        </NavLink>
        <Divider className='divider' />
        <List className='app-left-pane'>
          {subNavItems?.map((item, i) => {
            return (
              hasReadAccess(item.moduleName) && (
                <NavLink path={`${item.path}`} key={'sub' + i} moduleName={item.moduleName}>
                  <NavPaneTooltip
                    title={item.label}
                    buttonNode={
                      <ListItem button key={item.label} className={classNames('item', 'item-selected')}>
                        <ListItemIcon key={'icon' + i} className={classNames('icon-selected')}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText key={'label' + i} className='item-text' primary={item.label} />
                      </ListItem>
                    }
                  ></NavPaneTooltip>
                </NavLink>
              )
            );
          })}
        </List>
      </div>
    </Slide>
  );
};

export default SubNavPaneControl;
