import { initialState } from './alertInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { AlertModel } from '../../models/alertModel';

const alertSlice = createSlice({
  name: 'alertState',
  initialState,
  reducers: {
    setAlertStatus: (state, action: PayloadAction<string>) => {
      state.alertStatus = action.payload;
      state.hasError = false;
    },
    setAlertData: (state, action: PayloadAction<AlertModel[]>) => {
      state.alertData = action.payload;
      state.hasError = false;
    },
    setAlertTypes: (state, action: PayloadAction<string[]>) => {
      state.alertTypes = action.payload;
      state.hasError = false;
    },
    setAlertFuzzySearch: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.alertFuzzySearch = action.payload;
    },
    setAlertError: (state) => {
      state.hasError = true;
      state.alertData = [];
      state.alertStatus = LoadingStatus.ERROR;
    },
    resetAlertState: () => {
      return initialState;
    },
  },
});

export const { setAlertStatus, setAlertData, setAlertTypes, setAlertFuzzySearch, setAlertError, resetAlertState } =
  alertSlice.actions;

export default alertSlice.reducer;
