import { SwitchListModel } from '../../models/baseModels/switchListModel';
import { Box } from '@mui/material';
import Item from '../../components/box-items/box-item';
import FormGroup from '@mui/material/FormGroup';
import classNames from 'classnames';
import CustomSwitch from '../switch/custom-switch.containter';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import './switch-list.scss';
interface SwitchListProps {
  items?: SwitchListModel[];
  readOnly: boolean;
  showSwitch: boolean;
  onChangeValue?: (newvalue: KeyValuePair, index: number) => void | undefined
}

const SwitchList = (props: SwitchListProps) => {
  const {
    items,
    readOnly,
    showSwitch,
    onChangeValue,
  } = props;

  return (
    <div>
      <FormGroup>
        {items?.map((item, i) => (
          <Box key={i}>
            <Box className={classNames(
                  'switch-list-grid',
                  {'hide-switch': !showSwitch}
                )}>
              { showSwitch && (<Item
                className={classNames(
                  'switch-list-text',
                  'switch-list-toggle-label'
                )}
              >
                {
                  <CustomSwitch
                    onBindingValue={(newvalue: KeyValuePair) => {
                      if (onChangeValue) {
                        onChangeValue(newvalue, i);
                      }
                    }}
                   key={i} name={item?.name} readOnly={readOnly} value={item?.enabled} className='switch-button' 
                   enabledLabel='On'
                   disabledLabel='Off'/>}
              </Item>)}
              <Box className="switch-list-container">
                <Item
                  className={classNames(
                    'switch-list-text',
                    'switch-list-main-label'
                  )}
                >
                  {item?.name}
                </Item>
                <Item
                  className={classNames(
                    'switch-list-text',
                    'switch-list-sub-label'
                  )}
                >
                  {item?.description}
                </Item>
              </Box>
            </Box>
            {items?.length > i + 1 && <Box className={classNames('box-line')} />}
          </Box>
        ))}
      </FormGroup>
    </div>
  );

};

export default SwitchList;
