import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import * as headerActions from '../../store/header/actions';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { Dispatch } from '@reduxjs/toolkit';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import Operations from './terminal-operations';
import { selectTerminalOperationData, selectTerminalOperationStatus } from '../../store/terminal-operations/selectors';
import * as terminalOperationActions from '../../store/terminal-operations/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import { TerminalOperationModel } from '../../models/terminalOperationModel';

const MapStateToProps = (state: RootState) => {
  return {     
    terminalOperationStatus: selectTerminalOperationStatus(state),
    terminalOperationData: selectTerminalOperationData(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const MapDispatchToProps = (dispatch: Dispatch) => {
  return {   
    loadAllOperations: (terminalId: string) =>
    dispatch({ type: terminalOperationActions.LOAD_TERMINALS_OPERATIONS, payload: terminalId }),
    checkOperationProgress: () =>
    dispatch({ type: terminalOperationActions.CHECK_OPERATION_PROGRESS }),
    cancelOperation: (data: TerminalOperationModel) =>
    dispatch({ type: terminalOperationActions.CANCEL_TERMINAL_OPERATION, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }), 
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
      openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }), 
  };
};

const TerminalOperations = connect(MapStateToProps, MapDispatchToProps)(Operations);

export default TerminalOperations;
