import { createSelector } from 'reselect';
import { RootState } from '../rootReducers';

export const selectLocalState = (state: RootState) => state.BinRangeState;

export const selectBinRangeStatus = createSelector(
    selectLocalState,
    state => state.binRangeStatus
);

export const selectBinRangeList = createSelector(
    selectLocalState,
    state => state.binRangeList
);

export const selectBinRangeData = createSelector(
    selectLocalState,
    state => state.binRangeData
);

export const selectBinRangeError = createSelector(
    selectLocalState,
    state => state.hasError
);

export const selectBinRangeNameList = createSelector(
    selectLocalState,
    state => state.binRnageNameList
);

