import { useCallback, useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import BackButton from '../back-button/back-button';
import ErrorDisplayControl from '../error-display/error-display.container';
import LoadingDisplay from '../loading-spinner/loading-display';
import MessageDisplay from '../message-display/message-display';
import BottomMenu from '../bottom-menu/bottom-menu.container';
import ConfirmDialogBox from '../confirm-dialog/confirm-dialog';
import CustomButton from '../button/custom-button';
import Wizard from '../wizard/wizard';
import FooterBar from '../footer/footer';
import TabList from '../tab-list/tab-list.container';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { TabListModel } from '../../models/baseModels/tabListModel';
import { Messages } from '../../constants/messages';
import { ButtonStyle } from '../../constants/button-constants';
import { LoadingStatus } from '../../constants/loading-constants';
import { EditIcon, ReceiptTemplateIcon } from '../icons';
import './styles/form.scss';

interface FormProps {
  isPageDirty: boolean;
  children: React.ReactNode;
  displayLoadingIndicator: boolean;
  displayErrorDetails: boolean;
  displayNoAccessMessage: boolean;
  displayForm: boolean;
  displayLivePreview?: boolean;
  displaySubSections?: boolean;
  isSaveButtonEnabled: boolean;
  hideCancelButton?: boolean;
  cancelText?: string | undefined;
  saveText?: string | undefined;
  enableDisplayPrompt?: boolean | false;
  formDataloading?: boolean;
  hasUpdateAccess?: boolean;
  backDropActionStatus: string;
  listURL?: string;
  isClickFromViewPage?: boolean;
  livePreview?: any;
  subChildren?: any;
  wizardList?: KeyValuePair[];
  tabWizardList?: TabListModel[];
  primaryButtonMoreOptions?: KeyValuePair[];
  secondaryButtonMoreOptions?: KeyValuePair[];
  forceChangeTabIndex?: number;
  onCancelClick: () => void;
  onSaveClick: () => void;
  onEditClick?: () => void;
  onPreviewClick?: () => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  onCurrentTabWizardChange?: (data: number) => void;
  wizardListChange?: () => void;
}

const FormComponent: React.FC<FormProps> = (props: FormProps) => {
  const {
    isPageDirty,
    children,
    displayLoadingIndicator,
    displayErrorDetails,
    displayNoAccessMessage,
    displayForm,
    displayLivePreview,
    displaySubSections,
    isSaveButtonEnabled,
    hideCancelButton,
    cancelText,
    saveText,
    enableDisplayPrompt,
    formDataloading,
    hasUpdateAccess,
    backDropActionStatus,
    listURL,
    isClickFromViewPage,
    livePreview,
    subChildren,
    wizardList,
    tabWizardList,
    primaryButtonMoreOptions,
    secondaryButtonMoreOptions,
    forceChangeTabIndex,
    onCancelClick,
    onSaveClick,
    onEditClick,
    onPreviewClick,
    setIsPageDirty,
    removeAllValidation,
    onCurrentTabWizardChange,
    wizardListChange,
  } = props;

  const { orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // Get router history stack, find if user visited directly from url
  const doesAnyHistoryEntryExist = location.key !== 'default';
  const [dialogState, setDialogStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [currentTabWizard, setCurrentTabWizard] = useState(1);
  const [forceTabIndex, setForceTabIndex] = useState(1);
  const [formSecondaryButtonMoreOptions, setFormSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [formPrimaryButtonMoreOptions, setFormPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);

  const routerBackHandler = useCallback(() => {
    if (isClickFromViewPage && doesAnyHistoryEntryExist) {
      navigate(-1);
    } else {
      if (listURL) {
        navigate(listURL);
      }
    }
  }, [isClickFromViewPage, doesAnyHistoryEntryExist, listURL, navigate]);

  useEffect(() => {
    setIsUpdatingForm(
      location.pathname.toLocaleLowerCase().endsWith('edit') ||
        location.pathname.toLocaleLowerCase().endsWith('create') ||
        location.pathname.toLocaleLowerCase().endsWith('export') ||
        location.pathname.toLocaleLowerCase().endsWith('invite-guest') ||
        location.pathname.toLocaleLowerCase().endsWith('add-delivery') ||
        location.pathname.toLocaleLowerCase().endsWith('add-adjustment')
        ? true
        : false
    );
  }, [location]);

  useEffect(() => {
    setTimeout(function () {
      setLoading(true);
    }, 200);
  }, []);

  useEffect(() => {
    setIsPageDirty(false);
    setDialogStatus(false);
  }, [isUpdatingForm, setIsPageDirty]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      routerBackHandler();
    }
  }, [backDropActionStatus, navigate, routerBackHandler]);

  useEffect(() => {
    setForceTabIndex(currentTabWizard);
    if (onCurrentTabWizardChange) {
      onCurrentTabWizardChange(currentTabWizard);
    }
  }, [currentTabWizard]);

  useEffect(() => {
    if (
      secondaryButtonMoreOptions &&
      secondaryButtonMoreOptions?.length > 0 &&
      tabWizardList &&
      currentTabWizard >= 1
    ) {
      setFormSecondaryButtonMoreOptions(
        secondaryButtonMoreOptions?.map((it) => {
          return {
            key: it.key,
            value: it.value,
            additionalValue: () => {
              if ((isSaveButtonEnabled || enableDisplayPrompt) && isPageDirty) {
                setIsPageDirty(false);
                setDialogStatus(true);
              } else {
                removeAllValidation();
                setIsPageDirty(false);
                routerBackHandler();
              }
              onCancelClick();
            },
          };
        })
      );
    } else {
      setFormSecondaryButtonMoreOptions([]);
    }
  }, [
    secondaryButtonMoreOptions,
    currentTabWizard,
    tabWizardList,
    isSaveButtonEnabled,
    enableDisplayPrompt,
    isPageDirty,
    setIsPageDirty,
    setDialogStatus,
    removeAllValidation,
    routerBackHandler,
    onCancelClick,
  ]);

  useEffect(() => {
    if (
      primaryButtonMoreOptions &&
      primaryButtonMoreOptions?.length > 0 &&
      tabWizardList &&
      currentTabWizard <= tabWizardList?.filter((it) => !it.tabDisabled)?.length
    )
      setFormPrimaryButtonMoreOptions(
        primaryButtonMoreOptions?.map((it) => {
          return {
            key: it.key,
            value: it.value,
            additionalValue: onSaveClick,
          };
        })
      );
    else {
      setFormPrimaryButtonMoreOptions([]);
    }
  }, [primaryButtonMoreOptions, currentTabWizard, tabWizardList, setFormPrimaryButtonMoreOptions, onSaveClick]);

  useEffect(() => {
    if (forceChangeTabIndex) {
      setForceTabIndex(forceChangeTabIndex);
    }
  }, [forceChangeTabIndex]);

  const cancelDialog = () => {
    setIsPageDirty(true);
    setDialogStatus(false);
  };

  const confirmDialog = () => {
    removeAllValidation();
    setIsPageDirty(false);
    routerBackHandler();
  };

  const onCancel = () => {
    if ((isSaveButtonEnabled || enableDisplayPrompt) && isPageDirty) {
      setIsPageDirty(false);
      setDialogStatus(true);
    } else {
      removeAllValidation();
      setIsPageDirty(false);
      routerBackHandler();
    }
    onCancelClick();
  };

  const onTabWizardCancel = useCallback(() => {
    if (currentTabWizard > 1) {
      setForceTabIndex(currentTabWizard - 1);
      if (wizardListChange) wizardListChange();
    } else {
      if ((isSaveButtonEnabled || enableDisplayPrompt) && isPageDirty) {
        setIsPageDirty(false);
        setDialogStatus(true);
      } else {
        removeAllValidation();
        setIsPageDirty(false);
        routerBackHandler();
      }
      onCancelClick();
    }
  }, [
    currentTabWizard,
    isSaveButtonEnabled,
    enableDisplayPrompt,
    isPageDirty,
    setIsPageDirty,
    removeAllValidation,
    setForceTabIndex,
    onCancelClick,
  ]);

  const onTabWizardSaveClick = useCallback(() => {
    if (tabWizardList && currentTabWizard < tabWizardList?.filter((it) => !it.tabDisabled)?.length - 1) {
      setForceTabIndex(currentTabWizard + 1);
      if (wizardListChange) wizardListChange();
    } else {
      onSaveClick();
    }
  }, [tabWizardList, currentTabWizard, setForceTabIndex, onSaveClick]);

  const onEdit = () => {
    if (onEditClick) {
      onEditClick();
    }
  };

  const onPreview = () => {
    if (onPreviewClick) {
      onPreviewClick();
    }
  };

  const onViewRecent = () => {
    navigate(`/organisations/${orgId}/transactions/recent`);
  };

  const handleTabWizardListChange = (data: number) => {
    setCurrentTabWizard(data);
  };

  return (
    <>
      <ConfirmDialogBox
        context={'Are you sure you want to discard your changes?'}
        open={dialogState}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      {!displayErrorDetails && !displayNoAccessMessage && (
        <Stack
          direction='row'
          className={classNames('buttonStack', tabWizardList ? 'button-stack-with-tab-wizard' : '')}
        >
          {wizardList ? <Wizard wizardList={wizardList} /> : tabWizardList ? <></> : <BackButton onClick={onCancel} />}
          <div className='function-buttons'>
            {displayLivePreview && (
              <CustomButton
                className='live-preview-icon'
                buttonStyle={ButtonStyle.EDIT}
                onClick={onPreview}
                icon={<ReceiptTemplateIcon />}
              >
                Preview
              </CustomButton>
            )}
            {!isUpdatingForm && hasUpdateAccess && !formDataloading && (
              <CustomButton buttonStyle={ButtonStyle.EDIT} onClick={onEdit} icon={<EditIcon />}>
                Edit
              </CustomButton>
            )}

            {displaySubSections && !formDataloading && (
              <CustomButton className='view-recent-button' buttonStyle={ButtonStyle.TOOLBAR} onClick={onViewRecent}>
                Recent
              </CustomButton>
            )}
          </div>
        </Stack>
      )}

      {displayNoAccessMessage && !displayErrorDetails && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {/* Form */}
      {loading &&
        displayForm &&
        !displayNoAccessMessage &&
        !displayLoadingIndicator &&
        !displayLivePreview &&
        !displaySubSections && (
          <Box
            className={classNames(
              'form',
              !isUpdatingForm && hasUpdateAccess && !formDataloading && tabWizardList
                ? 'form-with-tab-wizard-and-button-stack'
                : ''
            )}
          >
            {tabWizardList ? (
              <TabList
                tabDetailList={tabWizardList}
                wizardList={true}
                forceTabIndex={forceTabIndex}
                onBindingValue={handleTabWizardListChange}
              />
            ) : (
              children
            )}
          </Box>
        )}
      {/* Live Preview Form */}
      {loading &&
        displayForm &&
        !displayNoAccessMessage &&
        !displayLoadingIndicator &&
        displayLivePreview &&
        !displaySubSections && (
          <>
            <Box className='form-with-live-preview-desktop'>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  {children}
                </Grid>
                <Grid item xs={5}>
                  {livePreview}
                </Grid>
              </Grid>
            </Box>
            <Box className='form-with-live-preview-mobile'>{children}</Box>
          </>
        )}
      {/* Sub Sections Form */}
      {loading &&
        displayForm &&
        !displayNoAccessMessage &&
        !displayLoadingIndicator &&
        !displayLivePreview &&
        displaySubSections && (
          <>
            <Box className='form-with-sub-section-desktop'>
              <Grid container spacing={2}>
                <Grid item xs={9} className='main-section'>
                  {children}
                </Grid>
                <Grid item xs={3}>
                  {subChildren}
                </Grid>
              </Grid>
            </Box>
            <Box className='form-with-sub-section-mobile'>{children}</Box>
          </>
        )}

      <div className='loading-error-handler-container'>
        {displayLoadingIndicator && !displayNoAccessMessage && <LoadingDisplay />}
        {displayErrorDetails && <ErrorDisplayControl />}
      </div>

      {/* Form Footer */}
      {!displayErrorDetails &&
        !displayNoAccessMessage &&
        (location.pathname.toLocaleLowerCase().endsWith('create') ? true : !formDataloading) &&
        isUpdatingForm &&
        !displaySubSections &&
        !tabWizardList && (
          <div className='bottomMenu-container'>
            <BottomMenu
              onCancel={onCancel}
              onSave={onSaveClick}
              saveButtonEnabled={isSaveButtonEnabled}
              hideCancelButton={hideCancelButton}
              cancelText={cancelText}
              saveText={saveText}
            ></BottomMenu>
          </div>
        )}

      {/* Form Footer When Using Tab Wizard*/}
      {!displayErrorDetails &&
        !displayNoAccessMessage &&
        (location.pathname.toLocaleLowerCase().endsWith('create') ? true : !formDataloading) &&
        isUpdatingForm &&
        !displaySubSections &&
        tabWizardList && (
          <div className='bottomMenu-container'>
            <BottomMenu
              onCancel={onTabWizardCancel}
              onSave={onTabWizardSaveClick}
              saveButtonEnabled={isSaveButtonEnabled}
              hideCancelButton={hideCancelButton}
              cancelText={currentTabWizard === 1 ? cancelText : 'Back'}
              saveText={
                currentTabWizard === tabWizardList?.filter((it) => !it.tabDisabled).length - 1 ? saveText : 'Next'
              }
              primaryButtonMoreOptions={formPrimaryButtonMoreOptions}
              secondaryButtonMoreOptions={formSecondaryButtonMoreOptions}
            ></BottomMenu>
          </div>
        )}

      {/* Sub Sections Footer */}
      {!displayErrorDetails &&
        !displayNoAccessMessage &&
        (location.pathname.toLocaleLowerCase().endsWith('create') ? true : !formDataloading) &&
        isUpdatingForm &&
        displaySubSections &&
        !tabWizardList && (
          <div className='bottomMenu-container'>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <BottomMenu
                  onCancel={onCancel}
                  onSave={onSaveClick}
                  saveButtonEnabled={isSaveButtonEnabled}
                  cancelText={cancelText}
                  saveText={saveText}
                  isFormHasSubSection={true}
                ></BottomMenu>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </div>
        )}

      {!isUpdatingForm && (
        <div className='for-mobile'>
          <FooterBar className={classNames(isUpdatingForm ? 'footer-no-content' : 'footer-with-content')} />
        </div>
      )}
    </>
  );
};

export default FormComponent;
