import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './alertRuleInitState';
import { LoadingStatus } from '../../constants/loading-constants';
import { AlertRuleModel, AlertRuleTriggerOptionModel } from '../../models/alertRuleModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const alertRuleSlice = createSlice({
  name: 'alertRuleState',
  initialState,
  reducers: {
    setAlertRuleStatus: (state, action: PayloadAction<string>) => {
      state.alertRuleStatus = action.payload;
      state.hasError = false;
    },
    setAlertRuleListData: (state, action: PayloadAction<AlertRuleModel[]>) => {
      state.alertRuleList = action.payload;
      state.hasError = false;
    },
    setAlertRuleNameListData: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.alertRuleNameList = action.payload;
      state.hasError = false;
    },
    setAlertRuleInfoData: (state, action: PayloadAction<AlertRuleModel>) => {
      state.alertRuleInfo = action.payload;
      state.hasError = false;
    },
    setAlertRuleTriggerListData: (state, action: PayloadAction<AlertRuleTriggerOptionModel[]>) => {
      state.alertRuleTriggerList = action.payload;
      state.hasError = false;
    },
    setAlertRuleError: (state) => {
      state.hasError = true;
      state.alertRuleList = [];
      state.alertRuleStatus = LoadingStatus.ERROR;
    },
    resetAlertRuletate: () => {
      return initialState;
    },
  },
});

export const {
  setAlertRuleStatus,
  setAlertRuleListData,
  setAlertRuleNameListData,
  setAlertRuleInfoData,
  setAlertRuleTriggerListData,
  setAlertRuleError,
  resetAlertRuletate,
} = alertRuleSlice.actions;

export default alertRuleSlice.reducer;
