import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TableRow, TableCell } from '@mui/material';
import { HealthStatusDisplayType } from '../../../constants/status';
import { ModalType } from '../../../constants/modal-constants';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { TerminalWindcaveModel } from '../../../models/terminalModel';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import MainModal from '../../../components/main-modal/modal.container';
import Item from '../../../components/box-items/box-item';
import CopyText from '../../../components/copy-text/copy-text.container';
import * as fieldMappingHelper from '../../../utilities/fieldMapping-helper';
import '../styles/windcave-status.scss';

interface WindcaveStatusModalProps {
  modalData: ModalStateModel;
  modalActionStatus: string;
  closeModal: () => void;
}

const WindcaveStatusModal: React.FC<WindcaveStatusModalProps> = (props: WindcaveStatusModalProps) => {
  const { modalData, modalActionStatus, closeModal } = props;

  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);

  const [windcaveStatusInfo, setWindcaveStatusInfo] = useState({} as TerminalWindcaveModel);

  useEffect(() => {
    if (modalData.type === ModalType.EDIT) {
    }
  }, [modalData.type]);

  useEffect(() => {
    if (modalData?.terminalRecord?.windcave) setWindcaveStatusInfo(modalData?.terminalRecord?.windcave);
  }, [modalData]);

  const onCancel = (event: any) => {
    closeModal();
  };

  const cancelDialog = () => {
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
    closeModal();
  };

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        modalContentClassName='windcave-status-modal-container'
        onClose={onCancel}
        modalTitle={`Terminal ${modalData.name} Windcave Status`}
        onCancelButton={onCancel}
        viewOnlyModal={modalData.type === ModalType.VIEW}
        hideFooter={true}
        closeButtonText='Close'
        enableClickOutsideClose={true}
      >
        <div className='windcave-status-modal'>
          <div className='windcave-status-key-value-section'>
            {windcaveStatusInfo?.cardReader?.id && (
              <Item className='key-value-pair'>
                <Item className='key'>Serial Number</Item>
                <Item className='value'>
                  {windcaveStatusInfo?.cardReader?.id}
                  <CopyText copyValue={windcaveStatusInfo?.cardReader?.id} label='Serial Number' />
                </Item>
              </Item>
            )}
            {windcaveStatusInfo?.cardReader?.firmware && (
              <Item className='key-value-pair'>
                <Item className='key'>Firmware Version</Item>
                <Item className='value'>{windcaveStatusInfo?.cardReader?.firmware}</Item>
              </Item>
            )}
          </div>
          <div className='windcave-status-detail-table'>
            <TableRow className='table-header'>
              <TableCell className='entity-header'>Entity</TableCell>
              <TableCell className='status-header'>Status</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='entity-name'>Card Reader</TableCell>
              <TableCell className='status-detail'>
                <Item className={classNames('status-icon', `${windcaveStatusInfo?.cardReader?.status}-icon`)} />
                {fieldMappingHelper.getDisplayValue(windcaveStatusInfo?.cardReader?.status, HealthStatusDisplayType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='entity-name'>Keypad</TableCell>
              <TableCell className='status-detail'>
                <Item className={classNames('status-icon', `${windcaveStatusInfo?.keypad?.status}-icon`)} />
                {fieldMappingHelper.getDisplayValue(windcaveStatusInfo?.keypad?.status, HealthStatusDisplayType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='entity-name'>Server Connection</TableCell>
              <TableCell className='status-detail'>
                <Item className={classNames('status-icon', `${windcaveStatusInfo?.uplink?.status}-icon`)} />
                {fieldMappingHelper.getDisplayValue(windcaveStatusInfo?.uplink?.status, HealthStatusDisplayType)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='entity-name'>Contactless Reader</TableCell>
              <TableCell className='status-detail'>
                <Item className={classNames('status-icon', `${windcaveStatusInfo?.contactlessReader?.status}-icon`)} />
                {fieldMappingHelper.getDisplayValue(
                  windcaveStatusInfo?.contactlessReader?.status,
                  HealthStatusDisplayType
                )}
              </TableCell>
            </TableRow>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default WindcaveStatusModal;
