import { TransactionFilterRequest } from '../../entities/transaction';
import api, { setAuthorize } from '../../utilities/api';
import { convertObjectToQueryString } from '../../utilities/querystring-helper';

const PAYMENT_API_PREFIX = 'payment-management';

export const getAllTransactions = async (request: TransactionFilterRequest, fuzzyRequest: string) => {
  const qsRequest = convertObjectToQueryString(request);
  await setAuthorize();
  const res = await api.get(
    `/${PAYMENT_API_PREFIX}/transactions?${qsRequest}${fuzzyRequest ? `&pan:contains=${fuzzyRequest}` : ''}`
  );
  return res.data;
};
