import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import ClearIcon from '@mui/icons-material/Clear';
import CustomTextField from '../text-field/text-field.container';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { ValidationError } from '../../models/baseModels/validationModel';
import { TextFieldType } from '../../constants/textfield-constants';
import { Messages } from '../../constants/messages';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './editable-table.scss';

interface TableDropdownProps {
  name: string;
  value: string;
  options: any[];
  tableName: string;
  readOnly?: boolean;
  displayValueList?: KeyValuePair[];
  allRowsData?: any;
  onChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

const TableDropdown: React.FC<TableDropdownProps> = ({
  name,
  value,
  options,
  tableName,
  readOnly,
  displayValueList,
  allRowsData,
  onChange,
}) => {
  const handleSelect = (e: any) => {
    onChange(e);
  };

  const [avaliableOptions, setAvaliableOptions] = useState(options);

  useEffect(() => {
    const newObjectArray = options.filter(
      (obj) =>
        !allRowsData
          ?.map((it: any) => {
            return it?.rowData?.type;
          })
          .includes(obj.value) || obj.value === value
    );
    setAvaliableOptions(newObjectArray);
  }, [value, allRowsData, options]);

  return (
    <FormControl className={classNames(`select-form-control`)}>
      {readOnly ? (
        <CustomTextField
          key={value}
          name={value}
          value={fieldMappingHelper.getDisplayValue(value, displayValueList) || ''}
          type='input'
          isMandatory={false}
          readOnly={readOnly}
        />
      ) : (
        <Select
          key={value}
          className={classNames(`select-${tableName}`)}
          value={value || ''}
          onChange={handleSelect}
          inputProps={{
            name: name,
            id: name,
          }}
        >
          {(avaliableOptions || []).map((item) => {
            return (
              <MenuItem className={classNames(`select-item-${tableName}`)} key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
};

interface EditableRowProps {
  rowIndex: number;
  fieldsArr?: any[];
  editData?: any;
  tableName: string;
  readOnly?: boolean;
  displayValueList?: KeyValuePair[];
  allRowsData?: any;
  newRowRef?: React.RefObject<HTMLInputElement>;
  minimumRows?: number;
  handleSave: (row: any, index: number) => void;
  handleDeleteRow?: () => void;
}

const EditableRow: React.FC<EditableRowProps> = ({
  rowIndex,
  fieldsArr = [],
  editData,
  tableName,
  readOnly,
  displayValueList,
  allRowsData,
  newRowRef,
  minimumRows,
  handleSave,
  handleDeleteRow,
}) => {
  let initializeObj: any = {};
  fieldsArr.forEach((item) => {
    initializeObj[item.name] = '';
  });

  const [rowData, setRowData] = useState(editData ? Object.assign({}, initializeObj, editData) : initializeObj);

  useEffect(() => {
    if (editData) setRowData(editData);
  }, [editData]);

  const handleOnChange = (e: any) => {
    const updatedData = Object.assign({}, rowData, {
      [e.target.name]: e.target.value,
    });
    setRowData(updatedData);
    handleSave(updatedData, rowIndex);
  };

  const handleOnTextChange = (e: any) => {
    const updatedData = Object.assign({}, rowData, {
      [e.key]: e.value,
    });
    setRowData(updatedData);
    handleSave(updatedData, rowIndex);
  };

  return (
    <TableRow className={classNames(rowIndex % 2 === 0 ? 'even-row' : 'odd-row')}>
      {fieldsArr.map((item, i) => {
        return (
          <TableCell width={`${60 / fieldsArr?.length}%`} className={classNames(`tableBodyCell_${tableName}`)} key={i}>
            {item?.type === 'select' ? (
              <TableDropdown
                tableName={tableName}
                name={item.name}
                onChange={handleOnChange}
                options={item.options}
                value={rowData && rowData[item.name] ? rowData[item.name] : ''}
                readOnly={readOnly}
                displayValueList={displayValueList}
                allRowsData={allRowsData}
              />
            ) : (
              <CustomTextField
                validateCounter={0}
                key={item.name}
                name={item.name}
                onBindingValue={handleOnTextChange}
                placeholder={`Enter ${item.label}`}
                value={rowData && rowData[item.name] ? rowData[item.name] : ''}
                type={TextFieldType.INTEGER}
                readOnly={readOnly}
                maxCharLength={item.maxCharLength}
                minCharLength={item.minCharLength}
                maxRange={item.maxRange}
                minRange={item.minRange}
                inputRef={i === 0 ? newRowRef : undefined}
              />
            )}
          </TableCell>
        );
      })}
      {!readOnly && (
        <TableCell width={'10%'} className={classNames(`tableBodyCell_${tableName}`)}>
          {rowData.type !== 'default' && (minimumRows ? allRowsData?.length > minimumRows : true) && (
            <Button className={classNames(`table-delete-button`)} onClick={handleDeleteRow}>
              <ClearIcon />
            </Button>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

interface EditableTableProps {
  tableInfo?: string | number;
  defaultData?: any[];
  tableData?: any[];
  fieldsArr?: any[];
  classes?: any;
  name: string;
  initWithoutHead: boolean;
  readOnly?: boolean;
  displayValueList?: KeyValuePair[];
  isMandatory?: boolean;
  noDuplicateKey?: string;
  noDuplicateValue?: string;
  noDuplicateOnY?: boolean;
  duplicateErrorMsg?: string;
  minimumRows?: number;
  tableDataUpdate?: (data: any, tableInfo?: string | number) => void;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setCustomValidatationMessage: (value: string) => void;
}

const EditableTable: React.FC<EditableTableProps> = ({
  tableInfo,
  defaultData,
  tableData,
  fieldsArr = [],
  classes = {},
  name,
  initWithoutHead,
  readOnly,
  displayValueList,
  isMandatory,
  noDuplicateKey,
  noDuplicateValue,
  duplicateErrorMsg,
  minimumRows,
  tableDataUpdate,
  setFieldValidation,
  removeValidation,
  setCustomValidatationMessage,
}: EditableTableProps) => {
  const [allRowsData, setAllRowsData] = useState((defaultData || []).map((item) => ({ rowData: item })));

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      let newAllRowsData = tableData;
      newAllRowsData = tableData.map((item) => item);
      const mappedData = newAllRowsData.map((item: any) => ({
        rowData: {
          height:
            item.sensorReading !== undefined || item.sensorReading !== null ? item.sensorReading.toString() : undefined,
          volume:
            item.correspondingValue !== undefined || item.correspondingValue !== null
              ? item.correspondingValue.toString()
              : undefined,
        },
      }));
      setAllRowsData(mappedData);
    }
  }, [tableData]);

  const handleSave = (row: any, index: number) => {
    let newAllRowsData = allRowsData;
    newAllRowsData = newAllRowsData.map((item, i) => {
      if (i === index) {
        return { rowData: row };
      } else {
        return item;
      }
    });
    setAllRowsData(newAllRowsData);
    setToParent(newAllRowsData);
  };

  const setToParent = (data: any) => {
    //@ts-ignore
    const formattedData = data.map(({ rowData }) => rowData);
    if (tableDataUpdate) tableDataUpdate(formattedData, tableInfo);
  };

  const handleDeleteRow = (index: number) => {
    const updatedRows = allRowsData.filter((item, i) => i !== index);
    setAllRowsData(updatedRows);
    setToParent(updatedRows);
  };

  const headRow = readOnly
    ? [...fieldsArr.map((item) => ({ label: item.label, name: item.name }))]
    : [...fieldsArr.map((item) => ({ label: item.label, name: item.name })), { label: 'Actions', name: 'actions' }];

  const newRowRef = useRef<HTMLInputElement>(null);

  const handleAddClick = () => {
    let codeItem = { rowData: { type: '', code: '' } } as any;
    let list = [codeItem];
    list = [...allRowsData];
    list.push(codeItem);
    setAllRowsData(list);

    // Focus on the new added field
    setTimeout(() => {
      if (newRowRef.current) {
        newRowRef.current.focus();
      }
    }, 0);
  };
  const VALIDATION_MESSAGE_MANDATORY_FIELD = 'Required field';

  const isArrayEmpty = (array: any) => {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i].rowData;
      const values = Object.values(obj);
      if (!values.every((val) => val === '' || val === null || val === undefined)) {
        return false;
      }
    }
    return true;
  };

  /**FOR VALIDATION */
  const CheckMandatoryValue = useCallback(
    (value: any[]): boolean => {
      if (value === undefined || !value || value?.length <= 0 || isArrayEmpty(value)) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MANDATORY_FIELD,
        });
        return true;
      } else {
        removeValidation(name);
      }
      return false;
    },
    [VALIDATION_MESSAGE_MANDATORY_FIELD, name, setFieldValidation, removeValidation]
  );

  const CheckDuplicateKeyValue = useCallback(
    (value: any[]): boolean => {
      const hasDuplicateCheckValue = (rowDataArray: any, checkValue: any): boolean => {
        const values = Object.values(rowDataArray);
        const checkValues: string[] = values.map((row: any) => row.rowData[checkValue]);
        const duplicateValues = checkValues.filter((value, index) => checkValues.indexOf(value) !== index);
        return duplicateValues?.length > 0;
      };

      if (hasDuplicateCheckValue(value, noDuplicateKey) || hasDuplicateCheckValue(value, noDuplicateValue)) {
        setCustomValidatationMessage(duplicateErrorMsg ? duplicateErrorMsg : Messages.DUPLICATE_VALUES_ERROR_MESSAGE);
        return true;
      } else {
        setCustomValidatationMessage('');
        removeValidation(name);
      }
      return false;
    },
    [
      name,
      noDuplicateKey,
      noDuplicateValue,
      duplicateErrorMsg,
      setFieldValidation,
      removeValidation,
      setCustomValidatationMessage,
    ]
  );

  const validateData = useCallback(
    (value: any[]) => {
      if (isMandatory) {
        CheckMandatoryValue(value);
      }
      if (noDuplicateKey || noDuplicateValue) {
        CheckDuplicateKeyValue(value);
      }
    },
    [isMandatory, noDuplicateKey, noDuplicateValue, CheckMandatoryValue]
  );

  useEffect(() => {
    validateData(allRowsData);
  }, [allRowsData, validateData]);

  return (
    <>
      <Table className={classNames(`${name}`)}>
        {
          <TableHead className={classNames(classes.tableHead)}>
            <TableRow className={classNames(`editable-table-header-row`)}>
              {headRow.map(({ label, name }, i) => (
                <TableCell key={i}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        <TableBody className={classNames(`editable-table-body`)}>
          {!!allRowsData.length &&
            allRowsData.map(({ rowData }, i) => {
              return (
                <EditableRow
                  key={i}
                  rowIndex={i}
                  tableName={name}
                  editData={rowData}
                  handleSave={handleSave}
                  handleDeleteRow={() => handleDeleteRow(i)}
                  fieldsArr={fieldsArr}
                  displayValueList={displayValueList}
                  readOnly={readOnly}
                  allRowsData={allRowsData}
                  newRowRef={i === allRowsData.length - 1 ? newRowRef : undefined}
                  minimumRows={minimumRows}
                />
              );
            })}
        </TableBody>
      </Table>

      {!readOnly && (
        <Link className='add-rows' underline='hover' onClick={handleAddClick}>
          + Add Row
        </Link>
      )}
    </>
  );
};

export default EditableTable;
