import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/back-button/back-button';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import EmptyList from '../../components/empty-list/empty-list';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import MessageDisplay from '../../components/message-display/message-display';
import { LoadingStatus } from '../../constants/loading-constants';
import { Messages } from '../../constants/messages';
import { ModuleName } from '../../constants/module-constants';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { TerminalOperationModel, TerminalOperationsModel } from '../../models/terminalOperationModel';
import { operationType } from '../../constants/dropdown-constants';
import { AppPollInterval } from '../../config/config';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import OperationItem from './operationItem';
import './styles/terminal-operations.scss';

interface OperationsProps {
  terminalOperationStatus: string;
  terminalOperationData: TerminalOperationsModel;
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadAllOperations: (terminalId: string) => void;
  checkOperationProgress: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  cancelOperation: (data: TerminalOperationModel) => void;
}

const Operations: React.FC<OperationsProps> = (props: OperationsProps) => {
  const {
    terminalOperationStatus,
    terminalOperationData,
    authStatus,
    userAccess,
    loadAllOperations,
    checkOperationProgress,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    openDialogBox,
    closeDialogBox,
    cancelOperation,
  } = props;

  const navigate = useNavigate();
  const { orgId, siteId, terminalId } = useParams();
  const isLoadAllOperationsRequested = useRef(false);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  const [hasOperationReadAccess, setHasOperationReadAccess] = useState(false);
  const [hasOperationFileUpdateAccess, setHasOperationFileUpdateAccess] = useState(false);
  const [hasOperationRestartAccess, setHasOperationRestartAccess] = useState(false);
  const [hasOperationBootloaderRestartAccess, sethasOperationBootloaderRestartAccess] = useState(false);
  const [hasOperationMemoryDumpAccess, setHasOperationMemoryDumpAccess] = useState(false);

  /** CHECK ACCESS STATUS */
  useMemo(() => {
    setHasOperationReadAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasReadAccess);
    setHasOperationFileUpdateAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasFileUploadAccess);
    setHasOperationRestartAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasRestartAccess);
    sethasOperationBootloaderRestartAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasBootloaderRestartAccess);
    setHasOperationMemoryDumpAccess(userAccess(ModuleName.TERMINAL_OPERATION).hasMemoryDumpAccess);
  }, [userAccess]);

  const hasCreateAccess =
    hasOperationFileUpdateAccess ||
    hasOperationRestartAccess ||
    hasOperationBootloaderRestartAccess ||
    hasOperationMemoryDumpAccess;

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [title, setTitle] = useState('');

  useMemo(() => {
    setLoading(terminalOperationStatus === LoadingStatus.LOADING && hasOperationReadAccess);
    setSuccess(terminalOperationStatus === LoadingStatus.SUCCESS && hasOperationReadAccess && !hasNoSystemAccess);
    setError(terminalOperationStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasNoSystemAccess, hasOperationReadAccess, terminalOperationStatus]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (authSuccess && hasOperationReadAccess) {
      if (!isLoadAllOperationsRequested.current && !!terminalId) {
        loadAllOperations(terminalId);
      }
      isLoadAllOperationsRequested.current = true;
    }
  }, [authSuccess, hasOperationReadAccess, loadAllOperations, terminalId]);

  useEffect(() => {
    if (success && terminalOperationData?.terminalNumber) {
      setTitle(`Terminal ${terminalOperationData?.terminalNumber} Operations`);
    } else setTitle('Terminal Operations');
  }, [success, terminalOperationData?.terminalNumber]);

  useEffect(() => {
    setHeaderConfiguration({
      title: title,
      showCreateButton: hasCreateAccess,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      error: error,
      pageURL: 'terminal-operation',
      createButtonText: 'Start Operation',
    } as HeaderStateModel);
  }, [title, error, hasCreateAccess, setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  const isPageVisible = usePageVisibility();

  useEffect(() => {
    if (isPageVisible) {
      let intervalId = setInterval(() => {}, AppPollInterval);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPageVisible, checkOperationProgress]);

  const onBackButtonClick = () => {
    navigate(`/organisations/${orgId}/sites/${siteId}/terminals`);
  };
  /** DIALOG BUTTONS */

  const [cancelDialogContext, setCancelDialogContext] = useState('');
  const [selectedTerminalOperationItem, setSelectedTerminalOperationItem] = useState({} as TerminalOperationModel);

  const cancelDialog = () => {
    closeDialogBox();
  };

  const confirmDialog = () => {
    cancelOperation(selectedTerminalOperationItem);
  };

  const onCancelOperation = useCallback(
    (item: TerminalOperationModel) => {
      let displayValue = fieldMappingHelper.getDisplayValue(item?.type, operationType);
      setSelectedTerminalOperationItem(item);
      setCancelDialogContext(`Are you sure you want to cancel ${displayValue} operation?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  return (
    <>
      {
        <DialogBoxComponent
          context={cancelDialogContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Cancel Operation'
        />
      }
      {(success || loading) && <BackButton onClick={onBackButtonClick} />}
      {(!authCheckCompleted || loading) && (
        <div className='loading-error-handler-container'>
          <LoadingDisplay />
        </div>
      )}
      {(hasNoSystemAccess || (authSuccess && !hasOperationReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {success && (
        <>
          <div className={'operation-section-title'}>On-going Operations</div>
          <div className='ongoing-operation-list-container'>
            {terminalOperationData?.runningOperations &&
              terminalOperationData?.runningOperations.map((item, i) => (
                <OperationItem
                  key={item.id}
                  item={item}
                  hasFileUploadAccess={hasOperationFileUpdateAccess}
                  hasRestartAccess={hasOperationRestartAccess}
                  hasBootloaderRestartAccess={hasOperationBootloaderRestartAccess}
                  hasMemoryDumpAccess={hasOperationMemoryDumpAccess}
                  onCancelOperation={() => onCancelOperation(item)}
                />
              ))}
            {!!terminalOperationData?.runningOperations && terminalOperationData?.runningOperations?.length < 1 && (
              <EmptyList message='No on-going operations found'></EmptyList>
            )}
          </div>
          <div className={'operation-section-title'}>Pending Operations</div>
          <div className='pending-operation-list-container'>
            {terminalOperationData?.pendingOperations &&
              terminalOperationData?.pendingOperations.map((item, i) => (
                <OperationItem
                  key={item.id}
                  item={item}
                  hasFileUploadAccess={hasOperationFileUpdateAccess}
                  hasRestartAccess={hasOperationRestartAccess}
                  hasBootloaderRestartAccess={hasOperationBootloaderRestartAccess}
                  hasMemoryDumpAccess={hasOperationMemoryDumpAccess}
                  onCancelOperation={() => onCancelOperation(item)}
                />
              ))}
            {!!terminalOperationData?.pendingOperations && terminalOperationData?.pendingOperations?.length < 1 && (
              <EmptyList message='No pending operations found'></EmptyList>
            )}
          </div>
          <div className={'operation-section-title'}>Completed Operations</div>
          <div className='completed-operation-list-container'>
            {terminalOperationData?.completedOperations &&
              terminalOperationData?.completedOperations.map((item, i) => (
                <OperationItem
                  key={item.id}
                  item={item}
                  hasFileUploadAccess={hasOperationFileUpdateAccess}
                  hasRestartAccess={hasOperationRestartAccess}
                  hasBootloaderRestartAccess={hasOperationBootloaderRestartAccess}
                  hasMemoryDumpAccess={hasOperationMemoryDumpAccess}
                  onCancelOperation={() => onCancelOperation(item)}
                />
              ))}
            {!!terminalOperationData?.completedOperations && terminalOperationData?.completedOperations?.length < 1 && (
              <EmptyList message='No completed operations found'></EmptyList>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default Operations;
