import React, { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle, Grid, Link } from '@mui/material';
import { AlertRuleActionModel } from '../../../models/alertRuleModel';
import { notificationMethod } from '../../../constants/dropdown-constants';
import { FormActionType } from '../../../constants/form-constants';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomCheckBox from '../../../components/checkbox/checkbox';
import '../styles/actions.scss';

interface ActionsProps {
  validateCounter: number;
  actions: AlertRuleActionModel[];
  alertUserGroupNameList: KeyValuePair[];
  readOnly?: boolean;
  formAction?: string;
  onBindingValue?: (newvalue: AlertRuleActionModel[]) => void | undefined;
  removeValidation: (name: string) => void;
  selectedSMSActions?: (value: boolean) => void;
  setAgreedSMSWarning?: (value: boolean) => void;
}

const ActionsComponent: React.FC<ActionsProps> = (props: ActionsProps) => {
  const {
    validateCounter,
    actions,
    alertUserGroupNameList,
    readOnly,
    formAction,
    onBindingValue,
    removeValidation,
    selectedSMSActions,
    setAgreedSMSWarning,
  } = props;
  const [actionsValue, setActionsValue] = useState([] as AlertRuleActionModel[]);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSelectedSMSActions, setIsSelectedSMSActions] = useState(false);
  const [isAgreedSMSWarning, setIsAgreedSMSWarning] = useState(false);

  useEffect(() => {
    setValidateCounterFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setActionsValue(actions);
  }, [actions, setActionsValue]);

  useEffect(() => {
    const isSMSSelected = actionsValue?.filter((it) => it?.notificationMethod === 'sms')?.length > 0;
    setIsSelectedSMSActions(isSMSSelected);
    if (selectedSMSActions) {
      selectedSMSActions(isSMSSelected);
    }
  }, [actionsValue, selectedSMSActions]);

  useEffect(() => {
    if (formAction === FormActionType.VIEW) {
      setIsAgreedSMSWarning(true);
      if (setAgreedSMSWarning) setAgreedSMSWarning(true);
    } else if (formAction === FormActionType.EDIT || formAction === FormActionType.CREATE) {
      setIsAgreedSMSWarning(false);
      if (setAgreedSMSWarning) setAgreedSMSWarning(false);
    }
  }, [formAction]);

  // handle click event of the Add button
  const handleAddClick = () => {
    setValidateCounterFlag(0);
    let newItem = {} as AlertRuleActionModel;
    let list = [newItem];
    if (actionsValue !== undefined) {
      list = [...actionsValue];
      list.push(newItem);
    }
    setActionsValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetHoseListValidation();
    let list = [...actionsValue];
    list.splice(index, 1);
    setActionsValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetHoseListValidation = () => {
    actionsValue.forEach((_, index) => {
      removeValidation(`sites${index}`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let actions = [...actionsValue];
    if (!!actions[index]) {
      let item = { ...actions[index] };
      if (newvalue.key.toString().includes('notificationMethod')) {
        item.notificationMethod = String(newvalue.value);
      } else if (newvalue.key.toString().includes('userGroup')) {
        item.userGroupId = newvalue.value.toString();
      }
      actions[index] = item;
      setActionsValue(actions);
      if (onBindingValue) {
        onBindingValue(actions);
      }
    }
  };

  const SMSWarningCheckedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setAgreedSMSWarning) {
      setIsAgreedSMSWarning(event.target.checked);
      setAgreedSMSWarning(event.target.checked);
    }
  };

  return (
    <div className='actions-container'>
      <div className='action-header'>Actions {!readOnly && <strong className='asterisk'>*</strong>}</div>
      {(!actionsValue || actionsValue?.length < 1) && readOnly && (
        <div className='no-data-found-text'>No actions found</div>
      )}
      {!!actionsValue &&
        actionsValue?.map((item, i) => {
          return (
            <>
              {!readOnly && actionsValue && actionsValue?.length > 1 && (
                <Link className='remove-action' underline='hover' onClick={() => handleRemoveClick(i)}>
                  - Remove
                </Link>
              )}
              <Grid item xs={12} sm={12} rowGap={2} className='action-item-container'>
                <Grid container alignItems='flex-start' spacing={1}>
                  <Grid item xs={12} md={12} lg={6}>
                    <DropDown
                      validateCounter={validateCounterFlag}
                      key={`notificationMethod${i}`}
                      name={`notificationMethod${i}`}
                      value={item?.notificationMethod}
                      onBindingValue={(newvalue: KeyValuePair) => {
                        handleNewValue(newvalue, i);
                      }}
                      label='Notification'
                      isMandatory={true}
                      hideAsteriskSymbol={true}
                      keyValuePair={notificationMethod}
                      readOnly={readOnly || alertUserGroupNameList?.length <= 0}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <DropDown
                      validateCounter={validateCounterFlag}
                      key={`userGroup${i}`}
                      name={`userGroup${i}`}
                      value={item?.userGroupId}
                      onBindingValue={(newvalue: KeyValuePair) => {
                        handleNewValue(newvalue, i);
                      }}
                      label='User Group'
                      isMandatory={true}
                      hideAsteriskSymbol={true}
                      keyValuePair={alertUserGroupNameList}
                      readOnly={readOnly || alertUserGroupNameList?.length <= 0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}

      {!readOnly && (!actionsValue || actionsValue?.length < 2) && (
        <Link className='add-action' underline='hover' onClick={handleAddClick}>
          + Add Action
        </Link>
      )}

      {isSelectedSMSActions && (
        <Alert className='warning-info' variant='outlined' severity='warning'>
          <AlertTitle>Warning</AlertTitle>
          {formAction !== FormActionType.VIEW && (
            <CustomCheckBox
              className='sms-warning-checkbox'
              onChange={SMSWarningCheckedHandler}
              name='warning-info'
              defaultState={false}
              label='By checking the box, you acknowledge and agree that SMS messages will incur extra charges.'
              isChecked={isAgreedSMSWarning}
              readOnly={readOnly}
            />
          )}
          {formAction === FormActionType.VIEW && <span className='warning-msg'>SMS messages incur extra charges.</span>}
        </Alert>
      )}
    </div>
  );
};

export default ActionsComponent;
