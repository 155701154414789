import {
  selectCustomValidationMessage,
  selectHasValidationError,
  showValidationError,
} from './../../store/fieldValidation/selectors';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import BottomMenuComponent from './bottom-menu';
import { selectNavPaneStatus } from './../../store/nav-pane/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    hasValidationError: selectHasValidationError(state),
    showValidationError: showValidationError(state),
    navPaneState: selectNavPaneStatus(state),
    customValidationMessage: selectCustomValidationMessage(state),
  };
};

const BottomMenu = connect(mapStateToProps)(BottomMenuComponent);

export default BottomMenu;
