import React, { useEffect, useState } from 'react';
import { ModalType } from '../../../constants/modal-constants';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import MainModal from '../../../components/main-modal/modal.container';
import CustomCheckBox from '../../../components/checkbox/checkbox';
import '../styles/export-columns-modal.scss';

interface ExportColumnsModalProps {
  modalData: ModalStateModel;

  closeModal: () => void;
  removeAllValidation: () => void;
  onBindingValue?: (newvalue: string[]) => void;
}

const ExportColumnsModal: React.FC<ExportColumnsModalProps> = (props: ExportColumnsModalProps) => {
  const { modalData, closeModal, removeAllValidation, onBindingValue } = props;
  const [isTouched, setIsTouched] = useState(false);

  const [avaliableColumns, setAvaliableCoulmns] = useState([] as string[]);
  const [selectedColumnList, setSelectedColumnList] = useState([] as string[]);

  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);

  useEffect(() => {
    setIsTouched(false);
    if (modalData.columnList) {
      setAvaliableCoulmns(modalData.columnList);
    }
  }, [modalData.columnList]);

  const onCancel = (event: any) => {
    if (isTouched) {
      setDialogStatus({
        ...dialogState,
        isOpen: true,
        context: 'Are you sure you want to discard your changes?',
      });
    } else {
      removeAllValidation();
      setDialogStatus({
        ...dialogState,
        isOpen: false,
      });
      closeModal();
    }
  };

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (onBindingValue && selectedColumnList.length > 0) {
      onBindingValue(selectedColumnList);
    }

    closeModal();
  };

  const cancelDialog = () => {
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    removeAllValidation();
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });

    closeModal();
  };

  const selectAllCheckedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let existingList = selectedColumnList;
      const newList = [...existingList, event.target.name];
      setSelectedColumnList(newList);
    } else {
      let existingList = selectedColumnList;
      const newList = existingList.filter((it) => it !== event.target.name);
      setSelectedColumnList(newList);
    }
  };

  const isCheckedHandler = (value: string) => {
    if (selectedColumnList.includes(value)) {
      return true;
    } else return false;
  };

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        onClose={onCancel}
        modalTitle='Add Columns'
        saveButtonEnabled={true}
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
        viewOnlyModal={modalData.type === ModalType.VIEW}
        cancelButtonText='Cancel'
        saveButtonText='Add'
        closeButtonText='Close'
      >
        <div className='avaliable-column-list'>
          {avaliableColumns.map((it) => {
            return (
              <CustomCheckBox
                onChange={selectAllCheckedHandler}
                key={it}
                name={it}
                label={it}
                defaultState={false}
                isChecked={isCheckedHandler(it)}
              />
            );
          })}
        </div>
      </MainModal>
    </>
  );
};

export default ExportColumnsModal;
