import { takeLatest, put } from 'redux-saga/effects';
import { setHeaderConfiguration } from './reducers';
import * as actions from './actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';

export function* rootSaga() {
  yield takeLatest(actions.CHANGE_HEADER_CONFIGURATION, changeHeaderConfiguration);
}

export function* changeHeaderConfiguration(
  action: PayloadAction<HeaderStateModel>
) {
  yield put(setHeaderConfiguration(action.payload));
}
