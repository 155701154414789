import React from 'react';
import { Tooltip } from '@mui/material';
import './index.scss';
import { NavPaneStates } from '../../../constants/window-constants';

interface NavPaneProps {
  isFooter?: boolean;
  buttonNode: JSX.Element;
  navPaneState: string;
  title: string;
}

const NavPaneTooltip: React.FC<NavPaneProps> = (props: NavPaneProps) => {
  const { isFooter, buttonNode, navPaneState, title } = props;

  return (
    <div className='navpane-tooltip'>
      <Tooltip
        className='navpane-tooltip'
        title={isFooter ? title : navPaneState === NavPaneStates.COLLAPSED ? title : ''}
        placement='right'
        arrow
        followCursor
      >
        {buttonNode}
      </Tooltip>
    </div>
  );
};

export default NavPaneTooltip;
