export const downloadObjectAsJSON = async (obj: any, fileName: string) => {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj));
  const downloadNode = document.createElement('a');
  downloadNode.setAttribute('href', dataStr);
  downloadNode.setAttribute('download', fileName + '.json');
  document.body.appendChild(downloadNode); // required for firefox
  downloadNode.click();
  downloadNode.remove();
};

export const downloadFileWithOutFileType = async (link: string, fileName: string) => {
  let FileSaver = require('file-saver');
  FileSaver.saveAs(link, fileName);
};

export const downloadFile = async (link: string, fileName: string, fileType: string) => {
  let FileSaver = require('file-saver');
  FileSaver.saveAs(link, fileName + `.${fileType}`);
};

export const downloadCSVTemplate = (data: string, fileName: string) => {
  let FileSaver = require('file-saver');
  var blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, `${fileName}.csv`);
};
