import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectSelectedSiteId } from '../../../store/sites/selectors';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import {  selectTankData } from '../../../store/tanks/selectors';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { NewTankDeliveryModel } from '../../../models/tankDeliveryModel';
import * as actions from '../../../store/tank-deliveries/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as dialogActions from '../../../store/dialog-box/actions';
import TankDeliveryForm from './tank-delivery';

const mapStateToProps = (state: RootState) => {
  return {
    tankList: selectTankData(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    selectedSiteId: selectSelectedSiteId(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createTankDelivery: (data: NewTankDeliveryModel) =>
      dispatch({
        type: actions.CREATE_TANK_DELIVERY,
        payload: data,
      }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
  };
};

const TankDeliveryPage = connect(mapStateToProps, mapDispatchToProps)(TankDeliveryForm);

export default TankDeliveryPage;
