import { takeLatest, put } from 'redux-saga/effects';
import { setPaginationInfo, setIsReachEnd, clearContinuationTokenList } from './reducers';
import * as actions from './actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { PaginationModel } from '../../models/paginationModel';

export function* rootSaga() {
  yield takeLatest(actions.CHANGE_PAGINATION_DETAILS, changePaginationDetails);
  yield takeLatest(actions.CHANGE_IS_REACH_END, changeIsReachEnd);
  yield takeLatest(actions.CLEAR_CONTINUATION_TOKEN, clearContinuationToken);
}

export function* changePaginationDetails(action: PayloadAction<PaginationModel>) {
  yield put(setPaginationInfo(action.payload));
}

export function* changeIsReachEnd(action: PayloadAction<boolean>) {
  yield put(setIsReachEnd(action.payload));
}

export function* clearContinuationToken() {
  yield put(clearContinuationTokenList());
}
