import React, { useEffect } from 'react';
import useAuthenticationManager from '../../components/auth/authenticationManager';

interface LogoutProps {    
  setIsPageDirty: (data: boolean) => void;
}

const Logout: React.FC<LogoutProps> = (props: LogoutProps) => {
    const { setIsPageDirty } =
    props;
  const { instance } = useAuthenticationManager();
  useEffect(() => {
    sessionStorage.clear();
    setIsPageDirty(false);
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }, [instance, setIsPageDirty]);
  return (
    <React.Fragment>
      <div style={{ color: 'white', paddingTop: '10px' }}></div>
    </React.Fragment>
  );
};

export default Logout;
