import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import {
  selectBulkOperationState,
  selectBulkOperations,
  selectOperationsDetailsList,
  selectOperationsDetailsListState,
  selectRunningOperationsDetails,
} from '../../store/card-operations/selectors';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import * as bulkOperationActions from '../../store/card-operations/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as headerActions from '../../store/header/actions';
import Operations from './card-operations';

const MapStateToProps = (state: RootState) => {
  return {
    authStatus: selectAuthStatus(state),
    bulkOperationList: selectBulkOperations(state),
    bulkOperationState: selectBulkOperationState(state),
    runningOperationsDetails: selectRunningOperationsDetails(state),
    opeationDetailsList: selectOperationsDetailsList(state),
    operationsDetailsListState: selectOperationsDetailsListState(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const MapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadAllOperations: () => dispatch({ type: bulkOperationActions.LOAD_BULK_OPERATIONS }),
    loadOperationByIdStoreInList: (data: string) => {
      dispatch({
        type: bulkOperationActions.LOAD_OPERATION_BY_ID_STORE_IN_LIST,
        payload: data,
      });
    },
    cancelOperationReload: () =>
      dispatch({
        type: bulkOperationActions.CANCEL_OPERATION_RELOAD,
      }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
  };
};

const CardOperations = connect(MapStateToProps, MapDispatchToProps)(Operations);

export default CardOperations;
