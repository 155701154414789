import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectTerminalOperationState = (state: RootState) => state.TerminalOperationState;

export const selectTerminalOperationStatus = createSelector(
  selectTerminalOperationState,
  (state) => state.terminalOperationStatus
);

export const selectTerminalDirectoryStatus = createSelector(
  selectTerminalOperationState,
  (state) => state.terminalDirectoryStatus
);

export const selectTerminalOperationData = createSelector(
  selectTerminalOperationState,
  (state) => state.terminalOperatonsData
);

export const selectTerminalDirectoryData = createSelector(
  selectTerminalOperationState,
  (state) => state.terminalDirectoryData
);

export const selectTerminalDirectoryList = createSelector(
  selectTerminalOperationState,
  (state) => state.terminalDirectoryList
);
