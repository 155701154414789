import { forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import './dnd-item.scss';

export enum Position {
  Before = -1,
  After = 1,
}

export interface Props extends Omit<HTMLAttributes<HTMLButtonElement>, 'id'> {
  active?: boolean;
  clone?: boolean;
  insertPosition?: Position;
  id: string;
  index?: number;
  layout: 'grid';
  onRemove?(): void;
}

export const DndItem = forwardRef<HTMLLIElement, Props>(function Page(
  { id, index, active, clone, insertPosition, layout, onRemove, style, ...props },
  ref
) {
  return (
    <li className={classNames('dnd-item-containter')} style={style} ref={ref}>
      <button className='dnd-item' data-id={id.toString()} {...props}>
        {id}
      </button>
      {!active && onRemove ? <CloseIcon className='dnd-item-remove' onClick={onRemove} /> : null}
    </li>
  );
});
