import React, { useCallback, useEffect, useState } from 'react';
import { ValidationError } from '../../models/baseModels/validationModel';
import { DATETIME_FORMAT } from '../../constants/dateTimePicker-constants';
import { Messages } from '../../constants/messages';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import DropDown from '../dropdown/dropdown.container';
import DateRangeComponent from '../daterange/daterange.container';
import classNames from 'classnames';
import './datetimerange.scss';

interface DateTimePickerProps {
  label: string;
  value: string;
  startDateTimeValue: string;
  endDateTimeValue: string;
  startDateFieldName: string;
  endDateFieldName: string;
  validateCounter?: number;
  handleStartDateTimeChange: (newValue: string) => void;
  handleEndDateTimeChange: (newValue: string) => void;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
  onBindingValue?: (newvalue: string) => void | undefined;
}

const DateTimePickerControl = (props: DateTimePickerProps) => {
  const {
    label,
    value,
    startDateTimeValue,
    endDateTimeValue,
    startDateFieldName,
    endDateFieldName,
    validateCounter,
    handleStartDateTimeChange,
    handleEndDateTimeChange,
    setFieldValidation,
    removeValidation,
    setShowValidationMessage,
    onBindingValue,
  } = props;

  const dateRangeControlName = `daterange-${startDateFieldName}-${endDateFieldName}`;
  const [selectedDateTimeRange, setSelectedDateTimeRange] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');

  const dateRangeValidation = useCallback(() => {
    if (!!startDateTimeValue && !!endDateTimeValue) {
      let startDateTime = new Date(startDateTimeValue);
      let endDateTime = new Date(endDateTimeValue);

      if (startDateTime.valueOf() >= endDateTime?.valueOf()) {
        setFieldValidation({
          name: dateRangeControlName,
          hasError: true,
          showErrorMessage: false,
          validationMessage: Messages.DATE_RANGE_START_END_VALIDATION,
        });
        setShowValidationMessage({ key: dateRangeControlName, value: true });
      } else {
        removeValidation(dateRangeControlName);
        setShowValidationMessage({ key: dateRangeControlName, value: false });
      }
    } else {
      removeValidation(dateRangeControlName);
      setShowValidationMessage({ key: dateRangeControlName, value: false });
    }
  }, [
    dateRangeControlName,
    endDateTimeValue,
    startDateTimeValue,
    removeValidation,
    setFieldValidation,
    setShowValidationMessage,
  ]);

  useEffect(() => {
    dateRangeValidation();
  }, [dateRangeValidation]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      dateRangeValidation();
    }
  }, [validateCounter, dateRangeValidation]);

  useEffect(() => {
    if (value) {
      setSelectedDateTimeRange(value);
    } else {
      setSelectedDateTimeRange('');
    }
  }, [value, setSelectedDateTimeRange]);

  useEffect(() => {
    setStartDateTime(startDateTimeValue);
  }, [startDateTimeValue]);

  useEffect(() => {
    setEndDateTime(endDateTimeValue);
  }, [endDateTimeValue]);

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setSelectedDateTimeRange(String(newvalue.value));
      if (onBindingValue) {
        onBindingValue(String(newvalue.value));
      }
    },
    [setSelectedDateTimeRange]
  );

  const currentDate = new Date();

  const todayFormatMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate);
  const todayFormatDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate);
  const formattedToday = `${todayFormatDay} ${todayFormatMonth}`;

  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(currentDate.getDate() - 1);

  const yesterdayFormatMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(yesterdayDate);
  const yesterdayFormatDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(yesterdayDate);
  const formattedYesterday = `${yesterdayFormatDay} ${yesterdayFormatMonth}`;

  const currentDay = currentDate.getDay();
  const daysToMonday = currentDay === 0 ? 6 : currentDay - 1;
  const daysToSunday = 7 - currentDay;
  const mondayDate = new Date(currentDate);
  mondayDate.setDate(currentDate.getDate() - daysToMonday);
  const sundayDate = new Date(currentDate);
  sundayDate.setDate(currentDate.getDate() + daysToSunday);

  const thisMondayFormatMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(mondayDate);
  const thisMondayFormatDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(mondayDate);
  const thisSundayFormatMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(sundayDate);
  const thisSundayFormatDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(sundayDate);
  const formattedThisWeek = `${thisMondayFormatDay} ${thisMondayFormatMonth} - ${thisSundayFormatDay} ${thisSundayFormatMonth}`;

  const daysToLastMonday = (currentDay === 0 ? 6 : currentDay - 1) + 7;
  const daysToLastSunday = currentDay === 0 ? 0 : currentDay;

  const lastMondayDate = new Date(currentDate);
  lastMondayDate.setDate(currentDate.getDate() - daysToLastMonday);
  const lastSundayDate = new Date(currentDate);
  lastSundayDate.setDate(currentDate.getDate() - daysToLastSunday);

  const lastMondayFormatMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(lastMondayDate);
  const lastMondayFormatDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(lastMondayDate);
  const lastSundayFormatMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(lastSundayDate);
  const lastSundayFormatDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(lastSundayDate);
  const formattedLastWeek = `${lastMondayFormatDay} ${lastMondayFormatMonth} - ${lastSundayFormatDay} ${lastSundayFormatMonth}`;

  const monthOptions: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' };
  const currentFormattedMonth = currentDate.toLocaleDateString(undefined, monthOptions);
  const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
  const previousFormattedMonth = previousMonth.toLocaleDateString(undefined, monthOptions);

  const avaliableShortcuts: KeyValuePair[] = [
    { key: 'today', value: 'Today', additionalValue: formattedToday },
    { key: 'yesterday', value: 'Yesterday', additionalValue: formattedYesterday },
    { key: 'thisWeek', value: 'This Week', additionalValue: formattedThisWeek },
    { key: 'lastWeek', value: 'Last Week', additionalValue: formattedLastWeek },
    { key: 'thisMonth', value: 'This Month', additionalValue: currentFormattedMonth },
    { key: 'lastMonth', value: 'Last Month', additionalValue: previousFormattedMonth },
    { key: 'custom', value: 'Custom' },
  ];

  return (
    <React.Fragment>
      <div className={classNames('datetimerange-container')}>
        <div className='datetimerange-picker-container'>
          <DropDown
            key='selectedDateRange'
            name='selectedDateRange'
            label={label}
            onBindingValue={onTextChangeHandler}
            value={selectedDateTimeRange}
            keyValuePair={avaliableShortcuts}
          />
          {selectedDateTimeRange === 'custom' && (
            <DateRangeComponent
              fromLabel='From'
              toLabel='To'
              dateFormat={DATETIME_FORMAT.DATE_ONLY}
              startDateTimeValue={startDateTime}
              endDateTimeValue={endDateTime}
              handleStartDateTimeChange={handleStartDateTimeChange}
              handleEndDateTimeChange={handleEndDateTimeChange}
              disableFuture={true}
              startDateFieldName='startDate'
              endDateFieldName='endDate'
              startTimeFieldName='startTime'
              endTimeFieldName='endTime'
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DateTimePickerControl;
