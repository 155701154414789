import { LoadingStatus } from '../../constants/loading-constants';
import { TerminalDirectoryModel, TerminalOperationsModel } from '../../models/terminalOperationModel';

interface terminalOperationState {
  terminalOperationStatus: string;
  terminalDirectoryStatus: string;
  terminalOperatonsData: TerminalOperationsModel;
  terminalDirectoryData: TerminalDirectoryModel;
  terminalDirectoryList: TerminalDirectoryModel[];
  hasError: boolean;
}

export const initialState: terminalOperationState = {
  terminalOperationStatus: LoadingStatus.LOADING,
  terminalDirectoryStatus: LoadingStatus.LOADING,
  hasError: false,
} as terminalOperationState;
