import api, { setAuthorize } from '../../utilities/api';
import { SiteConfiguration } from '../../entities/site';

const SITE_API_PREFIX = 'site-management';
const SITE_STATUS_API_PREFIX = 'site-status-management';

export const getSiteList = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`${SITE_API_PREFIX}/sites?organisationId=${organisationId}`);
  return result.data;
};

export const getSiteStatusList = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`${SITE_STATUS_API_PREFIX}/site-status?organisationId=${organisationId}`);
  return result.data;
};

export const getSiteConfigurationModalData = async (Id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`${SITE_API_PREFIX}/sites/${Id}?organisationId=${organisationId}`);
  return result.data;
};

export const getSiteItemData = async (Id: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`${SITE_API_PREFIX}/sites/${Id}?organisationId=${organisationId}`);
  return result.data;
};

export const deleteSiteData = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${SITE_API_PREFIX}/sites/${id}?organisationId=${organisationId}`);
};

export const editSiteData = async (item: SiteConfiguration, id: string) => {
  await setAuthorize();
  await api.put(`/${SITE_API_PREFIX}/sites/${id}`, item);
};

export const createSiteData = async (item: SiteConfiguration) => {
  await setAuthorize();
  await api.post(`/${SITE_API_PREFIX}/sites`, item);
};
