import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Link } from '@mui/material';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { PriceSignModel, PriceSignRecordModel, ProductItem } from '../../../models/priceSignModel';
import { ModuleName } from '../../../constants/module-constants';
import {
  DEFAULT_DROP_DOWN_VALUE,
  priceSignProtocolBaudRate,
  protocolTypes,
} from '../../../constants/dropdown-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { FormActionType } from '../../../constants/form-constants';
import { ButtonStyle } from '../../../constants/button-constants';
import { findSmallestNumberNotInArray } from '../../../utilities/general-helper';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import CustomTextField from '../../../components/text-field/text-field.container';
import Form from '../../../components/form/form.container';
import DropDown from '../../../components/dropdown/dropdown.container';
import Item from '../../../components/box-items/box-item';
import CustomButton from '../../../components/button/custom-button';
import CustomTooltip from '../../../components/tooltip/custom-tooltip';
import '../styles/price-signs.scss';

interface PriceSignPageProps {
  pageTitle?: string;
  action?: string;
  priceSignStatus: string;
  hasValidationError: boolean;
  backDropActionStatus: string;
  productsListName: KeyValuePair[];
  priceSignItem: PriceSignModel;
  priceSignContent: PriceSignRecordModel[];
  loadPriceSignInfo: (data: string) => void;
  loadPriceSigns: () => void;
  loadProductNameList: () => void;
  createPriceSign: (data: PriceSignModel) => void;
  editPriceSign: (data: PriceSignModel) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  setCustomValidatationMessage: (value: string) => void;
}

const PriceSign: React.FC<PriceSignPageProps> = (props: PriceSignPageProps) => {
  const {
    pageTitle,
    action,
    priceSignStatus,
    hasValidationError,
    backDropActionStatus,
    productsListName,
    priceSignItem,
    priceSignContent,
    loadPriceSignInfo,
    loadPriceSigns,
    loadProductNameList,
    createPriceSign,
    editPriceSign,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    setCustomValidatationMessage,
  } = props;

  const [priceSignValue, setPriceSignValue] = useState({} as PriceSignModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([] as string[]);
  const [existingPriceSignNumberList, setExistingPriceSignNumberList] = useState([] as number[]);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, siteId, priceSignId } = useParams();
  const isLoadPriceSignInfoRequested = useRef(false);
  const isLoadProductNameListRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.RECEIPT_TEMPLATE).hasUpdateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(priceSignStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(priceSignStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(priceSignStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, priceSignStatus]);

  useEffect(() => {
    if (priceSignItem?.id) {
      setPriceSignValue(priceSignItem);
    }
  }, [priceSignItem, action]);

  useEffect(() => {
    if (priceSignItem?.products.length > 0) {
      const maxIndex = priceSignItem.products.reduce((max, product) => Math.max(max, product.index), -1);
      let products: string[] = [];
      for (let i = 0; i <= maxIndex; i++) {
        const foundProduct = priceSignItem.products.find((it) => it.index === i);
        if (foundProduct && foundProduct.id) {
          products.push(foundProduct.id);
        } else {
          products.push('Select');
        }
      }
      setSelectedProducts(products);
    }
  }, [priceSignItem?.products]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (!isLoadProductNameListRequested.current) {
      loadProductNameList();
      isLoadProductNameListRequested.current = true;
    }

    if (
      !isLoadPriceSignInfoRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadPriceSignInfo(priceSignId ? priceSignId : '');
      setIsSaveButtonEnabled(hasUpdateAccess);
      isLoadPriceSignInfoRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      setPriceSignValue({
        number: findSmallestNumberNotInArray(existingPriceSignNumberList, 1, 10),
        siteId: siteId,
        organisationId: orgId,
        protocolBaudRate: '1200',
      } as PriceSignModel);
      setSelectedProducts(['Select']);
      setIsSaveButtonEnabled(hasCreateAccess);
      removeAllValidation();
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    priceSignId,
    siteId,
    orgId,
    existingPriceSignNumberList,
    isClickFromViewPage,
    loadPriceSignInfo,
    loadProductNameList,
    setIsPageDirty,
    setIsSaveButtonEnabled,
    removeAllValidation,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      showSiteHeader: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/sites/${siteId}/price-signs`);
    }
  }, [backDropActionStatus, orgId, siteId, navigate]);

  useEffect(() => {
    if (!priceSignContent) {
      loadPriceSigns();
      return;
    }

    if (
      priceSignContent?.length !== existingPriceSignNumberList?.length &&
      action === FormActionType.CREATE &&
      hasCreateAccess
    )
      setExistingPriceSignNumberList(
        priceSignContent.map((it) => {
          return it.number;
        })
      );
  }, [priceSignContent]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/sites/${siteId}/price-signs/${priceSignId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);
    if (!hasValidationError) {
      if (!priceSignValue?.products || priceSignValue?.products?.length <= 0) {
        setCustomValidatationMessage('Please add at least one product');
        return;
      }
      switch (action) {
        case FormActionType.EDIT:
          editPriceSign(priceSignValue);
          break;
        case FormActionType.CREATE:
          createPriceSign(priceSignValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setPriceSignValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onLineItemsChangeHandler = useCallback(
    (newvalue: KeyValuePair, index: number) => {
      let items = [...selectedProducts];
      if (!!items[index]) {
        let item = items[index];
        item = String(newvalue.value);
        items[index] = item;
      } else {
        items.push(String(newvalue.value));
      }
      setSelectedProducts(items);

      if (items.length > 0) {
        setCustomValidatationMessage('');
      }

      const newProducts = items.map((it, i) => {
        if (it === 'Select') {
          return {
            id: 'Select',
          } as ProductItem;
        } else
          return {
            id: it,
            index: i,
          } as ProductItem;
      });

      setPriceSignValue((prevstate) => {
        return {
          ...prevstate,
          products: newProducts.filter((it) => it.id !== 'Select'),
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled, selectedProducts]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const parsEnums = (value: string | number) => {
    if (value !== undefined) {
      return value.toString();
    } else {
      return DEFAULT_DROP_DOWN_VALUE;
    }
  };

  const avaliableLineItems = (value: string) => {
    let productList = productsListName.filter((item) => {
      return item.key === value;
    });
    return productList.concat(productsListName.filter((item) => !selectedProducts.includes(String(item.key))));
  };

  const getProductName = (productId: string): string => {
    var productName = productsListName?.find((p) => {
      return p.key === productId;
    })?.value;
    return !!productName ? productName.toString() : '';
  };

  const handleAddClick = () => {
    let newLineItems = selectedProducts.concat('Select');
    setSelectedProducts(newLineItems);
  };

  const handleRemoveClick = (value: string, i: number) => {
    if (selectedProducts?.length <= 1) return;
    selectedProducts.splice(i, 1);
    setSelectedProducts([...selectedProducts]);

    const newProducts = [...selectedProducts].map((it, i) => {
      if (it === 'Select') {
        return {
          id: 'Select',
        } as ProductItem;
      } else
        return {
          id: it,
          index: i,
        } as ProductItem;
    });

    setPriceSignValue((prevstate) => {
      return {
        ...prevstate,
        products: newProducts.filter((it) => it.id !== 'Select'),
      };
    });
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/sites/${siteId}/price-signs`}
        isClickFromViewPage={isClickFromViewPage}
      >
        <CustomTextField
          validateCounter={validateCounterFlag}
          isMandatory={true}
          key='number'
          label='Number'
          placeholder='Enter Number'
          onBindingValue={onTextChangeHandler}
          name='number'
          value={priceSignValue?.number}
          type='number'
          minRange={1}
          maxRange={5}
          readOnly={!isUpdatingForm}
        ></CustomTextField>

        <DropDown
          key='protocolType'
          name='protocolType'
          value={parsEnums(priceSignValue?.protocolType)}
          onBindingValue={onTextChangeHandler}
          label='Protocol Type'
          keyValuePair={protocolTypes}
          readOnly={!isUpdatingForm}
          validateCounter={validateCounterFlag}
          isMandatory={true}
        />

        <DropDown
          key='protocolBaudRate'
          name='protocolBaudRate'
          value={parsEnums(priceSignValue?.protocolBaudRate)}
          onBindingValue={onTextChangeHandler}
          label='Baud Rate'
          keyValuePair={priceSignProtocolBaudRate}
          readOnly={!isUpdatingForm}
        />

        <div
          className={`${
            !isUpdatingForm ? 'price-sign-form-products-header-readonly' : 'price-sign-form-products-header'
          }`}
        >
          Products
          {isUpdatingForm && <strong className='asterisk'> *</strong> && (
            <CustomTooltip title={'The order of products corresponds to position on price sign.'} />
          )}
        </div>

        {!!selectedProducts &&
          productsListName &&
          selectedProducts.map((item, i) => {
            return (
              <Item key={i}>
                <div className={!isUpdatingForm ? 'price-sign-form-readonly-items' : 'price-sign-form-items'}>
                  {!isUpdatingForm ? (
                    <CustomTextField
                      className='read-only-text-field-container'
                      key={`item${i}`}
                      placeholder={'Select Product'}
                      type='input'
                      name={`item${i}`}
                      value={getProductName(item)}
                      maxCharLength={30}
                      readOnly={!isUpdatingForm}
                    ></CustomTextField>
                  ) : (
                    <DropDown
                      key={`item${i}`}
                      name={`item${i}`}
                      value={parsEnums(selectedProducts[i])}
                      onBindingValue={(newvalue: KeyValuePair) => {
                        onLineItemsChangeHandler(newvalue, i);
                      }}
                      keyValuePair={avaliableLineItems(item)}
                      readOnly={!isUpdatingForm}
                    />
                  )}
                  {isUpdatingForm && (
                    <div className='remove-item-container'>
                      <CustomButton buttonStyle={ButtonStyle.DELETE} onClick={() => handleRemoveClick(item, i)}>
                        x
                      </CustomButton>
                    </div>
                  )}
                </div>
              </Item>
            );
          })}

        {isUpdatingForm && (!selectedProducts || selectedProducts.length < 10) && (
          <div className='add-item-container'>
            <Link className='add-product' underline='hover' onClick={handleAddClick}>
              + Add Product
            </Link>
          </div>
        )}
      </Form>
    </>
  );
};

export default PriceSign;
