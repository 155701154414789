import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import InputBase, { InputBaseComponentProps } from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTooltip from '../tooltip/custom-tooltip';
import { ValidationError } from '../../models/baseModels/validationModel';
import { TextFieldType } from '../../constants/textfield-constants';
import './text-field.scss';

interface TextFieldProps {
  validateCounter?: number;
  isMultiline?: boolean;
  maxRows?: string | number;
  autoFocus?: boolean;
  isMandatory?: boolean;
  minCharLength?: number;
  maxCharLength?: number;
  minRange?: number | string;
  maxRange?: number | string;
  label?: string;
  inlineLabel?: string;
  placeholder?: string;
  type: string;
  value: string | number;
  name: string;
  className?: string;
  inputRef?: React.Ref<any> | undefined;
  tooltipTitle?: string;
  tooltipIcon?: string;
  inputTooltip?: string;
  readOnly?: boolean;
  moreInfoTextLink?: string;
  moreInfoTitle?: string;
  moreInfoContext?: string;
  prefix?: string;
  compareValidation?: boolean;
  compareValidationMsg?: string;
  hideAsteriskSymbol?: boolean;
  isPasswordField?: boolean;
  isDisabledPasswordField?: boolean;
  fieldValidationStatus: (name: string) => ValidationError | undefined;
  onBindingValue?: (value: KeyValuePair) => void | undefined;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
  onFieldLostFocus?: () => void;
}

const TextFieldControl = (props: TextFieldProps) => {
  const {
    validateCounter,
    isMultiline,
    maxRows,
    autoFocus,
    isMandatory,
    minCharLength,
    maxCharLength,
    minRange,
    maxRange,
    label,
    inlineLabel,
    placeholder,
    type,
    value,
    name,
    className,
    inputRef,
    tooltipTitle,
    inputTooltip,
    readOnly,
    moreInfoTextLink,
    moreInfoTitle,
    moreInfoContext,
    prefix,
    compareValidation,
    compareValidationMsg,
    hideAsteriskSymbol,
    isPasswordField,
    isDisabledPasswordField,
    fieldValidationStatus,
    onBindingValue,
    setFieldValidation,
    removeValidation,
    setShowValidationMessage,
    onFieldLostFocus,
  } = props;

  const VALIDATION_MESSAGE_MANDATORY_FIELD = 'Required field';
  const VALIDATION_MESSAGE_MIN_VALUE = `Minimum length: ${minCharLength} characters`;
  const VALIDATION_MESSAGE_RANGE_VALUE = `Acceptable range: ${minRange} to ${maxRange}`;
  const VALIDATION_MESSAGE_EMAIL = 'Invalid email address';
  const VALIDATION_MESSAGE_LABEL_MSG = `Invalid ${label?.toLocaleLowerCase()} `;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const [validationList, setValidationList] = useState([] as string[]);

  const [showPassword, setShowPassword] = useState(false);

  const inputTextRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const handleClickShowPassword = () => {
    if (isDisabledPasswordField) return;
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const fieldValue = value !== undefined ? value : '';
  const setItem = useCallback((item: string) => {
    setValidationList((prv) => {
      if (!prv.includes(item)) {
        return [...prv, item];
      } else return [...prv];
    });
  }, []);

  useEffect(() => {
    if (isMandatory) {
      setItem('required');
    }
  }, [setItem, isMandatory]);

  useEffect(() => {
    if (!isNaN(Number(minCharLength)) && Number(minCharLength) > 0) {
      setItem('min');
    }
  }, [setItem, minCharLength]);

  useEffect(() => {
    if (!isNaN(Number(minRange)) && !isNaN(Number(maxRange))) {
      setItem('range');
    }
  }, [setItem, maxRange, minRange]);

  useEffect(() => {
    if (type === TextFieldType.EMAIL) {
      setItem('email');
    }
  }, [setItem, type]);

  useEffect(() => {
    if (type === TextFieldType.GUID) {
      setItem('guid');
    }
  }, [setItem, type]);

  useEffect(() => {
    if (type === TextFieldType.TEXTAREA) {
      setItem('textarea');
    }
  }, [setItem, type]);

  useEffect(() => {
    if (compareValidation) {
      setItem('compare');
    }
  }, [setItem, compareValidation]);

  const CheckMandatoryValue = useCallback(
    (value: string | number): boolean => {
      if (isMandatory && (value === undefined || value.toString() === '')) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MANDATORY_FIELD,
        });
        return true;
      } else {
        removeValidation(name);
      }

      return false;
    },
    [VALIDATION_MESSAGE_MANDATORY_FIELD, isMandatory, name, setFieldValidation, removeValidation]
  );

  const CheckLengthMinValue = useCallback(
    (value: string | number): boolean => {
      if (value && minCharLength && value.toString().length < minCharLength) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MIN_VALUE,
        });
        return true;
      } else {
        removeValidation(name);
      }
      return false;
    },
    [VALIDATION_MESSAGE_MIN_VALUE, minCharLength, name, removeValidation, setFieldValidation]
  );

  const CheckRangeValue = useCallback(
    (value: string | number): boolean => {
      if (type === TextFieldType.ALPHANUMERIC) {
        if (!!value && minRange && maxRange) {
          let checkMinRange: string = prefix ? String(minRange).replace(prefix, '') : String(minRange);
          let checkMaxRange: string = prefix ? String(maxRange).replace(prefix, '') : String(maxRange);

          const startValue = String(value)?.substring(0, checkMinRange.length);
          const valueInRange = startValue >= checkMinRange && startValue <= checkMaxRange;
          if (!valueInRange) {
            setFieldValidation({
              name,
              hasError: true,
              showErrorMessage: false,
              validationMessage: VALIDATION_MESSAGE_RANGE_VALUE,
            });
          }
          return true;
        }
        return false;
      } else if (!isNaN(Number(value))) {
        if (
          !isNaN(Number(minRange)) &&
          !isNaN(Number(maxRange)) &&
          (Number(value) > Number(maxRange) || Number(value) < Number(minRange)) &&
          !(value === undefined || value.toString() === '')
        ) {
          setFieldValidation({
            name,
            hasError: true,
            showErrorMessage: true,
            validationMessage: VALIDATION_MESSAGE_RANGE_VALUE,
          });
          // min range or max range might updated from other fields, so we have set show message to true here
          setIsShowMessage(true);
          return true;
        } else {
          removeValidation(name);
        }
      }

      return false;
    },
    [
      VALIDATION_MESSAGE_RANGE_VALUE,
      value,
      maxRange,
      minRange,
      prefix,
      name,
      type,
      setFieldValidation,
      removeValidation,
    ]
  );

  const CheckEmailValid = useCallback(
    (value: string | number): boolean => {
      const regxPattern: RegExp = /^\w+([.+-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})+$/;

      if (!!value) {
        if (regxPattern.test(value.toString()) && maxCharLength) {
          removeValidation(name);
          return true;
        }
        // for update the application user
        else if (value === 'Unspecified') {
          removeValidation(name);
          return true;
        }
      }

      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: VALIDATION_MESSAGE_EMAIL,
      });

      return true;
    },
    [setFieldValidation, removeValidation, name, VALIDATION_MESSAGE_EMAIL, maxCharLength]
  );

  const CheckGuidValid = useCallback(
    (value: string | number): boolean => {
      const regxPattern = /^[0-9a-f-]{36}$/i;
      if (!!value) {
        if (regxPattern.test(value.toString()) && maxCharLength) {
          removeValidation(name);
          return true;
        }
      }
      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: VALIDATION_MESSAGE_LABEL_MSG,
      });
      return true;
    },
    [setFieldValidation, removeValidation, name, VALIDATION_MESSAGE_LABEL_MSG, maxCharLength]
  );

  const CheckAlphanumericValid = useCallback(
    (value: string | number): boolean => {
      const regxPattern = /^[A-Za-z0-9]*$/;
      if (!!value) {
        if (compareValidation && compareValidationMsg) {
          setFieldValidation({
            name,
            hasError: compareValidation,
            showErrorMessage: compareValidation,
            validationMessage: compareValidationMsg,
          });
          return true;
        } else if (regxPattern.test(value.toString()) && maxCharLength) {
          removeValidation(name);
          return true;
        }
      }
      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: VALIDATION_MESSAGE_LABEL_MSG,
      });
      return true;
    },
    [
      setFieldValidation,
      removeValidation,
      name,
      VALIDATION_MESSAGE_LABEL_MSG,
      maxCharLength,
      compareValidation,
      compareValidationMsg,
    ]
  );

  const validateData = useCallback(
    (value: string | number) => {
      let validationResult: boolean = false;

      validationList?.some((item) => {
        switch (item) {
          case 'required':
            validationResult = CheckMandatoryValue(value);
            break;
          case 'range':
            validationResult = CheckRangeValue(value);
            break;
          case 'min':
            validationResult = CheckLengthMinValue(value);
            break;
          case 'email':
            validationResult = CheckEmailValid(value);
            break;
          case 'guid':
            validationResult = CheckGuidValid(value);
            break;
          case 'alphanumeric':
            validationResult = CheckAlphanumericValid(value);
            break;
          default:
            removeValidation(name);
            break;
        }
        return validationResult;
      });
    },
    [
      name,
      validationList,
      CheckMandatoryValue,
      CheckRangeValue,
      CheckLengthMinValue,
      removeValidation,
      CheckEmailValid,
      CheckGuidValid,
      CheckAlphanumericValid,
    ]
  );

  useEffect(() => {
    validateData(value !== null || value !== undefined ? value : '');
  }, [value, validateData]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      validateData(value !== null || value !== undefined ? value : '');
    }
  }, [value, validateData, validateCounter]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, name, setShowValidationMessage]);

  const checkForMinusSign = (value: string) => {
    if (type === TextFieldType.DECIMAL || type === TextFieldType.INTEGER) {
      if (value.lastIndexOf('-') > 0) {
        return true;
      }
    }
    return false;
  };
  const checkForDecimalPoint = (value: string) => {
    let splitsValue = value.split('.');
    return splitsValue.length > 2;
  };

  const cursorPositionRef = useRef<number | null>(null);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, selectionStart } = event.target;
    cursorPositionRef.current = selectionStart;

    if (checkForMinusSign(value)) {
      return;
    }

    if (type === TextFieldType.DECIMAL) {
      if (checkForDecimalPoint(value)) {
        return;
      }
      handleDecimalNumberInput(value, event.target.name);
    } else if (type === TextFieldType.INTEGER) {
      handleIntegerNumberInput(value, event.target.name);
    } else if (type === TextFieldType.INTEGER_PIN) {
      handleIntegerPinInput(event);
    } else if (type === TextFieldType.ALPHANUMERIC) {
      handleAlphanumericInput(value, event.target.name);
    } else {
      if (onBindingValue) {
        onBindingValue({
          key: event.target.name,
          value: value,
        });
      }
    }

    setIsShowMessage(false);
    setShowValidationMessage({ key: name, value: false });
  };

  useEffect(() => {
    if (inputTextRef.current && cursorPositionRef.current !== null) {
      inputTextRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }
  }, [value]);

  const handleDecimalNumberInput = (value: string, name: string) => {
    const regxPattern: RegExp = /^[+-]?\d*\.?\d+?$/;

    if (regxPattern.test(value) && maxCharLength) {
      let slicedValue = value.slice(0, maxCharLength);

      if (onBindingValue) {
        onBindingValue({
          key: name,
          value: slicedValue,
        });
      }
    } else {
      if (onBindingValue) {
        // work around for keeping the value as number (issue with ios devices)
        onBindingValue({
          key: name,
          value: value.replace(/[^\d.-]/g, ''),
        });
      }
    }
  };

  const handleIntegerNumberInput = (value: string, name: string) => {
    const regxPattern: RegExp = /^\d*$/;

    if (regxPattern.test(value) && maxCharLength) {
      let slicedValue = value.slice(0, maxCharLength);

      if (onBindingValue) {
        onBindingValue({
          key: name,
          value: slicedValue,
        });
      }
    } else {
      if (onBindingValue) {
        // work around for keeping the value as number (issue with ios devices)
        onBindingValue({
          key: name,
          value: value.replace(/[^\d]/g, ''),
        });
      }
    }
  };

  const handleIntegerPinInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name, selectionStart, selectionEnd } = event.target;
    const maskedValueregxPattern: RegExp = /^\d*$/;

    let newValue = value;
    if (!showPassword && value?.length > String(fieldValue)?.length) {
      newValue = String(fieldValue).replace(/\*/g, '') + value.replace(/\*/g, '');
    } else if (!showPassword && value?.length < String(fieldValue)?.length && selectionStart && selectionEnd) {
      newValue = String(fieldValue).slice(0, selectionStart) + String(fieldValue).slice(selectionEnd + 1);
    }

    if (maskedValueregxPattern.test(newValue) && maxCharLength) {
      let slicedValue = newValue.slice(0, maxCharLength);

      if (onBindingValue) {
        onBindingValue({
          key: name,
          value: slicedValue,
        });
      }
    } else {
      if (onBindingValue) {
        onBindingValue({
          key: name,
          value: newValue.replace(/[^\d]/g, ''),
        });
      }
    }
  };

  const handleAlphanumericInput = (value: string, name: string) => {
    if (onBindingValue) {
      onBindingValue({
        key: name,
        value: value.replace(/[^a-zA-Z0-9]/g, '').toLocaleUpperCase(),
      });
    }
  };

  const onWheelHandler = (event: React.WheelEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement> | any) => {
    event.target.blur();
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onFieldLostFocus) onFieldLostFocus();
    validateData(event.target.value);
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  const inputFieldFocusHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsShowMessage(false);
    setShowValidationMessage({ key: name, value: false });
  };

  const getInputBaseComponentProps = useCallback((): InputBaseComponentProps | undefined => {
    let matches;
    let count: number = 0;
    // For textarea input type, do not count '\n' (new line symbol) in max char length
    if (type === TextFieldType.TEXTAREA && maxCharLength) {
      matches = String(fieldValue).match(/(\s+)(?=\n)/g);
      count = matches ? matches.reduce((total, match) => total + match.length, 0) : 0;
    }

    const renderDisplayValue = () => {
      const displayValue = String(fieldValue)?.replace(/./g, '*');
      return displayValue || '';
    };

    return {
      value: isPasswordField && !showPassword ? renderDisplayValue() : fieldValue,
      style: type === TextFieldType.ALPHANUMERIC ? { textTransform: 'uppercase' } : undefined,
      maxLength:
        type === TextFieldType.TEXTAREA && maxCharLength
          ? maxCharLength + count
          : maxCharLength
            ? maxCharLength
            : undefined,
    };
  }, [type, maxCharLength, fieldValue, showPassword]);

  const handleTooltipClose = () => {};

  const handleTooltipOpen = () => {
    setTimeout(handleTooltipClose, 5000);
  };

  return (
    <React.Fragment>
      {
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip disableFocusListener title={inputTooltip ? inputTooltip : ''} arrow followCursor enterTouchDelay={0}>
            <div className={classNames('text-field-container', className)}>
              {!inlineLabel && (
                <div className={classNames('text-field-label', className)}>
                  {label}{' '}
                  {tooltipTitle && (
                    <CustomTooltip
                      title={tooltipTitle}
                      moreInfoTextLink={moreInfoTextLink}
                      moreInfoTitle={moreInfoTitle}
                      moreInfoContext={moreInfoContext}
                    />
                  )}
                  {isMandatory && !readOnly && !hideAsteriskSymbol && label && <strong className='asterisk'>*</strong>}
                </div>
              )}
              <div className={inlineLabel ? classNames('inline-text-field-containter') : ''}>
                {inlineLabel && <p className='inline-label'>{inlineLabel}</p>}

                <InputBase
                  className={
                    isShowMessage && fieldValidationStatus(name)?.hasError
                      ? classNames(
                          'text-field',
                          { 'inline-text-field': inlineLabel },
                          'text-field-validation-error',
                          className
                        )
                      : classNames('text-field', { 'inline-text-field': inlineLabel }, className)
                  }
                  autoFocus={autoFocus || false}
                  autoComplete='off'
                  error={fieldValidationStatus(name)?.hasError}
                  placeholder={readOnly && !value ? '' : placeholder}
                  value={fieldValue}
                  name={name}
                  onChange={onChangeValue}
                  inputRef={inputTextRef}
                  multiline={isMultiline}
                  maxRows={maxRows}
                  onWheel={onWheelHandler}
                  onBlur={onBlurHandler}
                  onFocus={inputFieldFocusHandler}
                  inputProps={getInputBaseComponentProps()}
                  disabled={readOnly}
                  startAdornment={
                    prefix && (
                      <InputAdornment position='start' sx={{ display: 'flex', alignItems: 'center' }}>
                        {prefix}
                      </InputAdornment>
                    )
                  }
                  endAdornment={
                    isPasswordField && (
                      <InputAdornment position='end'>
                        <Tooltip
                          title={isDisabledPasswordField ? 'The PIN is masked for security reasons.' : ''}
                          disableFocusListener
                          describeChild
                          followCursor
                        >
                          <IconButton
                            className='password-visibility-icon'
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }
                  onClick={handleTooltipOpen}
                />
              </div>
              {isShowMessage && fieldValidationStatus(name)?.hasError && (
                <div className='validation-text-message-error'>{fieldValidationStatus(name)?.validationMessage}</div>
              )}
            </div>
          </Tooltip>
        </ClickAwayListener>
      }
    </React.Fragment>
  );
};

export default TextFieldControl;
