import { SnackBarStateModel } from '../../models/baseModels/snackBarStateModel';

interface SnackBarState {
    data: SnackBarStateModel
}

export const initialState: SnackBarState = {
     data:{
         isOpen:false
     }
} as SnackBarState;