import React, { useCallback, useRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper, Popper } from '@mui/material';
import { ValidationError } from '../../models/baseModels/validationModel';
import classNames from 'classnames';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTextField from '../text-field/text-field.container';
import CustomTooltip from '../tooltip/custom-tooltip';
import './dropdown-with-search.scss';

interface MultiSelectDropdownProps {
  name: string;
  label: string;
  className?: string;
  placeholder?: string;
  dataList: KeyValuePair[];
  value?: string | number;
  maxHeight?: number;
  isMandatory?: boolean;
  validateCounter?: number;
  readOnly?: boolean;
  noOptionsText?: string;
  tooltipTitle?: string;
  hideAsteriskSymbol?: boolean;
  onBindingValue?: (newvalue: KeyValuePair) => void | undefined;
  fieldValidationStatus: (name: string) => ValidationError | undefined;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
}

const MultiSelectDropdown = (props: MultiSelectDropdownProps) => {
  const {
    name,
    label,
    className,
    placeholder,
    dataList,
    value,
    maxHeight,
    isMandatory,
    validateCounter,
    readOnly,
    noOptionsText,
    tooltipTitle,
    hideAsteriskSymbol,
    onBindingValue,
    setFieldValidation,
    removeValidation,
    fieldValidationStatus,
    setShowValidationMessage,
  } = props;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const popperAnchor = useRef({} as HTMLDivElement);
  const [selectedOptions, setSelectedOptions] = useState('' as string | number);

  const VALIDATION_MESSAGE_MANDATORY_FIELD = 'Required field';
  let inputRef: { focus: () => void };

  useEffect(() => {
    if (dataList && value) {
      setSelectedOptions(value);
    }
  }, []);

  const onChangeValue = (event: React.SyntheticEvent<Element, Event> | null, value: KeyValuePair) => {
    if (onBindingValue) {
      onBindingValue({
        key: name,
        value: value.key,
      });

      if (isMandatory) {
        CheckMandatoryValue(value.key);
      }
      setSelectedOptions(String(value.key));
    }
  };

  /**FOR VALIDATION */
  const CheckMandatoryValue = useCallback(
    (values: string | number) => {
      if (!values) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: VALIDATION_MESSAGE_MANDATORY_FIELD,
        });
      } else {
        removeValidation(name);
      }
    },
    [VALIDATION_MESSAGE_MANDATORY_FIELD, name, setFieldValidation, removeValidation]
  );

  const validateData = useCallback(
    (value: KeyValuePair) => {
      if (isMandatory) {
        CheckMandatoryValue(value.key);
      }
    },
    [CheckMandatoryValue, isMandatory]
  );

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, value, name, validateData, setShowValidationMessage]);

  useEffect(() => {
    if (isMandatory) {
      CheckMandatoryValue(selectedOptions);
    }
  }, [selectedOptions, isMandatory, CheckMandatoryValue]);

  const selectFocusHandler = (event: React.SyntheticEvent) => {
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  const selectBlurHandler = (event: React.SyntheticEvent) => {
    setIsShowMessage(true);
    setShowValidationMessage({ key: name, value: true });
  };

  const readOnlyValue: string = String(
    dataList?.filter((it) => it.key === value)?.length > 0
      ? dataList?.filter((it) => it.key === value)[0]?.value
      : value
  );

  return (
    <div className={classNames('dropdown-with-search-container')}>
      {readOnly ? (
        <CustomTextField name={name} value={readOnlyValue} type='string' label={label} isMultiline={true} readOnly />
      ) : (
        <>
          {
            <div className={classNames('dropdown-with-search-label', className)}>
              {label} {tooltipTitle && <CustomTooltip title={tooltipTitle} />}
              {isMandatory && !hideAsteriskSymbol && <strong className='asterisk'> *</strong>}
            </div>
          }
          <div className={classNames('dropdown-with-search')}>
            <div ref={popperAnchor}>
              <Autocomplete
                className={
                  isShowMessage && fieldValidationStatus(name)?.hasError
                    ? classNames('autocomplete', 'autocomplete-error')
                    : classNames('autocomplete')
                }
                disableClearable
                sx={{ maxHeight: maxHeight, overflow: 'auto' }}
                id='size-small-filled-multi'
                size='small'
                options={dataList || []}
                getOptionLabel={(data) => data?.value?.toString()}
                isOptionEqualToValue={(option, value) => option?.key === value?.key}
                value={{
                  key: selectedOptions,
                  value:
                    dataList?.filter((it) => it.key === selectedOptions)?.length > 0
                      ? dataList?.filter((it) => it.key === selectedOptions)[0]?.value
                      : selectedOptions,
                }}
                noOptionsText={noOptionsText}
                onChange={onChangeValue}
                onBlur={selectBlurHandler}
                onOpen={selectFocusHandler}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                PaperComponent={({ style, children, ...props }) => {
                  return (
                    <Popper {...props} open={isShowMessage} anchorEl={popperAnchor.current} placement='bottom'>
                      <Paper {...props} style={{ width: popperAnchor.current.clientWidth }}>
                        {children}
                      </Paper>
                    </Popper>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    className={classNames('dropdown-with-search-field', className)}
                    autoComplete='on'
                    placeholder={placeholder}
                    inputRef={(input) => {
                      inputRef = input;
                    }}
                    {...params}
                  />
                )}
                renderOption={(props, option: KeyValuePair, { selected }) => (
                  <li
                    {...props}
                    style={{
                      display: option?.additionalValue ? 'block' : 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {option?.additionalValue ? (
                      <div className='dropdown-item-with-addtional-value-container'>
                        <span className='dropdown-item-with-addtional-value'>
                          <span>{option?.value}</span>
                          <span className='dropndown-item-addtional-value'>{option?.additionalValue}</span>
                        </span>
                      </div>
                    ) : (
                      <>
                        <span>{option?.value}</span>
                      </>
                    )}
                  </li>
                )}
              />
              {isShowMessage && fieldValidationStatus(name)?.hasError && (
                <div className='validation-text-message-error'>{fieldValidationStatus(name)?.validationMessage}</div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
