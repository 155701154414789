import { takeLatest, put } from 'redux-saga/effects';
import { openModal, closeModal, setModalError } from './reducers';
import * as actions from './actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { clearAllFieldValidation } from '../fieldValidation/reducers';

export function* rootSaga() {
  yield takeLatest(actions.OPEN_MODAL, openingModal);
  yield takeLatest(actions.CLOSE_MODAL, closingModal);
}

export function* openingModal(action: PayloadAction<ModalStateModel>) {
  try {
    yield put(clearAllFieldValidation());
    yield put(openModal(action.payload));
  } catch {
    yield put(setModalError(true));
  }
}

export function* closingModal() {
  try {
    yield put(closeModal());
  } catch {
    yield put(setModalError(true));
  }
}
