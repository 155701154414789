import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import * as pageConfigActions from '../../store/page-configuration/actions';

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        dispatch({
          type: pageConfigActions.SET_IS_PAGE_DIRTY,
          payload: false,
        });
        push(...args);
      }
      if (result === false) {
        dispatch({
          type: pageConfigActions.SET_IS_CANCEL_FORM_CHANGE,
          payload: true,
        });
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when, dispatch]);
}

export function usePrompt(message: string, when = true) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
