import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PriceSignModel } from '../../../models/priceSignModel';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectReceiptTemplateStatus } from '../../../store/receipt-template/selectors';
import { selectProductListName } from '../../../store/product/selectors';
import {
  selectPriceSignContent,
  selectPriceSignDetailStatus,
  selectPriceSignItem,
} from '../../../store/price-signs/selectors';
import * as actions from '../../../store/price-signs/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as headerActions from '../../../store/header/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as productActions from '../../../store/product/actions';
import ReceiptTemplate from './price-sign';

const mapStateToProps = (state: RootState) => {
  return {
    priceSignStatus: selectPriceSignDetailStatus(state),
    priceSignItem: selectPriceSignItem(state),
    priceSignContent: selectPriceSignContent(state),
    receiptTemplateStatus: selectReceiptTemplateStatus(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    productsListName: selectProductListName(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadPriceSignInfo: (data: string) => dispatch({ type: actions.LOAD_PRICESIGNINFO, payload: data }),
    loadPriceSigns: () => dispatch({ type: actions.LOAD_PRICESIGNS }),
    loadProductNameList: () => dispatch({ type: productActions.LOAD_PRODUCT_NAME_LIST }),
    createPriceSign: (data: PriceSignModel) => dispatch({ type: actions.CREATE_PRICESIGN, payload: data }),
    editPriceSign: (data: PriceSignModel) => dispatch({ type: actions.EDIT_PRICESIGN, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    setCustomValidatationMessage: (value: string) =>
      dispatch({ type: fieldActions.SET_CUSTOM_VALIDATION_MSG, payload: value }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const PriceSignPage = connect(mapStateToProps, mapDispatchToProps)(ReceiptTemplate);

export default PriceSignPage;
