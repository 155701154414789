import React from 'react';
import classNames from 'classnames';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import Item from '../../components/box-items/box-item';
import BoxItem from '../../components/box-items/box-item';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import './styles/permission-group.scss';

interface PermissionGroupItemProps {
  id: string;
  name: string;
  desc: string;
  permissions: string;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  hasCreateAccess: boolean;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClone: (id: string) => void;
  onClick: (id: string) => void;
}

const PermissionGroupItem: React.FC<PermissionGroupItemProps> = (props: PermissionGroupItemProps) => {
  const {
    id,
    name,
    desc,
    permissions,
    hasDeleteAccess,
    hasUpdateAccess,
    hasCreateAccess,
    onDelete,
    onEdit,
    onClone,
    onClick,
  } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess || hasCreateAccess;

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit Permission Group',
      handler: () => {
        onEdit(id);
      },
    });
  }

  if (hasCreateAccess) {
    items.push({
      label: 'Clone Permission Group',
      handler: () => {
        onClone(id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete Permission Group',
      handler: () => {
        onDelete(id, name);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <>
      <Card className={classNames('box-grid')}>
        <Box className='group-card' onClick={onItemClick}>
          <BoxItem className='name'>{name}</BoxItem>
          <BoxItem className='desc'>{desc}</BoxItem>
          <BoxItem className='permissions'>{permissions}</BoxItem>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu key={id} buttonNode={<MoreVertIcon />} items={items} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </>
  );
};

export default PermissionGroupItem;
