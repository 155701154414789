import '@fontsource/mulish';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import reactPlugin from './utilities/AppInsights';
import AppRoutes from './config/appRoutes';
import './styles/general/global-theme-overrides.scss';
import './app.scss';

function App() {
  let routeElement = createBrowserRouter(AppRoutes);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <RouterProvider router={routeElement} />
    </AppInsightsContext.Provider>
  );
}

export default App;
