import { EventRequest } from '../../entities/event';
import api, { setAuthorize } from '../../utilities/api';
import { convertObjectToQueryString } from '../../utilities/querystring-helper';

const EVENT_API_PREFIX = 'event-management';

export const getEventsData = async (request: EventRequest, fuzzyRequest?: string) => {
  const qsRequest = convertObjectToQueryString(request);
  await setAuthorize();
  let result = await api.get(
    `/${EVENT_API_PREFIX}/events?${qsRequest}${
      fuzzyRequest && fuzzyRequest.trim()?.length > 0 ? `&message:contains=${encodeURIComponent(fuzzyRequest)}` : ''
    }`
  );
  return result.data;
};

export const getEventTypes = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${EVENT_API_PREFIX}/events/types?organisationId=${organisationId}`);
  return result.data;
};
