import SvgIcon from '@mui/material/SvgIcon';

import './icons.scss';

const UserManagementIcon = () => {
    return (
        <SvgIcon className="base-icon user-management-icon" />
    );
};

export default UserManagementIcon