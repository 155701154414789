import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';
import { PaginationModel } from '../../models/paginationModel';

export const selectPaginationState = (state: RootState) => state.PaginationState;

export const selectPaginationInfo = createSelector(selectPaginationState, (state) => {
  let paginationInfo = state.paginationInfo;
  if (paginationInfo === null || paginationInfo === undefined) {
    paginationInfo = { page: 0, limit: 10, isClickNext: false } as PaginationModel;
  }
  return paginationInfo;
});

export const selectContinuationToken = createSelector(selectPaginationState, (state) => {
  let continuation = state.continuationToken;
  if (continuation === null || continuation === undefined) {
    continuation = [];
  }
  return continuation;
});

export const selectIsReachEnd = createSelector(selectPaginationState, (state) => {
  let isReachEnd = state.isReachEnd;
  if (isReachEnd === null || isReachEnd === undefined) {
    isReachEnd = false;
  }
  return isReachEnd;
});
