import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { ALERT_RULE_MAX_TRIGGERS } from '../../../constants/alerts-constants';
import { TextFieldType } from '../../../constants/textfield-constants';
import { formatCamelCaseString } from '../../../utilities/fieldMapping-helper';
import { AlertRuleTriggerModel, AlertRuleTriggerOptionModel } from '../../../models/alertRuleModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import DropDown from '../../../components/dropdown/dropdown.container';
import CustomTextFieldWithDropdown from '../../../components/text-field-with-dropdown/text-field-with-dropdowncontainer';
import DropdownWithSearch from '../../../components/dropdown-with-search/dropdown-with-search.container';
import CustomTextField from '../../../components/text-field/text-field.container';

import '../styles/triggers.scss';

interface TriggersProps {
  validateCounter: number;
  triggers: AlertRuleTriggerModel[];
  alertRuleTriggerList: AlertRuleTriggerOptionModel[];
  readOnly?: boolean;
  onBindingValue?: (newvalue: AlertRuleTriggerModel[]) => void | undefined;
  removeValidation: (name: string) => void;
}

const TriggersComponent: React.FC<TriggersProps> = (props: TriggersProps) => {
  const { validateCounter, triggers, alertRuleTriggerList, readOnly, onBindingValue, removeValidation } = props;
  const [triggerValue, setTriggerValue] = useState([] as AlertRuleTriggerModel[]);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [propertyOptions, setPropertyOptions] = useState([] as KeyValuePair[]);

  useEffect(() => {
    if (alertRuleTriggerList && alertRuleTriggerList?.length > 0) {
      setPropertyOptions(
        alertRuleTriggerList.map((it) => {
          return { key: it.property, value: it.name };
        })
      );
    } else {
      setPropertyOptions([] as KeyValuePair[]);
    }
  }, [alertRuleTriggerList]);

  useEffect(() => {
    setValidateCounterFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setTriggerValue(triggers);
  }, [triggers, setTriggerValue]);

  // handle click event of the Add button
  const handleAddClick = () => {
    setValidateCounterFlag(0);
    let newItem = {} as AlertRuleTriggerModel;
    let list = [newItem];
    if (triggerValue !== undefined) {
      list = [...triggerValue];
      list.push(newItem);
    }
    setTriggerValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetHoseListValidation();
    let list = [...triggerValue];
    list.splice(index, 1);
    setTriggerValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetHoseListValidation = () => {
    triggerValue.forEach((_, index) => {
      removeValidation(`sites${index}`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let triggers = [...triggerValue];
    if (!!triggers[index]) {
      let item = { ...triggers[index] };
      if (newvalue.key.toString().includes('property')) {
        item.property = String(newvalue.value);
        if (
          alertRuleTriggerList?.find((it) => it?.property === String(newvalue.value))?.availableOperators?.length === 1
        ) {
          item.operator = alertRuleTriggerList?.find(
            (it) => it?.property === String(newvalue.value)
          )?.availableOperators[0];
          item.value = '';
        }
      } else if (newvalue.key.toString().includes('operator')) {
        item.operator = newvalue.value.toString();
      } else if (newvalue.key.toString().includes('value')) {
        item.value = newvalue.value.toString();
      }
      item.dataType = alertRuleTriggerList?.find((it) => it?.property === item?.property)?.dataType;
      triggers[index] = item;

      setTriggerValue(triggers);

      if (onBindingValue) {
        onBindingValue(triggers);
      }
    }
  };

  const onTextChangeHandler = (newvalue: KeyValuePair, index: number) => {
    let triggers = [...triggerValue];
    if (!!triggers[index]) {
      let item = { ...triggers[index] };
      if (newvalue.key.toString().includes('value') && newvalue.value !== '') {
        item.value = newvalue.value;
      } else if (newvalue.key.toString().includes('value')) {
        item.value = '';
      }
      triggers[index] = item;
      setTriggerValue(triggers);
      if (onBindingValue) {
        onBindingValue(triggers);
      }
    }
  };

  const handleOperatorToKeyValuePair = useCallback((operator: string[] | undefined) => {
    if (operator && operator?.length > 0) {
      return operator.map((it) => {
        return {
          key: it,
          value: formatCamelCaseString(it),
        };
      });
    } else {
      return [] as KeyValuePair[];
    }
  }, []);

  const handleAvaliableValueToKeyValuePair = useCallback((operator: string[] | undefined, property: string) => {
    if (operator && operator?.length > 0) {
      return operator.map((it) => {
        return {
          key: it,
          value: formatCamelCaseString(it),
        };
      });
    } else {
      return [] as KeyValuePair[];
    }
  }, []);

  return (
    <div className='triggers-container'>
      <div className='trigger-header'>Triggers {!readOnly && <strong className='asterisk'>*</strong>}</div>
      {(!triggerValue || triggerValue?.length < 1) && readOnly && (
        <div className='no-data-found-text'>No triggers found</div>
      )}
      {!!triggerValue &&
        triggerValue?.map((item, i) => {
          return (
            <>
              {!readOnly && triggerValue && triggerValue?.length > 1 && (
                <Link className='remove-trigger' underline='hover' onClick={() => handleRemoveClick(i)}>
                  - Remove
                </Link>
              )}
              <Grid item xs={12} sm={12} rowGap={2} className='trigger-item-container'>
                <Grid container alignItems='flex-start' spacing={1}>
                  <Grid item xs={12} md={12} lg={4}>
                    <DropdownWithSearch
                      validateCounter={validateCounterFlag}
                      key={`property${item?.property}`}
                      name={`property${i}`}
                      value={item?.property}
                      onBindingValue={(newvalue: KeyValuePair) => {
                        handleNewValue(newvalue, i);
                      }}
                      label='Property'
                      placeholder='Select'
                      isMandatory={true}
                      hideAsteriskSymbol={true}
                      dataList={propertyOptions}
                      readOnly={readOnly || alertRuleTriggerList?.length <= 0}
                      noOptionsText='No Avaliable Triggers'
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={3}>
                    <DropDown
                      validateCounter={validateCounterFlag}
                      key={`operator${item?.property}`}
                      name={`operator${i}`}
                      value={item?.operator}
                      onBindingValue={(newvalue: KeyValuePair) => {
                        handleNewValue(newvalue, i);
                      }}
                      label='Operator'
                      isMandatory={true}
                      hideAsteriskSymbol={true}
                      keyValuePair={handleOperatorToKeyValuePair(
                        alertRuleTriggerList?.find((it) => it.property === item.property)?.availableOperators
                      )}
                      readOnly={!item?.property || readOnly || alertRuleTriggerList?.length <= 0}
                    />
                  </Grid>
                  {
                    <Grid item xs={12} md={12} lg={5}>
                      {alertRuleTriggerList?.find((it) => it.property === item.property)?.dataType === 'list' ? (
                        <DropDown
                          validateCounter={validateCounterFlag}
                          key={`value${item?.property}`}
                          name={`value${i}`}
                          value={item?.value}
                          onBindingValue={(newvalue: KeyValuePair) => {
                            handleNewValue(newvalue, i);
                          }}
                          label='Value'
                          isMandatory={true}
                          hideAsteriskSymbol={true}
                          keyValuePair={handleAvaliableValueToKeyValuePair(
                            alertRuleTriggerList?.find((it) => it.property === item.property)?.availableValues,
                            item.property
                          )}
                          readOnly={!item?.property || readOnly || alertRuleTriggerList?.length <= 0}
                        />
                      ) : alertRuleTriggerList?.find((it) => it.property === item.property)?.dataType === 'number' &&
                        alertRuleTriggerList?.find((it) => it.property === item.property)?.valueUnitOfMeasure ? (
                        <CustomTextFieldWithDropdown
                          validateCounterFlag={validateCounterFlag}
                          customMdGrid={[6, 6]}
                          customXsGrid={[7, 5]}
                          fieldKey={`value${i}`}
                          filedName={`value${i}`}
                          onFiledChangeHandler={(newvalue: KeyValuePair) => {
                            onTextChangeHandler(newvalue, i);
                          }}
                          label='Value'
                          isMandatory={true}
                          hideAsteriskSymbol={true}
                          fieldPlaceHolder='Enter Value'
                          fieldValue={item?.value}
                          fieldType='integer'
                          fieldMaxCharLength={3}
                          fieldMinRange={0}
                          fieldMaxRange={100}
                          dropdownKey='unitOfMeasure'
                          dropdownName='unitOfMeasure'
                          dropdownValue={
                            alertRuleTriggerList?.find((it) => it.property === item.property)?.valueUnitOfMeasure
                          }
                          dropdownKeyValuePair={
                            [
                              {
                                key: alertRuleTriggerList?.find((it) => it.property === item.property)
                                  ?.valueUnitOfMeasure,
                                value: alertRuleTriggerList?.find((it) => it.property === item.property)
                                  ?.valueUnitOfMeasure,
                              },
                            ] as KeyValuePair[]
                          }
                          readOnly={
                            !(item?.property && item?.operator) || readOnly || alertRuleTriggerList?.length <= 0
                          }
                        />
                      ) : alertRuleTriggerList?.find((it) => it.property === item.property)?.dataType === 'number' &&
                        !alertRuleTriggerList?.find((it) => it.property === item.property)?.valueUnitOfMeasure ? (
                        <CustomTextField
                          validateCounter={validateCounterFlag}
                          key={`value${i}`}
                          name={`value${i}`}
                          onBindingValue={(newvalue: KeyValuePair) => {
                            onTextChangeHandler(newvalue, i);
                          }}
                          label='Value'
                          isMandatory={true}
                          hideAsteriskSymbol={true}
                          placeholder='Enter Value'
                          value={item?.value}
                          type={TextFieldType.DECIMAL}
                          maxCharLength={15}
                          readOnly={
                            !(item?.property && item?.operator) || readOnly || alertRuleTriggerList?.length <= 0
                          }
                        />
                      ) : (
                        <DropDown
                          validateCounter={validateCounterFlag}
                          key={`value${i}`}
                          name={`value${i}`}
                          value={item?.value}
                          onBindingValue={(newvalue: KeyValuePair) => {
                            handleNewValue(newvalue, i);
                          }}
                          label='Value'
                          isMandatory={true}
                          hideAsteriskSymbol={true}
                          keyValuePair={handleAvaliableValueToKeyValuePair(
                            alertRuleTriggerList?.find((it) => it.property === item.property)?.availableValues,
                            item.property
                          )}
                          readOnly={!item?.property || readOnly || alertRuleTriggerList?.length <= 0}
                        />
                      )}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </>
          );
        })}

      {!readOnly && (!triggerValue || triggerValue?.length < ALERT_RULE_MAX_TRIGGERS) && (
        <Link className='add-trigger' underline='hover' onClick={handleAddClick}>
          + Add Trigger
        </Link>
      )}
    </div>
  );
};

export default TriggersComponent;
