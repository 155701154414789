import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { DEFAULT_DROP_DOWN_VALUE, MeasuringCapability, tankGaugeType } from '../../../constants/dropdown-constants';
import { TankGauge } from '../../../models/tankModel';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import DropDown from '../../../components/dropdown/dropdown.container';
import Item from '../../../components/box-items/box-item';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import '../styles/gauges.scss';

interface TankGaugesProps {
  validateCounter: number;
  gauges: TankGauge[];
  readOnly?: boolean;
  onBindingValue?: (newvalue: TankGauge[]) => void | undefined;
  removeValidation: (name: string) => void;
}

const TankGauges: React.FC<TankGaugesProps> = (props: TankGaugesProps) => {
  const { gauges, validateCounter, readOnly, onBindingValue, removeValidation } = props;
  const [gaugesValue, setGaugesValue] = useState(gauges);
  const [validationFlag, setValidationFlag] = useState(0);
  const avaliableChannelList = [
    { key: 1, value: 1 },
    { key: 2, value: 2 },
  ];

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    setGaugesValue(gauges);
  }, [gauges, setGaugesValue]);

  // handle click event of the Add button
  const handleAddClick = () => {
    setValidationFlag(0);
    let gaugeItem = {
      measuringCapabilities: ['productVolume'],
      calibration: {
        currentUnitOfMeasure: 'milliAmpere',
        correspondingValueUnitOfMeasure: 'millimetre',
        points: [
          {
            current: '20',
          },
          {
            current: '4',
          },
        ],
      },
    } as TankGauge;
    let list = [gaugeItem];
    if (gaugesValue !== undefined) {
      list = [...gaugesValue];
      list.push(gaugeItem);
    }
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetGaugeListValidation();

    let list = [...gaugesValue];
    list.splice(index, 1);

    setGaugesValue(list);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const resetGaugeListValidation = () => {
    gaugesValue.forEach((_, index) => {
      removeValidation(`type${index}`);
      removeValidation(`channel${index}`);
      removeValidation(`measuringCapabilities${index}`);
      removeValidation(`distanceFromFullTank`);
      removeValidation(`distanceFromEmptyTank`);
    });
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let gaugeItems = [...gaugesValue];
    if (!!gaugeItems[index]) {
      let item = { ...gaugeItems[index] };

      if (newvalue.key.toString().includes('type')) {
        item.type = String(newvalue.value);
        if (newvalue.value === 'currentLoop') {
          const avaliableChannel = avaliableChannelList.filter((item) => {
            return !gaugesValue.some((it) => it.channel === item.key);
          });

          item.channel = avaliableChannel[0]?.value ? avaliableChannel[0]?.value : 1;
          item.calibration = {
            currentUnitOfMeasure: 'milliAmpere',
            correspondingValueUnitOfMeasure: 'millimetre',
            points: [
              {
                current: '20',
              },
              {
                current: '4',
              },
            ],
          };
        } else if (newvalue.value === 'veederRoot') {
          item.channel = undefined;
        }
      } else if (newvalue.key.toString().includes('channel')) {
        item.channel = Number(newvalue.value);
      }
      gaugeItems[index] = item;
    }
    setGaugesValue(gaugeItems);

    if (onBindingValue) {
      onBindingValue(gaugeItems);
    }
  };

  const avaliableChannels = (value: number) => {
    return avaliableChannelList.filter((item) => {
      return (
        item.key === value ||
        !gaugesValue
          .map((it) => {
            return it.channel;
          })
          .includes(item.key)
      );
    });
  };

  const handleNewCapabilitiesValue = (newvalue: KeyValuePair[], index: number) => {
    let gaugeItems = [...gaugesValue];
    if (!!gaugeItems[index]) {
      let item = { ...gaugeItems[index] };
      const newCapabilities: string[] = newvalue.map((it) => {
        return it.key as string;
      });
      item.measuringCapabilities = [...newCapabilities];
      gaugeItems[index] = item;
    }
    setGaugesValue(gaugeItems);

    if (onBindingValue) {
      onBindingValue(gaugeItems);
    }
  };

  const avaliableCapabilitis = (value: string[]) => {
    const selectedCapabilitis = gaugesValue
      ?.map((it) => {
        return it.measuringCapabilities;
      })
      .flat();

    const avaliableList = MeasuringCapability?.filter(
      (item) => !selectedCapabilitis?.includes(String(item.key)) || value?.includes(String(item.key))
    );

    return avaliableList && avaliableList?.length > 0 ? avaliableList : [];
  };
  return (
    <>
      <div className='gauges-header'> Gauges </div>
      {(!gaugesValue || gaugesValue?.length < 1) && readOnly && <div>No gauges found</div>}

      {!!gaugesValue &&
        gaugesValue?.map((item, i) => {
          return (
            <Item key={i} className='gauges-box'>
              {!readOnly && gaugesValue?.length >= 1 && (
                <Link className='remove-gauges' underline='hover' onClick={() => handleRemoveClick(i)}>
                  - Remove Gauge
                </Link>
              )}
              {
                <div className='gauge-form'>
                  <DropDown
                    key={`type${i}`}
                    name={`type${i}`}
                    value={!!item?.type ? item?.type : DEFAULT_DROP_DOWN_VALUE}
                    onBindingValue={(newvalue: KeyValuePair) => {
                      handleNewValue(newvalue, i);
                    }}
                    label={'Gauge Type'}
                    keyValuePair={tankGaugeType}
                    isMandatory={true}
                    validateCounter={validationFlag}
                    readOnly={readOnly}
                  />
                  {item?.type === 'currentLoop' && (
                    <DropDown
                      key={`channel${i}`}
                      name={`channel${i}`}
                      value={!!item?.channel ? item?.channel : DEFAULT_DROP_DOWN_VALUE}
                      onBindingValue={(newvalue: KeyValuePair) => {
                        handleNewValue(newvalue, i);
                      }}
                      label={'Channel'}
                      keyValuePair={item.channel ? avaliableChannels(item.channel) : undefined}
                      isMandatory={item?.type === 'currentLoop'}
                      validateCounter={validationFlag}
                      readOnly={readOnly}
                    />
                  )}
                  <MultiSelectDropdown
                    validateCounter={validationFlag}
                    dataList={avaliableCapabilitis(item?.measuringCapabilities)}
                    name={`measuringCapabilities${i}`}
                    label='Capabilities'
                    placeholder='Add Capabilities'
                    value={item?.measuringCapabilities?.map(
                      (item) =>
                        ({
                          key: item,
                          value: item,
                        }) as KeyValuePair
                    )}
                    onBindingValue={(newvalue: KeyValuePair[]) => {
                      handleNewCapabilitiesValue(newvalue, i);
                    }}
                    maxHeight={192}
                    isMandatory={true}
                    readOnly={readOnly}
                  ></MultiSelectDropdown>
                </div>
              }
            </Item>
          );
        })}
      {!readOnly && (!gaugesValue || gaugesValue?.length < 1) && (
        <Link className='add-gauges' underline='hover' onClick={handleAddClick}>
          + Add Gauge
        </Link>
      )}
    </>
  );
};

export default TankGauges;
