
import { initialState } from "./dialogInitState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../constants/loading-constants";

const dialogBoxSlice = createSlice({
    name: 'dialogBoxState',
    initialState,
    reducers: {
        setDialogBoxActionStatus: (state, action: PayloadAction<string>) => {
            state.actionState = action.payload;
        },      
        openDialogBox: (state) =>{
            state.isVisible = true;
            state.actionState = LoadingStatus.INIT;
        },
        closeDialogBox: (state) =>{
            state.isVisible = false;
        }
    }
});

export const {
    setDialogBoxActionStatus,
    openDialogBox,
    closeDialogBox
} = dialogBoxSlice.actions;

export default dialogBoxSlice.reducer;