import { Box, Grid } from '@mui/material';
import classNames from 'classnames';
import Item from '../../components/box-items/box-item';
import { FirmwareStateModel } from '../../models/terminalFirmwareModel';
import ProgressBar from '../../components/progress-bar/progress-bar';
import './styles/firmwareItem.scss';

interface PendingFirmwareProps {
  itemHeaderOnly?: boolean;
  item?: FirmwareStateModel;
}

const PendingFirmware: React.FC<PendingFirmwareProps> = (
  props: PendingFirmwareProps
) => {
  const { itemHeaderOnly, item } = props;
  const smHeaderDisplay = itemHeaderOnly ? 'flex': 'none';
  const xsHeaderDisplay =  itemHeaderOnly ?  'none': 'flex';

  return (
    <>
          <Grid
            container
            className={classNames( 'firmware-grid-container', { 'card-grid-container' :!itemHeaderOnly})}
            alignItems='flex-start'
            sx={{ display: { xs: xsHeaderDisplay, sm: 'flex' } }}
          >
            <Grid item xs={12} sm={3}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box sx={{ display: {  xs: xsHeaderDisplay, sm: smHeaderDisplay } }}>
                    <Item className={classNames('firmware-card-header')}>
                      Board
                    </Item>
                  </Box>
                  <Item className={classNames( {'display-none': itemHeaderOnly, 'firmware-card-value': !itemHeaderOnly})}>
                    {item?.boardName}
                  </Item>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box sx={{ display: {  xs: xsHeaderDisplay, sm: smHeaderDisplay} }}>
                    <Item className={classNames('firmware-card-header')}>
                      Version
                    </Item>
                  </Box>
                  <Item className={classNames( {'display-none': itemHeaderOnly, 'firmware-card-value': !itemHeaderOnly})}>
                    {item?.version}
                  </Item>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box sx={{ display: {  xs: xsHeaderDisplay, sm: smHeaderDisplay } }}>
                    <Item className={classNames('firmware-card-header')}>
                      State
                    </Item>
                  </Box>
                  <Item className={classNames( {'display-none': itemHeaderOnly, 'firmware-card-value': !itemHeaderOnly})}>
                    {item?.state}
                  </Item>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box sx={{ display: {  xs: xsHeaderDisplay, sm: smHeaderDisplay } }}>
                    <Item className={classNames('firmware-card-header')}>
                      Progress
                    </Item>
                  </Box>
                  <Item className={classNames( {'display-none': itemHeaderOnly, 'firmware-card-value': !itemHeaderOnly})}>
                    {item?.progress !== undefined &&  (
                      <ProgressBar progress={item?.progress} />                    
                    )}
                  </Item>
                </div>
              </Item>
            </Grid>
          </Grid>
    </>
  );
};

export default PendingFirmware;
