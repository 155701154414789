import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './siteInitState';
import { LoadingStatus } from '../../constants/loading-constants';
import { SiteConfigurationModel } from '../../models/siteModel';
import { SiteItemModel } from '../../models/siteModel';
import { SitesInfoModel } from '../../models/siteModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

const siteSlice = createSlice({
  name: 'siteState',
  initialState,
  reducers: {
    setSiteStatus: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.siteStatus = action.payload;
    },
    setSiteConfiguration: (state, action: PayloadAction<SiteConfigurationModel>) => {
      state.hasError = false;
      state.siteConfiguration = action.payload;
    },
    setSiteItem: (state, action: PayloadAction<SiteItemModel>) => {
      state.hasError = false;
      state.siteItem = action.payload;
    },
    setSiteContent: (state, action: PayloadAction<SiteItemModel[]>) => {
      state.siteContent = action.payload;
    },
    setSiteError: (state) => {
      state.hasError = true;
      state.siteContent = [];
      state.siteStatus = LoadingStatus.ERROR;
    },
    setSelectedSiteId: (state, action: PayloadAction<string>) => {
      state.hasError = false;
      state.selectedSiteId = action.payload;
    },
    setSiteNameList: (state, action: PayloadAction<KeyValuePair[]>) => {
      state.hasError = false;
      state.siteNameList = action.payload;
    },
    setSitesIdContent: (state, action: PayloadAction<SitesInfoModel[]>) => {
      state.hasError = false;
      state.sitesIdContent = action.payload;
    },
    resetSiteState: () => {
      return initialState;
    },
  },
});

export const {
  setSiteStatus,
  setSiteConfiguration,
  setSiteItem,
  setSiteContent,
  setSiteError,
  setSiteNameList,
  setSelectedSiteId,
  setSitesIdContent,
  resetSiteState,
} = siteSlice.actions;

export default siteSlice.reducer;
