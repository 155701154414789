import { Box, Grid } from '@mui/material';
import classNames from 'classnames';
import Card from '../../../components/cards/cards';
import Item from '../../../components/box-items/box-item';
import { FirmwareModel } from '../../../models/terminalFirmwareModel';
import '../styles/firmwareItem.scss';

interface FirmwareVersionProps {
  isSelected: boolean;
  item: FirmwareModel;
  updateFirmware: (data: FirmwareModel) => void;
}

const FirmwareVersion: React.FC<FirmwareVersionProps> = (props: FirmwareVersionProps) => {
  const { isSelected, item, updateFirmware } = props;

  const onItemClick = () => {
    if (updateFirmware) {
      updateFirmware(item);
    }
  };
  return (
    <>
      <Card className={classNames('box-card-parent', 'firmware-version-card', { 'selected-firmware': isSelected })}>
        <Box onClick={onItemClick} className={classNames('')}>
          <Grid container rowSpacing={{ xs: 2 }} className='firmware-grid-container' alignItems='center'>
            <Grid item xs={12} sm={3}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                    <Item className={classNames('firmware-card-header')}>Version</Item>
                  </Box>
                  <Item className={classNames('firmware-card-modal-value')}>{item?.version}</Item>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                    <Item className={classNames('firmware-card-header')}>Description</Item>
                  </Box>
                  <Item className={classNames('firmware-card-modal-value')}>{item?.description}</Item>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default FirmwareVersion;
