import React, { useEffect } from 'react';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

interface PageNotFoundProps {
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const PageNotFound: React.FC<PageNotFoundProps> = (
  props: PageNotFoundProps
) => {
  const { setHeaderConfiguration, setPageConfiguration, setIsPageDirty } =
    props;

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Page Not Found',
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
    } as HeaderStateModel);
  }, [setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);
  
  return (
    <React.Fragment>
      <div style={{ color: 'white', paddingTop: '10px' }}>
        Page Not Found ..........
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
