import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { Dispatch } from '@reduxjs/toolkit';
import { selectedIsCancelFromChange } from '../../store/page-configuration/selectors';
import * as fieldActions from '../../store/fieldValidation/actions';
import TabList from './tab-list';

const mapStateToProps = (state: RootState) => {
  return {
    cancelFromChange: selectedIsCancelFromChange(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const TabListomponent = connect(mapStateToProps, mapDispatchToProps)(TabList);

export default TabListomponent;
