import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { FirmwareInstallModel, FirmwareModel } from '../../../models/terminalFirmwareModel';
import EmptyList from '../../../components/empty-list/empty-list';
import MainModal from '../../../components/main-modal/modal.container';
import SearchBar from '../../../components/search-bar/search-bar';
import Item from '../../../components/box-items/box-item';
import FirmwareVersion from './fimware-version';
import '../styles/available-firmware.scss';

interface AvailableFirmwareProps {
  hasInstallAccess: boolean;
  hasDownloadAccess: boolean;
  modalData: ModalStateModel;
  availableFirmware: FirmwareModel[];
  selectedVersion: FirmwareModel;
  loadAvailableFirmware: (boardModel: string) => void;
  downloadFirmware: (firmwareName: string) => void;
  updateTerminalFirmware: (data: FirmwareInstallModel) => void;
  closeModal: () => void;
  removeAllValidation: () => void;
  handleInstallFirmware: (data: FirmwareInstallModel) => void;
  handleDowngradeWarningMsg: (data: string) => void;
}

const AvailableFirmwareModal: React.FC<AvailableFirmwareProps> = (props: AvailableFirmwareProps) => {
  const {
    hasInstallAccess,
    hasDownloadAccess,
    modalData,
    availableFirmware,
    selectedVersion,
    loadAvailableFirmware,
    downloadFirmware,
    updateTerminalFirmware,
    closeModal,
    removeAllValidation,
    handleInstallFirmware,
    handleDowngradeWarningMsg,
  } = props;

  const [modalTitle, setModalTitle] = useState('Available Firmware');

  const [filteredFirmware, setfilteredFirmware] = useState(availableFirmware);
  const [selectedFirmware, setSelectedFirmware] = useState(selectedVersion);
  const [currentFirmware, setCurrentFirmware] = useState(selectedVersion);

  const searchInputRef = useRef<HTMLInputElement>(null!);
  const { terminalId } = useParams();

  useEffect(() => {
    setCurrentFirmware(selectedVersion);
  }, [selectedVersion]);

  useEffect(() => {
    if (!!modalData?.dataId) {
      loadAvailableFirmware(modalData?.dataId);
    }

    removeAllValidation();
  }, [loadAvailableFirmware, removeAllValidation, modalData?.dataId]);

  useEffect(() => {
    if (!!modalData?.name) {
      setModalTitle(`Available Firmware for ${modalData?.name}`);
    }
    setSelectedFirmware({} as FirmwareModel);
  }, [modalData?.name]);

  useEffect(() => {
    setfilteredFirmware(availableFirmware);
  }, [availableFirmware]);

  const onClose = (event: any) => {
    setSelectedFirmware({} as FirmwareModel);
    closeModal();
  };

  const onUpdateHandler = useCallback((data: FirmwareModel) => {
    if (!!data) {
      setSelectedFirmware(data);
    }
  }, []);

  const compareVersions = (version1: string, version2: string) => {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1Part = v1Parts[i] || 0;
      const v2Part = v2Parts[i] || 0;

      if (v1Part > v2Part) return 1;
      if (v1Part < v2Part) return -1;
    }

    return 0;
  };

  const onInstallHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let installData: FirmwareInstallModel = {
      boardModel: selectedFirmware?.boardModel,
      boardName: selectedFirmware?.boardName,
      version: selectedFirmware?.version,
      terminalId: !!terminalId ? terminalId : '',
    };
    if (currentFirmware?.version) {
      const comparisonResult = compareVersions(currentFirmware?.version, selectedFirmware?.version);
      if (comparisonResult >= 0) {
        closeModal();
        if (handleDowngradeWarningMsg) {
          handleInstallFirmware(installData);
          handleDowngradeWarningMsg(
            `The current firmware version is ${currentFirmware?.version}, are you sure you want to downgrade to ${selectedFirmware?.version}?`
          );
        }
        return;
      }
    }
    updateTerminalFirmware(installData);
  };

  const onDownloadHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!!selectedFirmware && terminalId) {
      downloadFirmware(`${selectedFirmware?.boardModel}_${selectedFirmware?.version}.bin`);
    }
  };

  const searchHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let enteredSearchValue = searchInputRef.current?.value.trim();

    if (enteredSearchValue?.length > 0) {
      const filteredResult: FirmwareModel[] = availableFirmware.filter(
        (x) =>
          x?.version?.toLowerCase().includes(enteredSearchValue.toLowerCase()) ||
          x?.description?.toLowerCase().includes(enteredSearchValue.toLowerCase())
      );
      setfilteredFirmware(filteredResult);
    } else {
      setfilteredFirmware(availableFirmware);
    }
  };

  return (
    <>
      <MainModal
        viewOnlyModal={false}
        cancelButtonText='Download'
        saveButtonText='Install'
        closeButtonText='Close'
        modalContentClassName='modal-container-firmware-version'
        modalLoadingClassName='loading-container-my-account'
        onClose={onClose}
        modalTitle={modalTitle}
        hideFooter={false}
        onCancelButton={onDownloadHandler}
        cancelButtonDisabled={!hasDownloadAccess || !selectedFirmware?.version}
        hideCancelButton={!hasDownloadAccess}
        hideSaveButton={!hasInstallAccess}
        onSaveButton={onInstallHandler}
        saveButtonEnabled={hasInstallAccess && !!selectedFirmware?.version && filteredFirmware?.length > 0}
      >
        <div className='firmware-search-container'>
          <SearchBar placeholder={'Search'} onKeyUp={searchHandler} ref={searchInputRef} />
        </div>
        {!!filteredFirmware && filteredFirmware.length > 0 && (
          <div className='available-firmware-grid-header-container'>
            <Grid
              container
              rowSpacing={4}
              className='firmware-grid-container'
              alignItems='center'
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              <Grid item xs={12} sm={3}>
                <Item className={classNames('firmware-card-header')}>
                  <div>Version</div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Item className={classNames('firmware-card-header')}>
                  <div>Description</div>
                </Item>
              </Grid>
              {hasInstallAccess && (
                <Grid item xs={12} sm={2}>
                  <Item className={classNames('firmware-card-header')}>
                    <div>
                      <Item className={classNames('')}></Item>
                    </div>
                  </Item>
                </Grid>
              )}
            </Grid>
          </div>
        )}
        <div className='firmware-version-list-container'>
          {filteredFirmware &&
            filteredFirmware.map((item, i) => (
              <FirmwareVersion
                key={`number${i}`}
                item={item}
                updateFirmware={() => onUpdateHandler(item)}
                isSelected={item?.version === selectedFirmware?.version}
              />
            ))}
          {!!filteredFirmware && filteredFirmware.length < 1 && (
            <EmptyList message='No available firmware found'></EmptyList>
          )}
        </div>
      </MainModal>
    </>
  );
};

export default AvailableFirmwareModal;
