import { createSelector } from 'reselect';
import { RootState } from '../rootReducers';

export const selectLocalState = (state: RootState) => state.OrganisationState;

export const selectOrganisationContent = createSelector(
    selectLocalState,
    state => state.organisationContent
);

export const selectOrganisationStatus = createSelector(
    selectLocalState,
    state => state.organisationStatus
);

export const selectOrganisationItem = createSelector(
    selectLocalState,
    state => state.organisationItem
);
