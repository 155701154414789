import { AlertRuleEntity } from '../../entities/alert-rule';
import api, { setAuthorize } from '../../utilities/api';

const ALERT_API_PREFIX = 'alert-management';

export const getAlertRuleList = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${ALERT_API_PREFIX}/alerts/rules?organisationId=${organisationId}`);
  return result.data;
};

export const getAlertRuleInfo = async (userGroupId: string, organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${ALERT_API_PREFIX}/alerts/rules/${userGroupId}?organisationId=${organisationId}`);
  return result.data;
};

export const getAlertRuleTriggerList = async (organisationId: string) => {
  await setAuthorize();
  let result = await api.get(`/${ALERT_API_PREFIX}/alerts/triggers?organisationId=${organisationId}`);
  return result.data;
};

export const createAlertRule = async (data: AlertRuleEntity) => {
  await setAuthorize();
  await api.post(`/${ALERT_API_PREFIX}/alerts/rules`, data);
};

export const editAlertRule = async (data: AlertRuleEntity, id: string) => {
  await setAuthorize();
  await api.put(`/${ALERT_API_PREFIX}/alerts/rules/${id}`, data);
};

export const deleteAlertRule = async (id: string, organisationId: string) => {
  await setAuthorize();
  await api.delete(`/${ALERT_API_PREFIX}/alerts/rules/${id}?organisationId=${organisationId}`);
};
