import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectNavPaneStatus } from '../../store/nav-pane/selectors';
import Main from './main';
import { selectSnackBarStatus } from '../../store/snackbar/selectors';
import * as snackBarActions from '../../store/snackbar/actions';
import * as authActions from '../../store/auth/actions';
import * as pageActions from '../../store/page-configuration/actions';
import { SnackBarStateModel } from '../../models/baseModels/snackBarStateModel';
import { Dispatch } from '@reduxjs/toolkit';
import { selectHeaderConfiguration } from '../../store/header/selectors';
import { selectBackDropStatus } from '../../store/backdrop/selectors';
import { selectAuthStatus } from '../../store/auth/selectors';
import { selectIsPageDirty, selectPageConfiguration } from '../../store/page-configuration/selectors';
import { OrganisationAccountModel } from '../../models/baseModels/OrganisationAccountModel';

const mapStateToProps = (state: RootState) => {
  return {
    navPaneState: selectNavPaneStatus(state),
    snackBarState: selectSnackBarStatus(state),
    headerConfiguration: selectHeaderConfiguration(state),
    authStatus: selectAuthStatus(state),
    pageConfiguration: selectPageConfiguration(state),
    backDropState: selectBackDropStatus(state),
    isPageDirty: selectIsPageDirty(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSnackBarState: (data: SnackBarStateModel) =>
      dispatch({ type: snackBarActions.CHANGE_SNACKBAR_STATUS, payload: data }),
    setAuthAccount: (data?: OrganisationAccountModel) =>
      dispatch({ type: authActions.SET_LOGGEDIN_ACCOUNT, payload: data }),
    setIsPageReachBottom: (data: boolean) =>
      dispatch({ type: pageActions.SET_IS_PAGE_REACH_BOTTOM, payload: data }),
  };
};

const MainPage = connect(mapStateToProps, mapDispatchToProps)(Main);

export default MainPage;
