import queryString from 'query-string';

export const convertObjectToQueryString = (requestObject: any) => {
  // Handle specific case for cardTags
  if (Array.isArray(requestObject.cardTags)) {
    requestObject.cardTags = convertCardTagsToString(requestObject.cardTags);
  } else if (Array.isArray(requestObject.tags)) {
    requestObject.tags = convertCardTagsToString(requestObject.tags);
  }

  const qsRequest = queryString.stringify(filterNonNull(requestObject));
  return filterPercentSymbol(qsRequest);
};

const convertCardTagsToString = (cardTags: { key: string; value: string }[]): string => {
  return cardTags.map((tag) => `${encodeURIComponent(tag.key)}:${encodeURIComponent(tag.value)}`).join(',');
};

const filterPercentSymbol = (requestObject: any) => {
  return requestObject.replace(/%25/g, '%');
};

const filterNonNull = (requestObject: any) => {
  return Object.fromEntries(
    Object.entries(requestObject).filter(([k, v]) => {
      return v !== null && v !== undefined && v !== '';
    })
  );
};
