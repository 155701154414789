import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { CardFilterModel } from '../../models/cardModel';
import { RootState } from '../../store/rootReducers';
import { selectNavPaneStatus } from '../../store/nav-pane/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import { selectCardFilters, selectCardList, selectCardStatus } from '../../store/cards/selectors';
import { selectIsPageReachBottom } from '../../store/page-configuration/selectors';
import { selectSiteList } from './../../store/sites/selectors';
import { selectBinRangeList, selectBinRangeNameList } from '../../store/bin-ranges/selectors';
import * as headerActions from '../../store/header/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import * as paginationActions from '../../store/pagination/actions';
import * as cardActions from '../../store/cards/actions';
import * as productActions from '../../store/product/actions';
import * as modalActions from '../../store/modals/actions';
import Cards from './cards';

const mapStateToProps = (state: RootState) => {
  return {
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    cardList: selectCardList(state),
    cardStatus: selectCardStatus(state),
    isPageReachBottom: selectIsPageReachBottom(state),
    siteListName: selectSiteList(state),
    binRangeList: selectBinRangeList(state),
    binRangeListName: selectBinRangeNameList(state),
    cardFilterSetting: selectCardFilters(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initLoadCardList: (data?: CardFilterModel) => dispatch({ type: cardActions.INIT_LOAD_CARD_LIST, payload: data }),
    loadCardList: (data?: CardFilterModel) => dispatch({ type: cardActions.LOAD_CARD_LIST, payload: data }),
    loadProductNameList: () => dispatch({ type: productActions.LOAD_PRODUCT_NAME_LIST }),
    clearCardList: () => dispatch({ type: cardActions.CLEAR_CARD_LIST }),
    clearSelectedCard: () => dispatch({ type: cardActions.CLEAR_SELECTED_CARD }),
    deleteCard: (id: string) => dispatch({ type: cardActions.DELETE_CARD, payload: id }),
    setIsScrollRequest: (data: boolean) => dispatch({ type: cardActions.SET_IS_SCROLL_REQUEST, payload: data }),
    saveCardFuzzySearch: (data: string) => dispatch({ type: cardActions.SAVE_CARD_FUZZY_SEARCH, payload: data }),
    clearContinuationToken: () => dispatch({ type: paginationActions.CLEAR_CONTINUATION_TOKEN }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
  };
};

const CardList = connect(mapStateToProps, mapDispatchToProps)(Cards);

export default CardList;
