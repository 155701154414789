import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import MyAccountModal from './my-account-modal';
import { selectModalData } from '../../../store/modals/selectors';
import { selectHasValidationError } from './../../../store/fieldValidation/selectors';
import { RootState } from '../../../store/rootReducers';
import * as modalActions from '../../../store/modals/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as actions from '../../../store/users/actions';
import { selectUserInfo } from "../../../store/users/selectors";
import { UserModel } from '../../../models/usersModel';


const mapStateToProps = (state: RootState) => {
    return {
      userInfo: selectUserInfo(state),
      modalData: selectModalData(state),
      hasValidationError: selectHasValidationError(state),
    }
  };

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadUserInfo: (data?: string) => dispatch({ type: actions.LOAD_USER_INFO,payload: data }),
        closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL}),
        editMyAccount: (data: UserModel) =>dispatch({ type: actions.EDIT_MY_ACCOUNT, payload: data }),
        removeAllValidation: () => dispatch({type: fieldActions.REMOVE_ALL_FIELD_VALIDATION}),
    }    
}

const MyAccountModalContainer = connect(mapStateToProps, mapDispatchToProps)(MyAccountModal);

export default MyAccountModalContainer;