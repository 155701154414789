import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import NavPaneTooltipControl from './index';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectNavPaneStatus } from '../../../store/nav-pane/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
    navPaneState: selectNavPaneStatus(state),
  };
};

const NavPaneTooltip = connect(mapStateToProps)(NavPaneTooltipControl);

export default NavPaneTooltip;
