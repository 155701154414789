import { LoadingStatus } from '../../constants/loading-constants';
import { AlertUserGroupModel } from '../../models/alertUserGroupModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

interface AlertUserGroupState {
  alertUserGroupStatus: string;
  alertUserGroupList: AlertUserGroupModel[];
  alertUserGroupInfo: AlertUserGroupModel;
  alertUserGroupNameList: KeyValuePair[];
  hasError: boolean;
}

export const initialState: AlertUserGroupState = {
  alertUserGroupStatus: LoadingStatus.LOADING,
} as AlertUserGroupState;
