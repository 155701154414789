import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalStateModel } from '../../../models/baseModels/modalStateModel';
import { DialogModel } from '../../../models/baseModels/dialogModel';
import ConfirmDialogBox from '../../../components/confirm-dialog/confirm-dialog';
import MainModal from '../../../components/main-modal/modal.container';
import { UserModel } from '../../../models/usersModel';
import { ModalType } from '../../../constants/modal-constants';
import CustomTextField from '../../../components/text-field/text-field.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import { TextFieldType } from '../../../constants/textfield-constants';
import '../styles/modalStyle.scss';

interface MyAccountModalProps {
  userInfo: UserModel;
  modalData: ModalStateModel;
  hasValidationError: boolean;
  removeAllValidation: () => void;
  editMyAccount: (data: UserModel) => void;
  closeModal: () => void;
  loadUserInfo: (data?: string) => void;
}

const MyAccountModal: React.FC<MyAccountModalProps> = (
  props: MyAccountModalProps
) => {
  const {
    userInfo,
    modalData,
    hasValidationError,
    removeAllValidation,
    editMyAccount,
    closeModal,
    loadUserInfo,
  } = props;
  
  const [isTouched, setIsTouched] = useState(false);
  const [dialogState, setDialogStatus] = useState({
    isOpen: false,
  } as DialogModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [userInfoValue, setUserInfoValue] = useState(userInfo);
  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {    
    setIsTouched(false);
    if (!!modalData && modalData.type === ModalType.EDIT) {
      loadUserInfo(modalData.dataId);
    }
  }, [modalData, loadUserInfo]);

  useMemo(() => {
    if (!!userInfo) {
      setUserInfoValue(userInfo);
    }
  }, [userInfo]);

  const onTextChangeHandler = useCallback((newvalue: KeyValuePair) => {    
    setIsTouched(true);
    setUserInfoValue((prevstate) => {
      return {
        ...prevstate,
        [newvalue.key]: newvalue.value,
      };
    });
  }, []);

  useMemo(() => {
    switch (modalData.type) {
      case ModalType.EDIT:
        setModalTitle('Edit My Account');
        break;
      default:
        setModalTitle('');
        break;
    }
  }, [modalData.type]);

  const onSaveClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setValidateCounterFlag((prev) => ++prev);

    if (!hasValidationError) {
      switch (modalData.type?.toLowerCase()) {
        case 'edit':
          editMyAccount(userInfoValue);
          break;
      }

      setValidateCounterFlag(0);
    }
  };

  const onCancel = (event: any) => {
    setValidateCounterFlag(0);

    if (isTouched) {
      setDialogStatus({
        ...dialogState,
        isOpen: true,
        context: 'Are you sure you want to discard your changes?',
      });
    } else {
      removeAllValidation();
      setDialogStatus({
        ...dialogState,
        isOpen: false,
      });
      closeModal();
    }
  };

  const cancelDialog = () => {
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
  };

  const confirmDialog = () => {
    removeAllValidation();
    setDialogStatus({
      ...dialogState,
      isOpen: false,
    });
    closeModal();
  };

  return (
    <>
      <ConfirmDialogBox
        context={dialogState.context}
        open={dialogState.isOpen}
        closeTextButton='No'
        confirmTextButton='Yes'
        confirmDialog={confirmDialog}
        onClose={cancelDialog}
      />
      <MainModal
        viewOnlyModal={modalData.type === ModalType.VIEW}
        cancelButtonText='Cancel'
        saveButtonText='Save & Exit'
        closeButtonText='Close'
        modalContentClassName='form-container-my-account'
        modalLoadingClassName='loading-container-my-account'
        onClose={onCancel}
        modalTitle={modalTitle}
        onSaveButton={onSaveClickHandler}
        onCancelButton={onCancel}
      >
        <div>
          <CustomTextField
            key='firstName'
            label='First Name'
            placeholder='Enter First Name'
            type='input'
            onBindingValue={onTextChangeHandler}
            name='firstName'
            value={userInfoValue?.firstName}
            maxCharLength={50}
            minCharLength={1}
            isMandatory={true}
            validateCounter={validateCounterFlag}
          ></CustomTextField>
          <CustomTextField
            key='lastName'
            label='Last Name'
            placeholder='Enter Last Name'
            type='input'
            onBindingValue={onTextChangeHandler}
            name='lastName'
            value={userInfoValue?.lastName}
            maxCharLength={50}
            minCharLength={1}
            isMandatory={true}
            validateCounter={validateCounterFlag}
          ></CustomTextField>
          <CustomTextField
            key='email'
            label='Email'
            placeholder='Enter Email'
            onBindingValue={onTextChangeHandler}
            name='email'
            value={userInfoValue?.email}
            maxCharLength={320}
            type={TextFieldType.EMAIL}
            readOnly={true}
          ></CustomTextField>
        </div>
      </MainModal>
    </>
  );
};

export default MyAccountModal;
