import { LoadingStatus } from '../../constants/loading-constants';

interface BackDropState {    
    hasError: boolean
    actionStatus: string
    backDropStatus: boolean
}

export const initialState: BackDropState = {
    backDropStatus: false,
    actionStatus: LoadingStatus.LOADING,
    hasError: false
} as BackDropState;