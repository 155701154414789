import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import * as actions from '../../store/bin-ranges/actions';
import * as headerActions from '../../store/header/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { RootState } from '../../store/rootReducers';
import {
  selectBinRangeList,
  selectBinRangeStatus,
} from '../../store/bin-ranges/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import BinRanges from './bin-ranges';

const mapStateToProps = (state: RootState) => {
  return {
    binRangeList: selectBinRangeList(state),
    binRangeStatus: selectBinRangeStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadBinRangeList: () =>
      dispatch({
        type: actions.LOAD_BIN_RANGE_LIST,
      }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
      deleteBinRange: (id: string) =>
      dispatch({ type: actions.DELETE_BIN_RANGE, payload: id }),
  };
};

const BinRangeList = connect(mapStateToProps, mapDispatchToProps)(BinRanges);

export default BinRangeList;
