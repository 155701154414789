import { initialState } from './eventInitState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { EventModel } from '../../models/eventModel';

const eventSlice = createSlice({
  name: 'eventState',
  initialState,
  reducers: {
    setEventStatus: (state, action: PayloadAction<string>) => {
      state.eventStatus = action.payload;
      state.hasError = false;
    },
    setEventData: (state, action: PayloadAction<EventModel[]>) => {
      state.eventData = action.payload;
      state.hasError = false;
    },
    setEventTypes: (state, action: PayloadAction<string[]>) => {
      state.eventTypes = action.payload;
      state.hasError = false;
    },
    setEventFuzzySearch: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.eventFuzzySearch = action.payload;
    },
    setEventError: (state) => {
      state.hasError = true;
      state.eventData = [];
      state.eventStatus = LoadingStatus.ERROR;
    },
    resetEventState: () => {
      return initialState;
    },
  },
});

export const { setEventStatus, setEventData, setEventTypes, setEventFuzzySearch, setEventError, resetEventState } =
  eventSlice.actions;

export default eventSlice.reducer;
