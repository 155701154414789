import { takeLatest, put } from 'redux-saga/effects';
import { closeDialogBox, openDialogBox } from './reducers';
import * as actions from './actions';

export function* rootSaga() {
  yield takeLatest(actions.OPEN_DIALOGBOX, openingDialog);
  yield takeLatest(actions.CLOSE_DIALOGBOX, closingDialog);
}

export function* openingDialog() {
  yield put(openDialogBox());
}

export function* closingDialog(){
  yield put(closeDialogBox());
}