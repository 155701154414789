import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducers';
import { selectFieldValidation } from './../../store/fieldValidation/selectors';
import { ValidationError } from '../../models/baseModels/validationModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import * as fieldActions from '../../store/fieldValidation/actions';
import EditableTableControl from './editable-table';

const mapStateToProps = (state: RootState) => {
  return {
    fieldValidationStatus: (name: string) => selectFieldValidation(name)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setFieldValidation: (validation: ValidationError) =>
      dispatch({ type: fieldActions.SET_FIELD_VALIDATION, payload: validation }),
    setCustomValidatationMessage: (value: string) =>
      dispatch({ type: fieldActions.SET_CUSTOM_VALIDATION_MSG, payload: value }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    setShowValidationMessage: (value: KeyValuePair) =>
      dispatch({ type: fieldActions.SET_SHOW_FIELD_VALIDATION, payload: value }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const EditableTable = connect(mapStateToProps, mapDispatchToProps)(EditableTableControl);

export default EditableTable;
