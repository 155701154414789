import { TerminalModel } from '../../models/terminalModel';
import { TerminalOperationActionPostModel, TerminalOperationModel } from '../../models/terminalOperationModel';
import api, { setAuthorize } from '../../utilities/api';

const TERMINAL_OPERATION_API_PREFIX = 'terminal-operation-management';

export const getAllOperations = async (data: TerminalModel) => {
  await setAuthorize();
  const res = await api.get(
    `/${TERMINAL_OPERATION_API_PREFIX}/terminals/${data?.id}/operations?organisationId=${data?.organisationId}`
  );
  return res.data;
};

export const postOperationAction = async (terminalId: string, data: TerminalOperationActionPostModel) => {
  await setAuthorize();
  await api.post(`/${TERMINAL_OPERATION_API_PREFIX}/terminals/${terminalId}/operations`, data);
};

export const getTerminalDirectory = async (terminalId: string, data: TerminalOperationActionPostModel) => {
  await setAuthorize();
  const res = await api.post(`/${TERMINAL_OPERATION_API_PREFIX}/terminals/${terminalId}/operations`, data);
  return res.data;
};

export const cancelOperation = async (data: TerminalOperationModel) => {
  await setAuthorize();
  await api.put(
    `/${TERMINAL_OPERATION_API_PREFIX}/terminals/${data?.terminalId}/operations/${data?.id}/cancel?organisationId=${data?.organisationId}`
  );
};
