import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  List,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import Item from '../../components/box-items/box-item';
import CustomButton from '../../components/button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import { DoubleArrowDownIcon, DoubleArrowUpIcon } from '../../components/icons';
import { EventModel } from '../../models/eventModel';
import { checkIsTextEllipsis, getMeasureSign } from '../../utilities/general-helper';
import * as datetimehelper from '../../utilities/datetime-helper';
import * as generalHelper from '../../utilities/general-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/event-item.scss';
import { HealthStatusDisplayType } from '../../constants/status';

interface EventItemProps {
  item: EventModel;
}

const EventItem: React.FC<EventItemProps> = (props: EventItemProps) => {
  const { item } = props;
  const [isEventitemSiteEllipsis, setIsEventitemSiteEllipsis] = useState<boolean>(false);
  const [isEventItemTypeEllipsis, setIsEventItemTypeEllipsis] = useState<boolean>(false);
  const EventItemSiteText = useRef<HTMLDivElement | null>(null);
  const EventItemTypeText = useRef<HTMLDivElement | null>(null);

  const [showDetails, setShowDetails] = useState(false);

  const updateTooltipDisplay = () => {
    if (checkIsTextEllipsis(EventItemTypeText.current)) {
      setIsEventItemTypeEllipsis(true);
    } else {
      setIsEventItemTypeEllipsis(false);
    }

    if (checkIsTextEllipsis(EventItemSiteText.current)) {
      setIsEventitemSiteEllipsis(true);
    } else {
      setIsEventitemSiteEllipsis(false);
    }
  };

  useEffect(() => {
    updateTooltipDisplay();
    window.addEventListener('resize', updateTooltipDisplay);
    return () => window.removeEventListener('resize', updateTooltipDisplay);
  }, []);

  const showDetailHandler = () => {
    setShowDetails((prev) => !prev);
  };

  const timezone = datetimehelper.getBrowserLocalTimezone();

  // Pump Price Info
  const PumpPriceInfoTableSection = () => {
    return (
      <Table className='pump-info-table'>
        <TableHead>
          {
            <TableRow>
              <TableCell>Hose</TableCell>
              <TableCell>Tank</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total Quantity</TableCell>
              <TableCell>Total Amount</TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody>
          {item?.hoses?.map((hose) => {
            return (
              <TableRow key={hose.number}>
                <TableCell component='th' scope='row'>
                  {hose?.number}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {hose?.tank?.number}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {hose?.tank?.product?.name}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {generalHelper.numberHandlerWithDP(hose?.unitPrice, 3)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {generalHelper.numberHandlerWithDP(hose?.totals?.quantity, 2)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {generalHelper.numberHandlerWithDP(hose?.totals?.amount, 2)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  // Pump Snapshot
  const PumpSnapshotTableSection = () => {
    return (
      <Table className='pump-info-table'>
        <TableHead>
          {
            <TableRow>
              <TableCell>Hose</TableCell>
              <TableCell>Tank</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total Quantity</TableCell>
              <TableCell>Total Amount</TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody>
          {item?.hoses?.map((hose) => {
            return (
              <TableRow key={hose.number}>
                <TableCell component='th' scope='row'>
                  {hose?.number}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {hose?.tank?.number}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {hose?.tank?.product?.name}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {generalHelper.numberHandlerWithDP(hose?.unitPrice, 3)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {generalHelper.numberHandlerWithDP(hose?.totals?.quantity, 2)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {generalHelper.numberHandlerWithDP(hose?.totals?.amount, 2)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const ProductKeyValueSection = () => {
    return (
      <Card className='key-value-table' sx={{ minWidth: 275 }} variant='outlined'>
        <CardContent>
          {item?.product?.volume?.value && (
            <List className='key-value-list' key='value'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Product Volume
              </Typography>
              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {item?.product?.volume?.value} {getMeasureSign(item?.product?.volume?.unitOfMeasure)}
              </Typography>
            </List>
          )}
          {item?.product?.volume?.temperatureCompensatedValue && (
            <List className='key-value-list' key='tcValue'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Compensated Volume
              </Typography>

              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {item?.product?.volume?.temperatureCompensatedValue}{' '}
                {getMeasureSign(item?.product?.volume?.unitOfMeasure)}
              </Typography>
            </List>
          )}
          {item?.product?.volume?.value && item?.ullage?.value && (
            <List className='key-value-list' key='valuePercentage'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Fill Level
              </Typography>
              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {((item?.product?.volume?.value * 100) / (item?.product?.volume?.value + item?.ullage?.value)).toFixed(
                  2
                )}{' '}
                %
              </Typography>
            </List>
          )}
          {item?.product?.height?.value && (
            <List className='key-value-list' key='height'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Height
              </Typography>

              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {item?.product?.height?.value} {getMeasureSign(item?.product?.height?.unitOfMeasure)}
              </Typography>
            </List>
          )}
          {item?.temperature?.value && (
            <List className='key-value-list' key='height'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Temperature
              </Typography>
              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {item?.temperature?.value} {getMeasureSign(item?.temperature?.unitOfMeasure)}
              </Typography>
            </List>
          )}
        </CardContent>
      </Card>
    );
  };

  const WaterKeyValueSection = () => {
    return (
      <Card className='key-value-table' sx={{ minWidth: 275 }} variant='outlined'>
        <CardContent>
          {item?.water?.volume?.value && (
            <List className='key-value-list' key='waterValue'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Water Volume
              </Typography>
              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {item?.water?.volume?.value} {getMeasureSign(item?.water?.volume?.unitOfMeasure)}
              </Typography>
            </List>
          )}
          {item?.water?.height?.value && (
            <List className='key-value-list' key='height'>
              <Typography align='left' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                Height
              </Typography>
              <Typography align='right' sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                {item?.water?.height?.value} {getMeasureSign(item?.water?.height?.unitOfMeasure)}
              </Typography>
            </List>
          )}
        </CardContent>
      </Card>
    );
  };

  // Tank Snapshot
  const TankSnapshotTableSection = () => {
    return (
      <Grid container spacing={4} className='tank-info-table'>
        <Grid item xs={11} sm={12}>
          {(item?.product?.volume?.value ||
            item?.product?.volume?.temperatureCompensatedValue ||
            item?.product?.volume?.value ||
            item?.ullage?.value ||
            item?.product?.height?.value) && (
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
              <ProductKeyValueSection />
            </Grid>
          )}
          {(item?.water?.volume?.value || item?.water?.height?.value) && (
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
              <WaterKeyValueSection />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  // PriceSign Price
  const PriceSignProductTableSection = () => {
    return (
      <Table className='price-sign-info-table'>
        <TableHead>
          {
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Unit Price</TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody>
          {item?.products
            ?.slice()
            ?.sort((a, b) => a.index - b.index)
            ?.map((hose) => {
              return (
                <TableRow key={hose.index}>
                  <TableCell component='th' scope='row'>
                    {hose?.name}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {hose?.unitPrice ? generalHelper.numberHandlerWithDP(hose?.unitPrice, 3) : 'Unknown'}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  };

  // Terminal Windcave Status
  const TerminalWindcaveStatusSection = () => {
    return (
      <Table className='windcave-status-table'>
        <TableHead>
          {
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>Firmware Version</TableCell>
              <TableCell>Card Reader</TableCell>
              <TableCell>Keypad</TableCell>
              <TableCell>Server Connection</TableCell>
              <TableCell>Contactless Reader</TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody>
          <TableRow key={item?.windcave?.cardReader?.id}>
            <TableCell component='th' scope='row'>
              {item?.windcave?.cardReader?.id}
            </TableCell>
            <TableCell component='th' scope='row'>
              {item?.windcave?.cardReader?.firmware}
            </TableCell>
            <TableCell component='th' scope='row'>
              {fieldMappingHelper.getDisplayValue(item?.windcave?.cardReader?.status, HealthStatusDisplayType)}
            </TableCell>
            <TableCell component='th' scope='row'>
              {fieldMappingHelper.getDisplayValue(item?.windcave?.keypad?.status, HealthStatusDisplayType)}
            </TableCell>
            <TableCell component='th' scope='row'>
              {fieldMappingHelper.getDisplayValue(item?.windcave?.uplink?.status, HealthStatusDisplayType)}
            </TableCell>
            <TableCell component='th' scope='row'>
              {fieldMappingHelper.getDisplayValue(item?.windcave?.contactlessReader?.status, HealthStatusDisplayType)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <Grid
        container
        className={classNames('event-grid-container', item?.expendDetails ? 'event-grid-with-more-info-container' : '')}
        alignItems='center'
        onClick={showDetailHandler}
      >
        <Grid item xs={11} sm={11} rowGap={2}>
          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={3} md={2}>
              <Item className={classNames('event-grid-item-container')}>
                <Box>
                  <Item className={classNames('event-card-field')}>
                    {datetimehelper.formatDateTimebyTimeZone(timezone, item?.dateTimeUtc, 'DD/MM/YYYY hh:mm:ss A')}
                  </Item>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <Item className={classNames('event-grid-item-container, event-card-field')}>
                <Tooltip
                  title={isEventitemSiteEllipsis ? item?.siteName : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <div ref={EventItemSiteText} className='event-item-site'>
                    {item?.siteName}
                  </div>
                </Tooltip>
              </Item>
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Item className={classNames('event-grid-item-container, event-card-field')}>
                <Tooltip
                  title={isEventItemTypeEllipsis ? item?.displayType : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <div ref={EventItemTypeText} className='event-item-type'>
                    {item?.displayType}
                  </div>
                </Tooltip>
              </Item>
            </Grid>

            <Grid item xs={12} sm={3} md={4}>
              <Item className={classNames('event-grid-item-container')}>
                <Box>
                  <Item className={classNames('event-card-field')}>{item?.details}</Item>
                </Box>
              </Item>
            </Grid>
          </Grid>

          {item?.expendDetails && (
            <Collapse in={showDetails}>
              <Grid container alignItems='flex-start' className={classNames('event-card-more-details')}>
                <Grid item xs={12} sm={12}>
                  <Item className={classNames('event-grid-item-container')}>
                    {item?.type === 'pumpPrice' && <PumpPriceInfoTableSection />}
                    {item?.type === 'pumpSnapshot' && <PumpSnapshotTableSection />}
                    {item?.type === 'tankSnapshot' && <TankSnapshotTableSection />}
                    {item?.type === 'priceSignPrice' && <PriceSignProductTableSection />}
                    {item?.type === 'terminalWindcaveStatus' && <TerminalWindcaveStatusSection />}
                  </Item>
                </Grid>
              </Grid>
            </Collapse>
          )}
        </Grid>

        {item?.expendDetails && (
          <Grid item xs={1} sm={1} alignItems='flex-start'>
            {
              <Item className={classNames('event-grid-item-container')}>
                <div>
                  <Item className={classNames('event-grid-button-container')}>
                    {
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className={classNames('button-icon-color')} />
                          ) : (
                            <DoubleArrowDownIcon className={classNames('button-icon-color')} />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    }
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        )}
      </Grid>

      <Grid
        container
        className={classNames(
          'event-grid-mobile-container',
          item?.expendDetails ? 'event-grid-with-more-info-container' : ''
        )}
        alignItems='center'
      >
        <Grid item xs={11} sm={11} rowGap={2}>
          <Grid container alignItems='flex-start'>
            {item?.dateTimeUtc && (
              <Grid item xs={12}>
                <Item className={classNames('event-grid-item-container')}>
                  <Box>
                    <Item className={classNames('event-card-field-header')}>Datetime</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('event-mobile-card-field-value')}>
                      {datetimehelper.formatDateTimebyTimeZone(timezone, item?.dateTimeUtc, 'DD/MM/YYYY hh:mm:ss A')}
                    </Item>
                  </Box>
                </Item>
              </Grid>
            )}
            {item?.siteName && (
              <Grid item xs={12}>
                <Item className={classNames('event-grid-item-container')}>
                  <Box>
                    <Item className={classNames('event-card-field-header')}>Site</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('event-mobile-card-field-value')}>{item?.siteName}</Item>
                  </Box>
                </Item>
              </Grid>
            )}
            {item?.displayType && (
              <Grid item xs={12}>
                <Item className={classNames('event-grid-item-container')}>
                  <Box>
                    <Item className={classNames('event-card-field-header')}>Type</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('event-mobile-card-field-value')}>{item?.displayType}</Item>
                  </Box>
                </Item>
              </Grid>
            )}
            {item?.details && (
              <Grid item xs={12}>
                <Item className={classNames('event-grid-item-container')}>
                  <Box>
                    <Item className={classNames('event-card-field-header')}>Details</Item>
                  </Box>
                  <Box>
                    <Item className={classNames('event-mobile-card-field-value')}>{item?.details}</Item>
                  </Box>
                </Item>
              </Grid>
            )}
          </Grid>

          {item?.expendDetails && (
            <Collapse in={showDetails}>
              <Grid container alignItems='flex-start' className={classNames('event-card-more-details')}>
                <Grid item xs={12} sm={12}>
                  <Item className={classNames('event-grid-item-container')}>
                    {item?.type === 'pumpPriceChanged' && <PumpPriceInfoTableSection />}
                    {item?.type === 'pumpSnapshot' && <PumpSnapshotTableSection />}
                    {item?.type === 'tankSnapshot' && <TankSnapshotTableSection />}
                    {item?.type === 'priceSignPrice' && <PriceSignProductTableSection />}
                  </Item>
                </Grid>
              </Grid>
            </Collapse>
          )}
        </Grid>

        {item?.expendDetails && (
          <Grid item xs={1} sm={1} alignItems='flex-start'>
            {
              <Item className={classNames('event-grid-item-container')}>
                <div>
                  <Item className={classNames('event-grid-button-container')}>
                    {
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className={classNames('button-icon-color')} />
                          ) : (
                            <DoubleArrowDownIcon className={classNames('button-icon-color')} />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    }
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EventItem;
