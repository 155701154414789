import { connect } from 'react-redux';
import PrivacyPage from './privacy-page';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { Dispatch } from '@reduxjs/toolkit';
import * as headerActions from '../../store/header/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
      setHeaderConfiguration: (data: HeaderStateModel) =>
        dispatch({
          type: headerActions.CHANGE_HEADER_CONFIGURATION,
          payload: data,
        }),        
        setPageConfiguration: (data: PageSettingStateModel) =>
        dispatch({
          type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
          payload: data,
        }),
        setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    };
  };
  
  const PrivacyPageControl = connect(
    null,
    mapDispatchToProps
  )(PrivacyPage);

export default PrivacyPageControl;
