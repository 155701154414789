import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateValidationError } from '@mui/x-date-pickers';
import { Link } from '@mui/material';
import { Messages } from '../../constants/messages';
import { ValidationError } from '../../models/baseModels/validationModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTooltip from '../tooltip/custom-tooltip';
import * as DateTimeMessage from '../../constants/dateTimePicker-constants';
import './datepicker.scss';

interface DatePickerProps {
  label?: string;
  dateFormat: string;
  dateValue: string | null;
  disableFuture: boolean;
  name: string;
  validateCounter?: number;
  isMandatory?: boolean;
  readOnly?: boolean;
  minDate?: Date;
  monthAndYearPicker?: boolean;
  tooltipTitle?: string;
  handleDateChange: (newValue: Moment | null) => void;
  invalidDateMessage?: string;
  disabledPassedDates?: Date;
  disabledFutureDates?: Date;
  fieldValidationStatus: (name: string) => ValidationError;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
  setToCurrentBrowserTime?: () => void;
}

const DatePickerControl = (props: DatePickerProps) => {
  const {
    label,
    dateFormat,
    dateValue,
    disableFuture,
    name,
    validateCounter,
    isMandatory,
    readOnly,
    minDate,
    monthAndYearPicker,
    tooltipTitle,
    invalidDateMessage,
    disabledPassedDates,
    disabledFutureDates,
    handleDateChange,
    fieldValidationStatus,
    setFieldValidation,
    removeValidation,
    setShowValidationMessage,
    setToCurrentBrowserTime,
  } = props;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const [value, setValue] = useState<Moment | null>(moment(dateValue));

  useEffect(() => {
    if (!!dateValue && invalidDateMessage) {
      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: invalidDateMessage,
      });
      setValue(moment(dateValue));
      if (dateValue !== DateTimeMessage.INVALID_DATE_MESSAGE_VALUE) {
      }
    } else if (!!dateValue && !invalidDateMessage) {
      setValue(moment(dateValue));
      if (dateValue !== DateTimeMessage.INVALID_DATE_MESSAGE_VALUE) {
        removeValidation(name);
      }
    } else {
      if (isMandatory) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: Messages.REQUIRED_FIELD,
        });
      } else {
        setValue(null);
      }
    }
  }, [invalidDateMessage, isMandatory, dateValue, name, removeValidation, setFieldValidation]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, setShowValidationMessage, name]);

  useEffect(() => {
    if (setToCurrentBrowserTime) {
      setIsShowMessage(true);
    }
  }, [setToCurrentBrowserTime]);

  const handleChange = (newValue: Moment | null) => {
    setValue(newValue);
    handleDateChange(newValue);
  };

  const onDateErrorHandler = (reason: DateValidationError) => {
    if (!value) {
      removeValidation(name);
      return;
    }
    switch (reason) {
      case DateTimeMessage.INVALID_DATE_REASON_VALUE: {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: DateTimeMessage.INVALID_DATE_MESSAGE_VALUE,
        });
        setShowValidationMessage({ key: name, value: true });
        break;
      }
      case DateTimeMessage.DISABLE_FUTURE_REASON_VALUE: {
        removeValidation(name);
        break;
      }
      case DateTimeMessage.DISABLE_DATE_VALUE: {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: true,
          validationMessage: DateTimeMessage.ACTIVATION_TIME_TOO_OLD,
        });
        break;
      }
      case null: {
        if (!fieldValidationStatus(name)?.hasError) removeValidation(name);
        break;
      }
    }
  };

  const onDateAcceptHandler = (value: Moment | null) => {
    if (!isMandatory) {
      removeValidation(name);
    }
    setIsShowMessage(false);
    setShowValidationMessage({ key: name, value: false });
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsShowMessage(true);
  };

  const onChangeHandler = (event: any) => {
    if (event) {
      setValue(event);
      handleDateChange(event);
    }
    if (isMandatory && event === '') {
      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: Messages.REQUIRED_FIELD,
      });
    }
    if (event === '') {
      handleChange(null);
    }
  };

  const setToCurrentTimeHandler = () => {
    if (setToCurrentBrowserTime) setToCurrentBrowserTime();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={classNames('datepicker', monthAndYearPicker ? 'month-and-year-date-picker' : '')}>
        {label && (
          <div className='datepicker-label'>
            {label} {tooltipTitle && <CustomTooltip title={tooltipTitle} />}{' '}
            {isMandatory && <strong className='asterisk'>*</strong>}
          </div>
        )}
        <div
          className={
            fieldValidationStatus(name)?.hasError && isShowMessage
              ? classNames('datepicker-container', 'datepicker-container-error')
              : readOnly
                ? classNames('datepicker-container', 'datepicker-container-read-only')
                : classNames('datepicker-container')
          }
        >
          <div className='desktop-container'>
            <DesktopDatePicker
              views={monthAndYearPicker ? ['month', 'year'] : ['year', 'month', 'day']}
              format={dateFormat}
              value={value}
              label={!readOnly && 'Select date'}
              onChange={handleChange}
              onAccept={onDateAcceptHandler}
              disableFuture={disableFuture}
              className='datePickerClass'
              orientation='portrait'
              onError={onDateErrorHandler}
              disabled={readOnly}
              minDate={minDate ? moment(minDate) : undefined}
              slotProps={{
                toolbar: { hidden: false },
                textField: { onBlur: onBlurHandler, onChange: onChangeHandler },
              }}
              shouldDisableDate={
                disabledPassedDates
                  ? (date) => date < moment(disabledPassedDates)
                  : disabledFutureDates
                    ? (date) => date > moment(disabledFutureDates)
                    : undefined
              }
            />
          </div>
          <div className='mobile-container'>
            <MobileDatePicker
              views={monthAndYearPicker ? ['month', 'year'] : ['year', 'month', 'day']}
              format={dateFormat}
              value={value}
              label={!readOnly && 'Select date'}
              onChange={handleChange}
              onAccept={onDateAcceptHandler}
              disableFuture={disableFuture}
              onError={onDateErrorHandler}
              orientation='portrait'
              disabled={readOnly}
              slotProps={{
                toolbar: { hidden: false },
                textField: { onBlur: onBlurHandler, onChange: onChangeHandler },
              }}
            />
          </div>
        </div>
        {isShowMessage && fieldValidationStatus(name)?.hasError && (
          <div className='validation-text-message-error'>
            {fieldValidationStatus(name)?.validationMessage}
            {setToCurrentBrowserTime && ','}
            {setToCurrentBrowserTime && (
              <Link className='set-current-time' underline='hover' onClick={setToCurrentTimeHandler}>
                click here to use the current browser time
              </Link>
            )}
          </div>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default DatePickerControl;
