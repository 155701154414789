import { LoadingStatus } from '../../constants/loading-constants';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';

interface ModalState {
    data: ModalStateModel
    hasError: boolean
    actionStatus: string
    modalStatus: boolean
}

export const initialState: ModalState = {
    data: {},
    modalStatus: false,
    actionStatus: LoadingStatus.LOADING,
    hasError: false
} as ModalState;