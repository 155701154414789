import classNames from "classnames";
import'./icons.scss';

interface CompacLogoProps {
    smallerSize:boolean
}

const CompacLogo = (props: CompacLogoProps) => {
    return (
        <div className='logo-container'>
        <div
            className={classNames({
            'compac-logo': !props.smallerSize,
            'compac-logo-small': props.smallerSize,
            })}
        />
        </div>
    );
};

export default CompacLogo