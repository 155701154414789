import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { PriceScheduleInfoModel } from '../../../models/priceScheduleModel';
import { SitesInfoModel } from '../../../models/siteModel';
import { ProductPriceModel } from '../../../models/priceScheduleModel';
import { ValidationError } from '../../../models/baseModels/validationModel';
import { ModuleName } from '../../../constants/module-constants';
import { LoadingStatus } from '../../../constants/loading-constants';
import { DATETIME_FORMAT } from '../../../constants/dateTimePicker-constants';
import { FormActionType } from '../../../constants/form-constants';
import * as dateTimeHelper from '../../../utilities/datetime-helper';
import * as DateTimeMessage from '../../../constants/dateTimePicker-constants';
import Form from '../../../components/form/form.container';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import DateTimePickerComponent from '../../../components/datetimepicker/datetimepicker.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import ProductPriceConfiguration from './product-prices';

interface PriceSchedulePageProps {
  pageTitle?: string;
  action?: string;
  priceScheduleInfo: PriceScheduleInfoModel;
  priceScheduleContent: PriceScheduleInfoModel[];
  hasValidationError: boolean;
  selectedOrganisationId: string;
  siteListName: KeyValuePair[];
  productListName: KeyValuePair[];
  priceScheduleStatus: string;
  backDropActionStatus: string;
  fieldValidations: ValidationError[];
  siteStatus: string;
  loadPriceScheduleInfo: (data: string | undefined) => void;
  loadPriceSchedules: () => void;
  editPriceScheduleItem: (data: PriceScheduleInfoModel) => void;
  createPriceScheduleItem: (data: PriceScheduleInfoModel) => void;
  userAccess: (moduleName: string) => AuthorisationModel;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
  removeAllValidation: () => void;
  removeValidation: (name: string) => void;
  loadSiteNameList: () => void;
  loadProductNameList: () => void;
}

const PriceSchedule: React.FC<PriceSchedulePageProps> = (props: PriceSchedulePageProps) => {
  const {
    pageTitle,
    action,
    priceScheduleInfo,
    priceScheduleContent,
    hasValidationError,
    selectedOrganisationId,
    siteListName,
    productListName,
    priceScheduleStatus,
    backDropActionStatus,
    fieldValidations,
    siteStatus,
    loadPriceScheduleInfo,
    loadPriceSchedules,
    editPriceScheduleItem,
    createPriceScheduleItem,
    userAccess,
    setHeaderConfiguration,
    setPageConfiguration,
    setIsPageDirty,
    removeAllValidation,
    removeValidation,
    loadSiteNameList,
    loadProductNameList,
  } = props;

  const [priceScheduleInfoValue, setPriceScheduleInfoValue] = useState({} as PriceScheduleInfoModel);
  const [validateCounterFlag, setValidateCounterFlag] = useState(0);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isUpdatingForm, setIsUpdatingForm] = useState(false);
  const [isClickFromViewPage, setIsClickFromViewPage] = useState(false);
  const [invalidActivationTime, setInvalidActivationTime] = useState(false);
  const [availableSites, setAvailableSites] = useState([] as KeyValuePair[]);
  const [secondaryButtonMoreOptions, setSecondaryButtonMoreOptions] = useState([] as KeyValuePair[]);
  const [primaryButtonMoreOptions, setPrimaryButtonMoreOptions] = useState([] as KeyValuePair[]);

  const currentTime = new Date();
  const offsetInMinutes = currentTime.getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;
  const twentyFourHoursBrforeUTC = new Date(currentTime.getTime() - (24 - offsetInHours) * 60 * 60 * 1000);

  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, priceScheduleId } = useParams();
  const isLoadPriceScheduleInfoRequested = useRef(false);
  const isLoadSiteNameListRequested = useRef(false);
  const isLoadProductNameListRequested = useRef(false);
  const isLoadPriceSchedulesRequested = useRef(false);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //ACCESS
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasSiteReadAccess, setHasSiteReadAccess] = useState(false);
  const [hasSiteCreateAccess, setHasSiteCreateAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasUpdateAccess);
    setHasSiteReadAccess(userAccess(ModuleName.SITE).hasReadAccess);
    setHasSiteCreateAccess(userAccess(ModuleName.SITE).hasCreateAccess);
  }, [userAccess]);

  useMemo(() => {
    setLoading(priceScheduleStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(priceScheduleStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(priceScheduleStatus === LoadingStatus.ERROR);
  }, [hasReadAccess, priceScheduleStatus]);

  useEffect(() => {
    setIsUpdatingForm(action === FormActionType.CREATE || action === FormActionType.EDIT);
  }, [location]);

  useEffect(() => {
    setIsPageDirty(false);
    if (
      !isLoadPriceScheduleInfoRequested.current &&
      hasReadAccess &&
      (action === FormActionType.VIEW || (action === FormActionType.EDIT && !isClickFromViewPage))
    ) {
      loadPriceScheduleInfo(priceScheduleId);
      setIsSaveButtonEnabled(hasUpdateAccess);
      isLoadPriceScheduleInfoRequested.current = true;
    } else if (action === FormActionType.CREATE && hasCreateAccess) {
      const currentTime = dateTimeHelper.convertDateToString(new Date());
      setPriceScheduleInfoValue({
        name: '',
        organisationId: selectedOrganisationId,
        activationDateTimeUtc: currentTime,
        sites: [] as string[],
        products: [] as ProductPriceModel[],
        sitesInfo: [] as SitesInfoModel[],
      } as PriceScheduleInfoModel);
      setIsSaveButtonEnabled(hasCreateAccess);
      removeAllValidation();

      if (!isLoadSiteNameListRequested.current && (!siteListName || siteListName?.length <= 0)) {
        loadSiteNameList();
        isLoadSiteNameListRequested.current = true;
      }

      if (!isLoadProductNameListRequested.current && productListName?.length <= 0) {
        loadProductNameList();
        isLoadProductNameListRequested.current = true;
      }
    }
  }, [
    action,
    hasCreateAccess,
    hasReadAccess,
    hasUpdateAccess,
    priceScheduleId,
    selectedOrganisationId,
    isClickFromViewPage,
    loadPriceScheduleInfo,
    setIsPageDirty,
    removeAllValidation,
    loadSiteNameList,
    loadProductNameList,
  ]);

  useEffect(() => {
    switch (action) {
      case FormActionType.CREATE:
        setSuccess(hasCreateAccess);
        break;
      case FormActionType.EDIT:
        setSuccess(hasUpdateAccess);
        break;
      case FormActionType.VIEW:
        setSuccess(hasReadAccess);
        break;
    }
  }, [action, hasCreateAccess, hasReadAccess, hasUpdateAccess]);

  useEffect(() => {
    setHeaderConfiguration({
      title: pageTitle,
      showCreateButton: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
    } as HeaderStateModel);
  }, [setHeaderConfiguration, pageTitle, error]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: action === FormActionType.VIEW,
    } as PageSettingStateModel);
  }, [action, setPageConfiguration]);

  useEffect(() => {
    if (backDropActionStatus === LoadingStatus.SUCCESS || backDropActionStatus === LoadingStatus.WARNING) {
      navigate(`/organisations/${orgId}/price-schedules`);
    }
  }, [backDropActionStatus, orgId, navigate]);

  useEffect(() => {
    if (!priceScheduleContent && !isLoadPriceSchedulesRequested.current) {
      loadPriceSchedules();
      isLoadPriceSchedulesRequested.current = true;
    } else if (siteListName && priceScheduleContent) {
      let selectedSites = [] as string[][];
      priceScheduleContent.map((it) => {
        if (it.id !== priceScheduleInfoValue?.id) {
          selectedSites.push(it.sites.flat());
          return;
        } else return null;
      });
      const selectedSiteArray: string[] = selectedSites.reduce((result, array) => [...result, ...array], []);
      setAvailableSites(siteListName?.filter((it) => !selectedSiteArray.includes(String(it.key))));
    }
  }, [siteListName, priceScheduleContent, priceScheduleInfoValue, action, loadPriceSchedules]);

  useEffect(() => {
    if (action === FormActionType.EDIT && priceScheduleInfo?.activationDateTimeUtc) {
      setInvalidActivationTime(new Date(priceScheduleInfo?.activationDateTimeUtc) < twentyFourHoursBrforeUTC);
    }
  }, [priceScheduleInfo, action]);

  useMemo(() => {
    if (!!priceScheduleInfo) {
      setPriceScheduleInfoValue(priceScheduleInfo);
    }
  }, [priceScheduleInfo, action]);

  const onEditHandler = () => {
    setIsClickFromViewPage(true);
    navigate(`/organisations/${orgId}/price-schedules/${priceScheduleId}/details/edit`);
  };

  const onSaveClickHandler = () => {
    setValidateCounterFlag((prev) => ++prev);

    let selectedActicationDateTime = new Date(priceScheduleInfoValue.activationDateTimeUtc);
    setInvalidActivationTime(new Date(selectedActicationDateTime) < twentyFourHoursBrforeUTC);

    if (new Date(selectedActicationDateTime) < twentyFourHoursBrforeUTC) return;

    if (!hasValidationError) {
      switch (action) {
        case FormActionType.EDIT:
          editPriceScheduleItem(priceScheduleInfoValue);
          break;
        case FormActionType.CREATE:
          createPriceScheduleItem(priceScheduleInfoValue);
          break;
      }
      setValidateCounterFlag(0);
    }
  };

  const onTextChangeHandler = useCallback(
    (newvalue: KeyValuePair) => {
      setIsPageDirty(isSaveButtonEnabled);
      setPriceScheduleInfoValue((prevstate) => {
        return {
          ...prevstate,
          [newvalue.key]: newvalue.value,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const onCancel = () => {
    setValidateCounterFlag(0);
  };

  const handleNewProductPricesValue = useCallback(
    (newvalue: ProductPriceModel[]) => {
      setIsPageDirty(isSaveButtonEnabled);
      setPriceScheduleInfoValue((prevstate) => {
        return {
          ...prevstate,
          products: [...newvalue],
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const handleNewSitesValue = useCallback(
    (newvalue: KeyValuePair[]) => {
      if (newvalue.length === 0) {
        removeValidation('activationDate');
        removeValidation('activationTime');
        setPriceScheduleInfoValue((prevstate) => {
          return {
            ...prevstate,
            activationDateTimeUtc: '',
          };
        });
      }
      setIsPageDirty(isSaveButtonEnabled && (action === FormActionType.CREATE || action === FormActionType.EDIT));
      const currentTime = dateTimeHelper.convertDateToString(new Date());
      setPriceScheduleInfoValue((prevstate: any) => {
        let sites = newvalue.map((x) => {
          return x.key.toString();
        });
        let uniqueSites = sites.filter((site, i) => {
          return sites.indexOf(site) === i;
        });
        return {
          ...prevstate,
          sites: [...uniqueSites],
          activationDateTimeUtc: prevstate.activationDateTimeUtc || currentTime,
        };
      });

      if (!priceScheduleInfoValue.activationDateTimeUtc && currentTime) {
        setPriceScheduleInfoValue((prevstate) => {
          return {
            ...prevstate,
            activationDateTimeUtc: currentTime,
          };
        });
      }
    },
    [setIsPageDirty, isSaveButtonEnabled, priceScheduleInfoValue.activationDateTimeUtc]
  );

  const handleActivationDateTimeChange = useCallback(
    (newValue: string) => {
      setInvalidActivationTime(new Date(newValue) < twentyFourHoursBrforeUTC);

      setIsPageDirty(isSaveButtonEnabled);
      setPriceScheduleInfoValue((prevstate) => {
        return {
          ...prevstate,
          activationDateTimeUtc: newValue,
        };
      });
    },
    [setIsPageDirty, isSaveButtonEnabled]
  );

  const getSiteName = (siteId: string): string => {
    var siteName = siteListName?.find((c) => {
      return c.key === siteId;
    })?.value;
    return !!siteName ? siteName.toString() : '';
  };

  const setToCurrentBrowserTimeHandler = useCallback(() => {
    const currentTime = dateTimeHelper.convertDateToString(new Date());

    if (currentTime) {
      handleActivationDateTimeChange(currentTime);
    }
  }, []);

  const handleSitekeyValuePairLoding = () => {
    if (siteListName?.length === 0 && !isLoadSiteNameListRequested.current) {
      loadSiteNameList();
      isLoadSiteNameListRequested.current = true;
    }
  };

  const tabWizardList = [
    {
      tabType: 'link',
      tabLink: `/organisations/${orgId}/price-schedules`,
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Price Schedules</span> <NavigateNextIcon className='next-icon' />
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Basic</span>
          <NavigateNextIcon className='next-icon' />
        </div>
      ),
      tabError: fieldValidations?.some(
        (it) =>
          (it.name === 'name' && it.hasError === true) ||
          (it.name === 'sites' && it.hasError === true) ||
          (it.name === 'activationDate' && it.hasError === true) ||
          (it.name === 'activationTime' && it.hasError === true)
      ),
      tabFormCompleted: !fieldValidations?.some(
        (it) =>
          (it.name === 'name' && it.hasError === true) ||
          (it.name === 'sites' && it.hasError === true) ||
          (it.name === 'activationDate' && it.hasError === true) ||
          (it.name === 'activationTime' && it.hasError === true)
      ),
      tabPanel: (
        <div className='wizard-tab-panel-details'>
          <CustomTextField
            validateCounter={validateCounterFlag}
            isMandatory={true}
            maxCharLength={50}
            minCharLength={3}
            key={'name'}
            label={'Price Schedule Name'}
            placeholder={`Enter Price Schedule Name`}
            onBindingValue={onTextChangeHandler}
            name={'name'}
            value={priceScheduleInfoValue?.name}
            type={'input'}
            readOnly={!isUpdatingForm}
          ></CustomTextField>
          {!!priceScheduleInfoValue?.sitesInfo && (
            <MultiSelectDropdown
              dataList={availableSites}
              label='Sites'
              name='sites'
              placeholder='Add Sites'
              noOptionsText={`${siteListName && siteListName?.length > 0 ? 'All the sites have been assigned to other price schedules.' : 'No Sites Available'}`}
              dataListFinishedLoading={siteStatus === LoadingStatus.SUCCESS || siteStatus === LoadingStatus.ERROR}
              createOptionsLink={
                (!siteListName || siteListName?.length <= 0) && hasSiteReadAccess && hasSiteCreateAccess
                  ? `/organisations/${orgId}/sites/create`
                  : ''
              }
              value={priceScheduleInfoValue?.sites.map(
                (item) => ({ key: item, value: getSiteName(item) }) as KeyValuePair
              )}
              onBindingValue={handleNewSitesValue}
              maxHeight={192}
              readOnly={!isUpdatingForm}
              keyValuePairLoding={handleSitekeyValuePairLoding}
              keyValuePairLoadingStatus={siteStatus}
            ></MultiSelectDropdown>
          )}
          {
            <DateTimePickerComponent
              validateCounter={validateCounterFlag}
              label='Activation Time (Site Local Time)'
              dateFormat={DATETIME_FORMAT.DATE_ONLY}
              dateField='activationDate'
              timeField='activationTime'
              dateTimeValue={
                priceScheduleInfoValue?.sites && priceScheduleInfoValue?.sites?.length > 0
                  ? priceScheduleInfoValue?.activationDateTimeUtc
                  : ''
              }
              handleDateTimeChange={handleActivationDateTimeChange}
              disableFuture={false}
              isMandatory={priceScheduleInfoValue?.sites?.length > 0}
              invalidDateMessage={invalidActivationTime ? DateTimeMessage.ACTIVATION_TIME_TOO_OLD : ''}
              readOnly={!isUpdatingForm || priceScheduleInfoValue?.sites?.length <= 0}
              setToCurrentBrowserTime={invalidActivationTime ? setToCurrentBrowserTimeHandler : undefined}
              disabledPassedDates={new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)}
            />
          }
        </div>
      ),
    },
    {
      tabLabel: (
        <div className='wizard-tab-item'>
          <span className='wizard-tab-item-label'>Prices</span>
        </div>
      ),
      tabError:
        fieldValidations?.some(
          (it) =>
            (it.name === 'product0' && it.hasError === true) ||
            (it.name === 'price0' && it.hasError === true) ||
            (it.name === 'product1' && it.hasError === true) ||
            (it.name === 'price1' && it.hasError === true) ||
            (it.name === 'product2' && it.hasError === true) ||
            (it.name === 'price2' && it.hasError === true) ||
            (it.name === 'product3' && it.hasError === true) ||
            (it.name === 'price3' && it.hasError === true) ||
            (it.name === 'product4' && it.hasError === true) ||
            (it.name === 'price4' && it.hasError === true) ||
            (it.name === 'product5' && it.hasError === true) ||
            (it.name === 'price5' && it.hasError === true) ||
            (it.name === 'product6' && it.hasError === true) ||
            (it.name === 'price6' && it.hasError === true)
        ) ||
        action === FormActionType.VIEW ||
        action === FormActionType.EDIT,
      tabFormCompleted:
        !fieldValidations?.some(
          (it) =>
            (it.name === 'product0' && it.hasError === true) ||
            (it.name === 'price0' && it.hasError === true) ||
            (it.name === 'product1' && it.hasError === true) ||
            (it.name === 'price1' && it.hasError === true) ||
            (it.name === 'product2' && it.hasError === true) ||
            (it.name === 'price2' && it.hasError === true) ||
            (it.name === 'product3' && it.hasError === true) ||
            (it.name === 'price3' && it.hasError === true) ||
            (it.name === 'product4' && it.hasError === true) ||
            (it.name === 'price4' && it.hasError === true) ||
            (it.name === 'product5' && it.hasError === true) ||
            (it.name === 'price5' && it.hasError === true) ||
            (it.name === 'product6' && it.hasError === true) ||
            (it.name === 'price6' && it.hasError === true)
        ) &&
        priceScheduleInfoValue?.products &&
        priceScheduleInfoValue?.products?.length > 0 &&
        Boolean(priceScheduleInfoValue?.products[0]) &&
        Boolean(priceScheduleInfoValue?.products[0]?.price) &&
        Boolean(priceScheduleInfoValue?.products[0]?.id),
      tabPanel: (
        <ProductPriceConfiguration
          validateCounter={validateCounterFlag}
          productPrices={priceScheduleInfoValue?.products}
          onBindingValue={handleNewProductPricesValue}
          products={productListName}
          removeValidation={removeValidation}
          readOnly={!isUpdatingForm}
        ></ProductPriceConfiguration>
      ),
    },
  ];

  const currentTabWizardChangeHandler = (data: number) => {
    if (data <= 1) {
      setSecondaryButtonMoreOptions([]);
      setPrimaryButtonMoreOptions([
        {
          key: 'save',
          value: 'Save',
          additionalValue: onSaveClickHandler,
        } as KeyValuePair,
      ]);
    } else if (data >= 2) {
      setSecondaryButtonMoreOptions([
        {
          key: 'cancel',
          value: 'Cancel',
          additionalValue: () => navigate(`/organisations/${orgId}/bin-ranges`),
        } as KeyValuePair,
      ]);
      setPrimaryButtonMoreOptions([]);
    }
  };

  const wizardListChangeHandler = () => {
    if (fieldValidations && fieldValidations?.length > 0) setValidateCounterFlag((prev) => ++prev);
  };

  return (
    <>
      <Form
        displayLoadingIndicator={(action === FormActionType.VIEW || action === FormActionType.EDIT) && loading}
        displayErrorDetails={error}
        displayNoAccessMessage={
          (action === FormActionType.VIEW && !hasReadAccess) ||
          (action === FormActionType.CREATE && !hasCreateAccess) ||
          (action === FormActionType.EDIT && !hasUpdateAccess)
        }
        displayForm={success}
        isSaveButtonEnabled={isSaveButtonEnabled}
        onCancelClick={onCancel}
        onSaveClick={onSaveClickHandler}
        onEditClick={onEditHandler}
        formDataloading={loading}
        hasUpdateAccess={hasUpdateAccess}
        listURL={`/organisations/${orgId}/price-schedules`}
        isClickFromViewPage={isClickFromViewPage}
        tabWizardList={tabWizardList}
        onCurrentTabWizardChange={currentTabWizardChangeHandler}
        wizardListChange={wizardListChangeHandler}
        primaryButtonMoreOptions={primaryButtonMoreOptions}
        secondaryButtonMoreOptions={secondaryButtonMoreOptions}
      >
        <></>
      </Form>
    </>
  );
};

export default PriceSchedule;
