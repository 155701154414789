import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import {
  selectPriceSchedContent,
  selectPriceSchedStatus,
  selectPricSchedListStatus,
} from '../../store/price-schedules/selectors';
import { selectAuthStatus, selectUserAccess } from '../../store/auth/selectors';
import * as priceScheduleActions from '../../store/price-schedules/actions';
import * as headerActions from '../../store/header/actions';

import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import PriceSchedulesList from './price-schedules-list';
import { Dispatch } from '@reduxjs/toolkit';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import * as modalActions from '../../store/modals/actions';
import * as dialogActions from '../../store/dialog-box/actions';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const MapStateToProps = (state: RootState) => {
  return {
    priceScheduleContent: selectPriceSchedContent(state),
    priceScheduleStatus: selectPriceSchedStatus(state),
    priceScheduleListStatus: selectPricSchedListStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const MapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadPriceSchedules: () => dispatch({ type: priceScheduleActions.LOAD_PRICESCHEDULES }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    deletePriceScheduleItem: (data: string) =>
      dispatch({
        type: priceScheduleActions.DELETE_PRICESCHEDULE,
        payload: data,
      }),
    openModal: (data: ModalStateModel) =>
      dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    openDialogBox: () =>
      dispatch({
        type: dialogActions.OPEN_DIALOGBOX,
      }),
    closeDialogBox: () =>
      dispatch({
        type: dialogActions.CLOSE_DIALOGBOX,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const PriceSchedulePage = connect(MapStateToProps, MapDispatchToProps)(PriceSchedulesList);

export default PriceSchedulePage;
