import { LoadingStatus } from "../../constants/loading-constants";
import { PermissionGroupItemsModel } from "../../models/permissionGroupModel";
import { UserPermissionAssignmentDetailModal, UserPermissionModel } from "../../models/userPermissionModel";
import { UserModel } from '../../models/usersModel';

interface UserState {
    userStatus: string
    usersContent: UserModel[],    
    userInfo: UserModel,
    userPermission: UserPermissionModel[],
    hasError: boolean,
    userListPermissionGroups: PermissionGroupItemsModel[],
    userListPermission: UserPermissionAssignmentDetailModal[],
};

export const initialState: UserState = {userStatus: LoadingStatus.LOADING} as UserState;