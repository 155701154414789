import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Item from '../../../components/box-items/box-item';
import MultiSelectDropdown from '../../../components/multiselect-dropdown/multiselect-dropdown.container';
import CustomSwitch from '../../../components/switch/custom-switch.containter';
import DropDown from '../../../components/dropdown/dropdown.container';
import CollapsibleList from '../../../components/collapsible-list/collapsible-list';
import TabList from '../../../components/tab-list/tab-list.container';
import CustomTextField from '../../../components/text-field/text-field.container';
import KeyValuePair from '../../../models/baseModels/keyValuePairModel';
import { BinRangeModel, CardProcessingRuleModel } from '../../../models/binRangeModel';
import { TabListModel } from '../../../models/baseModels/tabListModel';
import { TextFieldType } from '../../../constants/textfield-constants';
import { FormActionType } from '../../../constants/form-constants';
import {
  DEFAULT_DROP_DOWN_VALUE,
  PromptTypes,
  alternateAuthorisationAmountOption,
  processingMethod,
} from '../../../constants/dropdown-constants';
import '../styles/card-processing-rule.scss';

interface CardProcessingRuleProps {
  binRange: BinRangeModel;
  validateCounter: number;
  cardProcessingRules: CardProcessingRuleModel[];
  readOnly?: boolean;
  siteListName: KeyValuePair[];
  isSelectedSites: boolean;
  cardType?: string;
  action?: string;
  hasSiteReadAccess: boolean;
  hasSiteCreateAccess: boolean;
  isSaveButtonEnabled: boolean;
  removeValidation: (name: string) => void;
  onBindingValue?: (newvalue: CardProcessingRuleModel[]) => void;
  setIsPageDirty: (data: boolean) => void;
}

const CardProcessingRule: React.FC<CardProcessingRuleProps> = (props: CardProcessingRuleProps) => {
  const {
    binRange,
    validateCounter,
    cardProcessingRules,
    readOnly,
    siteListName,
    isSelectedSites,
    cardType,
    action,
    hasSiteReadAccess,
    hasSiteCreateAccess,
    isSaveButtonEnabled,
    removeValidation,
    onBindingValue,
    setIsPageDirty,
  } = props;

  const [validationFlag, setValidationFlag] = useState(0);
  const [rulesValue, setRulesValue] = useState(cardProcessingRules);
  const [seceltedOnAllSites, setSeceltedOnAllSites] = useState(false);
  const [disabledCachedCardProcessing, setDisabledCachedCardProcessing] = useState(false);
  const [displaySiteList, setDisplaySiteList] = useState([] as KeyValuePair[]);
  const [isLastRuleSelectedSite, setIsLastRuleSelectedSite] = useState(false);
  const { orgId } = useParams();

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    if (cardType === 'bank' && action === FormActionType.CREATE) {
      setDisabledCachedCardProcessing(true);
      rulesValue?.forEach((_, i) => {
        removeValidation(`cachedCardsEnabled${i}`);
        removeValidation(`cachedCardsMethod${i}`);
        removeValidation(`cachedCardsOfflineEnabled${i}`);
        removeValidation(`cachedCardsOfflineMaxTransactionsPerCardPerDay${i}`);
        removeValidation(`cachedCardsOfflineMaxTransactionAmountLimit${i}`);
        removeValidation(`merchantId${i}`);
        removeValidation(`terminalId${i}`);
      });

      if (rulesValue) {
        let ruleItems = [...rulesValue];
        if (!!ruleItems) {
          const updatedRules = ruleItems.map((obj) => ({
            ...obj,
            applyToAllSites: true,
            uncachedCardsMethod: 'windcave',
            cachedCardsEnabled: false,
          }));
          setRulesValue(updatedRules);
          if (onBindingValue) {
            onBindingValue(updatedRules);
          }
        }
      }
    } else if (cardType === 'account' && action === FormActionType.CREATE) {
      setDisabledCachedCardProcessing(false);
      if (rulesValue) {
        let ruleItems = [...rulesValue];
        if (!!ruleItems) {
          const updatedRules = ruleItems.map((obj) => ({
            ...obj,
            applyToAllSites: true,
            uncachedCardsProcessingEnabled: true,
            uncachedCardsMethod: 'compac',
            cachedCardsEnabled: true,
            cachedCardsMethod: 'compac',
            cachedCardsOfflineEnabled: false,
          }));
          setRulesValue(updatedRules);
          if (onBindingValue) {
            onBindingValue(updatedRules);
          }
        }
      }
    } else if (cardType === 'ampol' && action === FormActionType.CREATE) {
      setDisabledCachedCardProcessing(false);
      if (rulesValue) {
        let ruleItems = [...rulesValue];
        if (!!ruleItems) {
          const updatedRules = ruleItems.map((obj) => ({
            ...obj,
            applyToAllSites: true,
            uncachedCardsMethod: 'windcave',
            cachedCardsEnabled: true,
          }));
          setRulesValue(updatedRules);
          if (onBindingValue) {
            onBindingValue(updatedRules);
          }
        }
      }
    } else setDisabledCachedCardProcessing(false);
  }, [cardType, action]);

  useEffect(() => {
    setValidationFlag(validateCounter);
  }, [validateCounter]);

  useEffect(() => {
    if (!!cardProcessingRules) {
      setRulesValue(cardProcessingRules);
      const rulesIncludedSites = cardProcessingRules
        .map((it) => {
          return it.sites;
        })
        .flat();
      let updatedSiteList = [];
      for (let i = 0; i < siteListName?.length; i++) {
        const site = siteListName[i];
        if (!rulesIncludedSites.includes(String(site.key))) {
          updatedSiteList.push(site);
        }
      }
      setDisplaySiteList(updatedSiteList);
      setIsLastRuleSelectedSite(cardProcessingRules[cardProcessingRules?.length - 1]?.sites?.length > 0);
    }
  }, [cardProcessingRules, siteListName, setRulesValue]);

  const getSiteName = (siteId: string): string => {
    var siteName = siteListName?.find((c) => {
      return c.key === siteId;
    })?.value;
    return !!siteName ? siteName.toString() : '';
  };

  const getPromptName = (prompt: string): string => {
    var promptName = PromptTypes?.find((c) => {
      return c.key === prompt;
    })?.value;
    return !!promptName ? promptName.toString() : '';
  };

  const resetRuleListValidation = () => {
    rulesValue.forEach((_, i) => {
      removeValidation(`sites${i}`);
      removeValidation(`cachedCardsEnabled${i}`);
      removeValidation(`cachedCardsMethod${i}`);
      removeValidation(`cachedCardsOfflineEnabled${i}`);
      removeValidation(`cachedCardsOfflineMaxTransactionsPerCardPerDay${i}`);
      removeValidation(`cachedCardsOfflineMaxTransactionAmountLimit${i}`);
      removeValidation(`uncachedCardsEnabled${i}`);
      removeValidation(`uncachedCardsMethod${i}`);
      removeValidation(`merchantId${i}`);
      removeValidation(`terminalId${i}`);
    });
  };

  const handleAddClick = () => {
    let cardProcessingRule = {
      name: `Rule ${rulesValue?.length + 1}`,
      sites: [],
      applyToAllSites: isSelectedSites
        ? false
          ? cardProcessingRules
              .map((it) => {
                return it.sites;
              })
              .flat().length > 0
          : false
        : true,
      cachedCardsMethod: 'compac',

      cachedCardsOnlineEnabled: cardType === 'bank' ? false : true,
      cachedCardsOnlineAuthorisationAmount: undefined,
      cachedCardsOnlineAlternateAuthorisationAmount: undefined,
      cachedCardsOfflineEnabled: cardType === 'bank' ? false : true,
      cachedCardsOfflineMaxTransactionsPerCardPerDay: undefined,
      cachedCardsOfflineAuthorisationAmount: undefined,
      cachedCardsOfflineAlternateAuthorisationAmount: undefined,

      cachedCardsRequiredPrompts: [],
      avaliableCachedCardsRequiredPrompts: PromptTypes.filter(
        (item) => item.key !== 'productConfirmation' && item.key !== 'secondPump'
      ),
      cachedCardsOptionalPrompts: [],
      avaliableCachedCardsOptionalPrompts: PromptTypes.filter((item) => item.key !== 'secondPump'),

      uncachedCardsMethod: cardType === 'bank' ? 'windcave' : 'compac',
      uncachedCardsOnlineEnabled: true,
      uncachedCardsOnlineAuthorisationAmount: undefined,
      uncachedCardsOnlineAlternateAuthorisationAmount: undefined,
      uncachedCardsOfflineEnabled: cardType === 'wex' ? false : true,
      uncachedCardsOfflineAuthorisationAmount: undefined,
      uncachedCardsOfflineAlternateAuthorisationAmount: undefined,
      uncachedCardsRequiredPrompts: [],
      avaliableUncachedCardsRequiredPrompts: PromptTypes.filter(
        (item) =>
          item.key !== 'productConfirmation' && (cardType === 'bank' ? item.key !== '' : item.key !== 'secondPump')
      ),
      uncachedCardsOptionalPrompts: [],
      avaliableUncachedCardsOptionalPrompts: PromptTypes.filter((item) =>
        cardType === 'bank' ? item.key !== '' : item.key !== 'secondPump'
      ),
      displaySelectAllSiteOption: isSelectedSites
        ? false
          ? cardProcessingRules
              .map((it) => {
                return it.sites;
              })
              .flat().length > 0
          : false
        : true,
      displaySiteOption: displaySiteList ? displaySiteList : siteListName,
      merchantId: '',
      terminalId: '',
    } as CardProcessingRuleModel;
    setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    let list = [cardProcessingRule];
    if (rulesValue !== undefined) {
      list = [...rulesValue];
      list.push(cardProcessingRule);
    }
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    resetRuleListValidation();

    let list = [...rulesValue];
    const removedSite = list[index].sites
      .map((it) => siteListName.find((site) => site.key === it) as KeyValuePair)
      .filter(Boolean);

    if (removedSite.length > 0) {
      for (let i = 0; i < list.length; i++) {
        if (i !== index) {
          list[i] = {
            ...list[i],
            displaySiteOption: [...list[i].displaySiteOption, ...removedSite],
          };
        }
      }
    }

    list.splice(index, 1);

    if (list.length === 1) {
      list[0] = {
        ...list[0],
        displaySelectAllSiteOption: true,
      };
    }

    setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    setRulesValue(list);
    setSeceltedOnAllSites(false);
    if (onBindingValue) {
      onBindingValue(list);
    }
  };

  const handleNewSites = (newvalue: KeyValuePair[], index: number) => {
    let ruleItems = [...rulesValue];
    if (!!ruleItems[index]) {
      let item = { ...ruleItems[index] };
      let sites = newvalue?.map((x) => {
        return x.key.toString();
      });
      let uniqueSites = sites?.filter((site, i) => {
        return sites.indexOf(site) === i;
      });
      item.applyToAllSites = uniqueSites?.length === siteListName?.length;
      item.sites = [...uniqueSites];
      ruleItems[index] = item;
    }
    setRulesValue(ruleItems);
    setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    let allSelectedSites = [] as string[];
    for (let rule of ruleItems) {
      allSelectedSites = allSelectedSites.concat(rule.sites);
    }
    if (onBindingValue) {
      onBindingValue(ruleItems);
    }
  };

  const handleNewPrompts = (newvalue: KeyValuePair[], index: number, newPromptObject: string) => {
    let ruleItems = [...rulesValue];
    if (!!ruleItems[index]) {
      let item = { ...ruleItems[index] };
      const newPrompts: string[] = newvalue.map((it) => {
        return it.key as string;
      });
      const avaliableOptions = PromptTypes.filter(
        (prompt) =>
          !newvalue
            .map((it) => {
              return it.key;
            })
            .includes(String(prompt.key))
      );

      if (newPromptObject === 'cachedCardsRequiredPrompts') {
        item.cachedCardsRequiredPrompts = [...newPrompts];
        item.avaliableCachedCardsOptionalPrompts = avaliableOptions;
      }
      if (newPromptObject === 'cachedCardsOptionalPrompts') {
        item.cachedCardsOptionalPrompts = [...newPrompts];
        item.avaliableCachedCardsRequiredPrompts = avaliableOptions.filter(
          (item) => item.key !== 'productConfirmation'
        );
      }
      if (newPromptObject === 'uncachedCardsRequiredPrompts') {
        item.uncachedCardsRequiredPrompts = [...newPrompts];
        item.avaliableUncachedCardsOptionalPrompts = avaliableOptions;
      }
      if (newPromptObject === 'uncachedCardsOptionalPrompts') {
        item.uncachedCardsOptionalPrompts = [...newPrompts];
        item.avaliableUncachedCardsRequiredPrompts = avaliableOptions.filter(
          (item) => item.key !== 'productConfirmation'
        );
      }
      ruleItems[index] = item;
    }
    setRulesValue(ruleItems);
    setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    if (onBindingValue) {
      onBindingValue(ruleItems);
    }
  };

  const handleSelectedOnAllSites = (newvalue: boolean, index: number) => {
    let ruleItems = [...rulesValue];
    if (!!ruleItems[index]) {
      let item = { ...ruleItems[index] };
      item.applyToAllSites = newvalue;
      if (newvalue) {
        item.sites = [];
      }
      setSeceltedOnAllSites(newvalue);
      ruleItems[index] = item;
    }
    setRulesValue(ruleItems);
    setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    if (onBindingValue) {
      onBindingValue(ruleItems);
    }
  };

  const handleNewValue = (newvalue: KeyValuePair, index: number) => {
    let ruleItems = [...rulesValue];
    if (!!ruleItems[index]) {
      let item = { ...ruleItems[index] };
      if (newvalue.key.toString().includes('name')) {
        item.name = String(newvalue.value);
      } else if (newvalue.key.toString().includes('uncachedCardsMethod')) {
        item.uncachedCardsMethod = newvalue.value.toString();
        if (
          newvalue.value !== 'compac' &&
          item.avaliableUncachedCardsRequiredPrompts?.findIndex((it) => it.key === 'secondPump') < 0 &&
          item.uncachedCardsOptionalPrompts?.findIndex((it) => it === 'secondPump') <= 0
        ) {
          item.avaliableUncachedCardsRequiredPrompts = item.avaliableUncachedCardsRequiredPrompts.concat({
            key: 'secondPump',
            value: 'Second Pump',
          });
        }
        if (
          newvalue.value !== 'compac' &&
          item.avaliableUncachedCardsOptionalPrompts?.findIndex((it) => it.key === 'secondPump') < 0 &&
          item.uncachedCardsRequiredPrompts?.findIndex((it) => it === 'secondPump') <= 0
        ) {
          item.avaliableUncachedCardsOptionalPrompts = item.avaliableUncachedCardsOptionalPrompts.concat({
            key: 'secondPump',
            value: 'Second Pump',
          });
        }
        if (
          newvalue.value === 'compac' &&
          item.avaliableUncachedCardsRequiredPrompts?.findIndex((it) => it.key === 'secondPump') >= 0
        ) {
          item.avaliableUncachedCardsRequiredPrompts = item.avaliableUncachedCardsRequiredPrompts.filter(
            (it) => it?.key !== 'secondPump'
          );
        }
        if (
          newvalue.value === 'compac' &&
          item.avaliableUncachedCardsOptionalPrompts?.findIndex((it) => it.key === 'secondPump') >= 0
        ) {
          item.avaliableUncachedCardsRequiredPrompts = item.avaliableUncachedCardsOptionalPrompts.filter(
            (it) => it?.key !== 'secondPump'
          );
        }
      } else if (newvalue.key.toString().includes('uncachedCardsOnlineEnabled')) {
        item.uncachedCardsOnlineEnabled = newvalue.value === true;
      } else if (newvalue.key.toString().includes('uncachedCardsOnlineAuthorisationAmount')) {
        item.uncachedCardsOnlineAuthorisationAmount = Number(newvalue.value);
      } else if (newvalue.key.toString().includes('uncachedCardsOfflineEnabled')) {
        item.uncachedCardsOfflineEnabled = newvalue.value === true;
      } else if (newvalue.key.toString().includes('uncachedCardsOfflineAuthorisationAmount')) {
        item.uncachedCardsOfflineAuthorisationAmount = Number(newvalue.value);
      } else if (newvalue.key.toString().includes('cachedCardsMethod')) {
        item.cachedCardsMethod = newvalue.value.toString();
        if (
          newvalue.value !== 'compac' &&
          item.avaliableCachedCardsRequiredPrompts?.findIndex((it) => it.key === 'secondPump') < 0 &&
          item.cachedCardsOptionalPrompts?.findIndex((it) => it === 'secondPump') <= 0
        ) {
          item.avaliableCachedCardsRequiredPrompts = item.avaliableCachedCardsRequiredPrompts.concat({
            key: 'secondPump',
            value: 'Second Pump',
          });
        }
        if (
          newvalue.value !== 'compac' &&
          item.avaliableCachedCardsOptionalPrompts?.findIndex((it) => it.key === 'secondPump') < 0 &&
          item.cachedCardsRequiredPrompts?.findIndex((it) => it === 'secondPump') <= 0
        ) {
          item.avaliableCachedCardsOptionalPrompts = item.avaliableCachedCardsOptionalPrompts.concat({
            key: 'secondPump',
            value: 'Second Pump',
          });
        }
        if (
          newvalue.value === 'compac' &&
          item.avaliableCachedCardsRequiredPrompts?.findIndex((it) => it.key === 'secondPump') >= 0
        ) {
          item.avaliableCachedCardsRequiredPrompts = item.avaliableCachedCardsRequiredPrompts.filter(
            (it) => it?.key !== 'secondPump'
          );
        }
        if (
          newvalue.value === 'compac' &&
          item.avaliableCachedCardsRequiredPrompts?.findIndex((it) => it.key === 'secondPump') >= 0
        ) {
          item.avaliableCachedCardsRequiredPrompts = item.avaliableCachedCardsRequiredPrompts.filter(
            (it) => it?.key !== 'secondPump'
          );
        }
      } else if (newvalue.key.toString().includes('cachedCardsOnlineEnabled')) {
        item.cachedCardsOnlineEnabled = newvalue.value === true;
      } else if (newvalue.key.toString().includes('cachedCardsOnlineAuthorisationAmount')) {
        item.cachedCardsOnlineAuthorisationAmount = Number(newvalue.value);
      } else if (newvalue.key.toString().includes('cachedCardsOfflineEnabled')) {
        item.cachedCardsOfflineEnabled = newvalue.value === true;
      } else if (newvalue.key.toString().includes('cachedCardsOfflineMaxTransactionsPerCardPerDay')) {
        item.cachedCardsOfflineMaxTransactionsPerCardPerDay = Number(newvalue.value);
      } else if (newvalue.key.toString().includes('cachedCardsOfflineAuthorisationAmount')) {
        item.cachedCardsOfflineAuthorisationAmount = Number(newvalue.value);
      } else if (newvalue.key.toString().includes('merchantId')) {
        item.merchantId = String(newvalue.value);
      } else if (newvalue.key.toString().includes('terminalId')) {
        item.terminalId = String(newvalue.value);
      } else if (newvalue.key.toString().includes('uncachedCardsOnlineAlternateAuthorisationAmount')) {
        item.uncachedCardsOnlineAlternateAuthorisationAmount = String(newvalue.value);
      } else if (newvalue.key.toString().includes('uncachedCardsOfflineAlternateAuthorisationAmount')) {
        item.uncachedCardsOfflineAlternateAuthorisationAmount = String(newvalue.value);
      } else if (newvalue.key.toString().includes('cachedCardsOnlineAlternateAuthorisationAmount')) {
        item.cachedCardsOnlineAlternateAuthorisationAmount = String(newvalue.value);
      } else if (newvalue.key.toString().includes('cachedCardsOfflineAlternateAuthorisationAmount')) {
        item.cachedCardsOfflineAlternateAuthorisationAmount = String(newvalue.value);
      }

      ruleItems[index] = item;
    }
    setRulesValue(ruleItems);
    setIsPageDirty(action !== FormActionType.VIEW && isSaveButtonEnabled);
    if (onBindingValue) {
      onBindingValue(ruleItems);
    }
  };

  const getMerchantDetailsTabDetailList = (item: CardProcessingRuleModel, i: number) => {
    return [
      {
        tabLabel: 'WEX',
        tabPanel: (
          <div className='tab-panel-details'>
            <CustomTextField
              key={`merchantId${i}`}
              label={'Merchant Id'}
              placeholder={'Enter Merchant Id'}
              onBindingValue={(newvalue: KeyValuePair) => {
                handleNewValue(newvalue, i);
              }}
              type='input'
              name={`merchantId${i}`}
              value={item?.merchantId}
              validateCounter={validationFlag}
              isMandatory={true}
              maxCharLength={15}
              readOnly={readOnly}
            ></CustomTextField>

            <CustomTextField
              key={`terminalId${i}`}
              label={'Terminal Id'}
              placeholder={'Enter Terminal Id'}
              onBindingValue={(newvalue: KeyValuePair) => {
                handleNewValue(newvalue, i);
              }}
              type='input'
              name={`terminalId${i}`}
              value={item?.terminalId}
              validateCounter={validationFlag}
              isMandatory={true}
              maxCharLength={30}
              readOnly={readOnly}
            ></CustomTextField>
          </div>
        ),
      },
    ] as TabListModel[];
  };

  const getCardsTabDetailList = (item: CardProcessingRuleModel, i: number) => {
    return [
      {
        tabLabel: 'Uncached Cards',
        tabPanel: (
          <div className='tab-panel-details'>
            <DropDown
              validateCounter={validationFlag}
              key={`uncachedCardsMethod${i}`}
              name={`uncachedCardsMethod${i}`}
              value={!!item?.uncachedCardsMethod ? item?.uncachedCardsMethod : DEFAULT_DROP_DOWN_VALUE}
              onBindingValue={(newvalue: KeyValuePair) => {
                handleNewValue(newvalue, i);
              }}
              label='Method'
              keyValuePair={processingMethod}
              readOnly={readOnly}
              isMandatory={true}
            />
            {!(
              action === FormActionType.VIEW &&
              item?.cachedCardsRequiredPrompts?.length <= 0 &&
              item?.cachedCardsOptionalPrompts?.length <= 0
            ) && <p>Prompts</p>}
            {item?.avaliableUncachedCardsRequiredPrompts?.length > 0 ? (
              <MultiSelectDropdown
                key={`uncachedCardsRequiredPrompts${i}`}
                name={`uncachedCardsRequiredPrompts${i}`}
                noOptionsText={'No Prompts Available'}
                validateCounter={validationFlag}
                dataList={item?.avaliableUncachedCardsRequiredPrompts}
                label='Required'
                inlineLabel='Required'
                placeholder='Add Prompts'
                value={item?.uncachedCardsRequiredPrompts?.map(
                  (prompt) =>
                    ({
                      key: prompt,
                      value: getPromptName(prompt),
                    }) as KeyValuePair
                )}
                onBindingValue={(newvalue: KeyValuePair[]) => {
                  handleNewPrompts(newvalue, i, 'uncachedCardsRequiredPrompts');
                }}
                maxHeight={192}
                isMandatory={false}
                readOnly={readOnly}
              ></MultiSelectDropdown>
            ) : (
              !readOnly && (
                <MultiSelectDropdown
                  noOptionsText={'No Prompts Available'}
                  dataList={[]}
                  name={`uncachedCardsRequiredPrompts${i}`}
                  label='Required'
                  inlineLabel='Required'
                  placeholder='Add Prompts'
                  value={[{} as KeyValuePair]}
                  onBindingValue={() => {
                    return;
                  }}
                  maxHeight={192}
                  isMandatory={false}
                  readOnly={readOnly}
                ></MultiSelectDropdown>
              )
            )}
            {item?.avaliableUncachedCardsOptionalPrompts?.length > 0 ? (
              <MultiSelectDropdown
                key={`uncachedCardsOptionalPrompts${i}`}
                noOptionsText={'No Prompts Available'}
                validateCounter={validationFlag}
                dataList={item?.avaliableUncachedCardsOptionalPrompts}
                name={`uncachedCardsOptionalPrompts${i}`}
                label='Optional'
                inlineLabel='Optional'
                placeholder='Add Prompts'
                value={item?.uncachedCardsOptionalPrompts?.map(
                  (prompt) =>
                    ({
                      key: prompt,
                      value: getPromptName(prompt),
                    }) as KeyValuePair
                )}
                onBindingValue={(newvalue: KeyValuePair[]) => {
                  handleNewPrompts(newvalue, i, 'uncachedCardsOptionalPrompts');
                }}
                maxHeight={192}
                isMandatory={false}
                readOnly={readOnly}
              ></MultiSelectDropdown>
            ) : (
              !readOnly && (
                <MultiSelectDropdown
                  noOptionsText={'No Prompts Available'}
                  dataList={[]}
                  name={`uncachedCardsOptionalPrompts${i}`}
                  label='Optional'
                  inlineLabel='Optional'
                  placeholder='Add Prompts'
                  value={[{} as KeyValuePair]}
                  onBindingValue={() => {
                    return;
                  }}
                  maxHeight={192}
                  isMandatory={false}
                  readOnly={readOnly}
                ></MultiSelectDropdown>
              )
            )}
            <CollapsibleList
              key={'Online'}
              label={'Online'}
              expanded={item?.uncachedCardsOnlineEnabled}
              disabledExpand={!item?.uncachedCardsOnlineEnabled}
              titleButtonOption={
                <CustomSwitch
                  key={`uncachedCardsOnlineEnabled${i}`}
                  name={`uncachedCardsOnlineEnabled${i}`}
                  enabledLabel='Enabled'
                  disabledLabel='Disabled'
                  value={item?.uncachedCardsOnlineEnabled}
                  onBindingValue={(newvalue: KeyValuePair) => {
                    handleNewValue(newvalue, i);
                  }}
                  readOnly={readOnly}
                />
              }
              children={
                <>
                  {item?.uncachedCardsOnlineEnabled && (
                    <>
                      <CustomTextField
                        key={`uncachedCardsOnlineAuthorisationAmount${i}`}
                        label={'Default Authorisation Amount'}
                        placeholder={'Enter Default Authorisation Amount'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type={TextFieldType.INTEGER}
                        name={`uncachedCardsOnlineAuthorisationAmount${i}`}
                        value={
                          item?.uncachedCardsOnlineAuthorisationAmount
                            ? item?.uncachedCardsOnlineAuthorisationAmount
                            : ''
                        }
                        validateCounter={validationFlag}
                        maxRange={9999}
                        minRange={0}
                        readOnly={readOnly || !item?.uncachedCardsOnlineEnabled}
                      ></CustomTextField>

                      <DropDown
                        key={`uncachedCardsOnlineAlternateAuthorisationAmount${i}`}
                        name={`uncachedCardsOnlineAlternateAuthorisationAmount${i}`}
                        value={item?.uncachedCardsOnlineAlternateAuthorisationAmount}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        label='Alternate Authorisation Amount'
                        keyValuePair={alternateAuthorisationAmountOption}
                        readOnly={readOnly || !item?.uncachedCardsOnlineEnabled}
                        tooltipTitle={alternateAuthorisationAmountTooltipTitle}
                      />
                    </>
                  )}
                </>
              }
            ></CollapsibleList>
            <CollapsibleList
              key={'Offline'}
              label={'Offline'}
              expanded={item?.uncachedCardsOfflineEnabled}
              disabledExpand={!item?.uncachedCardsOfflineEnabled || cardType === 'wex'}
              clickTooltip={cardType === 'wex' ? 'Offline cannot be configured for Wex type BIN Range.' : ''}
              titleButtonOption={
                <CustomSwitch
                  key={`uncachedCardsOfflineEnabled${i}`}
                  name={`uncachedCardsOfflineEnabled${i}`}
                  enabledLabel='Enabled'
                  disabledLabel='Disabled'
                  value={item?.uncachedCardsOfflineEnabled}
                  onBindingValue={(newvalue: KeyValuePair) => {
                    handleNewValue(newvalue, i);
                  }}
                  readOnly={readOnly || cardType === 'wex'}
                />
              }
              children={
                <>
                  {item?.uncachedCardsOfflineEnabled && (
                    <>
                      <CustomTextField
                        key={`uncachedCardsOfflineAuthorisationAmount${i}`}
                        label={'Default Authorisation Amount'}
                        placeholder={'Enter Default Authorisation Amount'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type={TextFieldType.INTEGER}
                        name={`uncachedCardsOfflineAuthorisationAmount${i}`}
                        value={
                          item?.uncachedCardsOfflineAuthorisationAmount
                            ? item?.uncachedCardsOfflineAuthorisationAmount
                            : ''
                        }
                        validateCounter={validationFlag}
                        maxRange={9999}
                        minRange={0}
                        readOnly={readOnly || !item?.uncachedCardsOfflineEnabled}
                      ></CustomTextField>

                      <DropDown
                        key={`uncachedCardsOfflineAlternateAuthorisationAmount${i}`}
                        name={`uncachedCardsOfflineAlternateAuthorisationAmount${i}`}
                        value={item?.uncachedCardsOfflineAlternateAuthorisationAmount}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        label='Alternate Authorisation Amount'
                        keyValuePair={alternateAuthorisationAmountOption}
                        readOnly={readOnly || !item?.uncachedCardsOfflineEnabled}
                        tooltipTitle={alternateAuthorisationAmountTooltipTitle}
                      />
                    </>
                  )}
                </>
              }
            ></CollapsibleList>
          </div>
        ),
      },

      {
        tabLabel: 'Cached Cards',
        tabPanel: (
          <div className='tab-panel-details'>
            <DropDown
              validateCounter={validationFlag}
              key={`cachedCardsMethod${i}`}
              name={`cachedCardsMethod${i}`}
              value={!!item?.cachedCardsMethod ? item?.cachedCardsMethod : DEFAULT_DROP_DOWN_VALUE}
              onBindingValue={(newvalue: KeyValuePair) => {
                handleNewValue(newvalue, i);
              }}
              label='Method'
              keyValuePair={processingMethod}
              readOnly={readOnly || disabledCachedCardProcessing || binRange?.cardType === 'wex'}
              isMandatory={true}
            />
            {!(
              action === FormActionType.VIEW &&
              item?.cachedCardsRequiredPrompts?.length <= 0 &&
              item?.cachedCardsOptionalPrompts?.length <= 0
            ) && <p>Prompts</p>}
            {item?.avaliableCachedCardsRequiredPrompts?.length > 0 ? (
              <MultiSelectDropdown
                key={`cachedCardsRequiredPrompts${i}`}
                name={`cachedCardsRequiredPrompts${i}`}
                noOptionsText={'No Prompts Available'}
                validateCounter={validationFlag}
                dataList={item?.avaliableCachedCardsRequiredPrompts}
                label='Required'
                inlineLabel='Required'
                placeholder='Add Prompts'
                value={item?.cachedCardsRequiredPrompts?.map(
                  (prompt) =>
                    ({
                      key: prompt,
                      value: getPromptName(prompt),
                    }) as KeyValuePair
                )}
                onBindingValue={(newvalue: KeyValuePair[]) => {
                  handleNewPrompts(newvalue, i, 'cachedCardsRequiredPrompts');
                }}
                maxHeight={192}
                isMandatory={false}
                readOnly={readOnly || binRange?.cardType === 'wex'}
              ></MultiSelectDropdown>
            ) : (
              !readOnly && (
                <MultiSelectDropdown
                  noOptionsText={'No Prompts Available'}
                  dataList={[]}
                  name={`cachedCardsRequiredPrompts${i}`}
                  label='Required'
                  inlineLabel='Required'
                  placeholder='Add Prompts'
                  value={[{} as KeyValuePair]}
                  onBindingValue={() => {
                    return;
                  }}
                  maxHeight={192}
                  isMandatory={false}
                  readOnly={readOnly}
                ></MultiSelectDropdown>
              )
            )}
            {item?.avaliableCachedCardsOptionalPrompts?.length > 0 ? (
              <MultiSelectDropdown
                key={`cachedCardsOptionalPrompts${i}`}
                noOptionsText={'No Prompts Available'}
                validateCounter={validationFlag}
                dataList={item?.avaliableCachedCardsOptionalPrompts}
                name={`cachedCardsOptionalPrompts${i}`}
                label='Optional'
                inlineLabel='Optional'
                placeholder='Add Prompts'
                value={item?.cachedCardsOptionalPrompts?.map(
                  (prompt) =>
                    ({
                      key: prompt,
                      value: getPromptName(prompt),
                    }) as KeyValuePair
                )}
                onBindingValue={(newvalue: KeyValuePair[]) => {
                  handleNewPrompts(newvalue, i, 'cachedCardsOptionalPrompts');
                }}
                maxHeight={192}
                isMandatory={false}
                readOnly={readOnly || binRange?.cardType === 'wex'}
              ></MultiSelectDropdown>
            ) : (
              !readOnly && (
                <MultiSelectDropdown
                  noOptionsText={'No Prompts Available'}
                  dataList={[]}
                  name={`cachedCardsOptionalPrompts${i}`}
                  label='Optional'
                  inlineLabel='Optional'
                  placeholder='Add Prompts'
                  value={[{} as KeyValuePair]}
                  onBindingValue={() => {
                    return;
                  }}
                  maxHeight={192}
                  isMandatory={false}
                  readOnly={readOnly}
                ></MultiSelectDropdown>
              )
            )}
            <CollapsibleList
              key={'Online'}
              label={'Online'}
              expanded={item?.cachedCardsOnlineEnabled && !disabledCachedCardProcessing}
              disabledExpand={!item?.cachedCardsOnlineEnabled}
              titleButtonOption={
                <CustomSwitch
                  key={`cachedCardsOnlineEnabled${i}`}
                  name={`cachedCardsOnlineEnabled${i}`}
                  enabledLabel='Enabled'
                  disabledLabel='Disabled'
                  value={item?.cachedCardsOnlineEnabled}
                  onBindingValue={(newvalue: KeyValuePair) => {
                    handleNewValue(newvalue, i);
                  }}
                  readOnly={readOnly || disabledCachedCardProcessing || binRange?.cardType === 'wex'}
                />
              }
              children={
                <>
                  {item?.cachedCardsOnlineEnabled && (
                    <>
                      <CustomTextField
                        key={`cachedCardsOnlineAuthorisationAmount${i}`}
                        label={'Default Authorisation Amount'}
                        placeholder={'Enter Default Authorisation Amount'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type={TextFieldType.INTEGER}
                        name={`cachedCardsOnlineAuthorisationAmount${i}`}
                        value={
                          item?.cachedCardsOnlineAuthorisationAmount ? item?.cachedCardsOnlineAuthorisationAmount : ''
                        }
                        validateCounter={validationFlag}
                        maxRange={9999}
                        minRange={0}
                        readOnly={
                          readOnly ||
                          !item?.cachedCardsOnlineEnabled ||
                          disabledCachedCardProcessing ||
                          binRange?.cardType === 'wex'
                        }
                      ></CustomTextField>

                      <DropDown
                        key={`cachedCardsOnlineAlternateAuthorisationAmount${i}`}
                        name={`cachedCardsOnlineAlternateAuthorisationAmount${i}`}
                        value={item?.cachedCardsOnlineAlternateAuthorisationAmount}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        label='Alternate Authorisation Amount'
                        keyValuePair={alternateAuthorisationAmountOption}
                        readOnly={readOnly || !item?.cachedCardsOnlineEnabled}
                        tooltipTitle={alternateAuthorisationAmountTooltipTitle}
                      />
                    </>
                  )}
                </>
              }
            ></CollapsibleList>
            <CollapsibleList
              key={'Offline'}
              label={'Offline'}
              expanded={item?.cachedCardsOfflineEnabled && !disabledCachedCardProcessing}
              disabledExpand={!item?.cachedCardsOfflineEnabled}
              titleButtonOption={
                <CustomSwitch
                  key={`cachedCardsOfflineEnabled${i}`}
                  name={`cachedCardsOfflineEnabled${i}`}
                  enabledLabel='Enabled'
                  disabledLabel='Disabled'
                  value={item?.cachedCardsOfflineEnabled}
                  onBindingValue={(newvalue: KeyValuePair) => {
                    handleNewValue(newvalue, i);
                  }}
                  readOnly={readOnly || disabledCachedCardProcessing || binRange?.cardType === 'wex'}
                />
              }
              children={
                <>
                  {item?.cachedCardsOfflineEnabled && (
                    <>
                      <CustomTextField
                        key={`cachedCardsOfflineMaxTransactionsPerCardPerDay${i}`}
                        label={'Max Transactions Per Card Per Day'}
                        placeholder={'Enter Max Transactions Per Card Per Day'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type={TextFieldType.INTEGER}
                        name={`cachedCardsOfflineMaxTransactionsPerCardPerDay${i}`}
                        value={
                          item?.cachedCardsOfflineMaxTransactionsPerCardPerDay
                            ? item?.cachedCardsOfflineMaxTransactionsPerCardPerDay
                            : ''
                        }
                        validateCounter={validationFlag}
                        maxRange={100}
                        minRange={0}
                        readOnly={
                          readOnly ||
                          !item?.cachedCardsOfflineEnabled ||
                          disabledCachedCardProcessing ||
                          binRange?.cardType === 'wex'
                        }
                      ></CustomTextField>
                      <CustomTextField
                        key={`cachedCardsOfflineAuthorisationAmount${i}`}
                        label={'Default Authorisation Amount'}
                        placeholder={'Enter Default Authorisation Amount'}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        type={TextFieldType.INTEGER}
                        name={`cachedCardsOfflineAuthorisationAmount${i}`}
                        value={
                          item?.cachedCardsOfflineAuthorisationAmount ? item?.cachedCardsOfflineAuthorisationAmount : ''
                        }
                        validateCounter={validationFlag}
                        maxRange={9999}
                        minRange={0}
                        readOnly={
                          readOnly ||
                          !item?.cachedCardsOfflineEnabled ||
                          disabledCachedCardProcessing ||
                          binRange?.cardType === 'wex'
                        }
                      ></CustomTextField>
                      <DropDown
                        key={`cachedCardsOfflineAlternateAuthorisationAmount${i}`}
                        name={`cachedCardsOfflineAlternateAuthorisationAmount${i}`}
                        value={item?.cachedCardsOfflineAlternateAuthorisationAmount}
                        onBindingValue={(newvalue: KeyValuePair) => {
                          handleNewValue(newvalue, i);
                        }}
                        label='Alternate Authorisation Amount'
                        keyValuePair={alternateAuthorisationAmountOption}
                        readOnly={readOnly || !item?.cachedCardsOfflineEnabled}
                        tooltipTitle={alternateAuthorisationAmountTooltipTitle}
                      />
                    </>
                  )}
                </>
              }
            ></CollapsibleList>
          </div>
        ),
        tabDisabled: binRange?.cardType === 'wex',
        clickTooltip: binRange?.cardType === 'wex' ? 'Cached Cards cannot be configured for Wex type BIN Range.' : '',
      },
    ] as TabListModel[];
  };

  const alternateAuthorisationAmountTooltipTitle: string = `Cards belonging to this BIN Range will be authorised for the amount defined in the selected entity. If the entity doesn't have a configured amount, the default authorisation amount in the BIN Range will be used.
  \ne.g. if Pump is selected, cards in this BIN range will be authorised for the amount defined in the selected pump's configuration. If the chosen pump has no default authorisation amount, the default authorisation amount configured in the BIN range will be used.`;

  return (
    <>
      <div className='card-processing-rules'>
        {!!rulesValue &&
          rulesValue?.map((item, i) => {
            return (
              <CollapsibleList
                key={i}
                label={item.name}
                expanded={true}
                titleButtonOption={
                  !readOnly &&
                  rulesValue?.length >= 1 && (
                    <Link
                      className='remove-card-processing-rule'
                      underline='hover'
                      onClick={() => handleRemoveClick(i)}
                    >
                      - Remove
                    </Link>
                  )
                }
                children={
                  <Item key={i} className='card-processing-rule-box'>
                    {
                      <div className='card-processing-rule-form'>
                        <CustomTextField
                          key={`name${i}`}
                          label={'Name'}
                          placeholder={'Enter Name'}
                          onBindingValue={(newvalue: KeyValuePair) => {
                            handleNewValue(newvalue, i);
                          }}
                          type='input'
                          name={`name${i}`}
                          value={item?.name}
                          validateCounter={validationFlag}
                          isMandatory={true}
                          maxCharLength={30}
                          minCharLength={3}
                          readOnly={readOnly}
                        ></CustomTextField>

                        {
                          <MultiSelectDropdown
                            dataList={item?.displaySiteOption}
                            key={`sites${i}`}
                            label='Sites'
                            name={`sites${i}`}
                            placeholder='Add Sites'
                            value={item?.sites?.map(
                              (site) =>
                                ({
                                  key: site,
                                  value: getSiteName(site),
                                }) as KeyValuePair
                            )}
                            onBindingValue={(newvalue: KeyValuePair[]) => {
                              handleNewSites(newvalue, i);
                            }}
                            maxHeight={192}
                            readOnly={readOnly}
                            selectAllOption={
                              item.displaySelectAllSiteOption &&
                              item?.displaySiteOption &&
                              item?.displaySiteOption?.length > 0
                            }
                            isSelectedAll={(newvalue: boolean) => {
                              item.displaySelectAllSiteOption &&
                                item?.displaySiteOption &&
                                item?.displaySiteOption?.length > 0 &&
                                handleSelectedOnAllSites(newvalue, i);
                            }}
                            validateCounter={validationFlag}
                            selectedAll={item.applyToAllSites}
                            isMandatory={true}
                            noOptionsText={'No Sites Available'}
                            createOptionsLink={
                              hasSiteReadAccess && hasSiteCreateAccess ? `/organisations/${orgId}/sites/create` : ''
                            }
                          ></MultiSelectDropdown>
                        }
                        {binRange?.cardType === 'wex' && (
                          <TabList label='Merchant Details' tabDetailList={getMerchantDetailsTabDetailList(item, i)} />
                        )}
                        <TabList tabDetailList={getCardsTabDetailList(item, i)} />
                      </div>
                    }
                  </Item>
                }
              ></CollapsibleList>
            );
          })}

        {!readOnly && (rulesValue?.length <= 0 || (!seceltedOnAllSites && isLastRuleSelectedSite)) && (
          <Link className='add-card-processing-rule' underline='hover' onClick={handleAddClick}>
            + Add Card Processing Rule
          </Link>
        )}
      </div>
    </>
  );
};

export default CardProcessingRule;
