import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { LoadingStatus } from '../../constants/loading-constants';
import {
  BinRangeEntity,
  BinRangeListReponse,
  CachedCardsEntity,
  CardProcessingRuleEntity,
  MerchantDetailsEntity,
  Prompt,
  UnCachedCardsEntity,
  WexMerchantDetailsEntity,
} from '../../entities/bin-range';
import { BinRangeItemListModel, BinRangeModel, CardProcessingRuleModel } from '../../models/binRangeModel';
import { selectOrganisationId } from '../auth/selectors';
import { setBinRangeData, setBinRangeError, setBinRangeList, setBinRangeStatus, setBinRangeNameList } from './reducers';
import * as actions from './actions';
import * as services from './services';
import * as siteServices from '../sites/services';
import * as fieldHelper from '../../utilities/field-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import { PromptTypes, cardType } from '../../constants/dropdown-constants';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { setGenericErrorData } from '../generic-error/reducers';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { SiteResponseEntity } from '../../entities/site';
import { closeDialogBox, setDialogBoxActionStatus } from '../dialog-box/reducers';
import { Messages } from '../../constants/messages';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { selectSiteList } from '../sites/selectors';
import { loadSiteNameList } from '../sites/sagas';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_BIN_RANGE_LIST, loadBinRangeList);
  yield takeLatest(actions.LOAD_BIN_RANGE_LIST_WITHOUT_SITE, loadBinRangeListWithoutSite);
  yield takeLatest(actions.LOAD_BIN_RANGE_BY_ID, loadBinRangeById);
  yield takeLatest(actions.DELETE_BIN_RANGE, deleteBinRange);
  yield takeLatest(actions.CREATE_BIN_RANGE, createBinRange);
  yield takeLatest(actions.UPDATE_BIN_RANGE, editBinRange);
  yield takeLatest(actions.LOAD_BIN_RANGE_NAME_LIST, loadBinRangeNameList);
  yield takeLatest(actions.LOAD_BIN_RANGE_NAME_LIST_WITHOUT_SITE, loadBinRangeNameListWithoutSite);
}

export function* loadBinRangeList() {
  try {
    yield put(setBinRangeStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: BinRangeListReponse = yield call(services.getBinRangeList, organisationId);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);
    let records: BinRangeItemListModel[] = yield call(MapEntityListToItemListModel, response, siteNameList);
    yield put(setBinRangeList(records));
    yield put(setBinRangeStatus(LoadingStatus.SUCCESS));

    const binRangeCountWithoutApplyToAllSites = response?.items?.filter(
      (it) => it?.cardProcessingRules?.filter((rule) => rule?.sites && rule?.sites?.length > 0).length > 0
    ).length;

    if ((!siteNameList || siteNameList?.length <= 0) && binRangeCountWithoutApplyToAllSites > 0) {
      yield call(loadSiteNameList);
      siteNameList = yield select(selectSiteList);
      let records: BinRangeItemListModel[] = yield call(MapEntityListToItemListModel, response, siteNameList);
      yield put(setBinRangeList(records));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setBinRangeError());
    yield put(setBinRangeStatus(LoadingStatus.ERROR));
  }
}

export function* loadBinRangeListWithoutSite() {
  try {
    yield put(setBinRangeStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: BinRangeListReponse = yield call(services.getBinRangeList, organisationId);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);
    let records: BinRangeItemListModel[] = yield call(MapEntityListToItemListModel, response, siteNameList);
    yield put(setBinRangeList(records));
    yield put(setBinRangeStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setBinRangeError());
    yield put(setBinRangeStatus(LoadingStatus.ERROR));
  }
}

export function* loadBinRangeNameList() {
  try {
    yield put(setBinRangeStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: BinRangeListReponse = yield call(services.getBinRangeList, organisationId);
    yield call(loadSiteNameList);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);
    let binRangeRecords: BinRangeItemListModel[] = yield call(MapEntityListToItemListModel, response, siteNameList);
    yield put(setBinRangeList(binRangeRecords));
    let records: KeyValuePair[] = yield call(MapBinRangeItemEntityToKeyValuePair, response);
    yield put(setBinRangeNameList(records));
    yield put(setBinRangeStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setBinRangeError());
    yield put(setBinRangeStatus(LoadingStatus.ERROR));
  }
}

export function* loadBinRangeNameListWithoutSite() {
  try {
    yield put(setBinRangeStatus(LoadingStatus.LOADING));
    const organisationId: string = yield select(selectOrganisationId);
    let response: BinRangeListReponse = yield call(services.getBinRangeList, organisationId);
    let siteNameList: KeyValuePair[] = yield select(selectSiteList);
    let binRangeRecords: BinRangeItemListModel[] = yield call(MapEntityListToItemListModel, response, siteNameList);
    yield put(setBinRangeList(binRangeRecords));
    let records: KeyValuePair[] = yield call(MapBinRangeItemEntityToKeyValuePair, response);
    yield put(setBinRangeNameList(records));
    yield put(setBinRangeStatus(LoadingStatus.SUCCESS));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setBinRangeError());
    yield put(setBinRangeStatus(LoadingStatus.ERROR));
  }
}

export function* loadBinRangeById(action: PayloadAction<string>) {
  try {
    if (!!action.payload) {
      yield put(setBinRangeStatus(LoadingStatus.LOADING));
      const organisationId: string = yield select(selectOrganisationId);
      let response: BinRangeEntity = yield call(services.getBinRangeById, action.payload, organisationId);
      let siteListName: KeyValuePair[] = yield select(selectSiteList);
      if (!siteListName || siteListName?.length <= 0) {
        let site_response: SiteResponseEntity = yield call(siteServices.getSiteList, organisationId);
        siteListName = yield call(MapSiteItemEntityToKeyValuePair, site_response);
      }
      let binRangeData: BinRangeModel = yield call(MapBinRangeEntitytoModel, response, siteListName);
      yield put(setBinRangeData(binRangeData));
      yield put(setBinRangeStatus(LoadingStatus.SUCCESS));
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setBinRangeError());
  }
}

export function* deleteBinRange(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const activeOrganisationId: string = yield select(selectOrganisationId);
    yield call(services.deleteBinRange, action.payload, activeOrganisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.BIN_RANGE_DELETE_SUCCESS));
    yield call(loadBinRangeList);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* createBinRange(action: PayloadAction<BinRangeModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let binRangeEntity = MapBinRangeModelToEntity(action.payload, organisationId);
    yield call(services.createBinRange, binRangeEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.BIN_RANGE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editBinRange(action: PayloadAction<BinRangeModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    let binRangeEntity = MapBinRangeModelToEntity(action.payload, organisationId);
    yield call(services.editBinRange, binRangeEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.BIN_RANGE_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const MapEntityListToItemListModel = (response: BinRangeListReponse, siteListName: KeyValuePair[]) => {
  if (response && response.items?.length > 0) {
    const result: BinRangeItemListModel[] = response.items.map((item) => {
      let { siteNames, summary } = GetSitesDetail(item?.cardProcessingRules, siteListName);
      return {
        id: item.id,
        organisationId: item.organisationId,
        name: fieldHelper.getDefaultStringvalue(item.name),
        binRange: `${fieldHelper.getDefaultStringvalue(item.binLow)} - ${fieldHelper.getDefaultStringvalue(
          item.binHigh
        )}`,
        binLow: item?.binLow,
        binHigh: item?.binHigh,
        cardType: fieldMappingHelper.validateEnumValue(item?.cardType?.toString(), cardType),
        sites: siteNames,
        siteSummary: summary,
        applyToAllSites: item.cardProcessingRules[0].applyToAllSites,
        selectedWindcaveProcessingRule:
          item?.cardProcessingRules?.map((it) => it.cachedCards?.method).findIndex((it) => it === 'windcave') >= 0 ||
          item?.cardProcessingRules?.map((it) => it.uncachedCards?.method).findIndex((it) => it === 'windcave') >= 0,
        selectedCompacProcessingRule:
          item?.cardProcessingRules?.map((it) => it.cachedCards?.method).findIndex((it) => it === 'compac') >= 0 ||
          item?.cardProcessingRules?.map((it) => it.uncachedCards?.method).findIndex((it) => it === 'compac') >= 0,
        cardTags: item.cardTags,
      } as BinRangeItemListModel;
    });

    return result;
  }

  return [] as BinRangeItemListModel[];
};

const MapBinRangeItemEntityToKeyValuePair = (response: BinRangeListReponse) => {
  if (response && response.items?.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};

const GetSitesDetail = (entity: CardProcessingRuleEntity[], siteListName: KeyValuePair[]) => {
  let summary: string = '';
  let sites: string[] = [];
  let siteNames: string[] = [];
  if (entity?.length > 0) {
    for (let item of entity) {
      if (item.sites)
        for (let site of item.sites) {
          if (sites?.filter((x) => x === site)?.length < 1) {
            let siteName = siteListName?.find((x) => x.key === site)?.value;
            if (!!siteName) {
              sites.push(site);
              siteNames.push(siteName.toString());
            }
          }
        }
    }
    if (siteNames?.length > 2) {
      summary = `${siteNames[0]}, ${siteNames[1]} and`;
    }
    if (siteNames?.length === 2) {
      summary = `${siteNames[0]} and ${siteNames[1]}`;
    }
    if (siteNames?.length === 1) {
      summary = `${siteNames[0]} `;
    }
  }
  return { siteNames, summary };
};

const MapSiteItemEntityToKeyValuePair = (response: SiteResponseEntity) => {
  if (response && response.items?.length > 0) {
    const result: KeyValuePair[] = response.items.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    });

    return result;
  }
};

const MapBinRangeEntitytoModel = (response: BinRangeEntity, siteListName: KeyValuePair[]) => {
  if (!!response) {
    return {
      id: response.id,
      organisationId: response.organisationId,
      name: response.name,
      description: response.description,
      type: response.type,
      binHigh: response.binHigh,
      binLow: response.binLow,
      access: response.access,
      cardType: response.cardType,
      cardTags: response.cardTags,
      cardProcessingRules: response?.cardProcessingRules?.map((item, i) => {
        const otherSites = response?.cardProcessingRules
          ?.filter((otherRule) => otherRule.sites !== item?.sites)
          .flatMap((otherRule) => otherRule.sites);
        const displaySites = siteListName?.filter((site) => !otherSites.includes(String(site.key)));
        let cachedCardsRequiredPrompts = [] as string[];
        let cachedCardsOptionalPrompts = [] as string[];
        let uncachedCardsRequiredPrompts = [] as string[];
        let uncachedCardsOptionalPrompts = [] as string[];

        if (item?.cachedCards?.prompts) {
          Object.entries(item?.cachedCards?.prompts).forEach((it) => {
            if (it[1].enabled === true) {
              if (it[1].required === true) {
                cachedCardsRequiredPrompts.push(it[0]);
              } else {
                cachedCardsOptionalPrompts.push(it[0]);
              }
            }
          });
        }

        if (item?.uncachedCards?.prompts) {
          Object.entries(item?.uncachedCards?.prompts).forEach((it) => {
            if (it[1].enabled === true) {
              if (it[1].required === true) {
                uncachedCardsRequiredPrompts.push(it[0]);
              } else {
                uncachedCardsOptionalPrompts.push(it[0]);
              }
            }
          });
        }

        return {
          name: item.name,
          sites: item.sites,
          applyToAllSites: item?.applyToAllSites,
          cachedCardsMethod: item?.cachedCards?.method,
          cachedCardsOnlineEnabled: item?.cachedCards?.connectionMode?.online?.enabled,
          cachedCardsOnlineAuthorisationAmount:
            item?.cachedCards?.connectionMode?.online?.transactionLimits?.defaultAuthorisationAmount,
          cachedCardsOnlineAlternateAuthorisationAmount:
            item?.cachedCards?.connectionMode?.online?.transactionLimits?.alternateAuthorisationAmount,
          cachedCardsOfflineEnabled: item?.cachedCards?.connectionMode?.offline?.enabled,
          cachedCardsOfflineMaxTransactionsPerCardPerDay:
            item?.cachedCards?.connectionMode?.offline?.transactionLimits?.maximumTransactionsPerCardPerDay,
          cachedCardsOfflineAuthorisationAmount:
            item?.cachedCards?.connectionMode?.offline?.transactionLimits?.defaultAuthorisationAmount,
          cachedCardsOfflineAlternateAuthorisationAmount:
            item?.cachedCards?.connectionMode?.offline?.transactionLimits?.alternateAuthorisationAmount,
          cachedCardsRequiredPrompts: cachedCardsRequiredPrompts,
          avaliableCachedCardsRequiredPrompts: PromptTypes?.filter(
            (prompt) => !cachedCardsOptionalPrompts.includes(String(prompt.key))
          )?.filter(
            (promptItem) =>
              promptItem.key !== 'productConfirmation' &&
              !(item?.cachedCards?.method === 'compac' && promptItem.key === 'secondPump')
          ),
          cachedCardsOptionalPrompts: cachedCardsOptionalPrompts,
          avaliableCachedCardsOptionalPrompts: PromptTypes?.filter(
            (prompt) => !cachedCardsRequiredPrompts.includes(String(prompt.key))
          )?.filter((promptItem) => !(item?.cachedCards?.method === 'compac' && promptItem.key === 'secondPump')),
          uncachedCardsMethod: item?.uncachedCards?.method,
          uncachedCardsOnlineEnabled: item?.uncachedCards?.connectionMode?.online?.enabled,
          uncachedCardsOnlineAuthorisationAmount:
            item?.uncachedCards?.connectionMode?.online?.transactionLimits?.defaultAuthorisationAmount,
          uncachedCardsOnlineAlternateAuthorisationAmount:
            item?.uncachedCards?.connectionMode?.online?.transactionLimits?.alternateAuthorisationAmount,
          uncachedCardsOfflineEnabled: item?.uncachedCards?.connectionMode?.offline?.enabled,
          uncachedCardsOfflineAuthorisationAmount:
            item?.uncachedCards?.connectionMode?.offline?.transactionLimits?.defaultAuthorisationAmount,
          uncachedCardsOfflineAlternateAuthorisationAmount:
            item?.uncachedCards?.connectionMode?.offline?.transactionLimits?.alternateAuthorisationAmount,
          uncachedCardsRequiredPrompts: uncachedCardsRequiredPrompts,
          avaliableUncachedCardsRequiredPrompts: PromptTypes?.filter(
            (prompt) => !uncachedCardsOptionalPrompts.includes(String(prompt.key))
          )?.filter(
            (promptItem) =>
              promptItem.key !== 'productConfirmation' &&
              !(item?.uncachedCards?.method === 'compac' && promptItem.key === 'secondPump')
          ),
          uncachedCardsOptionalPrompts: uncachedCardsOptionalPrompts,
          avaliableUncachedCardsOptionalPrompts: PromptTypes?.filter(
            (prompt) => !uncachedCardsRequiredPrompts.includes(String(prompt.key))
          )?.filter((promptItem) => !(item?.uncachedCards?.method === 'compac' && promptItem.key === 'secondPump')),
          displaySelectAllSiteOption: item?.applyToAllSites
            ? true
            : displaySites?.length === siteListName?.length
              ? true
              : false,
          displaySiteOption: displaySites,
          merchantId: item?.merchantDetails?.wex?.merchantId,
          terminalId: item?.merchantDetails?.wex?.terminalId,
        } as CardProcessingRuleModel;
      }),
    } as BinRangeModel;
  }
  return {} as BinRangeModel;
};

function MapBinRangeModelToEntity(payload: BinRangeModel, organisationId: string) {
  if (!!payload) {
    return {
      id: fieldMappingHelper.sanitizeStringValue(payload.id),
      organisationId: fieldMappingHelper.sanitizeStringValue(organisationId),
      name: fieldMappingHelper.sanitizeStringValue(payload.name),
      description: fieldMappingHelper.sanitizeStringValue(payload.description),
      type: fieldMappingHelper.sanitizeStringDropDownValue(payload.type),
      binHigh: fieldMappingHelper.sanitizeStringValue(payload.binHigh),
      binLow: fieldMappingHelper.sanitizeStringValue(payload.binLow),
      access: fieldMappingHelper.sanitizeStringValue(payload.access),
      cardType: fieldMappingHelper.sanitizeStringDropDownValue(payload.cardType),
      cardTags: payload?.cardTags?.filter((it) =>
        fieldMappingHelper.sanitizeStringWithoutEmptySpaceBeforeOrAfter(it?.key)
      ),
      cardProcessingRules: payload?.cardProcessingRules?.map((item, i) => {
        let cachedCardsOdometerPrompt: Prompt = {} as Prompt;
        let cachedCardsEngineHoursPrompt: Prompt = {} as Prompt;
        let cachedCardsAuthAmountPrompt: Prompt = {} as Prompt;
        let cachedCardsOrderNumberPrompt: Prompt = {} as Prompt;
        let cachedCardsVehicleNumberPrompt: Prompt = {} as Prompt;
        let cachedCardsPinPrompt: Prompt = {} as Prompt;
        let cachedCardsProdConfirmationPrompt: Prompt = {} as Prompt;
        let cachedCardsSecondPumpPrompt: Prompt = {} as Prompt;

        let uncachedCardsOdometerPrompt: Prompt = {} as Prompt;
        let uncachedCardsEngineHoursPrompt: Prompt = {} as Prompt;
        let uncachedCardsAuthAmountPrompt: Prompt = {} as Prompt;
        let uncachedCardsOrderNumberPrompt: Prompt = {} as Prompt;
        let uncachedCardsVehicleNumberPrompt: Prompt = {} as Prompt;
        let uncachedCardsPinPrompt: Prompt = {} as Prompt;
        let uncachedCardsProdConfirmationPrompt: Prompt = {} as Prompt;
        let uncachedCardsSecondPumpPrompt: Prompt = {} as Prompt;

        const cachedCardEnabledPrompts = item?.cachedCardsRequiredPrompts?.concat(item?.cachedCardsOptionalPrompts);
        if (cachedCardEnabledPrompts?.length > 0) {
          if (
            cachedCardEnabledPrompts?.includes('odometer') &&
            item?.cachedCardsRequiredPrompts?.includes('odometer')
          ) {
            cachedCardsOdometerPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('odometer') &&
            item?.cachedCardsOptionalPrompts?.includes('odometer')
          ) {
            cachedCardsOdometerPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            cachedCardEnabledPrompts?.includes('engineHours') &&
            item?.cachedCardsRequiredPrompts?.includes('engineHours')
          ) {
            cachedCardsEngineHoursPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('engineHours') &&
            item?.cachedCardsOptionalPrompts?.includes('engineHours')
          ) {
            cachedCardsEngineHoursPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            cachedCardEnabledPrompts?.includes('authorisationAmount') &&
            item?.cachedCardsRequiredPrompts?.includes('authorisationAmount')
          ) {
            cachedCardsAuthAmountPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('authorisationAmount') &&
            item?.cachedCardsOptionalPrompts?.includes('authorisationAmount')
          ) {
            cachedCardsAuthAmountPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            cachedCardEnabledPrompts?.includes('orderNumber') &&
            item?.cachedCardsRequiredPrompts?.includes('orderNumber')
          ) {
            cachedCardsOrderNumberPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('orderNumber') &&
            item?.cachedCardsOptionalPrompts?.includes('orderNumber')
          ) {
            cachedCardsOrderNumberPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            cachedCardEnabledPrompts?.includes('vehicleNumber') &&
            item?.cachedCardsRequiredPrompts?.includes('vehicleNumber')
          ) {
            cachedCardsVehicleNumberPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('vehicleNumber') &&
            item?.cachedCardsOptionalPrompts?.includes('vehicleNumber')
          ) {
            cachedCardsVehicleNumberPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (cachedCardEnabledPrompts?.includes('pin') && item?.cachedCardsRequiredPrompts?.includes('pin')) {
            cachedCardsPinPrompt = {
              enabled: true,
              required: true,
            };
          } else if (cachedCardEnabledPrompts?.includes('pin') && item?.cachedCardsOptionalPrompts?.includes('pin')) {
            cachedCardsPinPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            cachedCardEnabledPrompts?.includes('productConfirmation') &&
            item?.cachedCardsRequiredPrompts?.includes('productConfirmation')
          ) {
            cachedCardsProdConfirmationPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('productConfirmation') &&
            item?.cachedCardsOptionalPrompts?.includes('productConfirmation')
          ) {
            cachedCardsProdConfirmationPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            cachedCardEnabledPrompts?.includes('secondPump') &&
            item?.cachedCardsRequiredPrompts?.includes('secondPump')
          ) {
            cachedCardsSecondPumpPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            cachedCardEnabledPrompts?.includes('secondPump') &&
            item?.cachedCardsOptionalPrompts?.includes('secondPump')
          ) {
            cachedCardsSecondPumpPrompt = {
              enabled: true,
              required: false,
            };
          }
        }

        const uncachedCardEnabledPrompts = item?.uncachedCardsRequiredPrompts?.concat(
          item?.uncachedCardsOptionalPrompts
        );

        if (uncachedCardEnabledPrompts?.length > 0) {
          if (
            uncachedCardEnabledPrompts?.includes('odometer') &&
            item?.uncachedCardsRequiredPrompts?.includes('odometer')
          ) {
            uncachedCardsOdometerPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('odometer') &&
            item?.uncachedCardsOptionalPrompts?.includes('odometer')
          ) {
            uncachedCardsOdometerPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            uncachedCardEnabledPrompts?.includes('engineHours') &&
            item?.uncachedCardsRequiredPrompts?.includes('engineHours')
          ) {
            uncachedCardsEngineHoursPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('engineHours') &&
            item?.uncachedCardsOptionalPrompts?.includes('engineHours')
          ) {
            uncachedCardsEngineHoursPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            uncachedCardEnabledPrompts?.includes('authorisationAmount') &&
            item?.uncachedCardsRequiredPrompts?.includes('authorisationAmount')
          ) {
            uncachedCardsAuthAmountPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('authorisationAmount') &&
            item?.uncachedCardsOptionalPrompts?.includes('authorisationAmount')
          ) {
            uncachedCardsAuthAmountPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            uncachedCardEnabledPrompts?.includes('orderNumber') &&
            item?.uncachedCardsRequiredPrompts?.includes('orderNumber')
          ) {
            uncachedCardsOrderNumberPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('orderNumber') &&
            item?.uncachedCardsOptionalPrompts?.includes('orderNumber')
          ) {
            uncachedCardsOrderNumberPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            uncachedCardEnabledPrompts?.includes('vehicleNumber') &&
            item?.uncachedCardsRequiredPrompts?.includes('vehicleNumber')
          ) {
            uncachedCardsVehicleNumberPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('vehicleNumber') &&
            item?.uncachedCardsOptionalPrompts?.includes('vehicleNumber')
          ) {
            uncachedCardsVehicleNumberPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (uncachedCardEnabledPrompts?.includes('pin') && item?.uncachedCardsRequiredPrompts?.includes('pin')) {
            uncachedCardsPinPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('pin') &&
            item?.uncachedCardsOptionalPrompts?.includes('pin')
          ) {
            uncachedCardsPinPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            uncachedCardEnabledPrompts?.includes('productConfirmation') &&
            item?.uncachedCardsRequiredPrompts?.includes('productConfirmation')
          ) {
            uncachedCardsProdConfirmationPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('productConfirmation') &&
            item?.uncachedCardsOptionalPrompts?.includes('productConfirmation')
          ) {
            uncachedCardsProdConfirmationPrompt = {
              enabled: true,
              required: false,
            };
          }

          if (
            uncachedCardEnabledPrompts?.includes('secondPump') &&
            item?.uncachedCardsRequiredPrompts?.includes('secondPump')
          ) {
            uncachedCardsSecondPumpPrompt = {
              enabled: true,
              required: true,
            };
          } else if (
            uncachedCardEnabledPrompts?.includes('secondPump') &&
            item?.uncachedCardsOptionalPrompts?.includes('secondPump')
          ) {
            uncachedCardsSecondPumpPrompt = {
              enabled: true,
              required: false,
            };
          }
        }

        let cachedCardsPromptsOnList = {
          ...(cachedCardsOdometerPrompt &&
            cachedCardsOdometerPrompt.enabled && { odometer: cachedCardsOdometerPrompt }),
          ...(cachedCardsEngineHoursPrompt &&
            cachedCardsEngineHoursPrompt.enabled && { engineHours: cachedCardsEngineHoursPrompt }),
          ...(cachedCardsAuthAmountPrompt &&
            cachedCardsAuthAmountPrompt.enabled && { authorisationAmount: cachedCardsAuthAmountPrompt }),
          ...(cachedCardsOrderNumberPrompt &&
            cachedCardsOrderNumberPrompt.enabled && { orderNumber: cachedCardsOrderNumberPrompt }),
          ...(cachedCardsVehicleNumberPrompt &&
            cachedCardsVehicleNumberPrompt.enabled && { vehicleNumber: cachedCardsVehicleNumberPrompt }),
          ...(cachedCardsPinPrompt && cachedCardsPinPrompt.enabled && { pin: cachedCardsPinPrompt }),
          ...(cachedCardsProdConfirmationPrompt &&
            cachedCardsProdConfirmationPrompt.enabled && {
              productConfirmation: cachedCardsProdConfirmationPrompt,
            }),
          ...(cachedCardsSecondPumpPrompt &&
            cachedCardsSecondPumpPrompt.enabled && { secondPump: cachedCardsSecondPumpPrompt }),
        };

        let uncachedCardsPromptsOnList = {
          ...(uncachedCardsOdometerPrompt &&
            uncachedCardsOdometerPrompt.enabled && { odometer: uncachedCardsOdometerPrompt }),
          ...(uncachedCardsEngineHoursPrompt &&
            uncachedCardsEngineHoursPrompt.enabled && { engineHours: uncachedCardsEngineHoursPrompt }),
          ...(uncachedCardsAuthAmountPrompt &&
            uncachedCardsAuthAmountPrompt.enabled && { authorisationAmount: uncachedCardsAuthAmountPrompt }),
          ...(uncachedCardsOrderNumberPrompt &&
            uncachedCardsOrderNumberPrompt.enabled && { orderNumber: uncachedCardsOrderNumberPrompt }),
          ...(uncachedCardsVehicleNumberPrompt &&
            uncachedCardsVehicleNumberPrompt.enabled && { vehicleNumber: uncachedCardsVehicleNumberPrompt }),
          ...(uncachedCardsPinPrompt && uncachedCardsPinPrompt.enabled && { pin: uncachedCardsPinPrompt }),
          ...(uncachedCardsProdConfirmationPrompt &&
            uncachedCardsProdConfirmationPrompt.enabled && {
              productConfirmation: uncachedCardsProdConfirmationPrompt,
            }),
          ...(uncachedCardsSecondPumpPrompt &&
            uncachedCardsSecondPumpPrompt.enabled && { secondPump: uncachedCardsSecondPumpPrompt }),
        };

        const merchantDetails = {
          wex: {
            merchantId: item?.merchantId,
            terminalId: item?.terminalId,
          } as WexMerchantDetailsEntity,
        } as MerchantDetailsEntity;

        return {
          name: fieldMappingHelper.sanitizeStringValue(item?.name),
          sites: item?.applyToAllSites ? undefined : item?.sites,
          applyToAllSites: item?.sites && item?.sites?.length > 0 ? false : item?.applyToAllSites,
          cachedCards:
            item?.cachedCardsMethod && payload.cardType !== 'wex'
              ? ({
                  method: fieldMappingHelper.sanitizeStringDropDownValue(item?.cachedCardsMethod),
                  ...(cachedCardsPromptsOnList &&
                    cachedCardsPromptsOnList !== null && { prompts: cachedCardsPromptsOnList }),
                  connectionMode: {
                    online: {
                      enabled: item?.cachedCardsOnlineEnabled ? item?.cachedCardsOnlineEnabled : false,
                      ...((item?.cachedCardsOnlineEnabled || item?.cachedCardsOnlineAlternateAuthorisationAmount) && {
                        transactionLimits: {
                          defaultAuthorisationAmount: fieldMappingHelper.sanitizeNumericValue(
                            item?.cachedCardsOnlineAuthorisationAmount
                          ),
                          alternateAuthorisationAmount: fieldMappingHelper.sanitizeStringValueFromDropdown(
                            item?.cachedCardsOnlineAlternateAuthorisationAmount
                          ),
                        },
                      }),
                    },
                    offline: {
                      enabled: item?.cachedCardsOfflineEnabled ? item?.cachedCardsOfflineEnabled : false,
                      ...((item?.cachedCardsOfflineEnabled || item?.cachedCardsOfflineAlternateAuthorisationAmount) && {
                        transactionLimits: {
                          defaultAuthorisationAmount: fieldMappingHelper.sanitizeNumericValue(
                            item?.cachedCardsOfflineAuthorisationAmount
                          ),
                          maximumTransactionsPerCardPerDay: item?.cachedCardsOfflineMaxTransactionsPerCardPerDay,
                          alternateAuthorisationAmount: fieldMappingHelper.sanitizeStringValueFromDropdown(
                            item?.cachedCardsOfflineAlternateAuthorisationAmount
                          ),
                        },
                      }),
                    },
                  },
                } as CachedCardsEntity)
              : undefined,

          uncachedCards: item?.uncachedCardsMethod
            ? ({
                method: fieldMappingHelper.sanitizeStringDropDownValue(item?.uncachedCardsMethod),
                ...(uncachedCardsPromptsOnList &&
                  uncachedCardsPromptsOnList !== null && { prompts: uncachedCardsPromptsOnList }),
                connectionMode: {
                  online: {
                    enabled: item?.uncachedCardsOnlineEnabled ? item?.uncachedCardsOnlineEnabled : false,
                    ...((item?.uncachedCardsOnlineEnabled || item?.uncachedCardsOnlineAlternateAuthorisationAmount) && {
                      transactionLimits: {
                        defaultAuthorisationAmount: fieldMappingHelper.sanitizeNumericValue(
                          item?.uncachedCardsOnlineAuthorisationAmount
                        ),
                        alternateAuthorisationAmount: fieldMappingHelper.sanitizeStringValueFromDropdown(
                          item?.uncachedCardsOnlineAlternateAuthorisationAmount
                        ),
                      },
                    }),
                  },
                  offline: {
                    enabled:
                      payload.cardType === 'wex'
                        ? false
                        : item?.uncachedCardsOfflineEnabled
                          ? item?.uncachedCardsOfflineEnabled
                          : false,
                    ...((item?.uncachedCardsOfflineEnabled ||
                      item?.uncachedCardsOfflineAlternateAuthorisationAmount) && {
                      transactionLimits: {
                        defaultAuthorisationAmount: fieldMappingHelper.sanitizeNumericValue(
                          item?.uncachedCardsOfflineAuthorisationAmount
                        ),
                        alternateAuthorisationAmount: fieldMappingHelper.sanitizeStringValueFromDropdown(
                          item?.uncachedCardsOfflineAlternateAuthorisationAmount
                        ),
                      },
                    }),
                  },
                },
              } as UnCachedCardsEntity)
            : undefined,

          merchantDetails: payload.cardType === 'wex' ? merchantDetails : undefined,
        } as CardProcessingRuleEntity;
      }),
    } as BinRangeEntity;
  }
  return {} as BinRangeEntity;
}
