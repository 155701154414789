import React, { useState } from 'react';
import classNames from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { DownloadIcon } from '../icons';
import './checkbox.scss';

interface CustomCheckBoxProps {
  label?: string;
  description?: string;
  defaultState?: boolean;
  name?: string;
  className?: string;
  isChecked?: boolean;
  readOnly?: boolean;
  onBindingValue?: (newvalue: KeyValuePair) => void | undefined;
  onChecked?: (value: boolean) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckBox = (props: CustomCheckBoxProps) => {
  const {
    label,
    description,
    name,
    className,
    defaultState = false,
    isChecked,
    readOnly,
    onBindingValue,
    onChecked,
    onChange,
  } = props;
  const [checked, setChecked] = useState(defaultState);

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked);
    if (onBindingValue) {
      onBindingValue({
        key: event.target.name,
        value: event.target.checked,
      });
    }
    if (onChecked) {
      onChecked(checked);
    }
  };

  return (
    <React.Fragment>
      {name === 'downloadCert' ? (
        <div className={classNames('download-checkbox-container', className)}>
          <div className={classNames('checkbox-label', className)}>{label}</div>
          <div className='checkbox-control-container'>
            <div className='checkbox-control-file'>
              <div className={'checkbox-file'}>
                <DownloadIcon className='icon-color' />
              </div>
              <div className='checkbox-filename'>Provisioning File</div>
            </div>
            <div className='checkbox-control-desc'>
              <Checkbox
                className='control'
                onChange={onChangeValue}
                checked={checked}
                name={name}
                disabled={readOnly}
                disableRipple
              />
              <div className='description'>{description}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames('checkbox-container', className)}>
          <Checkbox
            className='control'
            onChange={onChange}
            checked={isChecked}
            name={name}
            disabled={readOnly}
            disableRipple
          />
          <span className={classNames('checkbox-label', className)}>{label}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomCheckBox;
