import classNames from 'classnames';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PriceSchedListErrorModel, PriceScheduleInfoModel } from '../../models/priceScheduleModel';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from '../../constants/loading-constants';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import EmptyList from '../../components/empty-list/empty-list';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import FooterBar from '../../components/footer/footer';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import MessageDisplay from '../../components/message-display/message-display';
import PriceSchedulesItem from './price-schedules-item';

interface PriceScheduledProps {
  priceScheduleStatus: string;
  priceScheduleContent: PriceScheduleInfoModel[];
  priceScheduleListStatus: PriceSchedListErrorModel[];
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadPriceSchedules: () => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  deletePriceScheduleItem: (data: string) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const PriceSchedulesList: React.FC<PriceScheduledProps> = (props: PriceScheduledProps) => {
  const {
    priceScheduleContent,
    priceScheduleStatus,
    priceScheduleListStatus,
    authStatus,
    userAccess,
    loadPriceSchedules,
    setHeaderConfiguration,
    deletePriceScheduleItem,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const navigate = useNavigate();
  const { orgId } = useParams();
  const isLoadPriceSchedulesRequested = useRef(false);
  const [selectedPriceScheduleId, setSelectedPriceScheduleId] = useState('');
  const [deleteContext, setDeleteContent] = useState('');

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.PRICE_SCHEDULE).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(priceScheduleStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(priceScheduleStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(priceScheduleStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, priceScheduleStatus]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Price Schedules',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: true,
      error: error,
      pageURL: 'price-schedules',
    } as HeaderStateModel);
  }, [authSuccess, setHeaderConfiguration, hasCreateAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (!isLoadPriceSchedulesRequested.current && authSuccess && hasReadAccess) {
      loadPriceSchedules();
      isLoadPriceSchedulesRequested.current = true;
    }
  }, [authSuccess, hasReadAccess, loadPriceSchedules]);

  const onClickHandler = (priceScheduleId: string) => {
    navigate(`/organisations/${orgId}/price-schedules/${priceScheduleId}/details`);
  };

  const onEditHandler = (priceScheduleId: string) => {
    navigate(`/organisations/${orgId}/price-schedules/${priceScheduleId}/details/edit`);
  };
  const confirmDialog = () => {
    deletePriceScheduleItem(selectedPriceScheduleId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  const onDeleteHandler = useCallback(
    (priceScheduleId: string, name: string) => {
      setSelectedPriceScheduleId(priceScheduleId);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Price Schedule'
        />
      }
      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}
      {error && <ErrorDisplayControl />}
      {
        <div className='price-schedule-list'>
          {success && priceScheduleContent && (
            <>
              <div>
                {priceScheduleContent.map((item) => (
                  <PriceSchedulesItem
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    activationDatetimeUtc={item.activationDateTimeUtc}
                    sites={item.sites.map((it) => {
                      return it;
                    })}
                    sitesInfo={item.sitesInfo}
                    products={item.products}
                    onEdit={onEditHandler}
                    onDelete={onDeleteHandler}
                    onClick={onClickHandler}
                    hasDeleteAccess={hasDeleteAccess}
                    hasUpdateAccess={hasUpdateAccess}
                  ></PriceSchedulesItem>
                ))}
                {
                  <div>
                    {!!priceScheduleListStatus && priceScheduleContent.length <= 0 && (
                      <EmptyList message='No price schedules found'></EmptyList>
                    )}
                  </div>
                }
              </div>
            </>
          )}
          {
            <div className='for-mobile'>
              <FooterBar
                className={classNames(
                  !!priceScheduleContent && priceScheduleContent?.length < 1
                    ? 'footer-no-content'
                    : 'footer-with-content'
                )}
              />
            </div>
          }
        </div>
      }
    </>
  );
};
export default PriceSchedulesList;
