import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectUser } from '../../store/users/selectors';
import UserItem from './users-item';

const mapStateToProps = (state: RootState) => {
  return {
    loadUser: (userId: string) => selectUser(userId)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};
const UserItemComponent = connect(mapStateToProps, mapDispatchToProps)(UserItem);

export default UserItemComponent;
