    import { RootState } from './../rootReducers';
    import { createSelector } from 'reselect';

export const selectPumpState = (state:RootState) => state.PumpState;

export const selectPumpContent = createSelector(
    selectPumpState,
    state => state.pumpContent
);

export const selectPumpStatus = createSelector(
    selectPumpState,
    state => state.pumpStatus
);

export const selectPumpDetailStatus = createSelector(
    selectPumpState,
    state => state.pumpDetailStatus
);

export const selectPumpInfo = createSelector(
    selectPumpState,
    state => state.pumpInfo
);

export const selectPumpNumberList = createSelector(
    selectPumpState,
    state => state.pumpNumberList
  )
  