import { createSelector } from 'reselect';
import { RootState } from '../rootReducers';

export const selectLocalState = (state: RootState) => state.PermissionGroupState;

export const selectPermissionGroupContent = createSelector(
    selectLocalState,
    state => state.permissionGroupContent
);

export const selectPermissionGroupStatus = createSelector(
    selectLocalState,
    state => state.permissionGroupStatus
);

export const selectPermissionGroupItem = createSelector(
    selectLocalState,
    state => state.permissionGroupItem
);

export const selectPermissionGroupNameList = createSelector(
    selectLocalState,
    state => state.permissionGroupNameList
);


