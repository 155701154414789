import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';
import { AddressDetailsModel } from '../../models/siteModel';

export const selectAzureMapState = (state: RootState) => state.AzureMapState;

export const selectAddressList = createSelector(selectAzureMapState, (state) => {
  let addressList = state.addressList;
  if (addressList === null || addressList === undefined) {
    addressList = [];
  }
  return addressList;
});

export const selectSelectedAddressTimezone = createSelector(selectAzureMapState, (state) => {
  let timezone = state.selectedAddressTimezone;
  if (timezone === null || timezone === undefined) {
    timezone = '';
  }
  return timezone;
});

export const selectSelectedAddress = createSelector(selectAzureMapState, (state) => {
  let address = state.selectedAddress;
  if (address === null || address === undefined) {
    address = {} as AddressDetailsModel;
  }
  return address;
});
