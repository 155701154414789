import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import { AlertRuleModel } from '../../models/alertRuleModel';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { moreDataDisplayInListHandler, tooltipDisplayInListHandler } from '../../utilities/general-helper';
import Item from '../../components/box-items/box-item';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import LoadingSkeleton from '../../components/loading-skeleton/loading-skeleton';
import './styles/alert-rule-item.scss';

interface AlertRuleItemProps {
  item: AlertRuleModel;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClickHandler: (id: string) => void;
}

const AlertRuleItem: React.FC<AlertRuleItemProps> = (props: AlertRuleItemProps) => {
  const { item, hasDeleteAccess, hasUpdateAccess, onDelete, onEdit, onClickHandler } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let actionItems: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit Rule',
      handler: () => {
        onEdit(item.id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete Rule',
      handler: () => {
        onDelete(item.id, item.name);
      },
    });
  }
  const onItemClick = () => {
    if (onClickHandler) {
      onClickHandler(item.id);
    }
  };

  const triggerListDisplay: string = moreDataDisplayInListHandler(item?.triggerList);
  const moreTriggerListDisplay: string = tooltipDisplayInListHandler(item?.triggerList);

  const siteListDisplay: string = moreDataDisplayInListHandler(item?.siteList);
  const moreSiteListDisplay: string = tooltipDisplayInListHandler(item?.siteList);

  const notificationListDisplay: string = moreDataDisplayInListHandler(item?.notificationList);
  const moreNotificationListDisplay: string = tooltipDisplayInListHandler(item?.notificationList);

  return (
    <>
      <Card className={classNames('box-card', 'box-grid')}>
        <Box className='alert-rule-card' onClick={onItemClick}>
          <Item className='box-main'>{item.name}</Item>
          <Item className='box-main hidden-mobile'>
            <Item
              className={classNames(
                'box-health-icon',
                item.enabled ? 'alert-rule-enabled-icon' : 'alert-rule-disabled-icon'
              )}
            ></Item>
            <span className='box-child'>&nbsp;{item.enabled ? 'Enabled' : 'Disabled'}</span>
          </Item>
          <Item className='box-child hidden-mobile'></Item>
          <Item className='box-child alert-rule-triggers'>
            {!triggerListDisplay && <LoadingSkeleton customWidth={200} />}
            {triggerListDisplay}{' '}
            {item.triggerList.length > 2 && (
              <CustomTooltip title={moreTriggerListDisplay} textDisplay={`${item.triggerList?.length - 2} more`} />
            )}
          </Item>
          <Item className='box-child hidden-mobile'></Item>
          <Item className='box-child hidden-mobile'>
            {item.applyToAllSites ? (
              'All'
            ) : (
              <>
                {!siteListDisplay && <LoadingSkeleton customWidth={150} />}
                {siteListDisplay}{' '}
                {item.siteList.length > 2 && (
                  <CustomTooltip title={moreSiteListDisplay} textDisplay={`${item.siteList?.length - 2} more`} />
                )}
              </>
            )}
          </Item>
          <Item className='box-child hidden-mobile'>
            {!notificationListDisplay && <LoadingSkeleton customWidth={160} />}
            {notificationListDisplay}{' '}
            {item.notificationList.length > 2 && (
              <CustomTooltip
                title={moreNotificationListDisplay}
                textDisplay={`${item.notificationList?.length - 2} more`}
              />
            )}
          </Item>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu key={item.id} buttonNode={<MoreVertIcon />} items={actionItems} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </>
  );
};

export default AlertRuleItem;
