import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import { PermissionGroupItemsModel } from '../../models/permissionGroupModel';
import { initialState } from './permissionGroupInitState';

const permissionGroupSlice = createSlice({
    name: 'permmissionGroupState',
    initialState,
    reducers:{
        setPermissionGroupStatus: (state, action: PayloadAction<string>) => {
            state.permissionGroupStatus = action.payload;
            state.hasError = false;
        },
        setPermissionGroupItem: (state, action: PayloadAction<PermissionGroupItemsModel>) => {
            state.permissionGroupItem = action.payload;
            state.hasError = false;
        },
        setPermissionGroupContent: (state, action: PayloadAction<PermissionGroupItemsModel[]>) => {
            state.permissionGroupContent = action.payload;
            state.hasError = false;
        },
        setPermissionGroupError: (state) => {
            state.hasError = true;
            state.permissionGroupContent = [];
            state.permissionGroupStatus = LoadingStatus.ERROR;
        },
        setPermissionGroupListName: (state, action: PayloadAction<KeyValuePair[]>) => {
            state.permissionGroupNameList = action.payload;
        },
       
    }
});

export const {
    setPermissionGroupStatus,
    setPermissionGroupItem,
    setPermissionGroupContent,
    setPermissionGroupError,
    setPermissionGroupListName,
} = permissionGroupSlice.actions;

export default permissionGroupSlice.reducer;
