import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import PageNotFound from './index';
import * as headerActions from '../../store/header/actions';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { RootState } from '../../store/rootReducers';
import * as pageConfigActions from '../../store/page-configuration/actions';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';

const mapStateToProps = (state:RootState) => {
    return {}
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),        
      setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
      setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const PageNotFoundPage = connect(mapStateToProps,mapDispatchToProps)(PageNotFound);

export default PageNotFoundPage;
