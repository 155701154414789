import { AccountInfo } from '@azure/msal-browser';
import { Box, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Card from '../../components/cards/cards';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import { LoadingStatus } from '../../constants/loading-constants';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { UserModel } from '../../models/usersModel';
import Item from '../../components/box-items/box-item';
import { UserPermissionModel } from '../../models/userPermissionModel';
import FooterBar from '../../components/footer/footer';
import './styles/myaccount.scss';
import CustomButton from '../../components/button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import EmptyList from '../../components/empty-list/empty-list';
import SwitchList from '../../components/switch-list/switch-list';
import MyAccountModalContainer from './child-modal/my-account-modal.container';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { ModalType } from '../../constants/modal-constants';
import Avatar from '../../components/avatar/avatar';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { checkIsTextEllipsisWithDecimal } from '../../utilities/general-helper';

interface MyAccountProps {
  userInfo: UserModel;
  userStatus: string;
  userAccount: AccountInfo;
  userPermissions: UserPermissionModel[];
  loadMyAccount: (data?: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openModal: (data: ModalStateModel) => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const MyAccount: React.FC<MyAccountProps> = (props: MyAccountProps) => {
  const {
    userInfo,
    userStatus,
    userAccount,
    userPermissions,
    loadMyAccount,
    setHeaderConfiguration,
    openModal,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isUserNameTextEllipsis, setIsUserNameTextEllipsis] = useState<boolean>(false);
  const UserNameText = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    updateTooltipDisplay();
  }, [userInfo?.firstName]);

  useMemo(() => {
    setLoading(userStatus === LoadingStatus.LOADING);
    setSuccess(userStatus === LoadingStatus.SUCCESS);
    setError(userStatus === LoadingStatus.ERROR);
  }, [userStatus]);

  const updateTooltipDisplay = () => {
    if (checkIsTextEllipsisWithDecimal(UserNameText.current)) {
      setIsUserNameTextEllipsis(true);
    } else {
      setIsUserNameTextEllipsis(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateTooltipDisplay);
    return () => window.removeEventListener('resize', updateTooltipDisplay);
  }, []);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'My Account',
      showOrganisation: true,
      showCreateButton: false,
      showSiteHeader: false,
      showInfoButton: false,
      showAccountOption: true,
      error: error,
    } as HeaderStateModel);
  }, [error, setHeaderConfiguration]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  useEffect(() => {
    if (!!userAccount) {
      loadMyAccount(userAccount?.idTokenClaims?.sub ? userAccount?.idTokenClaims?.sub : undefined);
    }
  }, [loadMyAccount, userAccount]);

  const onEditHandler = useCallback(() => {
    openModal({
      type: ModalType.EDIT,
      dataId: userAccount?.idTokenClaims?.sub ? userAccount?.idTokenClaims?.sub : undefined,
    } as ModalStateModel);
  }, [openModal, userAccount]);

  const Modal = useMemo(() => {
    return <MyAccountModalContainer />;
  }, []);

  const permissionlist = useMemo(() => {
    return <SwitchList items={userPermissions} readOnly={true} showSwitch={false} />;
  }, [userPermissions]);

  return (
    <>
      {loading && <LoadingDisplay />}
      {error && <ErrorDisplayControl />}
      {<>{Modal}</>}
      {success && (
        <div>
          <Box className='my-account-sub-header'>
            <label>Profile</label>
          </Box>
          <Card className={classNames('profile-card')}>
            <Box className='profile-box'>
              <Item>
                <div className='avatar-container'>
                  <Avatar
                    value={userInfo?.firstName + userInfo?.lastName}
                    firstName={userInfo?.firstName}
                    lastName={userInfo?.lastName}
                    size={60}
                  />
                </div>
              </Item>
              <Tooltip
                title={isUserNameTextEllipsis ? `${userInfo?.firstName} ${userInfo?.lastName}` : ''}
                disableFocusListener
                describeChild
                followCursor
              >
                <div className={classNames('profile-text', 'profile-text-name')} ref={UserNameText}>
                  {userInfo?.firstName} {userInfo?.lastName}
                </div>
              </Tooltip>
              <Item>
                <CustomButton className='profile-edit-btn' buttonStyle={ButtonStyle.PRIMARY} onClick={onEditHandler}>
                  {'Edit'}
                </CustomButton>
              </Item>
            </Box>
            <Box className={classNames('box-line')} />
            <Box className='profile-box-detail'>
              <Item className={classNames('profile-text', 'profile-text-label')}>Email</Item>
              <Item className={classNames('profile-text', 'profile-text-value', 'profile-text-mobile')}>
                {userInfo?.email}
              </Item>
            </Box>
          </Card>
          <Box className='my-account-sub-header'>
            <label>Permissions</label>
          </Box>
          {!!userPermissions && userPermissions.length < 1 && <EmptyList message='No permissions found'></EmptyList>}
          {!!userPermissions && userPermissions?.length > 0 && (
            <Card className='permission-list-card'>{permissionlist}</Card>
          )}
        </div>
      )}
      {
        <div className='for-mobile'>
          <FooterBar className={classNames(!!userAccount ? 'footer-no-content' : 'footer-with-content')} />
        </div>
      }
    </>
  );
};
export default MyAccount;
