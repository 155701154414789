import React from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import BoxItem from '../../components/box-items/box-item';
import CustomTooltip from '../../components/tooltip/custom-tooltip';
import LoadingSkeleton from '../../components/loading-skeleton/loading-skeleton';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { BinRangeInfoModel } from '../../models/receiptTemplateModel';
import {
  moreDataDisplayInListNameModelHandler,
  tooltipDisplayInListNameModelHandler,
} from '../../utilities/general-helper';
import './styles/receipt-templates.scss';

interface ReceiptTemplateItemProps {
  id: string;
  name: string;
  binRangeInfos: BinRangeInfoModel[];
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string) => void;
  onClick: (id: string) => void;
}

const ReceiptTemplateItem: React.FC<ReceiptTemplateItemProps> = (props: ReceiptTemplateItemProps) => {
  const { id, name, binRangeInfos, hasDeleteAccess, hasUpdateAccess, onDelete, onEdit, onClick } = props;

  const displayMenu: boolean = hasDeleteAccess || hasUpdateAccess;

  let items: FloatingMenuItem[] = [];

  if (hasUpdateAccess) {
    items.push({
      label: 'Edit Receipt Template',
      handler: () => {
        onEdit(id);
      },
    });
  }

  if (hasDeleteAccess) {
    items.push({
      label: 'Delete Receipt Template',
      handler: () => {
        onDelete(id, name);
      },
    });
  }

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const allowedBinRangesDisplay: string = moreDataDisplayInListNameModelHandler(binRangeInfos);
  const moreAllowedSitesInfo: string = tooltipDisplayInListNameModelHandler(binRangeInfos);

  return (
    <>
      <Card className={classNames('box-grid')}>
        <Box className='receipt-tamplate-card' onClick={onItemClick}>
          <BoxItem className='name'>{name}</BoxItem>
          <BoxItem className='bin-ranges'>
            <>
              {!allowedBinRangesDisplay && <LoadingSkeleton customWidth={150} />}
              {allowedBinRangesDisplay}{' '}
              {binRangeInfos?.length > 2 && (
                <CustomTooltip title={moreAllowedSitesInfo} textDisplay={`${binRangeInfos?.length - 2} more`} />
              )}
            </>
          </BoxItem>
        </Box>
        {displayMenu ? (
          <Item className={classNames('ellipsis')}>
            <FloatingMenu key={id} buttonNode={<MoreVertIcon />} items={items} />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Card>
    </>
  );
};

export default ReceiptTemplateItem;
