import React, { useState } from 'react';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TerminalDetailModel, TerminalRecordModel } from '../../models/terminalModel';
import CollapsibleCard from '../../components/collapsible-card/collapsible-card';
import classNames from 'classnames';
import Card from '../../components/cards/cards';
import Item from '../../components/box-items/box-item';
import FloatingMenu from '../../components/floating-menu/floating-menu';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import { ItemDetailModel } from '../../models/baseModels/itemDetailModel';
import { HealthStatusDisplayType } from '../../constants/status';
import {
  BatteryDisplayType,
  CardReaderType,
  DoorStatusType,
  EmergencyStopStatusType,
  PrinterStatusType,
} from '../../constants/terminal-constants';
import { getStatusMessageCount } from '../../utilities/general-helpers';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as datetimeHelper from '../../utilities/datetime-helper';
import './styles/terminal-item.scss';

interface TerminalItemProps {
  terminalItem: TerminalRecordModel;
  status: string;
  healthIndicator: string;
  hasDeleteAccess: boolean;
  hasUpdateAccess: boolean;
  hasTerminalFimwareReadAccess: boolean;
  hasOperationReadAccess: boolean;
  hasOperationFileUpdateAccess: boolean;
  hasOperationRestartAccess: boolean;
  hasOperationBootloaderRestartAccess: boolean;
  hasOperationMemoryDumpAccess: boolean;
  hasReenrolAccess: boolean;
  lastUpdatedDateTimeUtc: string;
  onDelete: (terminalItem: TerminalRecordModel) => void;
  onEdit: (terminalItem: TerminalRecordModel) => void;
  onClick: (terminalItem: TerminalRecordModel) => void;
  onViewFirmwareClick: (terminalItem: TerminalRecordModel) => void;
  onOperationFileUpload: (terminalItem: TerminalRecordModel) => void;
  onOperationRestart: (terminalItem: TerminalRecordModel) => void;
  onOperationBootloaderRestart: (terminalItem: TerminalRecordModel) => void;
  onOperationMemoryDump: (terminalItem: TerminalRecordModel) => void;
  onReenrol: (id: string) => void;
  onOperationViewAll: (terminalItem: TerminalRecordModel) => void;
  onWindcaveStatusClick: (terminalItem: TerminalRecordModel) => void;
}

const TerminalItem: React.FC<TerminalItemProps> = (props: TerminalItemProps) => {
  const {
    terminalItem,
    status,
    healthIndicator,
    hasDeleteAccess,
    hasUpdateAccess,
    hasTerminalFimwareReadAccess,
    hasOperationReadAccess,
    hasOperationFileUpdateAccess,
    hasOperationRestartAccess,
    hasOperationBootloaderRestartAccess,
    hasOperationMemoryDumpAccess,
    hasReenrolAccess,
    lastUpdatedDateTimeUtc,
    onDelete,
    onEdit,
    onClick,
    onViewFirmwareClick,
    onOperationFileUpload,
    onOperationRestart,
    onOperationBootloaderRestart,
    onOperationMemoryDump,
    onReenrol,
    onOperationViewAll,
    onWindcaveStatusClick,
  } = props;

  const displayMenu: boolean =
    hasDeleteAccess || hasUpdateAccess || hasTerminalFimwareReadAccess || hasOperationReadAccess;
  const [isSecondLevelActionMenu, setIsSecondLevelMenu] = useState(false);
  const [isWindcaveStatusHovered, setIsWindcaveStatusHovered] = useState(false);

  let actionItems: FloatingMenuItem[] = [];
  let secondLevelActionItems: FloatingMenuItem[] = [
    {
      label: 'Back',
      isClickBack: true,
      handler: () => setIsSecondLevelMenu(false),
    },
  ];
  let moreItemDetails: ItemDetailModel[] = [];

  const getlastStatusUpdateDetails = () => {
    return {
      name: 'Last Status Update',
      value: lastUpdatedDateTimeUtc ? lastUpdatedDateTimeUtc : 'Unspecified',
      status: status ? status : 'Unspecified',
      healthIndicator: 'display-none',
    };
  };

  let terminalDetails: TerminalDetailModel = {
    id: terminalItem?.id,
    name: 'Status',
    value: fieldMappingHelper.getDisplayValue(status, HealthStatusDisplayType),
    status: status,
    healthIndicator: healthIndicator,
  };

  const fixedItemDetails: TerminalDetailModel[] = [terminalDetails, getlastStatusUpdateDetails()];

  const handleMouseEnter = () => {
    setIsWindcaveStatusHovered(true);
  };

  const handleMouseLeave = () => {
    setIsWindcaveStatusHovered(false);
  };

  if (hasUpdateAccess) {
    actionItems.push({
      label: 'Edit Terminal',
      handler: () => {
        onEdit(terminalItem);
      },
    });
  }

  if (hasReenrolAccess) {
    actionItems.push({
      label: 'Re-enrol Terminal',
      handler: () => {
        onReenrol(terminalItem.id);
      },
    });
  }

  if (hasDeleteAccess) {
    actionItems.push({
      label: 'Delete Terminal',
      handler: () => {
        onDelete(terminalItem);
      },
    });
  }

  if (hasTerminalFimwareReadAccess) {
    actionItems.push({
      label: 'Firmware',
      handler: () => {
        onViewFirmwareClick(terminalItem);
      },
    });
  }

  if (hasOperationFileUpdateAccess) {
    secondLevelActionItems.push({
      label: 'Retrieve File',
      handler: () => {
        onOperationFileUpload(terminalItem);
      },
    });
  }

  if (hasOperationRestartAccess) {
    secondLevelActionItems.push({
      label: 'Terminal Restart',
      handler: () => {
        onOperationRestart(terminalItem);
      },
    });
  }

  if (hasOperationBootloaderRestartAccess) {
    secondLevelActionItems.push({
      label: 'Bootloader Restart',
      handler: () => {
        onOperationBootloaderRestart(terminalItem);
      },
    });
  }

  if (hasOperationMemoryDumpAccess) {
    secondLevelActionItems.push({
      label: 'Memory Dump',
      handler: () => {
        onOperationMemoryDump(terminalItem);
      },
    });
  }

  if (hasOperationReadAccess) {
    secondLevelActionItems.push({
      label: 'View All Operations',
      handler: () => {
        onOperationViewAll(terminalItem);
      },
    });
  }

  if (secondLevelActionItems.length >= 2) {
    actionItems.push({
      label: 'Operations',
      isClickNext: true,
      handler: () => setIsSecondLevelMenu(true),
    });
  }

  if (terminalItem.systemStartupDateTimeUtc) {
    moreItemDetails.push({
      name: 'Up Time',
      value: terminalItem.systemStartupDateTimeUtc,
    });
  }

  if (terminalItem.sdCard?.status && !terminalItem.sdCard?.usagePercentage) {
    moreItemDetails.push({
      name: 'SD Card',
      value: `${fieldMappingHelper.getDisplayValue(terminalItem.sdCard.status, HealthStatusDisplayType)}`,
    });
  }

  if (terminalItem.sdCard?.status && terminalItem.sdCard?.usagePercentage) {
    moreItemDetails.push({
      name: 'SD Card',
      value: `${fieldMappingHelper.getDisplayValue(terminalItem.sdCard.status, HealthStatusDisplayType)}${
        terminalItem.sdCard.status ? ', ' : ''
      }${terminalItem.sdCard.usagePercentage} % used`,
    });
  }

  if (terminalItem.temperatureCelsius) {
    moreItemDetails.push({
      name: 'Temperature',
      value: `${terminalItem.temperatureCelsius} °C`,
    });
  }

  if (terminalItem.upsBoard?.battery) {
    moreItemDetails.push({
      name: 'Battery',
      value: ` ${fieldMappingHelper.getDisplayValue(terminalItem.upsBoard.battery.state, BatteryDisplayType)}, ${
        terminalItem.upsBoard.battery.chargeLevelPercentage
      } %`,
    });
  }

  if (terminalItem.fmsReplacementBoard?.battery) {
    moreItemDetails.push({
      name: 'Battery',
      value: ` ${fieldMappingHelper.getDisplayValue(
        terminalItem.fmsReplacementBoard.battery.state,
        BatteryDisplayType
      )}, ${terminalItem.fmsReplacementBoard.battery.chargeLevelPercentage} %`,
    });
  }

  if (terminalItem.transactionStorageUsage) {
    moreItemDetails.push({
      name: 'Transaction Storage',
      value: `${terminalItem.transactionStorageUsage.authorisationUsagePercentage} % / ${terminalItem.transactionStorageUsage.completionUsagePercentage} % / ${terminalItem.transactionStorageUsage.voidUsagePercentage} %`,
      tooltipTitle: 'Authorisation / Completion / Void',
    });
  }

  if (terminalItem.card?.dateTimeUtc) {
    moreItemDetails.push({
      name: 'Card Presented',
      value: `${datetimeHelper.getDayCounter(terminalItem.card?.dateTimeUtc)}`,
      tooltipTitle: `${fieldMappingHelper.getDisplayValue(terminalItem.card?.readerType, CardReaderType)}`,
    });
  }

  if (terminalItem?.printer?.status) {
    moreItemDetails.push({
      name: 'Printer',
      value: `${fieldMappingHelper.getDisplayValue(terminalItem.printer?.status, PrinterStatusType)}${
        terminalItem.printer?.errorCode ? ': ' + terminalItem.printer?.errorCode : ''
      }`,
    });
  }

  if (terminalItem?.emergencyStopStatus) {
    moreItemDetails.push({
      name: 'Emergency Stop',
      value: `${fieldMappingHelper.getDisplayValue(terminalItem.emergencyStopStatus, EmergencyStopStatusType)} `,
    });
  }

  if (terminalItem?.doorStatus) {
    moreItemDetails.push({
      name: 'Door',
      value: `${fieldMappingHelper.getDisplayValue(terminalItem.doorStatus, DoorStatusType)}`,
    });
  }

  if (terminalItem?.windcave) {
    moreItemDetails.push({
      name: 'Windcave',
      value: (
        <span
          className='windcave-status-button'
          onClick={() => onItemWindcaveStatusClick(terminalItem)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span
            className={classNames('windcave-status-overview', {
              'windcave-status-overview-hover': isWindcaveStatusHovered,
            })}
          >
            {terminalItem.windcaveStatus}
          </span>
          <i className='pop-up-modal-link'></i>
        </span>
      ),
    });
  }

  const onItemClick = (e: any) => {
    const target = e.target;
    if (
      (target.tagName.toLowerCase() === 'span' && target.classList.contains('windcave-status-button')) ||
      (target.tagName.toLowerCase() === 'i' && target.classList.contains('pop-up-modal-link')) ||
      (target.tagName.toLowerCase() === 'span' && target.classList.contains('windcave-status-overview'))
    )
      return;
    if (onClick) {
      onClick(terminalItem);
    }
  };

  const onItemWindcaveStatusClick = (terminalItem: TerminalRecordModel) => {
    onWindcaveStatusClick(terminalItem);
  };

  const StatusDetail = () => {
    return (
      <span>
        {terminalItem?.messages && terminalItem?.messages?.length > 0 && getStatusMessageCount(terminalItem?.messages)}
      </span>
    );
  };

  return (
    <Card className={classNames('box-card-parent')}>
      <Box className={classNames('primary-details')}>
        <Item onClick={onItemClick} className={classNames('box-row-columns')}>
          <Item className={classNames('box-row-item-header')}>
            <div className={classNames('icon-name-header')}>
              <Item className={classNames('list-icon-size', 'img-size-mob', 'terminal-icon-img')}></Item>
              <div>
                <Item className={classNames('list-column-header', 'item-header-name')}>{terminalItem?.number}</Item>
                <Item className={classNames('list-column-content', 'item-primary-header-name')}>Terminal</Item>
              </div>
            </div>
          </Item>
          <Item className={classNames('item-primary-header', 'item-primary-header-mobile')}>
            <div>
              <Item className={classNames('list-column-header', 'item-primary-header-name')}>Status</Item>
              <Item className={classNames('list-column-content')}>
                <Item className={classNames('status-icon', healthIndicator)} />
                {fieldMappingHelper.getDisplayValue(status, HealthStatusDisplayType)}
              </Item>
            </div>
          </Item>
          <Item className={classNames('item-primary-header', 'item-primary-header-tablet')}>
            <div>
              <Item className={classNames('list-column-header', 'item-primary-header-name')}>Last Status Update</Item>
              <Item className={classNames('list-column-content')}>{lastUpdatedDateTimeUtc}</Item>
            </div>
          </Item>
        </Item>
        {displayMenu ? (
          <Item className={classNames('row-item-ellipse')}>
            <FloatingMenu
              key={terminalItem?.id}
              buttonNode={<MoreVertIcon className='ellipse-icon' />}
              items={isSecondLevelActionMenu ? secondLevelActionItems : actionItems}
              isSecondLevelActionMenu={isSecondLevelActionMenu}
              setIsSecondLevelMenu={setIsSecondLevelMenu}
            />
          </Item>
        ) : (
          <Box className='box-item' onClick={onItemClick}></Box>
        )}
      </Box>
      <CollapsibleCard
        id={terminalItem?.id}
        itemDetails={moreItemDetails}
        fixeditemDetails={fixedItemDetails}
        rowViewItemDetails={terminalItem?.messages}
        showMoreDetailText={terminalItem?.messages && terminalItem?.messages?.length > 0 && StatusDetail()}
        onClick={onItemClick}
      ></CollapsibleCard>
    </Card>
  );
};

export default TerminalItem;
