import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { selectSiteConfiguration, selectSitesStatus } from '../../store/sites/selectors';
import HeaderControl from './header';
import { selectHeaderConfiguration } from '../../store/header/selectors';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import * as modalActions from '../../store/modals/actions';
import { selectCurrentOrganisation } from '../../store/auth/selectors';
import * as siteActions from '../../store/sites/actions';

const mapStateToProps = (state: RootState) => {
  return {
    siteConfigurationItem: selectSiteConfiguration(state),
    headerConfiguration: selectHeaderConfiguration(state),
    activeOrganisation: selectCurrentOrganisation(state),
    siteStatus: selectSitesStatus(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    openModal: (data: ModalStateModel) => dispatch({ type: modalActions.OPEN_MODAL, payload: data }),
    setSelectedSiteId: (id: string) => dispatch({ type: siteActions.SET_SELECTED_SITE_ID, payload: id }),
    loadSiteConfiguration: (id?: string) => dispatch({ type: siteActions.SITE_CONFIGURATION, payload: id }),
  };
};

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderControl);

export default Header;
