import { Box, Grid } from '@mui/material';
import classNames from 'classnames';
import { FirmwareStateModel } from '../../models/terminalFirmwareModel';
import { ButtonStyle } from '../../constants/button-constants';
import { SystemUpdateIcon } from '../../components/icons';
import Item from '../../components/box-items/box-item';
import CustomButton from '../../components/button/custom-button';
import './styles/firmwareItem.scss';

interface CurrentFirmwareProps {
  itemHeaderOnly?: boolean;
  item?: FirmwareStateModel;
  hasInstallAccess: boolean;
  hasDownloadAccess: boolean;
  onItemClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CurrentFirmware: React.FC<CurrentFirmwareProps> = (props: CurrentFirmwareProps) => {
  const { itemHeaderOnly, item, hasInstallAccess, hasDownloadAccess, onItemClick } = props;
  const smHeaderDisplay = itemHeaderOnly ? 'flex' : 'none';
  const xsHeaderDisplay = itemHeaderOnly ? 'none' : 'flex';

  return (
    <>
      <Grid
        container
        className={classNames('firmware-grid-container', {
          'card-grid-container': !itemHeaderOnly,
        })}
        alignItems='center'
        sx={{ display: { xs: xsHeaderDisplay, sm: 'flex' } }}
      >
        <Grid item xs={10} sm={10}>
          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={6}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box
                    sx={{
                      display: { xs: xsHeaderDisplay, sm: smHeaderDisplay },
                    }}
                  >
                    <Item className={classNames('firmware-card-header')}>Board</Item>
                  </Box>
                  <Item
                    className={classNames({
                      'display-none': itemHeaderOnly,
                      'firmware-card-value': !itemHeaderOnly,
                    })}
                  >
                    <Item>
                      <div>{item?.boardName}</div>
                      <div className={classNames('firmware-card-boardModel-value')}>{item?.boardModel}</div>
                    </Item>
                  </Item>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Item className={classNames('item-primary-header')}>
                <div>
                  <Box
                    sx={{
                      display: { xs: xsHeaderDisplay, sm: smHeaderDisplay },
                    }}
                  >
                    <Item className={classNames('firmware-card-header')}>Version</Item>
                  </Box>
                  <Item
                    className={classNames({
                      'display-none': itemHeaderOnly,
                      'firmware-card-value': !itemHeaderOnly,
                    })}
                  >
                    {item?.version}
                  </Item>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2} sm={2} className={classNames('firmware-grid-button-container')}>
          {(hasInstallAccess || hasDownloadAccess) && !itemHeaderOnly && (
            <Item className={classNames('item-primary-header')}>
              <div>
                <Item className={classNames('firmware-grid-button-container')}>
                  <CustomButton
                    buttonStyle={ButtonStyle.ICON}
                    onClick={onItemClick}
                    icon={<SystemUpdateIcon className={classNames('button-icon-color')} />}
                    iconTooltip='Manage Firmware'
                  ></CustomButton>
                </Item>
              </div>
            </Item>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CurrentFirmware;
