import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectModalData } from '../../../store/modals/selectors';
import * as modalActions from '../../../store/modals/actions';
import TransactionFilter from './receipt-modal';

const mapStateToProps = (state: RootState) => {
  return {
    modalData: selectModalData(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch({ type: modalActions.CLOSE_MODAL }),
  };
};

const TransactionFilteForm = connect(mapStateToProps, mapDispatchToProps)(TransactionFilter);

export default TransactionFilteForm;
