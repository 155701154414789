import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { TerminalInfo, TerminalModel } from '../../../models/terminalModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { selectTerminalData, selectTerminalDetailStatus, selectTerminalInfo } from '../../../store/terminal/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectSelectedSiteId } from '../../../store/sites/selectors';
import { selectOrganisationId } from '../../../store/auth/selectors';
import * as terminalActions from '../../../store/terminal/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as headerActions from '../../../store/header/actions';
import Terminal from './terminal';

const mapStateToProps = (state: RootState) => {
  return {
    terminalData: selectTerminalData(state),
    terminalInfo: selectTerminalInfo(state),
    terminalDetailStatus: selectTerminalDetailStatus(state),
    selectedSiteId: selectSelectedSiteId(state),
    selectedOrganisationId: selectOrganisationId(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTerminals: (data: TerminalModel) => dispatch({ type: terminalActions.LOAD_TERMINALS, payload: data }),
    editTerminalItem: (data: TerminalInfo) => dispatch({ type: terminalActions.EDIT_TERMINAL, payload: data }),
    createTerminalItem: (data: TerminalInfo) => dispatch({ type: terminalActions.CREATE_TERMINAL, payload: data }),
    loadTerminalInfo: (data: TerminalModel) => dispatch({ type: terminalActions.LOAD_TERMINALINFO, payload: data }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),

    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
  };
};

const TerminalPage = connect(mapStateToProps, mapDispatchToProps)(Terminal);

export default TerminalPage;
