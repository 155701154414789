export const SetDataInSessionStorage = (key: string, data: any) => {
    sessionStorage.setItem(key, JSON.stringify(data));
}

export const RemoveDataFromSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
}

export const GetDataFromSessionStorage = (key: string): any => {
    let value = sessionStorage.getItem(key);
    
    if(!!value || value !== null)
        return JSON.parse(value || '')
    else 
        return '';
}