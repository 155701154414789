export const ButtonStyle = Object.freeze({
  CREATE: 'create',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TOOLBAR: 'toolbar',
  REVIEW: 'review',
  EDIT: 'edit',
  INSTALL: 'install',
  ICON: 'icon',
  NO_BORDER: 'no-border',
  DELETE: 'delete',
  FILTER: 'filter',
  FLOAT_MENU_TOOLBAR: 'float-menu-toolbar',
  SEARCH_BAR: 'search-bar',
  TOOLBAR_FILTER: 'toolbar-filter',
  TEXTFIELD_INLINE_BUTTON: 'textfield-inline-button',
  ITEM_INLINE_BUTTON: 'item-inline-button',
});
