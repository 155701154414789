import KeyValuePair from '../models/baseModels/keyValuePairModel';

export const TerminalOperationState = Object.freeze({
  RUNNING: 'running',
  PENDING: 'pending',
  COMPLETED: 'completed',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
  ERROR: 'error',
});

export const TerminalOperationType = Object.freeze({
  FILE_UPLOAD: 'fileUpload',
  TERMINAL_RESTART: 'terminalRestart',
  BOOTLOADER_RESTART: 'bootloaderRestart',
  MEMORY_DUMP: 'memoryDump',
});

export const TerminalOperationFileUploadType = Object.freeze({
  CREATE: 'create',
  APPEND: 'append',
  STREAM_CREATE: 'streamCreate',
});

export const TerminalFileType: KeyValuePair[] = [
  { key: 'file', value: 'File' },
  { key: 'directory', value: 'Directory' },
];

export const offsetUnitOfMeasureOptions = Object.freeze({
  BYTES: 'bytes',
  KILOBYTES: 'kilobytes',
  MEGABYTES: 'megabytes',
});
