import { connect } from 'react-redux';
import { RootState } from '../../../store/rootReducers';
import MainNavPaneControl from './index';
import { selectUserAccess } from '../../../store/auth/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const MainNavPaneComponent = connect(mapStateToProps)(MainNavPaneControl);

export default MainNavPaneComponent;
