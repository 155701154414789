import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import classNames from 'classnames';
import { NavPaneStates } from '../../../constants/window-constants';
import { NavDropdownModel, NavItemModel } from '../../../models/navItemModel';
import { AuthorisationModel } from '../../../models/baseModels/authorisationModel';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

interface FloatingMenuProps {
  buttonNode: JSX.Element;
  menuInfo: NavItemModel;
  className?: string;
  navPaneState: string;
  selectSelectedDropdownMenuIndex: NavDropdownModel;
  isDropdownMenuToggled: boolean | undefined;
  menuIndex: number;
  userAccess: (moduleName: string) => AuthorisationModel;
}

const NavPaneFloatingMenu: React.FC<FloatingMenuProps> = (
  props: FloatingMenuProps
) => {
  const {
    buttonNode,
    navPaneState,
    selectSelectedDropdownMenuIndex,
    isDropdownMenuToggled,
    menuIndex,
    menuInfo,
    userAccess,
  } = props;

  const subItems = menuInfo?.subItems;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const hasReadAccess = (moduleName: string | undefined, path: string) => {
    if (path === '/') {
      return true;
    }
    return !!moduleName ? userAccess(moduleName).hasReadAccess : false;
  };

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDropdownMenuSelected = useCallback(
    (index: number, dropdownIndex: number) => {
      if (selectSelectedDropdownMenuIndex?.menuIndex === undefined) {
        return false;
      } else {
        return (
          selectSelectedDropdownMenuIndex.menuIndex === index &&
          selectSelectedDropdownMenuIndex.dropdownMenuIndex === dropdownIndex
        );
      }
    },
    [selectSelectedDropdownMenuIndex]
  );

  return (
    <div onMouseLeave={handleClose}>
      <div onMouseMove={(it) => handleOpen(it)}>{buttonNode}</div>
      {navPaneState === NavPaneStates.COLLAPSED && !isDropdownMenuToggled && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          role={undefined}
          placement='left-start'
          disablePortal={false}
          onClick={handleClose}
          className={'navpane-floatingmenu-container'}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              id='basic-menu'
              autoFocusItem={open}
              className={classNames('navpane-floatingmenu', props.className)}
            >
              <ListItemText
                key={'submenu-label'}
                className='submenu-label'
                primary={menuInfo?.label}
              />
              {subItems?.map((item, i) => {
                return (
                  hasReadAccess(item.moduleName, item.path) && (
                    <Link
                      key={'sub' + i}
                      to={item.path}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItem
                        button
                        key={item.label}
                        selected={isDropdownMenuSelected(menuIndex, i)}
                        className={classNames('item', 'item-sub', {
                          'item-selected': isDropdownMenuSelected(menuIndex, i),
                        })}
                      >
                        <div
                          className={classNames({
                            'icon-selected': isDropdownMenuSelected(
                              menuIndex,
                              i
                            ),
                          })}
                        >
                          <ListItemIcon key={'icon' + i}>
                            {item.icon}
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          key={'label' + i}
                          className='item-text'
                          primary={item.label}
                        />
                      </ListItem>
                    </Link>
                  )
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Popper>
      )}
    </div>
  );
};

export default NavPaneFloatingMenu;
