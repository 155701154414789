export const removeSpecialCharacters = (strValue: string) => {
  // eslint-disable-next-line no-useless-escape
  return strValue.replace(/[&\/\\|#,+=()$£Є¥₱~%.'":*!@`?<>{}\[\]^-]/g, '');
};

export const checkIsTextEllipsis = (textContainer: HTMLDivElement | null): boolean => {
  if (textContainer) {
    return textContainer.clientWidth < textContainer.scrollWidth;
  }
  return false;
};

export const checkIsTextEllipsisWithDecimal = (textContainer: HTMLDivElement | null): boolean => {
  if (textContainer) {
    return textContainer.scrollWidth !== textContainer.offsetWidth
      ? textContainer.scrollWidth > textContainer.offsetWidth
      : checkRanges(textContainer);
  }
  return false;
};
export const amountHandlerWithCurrency = (amount: number, currency: string, dp?: number): string => {
  const DPAmount = amount.toFixed(dp ? dp : 2);
  return `${currency === 'NZD' || currency === 'AUD' ? '$' : ''}${DPAmount}`;
};

export const getCurrencySign = (currency: string): string => {
  return `${currency === 'NZD' || currency === 'AUD' ? '$' : ''}`;
};

export const getMeasureSign = (unitOfMeasure: string | undefined): string => {
  return `${
    unitOfMeasure === 'litre'
      ? 'L'
      : unitOfMeasure === 'celsius'
        ? '°C'
        : unitOfMeasure === 'millimetre'
          ? 'mm'
          : unitOfMeasure === 'milliAmpere'
            ? 'mA'
            : ''
  }`;
};

export const numberHandlerWithDP = (number: number | string, dp: number) => {
  if (number === null || number === undefined || number === '') {
    return '';
  }

  const parsedNum = typeof number === 'string' ? parseFloat(number) : number;

  if (isNaN(parsedNum)) {
    return 'Invalid number';
  }

  let formattedNumber = parsedNum.toFixed(dp);

  return formattedNumber;
};

export const moreDataDisplayInListHandler = (data: any) => {
  return data
    ? data?.length <= 0
      ? ''
      : data?.length === 1
        ? data[0]
        : data?.length === 2
          ? `${data[0]} and ${data[1]}`
          : `${data[0]}, ${data[1]} and`
    : '';
};

export const moreDataDisplayInListNameModelHandler = (data: any) => {
  return data
    ? data?.length <= 0
      ? ''
      : data?.length === 1
        ? data[0]?.name
        : data?.length === 2
          ? `${data[0]?.name} and ${data[1]?.name}`
          : `${data[0]?.name}, ${data[1]?.name} and`
    : '';
};

export const tooltipDisplayInListHandler = (data: any) => {
  return data
    ? data.length <= 2
      ? ''
      : data?.length === 3
        ? String(
            data
              .map((it: string) => {
                return `${it}`;
              })
              .slice(2)
          )
        : String(
            `${data[2]},` +
              data
                .map((it: string) => {
                  return `\n${it}`;
                })
                .slice(3)
          )
    : '';
};

export const tooltipDisplayInListNameModelHandler = (data: any) => {
  return data
    ? data.length <= 2
      ? ''
      : data?.length === 3
        ? String(
            data
              .map((it: { name: string }) => {
                return `${it?.name}`;
              })
              .slice(2)
          )
        : String(
            `${data[2]?.name},` +
              data
                .map((it: { name: string }) => {
                  return `\n${it?.name}`;
                })
                .slice(3)
          )
    : '';
};

export const findSmallestNumberNotInArray = (arr: number[], minNumber: number, maxNumber: number): number => {
  const numberSet = new Set(arr);

  for (let num = minNumber; num <= maxNumber; num++) {
    if (!numberSet.has(num)) {
      return num;
    }
  }

  return maxNumber + 1;
};

const checkRanges = (element: any) => {
  const range = new Range();
  range.selectNodeContents(element);

  const range_rect = range.getBoundingClientRect();
  const el_rect = element.getBoundingClientRect();

  if (Math.round(range_rect.right * 100) / 100 > Math.round(el_rect.right * 100) / 100) {
    return true;
  }

  element.classList.add('text-overflow-ellipsis');
  const rects_ellipsis = range.getClientRects();
  element.classList.add('text-overflow-clip');
  const rects_clipped = range.getClientRects();

  element.classList.remove('text-overflow-ellipsis');
  element.classList.remove('text-overflow-clip');
  return rects_clipped.length !== rects_ellipsis.length;
};

export const base64EncodingGuid = (guid: string) => {
  const byteArray = guidToByteArray(guid);
  const base64Encode = byteArrayToBase64(byteArray);
  return base64Encode.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

const guidToByteArray = (guid: string) => {
  const guidString = guid.replace(/[{}-]/g, '').toLowerCase();
  const byteArray = [] as number[];

  for (let i = 0; i < 16; i++) {
    const startIndex = i * 2;
    const endIndex = startIndex + 2;
    byteArray.push(parseInt(guidString?.substring(startIndex, endIndex), 16));
  }

  return byteArray;
};

const byteArrayToBase64 = (bytes: number[]) => {
  const binaryString = String.fromCharCode(...bytes);
  return window.btoa(binaryString);
};

export const getBrowserZoomLevel = () => {
  return window.devicePixelRatio;
};

export const formatSize = (sizeStr: string): string => {
  const size = parseInt(sizeStr, 10);

  if (isNaN(size)) {
    return '';
  }

  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024) {
    const sizeInKB = size / 1024;
    const formattedSizeInKB = sizeInKB % 1 === 0 ? sizeInKB.toFixed(0) : sizeInKB.toFixed(1);
    return `${formattedSizeInKB} KB`;
  } else {
    const sizeInMB = size / (1024 * 1024);
    const formattedSizeInMB = sizeInMB % 1 === 0 ? sizeInMB.toFixed(0) : sizeInMB.toFixed(1);
    return `${formattedSizeInMB} MB`;
  }
};

export const getIndefiniteArticleAndReturnString = (word: string): string => {
  if (!word || word.length === 0) return '';

  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const trimmedWord = word.trim();
  const firstLetter = trimmedWord.charAt(0).toLowerCase();
  const article = vowels.includes(firstLetter) ? 'an' : 'a';
  const capitalizedWord = trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1);

  return `${article} ${capitalizedWord}`;
};
