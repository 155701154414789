import { RootState } from './../rootReducers';
import { createSelector } from 'reselect';

export const selectForecourtControllerState = (state:RootState) => state.ForecourtControllerState;

export const selectForecourtControllerContent = createSelector(
    selectForecourtControllerState,
    state => state.forecourtControllerContent
);

export const selectForecourtControllerItem = createSelector(
    selectForecourtControllerState,
    state => state.forecourtControllerItem
)

export const selectForecourtControllerStatus = createSelector(
    selectForecourtControllerState,
    state => state.forecourtControllerStatus
);

export const selectForecourtControllerDetailStatus = createSelector(
    selectForecourtControllerState,
    state => state.forecourtControllerDetailStatus
);


