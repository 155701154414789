import { SvgIcon } from '@mui/material';
import './icons.scss';

interface RetryIconProps{
    className?:string
}

const RetryIcon = (props: RetryIconProps) => {
    return (
        <SvgIcon className={`base-icon retry-icon ${props.className}`} />
    );
};

export default RetryIcon