import { selectHasValidationError, showValidationError } from './../../store/fieldValidation/selectors';
import { connect } from 'react-redux';
import { RootState } from '../../store/rootReducers';
import { 
    selectModalData, 
    selectModalActionStatus, 
    selectModalStatus 
} from './../../store/modals/selectors';
import modal from './main-modal';

const mapStateToProps = (state: RootState) => {
    return {
        hasValidationError: selectHasValidationError(state),
        showValidationError: showValidationError(state),
        open: selectModalStatus(state),
        modalData: selectModalData(state),
        modalActionStatus: selectModalActionStatus(state),
    }
}

const MainModal = connect(mapStateToProps)(modal);

export default MainModal;