import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectProductState = (state: RootState) => state.ProductState;

export const selectProductData = createSelector(
    selectProductState,
    state => state.productData
  );

export const selectProductStatus = createSelector(
  selectProductState,
  state => state.productStatus
);

export const selectProductItem = createSelector(
  selectProductState,
  state => state.productItem
);

export const selectProductListName = createSelector(
  selectProductState,
  (state) => state.productListName
);