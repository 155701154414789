export const ModalType = Object.freeze({
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  SEARCH: 'SEARCH',
  VIEW: 'VIEW',
  RECEIPT: 'RECEIPT',
  LIST: 'LIST',
  EXPORT: 'EXPORT',
  FILE_EXPLORER: 'FILE_EXPLORER',
});

export const MODAL_VALIDATION_MESSAGE = 'Please correct marked fields to continue';
