import { initialState } from '././paginationInitstate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationModel } from '../../models/paginationModel';

const paginationSlice = createSlice({
  name: 'paginationState',
  initialState,
  reducers: {
    setPaginationInfo: (state, action: PayloadAction<PaginationModel>) => {
      state.paginationInfo = action.payload;
    },
    setContinuationTokenList: (state, action: PayloadAction<string>) => {
      if (state.paginationInfo.isClickNext) {
        state.continuationToken = [...state.continuationToken, action.payload];
      } else {
        const newv = state.continuationToken.slice(0, -2);
        state.continuationToken = [...newv, action.payload];
      }
    },
    setIsReachEnd: (state, action: PayloadAction<boolean>) => {
      state.isReachEnd = action.payload;
    },
    clearContinuationTokenList: (state) => {
      state.continuationToken = [];
    },
  },
});

export const { setPaginationInfo, setContinuationTokenList, setIsReachEnd, clearContinuationTokenList } =
  paginationSlice.actions;

export default paginationSlice.reducer;
