import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as services from './services';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import {
  setReportState,
  setExportColumnsStatus,
  setReportData,
  setExportColumnsData,
  setReportError,
  setExportColumnsError,
  setExportFilter,
  setSelectedColumns,
} from './reducers';
import { selectUserId, selectOrganisationId } from '../auth/selectors';
import { setGenericErrorData } from '../generic-error/reducers';
import { getGenericErrorMessage } from '../../utilities/errorhandler';
import { ReportModel, TransactionReportColumn, TransactionReportRequestModel } from '../../models/reportModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import {
  ExportColumnsResponse,
  ReportResponse,
  TransactionReportFilterModel,
  TransactionReportRequestEntity,
} from '../../entities/report';
import { LoadingStatus } from '../../constants/loading-constants';
import { TransactionFilterModel } from '../../models/transactionModel';
import { setTransactionError } from '../transaction/reducers';
import {
  TRANSACTION_EXPORT_COLUMN_STORAG_KEY,
  TRANSACTION_EXPORT_FILTER_STORAG_KEY,
} from '../../constants/transaction-constants';
import { SetDataInLocalStorage } from '../../utilities/localStorage-helper';
import { setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_REPORTS, loadReports);
  yield takeLatest(actions.LOAD_COLUMNS, loadAllColumns);
  yield takeLatest(actions.GENERATE_TRANSACTION_REPORT, generateTransactionReport);
  yield takeLatest(actions.SAVE_EXPORT_FILTER, saveExportFilter);
  yield takeLatest(actions.SAVE_EXPORT_COLUMN, saveExportColumn);
  yield takeLatest(actions.SET_SELECTED_COLUMNS, saveSelectedColumns);
}

export function* loadReports() {
  try {
    yield put(setReportState(LoadingStatus.LOADING));

    const userId: string = yield select(selectUserId);
    const orgId: string = yield select(selectOrganisationId);

    while (true) {
      let response: ReportResponse = yield call(services.getAllReports, orgId, userId);
      let records: ReportModel[] = MapReportEntityToModel(response);

      const currentDate = new Date();
      const oneHourEarlier = new Date(currentDate.getTime() - 60 * 60 * 1000);
      const filteredRecords = records.filter((it) => it.startDateTimeUtc > oneHourEarlier.toISOString());

      yield put(setReportData(filteredRecords));
      yield put(setReportState(LoadingStatus.SUCCESS));

      const runningReports = filteredRecords.some((record) => record.state === 'queued' || record.state === 'running');

      if (!runningReports) {
        break;
      }

      yield delay(15000);
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setReportError());
  }
}

export function* loadAllColumns() {
  try {
    yield put(setExportColumnsStatus(LoadingStatus.LOADING));
    const orgId: string = yield select(selectOrganisationId);
    let response: ExportColumnsResponse = yield call(services.getAllColumns, orgId);
    let records: string[] = MapExportColumnEntityToModel(response);

    yield put(setExportColumnsData(records));
    yield put(setExportColumnsStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setExportColumnsError());
  }
}

export function* generateTransactionReport(action: PayloadAction<TransactionReportRequestModel>) {
  try {
    yield put(setReportState(LoadingStatus.LOADING));
    let requestEntity = MapReportModelToEntity(action.payload);
    yield call(saveExportFilter, {
      type: actions.SAVE_EXPORT_FILTER,
      payload: action.payload.filters,
    });

    yield call(saveExportColumn, {
      type: actions.SAVE_EXPORT_COLUMN,
      payload: action.payload.columns,
    });
    yield call(services.generateTransactionReport, requestEntity);
    yield put(setReportState(LoadingStatus.SUCCESS));
    yield put(setSnackBarSuccess(Messages.REPORT_GENERATE_QUEUED));
    yield call(loadReports);
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setReportState(LoadingStatus.ERROR));
    yield put(setReportError());
  }
}

export function* saveExportFilter(action: PayloadAction<TransactionFilterModel>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const userId: string = yield select(selectUserId);
    yield call(
      SetDataInLocalStorage,
      `${TRANSACTION_EXPORT_FILTER_STORAG_KEY}-${organisationId}-${userId}`,
      action.payload
    );
    yield put(setExportFilter(action.payload));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTransactionError());
  }
}

export function* saveExportColumn(action: PayloadAction<TransactionReportColumn[]>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const userId: string = yield select(selectUserId);
    yield call(
      SetDataInLocalStorage,
      `${TRANSACTION_EXPORT_COLUMN_STORAG_KEY}-${organisationId}-${userId}`,
      action.payload
    );

    yield put(setSelectedColumns(action.payload));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTransactionError());
  }
}

export function* saveSelectedColumns(action: PayloadAction<TransactionReportColumn[]>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    const userId: string = yield select(selectUserId);
    yield call(
      SetDataInLocalStorage,
      `${TRANSACTION_EXPORT_COLUMN_STORAG_KEY}-${organisationId}-${userId}`,
      action.payload
    );

    yield put(setSelectedColumns(action.payload));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setTransactionError());
  }
}

const MapReportEntityToModel = (response: ReportResponse) => {
  if (response && response.items.length > 0) {
    const result: ReportModel[] = response.items.map((item, i) => {
      return {
        id: item.id,
        name: item.name,
        userId: item.userId,
        state: item.state,
        type: item.type,
        startDateTimeUtc: item.startDateTimeUtc,
        endDateTimeUtc: item.endDateTimeUtc,
        downloadLink: item.downloadLink,
      } as ReportModel;
    });
    return result;
  }
  return [] as ReportModel[];
};

const MapExportColumnEntityToModel = (response: ExportColumnsResponse) => {
  if (response && response.items.length > 0) {
    const stringArray = response.items.map((item) => {
      return fieldMappingHelper.formatCamelCaseString(item.name);
    });
    return stringArray;
  }
  return [] as string[];
};

const MapReportModelToEntity = (model: TransactionReportRequestModel) => {
  if (model) {
    const currentDate = new Date();
    let startTime = model?.filters?.startDateTime;
    let endTime = model?.filters?.endDateTime;

    if (model?.filters?.dateRange === 'today') {
      const todayStart = new Date(currentDate);
      todayStart.setHours(0, 0, 0, 0);

      const todayEnd = new Date(currentDate);
      todayEnd.setHours(23, 59, 59, 999);

      startTime = String(todayStart);
      endTime = String(todayEnd);
    }

    if (model?.filters?.dateRange === 'thisWeek') {
      const today = currentDate.getDay();
      const mondayDate = new Date(currentDate);
      mondayDate.setDate(currentDate.getDate() - today + 1);
      mondayDate.setHours(0, 0, 0, 0);

      const sundayDate = new Date(currentDate);
      sundayDate.setDate(currentDate.getDate() + (7 - today));
      sundayDate.setHours(23, 59, 59, 999);

      startTime = String(mondayDate);
      endTime = String(sundayDate);
    }

    if (model?.filters?.dateRange === 'lastWeek') {
      const currentDayOfWeek = currentDate.getDay();
      const daysToLastMonday = (currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1) + 7;
      const lastMondayDate = new Date(currentDate);
      lastMondayDate.setDate(currentDate.getDate() - daysToLastMonday);
      lastMondayDate.setHours(0, 0, 0, 0);

      const daysToLastSunday = currentDayOfWeek === 0 ? 0 : currentDayOfWeek;
      const lastSundayDate = new Date(currentDate);
      lastSundayDate.setDate(currentDate.getDate() - daysToLastSunday);
      lastSundayDate.setHours(23, 59, 59, 999);

      startTime = String(lastMondayDate);
      endTime = String(lastSundayDate);
    }

    if (model?.filters?.dateRange === 'thisMonth') {
      const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      firstDateOfMonth.setHours(0, 0, 0, 0);

      const nextMonth = currentDate.getMonth() + 1;
      const lastDateOfMonth = new Date(currentDate.getFullYear(), nextMonth, 0);
      lastDateOfMonth.setHours(23, 59, 59, 999);

      startTime = String(firstDateOfMonth);
      endTime = String(lastDateOfMonth);
    }

    if (model?.filters?.dateRange === 'lastMonth') {
      const lastMonth = currentDate.getMonth() - 1;
      const firstDateOfLastMonth = new Date(currentDate.getFullYear(), lastMonth, 1);
      firstDateOfLastMonth.setHours(0, 0, 0, 0);

      const lastDateOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      lastDateOfLastMonth.setHours(23, 59, 59, 999);

      startTime = String(firstDateOfLastMonth);
      endTime = String(lastDateOfLastMonth);
    }

    if (model?.filters?.dateRange === 'custom') {
      startTime = model?.filters?.startDateTime;
      endTime = model?.filters?.endDateTime;
    }

    const exportFilter = {
      siteId: fieldMappingHelper.sanitizeStringDropDownValue(model?.filters?.siteId ?? undefined),
      type: fieldMappingHelper.sanitizeStringDropDownValue(model?.filters?.type ?? undefined),
      startDateTimeUtc: dateTimeHelper.convertDateStringtoUTCString(startTime),
      endDateTimeUtc: dateTimeHelper.convertEndDateStringtoUTCString(endTime),
      reference: model?.filters?.reference ?? undefined,
      state: fieldMappingHelper.sanitizeStringDropDownValue(model?.filters?.state ?? undefined),
      binRange: fieldMappingHelper.sanitizeStringDropDownValue(model?.filters?.binRange ?? undefined),
      acquirerName: fieldMappingHelper.sanitizeStringDropDownValue(model?.filters?.acquirerName ?? undefined),
      cardType: fieldMappingHelper.sanitizeStringDropDownValue(model?.filters?.cardType ?? undefined),
      cardTags:
        model?.filters?.tags?.filter(
          (tag) =>
            fieldMappingHelper.sanitizeStringWithoutEmptySpaceBeforeOrAfter(tag.key) ||
            fieldMappingHelper.sanitizeStringWithoutEmptySpaceBeforeOrAfter(tag.value)
        ) ?? undefined,
      panContains: model?.filters?.pan ?? undefined,
    } as TransactionReportFilterModel;

    let reportEntity = {
      name: model.name,
      organisationId: model.organisationId,
      format: model.format,
      filters: exportFilter,
      columns: model.columns.map((it) => {
        return { name: fieldMappingHelper.formatStringToCamelCase(it.name) };
      }),
    } as TransactionReportRequestEntity;
    return reportEntity;
  }

  return {} as TransactionReportRequestEntity;
};
