import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import CustomTooltip from '../tooltip/custom-tooltip';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import DropDown from '../dropdown/dropdown.container';
import CustomTextField from '../text-field/text-field.container';
import './text-field-with-dropdown.scss';

interface TextFieldProps {
  validateCounterFlag?: number;
  className?: string;
  label?: string;
  inlineLabel?: string;
  tooltipTitle?: string;
  moreInfoTextLink?: string;
  moreInfoTitle?: string;
  moreInfoContext?: string;
  isMandatory?: boolean;
  readOnly?: boolean;
  fieldKey?: string;
  fieldPlaceHolder?: string;
  filedName: string;
  fieldValue: string | number;
  fieldType: string;
  fieldMinRange?: number;
  fieldMaxRange?: number;
  fieldMinCharLength?: number;
  fieldMaxCharLength?: number;
  fieldErrorMsg?: boolean;
  dropdownInFront?: boolean;
  fieldInputTooltip?: string;
  dropdownKey?: string;
  dropdownName: string;
  dropdownValue?: string | number;
  dropdownKeyValuePair: KeyValuePair[];
  dropdownErrorMsg?: boolean;
  dropdownInputTooltip?: string;
  displayPrefixAdditionalValue?: boolean;
  hideAsteriskSymbol?: boolean;
  customMdGrid?: number[];
  customXsGrid?: number[];
  onFiledChangeHandler?: (value: KeyValuePair) => void | undefined;
  onDropDownChangeHandler?: (value: KeyValuePair) => void | undefined;
}

const TextFieldWithDropdown = (props: TextFieldProps) => {
  const {
    validateCounterFlag,
    className,
    label,
    inlineLabel,
    tooltipTitle,
    moreInfoTextLink,
    moreInfoTitle,
    moreInfoContext,
    isMandatory,
    readOnly,
    fieldKey,
    fieldPlaceHolder,
    filedName,
    fieldValue,
    fieldType,
    fieldMinRange,
    fieldMaxRange,
    fieldMinCharLength,
    fieldMaxCharLength,
    fieldErrorMsg,
    dropdownInFront,
    fieldInputTooltip,
    dropdownKey,
    dropdownName,
    dropdownValue,
    dropdownKeyValuePair,
    dropdownErrorMsg,
    dropdownInputTooltip,
    displayPrefixAdditionalValue,
    hideAsteriskSymbol,
    customMdGrid,
    customXsGrid,
    onFiledChangeHandler,
    onDropDownChangeHandler,
  } = props;

  return (
    <React.Fragment>
      {
        <div
          className={classNames(
            'text-field-with-dropdown-container',
            readOnly ? 'text-field-with-dropdown-read-only-container' : '',
            className
          )}
        >
          {!inlineLabel && (
            <div className={classNames('text-field-label', className)}>
              {label}{' '}
              {tooltipTitle && (
                <CustomTooltip
                  title={tooltipTitle}
                  moreInfoTextLink={moreInfoTextLink}
                  moreInfoTitle={moreInfoTitle}
                  moreInfoContext={moreInfoContext}
                />
              )}
              {isMandatory && !readOnly && !hideAsteriskSymbol && label && <strong className='asterisk'>*</strong>}
            </div>
          )}
          <div
            className={classNames('text-field-with-dropdown-field', {
              'text-field-with-dropdown-with-error-container': fieldErrorMsg || dropdownErrorMsg,
              'inline-text-field-with-dropdown-field': inlineLabel,
            })}
          >
            {inlineLabel && <p className='inline-label'>{inlineLabel}</p>}
            <Grid
              container
              spacing={0}
              className={classNames({ 'inline-filed-with-dropwon': inlineLabel }, 'filed-with-dropwon')}
            >
              {dropdownInFront && (
                <Grid item xs={customXsGrid ? customXsGrid[1] : 4} md={customMdGrid ? customMdGrid[1] : 3}>
                  <DropDown
                    className={classNames({
                      'read-only-select-field-container': readOnly,
                      'select-with-dropdown-error-display': fieldErrorMsg && !dropdownErrorMsg,
                      'prefix-additional-value-field-container': displayPrefixAdditionalValue,
                    })}
                    validateCounter={validateCounterFlag}
                    isMandatory={isMandatory}
                    key={dropdownKey}
                    name={dropdownName}
                    onBindingValue={onDropDownChangeHandler}
                    value={dropdownValue}
                    keyValuePair={dropdownKeyValuePair}
                    readOnly={readOnly}
                    hideDefaultSelect={true}
                    displayPrefixAdditionalValue={displayPrefixAdditionalValue}
                    inputTooltip={dropdownInputTooltip}
                  />
                </Grid>
              )}
              <Grid item xs={customXsGrid ? customXsGrid[0] : 8} md={customMdGrid ? customMdGrid[0] : 9}>
                <CustomTextField
                  className={classNames({ 'field-with-dropdown-error-display': dropdownErrorMsg && !fieldErrorMsg })}
                  validateCounter={validateCounterFlag}
                  isMandatory={isMandatory}
                  key={fieldKey}
                  placeholder={fieldPlaceHolder}
                  onBindingValue={onFiledChangeHandler}
                  name={filedName}
                  value={fieldValue}
                  type={fieldType}
                  readOnly={readOnly}
                  minRange={fieldMinRange}
                  maxRange={fieldMaxRange}
                  minCharLength={fieldMinCharLength}
                  maxCharLength={fieldMaxCharLength}
                  inputTooltip={fieldInputTooltip}
                ></CustomTextField>
              </Grid>
              {!dropdownInFront && (
                <Grid item xs={customXsGrid ? customXsGrid[1] : 4} md={customMdGrid ? customMdGrid[1] : 3}>
                  <DropDown
                    className={classNames({
                      'read-only-select-field-container': readOnly,
                      'select-with-dropdown-error-display': fieldErrorMsg && !dropdownErrorMsg,
                    })}
                    validateCounter={validateCounterFlag}
                    isMandatory={isMandatory}
                    key={dropdownKey}
                    name={dropdownName}
                    onBindingValue={onDropDownChangeHandler}
                    value={dropdownValue}
                    keyValuePair={dropdownKeyValuePair}
                    readOnly={readOnly}
                    hideDefaultSelect={true}
                    inputTooltip={dropdownInputTooltip}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default TextFieldWithDropdown;
