import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import classNames from 'classnames';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import CustomTooltip from '../tooltip/custom-tooltip';
import './custom-switch.scss';

interface CustomSwitchProps {
  label?: string;
  value?: boolean;
  name: string;
  className?: string;
  readOnly?: boolean;
  isMandatory?: boolean;
  id?: string;
  enabledLabel?: string;
  disabledLabel?: string;
  tooltipTitle?: string;
  moreInfoTextLink?: string;
  moreInfoTitle?: string;
  moreInfoContext?: string;
  onBindingValue?: (newvalue: KeyValuePair) => void | undefined;
}

const CustomSwitchComponent = (props: CustomSwitchProps) => {
  const {
    label,
    value,
    name,
    className,
    readOnly,
    isMandatory,
    id,
    enabledLabel,
    disabledLabel,
    tooltipTitle,
    moreInfoTextLink,
    moreInfoTitle,
    moreInfoContext,
    onBindingValue,
  } = props;

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onBindingValue) {
      onBindingValue({
        key: event.target.name,
        value: event.target.checked,
      });
    }
  };

  return (
    <React.Fragment>
      {
        <div className={classNames('boolean-field-container', className)}>
          <div className={classNames('boolean-field-label', className)}>
            {label}
            {label && isMandatory && !readOnly && <strong className='asterisk'> *</strong>}
            {tooltipTitle && (
              <CustomTooltip
                title={tooltipTitle}
                moreInfoTextLink={moreInfoTextLink}
                moreInfoTitle={moreInfoTitle}
                moreInfoContext={moreInfoContext}
              />
            )}
          </div>
          <div>
            <FormControlLabel
              disabled={readOnly}
              control={
                <Switch
                  className={classNames('boolean-field', className)}
                  checked={value}
                  value={value}
                  name={name}
                  id={id}
                  disabled={!!readOnly ? readOnly : false}
                  onChange={onChangeValue}
                />
              }
              label={value ? enabledLabel : disabledLabel}
            />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default CustomSwitchComponent;
