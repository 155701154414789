import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { AlertRuleModel } from '../../../models/alertRuleModel';
import { RootState } from '../../../store/rootReducers';
import { selectHasValidationError } from '../../../store/fieldValidation/selectors';
import { selectUserAccess } from '../../../store/auth/selectors';
import { selectBackdropActionStatus } from '../../../store/backdrop/selectors';
import { selectSiteList, selectSitesStatus } from '../../../store/sites/selectors';
import { selectAlertUserGroupNameListData } from '../../../store/alert-usergroups/selectors';
import {
  selectAlertRuleInfoData,
  selectAlertRuleStatus,
  selectAlertRuleTriggerListData,
} from '../../../store/alert-rules/selectors';
import * as actions from '../../../store/alert-rules/actions';
import * as headerActions from '../../../store/header/actions';
import * as footerActions from '../../../store/page-configuration/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import * as fieldActions from '../../../store/fieldValidation/actions';
import * as siteActions from '../../../store/sites/actions';
import * as userGroupActions from '../../../store/alert-usergroups/actions';
import AlertUserGroup from './alert-rule';

const mapStateToProps = (state: RootState) => {
  return {
    alertRuleItem: selectAlertRuleInfoData(state),
    alertRuleTriggerList: selectAlertRuleTriggerListData(state),
    alertRuleStatus: selectAlertRuleStatus(state),
    alertUserGroupNameList: selectAlertUserGroupNameListData(state),
    siteListName: selectSiteList(state),
    hasValidationError: selectHasValidationError(state),
    backDropActionStatus: selectBackdropActionStatus(state),
    siteStatus: selectSitesStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadAlertRuleInfo: (id?: string) => dispatch({ type: actions.LOAD_ALERT_RULE_INFO, payload: id }),
    loadAlertRuleTriggerList: (id?: string) => dispatch({ type: actions.LOAD_ALERT_RULE_TRIGGERS, payload: id }),
    loadAlertUserGroupNameList: (id?: string) =>
      dispatch({ type: userGroupActions.LOAD_ALERT_USER_GROUP_NAME_LIST, payload: id }),
    createAlertRule: (item: AlertRuleModel) => dispatch({ type: actions.CREATE_ALERT_RULE, payload: item }),
    editAlertRule: (item: AlertRuleModel) => dispatch({ type: actions.EDIT_ALERT_RULE, payload: item }),
    loadSiteNameList: () => dispatch({ type: siteActions.LOAD_SITENAME_LIST }),
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: footerActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
    removeAllValidation: () => dispatch({ type: fieldActions.REMOVE_ALL_FIELD_VALIDATION }),
    removeValidation: (name: string) => dispatch({ type: fieldActions.REMOVE_FIELD_VALIDATION, payload: name }),
  };
};

const AlertUserGroupPage = connect(mapStateToProps, mapDispatchToProps)(AlertUserGroup);

export default AlertUserGroupPage;
