import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { HeaderStateModel } from '../../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../../models/baseModels/pageSettingStateModel';
import { RootState } from '../../../store/rootReducers';
import { selectNavPaneStatus } from '../../../store/nav-pane/selectors';
import { selectAuthStatus, selectUserAccess } from '../../../store/auth/selectors';
import { selectBulkOperationState, selectOperationDetails } from '../../../store/card-operations/selectors';
import * as cardOperationActions from '../../../store/card-operations/actions';
import * as headerActions from '../../../store/header/actions';
import * as pageConfigActions from '../../../store/page-configuration/actions';
import OperationDetails from './operation-details';

const mapStateToProps = (state: RootState) => {
  return {
    bulkOperationState: selectBulkOperationState(state),
    operationDetails: selectOperationDetails(state),
    navPaneState: selectNavPaneStatus(state),
    authStatus: selectAuthStatus(state),
    userAccess: (moduleName: string) => selectUserAccess(moduleName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initLoadOperationById: (data: string) => {
      dispatch({
        type: cardOperationActions.INIT_LOAD_OPERATION_BY_ID,
        payload: data,
      });
    },
    setHeaderConfiguration: (data: HeaderStateModel) =>
      dispatch({
        type: headerActions.CHANGE_HEADER_CONFIGURATION,
        payload: data,
      }),
    setPageConfiguration: (data: PageSettingStateModel) =>
      dispatch({
        type: pageConfigActions.CHANGE_PAGE_CONFIGURATION,
        payload: data,
      }),
    setIsPageDirty: (data: boolean) =>
      dispatch({
        type: pageConfigActions.SET_IS_PAGE_DIRTY,
        payload: data,
      }),
  };
};

const SiteList = connect(mapStateToProps, mapDispatchToProps)(OperationDetails);

export default SiteList;
