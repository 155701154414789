import { Paper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import CustomButton from '../button/custom-button';
import { ButtonStyle } from '../../constants/button-constants';
import { MODAL_VALIDATION_MESSAGE } from '../../constants/modal-constants';
import { NavPaneStates } from '../../constants/window-constants';
import FloatingMenu from '../floating-menu/floating-menu';
import './styles/bottom-menu.scss';
import { FloatingMenuItem } from '../../models/floatingMenuModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

interface BottomMenuProps {
  onCancel?: MouseEventHandler<HTMLButtonElement> | undefined;
  onSave?: MouseEventHandler<HTMLButtonElement> | undefined;
  cancelText?: string | undefined;
  saveText?: string | undefined;
  hasValidationError: boolean;
  showValidationError: boolean;
  saveButtonEnabled: boolean;
  navPaneState: string;
  isFormHasSubSection?: boolean;
  hideCancelButton?: boolean;
  customValidationMessage?: string;
  primaryButtonMoreOptions?: KeyValuePair[];
  secondaryButtonMoreOptions?: KeyValuePair[];
}

const BottomMenuComponent: React.FC<BottomMenuProps> = (props: BottomMenuProps) => {
  const {
    onCancel,
    onSave,
    cancelText,
    saveText,
    hasValidationError,
    showValidationError,
    saveButtonEnabled,
    navPaneState,
    isFormHasSubSection,
    hideCancelButton,
    customValidationMessage,
    primaryButtonMoreOptions,
    secondaryButtonMoreOptions,
  } = props;

  let primaryButtonMoreItems: FloatingMenuItem[] = [];

  if (primaryButtonMoreOptions) {
    primaryButtonMoreItems = primaryButtonMoreOptions.map((it) => {
      return {
        label: it.value,
        handler: it.additionalValue,
      } as FloatingMenuItem;
    });
  }

  let secondaryButtonMoreItems: FloatingMenuItem[] = [];

  if (secondaryButtonMoreOptions) {
    secondaryButtonMoreItems = secondaryButtonMoreOptions.map((it) => {
      return {
        label: it.value,
        handler: it.additionalValue,
      } as FloatingMenuItem;
    });
  }

  return (
    <div className='bottomActionDivWidth'>
      <div
        className={classNames('bottomActionContainer', {
          'bottom-menu-expanded-width': navPaneState === NavPaneStates.EXPANDED,
          'bottom-menu-collapsed-width':
            navPaneState === NavPaneStates.COLLAPSED || navPaneState === NavPaneStates.COLLAPSED_AND_FLOATING,
          'bottom-menu-hamburger-width':
            navPaneState === NavPaneStates.HAMBURGER || navPaneState === NavPaneStates.HAMBURGER_AND_FLOATING,
          'bottom-menu-sub-form': isFormHasSubSection,
        })}
      >
        <Paper elevation={5}>
          <div className={'bottomActionMenuGrid'}>
            <div
              className={classNames('validation-ErrorMessage', {
                'mobile-display-none': !showValidationError && !customValidationMessage,
              })}
            >
              {hasValidationError && showValidationError && MODAL_VALIDATION_MESSAGE}
              {!hasValidationError && !showValidationError && customValidationMessage && customValidationMessage}
            </div>

            <div>
              <div className={classNames('button-container')}>
                {!hideCancelButton &&
                  (secondaryButtonMoreOptions && secondaryButtonMoreOptions?.length > 0 ? (
                    <div className='footer-secondary-with-more-option'>
                      <CustomButton
                        buttonStyle={ButtonStyle.SECONDARY}
                        className={'footer-secondary-1'}
                        onClick={onCancel}
                      >
                        {cancelText ? cancelText : 'Cancel'}
                      </CustomButton>
                      <FloatingMenu
                        className='footer-secondary-floating-menu'
                        buttonNode={<ArrowDropDownIcon />}
                        items={secondaryButtonMoreItems}
                        isPopStartEnd={true}
                      />
                    </div>
                  ) : (
                    <CustomButton
                      buttonStyle={ButtonStyle.SECONDARY}
                      className={'footer-secondary-1'}
                      onClick={onCancel}
                    >
                      {cancelText ? cancelText : 'Cancel'}
                    </CustomButton>
                  ))}
                {primaryButtonMoreOptions && primaryButtonMoreOptions?.length > 0 ? (
                  <div className='footer-primary-with-more-option'>
                    <CustomButton
                      isDisabled={!saveButtonEnabled}
                      buttonStyle={ButtonStyle.PRIMARY}
                      className={'footer-primary-button1'}
                      onClick={onSave}
                    >
                      {saveText ? saveText : 'Save'}
                    </CustomButton>
                    <FloatingMenu
                      className='footer-primary-floating-menu'
                      buttonNode={<ArrowDropDownIcon />}
                      items={primaryButtonMoreItems}
                      isPopStartEnd={true}
                    />
                  </div>
                ) : (
                  <CustomButton
                    isDisabled={!saveButtonEnabled}
                    buttonStyle={ButtonStyle.PRIMARY}
                    className={'footer-primary-button1'}
                    onClick={onSave}
                  >
                    {saveText ? saveText : 'Save'}
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default BottomMenuComponent;
