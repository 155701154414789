import { LoadingStatus } from '../../constants/loading-constants';
import { TerminalCertificateModel, TerminalInfo, TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';

interface terminalState {
  terminalStatus: string;
  terminalDetailStatus: string;
  terminalData: TerminalRecordModel[];
  hasError: boolean;
  terminalInfo: TerminalInfo;
  selectedTerminalItem: TerminalModel;
  terminalCertificate: TerminalCertificateModel;
  terminalNumberList: KeyValuePair[];
}

export const initialState: terminalState = {
  terminalStatus: LoadingStatus.LOADING,
  terminalDetailStatus: LoadingStatus.LOADING,
  hasError: false,
} as terminalState;
