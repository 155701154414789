import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Divider, Tooltip } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ButtonStyle } from '../../constants/button-constants';
import { LoadingStatus } from '../../constants/loading-constants';
import { SiteConfigurationModel } from '../../models/siteModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { OrganisationItemModel } from '../../models/organisationModel';
import { ModalStateModel } from '../../models/baseModels/modalStateModel';
import { ModalType } from '../../constants/modal-constants';
import { checkIsTextEllipsis, checkIsTextEllipsisWithDecimal } from '../../utilities/general-helper';
import { ArrowDownIcon } from '../../components/icons';
import Avatar from '../../components/avatar/avatar';
import CustomButton from '../button/custom-button';
import useAuthenticationManager from '../auth/authenticationManager';
import FloatingMenu from '../floating-menu/floating-menu';
import TransactionToolBar from '../../modules/transactions/toolbar/toolbar.container';
import './header.scss';

interface HeaderProps {
  siteConfigurationItem: SiteConfigurationModel;
  activeOrganisation: OrganisationItemModel;
  headerConfiguration: HeaderStateModel;
  siteStatus: string;
  openModal: (data: ModalStateModel) => void;
  setSelectedSiteId: (id: string) => void;
  loadSiteConfiguration: (id: string) => void;
}

const Header = (props: HeaderProps) => {
  const {
    siteConfigurationItem,
    activeOrganisation,
    headerConfiguration,
    siteStatus,
    openModal,
    setSelectedSiteId,
    loadSiteConfiguration,
  } = props;

  const {
    title,
    showInfoButton,
    showCreateButton,
    showCreateButtonWithDropdown,
    showOrganisation,
    createButtonText,
    dropdownButtonText,
    dropdownButtonLink,
    showSiteHeader,
    showAccountOption,
    error,
    pageURL,
    hasReportReadAccess,
    hasTransactionReportAccess,
    showTransactionToolBar,
  } = headerConfiguration || '';

  const navigate = useNavigate();
  const location = useLocation();
  const { siteId, terminalId, tankId } = useParams();
  const { instance } = useAuthenticationManager();
  const [firstName, setFirstName] = useState('User');
  const [lastName, setLastName] = useState('');
  const [isPageHeaderContentShown, setisPageHeaderContentShown] = useState(false);
  const [isOrgOrSiteTextEllipsis, setIsOrgOrSiteTextEllipsis] = useState<boolean>(false);
  const [isUserNameTextEllipsis, setIsUserNameTextEllipsis] = useState<boolean>(false);
  const HeaderOrgOrSiteText = useRef<HTMLDivElement | null>(null);
  const HeaderUserNameText = useRef<HTMLDivElement | null>(null);

  function isSitePagePath(path: string) {
    const regex =
      /^\/organisations\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/sites\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/details(\/edit)?$/;
    return regex.test(path);
  }

  useEffect(() => {
    document.title = getDocumentTitle(title);
    updateTooltipDisplay();
  }, [title]);

  useEffect(() => {
    if (!!siteId && !siteConfigurationItem?.id) {
      setSelectedSiteId(siteId);
      if (isSitePagePath(location?.pathname)) return;
      loadSiteConfiguration(siteId);
    }
  }, [loadSiteConfiguration, setSelectedSiteId, siteId, location]);

  const getDocumentTitle = (title: string): string => {
    if (!title || title.toLowerCase() === 'dashboard') {
      return 'CompacOnline';
    }
    return title + ' | CompacOnline';
  };

  const updateTooltipDisplay = () => {
    if (checkIsTextEllipsisWithDecimal(HeaderOrgOrSiteText.current)) {
      setIsOrgOrSiteTextEllipsis(true);
    } else {
      setIsOrgOrSiteTextEllipsis(false);
    }
    if (checkIsTextEllipsis(HeaderUserNameText.current)) {
      setIsUserNameTextEllipsis(true);
    } else {
      setIsUserNameTextEllipsis(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateTooltipDisplay);
    return () => window.removeEventListener('resize', updateTooltipDisplay);
  }, []);

  useEffect(() => {
    let accountInfo = instance?.getActiveAccount()?.idTokenClaims;
    if (!!accountInfo) {
      let firstName = `${accountInfo['firstname'] ? accountInfo['firstname'] : ' '}`;
      setFirstName(firstName);
      let lastName = `${accountInfo['surname'] ? accountInfo['surname'] : ' '}`;
      setLastName(lastName);
      if (firstName.trim() === '' && lastName.trim() === '') {
        setFirstName('User');
      }
    }
  }, [instance]);

  useEffect(() => {
    setisPageHeaderContentShown(showSiteHeader || showCreateButton || showCreateButtonWithDropdown || showOrganisation);
  }, [setisPageHeaderContentShown, showSiteHeader, showCreateButton, showCreateButtonWithDropdown, showOrganisation]);

  let items = [
    {
      label: 'Log Out',
      handler: () => {
        navigate('/logout');
      },
    },
  ];

  if (!error && showAccountOption) {
    items = [
      {
        label: 'View Account',
        handler: () => {
          navigate('/myaccount');
        },
      },
      ...items,
    ];
  }

  // coverting modal to page in progress, will remove this after done all the converting
  const onCreateHandler = () => {
    if (pageURL && pageURL !== null) {
      switch (pageURL) {
        case 'pumps':
        case 'terminals':
        case 'tanks':
        case 'receipt-templates':
        case 'price-signs':
        case 'forecourt-controllers':
          navigate(`organisations/${activeOrganisation.id}/sites/${siteConfigurationItem.id}/${pageURL}/create`);
          break;
        case 'terminal-operation':
          navigate(
            `organisations/${activeOrganisation.id}/sites/${siteConfigurationItem.id}/terminals/${terminalId}/operations/create`
          );
          break;
        case 'deliveries':
          navigate(
            `organisations/${activeOrganisation.id}/sites/${siteConfigurationItem.id}/tanks/${tankId}/deliveries/add-delivery`
          );
          break;
        default:
          navigate(`organisations/${activeOrganisation.id}/${pageURL}/create`);
          break;
      }
    } else {
      openModal({
        type: ModalType.CREATE,
        dataId: '',
      } as ModalStateModel);
    }
  };

  return (
    <React.Fragment>
      <div className='page-header'>
        <div
          className={classNames('page-header-top', {
            'avatar-extra-padding': isPageHeaderContentShown,
            'organisation-header-padding': !isPageHeaderContentShown,
          })}
        >
          <div className='page-header-content'>
            {!error && siteConfigurationItem && showSiteHeader && siteStatus !== LoadingStatus.LOADING && (
              <div className='header-detail-container'>
                <div className='box-avatar'>
                  <Avatar value={siteConfigurationItem.name} displayValue={siteConfigurationItem.name} size={40} />
                </div>

                <Tooltip
                  title={isOrgOrSiteTextEllipsis ? siteConfigurationItem.name : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <div ref={HeaderOrgOrSiteText} className='header-detail-name'>
                    {siteConfigurationItem.name}
                  </div>
                </Tooltip>
              </div>
            )}
            {!error && showOrganisation && (
              <div className='header-detail-container'>
                <div className='box-avatar'>
                  <Avatar value={activeOrganisation.name} displayValue={activeOrganisation.name} size={40} />
                </div>

                <Tooltip
                  title={isOrgOrSiteTextEllipsis ? activeOrganisation.name : ''}
                  disableFocusListener
                  describeChild
                  followCursor
                >
                  <span ref={HeaderOrgOrSiteText} className='header-detail-name'>
                    {activeOrganisation.name}
                  </span>
                </Tooltip>
              </div>
            )}
            {/* hide this portion if org or site information is shown. it will be display on diff container*/}
            {!error && !isPageHeaderContentShown && (
              <div className={classNames('page-name-container')}>
                <div className='title'>{title}</div>
                {showInfoButton && <div className='more-info-icon' data-testid='test-header-info' />}
              </div>
            )}
          </div>

          <div className='right-pane-with-header-detail'>
            <div className='user-account'>
              <FloatingMenu
                key={1}
                buttonNode={
                  <div className='floatingMenu'>
                    <div className='user-avatar'>
                      <Avatar value={firstName + lastName} firstName={firstName} lastName={lastName} size={40} />
                    </div>
                    <div className='user-info'>
                      <Tooltip
                        title={isUserNameTextEllipsis ? firstName + ' ' + lastName : ''}
                        disableFocusListener
                        describeChild
                        followCursor
                      >
                        <div ref={HeaderUserNameText} className='username'>
                          {firstName + ' ' + lastName}
                        </div>
                      </Tooltip>
                      <ArrowDownIcon className='usericon' onClick={() => {}} />
                    </div>
                  </div>
                }
                items={items}
                className='account-option'
              />
            </div>
          </div>
        </div>
        <div
          className={classNames('page-header-top', {
            'default-header-padding': isPageHeaderContentShown,
          })}
        >
          <div className='page-header-content'>
            {/* show this portion if org or site information is shown */}
            {!error && isPageHeaderContentShown && (
              <div className={classNames('page-name-container')}>
                <div className='title'>{title}</div>
                {showInfoButton && <div className='more-info-icon' data-testid='test-header-info' />}
              </div>
            )}
          </div>
          <div className='right-pane-with-header-detail'>
            {!error && showCreateButton && (
              <div className='header-create-button'>
                <CustomButton buttonStyle={ButtonStyle.CREATE} onClick={onCreateHandler}>
                  {!createButtonText ? 'Create New' : createButtonText}
                </CustomButton>
              </div>
            )}
            {!error && showCreateButtonWithDropdown && (
              <div className='header-create-button-with-dropdown-container'>
                <CustomButton buttonStyle={ButtonStyle.CREATE} onClick={onCreateHandler}>
                  {!createButtonText ? 'Create New' : createButtonText}
                </CustomButton>
                <FloatingMenu
                  className='header-create-button-dropdown'
                  buttonNode={<ArrowDropDownIcon />}
                  items={[
                    {
                      label: dropdownButtonText,
                      handler: () => {
                        if (dropdownButtonLink) navigate(dropdownButtonLink);
                      },
                    },
                  ]}
                  isPopStartEnd={true}
                />
              </div>
            )}
            {!error && showTransactionToolBar && (
              <TransactionToolBar
                hasReportReadPermission={hasReportReadAccess}
                hasTransactionReportPermission={hasTransactionReportAccess}
              />
            )}
          </div>
        </div>
        <Divider className='divider' />
      </div>
    </React.Fragment>
  );
};

export default Header;
