import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router-dom';
import { TabListModel } from '../../models/baseModels/tabListModel';
import './tab-list.scss';

interface TabListProps {
  label?: string;
  tabDetailList: TabListModel[];
  wizardList?: boolean;
  forceTabIndex?: number;
  cancelFromChange?: boolean;
  onBindingValue?: (data: number) => void;
  removeAllValidation: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tab-tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const applyProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-tabpanel-${index}`,
  };
};

const TabList = (props: TabListProps) => {
  const { label, tabDetailList, wizardList, forceTabIndex, cancelFromChange, onBindingValue, removeAllValidation } =
    props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(wizardList ? 1 : 0);
  const [displayTabDetailList, setDisplayTabDetailList] = useState<TabListModel[]>(tabDetailList || []);

  const navigate = useNavigate();

  useEffect(() => {
    if (tabDetailList) {
      const filteredTabDetailList = tabDetailList.filter((it) => it?.tabPanel || it?.tabLabel);

      if (filteredTabDetailList.length > 0) {
        setDisplayTabDetailList(filteredTabDetailList);
      }
    }
  }, [tabDetailList]);

  useEffect(() => {
    if (forceTabIndex) {
      handleChange(null, forceTabIndex);
    }
  }, [forceTabIndex]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent | null, newValue: number) => {
      if (wizardList && cancelFromChange && newValue === 0) {
        return;
      }
      if (displayTabDetailList[newValue].tabDisabled) {
        return;
      }
      setValue(newValue);
      if (onBindingValue) onBindingValue(newValue);
    },
    [wizardList, cancelFromChange, displayTabDetailList, onBindingValue]
  );

  const handleClick = (data: TabListModel) => {
    if (data?.tabType === 'link' && data.tabLink) {
      removeAllValidation();
      navigate(data.tabLink);
    }

    if (data?.clickTooltip) {
      handleTooltipOpen();
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(handleTooltipClose, 3000);
  };

  return (
    <Box sx={{ width: '100%' }} className='tab-list-container'>
      {label && <div className='tab-title'>{label}</div>}
      <Tabs className={classNames('tab-list', { 'wizard-tab-list': wizardList })} value={value} onChange={handleChange}>
        {displayTabDetailList.length > 0 &&
          displayTabDetailList.map((it, i) => {
            if (it) {
              return (
                <Tab
                  className={classNames(
                    'tab-container',
                    { 'error-tab': !wizardList && it.tabError },
                    { 'disabled-tab': it.tabDisabled }
                  )}
                  icon={
                    wizardList && i !== 0 ? (
                      <CircleIcon
                        className={classNames(
                          'wizard-tab-indicator',
                          { 'wizard-tab-warning-indicator': it.tabError },
                          { 'wizard-tab-completed-indicator': it.tabFormCompleted },
                          { 'wizard-tab-disabled-indicator': it.tabDisabled }
                        )}
                      />
                    ) : (
                      <></>
                    )
                  }
                  iconPosition='start'
                  key={i}
                  onClick={() => handleClick(it)}
                  disableRipple={wizardList}
                  {...applyProps(i)}
                  label={
                    !wizardList ? (
                      <Tooltip
                        title={it?.clickTooltip ? it?.clickTooltip : ''}
                        onClose={handleTooltipClose}
                        open={open}
                        followCursor
                        arrow
                      >
                        <span>{it.tabLabel}</span>
                      </Tooltip>
                    ) : (
                      it.tabLabel
                    )
                  }
                />
              );
            } else return;
          })}
      </Tabs>
      {displayTabDetailList.length > 0 &&
        displayTabDetailList.map((it, i) => {
          if (it) {
            return (
              <CustomTabPanel key={i} index={i} value={value}>
                {it.tabPanel}
              </CustomTabPanel>
            );
          } else return;
        })}
    </Box>
  );
};

export default TabList;
