import { takeLatest, put } from 'redux-saga/effects';
import { hideBackdrop, showBackdrop } from './reducers';
import * as actions from './actions';

export function* rootSaga() {
  yield takeLatest(actions.SHOW_BACKDROP, displayBackDrop);
  yield takeLatest(actions.HIDE_BACKDROP, hideBackDrop);
}

export function* displayBackDrop() {
  yield put(showBackdrop());
}

export function* hideBackDrop() {
  yield put(hideBackdrop());
}
