import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DesktopTimePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimeValidationError } from '@mui/x-date-pickers';
import { Messages } from '../../constants/messages';
import { ValidationError } from '../../models/baseModels/validationModel';
import * as DateTimeMessage from '../../constants/dateTimePicker-constants';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import './timepicker.scss';

interface TimePickerProps {
  label?: string;
  timeValue: string;
  name: string;
  validateCounter?: number;
  isMandatory?: boolean;
  readOnly?: boolean;
  invalidDateMessage?: string;
  handleTimeChange: (newValue: Moment | null) => void;
  fieldValidationStatus: (name: string) => ValidationError;
  setFieldValidation: (validation: ValidationError) => void;
  removeValidation: (name: string) => void;
  setShowValidationMessage: (value: KeyValuePair) => void;
}

const TimePickerControl = (props: TimePickerProps) => {
  const {
    label,
    timeValue,
    name,
    validateCounter,
    isMandatory,
    readOnly,
    invalidDateMessage,
    handleTimeChange,
    fieldValidationStatus,
    setFieldValidation,
    removeValidation,
    setShowValidationMessage,
  } = props;

  const [isShowMessage, setIsShowMessage] = useState(false);
  const [value, setValue] = useState<Moment | null>(moment(timeValue));

  useEffect(() => {
    if (!!timeValue && invalidDateMessage) {
      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: '',
      });
      setValue(moment(timeValue));
      if (timeValue !== DateTimeMessage.INVALID_DATE_MESSAGE_VALUE) removeValidation(name);
    } else if (!!timeValue && !invalidDateMessage) {
      setValue(moment(timeValue));
      if (timeValue !== DateTimeMessage.INVALID_DATE_MESSAGE_VALUE) removeValidation(name);
    } else {
      if (isMandatory) {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: Messages.REQUIRED_FIELD,
        });
      } else {
        setValue(null);
      }
    }
  }, [invalidDateMessage, isMandatory, timeValue, name, setFieldValidation, removeValidation]);

  useEffect(() => {
    if (validateCounter && validateCounter > 0) {
      setIsShowMessage(true);
      setShowValidationMessage({ key: name, value: true });
    }
  }, [validateCounter, setShowValidationMessage, name]);

  const handleChange = (newValue: Moment | null) => {
    setValue(newValue);
    handleTimeChange(newValue);
  };

  const onTimeErrorHandler = (reason: TimeValidationError) => {
    switch (reason) {
      case DateTimeMessage.INVALID_DATE_REASON_VALUE: {
        setFieldValidation({
          name,
          hasError: true,
          showErrorMessage: false,
          validationMessage: DateTimeMessage.INVALID_TIME_MESSAGE_VALUE,
        });
        setShowValidationMessage({ key: name, value: true });
        break;
      }
      case null: {
        removeValidation(name);
        break;
      }
    }
  };

  const onTimeAcceptHandler = (value: Moment | null) => {
    if (!isMandatory) {
      removeValidation(name);
    }
    setIsShowMessage(false);
    setShowValidationMessage({ key: name, value: false });
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsShowMessage(true);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isMandatory && event?.target?.value === '') {
      setFieldValidation({
        name,
        hasError: true,
        showErrorMessage: false,
        validationMessage: Messages.REQUIRED_FIELD,
      });
    }
    if (event?.target?.value === '') {
      handleChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className='timepicker'>
        {label && (
          <div className='timepicker-label'>
            {label} {isMandatory && <strong className='asterisk'>*</strong>}
          </div>
        )}
        <div
          className={
            fieldValidationStatus(name)?.hasError && isShowMessage
              ? classNames('timepicker-container', 'timepicker-container-error')
              : readOnly
                ? classNames('timepicker-container', 'timepicker-container-read-only')
                : classNames('timepicker-container')
          }
        >
          <div className='desktop-container'>
            <DesktopTimePicker
              label={!readOnly && 'Select time'}
              value={value}
              onChange={handleChange}
              onAccept={onTimeAcceptHandler}
              orientation='portrait'
              disableIgnoringDatePartForTimeValidation={true}
              onError={onTimeErrorHandler}
              disabled={readOnly}
              slotProps={{
                toolbar: { hidden: false },
                textField: { onBlur: onBlurHandler, onChange: onChangeHandler },
              }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
            />
          </div>
          <div className='mobile-container'>
            <MobileTimePicker
              label={!readOnly && 'Select time'}
              value={value}
              onChange={handleChange}
              onAccept={onTimeAcceptHandler}
              orientation='portrait'
              disableIgnoringDatePartForTimeValidation={true}
              onError={onTimeErrorHandler}
              disabled={readOnly}
              slotProps={{
                toolbar: { hidden: false },
                textField: { onBlur: onBlurHandler, onChange: onChangeHandler },
              }}
            />
          </div>
        </div>
        {isShowMessage && fieldValidationStatus(name)?.hasError && (
          <div className='validation-text-message-error'>{fieldValidationStatus(name)?.validationMessage}</div>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default TimePickerControl;
