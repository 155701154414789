import { RootState } from '../rootReducers';
import { createSelector } from 'reselect';

export const selectReceiptTemplateState = (state: RootState) => state.ReceiptTemplateState;

export const selectReceiptTemplateContent = createSelector(
  selectReceiptTemplateState,
  (state) => state.receiptTemplateContent
);

export const selectReceiptTemplateStatus = createSelector(
  selectReceiptTemplateState,
  (state) => state.receiptTemplateStatus
);

export const selectReceiptTemplateItem = createSelector(
  selectReceiptTemplateState,
  (state) => state.receiptTemplateItem
);
