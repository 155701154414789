import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../constants/loading-constants';
import { OrganisationItemModel } from '../../models/organisationModel'
import { initialState } from './organisationInitState';

const organisationsSlice = createSlice({
    name: 'organisationState',
    initialState,
    reducers:{
        setOrganisationStatus: (state, action: PayloadAction<string>) => {
            state.organisationStatus = action.payload;
            state.hasError = false;
        },
        setOrganisationItem: (state, action: PayloadAction<OrganisationItemModel>) => {
            state.organisationItem = action.payload;
            state.hasError = false;
        },
        setOrganisationContent: (state, action: PayloadAction<OrganisationItemModel[]>) => {
            state.organisationContent = action.payload;
            state.hasError = false;
        },
        setOrganisationError: (state) => {
            state.hasError = true;
            state.organisationContent = [];
            state.organisationStatus = LoadingStatus.ERROR;
        }
    }
});

export const {
    setOrganisationStatus,
    setOrganisationItem,
    setOrganisationContent,
    setOrganisationError
} = organisationsSlice.actions;

export default organisationsSlice.reducer;
