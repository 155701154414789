import { useEffect, useState } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import classNames from 'classnames';
import { TerminalOperationModel } from '../../models/terminalOperationModel';
import { ButtonStyle } from '../../constants/button-constants';
import { fileUploadType, operationState, operationType } from '../../constants/dropdown-constants';
import { TerminalOperationState, TerminalOperationType } from '../../constants/terminal-operation-constants';
import { DoubleArrowDownIcon, DoubleArrowUpIcon, DownloadFileIcon, CancelOperationIcon } from '../../components/icons';
import Item from '../../components/box-items/box-item';
import CustomButton from '../../components/button/custom-button';
import * as fileDownload from '../../utilities/fileDownload-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import './styles/operationItem.scss';

interface OperationItemProps {
  item: TerminalOperationModel;
  hasFileUploadAccess: boolean;
  hasRestartAccess: boolean;
  hasBootloaderRestartAccess: boolean;
  hasMemoryDumpAccess: boolean;
  onCancelOperation?: (item: TerminalOperationModel) => void;
}

const OperationItem: React.FC<OperationItemProps> = (props: OperationItemProps) => {
  const {
    item,
    hasFileUploadAccess,
    hasRestartAccess,
    hasBootloaderRestartAccess,
    hasMemoryDumpAccess,
    onCancelOperation,
  } = props;

  const [isCompletedState, setIsCompletedState] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [hasCancelAccess, setHasCancelAccess] = useState(false);

  useEffect(() => {
    setIsFileUpload(item?.type === TerminalOperationType.FILE_UPLOAD);
  }, [item?.type]);

  useEffect(() => {
    setIsCompletedState(
      item?.state === TerminalOperationState.COMPLETED ||
        item?.state === TerminalOperationState.CANCELLED ||
        item?.state === TerminalOperationState.ERROR
    );
    setShowDetails(!isCompletedState);
  }, [isCompletedState, isFileUpload, item?.state]);

  useEffect(() => {
    if (!isCompletedState) {
      if (item?.type === TerminalOperationType.BOOTLOADER_RESTART) {
        setHasCancelAccess(hasBootloaderRestartAccess);
      }
      if (item?.type === TerminalOperationType.TERMINAL_RESTART) {
        setHasCancelAccess(hasRestartAccess);
      }
      if (item?.type === TerminalOperationType.MEMORY_DUMP) {
        setHasCancelAccess(hasMemoryDumpAccess);
      }
      if (item?.type === TerminalOperationType.FILE_UPLOAD) {
        setHasCancelAccess(hasFileUploadAccess);
      }
    } else {
      setHasCancelAccess(false);
    }
  }, [
    hasBootloaderRestartAccess,
    hasFileUploadAccess,
    hasMemoryDumpAccess,
    hasRestartAccess,
    isCompletedState,
    item?.type,
  ]);

  const showDetailHandler = () => {
    setShowDetails((prev) => !prev);
  };

  const onDownloadClick = (downloadLink: string, id: string) => {
    fileDownload.downloadFile(downloadLink, id, 'txt');
  };

  const onCancelClick = () => {
    if (onCancelOperation) {
      onCancelOperation(item);
    }
  };

  return (
    <>
      {(item?.type === 'fileUpload' ||
        item?.type === 'terminalRestart' ||
        item?.type === 'bootloaderRestart' ||
        item?.type === 'memoryDump') && (
        <Grid container className={classNames('operation-grid-container')} alignItems='center'>
          <Grid item xs={8} sm={10} rowGap={2}>
            <Grid container alignItems='flex-start'>
              <Grid item xs={12} sm={4}>
                <Item className={classNames('operation-grid-item-container')}>
                  <div>
                    <Box>
                      <Item className={classNames('operation-card-field-header')}>Type</Item>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: 'flex', sm: 'none' },
                      }}
                    >
                      <Item className={classNames('operation-card-field-value')}>
                        <div>{fieldMappingHelper.getDisplayValue(item?.type, operationType)}</div>
                      </Item>
                    </Box>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item className={classNames('operation-grid-item-container')}>
                  <div>
                    <Box>
                      <Item className={classNames('operation-card-field-header')}>State</Item>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: 'flex', sm: 'none' },
                      }}
                    >
                      <Item className={classNames('operation-card-field-value')}>
                        <div>{fieldMappingHelper.getDisplayValue(item?.state, operationState)}</div>
                      </Item>
                    </Box>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item className={classNames('operation-grid-item-container')}>
                  <div>
                    <Box>
                      <Item className={classNames('operation-card-field-header')}>Last Status Update</Item>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: 'flex', sm: 'none' },
                      }}
                    >
                      <Item className={classNames('operation-card-field-value')}>
                        <div>{item?.lastStatusUpdate}</div>
                      </Item>
                    </Box>
                  </div>
                </Item>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems='flex-start'
              sx={{
                display: { xs: 'none', sm: 'flex' },
              }}
            >
              <Grid item xs={12} sm={4}>
                <Item className={classNames('')}>
                  <div>
                    <Item className={classNames('operation-card-field-value')}>
                      <div>{fieldMappingHelper.getDisplayValue(item?.type, operationType)}</div>
                    </Item>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item className={classNames('')}>
                  <div>
                    <Item className={classNames('operation-card-field-value')}>
                      <div>{fieldMappingHelper.getDisplayValue(item?.state, operationState)}</div>
                    </Item>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item className={classNames('')}>
                  <div>
                    <Item className={classNames('operation-card-field-value')}>{item?.lastStatusUpdate}</Item>
                  </div>
                </Item>
              </Grid>
            </Grid>

            <Collapse in={showDetails && isFileUpload && hasFileUploadAccess}>
              <Grid container alignItems='flex-start' className={classNames('operation-card-more-details')}>
                <Grid item xs={12} sm={4}>
                  <Item className={classNames('operation-grid-item-container')}>
                    <div>
                      <Box>
                        <Item className={classNames('operation-card-field-header')}>Path</Item>
                      </Box>
                      <Item className={classNames('operation-card-field-value')}>{item?.path}</Item>
                    </div>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Item className={classNames('operation-grid-item-container')}>
                    <div>
                      <Box>
                        <Item className={classNames('operation-card-field-header')}>Upload Type</Item>
                      </Box>
                      <Item className={classNames('operation-card-field-value')}>
                        {fieldMappingHelper.getDisplayValue(item?.uploadType, fileUploadType)}
                      </Item>
                    </div>
                  </Item>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item xs={4} sm={2} alignItems='flex-start'>
            {
              <Item className={classNames('operation-grid-item-container')}>
                <div>
                  <Item className={classNames('operation-grid-button-container')}>
                    {isFileUpload && hasFileUploadAccess && (
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        onClick={showDetailHandler}
                        icon={
                          showDetails ? (
                            <DoubleArrowUpIcon className={classNames('button-icon-color')} />
                          ) : (
                            <DoubleArrowDownIcon className={classNames('button-icon-color')} />
                          )
                        }
                        iconTooltip={showDetails ? 'Show less' : 'Show more'}
                      ></CustomButton>
                    )}
                    {!!item?.downloadUrl && (
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        onClick={() => onDownloadClick(item?.downloadUrl, item?.id)}
                        icon={<DownloadFileIcon className={classNames('button-icon-color')} />}
                        iconTooltip={'Download'}
                      ></CustomButton>
                    )}
                    {hasCancelAccess && (
                      <CustomButton
                        buttonStyle={ButtonStyle.ICON}
                        onClick={onCancelClick}
                        icon={<CancelOperationIcon className={classNames('button-icon-color')} />}
                        iconTooltip={'Cancel'}
                      ></CustomButton>
                    )}
                  </Item>
                </div>
              </Item>
            }
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OperationItem;
