import SvgIcon from '@mui/material/SvgIcon';
import './icons.scss';

interface ExportIconProps {
    className?:string
};

const ExportIcon = (props: ExportIconProps) => {
    return (
        <SvgIcon className={`base-icon export-icon ${props.className}`}/>
    );
};

export default ExportIcon