import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import '../styles/general/general-helpers.scss';

export const getStatusMessageCount = (data: any[]) => {
  let warningCount = 0;
  let errorCount = 0;

  data.forEach((item) => {
    if (item.level === 'warning') {
      warningCount++;
    } else if (item.level === 'error') {
      errorCount++;
    }
  });

  let result = '';
  if (warningCount > 0 && errorCount > 0) {
    result = `${warningCount} Warning${warningCount > 1 ? 's' : ''} and ${errorCount} Error${
      errorCount > 1 ? 's' : ''
    }`;
  } else if (warningCount > 0) {
    result = `${warningCount} Warning${warningCount > 1 ? 's' : ''}`;
  } else if (errorCount > 0) {
    result = `${errorCount} Error${errorCount > 1 ? 's' : ''}`;
  }

  return (
    <span className='status-message-container'>
      Show ({result}{' '}
      {data?.filter((it) => it.level === 'error')?.length > 0 ? (
        <ErrorOutlineIcon className='error-outline-icon' />
      ) : (
        <ErrorOutlineIcon className='warning-amber-icon' />
      )}
      )
    </span>
  );
};

export const usePageLeaveListener = (cancelReloadFunction: () => void) => {
  const location = useLocation();

  useEffect(() => {
    const handlePageLeave = () => {
      // trigger cancel reload function
      cancelReloadFunction();
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handlePageLeave();
    };
  }, [cancelReloadFunction, location]);
};
