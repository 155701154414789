import { LoadingStatus } from '../../constants/loading-constants';
import { EventModel } from '../../models/eventModel';

interface EventState {
  eventStatus: string;
  eventData: EventModel[];
  eventTypes: string[];
  eventFuzzySearch: string;
  hasError: boolean;
}

export const initialState: EventState = {
  eventStatus: LoadingStatus.LOADING,
} as EventState;
